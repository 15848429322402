import { Dialog, Typography } from "@mui/material";
import React from "react";
import { dates } from "../../../Constant/GoalConstant";
import classes from "./GoalDatePicker.module.css";
import RightIcon from "../../../assets/Svg/RightIcon.svg";
function GoalDatePicker({
  isDatePickerOpen,
  dateModalClosehandler,
  dateselectorHandler,
  selectedDate,
}) {
  return (
    <Dialog
      open={isDatePickerOpen}
      onClose={() => {
        dateModalClosehandler();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "458px",
          height: "372px",
          borderRadius: "20px",
          position: "unset",
          padding: "20px",
        },
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography className={classes.dailyText} variant="subtitle1">
          Daily
        </Typography>
        <Typography className={classes.DateweeklyText} variant="subtitle1">
          Weekly
        </Typography>
        <div className={classes.WeeklyText}>
          <Typography variant="subtitle1" sx={{ color: "#434243" }}>
            Monthly
          </Typography>
          <img
            style={{ width: "14px", height: "8px" }}
            src={RightIcon}
            alt=""
          />
        </div>
        <div className={classes.DayWrapper}>
          {dates.map((date) => {
            return (
              <div
                onClick={() => dateselectorHandler(date)}
                className={
                  selectedDate === date
                    ? classes.selectedDateText
                    : classes.dateText
                }
              >
                {date}
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
}

export default GoalDatePicker;
