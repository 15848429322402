import { Button, Dialog, Typography, useMediaQuery } from "@mui/material";
import classes from "./GoalNotFound.module.css";
import React, { useState } from "react";
import typography from "../../theme/typography";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import CreateGoal from "../../core-ui/CreateGoal/CreateGoal";
import { useDispatch } from "react-redux";
import { updateGoalData } from "../../Store/Reducers/goalDataSlice";
function GoalNotFound({ setisAddNewGoal }) {
  const theme = useTheme();
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  // const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };
  const setIsAskOtherModalOpen = (val) => {
    dispatch(updateGoalData({
        isAskOtherModalOpen: val
    }))
}
  const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};

        position: unset;
      }
    }
  `;
  return (
    <div className={matches ? classes.smallMainWrapper : classes.mainWrapper}>
      <div className={classes.notAddGoalWrapper}>
        <Typography variant="body4" sx={{ marginBottom: "4px" }}>
          You haven’t added any goals
        </Typography>
        {matches ? (
          <Typography
            variant="subtitle1"
            sx={{
              color: "#434243",
              margin: "2px 0px",
              textAlign: "center",
              lineHeight: "140%",
            }}
          >
            Start creating goals to increase, decrease or calibrate your
            dimensions
          </Typography>
        ) : (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#434243",

                textAlign: "center",
                lineHeight: "140%",
              }}
            >
              Start creating goals to increase, decrease or calibrate
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#434243",

                textAlign: "center",
                lineHeight: "140%",
              }}
            >
              your dimensions
            </Typography>
          </>
        )}

        <Button
          className={classes.createGoalsBtn}
          sx={{ ...typography.subtitle1, marginTop: "20px" }}
          onClick={() => {
            setIsAskOtherModalOpen(true);
          }}
        >
          Create a goal
        </Button>
      </div>
      {/* <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CreateGoal
          handleClose={handleClose}
          setisAddNewGoal={setisAddNewGoal}
        />
      </CustomDialog> */}
    </div>
  );
}
export default GoalNotFound;
