import React, { useCallback, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { YearPicker } from "@mui/x-date-pickers/YearPicker";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import moment from "moment";

export default function DatePicker({ currentDate, setCurrentDate, maxDate }) {
  // const [date, setDate] = useState(new Date());

  // console.log("12",moment(date).format("LL"))

  const TestDatePicker = styled(CalendarPicker)`
    // background: yellow;
    margin: 0px;
    width: 100%;

    & .MuiDayPicker-header {
      justify-content: space-between;
    }

    & .MuiDayPicker-weekContainer {
      justify-content: space-between;
    }

    & .css-1n2mv2k {
      justify-content: space-between;
    }

    & .css-mvmu1r {
      justify-content: space-between;
    }
    & .css-1v994a0 {
      color: #190d1a;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
    }

    & .css-1dozdou {
      padding-left: 12px;
      padding-right: 6px;
    }

    & .PrivatePickersSlideTransition-root {
      min-height: 190px;
    }

    & .css-1dozdou {
      margin-top: 0;
    }

    & .css-1ufzhmb-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
      border: none;
      // border-radius: 4px;
      color: #9747ff;
    }

    & .css-1fqdhv1-MuiButtonBase-root-MuiPickersDay-root {
      :hover {
        border: 1px solid #9747ff;
        border-radius: 4px;
        background: unset;
      }
    }

    & .Mui-selected {
      border-radius: 4px;
    }

    & .MuiPickersArrowSwitcher-root {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & button {
        padding: 0px;
      }
      & svg {
        border: 0.5px solid #e5d5fc;
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
    }
  `;

  // const TestButtonWrapper = styled("div")(({ theme }) => ({
  //   color: '#190d1a',
  //   fontFamily: "Inter",
  //   fontStyle: "normal",
  //   fontWeight: '500',
  //   fontSize: '14px',
  //   lineHeight: '100%',
  //   [theme.breakpoints.down("xl")]: {

  //   },
  //   [theme.breakpoints.down("lg")]: {

  //   },
  //   [theme.breakpoints.up("md")]: {

  //   },
  //   [theme.breakpoints.down("md")]: {

  //   },
  //   [theme.breakpoints.down("sm")]: {

  //   },
  //   [theme.breakpoints.down("sm")]: {

  //   },
  // }));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TestDatePicker
        components={{}}
        date={currentDate}
        onChange={useCallback(
          (newDate) => {
            setCurrentDate(newDate);
          },
          [currentDate]
        )}
        maxDate={maxDate}
        
      />
    </LocalizationProvider>
  );
}
