import { Box, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SubmitButton, SubmitButtonWithBackGround } from "../../../components/Button/Button";
import classes from "./InvitePage.module.css";
import Coin from "../../../assets/Svg/Coin.svg";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { SubHeading } from "../StyledComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";


const InvitePage = () => {
  const {inviteDataSlice : {inviteData}} = useSelector((state)=>state)
  const navigate = useNavigate()
  const {id:inviteId} = useParams()
  console.log("inviteId",inviteId)
  useEffect(() => {
    localStorage.setItem("inviteId",inviteId)
  }, [])
  
  const onNext = () => {
    const uid = localStorage.getItem("userDetail");
    console.log("Uid",uid)
    if(uid){
      navigate(`/invite/${inviteId}/userinfo`)
    }else{
      navigate(`/invite/${inviteId}/auth`)
    }
  }
  return (
    <div className={classes.invitePageWrapper}>
      <div></div>
      <div className={classes.headingWrapper}>
        <div className={classes.headingText}>
          <Typography variant="h3">
            {inviteData?.name} invited to take {inviteData?.subCategory ? inviteData?.subCategory : "Virtues in action"} test
          </Typography>
        </div>
        <SubHeading>
          <div className={classes.subHeadingText}>
            <Typography variant="subtitle1" color="#434243">
              Help your friend to get feedback on their self-discovery and earn
            </Typography>
          </div>
        </SubHeading>
        <div className={classes.coinIconWrapper}>
          <img src={Coin} alt="" />
          <Typography variant="body3" color="#9747FF">
            20
          </Typography>
        </div>
      </div>
      <div className={classes.nextButton}>
        <SubmitButtonWithBackGround
          buttonText="Next"
          onClick={onNext}
          isDisabled={false}
          isLoading={false}
        />
      </div>
    </div>
  );
};

export default InvitePage;
