import { Box, LinearProgress, Typography } from "@mui/material";
import classes from "./ProgressBar.module.css";

function LinearProgressWithLabel({ progress=0, isPercentageValue=false }) {
  return (
    <Box className={classes.progressBarWrapper}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      </Box>
      {isPercentageValue && (
        <Box>
          <Typography variant="body3" color="#190D1A">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default LinearProgressWithLabel;
