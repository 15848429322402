import classes from "../Onbording.module.css";
import { Box } from "@mui/system";

import Input, {
  CheckBoxInput,
  CheckBoxInputChecked,
  InputCheckBox,
  InputFocus,
} from "../../../../components/Input/Input";
import { PrevAndNextButtonGroup } from "../../../../components/Button/Button";
import { Step, StepsWrapper, SubHeadingTwo } from "../../StyledComponent";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { updateGender } from "../../../../Store/Reducers/onbordingSlice";
import { Typography } from "@mui/material";

export default function StepThree({ onNext, onPrevious }) {
  const Male = "Male";
  const Female = "Female";
  const { gender,name } = useSelector((state) => state.onbording);
  const [male, setMale] = useState(gender === Male);
  const [female, setFemale] = useState(gender === Female);
  const [others, setOthers] = useState(gender === Male || gender === Female ? "" : gender);
  const dispatch = useDispatch();
  const onNextStep = () => {
    console.log(male, female);
    
    if (male) {
      dispatch(updateGender(Male));
    } else if(female) {
      dispatch(updateGender(Female));
    }else{
      dispatch(updateGender(others))
    }
    onNext();
  };

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const handleChange = (gender) => {
    if (gender === Male ) {
      setMale(!male);
      setFemale(false);
      if (others !== "") {
        setOthers("");
      }
    }
    if (gender === Female ) {
      setFemale(!female);
      setMale(false);
      if (others !== "") {
        setOthers("");
      }
    }
  };

  useEffect(() => {
    if (others !== "") {
      setFemale(false);
      setMale(false);
    }
  }, [others]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const isBtnDisabled = () =>{
    console.log(74,others,male,female)

  }
  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step></Step>
      </StepsWrapper>
      <div className={classes.wrapperDiv}>
      <Box className={classes.wrapper}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>{name}, it’s nice to meet you!</Typography>
      </Box>
      <SubHeadingTwo className={classes.wrapper} sx={{ mb: '12px' }}>
        <Typography  variant="subtitle1" sx={{ textAlign: "center" }}>How do you identify yourself?</Typography>
      </SubHeadingTwo>
      <Box className={classes.wrapper} sx={{ mb: '16px' }}>
        <CustomCheckBox
          checked={male}
          {...label}
          icon={<CheckBoxInput placeholder="Male" />}
          onChange={() => handleChange(Male)}
          checkedIcon={<CheckBoxInputChecked placeholder="Male" />}
        />
      </Box>
      <Box className={classes.wrapper} sx={{ mb: '16px' }}>
        <CustomCheckBox
          checked={female}
          onChange={() => handleChange(Female)}
          {...label}
          icon={<CheckBoxInput placeholder="Female" />}
          checkedIcon={<CheckBoxInputChecked placeholder="Female" />}
        />
      </Box>
      <Box className={classes.wrapper} sx={{ mb: '16px' }}>
        <InputCheckBox
          placeholder="Other"
          value={others}
          onChange={setOthers}
          type="text"
        />
      </Box>
      {/* <Box className={classes.wrapper} sx={{ mb: '16px' }}>
        <InputCheckBox
          placeholder="Other"
          value={others}
          onChange={setOthers}
          type="text"
        />
      </Box> */}
      <Box className={classes.wrapper}>
        <PrevAndNextButtonGroup
          isPreviousButton={true}
          onNext={onNextStep}
          onPrevious={onPrevious}
          isDisabled={ male || female ? others !== '' ? false : false : others !== '' ? false : true  }
        />
      </Box>
      </div>
    </>
  );
}
