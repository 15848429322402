import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    testCategoriesList: [],
    
}

export const testCategoriesListSlice = createSlice({
    name: 'testCategoriesListSlice',
    initialState,
    reducers: {
        storeTestCategoriesList: (state, action) => {
            state.testCategoriesList = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { storeTestCategoriesList} = testCategoriesListSlice.actions

export default testCategoriesListSlice.reducer