import React, { useEffect, useState } from "react";
import { USERTYPE } from "../../../Constant/Invite";
import GetAge from "../GetAge/GetAge";
import GetGender from "../GetGender/GetGender";
import GetName from "../GetName/GetName";
import NameOption from "../NameOption/NameOption";
import StartTest from "../StartTest/StartTest";
import classes from "./ShowUserDetailsFlow.module.css";

const showMyNameFlow = {
  1: NameOption,
  2: StartTest,
};

const anonymousUserFlow = {
  1: NameOption,
  2: GetName,
  3: GetAge,
  4: GetGender,
  5: StartTest,
};
const ShowUserDetailsFlow = () => {
  const [showUserdata, setshowUserdata] = useState({
    TesterMode: "",
    nickName: "",
    anonymousAge: "",
    anonymousGender: "",
  });
  console.log("showUserdata", showUserdata);
  const getUserDatafnc = (key, value) => {
    setshowUserdata({
      ...showUserdata,
      [key]: value,
    });
  };
  const [step, setStep] = useState(1);
  const Step =
    showUserdata.TesterMode === USERTYPE?.SHOW_MY_NAME
      ? showMyNameFlow[step]
      : anonymousUserFlow[step];
  const onPrevious = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  const onNext = () => {
    setStep((prev) => prev + 1);
  };
  return (
    <Step
      onNext={onNext}
      onPrevious={onPrevious}
      setshowUserdata={setshowUserdata}
      showUserdata={showUserdata}
      getUserDatafnc={getUserDatafnc}
    />
  );
};
export default ShowUserDetailsFlow;
