import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionData: [],
  compeletedAction: [],
  isAddmoreAction:false,
  isActionShedualOpen:false,
  scheduleState:{},
};
export const ActiondataSlice = createSlice({
  name: "ActiondataSlice",
  initialState,
  reducers: {
    updateActionData: (state, action) => {
      state.actionData = action.payload;
    },
    updateisCompeletedAction: (state, action) => {
      state.compeletedAction = action.payload;
    },
    updateIsAddMore: (state, action) => {
      state.isAddmoreAction = action.payload;
    },
    updateScheduleState: (state, action) => {
      state.scheduleState = action.payload;
    },
   
    
  },
});

// Action creators are generated for each case reducer function
export const { updateActionData, updateisCompeletedAction,updateIsAddMore,updateScheduleState, } =
  ActiondataSlice.actions;
export default ActiondataSlice.reducer;
