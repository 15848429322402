import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  notifications: [],
  unreadnotifications: [],
  alreadyCalled: false,
  headerNavigation: [
    {
      name: "Home",
      path: "/dashboard",
    },
  ],
  anchorEl: null,
  xposition: 0,
  yposition: 0,
  //   isActionShedualOpen:false,
  //   scheduleState:{}
};
export const HeaderData = createSlice({
  name: "HeaderData",
  initialState,
  reducers: {
    updateNotificationData: (state, action) => {
      state.notifications = action.payload;
      state.alreadyCalled = true
    },
    updateUnreadNotificationData: (state, action) => {
      state.unreadnotifications = action.payload;
      state.alreadyCalled = true
    },
    updateHeaderNavigation: (state, action) => {
        state.headerNavigation = action.payload
    },
    unreadSpecificaNotifications: (state, action) => {
      if(action.payload && action.payload.length) {
        state.unreadnotifications = [...state.unreadnotifications].filter(it => !action.payload.includes(it._id))
        state.notifications = state.notifications.map(it => {
          if(action.payload.includes(it._id)) {
            it.read = true
          }
          return it
        })
      }
    },
    setAnchorEl: (state, action)=> {
      state.anchorEl = action.payload
    },
    setXposition:  (state, action)=> {
      state.xposition = action.payload
    },
    setYposition:  (state, action)=> {
      state.yposition = action.payload
    },
    resetHeaderState: () => {
      return initialState;
    },
  },
});

export const { updateNotificationData, resetHeaderState, updateHeaderNavigation, updateUnreadNotificationData, unreadSpecificaNotifications, setAnchorEl, setXposition, setYposition } = HeaderData.actions;
export default HeaderData.reducer;
