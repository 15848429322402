import axios from "axios";
import { baseEndpoints } from "../config";

class WaitlistService {
    static WAIT_LIST_CREATE = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.WAIT_LIST.CREATE}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }
}

export default WaitlistService