import React, { useContext, useEffect, useState, useCallback } from "react";
import classes from "./CalendarHeader.module.css";
import { Typography } from "@mui/material";
import {
  AllButton,
  AllFavourite,
  FavouriteButton,
} from "../../GoalComponent/GoalButton";
import { CALENDARTYPE } from "../../../Constant/GoalConstant";
import LeftArrowIcon from "../../../assets/Svg/LeftArrowIcon.svg";
import RightArrowIcon from "../../../assets/Svg/RightArrowIcon.svg";
import {
  addWeeks,
  format,
  getWeek,
  lastDayOfWeek,
  startOfWeek,
  subWeeks,
} from "date-fns";
import moment from "moment";

const CalendarHeader = ({
  typeCalendar,
  setTypeCalendar,
  currentMonthOfWeek,
  setCurrentMonthOfWeek,
  setCurrentWeek,
  setMonthObject,
  monthObject,
  setDateObject,
  matches,
  setIeWeekTrue,
}) => {
  const handlePrevMonth = () => {
    if (typeCalendar === "WEEKLY_CALENDAR" || matches) {
      setCurrentMonthOfWeek(subWeeks(currentMonthOfWeek, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonthOfWeek, 1)));
      setIeWeekTrue(false);
    } else {
      const convertToNormal = moment(monthObject);
      const addedmonth = convertToNormal.subtract(1, "month");
      setDateObject(addedmonth);
      setMonthObject(moment(addedmonth).format("MMMM, yyyy"));
    }
  };
  function handleNextMonth() {
    if (typeCalendar === "WEEKLY_CALENDAR" || matches) {
      setCurrentMonthOfWeek(addWeeks(currentMonthOfWeek, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonthOfWeek, 1)));
      setIeWeekTrue(false);
    } else {
      const convertToNormal = moment(monthObject);
      const addedmonth = convertToNormal.add(1, "month");
      setDateObject(addedmonth);
      setMonthObject(moment(addedmonth).format("MMMM, yyyy"));
    }
  }
  const monthClick = useCallback(() => {
    setTypeCalendar(CALENDARTYPE.MONTHLY_CALENDAR);
  }, [typeCalendar]);
  const weekClick = useCallback(() => {
    setTypeCalendar(CALENDARTYPE.WEEKLY_CALENDAR);
  }, [typeCalendar]);
  const dateFormat = "MMMM, yyyy";
  return (
    <div className={classes.header}>
      <Typography variant="p" className={classes.month_year_text}>
        {typeCalendar === "WEEKLY_CALENDAR" || matches
          ? format(currentMonthOfWeek, dateFormat)
          : monthObject}
      </Typography>
      <div className={classes.header_month_week}>
        {!matches && (
          <AllFavourite>
            {" "}
            <AllButton
              onClick={() => {
                monthClick();
              }}
              variant={
                typeCalendar === CALENDARTYPE.MONTHLY_CALENDAR
                  ? "contained"
                  : null
              }
            >
              Month
            </AllButton>
            <FavouriteButton
              onClick={() => {
                weekClick();
              }}
              variant={
                typeCalendar === CALENDARTYPE.WEEKLY_CALENDAR
                  ? "contained"
                  : null
              }
            >
              {" "}
              Week
            </FavouriteButton>
          </AllFavourite>
        )}
        <div className={classes.icon}>
          <div className={classes.left_icon} onClick={handlePrevMonth}>
            <img src={LeftArrowIcon} alt={LeftArrowIcon} />
          </div>
          <div className={classes.right_icon} onClick={handleNextMonth}>
            <img src={RightArrowIcon} alt={RightArrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CalendarHeader);
