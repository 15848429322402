import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react'
import Header from '../../../components/Header/Header'
import { SettingNavigationBar } from '../../../components/Settings/SettingNavigationBar/SettingNavigationBar';
import { RightSideContentWrapper } from '../../../components/Settings/StyledElement/StyledDiv'
import { ChangePassword } from './ChangePassword'
import classes from './password.module.css'
import { useTheme } from "@emotion/react";

function SetPassword() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <RightSideContentWrapper>
        <Header />
        <Grid container spacing={1.5}>
          <Grid item xs={12} lg={9} md={9}>
            <div className={classes.dashbordTitleContainer}>
              <ChangePassword />
            </div>
          </Grid>
          {!matches && (
            <Grid item xs={12} lg={3} md={3}>
              <div
                style={{ padding: "12px 0px" }}
                className={classes.dashbordTitleContainer}
              >
                <SettingNavigationBar />
              </div>
            </Grid>
          )}
        </Grid>
      </RightSideContentWrapper>
    </>
  );
}

export default SetPassword