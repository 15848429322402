import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../components/Input/Input";
import useCustomSixCheckBox from "../../../Hooks/useCustomSixCheckBox";
import { USERSGENDER } from "../../../Constant/Invite";
import {
  CustomCheckBox,
  Step,
  StepsWrapper,
  SubHeadingTwo,
  WrapperDiv,
} from "../StyledComponent";
import classes from "./GetGender.module.css";

const GetGender = (props) => {
  const { firstCheckBox, secondCheckBox, thirdCheckBox, handleChange } =
    useCustomSixCheckBox(
      USERSGENDER?.MALE,
      USERSGENDER?.FEMALE,
      USERSGENDER?.OTHER,
      props?.showUserdata?.anonymousGender
    );
  const updateMainState = () => {
    if (firstCheckBox) {
      props.getUserDatafnc("anonymousGender", USERSGENDER?.MALE);
    } else if (secondCheckBox) {
      props.getUserDatafnc("anonymousGender", USERSGENDER?.FEMALE);
    } else {
      props.getUserDatafnc("anonymousGender", USERSGENDER?.OTHER);
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
      </StepsWrapper>
      <WrapperDiv>
        <div></div>
        <div>
          <Box className={classes.wrapper}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
            {props?.showUserdata?.nickName}, it’s nice to meet you!
            </Typography>
          </Box>
          <SubHeadingTwo>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              How do you identify yourself?
            </Typography>
          </SubHeadingTwo>
          <Box className={classes.wrapper} sx={{ width: "100%", mt: "12px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.buttonWrapper}
              rowSpacing="16px"
              columnSpacing="12px"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomCheckBox
                  checked={firstCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="Male" />}
                  onChange={() => {
                    handleChange(USERSGENDER?.MALE);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="Male" />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomCheckBox
                  checked={secondCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="Female" />}
                  onChange={() => {
                    handleChange(USERSGENDER?.FEMALE);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="Female" />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomCheckBox
                  checked={thirdCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="Other" />}
                  onChange={() => {
                    handleChange(USERSGENDER?.OTHER);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="Other" />}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomCheckBox
                  checked={false}
                  {...label}
                  icon={<CheckBoxInput placeholder="40-50" />}
                  // onChange={() => {
                  //   handleChange(FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION);
                  // }}
                  checkedIcon={<CheckBoxInputChecked placeholder="40-50" />}
                />
              </Grid> */}
            </Grid>
          </Box>
        </div>
        <Box sx={{ width: "100%", mt: "16px" }}>
          <ButtonGroup
            {...props}
            buttonText="Next"
            onClick={() => {}}
            updateMainState={updateMainState}
            isNextDisable={!firstCheckBox && !secondCheckBox && !thirdCheckBox}
            isLoading={false}
          />
        </Box>
      </WrapperDiv>
    </>
  );
};

export default GetGender;
