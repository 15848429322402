import { Box, CircularProgress,circularProgressClasses,  Typography } from "@mui/material";
import { styled } from "@mui/system";
// import "./circularProgress.css"
function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: "40px",
        height: "40px",
      }}
    >
       <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ?  props.colorCode : '#E5D5FC'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" style={{fontSize:"12px"}} component="div" color={props.colorCode}>
          {props.value}
        </Typography>
      </Box>
    </Box>
  );
}
export default CircularProgressWithLabel;
