import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import parse from "html-react-parser";
import Gift from "../../assets/Svg/Gift.svg";

import classes from "./TestResultCategoryList.module.css";

import { useDispatch, useSelector } from "react-redux";

import ResultCategoryCard, {
    LongMenu,
    TestcatagoriesComponent,
} from "../../components/ResultCategoryCard/ResultCategoryCard";
import { TestQuestionSlider } from "../../components/TestQuestionSlider/TestQuestionSlider";
import ResultChart from "../../components/ResultChart/ResultChart";
import { useTheme } from "@emotion/react";
import { updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";

import StepperModal from "./stepperModal";
import { imageURLRender, randomXP } from "../../utils";
import PersonalEmptyResult from "./PersonaEmptyResult";
const GridButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const ComparisonButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const GraphButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const GridGraphComprison = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
}));
const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: "28px",
    marginTop: "28px",
    marginBottom: "16px",
    fontWeight: "600",
    color: "#190D1A",
    [theme.breakpoints.down("md")]: {
        marginTop: "16px",
        fontSize: "18px",
    },
}));

const PersonalResultComponent = ({
    fetchTestCategories,
    getDimentions,
    unlockCard,
    UnlockGAAction,
    feedBackDataHandler,
    Notification,
    updateNotificationState
}) => {
    const { resultAllDiamention, testCategoriesList } = useSelector(
        (state) => state
    );
    const { selectedInviteDataSlice, averageByInviteUserSlice } = useSelector(
        (state) => state
    );
    const {
        selectLayoutBtn = SELECTLAYOUTBTN.GRID,
        typeofResult = TYPEOFRESULTS.PERSONAL,
        spacificPersonInviteId = '',
        QuetionCatagoriesId = "",
        isHandleCloseCall = false,
        isCatagories = true,
    } = resultAllDiamention
    const dispatch = useDispatch();


    const setSelected = val => dispatch(updateResultState({
        selectLayoutBtn: val
    }))
    const setTypeofResult = val => dispatch(updateResultState({
        typeofResult: val
    }))

    const setSendmodalStep = val => dispatch(updateResultState({
        sendmodalStep: val
    }))

    const setIsAskOtherModalOpen = val => dispatch(updateResultState({
        isAskOtherModalOpen: val
    }))
    const setModalData = val => dispatch(updateResultState({
        modalData: val
    }))
    const setSpacificPersonInviteId = val => dispatch(updateResultState({
        spacificPersonInviteId: val
    }))
    const setspecificInviterName = val => dispatch(updateResultState({
        specificInviterName: val
    }))
    const setQuetionCatagoriesId = val => dispatch(updateResultState({
        QuetionCatagoriesId: val
    }))
    const setFinalirrelevantdata = val => dispatch(updateResultState({
        finalirrelevantdata: val
    }))
    const setFinalinaccuratedata = val => dispatch(updateResultState({
        finalinaccuratedata: val
    }))
    const setIrreleventRating = val => dispatch(updateResultState({
        irreleventRating: val
    }))
    const setinaccurateRatting = val => dispatch(updateResultState({
        inaccurateRatting: val
    }))

    const sendModalhandler = (irrr) => {
        setSendmodalStep(irrr);
    };
    const mainStateUpdateHandler = (rattingIrrelevnt, irrelevantData) => {
        setFinalirrelevantdata(irrelevantData);
        setIrreleventRating(rattingIrrelevnt);
    };
    const inaccurateHandler = (ratingInaccurate, inaccurateData) => {
        setinaccurateRatting(ratingInaccurate);
        setFinalinaccuratedata(inaccurateData);
    };
    function renderGrowthFavorites(growthArea, growthFav, enable) {
        growthArea.filter(function (o1) {
            growthFav.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
                return item
            });
        });
    }

    function renderActionFavorites(actionArea, actionFavorites, enable) {
        actionArea.filter(function (o1) {
            actionFavorites.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
            });
        });
    }
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const modalOpenhandler = (data, questionCategory, questionCategoryId) => {
        setModalData({
            data,
            questionCategory,
            questionCategoryId,
        });
        const enable = data.length - 1
        const selectedScore = data?.length && data[data?.length - 1]?.uniqueId
        const growthAreaActionData = data?.map((item, index) => parse(item.descriptionDetails))
        const growthFavorites = data?.map((item) => item.growthFavorites)
        console.log("growthFavorites setting in store from click", growthFavorites);
        const actionFavorites = data?.map((item) => item.actionFavorites)
        dispatch(updateResultState({
            enable: enable,
            selectedScore: selectedScore,
            growthAreaActionData: growthAreaActionData,
            growthFavorites: growthFavorites,
            actionFavorites: actionFavorites,
        }))
        setIsAskOtherModalOpen(true);
        setQuetionCatagoriesId(questionCategoryId);
        const handleTestData = () => {
            let tempGrowthArea = [];
            let tempProgress = [];
            if (growthAreaActionData?.length) {
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "1"
                                ? item?.props?.children?.map((ele) => {
                                    tempGrowthArea.push({
                                        uniqueId: selectedScore,
                                        text: ele.props.children,
                                        isChecked: false,
                                    });
                                })
                                : null
                        );
                    }
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "3"
                                ? item?.props?.children?.map((ele) => {
                                    tempProgress.push({
                                        uniqueId: selectedScore,
                                        text: ele.props.children,
                                        isChecked: false,
                                        randomXPObject: randomXP(),
                                    });
                                })
                                : null
                        );
                    }
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "0" ? dispatch(updateResultState({
                                growthAreaQsn: item?.props?.children,
                            })) : ""
                        );
                    }
                    return data
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "2" ? dispatch(updateResultState({
                                progressQsn: item?.props?.children,
                            })) : ""
                        );
                    }
                    return data
                });
                renderGrowthFavorites(tempGrowthArea, [...growthFavorites], enable);
                renderActionFavorites(tempProgress, [...actionFavorites], enable);
                dispatch(updateResultState({
                    growthAreaData: tempGrowthArea,
                    progressData: tempProgress
                }))

                if (
                    data[enable]?.unlockgrowthandactions &&
                    data[enable]?.unlockgrowthandactions[0]
                ) {
                    dispatch(
                        updateResultState({
                            growthCount:
                                data[enable]?.unlockgrowthandactions[0].growth,
                            actionCount:
                                data[enable]?.unlockgrowthandactions[0].action,
                        })
                    );
                }
            }
        };
        handleTestData()
    };
    return <>
        {typeofResult === TYPEOFRESULTS.PERSONAL ? (
            isCatagories ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (<div className={classes.mainWrapper}>
                <div>
                    <TestcatagoriesComponent />
                    <div className={classes.headerWrapper}>
                        <TitleTypography>
                            {!selectedInviteDataSlice?.isSelectedSocialUser
                                ? "All personality results"
                                : `My personality from ${selectedInviteDataSlice?.selectedinviteData?.name} view`}
                        </TitleTypography>
                        {matches ? (
                            <LongMenu
                                selectLayoutBtn={selectLayoutBtn}
                                setSelected={setSelected}
                            />
                        ) : (
                            <GridGraphComprison>
                                <GridButton
                                    variant={
                                        selectLayoutBtn === SELECTLAYOUTBTN.GRID
                                            ? "contained"
                                            : null
                                    }
                                    onClick={() => {
                                        setSelected(SELECTLAYOUTBTN.GRID);
                                    }}
                                >
                                    Grid
                                </GridButton>
                                <ComparisonButton
                                    variant={
                                        selectLayoutBtn === SELECTLAYOUTBTN.COMPRISON
                                            ? "contained"
                                            : null
                                    }
                                    onClick={() => {
                                        setSelected(SELECTLAYOUTBTN.COMPRISON);
                                    }}
                                >
                                    Comparison
                                </ComparisonButton>
                                <GraphButton
                                    variant={
                                        selectLayoutBtn === SELECTLAYOUTBTN.GRAPH
                                            ? "contained"
                                            : null
                                    }
                                    onClick={() => {
                                        setSelected(SELECTLAYOUTBTN.GRAPH);
                                    }}
                                >
                                    Graph
                                </GraphButton>
                            </GridGraphComprison>
                        )}
                    </div>
                </div>
                {/* graph view */}
                {selectLayoutBtn === SELECTLAYOUTBTN.GRAPH ? (
                    resultAllDiamention?.ResultDiamention.length ?
                        <Grid container spacing={1.5} className={classes.testListWrapper}>
                            {resultAllDiamention?.ResultDiamention.map((item, i) => {
                                const { data, questionCategory, questionCategoryId } = item;
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={4}
                                        xl={4}
                                        key={i}

                                        onClick={!item.unlock ? null : () =>
                                            modalOpenhandler(
                                                data,
                                                questionCategory,
                                                questionCategoryId
                                            )
                                        }
                                    >
                                        <div className={classes.personalityReslutItem}>
                                            <img
                                                src={
                                                    data[data.length - 1].url === "/public/images/"
                                                        ? Gift
                                                        : imageURLRender(data[data.length - 1].url)
                                                }
                                                alt=""
                                                height={'20'}
                                                width={'20'}
                                            />
                                            <Typography className={classes.personalityResultText} sx={{
                                                fontSize: '0.75rem !important',
                                                fontWeight: 400,
                                            }}>
                                                {questionCategory}
                                            </Typography>
                                            {
                                                <>
                                                    <TestQuestionSlider
                                                        sx={{ height: "14px", width: "14px", color: "" }}
                                                        className={classes.TestQuestionSlider}
                                                        defaultValue={30}
                                                        // getAriaValueText={valuetext}
                                                        valueLabelDisplay="off"
                                                        step={50}
                                                        marks
                                                        min={0}
                                                        max={100}
                                                        value={data[data.length - 1].percent}
                                                        // components={{ Input: SilderMarkComponent }}
                                                        onChange={(e) => { }}
                                                    />
                                                    <Typography
                                                        className={classes.personalityResultpercentage}
                                                        sx={{
                                                            fontSize: '0.75rem !important',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {data[data.length - 1].percent?.toFixed()}%
                                                    </Typography>
                                                </>}
                                        </div>


                                    </Grid>
                                );
                            })}
                        </Grid>
                        : <PersonalEmptyResult />
                ) : !isHandleCloseCall ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    resultAllDiamention?.ResultDiamention.length ? <Grid container spacing={1.5} className={classes.testListWrapper}>
                        {
                            resultAllDiamention?.ResultDiamention?.map((item, i) => {
                                const { data, questionCategory, questionCategoryId } = item;
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={4}
                                        xl={2}
                                        key={i}
                                        sx={{ cursor: "pointer" }}
                                        id={QuetionCatagoriesId}
                                        onClick={!item.unlock ? null : (event) => {
                                            modalOpenhandler(
                                                data,
                                                questionCategory,
                                                questionCategoryId
                                            )
                                        }}
                                    >
                                        <ResultCategoryCard
                                            category={questionCategory}
                                            preDescription={data[data.length - 1].description}
                                            percentage={data[data.length - 1].percent}
                                            selectLayoutBtn={selectLayoutBtn}
                                            score={item?.data?.map((item) => item.score)}
                                            imageUrl={data[data.length - 1].url}
                                            fetchTestCategories={fetchTestCategories}
                                            getDimentions={getDimentions}
                                            unlockCard={unlockCard}
                                            {...item}
                                        ></ResultCategoryCard>
                                    </Grid>
                                );
                            })
                            // )
                        }
                    </Grid>
                        : <PersonalEmptyResult />
                )}

            </div>
            )
        ) : (
            <ResultChart
                spacificPersonInviteId={spacificPersonInviteId}
                setSpacificPersonInviteId={setSpacificPersonInviteId}
                setspecificInviterName={setspecificInviterName}
                setTypeofResult={setTypeofResult}
            />
        )}
        {<StepperModal
            UnlockGAAction={UnlockGAAction}
            sendModalhandler={sendModalhandler}
            mainStateUpdateHandler={mainStateUpdateHandler}
            inaccurateHandler={inaccurateHandler}
            feedBackDataHandler={feedBackDataHandler}
            Notification={Notification}
            updateNotificationState={updateNotificationState} />}
    </>
}

export default memo(PersonalResultComponent)