import {
    Checkbox,
   
    Typography,
} from "@mui/material";
import classes from "./ResultLayout.module.css";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Coin from "../../assets/Svg/Coin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import XP from "../../assets/Svg/XP.svg";
import Share from "../../assets/Svg/Share.svg";
import { imageURLRender } from "../../utils";
import { updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import { useDispatch, useSelector } from "react-redux";
const label = { inputProps: { "aria-label": "Checkbox" } };


const ResultImage = (props) => {
    const {
        state,
        setCustomNotification,
        setToastMessage
    } = props
    const { resultAllDiamention } = useSelector(
        (state) => state
    );
    const {
        
        isTestBookMark,
        
    } = resultAllDiamention
    const dispatch = useDispatch();
    const {
       
        coins,
        points,
        time,
        total_questions,
        
    } = state;
    const setIsTestBookMark = val => dispatch(updateResultState({
        isTestBookMark: val
    }))
    console.log(" image re rendered");
    return <div className={classes.imageWrapper}>
        <div className={classes.Imageoverlay}>

            <img src={imageURLRender(state?.imageUrl)} alt="" />
        </div>
        <div className={classes.iconsWrapper}>
            <div className={classes.iconsLeftWrapper}>
                <div className={classes.goldCoinIconWrapper}>
                    <img src={Coin} alt="" />
                    <Typography variant="body3" color="#9747FF">
                        {coins}
                    </Typography>
                </div>
                <div
                    className={`${classes.goldCoinIconWrapper} ${classes.ml4}`}
                >
                    <img src={DiamondCoin} alt="" />
                    <Typography variant="body3" color="#9747FF">
                        {points}
                    </Typography>
                </div>
                <div className={`${classes.timeIcon} ${classes.ml4}`} style={{ display: 'flex', gap: '4px' }}>
                    <img src={XP} alt="" style={{
                        height: '12px',
                        width: '12px',
                    }} />
                    <Typography variant="body3" color="#9747FF">
                        {total_questions}
                    </Typography>
                </div>
                <div className={`${classes.timeIcon} ${classes.ml4}`}>
                    <Typography variant="body3" color="#9747FF">
                        {time} min
                    </Typography>
                </div>
                <div className={`${classes.questionIcon} ${classes.ml4}`}>
                    <Typography variant="body3" color="#9747FF">
                        {total_questions} questions
                    </Typography>
                </div>
            </div>
            <div className={classes.iconsRightWrapper}>
                <div className={classes.bookMarkIcon}>
                    {/* <img src={Bookmark} alt="" /> */}
                    <Checkbox
                        {...label}
                        disableRipple
                        sx={{ pl: "0", width: "20px", height: "20px" }}
                        checked={isTestBookMark}
                        onChange={(e) => setIsTestBookMark(e.target.checked)}
                        icon={
                            <img
                                src={Bookmark}
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    padding: "0px",
                                }}
                                alt=""
                            ></img>
                        }
                        checkedIcon={
                            <img
                                src={BookmarkActive}
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    padding: "0px",
                                }}
                                alt=""
                            ></img>
                        }
                    />
                </div>
                <div
                    className={classes.shareIcon}
                    onClick={() => {
                        setCustomNotification(true);
                        setToastMessage({
                            message: "Link copied to clipboard",
                            isThumbIcon: true,
                            position: "bottom",
                        });
                    }}
                >
                    <img
                        src={Share}
                        alt=""
                        style={{
                            width: "20px",
                            height: "20px",
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default ResultImage