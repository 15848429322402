import React, { useState } from 'react'
import styled from '@emotion/styled';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { PROFILEINFO_TYPE } from '../../../Constant/SettingConstant';
import { ProfileInfoButtonWrapper } from '../StyledElement/StyledDiv';
import LeftArrowIcom from '../../../assets/Svg/LeftArrowIcon.svg'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import classes from "./profileHeader.module.css";
import { useNavigate } from 'react-router-dom';

function ProfileHeader({setPersonalAccountInfo}) {
  const [selectedImage, setSelectedImage] = useState(null);
const navigate = useNavigate()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const [value, setValue] = React.useState(0);
    const handleChange=(event,newValue)=>{
      if(newValue===0){
        setPersonalAccountInfo(PROFILEINFO_TYPE.ACCOUNT_INFO)
      }
      else{
        setPersonalAccountInfo(PROFILEINFO_TYPE.PERSONAL_INFO)

      }
        setValue(newValue)
    }

    const backToSideNavigate = () =>{
      navigate("/settings/settingnavigationbar");
    }
  return (
    <>
      {matches ? (
        <Typography variant="h5">
          <img className={classes.backbutton}
          onClick={backToSideNavigate}
            alt="..."
            src={
              selectedImage ? URL.createObjectURL(selectedImage) : LeftArrowIcom
            }
          />
          Profile
        </Typography>
      ) : (
        <Typography variant="h5"> Profile</Typography>
      )}
      <ProfileInfoButtonWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={{ fontSize: "16px !important" }} label="Account info" />
          <Tab sx={{ fontSize: "16px !important" }} label="Personal info" />
        </Tabs>
      </ProfileInfoButtonWrapper>
    </>
  );
}
export default ProfileHeader