import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { tab } from "@testing-library/user-event/dist/tab";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editPreSelectedGrowth,
  getGrowthData,
  goalUpdateHabitAction,
  growthCalenderCreate,
  growthCalenderGetCalenderData,
  updateHabitActionCalender,
} from "../../../services/auth";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../../Store/Reducers/goalGrowthCalendar";
import classes from "./MonthlyCalendar.module.css";
import {
  DropActionSnakebar,
  DropGrowthSnakebar,
  DropHabitSnakebar,
} from "../../GoalComponent/GoalDialog";
import DropModal from "../DropModal/DropModal";
import {
  storeGetActionData,
  storeGetCalendarData,
  storeGetHabitData,
  updateIsGetCalenderData,
} from "../../../Store/Reducers/getCalendarData";
import useCustomNotification from "../../../Hooks/useCustomNotification";
import useCustomNotificationActionHabit from "../../../Hooks/useCustomNotiFicationActionHabit";
import useCustomNotificationColourPick from "../../../Hooks/useCustomNotificationColourPick";
import SetDurationActionModal from "../SetDurationAction/SetDurationActionModal";
import GrowthArea from "../GrowthArea/GrowthArea";
import GrowthAction from "../GrowthAction/GrowthAction";
import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import UnspecifiedActionHabit from "../UnspecifiedActionHabit/UnspecifiedActionHabit";
import SetDurationHabitModal from "../SetDurationHabitModal/SetDurationHabitModal";
import GrowthHabit from "../GrowthHabit/GrowthHabit";
import { setDahData } from "../../../Store/Reducers/dashboard";
import { updateUserCions } from "../../../Store/Reducers/userProfileSlice";
import { FindAndGetXPLevel } from "../../../utils";

const MonthlyCaledar = ({
  monthObject,
  dateObject,
  bgcolor,
  setBgColor,
  getGrowthCalendarDate,
  setGetGrowthCalendarDate,
  setCalendarData,
  calendarData,
  getGrowthd,
  getCalendars
}) => {
  const { getAllGrowthData, getCalenderDataSlice, userProfile: { userData = {}} } = useSelector(
    (state) => state
  );
  const disapatch = useDispatch();
  const daysInMonth = () => {
    return dateObject.daysInMonth();
  };
  const [tableCellId, setTableCellId] = useState("");
  const [isDropGrowthAreaOpen, setisDropGrowthAreaOpen] = useState(false);
  const [colorPickData, setColorPickData] = useState({});
  const [actionData, setActionData] = useState({});
  const [habitData, setHabitData] = useState({});
  const [createdData, setCreatedData] = useState({});
  const [updateColourPickData, setUpdateColourPickData] = useState({});
  const [editGrowthArea, setEditGrowthArea] = useState({});
  const [editActionHabit, setEditActionHabit] = useState({});
  const [isSetDurationAction, setIsSetDurationAction] = useState(false);
  const [isSetDurationHabit, setIsSetDurationHabit] = useState(false);
  const [actionSliceData, setActionSliceData] = useState({});
  const [habitSliceData, setHabitSliceData] = useState({});
  const [updateAction, setUpdateAction] = useState({});
  const [updateHabit, setUpdateHabit] = useState({});
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const {
    NotificationContentColourPickJSX,
    customNotificationColourPick,
    setCustomNotificationColourPick,
  } = useCustomNotificationColourPick();
  const {
    NotificationContentActionHabitJSX,
    customNotificationActionHabit,
    setCustomNotificationActionHabit,
  } = useCustomNotificationActionHabit();
  const [gsMessage, setGAMessage] = useState("")

  const UK_TIME = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  let blanks = [];
  let dayInMonth = [];
  for (let d = 1; d <= daysInMonth(); d++) {
    if (d < 10) {
      dayInMonth.push("0" + d);
    } else {
      dayInMonth.push(d.toString());
    }
  }
  var totalSlots = [...blanks, ...dayInMonth];
  let rows = [];
  let cells = [];
  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      rows.push(cells);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      rows.push(cells);
    }
  });

  useEffect(() => {
    const startDate = moment(monthObject).format("MM-DD-yyyy");
    var theDate = new Date(startDate);
    let fromDate = new Date(theDate);
    fromDate.setDate(fromDate.getDate() - 13);
    const endDate = moment(new Date(`${daysInMonth()}-${monthObject}`)).format(
      "MM-DD-yyyy"
    );
    var theDate = new Date(endDate);
    let toDate = new Date(theDate);
    toDate.setDate(toDate.getDate() + 13);
    setGetGrowthCalendarDate({
      fromDate: moment(fromDate).format("MM-DD-yyyy"),
      toDate: moment(new Date(toDate)).format("MM-DD-yyyy"),
    });
  }, [dateObject]);

  const dragStart = (e, ele) => {
    let element = e.currentTarget;
    element.classList.add("dragged");
    e.dataTransfer.setData("text/plain", JSON.stringify(ele));
    e.dataTransfer.effectAllowed = "move";
  };

  const dragEnter = (e, position) => {
    e.preventDefault();
    let element = e.currentTarget;
    element.classList.add("dragged-over");
    e.dataTransfer.dropEffect = "move";
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const drop = (e, value, status) => {
    if (calendarData) {
      setCalendarData({});
    }
    if (Object.keys(actionSliceData).length && !isSetDurationAction) {
      setActionSliceData({});
    }
    if (updateAction) {
      setUpdateAction({});
    }
    if (Object.keys(createdData).length) {
      setCreatedData({});
    }
    let dateId = e.target.id;
    setTableCellId(dateId);
    const date = dateId.split(" ");
    const startDate = date[0] + date[1];
    var theDate = new Date(startDate);
    let endDate = new Date(theDate);
    endDate.setDate(endDate.getDate() + 13);

    e.preventDefault();
    let text = e.dataTransfer.getData("text/plain");
    e.target.classList.remove("dragged-over");
    const jsonData = JSON.parse(text);
    console.log("jsonData", jsonData);
    if (jsonData.type === "GA") {
      const editPreSelectedGrowthData = {
        uid: localStorage.getItem("userDetail"),
        goalId: jsonData.id,
        preSelectedGrowth: jsonData.preSelectedGrowth.map((item) => {
          if (item.text === jsonData.text) {
            return { ...item, active: "false" };
          }
          return item;
        }),
        preSelectedGrowthComplete: jsonData.preSelectedGrowthComplete,
      };
      setEditGrowthArea(editPreSelectedGrowthData);
    } else if (jsonData.type === "ACTION") {
      const updateActionHabit = {
        uid: localStorage.getItem("userDetail"),
        id: jsonData.id,
        type: jsonData.type,
        action: jsonData.actions.map((item) => {
          if (item.actionName === jsonData.text) {
            return { ...item, active: "false" };
          }
          return item;
        }),
      };
      setEditActionHabit(updateActionHabit);
    } else if (jsonData.type === "HABIT_DRAGGED") {
      const updateActionHabit = {
        uid: localStorage.getItem("userDetail"),
        id: jsonData.id,
        type: jsonData.type,
        habit: jsonData.habits.map((item) => {
          if (item.habitName === jsonData.text) {
            return { ...item, active: "false" };
          }
          return item;
        }),
      };
      setEditActionHabit(updateActionHabit);
    }
    function dateRange(startDate, endDate, steps = 1) {
      const dateArray = [];
      let currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        dateArray.push({
          date: moment(new Date(currentDate)).format("DD-MMMM, yyyy"),
        });
        currentDate.setUTCDate(currentDate.getUTCDate() + steps);
      }
      return dateArray;
    }
    const dates = dateRange(date[0] + " " + date[1], endDate);
    const isColorasign = getCalenderDataSlice?.getCalenderData.find(
      (item) => item.questionCategory === jsonData.name
    );

    let temp = [];
    getCalenderDataSlice?.getCalenderData?.map((item) => {
      if (
        moment(new Date(item.startDate)).format("DD-MMMM,yyyy") === startDate
      ) {
        temp.push(item);
      } else {
        item.dates.map((ele) => {
          if (moment(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate) {
            temp.push(item);
          }
        });
      }
    });
    if (jsonData.type === "GA") {
      if (moment(startDate, "DD-MMMM,yyyy").startOf('day').add(1, "hour").isBefore(moment().startOf('day'))) {
        setGAMessage('Can not drop GA on previous dates')
        setCustomNotification(true);
        return
      }
      console.log("startDate", startDate);
      let tempDropedData = {
        uid: calendarData?.uid,
        category: calendarData?.category,
        subCategory: calendarData?.subCategory,
        subCategory_id: calendarData.subCategory_id,
        questionCategoryId: jsonData.questionCategoryId,
        questionCategory: jsonData.name,
        startDate: startDate,
        endDate: endDate,
        colour: jsonData.colour,
        textColour: jsonData.textColour,
        progress: "IN_PROGRESS",
        name: jsonData.text,
        type: jsonData.type,
        dates: dates,
        _id: calendarData._id,
        createdAt: calendarData.createdAt,
        updatedAt: calendarData.updatedAt,
        data: {
          url: jsonData?.cat && jsonData?.cat?.url ? jsonData?.cat?.url : undefined
        }
      };
      if (!temp.length) {
        let tempGrowth = [];
        getCalenderDataSlice.getCalenderData.map((item, index) => {
          item.dates.map((dateRange) => {
            dates.map((dateIndex) => {
              if (
                moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                dateIndex.date
              ) {
                return tempGrowth.push(item);
              }
            });
          });
        });
        const unique2 = tempGrowth.filter((obj, index) => {
          return index === tempGrowth.findIndex((o) => obj._id === o._id);
        });
        if (unique2.length >= 2) {
          setGAMessage("Maximum limit reached! You can't add new growth area for this time frame.")
          setCustomNotification(true);
          setisDropGrowthAreaOpen(false);
        }
        if (temp.length < 2 && unique2.length < 2) {
          setCalendarData({});
          disapatch(
            storeGetCalendarData([
              ...getCalenderDataSlice.getCalenderData,
              tempDropedData,
            ])
          );
        }
      }
    }
    if (!temp.length && jsonData.type === "ACTION") {
      setCustomNotificationActionHabit(true);
    } else if (jsonData.type === "ACTION") {
      console.log("actionSliceData", actionSliceData);
      let tempDropedData = {
        uid: localStorage.getItem("userDetail"),
        actionDetail: actionSliceData?.actionDetail,
        actionId: actionSliceData?.actionId,
        actionName: jsonData?.text,
        active: actionSliceData?.active,
        archive: actionSliceData?.archive,
        calenderStatus: actionSliceData?.calenderStatus,
        category: actionSliceData?.category,
        category_id: actionSliceData?.category_id,
        executionDate: date[0] + " " + date[1],
        executionDateEnd: actionSliceData?.executionDateEnd,
        scheduled_type: actionSliceData.scheduled_type,
        status: "IN_PROGRESS",
        traits: [],
        subCategory: actionSliceData?.subCategory,
        subCategory_id: actionSliceData.subCategory_id,
        questionCategoryId: jsonData.questionCategoryId,
        questionCategory: jsonData.name,
        colour: isColorasign ? isColorasign.colour : jsonData.colour,
        textColour: isColorasign
          ? isColorasign.textColour
          : jsonData.textColour,
        _id: actionSliceData._id,
        createdAt: actionSliceData.createdAt,
        updatedAt: actionSliceData.updatedAt,
      };
      disapatch(
        storeGetActionData([
          ...getCalenderDataSlice?.getActionData,
          tempDropedData,
        ])
      );
      setCalendarData({});
      const findDimantion1 = temp[0];
      const findDimantion2 = temp[1];
      if (
        findDimantion1.questionCategoryId === jsonData.questionCategoryId &&
        temp.length === 1
      ) {
        findDimantion1.dates.map((ele) => {
          if (
            moment.utc(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate
          ) {
            setIsSetDurationAction(true);
            setActionData({
              ...jsonData,
              ...{
                executionDate: startDate,
                colour: isColorasign.colour,
                textColour: isColorasign.textColour,
              },
            });
            disapatch(
              storeGetActionData([
                ...getCalenderDataSlice?.getActionData,
                tempDropedData,
              ])
            );
          }
        });
      } else {
        if (
          findDimantion2 && findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
          temp.length === 2
        ) {
          setCustomNotificationActionHabit(false);
        } else if (temp.length !== 1) {
          setCustomNotificationActionHabit(true);
        }
      }
      if (
        findDimantion2 && findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
        temp.length === 2
      ) {
        findDimantion2.dates.map((ele) => {
          if (moment(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate) {
            setIsSetDurationAction(true);
            setActionData({
              ...jsonData,
              ...{
                executionDate: startDate,
                colour: isColorasign.colour,
                textColour: isColorasign.textColour,
              },
            });
            disapatch(
              storeGetActionData([
                ...getCalenderDataSlice?.getActionData,
                tempDropedData,
              ])
            );
          }
        });
      } else if (temp.length !== 1) {
        setCustomNotificationActionHabit(true);
      }
    }

    if (!temp.length && jsonData.type === "HABIT_DRAGGED") {
      setCustomNotificationActionHabit(true);
    } else if (jsonData.type === "HABIT_DRAGGED") {
      let tempDropedData = {
        uid: localStorage.getItem("userDetail"),
        actionDetail: actionSliceData?.actionDetail,
        habitId: actionSliceData?.actionId,
        habitName: jsonData?.text,
        active: actionSliceData?.active,
        archive: actionSliceData?.archive,
        calenderStatus: actionSliceData?.calenderStatus,
        category: actionSliceData?.category,
        category_id: actionSliceData?.category_id,
        executionDate: date[0] + " " + date[1],
        executionDateEnd: actionSliceData?.executionDateEnd,
        scheduled_type: actionSliceData.scheduled_type,
        status: "IN_PROGRESS",
        traits: [],
        subCategory: actionSliceData?.subCategory,
        subCategory_id: actionSliceData.subCategory_id,
        questionCategoryId: jsonData.questionCategoryId,
        questionCategory: jsonData.name,
        colour: isColorasign ? isColorasign.colour : jsonData.colour,
        textColour: isColorasign
          ? isColorasign.textColour
          : jsonData.textColour,
        _id: actionSliceData._id,
        createdAt: actionSliceData.createdAt,
        updatedAt: actionSliceData.updatedAt,
      };
      // disapatch(
      //   storeGetActionData([
      //     ...getCalenderDataSlice?.getActionData,
      //     tempDropedData,
      //   ])
      // );
      setCalendarData({});
      const findDimantion1 = temp[0];
      const findDimantion2 = temp[1];
      console.log("temp", temp);
      if (
        findDimantion1.questionCategoryId === jsonData.questionCategoryId &&
        temp.length === 1
      ) {
        findDimantion1.dates.map((ele) => {
          if (
            moment.utc(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate
          ) {
            // setIsSetDurationAction(true);
            // setActionData({

            // });
            updateHabitData({
              ...jsonData,
              ...{
                executionDate: startDate,
                colour: isColorasign.colour,
                textColour: isColorasign.textColour,
                "gaStart": moment(temp[0].startDate).format("MM-DD-YYYY"),
                "gaEnd": moment(temp[0].endDate).format("MM-DD-YYYY"),
              },
            });

            // disapatch(
            //   storeGetActionData([
            //     ...getCalenderDataSlice?.getActionData,
            //     tempDropedData,
            //   ])
            // );
          }
        });
      } else {
        if (
          findDimantion2 && findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
          temp.length === 2
        ) {
          setCustomNotificationActionHabit(false);
        } else if (temp.length !== 1) {
          setCustomNotificationActionHabit(true);
        }
      }
      if (
        findDimantion2 && findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
        temp.length === 2
      ) {
        findDimantion2.dates.map((ele) => {
          if (moment(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate) {
            // setIsSetDurationAction(true);
            // setActionData({
            //   ...jsonData,
            //   ...{
            //     executionDate: startDate,
            //     colour: isColorasign.colour,
            //     textColour: isColorasign.textColour,
            //   },
            // });
            updateHabitData({
              ...jsonData,
              ...{
                executionDate: startDate,
                colour: isColorasign.colour,
                textColour: isColorasign.textColour,
                "gaStart": moment(temp[0].startDate).format("MM-DD-YYYY"),
                "gaEnd": moment(temp[0].endDate).format("MM-DD-YYYY"),
              },
            });
            // disapatch(
            //   storeGetActionData([
            //     ...getCalenderDataSlice?.getActionData,
            //     tempDropedData,
            //   ])
            // );
          }
        });
      } else if (temp.length !== 1) {
        setCustomNotificationActionHabit(true);
      }
    }

    if (jsonData.type === "GA") {
      if (!temp.length) {
        let tempGrowth = [];
        getCalenderDataSlice.getCalenderData.map((item, index) => {
          item.dates.map((dateRange) => {
            dates.map((dateIndex) => {
              if (
                moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                dateIndex.date
              ) {
                return tempGrowth.push(item);
              }
            });
          });
        });
        const unique2 = tempGrowth.filter((obj, index) => {
          return index === tempGrowth.findIndex((o) => obj._id === o._id);
        });
        if (unique2.length >= 2) {
          setGAMessage("Maximum limit reached! You can't add new growth area for this time frame.")
          setCustomNotification(true);
          setisDropGrowthAreaOpen(false);
        } else {
          const updateData = jsonData.preSelectedGrowth.map((ele) => {
            if (ele.text === jsonData.text) {
              return { ...ele, active: "false" };
            }
            return ele;
          });
          const tempGetAllGrowthData = [];
          getAllGrowthData?.GetGrowthData?.map((data) => {
            tempGetAllGrowthData.push({
              actions: data.actions,
              actionsArchived: data.actionsArchived,
              actionsComplete: data.actionsComplete,
              archive: data.archive,
              habits: data.habits,
              habitsArchived: data.habitsArchived,
              habitsComplete: data.habitsComplete,
              increase: data.increase,
              mySelf: data.mySelf,
              others: data.others,
              preSelectedGrowth:
                data.questionCategoryId === jsonData.questionCategoryId
                  ? updateData
                  : jsonData.preSelectedGrowth,
              preSelectedGrowthComplete: data.preSelectedGrowthComplete,
              questionCategory: data.questionCategory,
              questionCategoryId: data.questionCategoryId,
              status: data.status,
              tbd: data.tbd,
              uid: data.uid,
              url: data.url,
              _id: data._id,
            });
            return data;
          });
          disapatch(storeGetGrowthData(tempGetAllGrowthData));
          setisDropGrowthAreaOpen(true);
        }
      } else if (temp.length < 2) {
        if (temp.length == 1) {
          let tempGrowth = [];
          getCalenderDataSlice.getCalenderData.map((item, index) => {
            item.dates.map((dateRange) => {
              dates.map((dateIndex) => {
                if (
                  moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                  dateIndex.date
                ) {
                  return tempGrowth.push(item);
                }
              });
            });
          });
          const unique2 = tempGrowth.filter((obj, index) => {
            return index === tempGrowth.findIndex((o) => obj._id === o._id);
          });
          if (unique2.length >= 2) {
            setGAMessage("Maximum limit reached! You can't add new growth area for this time frame.")
            setCustomNotification(true);
            setisDropGrowthAreaOpen(false);
          }
          if (unique2.length < 2) {
            temp.map((ele) => {
              if (ele.questionCategoryId === jsonData.questionCategoryId) {
                const createData = {
                  uid: localStorage.getItem("userDetail"),
                  questionCategoryId: jsonData.questionCategoryId,
                  name: jsonData.text,
                  type: jsonData.type,
                  colour: isColorasign && ele.colour,
                  textColour: isColorasign && ele.textColour,
                  startDate: startDate,
                  endDate: endDate,
                };
                // setCalendarData("");
                setCreatedData(createData);
                setisDropGrowthAreaOpen(false);
              } else {
                setisDropGrowthAreaOpen(true);
              }
            });
          }
        }
      } else {
        setGAMessage("Maximum limit reached! You can't add new growth area for this time frame.")
        setCustomNotification(true);
      }
    }
    setColorPickData({
      ...jsonData,
      ...{ startDate: startDate, endDate: endDate },
    });
  };
  //API call
  useEffect(() => {
    if (Object.keys(createdData).length) {
      growthCalenderCreate(createdData)
        .then((res) => {
          setCreatedData("");
          const getgrowthCalenderData = {
            uid: localStorage.getItem("userDetail"),
            from: getGrowthCalendarDate.fromDate,
            to: getGrowthCalendarDate.toDate,
          };
          if (getGrowthCalendarDate.fromDate) {
            growthCalenderGetCalenderData(getgrowthCalenderData)
              .then((res) => {
                disapatch(storeGetCalendarData(res?.data));
                disapatch(storeGetActionData(res?.actionData));
                disapatch(storeGetHabitData(res?.habitCalenderData));
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {
                disapatch(updateIsGetCalenderData(true));
              });
          }
          const temp = getCalenderDataSlice.getCalenderData.map((item) => {
            if (item.name === createdData.name) {
              return {
                ...item,
                uid: res?.growthdata?.uid,
                category: res?.growthdata?.category,
                subCategory: res?.growthdata?.subCategory,
                subCategory_id: res?.growthdata?.subCategory_id,
                questionCategoryId: res?.growthdata?.questionCategoryId,
                questionCategory: res?.growthdata?.questionCategory,
                startDate: res?.growthdata?.startDate,
                endDate: res?.growthdata?.endDate,
                colour: res?.growthdata?.colour,
                textColour: res?.growthdata?.textColour,
                progress: res?.growthdata?.progress,
                name: res?.growthdata?.text,
                type: res?.growthData?.type,
                dates: res?.growthdata?.dates,
                _id: res?.growthdata?._id,
                createdAt: res?.growthdata?.createdAt,
                updatedAt: res?.growthdata?.updatedAt,
              };
            }
            return item;
          });
          disapatch(storeGetCalendarData(temp));
          disapatch(storeGetActionData(getCalenderDataSlice?.getActionData));
          let growthData = {
            uid: localStorage.getItem("userDetail"),
            archive: false,
            status: "IN_PROGRESS",
          };
          getGrowthData(growthData)
            .then((res) => {
              disapatch(storeGetGrowthData(res?.data));
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              disapatch(updateIsGetGrowthData(true));
            });
          if (getAllGrowthData.GetGrowthData) {
            getAllGrowthData.GetGrowthData.map((item) => {
              if (item.questionCategoryId === createdData.questionCategoryId) {
                disapatch(storeGetActionData(item.actions));
              }
            });
          }
          if (editGrowthArea) {
            editPreSelectedGrowth(editGrowthArea)
              .then((res) => {
                console.log("res = ", res);
              })
              .catch((error) => {
                console.log("error = ", error);
              });
          } else if (editActionHabit) {
            goalUpdateHabitAction(editActionHabit)
              .then((res) => {
                console.log("res = ", res);
              })
              .catch((error) => {
                console.log("error = ", error);
              });
          }
          setisDropGrowthAreaOpen(false);
        })
        .catch((err) => {
          console.log("error = ", err);
        });
    }
  }, [createdData]);

  const updateProfileXp = (xp, add) => {
    const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
    disapatch(updateUserCions(updatedXp))
  }
  useEffect(() => {
    // let tempAction = [];
    if (Object.keys(actionSliceData).length) {
      let tempAction = getCalenderDataSlice?.getActionData?.map((item) => {
        if (item._id === undefined || item._id === null || item._id === "") {
          return {
            ...item,
            actionDetail: actionSliceData.actionDetail,
            actionId: actionSliceData.actionId,
            actionName: actionSliceData.actionName,
            active: "false",
            archive: false,
            calenderStatus: "IN_PROGRESS",
            category: actionSliceData.category,
            category_id: actionSliceData.category_id,
            colour: item.colour,
            createdAt: actionSliceData.createdAt,
            executionDate: actionSliceData.executionDate,
            executionDateEnd: actionSliceData.executionDateEnd,
            questionCategory: actionSliceData.questionCategory,
            questionCategoryId: actionSliceData.questionCategoryId,
            scheduled_type: actionSliceData.scheduled_type,
            status: actionSliceData.status,
            subCategory: actionSliceData.subCategory,
            subCategory_id: actionSliceData.subCategory_id,
            textColour: item.textColour,
            traits: [],
            uid: localStorage.getItem("userDetail"),
            updatedAt: actionSliceData.updatedAt,
            _id: actionSliceData._id,
          };
        }
        return item;
      });
      disapatch(storeGetActionData(tempAction));
      if (tempAction) {
        setActionSliceData({});
      }
    }
  }, [actionSliceData]);

  const handleClose = () => {
    if (Object.keys(updateColourPickData).length && updateColourPickData._id) {
      setisDropGrowthAreaOpen(false);
      setUpdateColourPickData("");
    }
  };
  if (getAllGrowthData.GetGrowthData) {
    getAllGrowthData.GetGrowthData.map((item) => {
      if (item.questionCategoryId === createdData.questionCategoryId) {
        disapatch(storeGetActionData(item.actions));
      }
    });
  }
  const handleIsTrue = (action) => {
    console.log("action", action);
    if (action.actionName) {
      let createActionData;
      const tempActionIsTrueFalse = getCalenderDataSlice?.getActionData.map(
        (item) => {
          if (item.actionId === action.actionId) {
            if (item.calenderStatus === "IN_PROGRESS") {
              setIsSetDurationAction(false);
              createActionData = {
                uid: localStorage.getItem("userDetail"),
                id: action.actionId,
                type: "ACTION",
                xpChange: "INCREASE",
                action: {
                  scheduled_type: action.scheduled_type,
                  active: "false",
                  colour: action.colour,
                  textColour: action.textColour,
                  executionDate: action.executionDate,
                  executionDateEnd: action.executionDateEnd,
                  calenderStatus: "COMPLETE",
                  status: "COMPLETE",
                  xpChange: "INCREASE"
                },
              };
              return { ...item, calenderStatus: "COMPLETE" };
            } else {
              setIsSetDurationAction(false);
              createActionData = {
                uid: localStorage.getItem("userDetail"),
                id: action.actionId,
                type: "ACTION",
                action: {
                  scheduled_type: action.scheduled_type,
                  active: "false",
                  colour: action.colour,
                  textColour: action.textColour,
                  executionDate: action.executionDate,
                  executionDateEnd: action.executionDateEnd,
                  calenderStatus: "IN_PROGRESS",
                  status: "IN_PROGRESS",
                  xpChange: "DECREASE"
                },
                xpChange: "DECREASE"
              };
              return { ...item, calenderStatus: "IN_PROGRESS" };
            }
          }
          return item;
        }
      );
      disapatch(storeGetActionData(tempActionIsTrueFalse));
      if (Object.keys(createActionData).length) {
        updateHabitActionCalender(createActionData)
          .then((res) => {
            setIsSetDurationAction(false);
            const xp = FindAndGetXPLevel(action.level, false)
            updateProfileXp(xp, createActionData.action.status !== "IN_PROGRESS")
            console.log("res = ", res);
          })
          .catch((err) => {
            console.log("err = ", err);
          });
      }
    } else {
      let createHabitData;
      const tempActionIsTrueFalse = getCalenderDataSlice?.getHabitData.map(
        (item) => {
          if (item._id === action._id) {
            if (item.calenderStatus === "IN_PROGRESS") {
              setIsSetDurationHabit(false);
              createHabitData = {
                uid: localStorage.getItem("userDetail"),
                id: action._id,
                type: "HABIT",
                habit: {
                  scheduled_type: action.scheduled_type,
                  executionDate: action.executionDate,
                  executionDateEnd: action.executionDateEnd,
                  calenderStatus: "COMPLETE",
                  status: "COMPLETE",
                  xpChange: "INCREASE"

                },
                xpChange: "INCREASE"
              };
              return { ...item, calenderStatus: "COMPLETE" };
            } else {
              setIsSetDurationHabit(false);
              createHabitData = {
                uid: localStorage.getItem("userDetail"),
                id: action._id,
                type: "HABIT",
                habit: {
                  scheduled_type: action.scheduled_type,
                  executionDate: action.executionDate,
                  executionDateEnd: action.executionDateEnd,
                  calenderStatus: "IN_PROGRESS",
                  status: "IN_PROGRESS",
                  xpChange: "DECREASE"

                },
                xpChange: "DECREASE"
              };
              return { ...item, calenderStatus: "IN_PROGRESS" };
            }
          }
          return item;
        }
      );
      disapatch(storeGetHabitData(tempActionIsTrueFalse));
      if (Object.keys(createHabitData).length) {
        updateHabitActionCalender(createHabitData)
          .then((res) => {
            setIsSetDurationHabit(false);
            const xp = FindAndGetXPLevel(action.level, false)
            updateProfileXp(xp, createHabitData.habit.status !== "IN_PROGRESS")
            console.log("res = ", res);
          })
          .catch((err) => {
            console.log("err = ", err);
          });
      }
    }
  };

  const updateData = useCallback(
    (ele, e) => {
      setUpdateColourPickData(ele);
      setisDropGrowthAreaOpen(true);
      setTableCellId(e.target.id);
    },
    [updateColourPickData]
  );
  const updateActionData = useCallback(
    (action) => {
      setIsSetDurationAction(true);
      setActionData({});
      setUpdateAction(action);
    },
    [updateAction]
  );

  const updateHabitData = useCallback(
    (habit) => {
      setIsSetDurationHabit(true);
      setUpdateHabit(habit);
    },
    [updateHabit]
  );

  const tempGetHabitData = [];
  rows?.map((d, index) => {
    d.map((date) => {
      getCalenderDataSlice?.getHabitData.map((ele) => {
        if (
          `${date}-${monthObject}` ===
          moment.utc(new Date(ele.calenderDate)).format("DD-MMMM, yyyy")
        ) {
          return tempGetHabitData.push(ele);
        }
      });
    });
  });
  const tempAction1 = [];
  const tempAction2 = [];
  const tempAction3 = [];
  const tempAction4 = [];
  const tempAction5 = [];
  const tempAction6 = [];
  const tempAction7 = [];
  rows?.map((d, index) => {
    getCalenderDataSlice?.getActionData.map((ele) => {
      if (moment.utc(new Date(ele.executionDate)).format("DD") === d[0]) {
        tempAction1.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[1]
      ) {
        tempAction2.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[2]
      ) {
        tempAction3.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[3]
      ) {
        tempAction4.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[4]
      ) {
        tempAction5.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[5]
      ) {
        tempAction6.push(ele);
      } else if (
        moment.utc(new Date(ele.executionDate)).format("DD") === d[6]
      ) {
        tempAction7.push(ele);
      }
    });
    tempGetHabitData.map((ele) => {
      if (moment(new Date(ele.calenderDate)).format("DD") === d[0]) {
        tempAction1.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[1]) {
        tempAction2.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[2]) {
        tempAction3.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[3]) {
        tempAction4.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[4]) {
        tempAction5.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[5]) {
        tempAction6.push(ele);
      } else if (moment(new Date(ele.calenderDate)).format("DD") === d[6]) {
        tempAction7.push(ele);
      }
    });
  });
  console.log("isSetDurationHabit", isSetDurationHabit);
  return (
    <>
      {!getCalenderDataSlice?.isGetCalenderData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            height:
              getCalenderDataSlice?.getActionData?.length ||
                getCalenderDataSlice?.getHabitData?.length
                ? "1575px"
                : "980px",
          }}
        >
          <div>
            <Table>
              <TableBody
                onDragOver={(e) => dragOver(e)}
                onDrop={(e) => drop(e, false, "false")}
                onClick={handleClose}
              >
                {rows?.map((d, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => {
                        if (
                          Object.keys(updateAction).length &&
                          updateAction._id
                        ) {
                          setIsSetDurationAction(false);
                          setUpdateAction({});
                        } else if (
                          Object.keys(updateHabit).length &&
                          updateHabit._id
                        ) {
                          setIsSetDurationHabit(false);
                          setUpdateHabit({});
                        }
                      }}
                    >
                      {d?.map((x, dateIndex) => {
                        return (
                          <TableCell
                            className={classes.first_cell}
                            sx={{
                              "& .MuiTableCell-root": {
                                padding: "0px",
                              },
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "#f3f3f3",
                              width: "163px",
                              position: "relative",
                            }}
                            key={dateIndex}
                            id={`${x}-${monthObject} ${moment(UK_TIME).format(
                              "h a"
                            )} ${index}`}
                          >
                            <span style={{ padding: "16px" }}>{x}</span>
                            <GrowthArea
                              x={x}
                              monthObject={monthObject}
                              updateData={updateData}
                              drop={drop}
                              dragOver={dragOver}
                              colorPickData={colorPickData}
                              daysInMonth={daysInMonth}
                              getCalenderDataSlice={getCalenderDataSlice}
                              index={index}
                            />
                            <div
                              style={{
                                height: "120px",
                                padding: "12px",
                                overflowY: "scroll",
                              }}
                              className={classes.action_box}
                              id={`${x}-${monthObject} ${moment(UK_TIME).format(
                                "h a"
                              )}`}
                            >
                              <GrowthAction
                                x={x}
                                monthObject={monthObject}
                                handleIsTrue={handleIsTrue}
                                updateActionData={updateActionData}
                                getCalenderDataSlice={getCalenderDataSlice}
                                updateHabitData={updateHabitData}
                              />
                              <GrowthHabit
                                x={x}
                                monthObject={monthObject}
                                handleIsTrue={handleIsTrue}
                                updateActionData={updateActionData}
                                getCalenderDataSlice={getCalenderDataSlice}
                                updateHabitData={updateHabitData}
                              />
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className={classes.unspecified}>
            <div className={classes.unspecified_text}>
              <Typography variant="p" style={{ fontSize: "14px" }}>
                Unspecified
              </Typography>
            </div>
            <Grid container xl={12}>
              <Grid item xs={1.7} className={classes.grid_Box}>
                {tempAction1.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateActionData={updateActionData}
                        updateHabitData={updateHabitData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <div
                style={{
                  height: "100%",
                  border: "0px solid #f3f3f3",
                }}
              ></div>
              <Grid item xs={1.75} className={classes.grid_Box}>
                {tempAction2.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateActionData={updateActionData}
                        updateHabitData={updateHabitData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={1.69} className={classes.grid_Box}>
                {tempAction3.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateActionData={updateActionData}
                        updateHabitData={updateHabitData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={1.69} className={classes.grid_Box}>
                {tempAction4.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateActionData={updateActionData}
                        updateHabitData={updateHabitData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={1.69} className={classes.grid_Box}>
                {tempAction5.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateHabitData={updateHabitData}
                        updateActionData={updateActionData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={1.75} className={classes.grid_Box}>
                {tempAction6.map((action, i) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        key={i}
                        updateHabitData={updateHabitData}
                        updateActionData={updateActionData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={1.7} className={classes.grid_Box}>
                {tempAction7.map((action) => {
                  if (action.scheduled_type === "UNSPECIFIED") {
                    return (
                      <UnspecifiedActionHabit
                        action={action}
                        updateHabitData={updateHabitData}
                        updateActionData={updateActionData}
                        handleIsTrue={handleIsTrue}
                      />
                    );
                  }
                })}
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <DropGrowthSnakebar
        style={{ position: "unset" }}
        open={isDropGrowthAreaOpen}
        action={
          <DropModal
            updateColourPickData={updateColourPickData}
            getGrowthCalendarDate={getGrowthCalendarDate}
            setUpdateColourPickData={setUpdateColourPickData}
            colorPickData={colorPickData}
            setColorPickData={setColorPickData}
            setBgColor={setBgColor}
            bgcolor={bgcolor}
            setisDropGrowthAreaOpen={setisDropGrowthAreaOpen}
            setCustomNotificationColourPick={setCustomNotificationColourPick}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            tableCellId={tableCellId}
          />
        }
      ></DropGrowthSnakebar>
      <DropActionSnakebar
        style={{ position: "unset" }}
        open={isSetDurationAction}
        action={
          <SetDurationActionModal
            setActionData={setActionData}
            actionData={actionData}
            setIsSetDurationAction={setIsSetDurationAction}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setActionSliceData={setActionSliceData}
            actionSliceData={actionSliceData}
            updateAction={updateAction}
            setUpdateAction={setUpdateAction}
          />
        }
      ></DropActionSnakebar>
      <DropHabitSnakebar
        style={{ position: "unset" }}
        open={isSetDurationHabit}
        action={
          <SetDurationHabitModal
            setHabitData={setHabitData}
            habitData={habitData}
            setIsSetDurationHabit={setIsSetDurationHabit}
            setHabitSliceData={setHabitSliceData}
            habitSliceData={habitSliceData}
            updateHabit={updateHabit}
            setUpdateHabit={setUpdateHabit}
            getGrowthd={getGrowthd}
            getCalendars={getCalendars}
          />
        }
      ></DropHabitSnakebar>
      {customNotification && (
        <NotificationContentJSX
          message={gsMessage}
          isThumbIcon={false}
          position="top"
        />
      )}
      {customNotificationColourPick && (
        <NotificationContentColourPickJSX
          message="can't pick same color for different diamension"
          isThumbIcon={false}
          position="top"
        />
      )}
      {customNotificationActionHabit && (
        <NotificationContentActionHabitJSX
          message="can't drop it without growth area"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
};

export default React.memo(MonthlyCaledar);
