import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomSelection } from "../../../components/GoalComponent/GoalDialog";
import {
  CheckBoxInputCheckedDay,
  CheckBoxInputDay,
} from "../../../components/Input/Input";
import { dates, days } from "../../../Constant/GoalConstant";
import typography from "../../../theme/typography";
import classes from "./HabitStep.module.css";
import RightSign from "../../../assets/Svg/RightSign.svg";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import GoalTimePicker from "../../../components/EditRemoveGoal/GoalTimePicker/GoalTimePicker";
import GoalDatePicker from "../../../components/EditRemoveGoal/GoalDatePicker/GoalDatePicker";
import { Key } from "@mui/icons-material";
import moment from "moment";
import { imageURLRender, XPLevels } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
function HabitStepSix({
  onPrevious,
  getGoalDetail,
  goalDetail,
  stepSixNextHandler,
  selectedDiamenstion,
  selectedDiamenstionImage,
  handleClose
}) {
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);
  const [isDatePickerOpen, setisDatePickerOpen] = useState(false);
  const [selectedDaystate, setSelectedDaystate] = useState([]);
  const [selectedDate, setselectedDate] = useState(
    moment(new Date()).format("DD")
  );
  const [habitInputData, setHabitInputData] = useState([
    {
      habitName: "",
      habitDetail: "",
      habitScheduleType: "",
      daysData: [],
      dateData: "",
      level: ''
    },
  ]);
  console.log(selectedDaystate);
  useEffect(() => {
    console.log("goalDetail?.habit", goalDetail?.habit);
    if (goalDetail?.habit && goalDetail?.habit.length) {
      setHabitInputData(goalDetail?.habit);
      if (goalDetail?.habit[0]?.habitScheduleType === "WEEKLY") {
        setSelectedDaystate(goalDetail?.habit[0]?.days);
      } else if (goalDetail?.habit[0]?.habitScheduleType === "MONTHLY") {
        setselectedDate(goalDetail?.habit[0]?.habitSchedule?.split("-")[1]);
      }
    }
  }, []);
  const setselectedDayHandler = (day) => {
    const findData = selectedDaystate.find((o) => o === day);
    if (findData) {
      setSelectedDaystate((current) => current.filter((data) => data !== day));
    } else {
      setSelectedDaystate([...selectedDaystate, day]);
    }
  };
  const dateselectorHandler = (date) => {
    setselectedDate(date);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...habitInputData];
    tempList[index][name] = value;
    setHabitInputData(tempList);
  };
  const handleChangeAction = (value, index) => {
    const tempList = [...habitInputData];
    tempList[index]["level"] = value;
    setHabitInputData(tempList);
  }
  const dayModalCloseHandler = (index) => {
    const tempList = [...habitInputData];
    tempList[index]["daysData"] = selectedDaystate;
    setHabitInputData(tempList);
    setIsDayPickerOpen(false);
  };
  const dateModalClosehandler = (index) => {
    const tempList = [...habitInputData];
    tempList[index]["dateData"] = selectedDate;
    setHabitInputData(tempList);
    setisDatePickerOpen(false);
  };
  const addMoreHandler = () => {
    if (
      habitInputData[habitInputData.length - 1].habitName !== "" &&
      habitInputData[habitInputData.length - 1].habitDetail !== "" &&
      habitInputData[habitInputData.length - 1].habitScheduleType !== ""
    ) {
      setHabitInputData([
        ...habitInputData,
        {
          habitName: "",
          habitDetail: "",
          habitScheduleType: "",
          daysData: [],
          dateData: "",
        },
      ]);
    }
  };
  const updateMainState = () => {
    let tempHabitData = [];
    habitInputData?.forEach((item) => {
      if (item.habitName && item.habitDetail && item.habitScheduleType) {
        tempHabitData.push({
          habitName: item.habitName,
          habitDetail: item.habitDetail,
          habitScheduleType: item.habitScheduleType,
          level: item.level,
          days: item.habitScheduleType === "WEEKLY" ? item.daysData : [],
          habitSchedule:
            item.habitScheduleType === "MONTHLY"
              ? `${moment().format("MM")}-${item.dateData}-${moment().format(
                "YYYY"
              )}`
              : false,
        });
      }

    });
    getGoalDetail("habit", tempHabitData);
  };
  const updateMainStateNext = () => {
    let tempHabitData = [];
    habitInputData?.forEach((item) => {
      if (item.habitName && item.habitDetail && item.habitScheduleType) {
        tempHabitData.push({
          habitName: item.habitName,
          habitDetail: item.habitDetail,
          habitScheduleType: item.habitScheduleType,
          level: item.level,
          days: item.habitScheduleType === "WEEKLY" ? item.daysData : [],
          habitSchedule:
            item.habitScheduleType === "MONTHLY"
              ? `${moment().format("MM")}-${item.dateData}-${moment().format(
                "YYYY"
              )}`
              : false,
        });
      }

    });
    getGoalDetail("habit", tempHabitData);
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={() => {
        updateMainStateNext();
        stepSixNextHandler();
      }}>
        <span style={{ color: "#9747FF" }}>{"Skip Habit"}</span>{" "}
      </Typography>} />
      <div className={classes.StepFiveWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Add actions
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Set habits
          </Typography>
        </div>
        <div className={classes.StepFiveSubWrapper}>

          <div><img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" /></div>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            You have selected{" "}
            <span style={{ color: "#9747FF" }}> {selectedDiamenstion}</span>{" "}
          </Typography>
          <Typography
            variant="body4"
            sx={{ margin: "16px 0px", textAlign: "center" }}
          >
            Add a habit
          </Typography>
          {habitInputData?.map((habitInput, index) => {
            return (
              <div
                key={index}
                style={{ width: "100%", marginTop: index === 0 ? "0px" : "39px" }}
              >
                <div style={{ width: "100%" }}>
                  <Typography variant="subtitle1">Give it a name</Typography>
                  <TextField
                    size="small"
                    name="habitName"
                    className={classes.goalActionInput}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    value={habitInput.habitName}
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  <Typography variant="subtitle1">
                    Tell us why this habit
                  </Typography>
                  <TextField
                    size="small"
                    name="habitDetail"
                    className={classes.goalActionInput}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    value={habitInput.habitDetail}
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ margin: "0" }}>
                    {" "}
                    How often
                  </Typography>
                  <CustomSelection
                    value={habitInput.habitScheduleType}
                    displayEmpty
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                    name="habitScheduleType"
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                        disableScrollLock: true,
                      },
                    }}
                    sx={{
                      margin: '12px 0px 32px 0px',
                      color: "#434243",
                      border: "1PX SOLID GREEN",
                      "& .MuiSelect-root": {
                        color: "#434243",
                      },
                    }}
                    className="AKSKASAJSKAJSA"
                  >
                    <MenuItem
                      value=""
                      sx={{
                        color: "#434243",
                      }}
                    >
                      Select
                    </MenuItem>
                    <MenuItem sx={{ color: "#434243" }} value={"DAILY"}>
                      Daily{" "}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "#434243" }}
                      value={"WEEKLY"}
                      onClick={() => {
                        setIsDayPickerOpen(true);
                      }}
                    >
                      Weekly{" "}
                    </MenuItem>
                    {/* <MenuItem
                    sx={{ color: "#434243" }}
                    value={"MONTHLY"}
                    onClick={() => {
                      setisDatePickerOpen(true);
                    }}
                  >
                    Monthly
                  </MenuItem> */}
                  </CustomSelection>
                </div>

                <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>
                  Select Difficulty
                </Typography>
                <div className={classes.customActionLevelGroup} style={{
                  margin: '12px 0px 32px 0px'
                }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={habitInput.level}
                    className={classes.customActionLevelGroup}
                    exclusive
                    onChange={(e, val) => {
                      handleChangeAction(val, index)
                    }}
                    size="small"
                    aria-label="Platform"
                  >
                    {XPLevels.map(it => (
                      <ToggleButton value={it.level} className={habitInput.level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {it.icon} <span className="toggle-text">{it.level}</span></ToggleButton>

                    ))}
                  </ToggleButtonGroup>
                </div>
                {isDayPickerOpen && (
                  <GoalTimePicker
                    isDayPickerOpen={isDayPickerOpen}
                    dayModalCloseHandler={() => dayModalCloseHandler(index)}
                    setselectedDayHandler={setselectedDayHandler}
                    selectedDaystate={selectedDaystate}
                  />
                )}
                {isDatePickerOpen && (
                  <GoalDatePicker
                    isDatePickerOpen={isDatePickerOpen}
                    dateModalClosehandler={() => dateModalClosehandler(index)}
                    dateselectorHandler={dateselectorHandler}
                    selectedDate={selectedDate}
                  />
                )}
              </div>
            );
          })}
          <div style={{ width: "100%", margin: "12px 0px" }}>
            <CustomShedualButton
              style={{ maxWidth: "91px" }}
              onClick={() => {
                addMoreHandler();
              }}
            >
              Add more +
            </CustomShedualButton>
          </div>

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: "0 40px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                // onClick={onPrevious}
                onClick={() => {
                  onPrevious();
                  updateMainState();
                }}
              >
                Previous
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButtonGrp}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainStateNext();
                  stepSixNextHandler();
                }}
                disabled={
                  habitInputData[habitInputData.length - 1]?.habitName === "" ||
                  habitInputData[habitInputData.length - 1]?.habitDetail ===
                  "" ||
                  habitInputData[habitInputData.length - 1]?.habitScheduleType === "" ||
                  !habitInputData[habitInputData.length - 1]?.level
                }
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>

  );
}

export default HabitStepSix;
