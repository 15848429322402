import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
const initialState = {
  currentMonth: [],
  startDay: moment().startOf("month").format(),
  endDay: moment().endOf("month").format(),
  selectedDate: moment().format(),
  scheduleData: [],
  openJournalModal: false,
  openJournalItem: {},
  savingJournal: false,
  quote: {},
  completedXP: 0,
  goalXp: 0,
  openNotification: false,
  notificationMessage: "",
  openInviteModal: false,
  loadingButton: false,
  visibleCelebrationmodal: false,
  visibleEditGoalModal: false,
  userLevelDetails: {
    level: "",
    start: 0,
    end: 0,
  },
  dailyGoalLevel: [],
  selectedActionIds: [],
  selectedAHabitIds: [],
  buttonLoading: false,
  showStories: false,
  showYoutube: false
};
export const DashboardData = createSlice({
  name: "DashboardData",
  initialState,
  reducers: {
    updateDashStartEnd: (state, action) => {
      state.startDay = action.payload.startDay;
      state.endDay = action.payload.endDay;
    },
    updateSetShowStories: (state, action) => {
      state.showStories = action.payload
    },
    updateSetShowYoutube: (state, action) => {
      state.showYoutube = action.payload
    },
    openCloseInviteModal: (state, action) => {
      state.openInviteModal = action.payload;
    },
    updateDashButtonLoading: (state, action) => {
      state.buttonLoading = action.payload;
    },
    updateDashGoalXP: (state, action) => {
      state.goalXp = action.payload;
    },
    openCloseEditGoalModal: (state, action) => {
      state.visibleEditGoalModal = action.payload;
    },
    openCloseJournalModal: (state, action) => {
      state.openJournalModal = action.payload.openJournalModal;
      state.openJournalItem = action.payload.openJournalItem;
    },
    openCloseCelebrationModal: (state, action) => {
      state.visibleCelebrationmodal = action.payload;
    },
    updateScheduleAtIndex: (state, action) => {
      const foundIndex = [...state.scheduleData].findIndex(it => {
          if (action.payload.actionId) return it.actionId === action.payload.actionId
          if (action.payload.habitId) return it._id === action.payload.habitId
      })
      console.log("foundIndex", foundIndex);
      if (foundIndex > -1) {
        if(action.payload.actionId) {
          state.scheduleData = state.scheduleData.map(it => {
            if(it.actionId === action.payload.actionId) {
              it = {
                ...it, 
                ...action.payload.obj,
              }
            }
            return it
          })
        }
        if(action.payload.habitId) {
          state.scheduleData = state.scheduleData.map(it => {
            if(it._id === action.payload.habitId) {
              it = {
                ...it, 
                ...action.payload.obj,
              }
            }
            return it
          })
        }
        Object.keys(action.payload.obj).map(it => {
          // state.scheduleData[foundIndex][it] = action.payload.obj[it]
        })
      }
    },
    setDahData: (state,action) => {
        console.log("action.payload ", action.payload);
        if(Object.keys(action.payload).includes("currentMonth")) {
            state.currentMonth = action.payload.currentMonth
        }
        if(Object.keys(action.payload).includes("startDay")) {
            state.startDay = action.payload.startDay
        }
        if(Object.keys(action.payload).includes("endDay")) {
            state.endDay = action.payload.endDay
        }
        if(Object.keys(action.payload).includes("selectedDate")) {
            state.selectedDate = action.payload.selectedDate
        }
        if(Object.keys(action.payload).includes("scheduleData")) {
            state.scheduleData = action.payload.scheduleData
        }
        if(Object.keys(action.payload).includes("openJournalModal")) {
            state.openJournalModal = action.payload.openJournalModal
        }
        if(Object.keys(action.payload).includes("openJournalItem")) {
            state.openJournalItem = action.payload.openJournalItem
        }
        if(Object.keys(action.payload).includes("savingJournal")) {
            state.savingJournal = action.payload.savingJournal
        }
        if(Object.keys(action.payload).includes("quote")) {
          if(!Object.keys(state.quote).length) {
            state.quote = action.payload.quote
          }
        }
        if(Object.keys(action.payload).includes("completedXP")) {
            state.completedXP = action.payload.completedXP
        }
        if(Object.keys(action.payload).includes("goalXp")) {
            state.goalXp = action.payload.goalXp
        }
        if(Object.keys(action.payload).includes("openNotification")) {
            state.openNotification = action.payload.openNotification
        }
        if(Object.keys(action.payload).includes("notificationMessage")) {
            state.notificationMessage = action.payload.notificationMessage
        }
        if(Object.keys(action.payload).includes("openInviteModal")) {
            state.openInviteModal = action.payload.openInviteModal
        }
        if(Object.keys(action.payload).includes("loadingButton")) {
            state.loadingButton = action.payload.loadingButton
        }
        if(Object.keys(action.payload).includes("visibleCelebrationmodal")) {
            state.visibleCelebrationmodal = action.payload.visibleCelebrationmodal
        }
        if(Object.keys(action.payload).includes("visibleEditGoalModal")) {
            state.visibleEditGoalModal = action.payload.visibleEditGoalModal
        }
        if(Object.keys(action.payload).includes("userLevelDetails")) {
            state.userLevelDetails = action.payload.userLevelDetails
        }
        if(Object.keys(action.payload).includes("dailyGoalLevel")) {
            state.dailyGoalLevel = action.payload.dailyGoalLevel
        }
        if(Object.keys(action.payload).includes("buttonLoading")) {
            state.buttonLoading = action.payload.buttonLoading
        }
    },
    resetDashboardState: () => {
            return initialState
    }
  },
});

export const {
  updateDashStartEnd,
  openCloseInviteModal,
  updateDashButtonLoading,
  updateDashGoalXP,
  openCloseEditGoalModal,
  openCloseJournalModal,
  openCloseCelebrationModal,
  setDahData,
  resetDashboardState,
  updateScheduleAtIndex,
  updateSetShowStories,
  updateSetShowYoutube
} = DashboardData.actions;
export default DashboardData.reducer;
