import styled from "@emotion/styled";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { getTestDimensions } from "../../../../services/auth";
import { updateDimensions } from "../../../../Store/Reducers/dimensions";
import typography from "../../../../theme/typography";
import classes from "./SpecificDimensionStepThree.module.css";

const UnCkeckedDimension = ({dimensionName}) => {
  return (
    <div className={classes.dimensionBtn}>
      <Typography
        variant="body3"
        color="#9747FF"
        className={classes.dimensionTextWrapper}
      >
       {dimensionName}
      </Typography>
    </div>
  );
};

const CheckedDimension = ({dimensionName}) => {
  return (
    <div className={`${classes.dimensionBtn} ${classes.dimensionBtn_active}`}>
      <Typography
        variant="body3"
        color="#9747FF"
        className={`${classes.dimensionTextWrapper} ${classes.dimensionTextWrapper_active}`}
      >
       {dimensionName}
      </Typography>
    </div>
  );
};

const SpecificDimensionStepThree = (props) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [dimensionIds, setDimensionIds] = useState(props?.inviteData?.questionCategoryIds)
  const [dimensions, setDimensions] = useState([])

  console.log("111",!props.dimensions)

  useEffect(() => {
    if(!props.dimensions){
      const subCategory_id = localStorage.getItem("selctedSubCategoryId")
      getTestDimensions(subCategory_id).then((res)=>{
        console.log(res)
        setDimensions(res?.questionCategoryData)
      }).catch((err)=>{
        console.log(err)
      })
    }else{
      setDimensions(props?.dimensions)
    }
  }, [])
  

  const onCheckValueChange = (questionCategoryId) =>{
    if(!dimensionIds?.includes(questionCategoryId)){
      setDimensionIds([...dimensionIds,questionCategoryId])
    }
    if(dimensionIds?.includes(questionCategoryId)){
      const updatedDimensionIds = dimensionIds.filter((dimensionId)=>{
        return dimensionId !== questionCategoryId
      })
      setDimensionIds(updatedDimensionIds)
    }
  }

  const updateMainState = () => {
    props.updateInviteData("questionCategoryIds", dimensionIds )
  }
  return (
    <div className={classes.stepOneWrapper}>
      <ProgressBar progress={props.progress} />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", mt: "32px" }}
        >
          Choose one dimension
        </Typography>
        <div className={classes.dimensionListWrapper}>
          {
            dimensions?.map((dimension) => {
              const {questionCategory,_id,questionCategoryId} = dimension
              return <Checkbox
                {...label}
                key={_id}
                checked={dimensionIds?.includes(questionCategoryId)}
                onChange={()=>onCheckValueChange(questionCategoryId)}
                disableTouchRipple
                disableFocusRipple
                disableRipple
                icon={<UnCkeckedDimension dimensionName={questionCategory} />}
                checkedIcon={<CheckedDimension dimensionName={questionCategory} />}
                sx={{ padding: 0 }}
              />
            })
          }
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
        <ButtonGroup {...props} updateMainState={updateMainState} isNextDisable={dimensionIds?.length === 0} />
      </div>
    </div>
  );
};

export default SpecificDimensionStepThree;
