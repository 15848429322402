import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  Chip,
  Avatar,
  Typography,
  useMediaQuery,
  IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Fingerprint from '@mui/icons-material/Fingerprint';
import React, { useEffect, useState } from "react";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import CheckboxStartIcon from "../../assets/Svg/CheckboxStartIcon.svg";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import {ReactComponent as Sparkles} from "../../assets/Svg/Sparkles.svg";
import XP from "../../assets/Svg/XP.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import classes from "./Accordian.module.css";
import { CustomShedualButton } from "../GoalComponent/GoalButton";
import styled from "@emotion/styled";
import AddMoreGoal from "../../pages/AddMoreGoal/AddMoreGoal";
import UncheckCheckbox from "../../assets/Svg/UncheckCheckbox.svg";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import { editPreSelectedGrowth, removeGrowthArea } from "../../services/auth";
import {
  AddMoreDialog,
  CardContent,
  DuplicateSnackbar,
  // CustomAddNewGoalDialog,
  EditRemoveSnackbar,
  MainCard,
  SelfTextSnackbar,
} from "../GoalComponent/GoalDialog";
import EditGoal from "../EditRemoveGoal/EditGoal/EditGoal";
import { useDispatch, useSelector } from "react-redux";
import EditSelf from "../EditRemoveGoal/EditSelf/EditSelf";
import useCustomNotification from "../../Hooks/useCustomNotification";
import GrowthAreaDialog from "../GoalAddMoreDialog/GrowthAreaDialog/GrowthAreaDialog";
import DuplicateGrowthArea from "../EditRemoveGoal/DuplicateGrowthArea/DuplicateGrowthArea";
function GrowthAreaAccordian({
  preSelectedGrowth,
  preSelectedGrowthComplete,
  selectGrowthAreaHandler,
  completedGrowthAreaHandler,
  selectedQuestionCategoriesId,
  setPreSelectedState,
  setCompletedGrowthAreaState,
  isAddMoreGrowthAreaOpen,
  setisAddMoreGrowthAreaOpen,
  goalName,
  expanded,
  growthRecommended = [],
  imageCompo,
}) {
  const dispatch = useDispatch();
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const { growthAreaDataSlice } = useSelector((state) => state);
  const [growthAccordian, setGrowthAccordian] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [isCompoletedMenuOpen, setIsCompoletedMenuOpen] = useState(false);
  const [selectedGrowthArea, setSelectedGrowthArea] = useState({});
  const [viewMore, setViewMore] = useState(2);
  const [isMySelfEdit, setIsMySelfEdit] = useState(false);
  const [isMySelfCompoleted, setIsMySelfCompoleted] = useState(false);
  const [mySelfState, setmySelfState] = useState("");
  const [idDialog, setidDialog] = useState("");
  const [growthAreaId, setGrowthAreaId] = useState("");
  useEffect(() => {
    const uniquePreselected = duplicateGrowthAreaRemoveHandler(
      preSelectedGrowthComplete
    );
    setCompletedGrowthAreaState(uniquePreselected);
  }, [expanded]);
  const duplicateGrowthAreaRemoveHandler = (arr) => {
    const res = {};
    arr?.forEach((obj) => {
      const key = `${obj.text}`;
      if (!res[key]) {
        res[key] = { ...obj, count: 0 };
      }
      res[key].count += 1;
    });
    return Object.values(res);
  };
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;
  const GrowthAccordianHandler = (panel) => (event, isExpanded) => {
    setGrowthAccordian(isExpanded ? panel : false);
  };
  const removeGrowthAreaHandler = () => {
    setIsEditMenuOpen(false);
    const user = localStorage.getItem("userDetail");
    const goalID = growthAreaDataSlice?.goalID;
    const growthID = selectedGrowthArea._id
      ? selectedGrowthArea._id
      : growthAreaId;
    const tempPreselected = preSelectedGrowth?.filter(
      (item) => item._id !== selectedGrowthArea._id
    );
    setPreSelectedState(tempPreselected);
    const tempCompeleted = preSelectedGrowthComplete?.filter(
      (item) => item._id !== selectedGrowthArea._id
    );
    setCompletedGrowthAreaState(tempCompeleted);
    let growthAreaData = {
      uid: user,
      goalId: goalID,
      growthId: growthID,
    };
    removeGrowthArea(growthAreaData)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editGrowthAreaTextHandler = () => {
    setIsEditMenuOpen(false);
    if (selectedGrowthArea.type === "SUGGESTED") {
      setCustomNotification(true);
    }
    if (selectedGrowthArea.type === "SELF") {
      if (isEditMenuOpen) {
        setIsMySelfEdit(true);
      }
      setmySelfState(selectedGrowthArea?.text);
    }
  };
  const handleSelfInput = (e) => {
    setmySelfState(e.target.value);
  };
  const editSelfHandler = () => {
    setIsMySelfEdit(false);
    setIsMySelfCompoleted(false);
    if (mySelfState) {
      let tempPreselectedgrowth = [];
      preSelectedGrowth?.map((item) => {
        return tempPreselectedgrowth.push({
          type: item.type,
          _id: item._id,
          status: item.status,
          text: item._id === selectedGrowthArea._id ? mySelfState : item.text,
        });
      });
      setPreSelectedState(tempPreselectedgrowth);
      let tempCompeletedGrowth = [];
      preSelectedGrowthComplete?.map((item) => {
        return tempCompeletedGrowth.push({
          type: item.type,
          _id: item._id,
          status: item.status,
          text: item._id === selectedGrowthArea._id ? mySelfState : item.text,
        });
      });
      setCompletedGrowthAreaState(tempCompeletedGrowth);
      const user = localStorage.getItem("userDetail");
      const goalID = growthAreaDataSlice?.goalID;
      const growthID = selectedGrowthArea._id
        ? selectedGrowthArea._id
        : growthAreaId;
      let growthAreaData = {
        uid: user,
        goalId: goalID,
        growthId: growthID,
        preSelectedGrowth: tempPreselectedgrowth,
        preSelectedGrowthComplete: tempCompeletedGrowth,
      };
      editPreSelectedGrowth(growthAreaData)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const addNewGrowthAreaHandler = (selfInput, preselectedState) => {
    let tempNewGrowthArea = [];
    let tempNewGrowthAreaState = [];
    if (selfInput?.text) {
      tempNewGrowthArea = [...preselectedState, selfInput];
      tempNewGrowthAreaState = [...preSelectedGrowth, ...tempNewGrowthArea];
    } else {
      tempNewGrowthAreaState = [...preSelectedGrowth, ...preselectedState];
    }
    setPreSelectedState(tempNewGrowthAreaState);
    const user = localStorage.getItem("userDetail");
    let newGrowthAreaData = {
      uid: user,
      goalId: growthAreaDataSlice?.goalID,
      preSelectedGrowth: tempNewGrowthAreaState,
      preSelectedGrowthComplete: preSelectedGrowthComplete,
    };
    editPreSelectedGrowth(newGrowthAreaData)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addNewGrowthAreaHandlerFormRecommened = (value) => {
    let tempNewGrowthArea = [];
    let tempNewGrowthAreaState = [];
    if (value) {
      tempNewGrowthArea = [value];
      tempNewGrowthAreaState = [...preSelectedGrowth, ...tempNewGrowthArea];
    } else {
      tempNewGrowthAreaState = [...preSelectedGrowth];
    }
    setPreSelectedState(tempNewGrowthAreaState);
    const user = localStorage.getItem("userDetail");
    let newGrowthAreaData = {
      uid: user,
      goalId: growthAreaDataSlice?.goalID,
      preSelectedGrowth: tempNewGrowthAreaState,
      preSelectedGrowthComplete: preSelectedGrowthComplete,
    };
    editPreSelectedGrowth(newGrowthAreaData)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const duplicateGrowthAreaHandler = () => {
    let tempDuplicateGrowtharea = [
      ...preSelectedGrowth,
      {
        type: selectedGrowthArea.type,
        status: "IN_PROGRESS",
        text: selectedGrowthArea.text,
      },
    ];
    setPreSelectedState(tempDuplicateGrowtharea);
    setIsCompoletedMenuOpen(false);
    const user = localStorage.getItem("userDetail");
    const duplicatedata = {
      uid: user,
      goalId: growthAreaDataSlice?.goalID,
      growthId: selectedGrowthArea._id,
      preSelectedGrowth: tempDuplicateGrowtharea,
      preSelectedGrowthComplete: preSelectedGrowthComplete,
    };
    editPreSelectedGrowth(duplicatedata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const tempGoalgrowth = [...(preSelectedGrowth || []), ...(preSelectedGrowthComplete || [])];
  const tempGrowthAreaSuggested = tempGoalgrowth.filter(
    (item) => item.type === "SUGGESTED"
  );
  const getUniquePreselectedGrowthArea = (array1 = [], array2 = []) => {
    let arr3 = [].concat(
      array1.filter((obj1) => array2.every((obj2) => obj1.text !== obj2.point)),
      array2.filter((obj2) => array1.every((obj1) => obj2.point !== obj1.text))
    );
    const abc = Object.values(
      arr3.reduce((acc, cur) => Object.assign(acc, { [cur.point]: cur }), {})
    );
    return abc
  };
  return (
    <>
      <Accordion
        expanded={growthAccordian === "panel"}
        onChange={GrowthAccordianHandler("panel")}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
          className={classes.subAccordianSummery}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            "& .Mui-expanded": {
              margin: "0px 0px !important",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
            },
          }}
        //
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Growth Area
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.GrowthAccordianDetailContainer}>
          <div className={classes.selectedWrapper}>
            <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
              Selected
            </Typography>
            <CustomShedualButton
              style={{
                maxWidth: "91px",
                background: "#F9F9F9",
                color: "#190D1A",
              }}
              onClick={() => {
                setidDialog(growthAreaDataSlice?.goalID);
                setisAddMoreGrowthAreaOpen(true);
              }}
            >
              Add more +
            </CustomShedualButton>
          </div>
          <div style={{ position: "relative" }}>
            {!preSelectedGrowth?.length ? (
              <div className={classes.dataNotFoundSubWrapper}>
                <Typography variant="body2">
                  selected growth area not found
                </Typography>
              </div>
            ) : (
              preSelectedGrowth?.map((data, index) => {
                const { text, type, _id } = data;
                return (
                  <div className={classes.customRadioAction} key={index}>
                    <div className={classes.actionNameWrapper}>
                      <img
                        className={classes.checkBoxIcon}
                        onClick={() => {
                          selectGrowthAreaHandler(data);
                        }}
                        src={UncheckCheckbox}
                        alt=""
                      />
                      <Typography
                        variant=" body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        {text}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <img
                        style={{
                          margin: "4px 0px 0px -7px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                        src={tripleDot}
                        alt="ExpandMore"
                        onClick={() => {
                          setGrowthAreaId(index);
                          setIsEditMenuOpen(true);
                          setIsCompoletedMenuOpen(false);
                          setSelectedGrowthArea(data);
                        }}
                      />
                    </div>
                    {growthAreaId === index && (
                      <>
                        <EditRemoveSnackbar
                          open={isEditMenuOpen}
                          // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          onClose={() => {
                            setIsEditMenuOpen(false);
                          }}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                          action={
                            <EditGoal
                              editGrowthAreaTextHandler={
                                editGrowthAreaTextHandler
                              }
                              removeGrowthAreaHandler={removeGrowthAreaHandler}
                            />
                          }
                        ></EditRemoveSnackbar>
                        <SelfTextSnackbar
                          open={isMySelfEdit}
                          onClose={() => {
                            editSelfHandler();
                          }}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                          action={
                            <EditSelf
                              handleSelfInput={handleSelfInput}
                              mySelfState={mySelfState}
                              editSelfHandler={editSelfHandler}
                            />
                          }
                        ></SelfTextSnackbar>
                        {customNotification && (
                          <NotificationContentJSX
                            message="You can't edit suggested growth area"
                            isThumbIcon={false}
                            position="top"
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })
            )}
            {/* <CustomShedualButton
              style={{ maxWidth: "84px" }}
              onClick={() => {
                setViewMore(preSelectedGrowth?.length);
                // setisViewMore(true)
              }}
            >
              View more
            </CustomShedualButton> */}
          </div>

          <div style={{ position: "relative" }}>
          <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }} className={classes.recommendedClass}>
          <span style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}>
                <Sparkles /> Recommended

              </span>
              <span style={{
                marginRight: '20px',
                background: '#F7F2FE',
                padding: '0 5px',
                borderRadius: '4px',
              }}>
                {getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, growthRecommended).length ? getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, growthRecommended).length : ''}
              </span>
            </Typography>
            {getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, growthRecommended).length === 0 ? (
              <div className={classes.dataNotFoundSubWrapper}>
                <Typography variant="body2">
                  Recommended growth area not found
                </Typography>
              </div>
            ) : (
              getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, growthRecommended)?.map((data, index) => {
                const { point: text, _id } = data;
               
                return (
                  <div className={classes.customRadioAction} style={{
                    alignItems: 'center',
                    cursor: 'pointer'
                  }} onClick={e => {
                    addNewGrowthAreaHandlerFormRecommened({
                      active: "true",
                      index: 0,
                      status: "IN_PROGRESS",
                      text,
                      type: "SUGGESTED"
                    })
                  }} key={index}>
                    <div className={classes.actionNameWrapper}>
                      <IconButton aria-label="add-icon" color="primary">
                        <AddIcon />
                      </IconButton>
                      <Typography
                        variant=" body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        {text}
                      </Typography>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div style={{ position: "relative" }}>
            <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
              Completed
            </Typography>
            {preSelectedGrowthComplete?.length === 0 ? (
              <div className={classes.dataNotFoundSubWrapper}>
                <Typography variant="body2">
                  Completed growth area not found
                </Typography>
              </div>
            ) : (
              preSelectedGrowthComplete?.map((data, index) => {
                const { text, _id } = data;
                return (
                  <div className={classes.customRadioAction} key={_id}>
                    <div className={classes.actionNameWrapper}>
                      {data?.count > 1 ? (
                        <div className={classes.duplicateTextWrapper}>
                          <img
                            src={CheckedCheckbox}
                            className={classes.checkBoxIcon}
                            alt=""
                            onClick={() => {
                              completedGrowthAreaHandler(data);
                            }}
                          />
                          <Typography variant="body3">
                            {data?.count}x
                          </Typography>
                        </div>
                      ) : (
                        <img
                          src={CheckedCheckbox}
                          className={classes.checkBoxIcon}
                          alt=""
                          onClick={() => {
                            completedGrowthAreaHandler(data);
                          }}
                        />
                      )}

                      <Typography
                        variant=" body2"
                        className={classes.checkBoxChekedText}
                      >
                        {" "}
                        {text}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <img
                        style={{
                          margin: "4px 0px 0px -7px",
                          height: "18px",
                        }}
                        src={tripleDot}
                        alt="ExpandMore"
                        onClick={() => {
                          setIsCompoletedMenuOpen(true);
                          setGrowthAreaId(index);
                          setIsEditMenuOpen(false);
                          setSelectedGrowthArea(data);
                        }}
                      />
                    </div>
                    {growthAreaId === index && (
                      <>
                        <DuplicateSnackbar
                          open={isCompoletedMenuOpen}
                          // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          onClose={() => {
                            setIsCompoletedMenuOpen(false);
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          action={
                            <DuplicateGrowthArea
                              duplicateGrowthAreaHandler={
                                duplicateGrowthAreaHandler
                              }
                            />
                          }
                        ></DuplicateSnackbar>
                      </>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {console.log("growthAreaDataSlice", growthAreaDataSlice)}
      {isAddMoreGrowthAreaOpen && idDialog === growthAreaDataSlice.goalID && (
        <CustomAddNewGoalDialog
          open={isAddMoreGrowthAreaOpen}
          onClose={() => {
            setisAddMoreGrowthAreaOpen(false);
          }}
        >
          <MainCard
            sx={{
              minWidth: 250,
              borderRadius: "40px",
              marginInline: "auto",
              height: "652px !important",
            }}
          >
            <CardContent>
              <GrowthAreaDialog
                selectedQuestionCategoriesId={selectedQuestionCategoriesId}
                goalName={goalName}
                setisAddMoreGrowthAreaOpen={setisAddMoreGrowthAreaOpen}
                preSelectedGrowth={preSelectedGrowth}
                preSelectedGrowthComplete={preSelectedGrowthComplete}
                imageCompo={imageCompo}
                addNewGrowthAreaHandler={addNewGrowthAreaHandler}
              ></GrowthAreaDialog>
            </CardContent>
          </MainCard>
        </CustomAddNewGoalDialog>
      )}
    </>
  );
}

export default GrowthAreaAccordian;
