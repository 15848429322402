import Stories from '../../components/Stories';
import Success16px from "./../../assets/Svg/success-circle-16px.svg"
import Story1 from "./../../assets/Svg/Story1.svg"
import Story2 from "./../../assets/Svg/Story2.svg"
import Story3 from "./../../assets/Svg/Story3.svg"
import { updateSetShowStories } from "../../Store/Reducers/dashboard";
import { useDispatch, useSelector } from "react-redux";
export const StoriesComponent = () => {
    const dispatch = useDispatch();
    const { showStories } = useSelector(
        (state) => state.dashboardDataSlice
    );
    console.log("dashboardDataSlice", showStories);
    return showStories ? (
        <Stories
            stories={[
                Story1,
                Story2,
                Story3
            ]}
            defaultInterval={1500}
            width={'24%'}
            height={'100%'}
            storyContainerStyles={{
                position: 'absolute',
                top: 0,
                width: '100%',
                left: '40%',
                zIndex: 10
            }}
            storyInnerContainerStyles={{
                padding: 0,
                width: '100%',
                background: "#000000",
                display: 'flex',
                alignItems: "center",
                justifyContent: 'center'
            }}
            storyStyles={{
                // margin: 0,
                objectFit: 'fill',
                width: '100%',
                borderRadius: '20px'
            }}
            onHandleExit={() => dispatch(updateSetShowStories(false))}
        />
    ) : null
}