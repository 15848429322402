import React, { useState } from 'react'
import styled from '@emotion/styled';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { PROFILEINFO_TYPE } from '../../../Constant/SettingConstant';
import { ProfileInfoButtonWrapper } from '../StyledElement/StyledDiv';
import LeftArrowIcom from '../../../assets/Svg/LeftArrowIcon.svg'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import classes from "./profileHeader.module.css";
import { useNavigate } from 'react-router-dom';

function ProfileHeader({ setPersonalAccountInfo }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = React.useState(null);
  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    if (newValue === 0) {
      window.open("https://scientific-selfdiscovery.com/privacy-policy/", "_blank")
      setPersonalAccountInfo(PROFILEINFO_TYPE.ACCOUNT_INFO)
    }
    else {
      window.open("https://scientific-selfdiscovery.com/cookiepolicy", "_blank")
      setPersonalAccountInfo(PROFILEINFO_TYPE.PERSONAL_INFO)

    }
    // setValue(newValue)
  }

  const backToSideNavigate = () => {
    navigate("/settings/settingnavigationbar");
  }
  return (
    <>
      {(
        <Typography variant="h5" sx={{ fontSize: "28px !important", fontWeight: `400 !important` }}> Legal</Typography>
      )}
      <ProfileInfoButtonWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={{ fontSize: "14px !important", fontWeight: `400 !important` }} label="Privacy Policy" />
          <Tab sx={{ fontSize: "14px !important", fontWeight: `400 !important` }} label="Cookie Policy" />
        </Tabs>
        {/* <ul>
          <li>
            <p> View  <a href="https://scientific-selfdiscovery.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> by clicking the <a href="https://scientific-selfdiscovery.com/privacy-policy" target="_blank" rel="noreferrer">link</a>  here. </p>
          </li>
          <li>
            <p> View Cookie Policy by clicking the <a href="https://scientific-selfdiscovery.com/cookiepolicy" target="_blank" rel="noreferrer">link</a>  here. </p>
          </li>
        </ul> */}
      </ProfileInfoButtonWrapper>
    </>
  );
}
export default ProfileHeader