import { Button, CircularProgress, Dialog, Grid } from "@mui/material";

import classes from "./Confirmation.module.css";
const ConfirmationModal = props => {
    const {
        visibleConfirmation,
        setConfirmationVisible,
        selectedItem,
        createInvoice,
        generatingPaymentLoader
    } = props
    
    const renderItemName = () => {
        if(selectedItem.questionCategory) {
            return "You are getting immediate access to \"" + (selectedItem.questionCategory ? selectedItem.questionCategory : '') + "\""
        }
    }
    return <Dialog
        open={visibleConfirmation}
        onClose={() => {
            setConfirmationVisible(false);
        }}
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                position: "unset",
                padding: "20px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div className={classes.confirmationModalcardTitle}>
            {renderItemName()}
        </div>
        {generatingPaymentLoader && <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>}
        {!generatingPaymentLoader && <Grid container rowSpacing={1} sx={{
            margin: '10px 0',
        }}>
            <Grid item xs={6}>
                <Button sx={{
                    width: '99%', float: 'left',
                    border: '1px solid #9747FF',
                    borderRadius: '6px'
                }} variant="outlined" onClick={() => setConfirmationVisible(false)}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
                <Button sx={{
                    width: '99%', float: 'right',
                    border: '1px solid #9747FF',
                    borderRadius: '6px'
                }} variant="contained" onClick={(e) => {
                    createInvoice(e, selectedItem.questionCategoryId, true)
                }}>Confirm</Button>
            </Grid>
        </Grid>}
        
    </Dialog>
}

export default ConfirmationModal