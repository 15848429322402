import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState, useDispatch } from "react";
import classes from "./SetDurationHabitModal.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import Frequency from "../Frequency/Frequency";
import moment from "moment";

const SetDurationHabitModal = ({
  setHabitData,
  habitData,
  setIsSetDurationHabit,
  setHabitSliceData,
  habitSliceData,
  updateHabit,
  setUpdateHabit,
  getGrowthd, 
  getCalendars
}) => {
  const [isFrequency, setIsFrequency] = useState(false);
  //   const dispatch = useDispatch();
  const editShedualdataHandler = (executionStart, executionEnd) => {};
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 244,
        padding: "12px",
        background: "white",
        boxShadow: "0px 0px 44px rgba(25, 13, 26, 0.1)",
        borderRadius: "12px",
        overflow: "auto",
      }}
      onClose={() => {
        setIsSetDurationHabit(false);
      }}
    >
      <Box className={clsx(classes.main_box)}>
        <Typography variant="p">
          {Object.keys(updateHabit).length
            ? updateHabit.habitName
            : habitData.text}
        </Typography>
        <Box className={classes.divider_box}>
          <div className={classes.hr_line}></div>
        </Box>
        <div
          className={classes.DateAndTimewrapper}
          onClick={() => {
            setIsFrequency(true);
          }}
        >
          <img src={Clock} />
          <Typography variant="body2" sx={{ color: "#190D1A" }}>
            {updateHabit?.habitScheduleType === "DAILY"
              ? updateHabit?.habitScheduleType === "DAILY" &&
                updateHabit.scheduled_type === "UNSPECIFIED"
                ? "Daily: Unspecified time"
                : `Daily at ${moment
                    (new Date(updateHabit.executionDate))
                    .format("HH:mm")}`
              : updateHabit?.habitScheduleType === "WEEKLY" &&
                updateHabit.scheduled_type === "UNSPECIFIED"
              ? "Weekly: Unspecified time"
              : `Every ${updateHabit?.days?.map((day) => {
                  const d = day.slice(0, 3).toLowerCase();
                  return " " + d.charAt(0).toUpperCase() + d.slice(1);
                })} at ${moment(new Date(updateHabit.executionDate))
                  .format("HH:mm")}`}
          </Typography>
        </div>
      </Box>
      {isFrequency && (
        <Frequency
          habitData={habitData}
          setHabitSliceData={setHabitSliceData}
          habitSliceData={habitSliceData}
          updateHabit={updateHabit}
          setUpdateHabit={setUpdateHabit}
          setIsFrequency={setIsFrequency}
          isFrequency={isFrequency}
          setIsSetDurationHabit={setIsSetDurationHabit}
          getGrowthd={getGrowthd}
          getCalendars={getCalendars}
        />
      )}
    </Box>
  );
};

export default SetDurationHabitModal;
