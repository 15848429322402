import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Divider,
    Grid,
    LinearProgress,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import parse from "html-react-parser";
import XP from "../../assets/Svg/XP.svg";
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import Header from "../../components/Header/Header";
import classes from "./ResultLayout.module.css";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import Sharedisable from "../../assets/Svg/Sharedisable.svg";
import Share from "../../assets/Svg/Share.svg";

import ReadMore from "../../components/ReadMore/ReadMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Calander from "../../components/Calander/Calander";
import useCustomNotification from "../../Hooks/useCustomNotification";
import {
    AllDiamentionByTest,
    AllDiamentionByTestInvite,
    archivedAnswer,
    categoryPage,
    feedbackDiamentionCreate,
    getAllTestScores,
    getQuestionsList,
    getTestDimensions,
    InviteQuetionList,
    SocialUserList,
    subcategoriesByInvite,
    unlockGAAction,
} from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/images/resizeCategory1662712270910_1662712270910.jpeg";
import {
    updateDimensions,
    updateSubCategoryId,
} from "../../Store/Reducers/dimensions";
import ResultCategoryCard, {
    LongMenu,
    ResultReflectiveCard,
    ResultReflectiveCardWhole,
} from "../../components/ResultCategoryCard/ResultCategoryCard";
import TestResultCategoriesList from "../TestResultCategoryList/TestResultCategoryList";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
import SocialResultUser from "../TestResultsPages/SocialResultUser/SocialResultUser";
import { useTheme } from "@emotion/react";
import { TestQuestionSlider } from "../../components/TestQuestionSlider/TestQuestionSlider";
import { PersonaltestResult } from "../../core-ui/PersonalTestResults/PersonaltestResult";
import { PersonalTestResult2 } from "../../core-ui/PersonalTestResults/PersonalTestResult2";
import {
    updateIsSelectedUser,
    updateSelectedIndividualInvitId,
    updateSelectedInviteData,
} from "../../Store/Reducers/selectedInviteData";
import { StepOne } from "../TestResultsPages/StepOne/StepOne";
import { updateModalData, updateResultDimention, updateResultState, updateTestResultDimention } from "../../Store/Reducers/ResultAllDiamention";
import ResultCongrats from "../TestResultsPages/ResultCongrates/ResultCongrats";
import { imageURLRender, randomXP } from "../../utils";
import StepperModal from "./stepperModal";
import useNotifications from "../../Hooks/useNotifications";
import { updateUserWalletCoins } from "../../Store/Reducers/userProfileSlice";
import ResultImage from "./resultImage";
import ResultDimention from "./resultDimention";
const PersonalButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "10px",
    fontSize: "10px !important",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const SocialButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",
    // color: "#190D1A",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const PersonPublic = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: "20px",
    },
}));

const ContainerWrapper = styled("div")(({ theme }) => ({
    overflowY: "auto",
    height: "90vh",
    [theme.breakpoints.down("md")]: {
        height: "fit-content",
        marginTop: "4px",
    },
}));
const ResultInfo = (props) => {
    const {
        state,
        setCustomNotification,
        setToastMessage,
        allDimentionByTest,
        allDimentionByTestInvite
    } = props
    const dispatch = useDispatch();
    const { selectedInviteDataSlice, categoriesIdSlice, resultAllDiamention } = useSelector(
        (state) => state
    );
    const {
       
        testtypeofResult: typeofResult,
        dimensionsBtnData,
        ResultAllDiamentionByInvite,
        reflectiveQuestion,
        subcategories,
        selectedSubCategories,
        testselectLayoutBtn: selectLayoutBtn,
        selectedUserInviteId,
        selectedinviteId,
        inviteName,
        testQuetionCatagoriesId: QuetionCatagoriesId,
        testfinalirrelevantdata: finalirrelevantdata,
        testirreleventRating: irreleventRating,
        selectedPersonal,
    } = resultAllDiamention
    const {
        subCategory,
        score,
        imageUrl,
        description,
        coins,
        points,
        time,
        total_questions,
        subCategory_id,
        category_id,
    } = state;
    const setTypeofResult = val => dispatch(updateResultState({
        testtypeofResult: val
    }))
    const setselectedPersonal = val => dispatch(updateResultState({
        selectedPersonal: val
    }))
    const setSelectedUserInviteId = val => dispatch(updateResultState({
        selectedUserInviteId: val
    }))
    const setIsAskOtherModalOpenSocial = val => dispatch(updateResultState({
        isAskOtherModalOpenSocial: val
    }))
    const socialHandler = useCallback(() => {
        setTypeofResult(TYPEOFRESULTS.SOCIAL);
        setIsAskOtherModalOpenSocial(true);
        // setSocialHeaderText(true);
    }, [typeofResult]);
    return <ContainerWrapper>
        <div className={classes.testInfo}>
            <ResultImage state={state} setCustomNotification={setCustomNotification} setToastMessage={setToastMessage} />

            <div className={classes.testName}>
                <Typography variant="h4">{subCategory}</Typography>
                <PersonPublic>
                    <PersonalButton
                        variant={
                            typeofResult === TYPEOFRESULTS.PERSONAL
                                ? "contained"
                                : null
                        }
                        onClick={() => {
                            setTypeofResult(TYPEOFRESULTS.PERSONAL);
                            setselectedPersonal(true);
                            setSelectedUserInviteId("");
                            dispatch(updateSelectedInviteData([]));
                            dispatch(updateIsSelectedUser(false));
                            dispatch(updateSelectedIndividualInvitId(""));
                        }}
                    >
                        Personal
                    </PersonalButton>
                    <SocialButton
                        variant={
                            typeofResult === TYPEOFRESULTS.SOCIAL
                                ? "contained"
                                : null
                        }
                        onClick={socialHandler}
                    >
                        Social
                    </SocialButton>
                </PersonPublic>
            </div>
            <div className={classes.testDescription}>
                <ReadMore
                    variant="subtitle1"
                    color="#434243"
                    styles={{ lineHeight: "145%" }}
                >
                    {description}
                </ReadMore>
            </div>

            <div className={classes.sourceText}>
                <Typography variant="body3">
                    Source: {subcategories ? subcategories[0]?.source : ""}
                </Typography>
            </div>
        </div>
        <div className={classes.mainWrapper}>

            <ResultDimention state={state} allDimentionByTest={allDimentionByTest} allDimentionByTestInvite={allDimentionByTestInvite}/>
        </div>
    </ContainerWrapper>
}

export default ResultInfo