import axios from "axios";
import { baseEndpoints } from "../config";

class PaymentService {
    static GET_PRODUCTS = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.PAYMENT.GET_PRODUCTS}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static CREATE_INVOICE = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.PAYMENT.CREATE_INVOICE}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static PAYMENT_STATUS = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.PAYMENT.PAYMENT_STATUS}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static getInvoices = async (body) => {
        const { data } = await axios
          .post(`${baseEndpoints.PAYMENT.FETCH_INVOICES}`, body)
          .then((response) => {
            return response;
          });
        return data;
      };
}

export default PaymentService