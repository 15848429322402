import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import XP from "../../assets/Svg/XP.svg";
import classes from "./TestCategoryCard.module.css";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import Sharedisable from "../../assets/Svg/Sharedisable.svg";
import Share from "../../assets/Svg/Share.svg";
import Avatarone from "../../assets/Svg/Avatarone.svg";
import Avatartwo from "../../assets/Svg/Avatartwo.svg";
import Avatarthree from "../../assets/Svg/Avatarthree.svg";
import Avatarfour from "../../assets/Svg/Avatarfour.svg";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { archivedAnswer, getAllTestScores, getUserData } from "../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomNotification from "../../Hooks/useCustomNotification";
import defaultImg from "../../assets/images/resizeCategory1662712270910_1662712270910.jpeg";
import moment from "moment";
import CategorySubcategoryService from "../../services/CategorySubcategory";
import { useDispatch, useSelector } from "react-redux";
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import ConfirmationModal from "./Modal/ConfirmatioinModal";
import PaymentService from "../../services/Payments";
import { imageURLRender, secondsToDhms } from "../../utils";

const usePayment = true
const generateRandomNumber = (min, max) => {
  return parseFloat(Math.random() * (max - min + 1) + min).toFixed(2);
}
function LinearProgressWithLabel(props) {
  return (
    <Box className={classes.progressBarWrapper}>
      <Box sx={{ width: "100%", mr: "15px" }}>
        <LinearProgress
          className={
            props.isprogressdisable
              ? classes.progressDisabeled
              : classes.progress
          }
          variant="determinate"
          {...props}
        />
      </Box>
      <Box>
        <Typography variant="body3" color="#190D1A">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{ width: "36px !important", height: "36px !important" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body3" component="div" sx={{ padding: "12px" }}>
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

const label = { inputProps: { "aria-label": "Checkbox" } };



export const TestCategoryCard = ({
  subCategory,
  description,
  percentage,
  onClickHandler,
  testStatus,
  time,
  points,
  subCategory_id,
  score,
  category_id,
  category,
  subCategory_desc,
  category_desc,
  imageUrl,
  handleClickOpen,
  coins,
  unlock,
  testUnlockIn,
  preDescription,
  total_questions,
  showBookmark = true,
  recommendation,
  loadingTime
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userProfile);

  const [isTestBookMark, setIsTestBookMark] = useState(false);
  const [generatingPaymentLoader, setGeneratingPaymentLoader] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [visibleConfirmation, setConfirmationVisible] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('');
  const [currentTime, setCurrentTime] = useState(testUnlockIn);
  const [sec, seSeconds] = useState(0);
  const setTime = () => {
    const splittedValue = testUnlockIn?.split(":")
    if (splittedValue && splittedValue[0] !== 'NaN') {
      const hms = testUnlockIn;   // your input string
      const a = hms.split(':');
      const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      setCurrentTime(secondsToDhms(seconds - sec))
      seSeconds(sec + 1)
    }
  }

  useEffect(() => {
    const inertval = setInterval(() => {
        setTime()
    }, 1000);
    return () => clearInterval(inertval)
  })
  const navigate = useNavigate();
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  // const fetchScores = () => {
  //   const uid = localStorage.getItem("userDetail");
  //   return getAllTestScores({
  //     uid,
  //     subCategory_id,
  //   });
  // };

  // console.log("152543543545",testScores)

  // const { isLoading: loadingSocres } = useQuery("test-category-card-scores", fetchScores, {
  //   onSuccess: (scores) => {
  //     setTestScores(scores.allPercentage);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  //   refetchOnWindowFocus: false,
  // });

  const retakeTestHendler = (e, subCategory_id) => {
    e.stopPropagation();
    const uid = localStorage.getItem("userDetail");
    // navigate(`/test/${subCategory_id}`, {
    //   state: {
    //     subCategory,
    //     description,
    //     subCategory_desc,
    //     category_desc,
    //     category,
    //     categoryId: category_id,
    //     subCategoryId: subCategory_id,
    //   },
    // });

    // {subCategory_id,subCategory,subCategory_desc: description,category_desc :state?.category_desc,category,categoryId:category_id,subCategoryId:subCategory_id}
    archivedAnswer({ uid, subCategory_id })
      .then((data) => {
        if (data) {
          navigate(`/test/${subCategory_id}`, {
            state: {
              subCategory,
              description,
              subCategory_desc,
              category_desc,
              category,
              categoryId: category_id,
              subCategoryId: subCategory_id,
              imageUrl,
            },
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.description) {
          setNotificationMessage(error?.response?.data?.description)
          // setCustomNotification(error?.response?.data?.description);
          setCustomNotification(true);
        }
      });
  };

  const unlockCard = (e, subCategory_id, makeAPI = false) => {
    e.stopPropagation();
    e.preventDefault();
    if (makeAPI) {
      if (usePayment) {
        const objForServer = {
          "user_email": userData?.user_email,
          "uid": userData?.uid,
          redirect_url: window.location.origin + '/testcategory/' + category_id + '/paymentresult'
        }
        if (selectedItem.category_id && selectedItem.subCategory_id) {
          objForServer["type"] = "SHOP_TEST"
          objForServer["category_id"] = selectedItem.category_id
          objForServer["subCategory_id"] = selectedItem.subCategory_id
          objForServer["subCategory"] = selectedItem.subCategory
        }
        PaymentService.CREATE_INVOICE(objForServer)
          .then((res) => {
            localStorage.setItem("selectedItem", res.paymentLink.id)
            localStorage.setItem("selected_item", JSON.stringify(selectedItem))
            setGeneratingPaymentLoader(false)
            setConfirmationVisible(false)
            if (res.paymentLink.url) {
              window.open(res.paymentLink.url, "_self")
            }
            // setSubcategories(res.subCategoryData)
            // setAllSubcategories(res.subCategoryData)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
          });
      } else {
        const uid = localStorage.getItem("userDetail");
        CategorySubcategoryService.UNLOCK_TEST({
          uid,
          "category_id": category_id,
          "subCategory_id": subCategory_id
        }).then((data) => {
          if (data) {
            const user = localStorage.getItem("userDetail");
            if (user) {
              getUserData(user)
                .then((response) => {
                  // navigate("/onbording");
                  dispatch(userProfileUpdate(response.data));
                })
                .catch((error) => {
                  console.log(error);
                })
            }
            navigate(`/test/${subCategory_id}`, {
              state: {
                subCategory,
                description,
                subCategory_desc,
                category_desc,
                category,
                categoryId: category_id,
                subCategoryId: subCategory_id,
                imageUrl,
              },
            });
          }
        })
          .catch((error) => {
            if (error?.response?.data?.description) {
              setNotificationMessage(error?.response?.data?.description)
              // setCustomNotification(error?.response?.data?.description);
              setCustomNotification(true);
            }
          });
      }

    } else {
      setSelectedItem({
        subCategory,
        description,
        percentage,
        onClickHandler,
        testStatus,
        time,
        points,
        subCategory_id,
        score,
        category_id,
        category,
        subCategory_desc,
        category_desc,
        imageUrl,
        handleClickOpen,
        coins,
        unlock,
        testUnlockIn,
        preDescription
      })
      setConfirmationVisible(true)
    }

  }

  return (
    <>
      <div
        className={classes.card}
        style={recommendation ? { border: '2px solid #9747FF'} : {}}
        onClick={() => {
          console.log("click handler called")
          onClickHandler()
        }}
      >
        {recommendation ? (
              <div className={classes.iconsWrapperRec} >
                <div className={classes.iconsLeftWrapperDisableRec}>
                  <div className={classes.recommendedicon} style={{
                    border: '1px solid #9747FF'
                  }}>
                    <Typography variant="body3" color="#9747FF" sx={{
                      fontSize: '0.875rem',
                      fontWeight: 400
                    }}>
                      {"Recommended"}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : null}
        <div>
        
          <div className={classes.imageWrapper}>
          
            <div className={classes.Imageoverlay}>

              <img
                src={imageURLRender(imageUrl)}
                alt=""
                className={classes.overlayEffect}
              />

            </div>
          
            <div className={classes.iconsWrapper}>
              <div className={classes.iconsLeftWrapper}>
                {/* <div className={classes.commingSoonicon}>
                <Typography variant="body3" color="#9747FF">
                  Soon
                </Typography>
              </div> */}
                <div className={classes.coinIconWrapper}>
                  <img src={Coin} alt="" />
                  <Typography variant="body3" color="#9747FF">
                    {coins}
                  </Typography>
                </div>
                <div className={`${classes.coinIconWrapper} ${classes.ml4}`}>
                  <img src={DiamondCoin} alt="" />
                  <Typography variant="body3" color="#9747FF">
                    {points}
                  </Typography>
                </div>
                <div className={`${classes.coinIconWrapper} ${classes.ml4}`}>
                  <img src={XP} alt="" style={{
                    height: '12px',
                    width: '12px',
                  }} />
                  <Typography variant="body3" color="#9747FF">
                    {total_questions}
                  </Typography>
                </div>
                <div className={`${classes.timeIcon} ${classes.ml4}`}>
                  <Typography variant="body3" color="#9747FF">
                    {time} min
                  </Typography>
                </div>
              </div>
              <div className={classes.iconsRightWrapper}>
                {showBookmark && (
                  <>
                    <div className={classes.avatarGroupWrapper}>
                      <AvatarGroup max={4}>
                        {/* <Avatar
                    alt=""
                    src={Avatarone}
                    sx={{ width: 24, height: 24, border: "unset !important" }}
                  />
                  <Avatar
                    alt=""
                    src={Avatartwo}
                    sx={{ width: 24, height: 24, border: "unset !important" }}
                  />
                  <Avatar
                    alt=""
                    src={Avatarthree}
                    sx={{ width: 24, height: 24, border: "unset !important" }}
                  /> */}
                        {/* <Avatar
                        alt=""
                        src={Avatarfour}
                        sx={{ width: 24, height: 24, border: "unset !important" }}
                        onClick={(e) => {
                          localStorage.setItem(
                            "selctedSubCategoryId",
                            subCategory_id
                          );
                          localStorage.setItem("selctedCategoryId", category_id);
                          handleClickOpen();
                          e.stopPropagation();
                        }}
                      /> */}
                      </AvatarGroup>
                    </div>
                    {/* <div className={classes.bookMarkIcon}>
                    <Checkbox
                      {...label}
                      disableRipple
                      sx={{ pl: "0", width: "20px", height: "20px" }}
                      checked={isTestBookMark}
                      onChange={(e) => setIsTestBookMark(e.target.checked)}
                      icon={
                        <img
                          src={Bookmark}
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "0px",
                          }}
                        ></img>
                      }
                      checkedIcon={
                        <img
                          src={BookmarkActive}
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "0px",
                          }}
                        ></img>
                      }
                    />
                  </div>
                  <div className={classes.shareIcon}>
                    <img src={Share} alt="" />
                  </div> */}</>
                )}

              </div>
            </div>
          </div>

          <div className={classes.testTitle}>
            <Typography variant="body4">{subCategory}</Typography>
          </div>
          <div className={classes.testDiscription}>
            <Typography variant="body2" color="#434243" className="text-3lines">
              {preDescription}
            </Typography>
          </div>
        </div>
        <div className={classes.testScores}>
          {/* <div key={index} className={classes.testResultScore}>
          <CircularProgressWithLabel value={percent} />
        </div> */}
          {score?.length === 0 ? (
            <div></div>
          ) : (
            score?.map((element, index) => {
              const { percent, archive } = element;
              return percentage === 100 ? (
                <div key={index} className={classes.testResultScore}>
                  <CircularProgressWithLabel value={percent} />
                </div>
              ) : archive ? (
                <div key={index} className={classes.testResultScore}>
                  <CircularProgressWithLabel value={percent} />
                </div>
              ) : null;
            })
          )}
          {/* <div key="1" className={classes.testResultScore}>
          <CircularProgressWithLabel value={20} />
        </div> */}
        </div>

        <div className={classes.testPrgressWrapper}>
          {/* <div className={classes.cardProgressBar}>
          <CircularProgressWithLabel value={percentage} />
        </div> */}
          {!unlock ? (
            <Grid
              container
              spacing={1.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ zIndex: 100 }}
            >
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                {currentTime && testUnlockIn !== currentTime && !loadingTime && <div className={classes.cardTimerBar}>
                  {currentTime || ''}
                </div>}
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div
                  className={classes.retakeButtonWrapper}
                  onClick={(e) => unlockCard(e, subCategory_id)}
                >
                  <Typography variant="body3" color="#9747FF">
                    Unlock
                  </Typography>
                </div>
              </Grid>
            </Grid>
          ) : percentage === 100 ? (
            <Grid
              container
              spacing={1.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <div className={classes.cardProgressBar}>
                  <LinearProgressWithLabel
                    value={percentage}
                    isprogressdisable={false}
                  />
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                {percentage === 100 && (
                  <div
                    className={classes.retakeButtonWrapper}
                    onClick={(e) => retakeTestHendler(e, subCategory_id)}
                  >
                    <Typography variant="body3" color="#9747FF">
                      Retake
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={percentage} />
            </div>
          )}
        </div>
      </div>

      {customNotification && (
        <NotificationContentJSX
          message={notificationMessage}
          isThumbIcon={false}
          position="top"
        />
      )}
      {visibleConfirmation && <ConfirmationModal
        visibleConfirmation={visibleConfirmation}
        setConfirmationVisible={setConfirmationVisible}
        selectedItem={selectedItem}
        // getSymboleAndAmountForBuy={getSymboleAndAmountForBuy}
        // unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
        // setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
        // setTestModalVisible={setTestModalVisible}
        // setDiamondModalVisible={setDiamondModalVisible}
        createInvoice={unlockCard}
        generatingPaymentLoader={generatingPaymentLoader}
      />}
    </>
  );
};

const TestCategoryCardDisable = ({
  subCategory,
  description,
  percentage,
  testStatus,
  time,
  points,
  subCategory_id,
  score,
  imageUrl,
  recommendation
}) => {
  console.log("recommendation", recommendation);
  if (testStatus === "SOON2") {
    return <div className={classes.card}>
      <div>
        <div className={classes.ImageoverlaySoonCard}>
          <span className={classes.questionImage1}>?</span>
          <span className={classes.questionImage2}>?</span>
          <span className={classes.questionImage3}>?</span>
          <div className={classes.sooncardBackground}>
            <div className={classes.ellipse1}></div>
            <div className={classes.ellipse2}></div>
            <div className={classes.ellipse3}></div>
            <div className={classes.ellipse4}></div>
          </div>
        </div>

        <div className={classes.testTitleDisableSoonCard}>
          {Array.from({ length: 5000 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#190D1A',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard1}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard2}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard3}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
      </div>


    </div>
  }
  return (
    <div className={classes.card}>
      <div>
        <div className={classes.imageWrapper}>
          <div className={classes.Imageoverlay}>

            <img src={imageURLRender(imageUrl)} alt="" />

          </div>
          <div className={classes.iconsWrapper}>
            <div className={classes.iconsLeftWrapperDisable}>
              <div className={classes.commingSoonicon}>
                <Typography variant="body3" color="#9747FF">
                  {testStatus?.charAt(0)?.toUpperCase() +
                    testStatus?.slice(1)?.toLowerCase().replace('1', "")}
                </Typography>
              </div>
              {recommendation ? (
                <div className={classes.recommendedicon} style={{
                  border: '1px solid #9747FF'
                }}>
                  <Typography variant="body3" color="#9747FF" sx={{
                    fontSize: '0.875rem',
                    fontWeight: 400
                  }}>
                    {"Recommended"}
                  </Typography>
                </div>

              ) : null}
              {/* <div className={classes.coinIconWrapperDisable}>
              <img src={Coin} alt="" />
              <Typography variant="body3" color="#DAD6DA">
                {points}
              </Typography>
            </div>
            <div className={classes.timeIconDisable}>
              <Typography variant="body3" color="#DAD6DA">
                {time} min
              </Typography>
            </div> */}
            </div>
            {/* <div className={classes.iconsRightWrapperDisable}>
              <div className={classes.bookMarkIcon}>
                <img src={Bookmark} alt="" />
              </div>
              <div className={classes.shareIconDisable}>
                <img src={Sharedisable} alt="" />
              </div>
            </div> */}
          </div>
        </div>

        <div className={classes.testTitleDisable}>
          <Typography variant="body4" color="#DAD6DA">
            {subCategory}
          </Typography>
        </div>
        <div className={classes.testDiscription}>
          <Typography variant="body2" color="#DAD6DA">
            {description?.substring(0, 100)}
            {description?.length > 100 && <span>...</span>}
          </Typography>
        </div>
      </div>

      <div className={classes.cardProgressBar}>
        <LinearProgressWithLabel value={percentage} isprogressdisable={true} />
      </div>

    </div>
  );
};

export default TestCategoryCardDisable;
