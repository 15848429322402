import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import parse from "html-react-parser";
import XP from "../../assets/Svg/XP.svg";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Header from "../../components/Header/Header";
import classes from "./ResultLayout.module.css";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import Sharedisable from "../../assets/Svg/Sharedisable.svg";
import Share from "../../assets/Svg/Share.svg";

import ReadMore from "../../components/ReadMore/ReadMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Calander from "../../components/Calander/Calander";
import useCustomNotification from "../../Hooks/useCustomNotification";
import {
  AllDiamentionByTest,
  AllDiamentionByTestInvite,
  archivedAnswer,
  categoryPage,
  feedbackDiamentionCreate,
  getAllTestScores,
  getQuestionsList,
  getTestDimensions,
  InviteQuetionList,
  SocialUserList,
  subcategoriesByInvite,
  unlockGAAction,
} from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/images/resizeCategory1662712270910_1662712270910.jpeg";
import {
  updateDimensions,
  updateSubCategoryId,
} from "../../Store/Reducers/dimensions";
import ResultCategoryCard, {
  LongMenu,
  ResultReflectiveCard,
  ResultReflectiveCardWhole,
} from "../../components/ResultCategoryCard/ResultCategoryCard";
import TestResultCategoriesList from "../TestResultCategoryList/TestResultCategoryList";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
import SocialResultUser from "../TestResultsPages/SocialResultUser/SocialResultUser";
import { useTheme } from "@emotion/react";
import { TestQuestionSlider } from "../../components/TestQuestionSlider/TestQuestionSlider";
import { PersonaltestResult } from "../../core-ui/PersonalTestResults/PersonaltestResult";
import { PersonalTestResult2 } from "../../core-ui/PersonalTestResults/PersonalTestResult2";
import {
  updateIsSelectedUser,
  updateSelectedIndividualInvitId,
  updateSelectedInviteData,
} from "../../Store/Reducers/selectedInviteData";
import { StepOne } from "../TestResultsPages/StepOne/StepOne";
import { updateModalData, updateResultDimention, updateResultState, updateTestResultDimention } from "../../Store/Reducers/ResultAllDiamention";
import ResultCongrats from "../TestResultsPages/ResultCongrates/ResultCongrats";
import { imageURLRender, randomXP } from "../../utils";
import StepperModal from "./stepperModal";
import useNotifications from "../../Hooks/useNotifications";
import { updateUserWalletCoins } from "../../Store/Reducers/userProfileSlice";
import ResultImage from "./resultImage";
import ResultDimention from "./resultDimention";
import ResultInfo from "./resultInfo";
const ResultLayoutData = () => {
  const label = { inputProps: { "aria-label": "Checkbox" } };
  // const { testCategories } = useSelector((state) => state);
  // const [dimensions, setDimensions] = useState([]);
  const dimensions = []
  const { selectedInviteDataSlice, categoriesIdSlice, resultAllDiamention } = useSelector(
    (state) => state
  );
  const {
    modalStep = false,
    sendmodalStep = false,
    congatesStep = false,
    isAskOtherModalOpen,
    modalData = {},
    selectedGrowthArr = [],
    selectedActionArr = [],
    finalSelectedGrowthArea = [],
    tempSelectedGrowthArea,
    tempSelectedAction,
    tempGrowhtArea,
    tempActionArea,
    resultTestDimention,
    isAskOtherModalOpenSocial,
    isTestBookMark,
    isLoading,
    testtypeofResult: typeofResult,
    dimensionsBtnData,
    ResultAllDiamentionByInvite,
    reflectiveQuestion,
    subcategories,
    selectedSubCategories,
    testselectLayoutBtn: selectLayoutBtn,
    selectedUserInviteId,
    selectedinviteId,
    inviteName,
    testQuetionCatagoriesId: QuetionCatagoriesId,
    testfinalirrelevantdata: finalirrelevantdata,
    testirreleventRating: irreleventRating,
    selectedPersonal,
  } = resultAllDiamention
  const setTypeofResult = val => dispatch(updateResultState({
    testtypeofResult: val
  }))
  const setDimensionsBtnData = val => dispatch(updateResultState({
    dimensionsBtnData: val
  }))
  const setResultAllDiamentionByInvite = val => dispatch(updateResultState({
    ResultAllDiamentionByInvite: val
  }))
  const setReflectiveQuestion = val => dispatch(updateResultState({
    reflectiveQuestion: val
  }))
  const setSubcategories = val => dispatch(updateResultState({
    subcategories: val
  }))
  const setSelectedSubCategories = val => dispatch(updateResultState({
    selectedSubCategories: val
  }))
  const setSelected = val => dispatch(updateResultState({
    testselectLayoutBtn: val
  }))
  const setSelectedUserInviteId = val => dispatch(updateResultState({
    selectedUserInviteId: val
  }))
  const setselectedInviteId = val => dispatch(updateResultState({
    selectedinviteId: val
  }))
  const setInviteName = val => dispatch(updateResultState({
    inviteName: val
  }))
  const setQuetionCatagoriesId = val => dispatch(updateResultState({
    testQuetionCatagoriesId: val
  }))
  const setFinalirrelevantdata = val => dispatch(updateResultState({
    testfinalirrelevantdata: val
  }))
  const setIrreleventRating = val => dispatch(updateResultState({
    testirreleventRating: val
  }))
  const setselectedPersonal = val => dispatch(updateResultState({
    selectedPersonal: val
  }))
  const setIsUsernotLoading = () => {

  }
  const setFinalinaccuratedata = () => {

  }
  // const [socialResultuser, setSocialResultuser] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    message: "Link copied to clipboard",
    isThumbIcon: true,
    position: "bottom",
  });

  console.log("ResultAllDiamentionByInvite = ", ResultAllDiamentionByInvite);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  // const [currntDate, setCurrntDate] = useState(new Date());
  const { state } = useLocation();
  const { testId } = useParams();
  const dispatch = useDispatch();
  const [inaccurateRatting, setinaccurateRatting] = useState("");
  const [selectedScore, setSelectedScore] = useState([]);
  const { Notification, updateNotificationState } = useNotifications(false);
  const {
    subCategory,
    score,
    imageUrl,
    description,
    coins,
    points,
    time,
    total_questions,
    subCategory_id,
    category_id,
  } = state;

  const UnlockGAAction = (type, object) => {
    unlockGAAction(object).then(data => {
      if (type === "GROWTH") {
        dispatch(updateModalData({
          growth: true,
          idx: 0
        }))
        dispatch(updateUserWalletCoins())

        // const modelInfo = {...modalData};
        // console.log("modelInfo", modelInfo);
        // modelInfo.data[0].unlockgrowthandactions[0] = {
        //   ...modelInfo.data[0].unlockgrowthandactions[0],
        //   growth: modelInfo.data[0].unlockgrowthandactions[0].growth + 3
        // }
        // setModalData(modelInfo)
        // dispatch(updateResultState({
        //   actionCount: growthCount + 3
        // }))
      }
      if (type === "ACTION") {

        dispatch(updateModalData({
          action: true,
          idx: 0
        }))
        dispatch(updateUserWalletCoins())
        // dispatch(updateResultState({
        //   actionCount: actionCount + 3
        // }))
        // const modelInfo = {...modalData};
        // modelInfo.data[0].unlockgrowthandactions[0] = {
        //   ...modelInfo.data[0].unlockgrowthandactions[0],
        //   action: modelInfo.data[0].unlockgrowthandactions[0].action + 3
        // }
        // setModalData(modelInfo)
      }
    }).catch(err => {
      if (err.response.data.description) {
        updateNotificationState(err.response.data.description);
        setTimeout(() => {
          updateNotificationState("", false);
        }, 2000);
        // setCustomNotification(err.response.data.description);
        // setCustomNotification(true);

      }
    }).finally(() => {

    })
  }
  const setIsLoading = val => dispatch(updateResultState({
    isLoading: val
  }))
  const setIsTestBookMark = val => dispatch(updateResultState({
    isTestBookMark: val
  }))
  const setIsAskOtherModalOpenSocial = val => dispatch(updateResultState({
    isAskOtherModalOpenSocial: val
  }))
  const setmodalStep = val => dispatch(updateResultState({
    modalStep: val
  }))
  const setSendmodalStep = val => dispatch(updateResultState({
    sendmodalStep: val
  }))
  const setCongatesStep = val => dispatch(updateResultState({
    congatesStep: val
  }))

  const setIsAskOtherModalOpen = val => dispatch(updateResultState({
    isAskOtherModalOpen: val
  }))
  const setModalData = val => dispatch(updateResultState({
    modalData: val
  }))

  const setselectedGrowthArr = val => dispatch(updateResultState({
    selectedGrowthArr: val
  }))
  const setselectedActionArr = val => dispatch(updateResultState({
    selectedActionArr: val
  }))

  const setFinalSelectedGrowthArea = val => dispatch(updateResultState({
    finalSelectedGrowthArea: val
  }))

  const setResultAllDiamention = val => dispatch(updateResultState({
    resultTestDimention: val
  }))

  const selctedSubCategoryId = localStorage.getItem("selctedSubCategoryId");
  const ref = useRef();

  const allDimentionByTest = () => {
    let uid;

    if (selectedUserInviteId) {
      uid = selectedUserInviteId;
    } else {
      const user = localStorage.getItem("userDetail");
      uid = user;
    }
    AllDiamentionByTest(uid, category_id, subCategory_id)
      .then((data) => {
        setResultAllDiamention(data?.allPercentage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    allDimentionByTest()
  }, [
    selectedUserInviteId,
    selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
  ]);
  const allDimentionByTestInvite = () => {
    let uid;
    if (selectedUserInviteId) {
      uid = selectedUserInviteId;
    } else if (selectedInviteDataSlice?.isSelectedSocialUser) {
      uid = selectedInviteDataSlice?.selectedinviteData?.inviteUserUid;
    } else {
      const user = localStorage.getItem("userDetail");
      uid = user;
    }
    AllDiamentionByTestInvite(uid, category_id, subCategory_id)
      .then((data) => {
        setResultAllDiamentionByInvite(data?.allPercentage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    allDimentionByTestInvite()
  }, [
    selectedUserInviteId,
    selectedPersonal,
    selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
  ]);

  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();

  // fetch api for reflective quetion-answer
  useEffect(() => {
    setIsLoading(true);
    let inviteID;
    let uid;
    if (selectedUserInviteId) {
      uid = selectedUserInviteId;
    } else {
      const user = localStorage.getItem("userDetail");
      uid = user;
    }
    if (selectedInviteDataSlice?.isSelectedSocialUser) {
      inviteID = selectedInviteDataSlice?.selectedIndividualInviteID;
      uid = selectedInviteDataSlice?.selectedinviteData?.inviteUserUid;
      setInviteName(selectedInviteDataSlice?.selectedinviteData?.name);
      setTypeofResult(TYPEOFRESULTS.SOCIAL);
    } else {
      inviteID = selectedinviteId;
    }
    if (inviteID) {
      InviteQuetionList(uid, inviteID)
        .then((data) => {
          setReflectiveQuestion(data?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    selectedinviteId,
    selectedUserInviteId,
    selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
  ]);
  //  sub category for score
  useEffect(() => {
    let category_id;
    let uid;
    category_id = categoriesIdSlice?.categoryid;
    // if (typeofResult === TYPEOFRESULTS.PERSONAL) {
    // }
    if (selectedInviteDataSlice?.isSelectedSocialUser) {
      uid = selectedInviteDataSlice?.selectedinviteData?.inviteUserUid;
      category_id = categoriesIdSlice?.categoryid;
      if (category_id) {
        subcategoriesByInvite({ uid, category_id })
          .then((data) => {
            setSubcategories(data?.subCategoryData);
            let spacificSubCategoryData = data?.subCategoryData?.filter(
              (data) => {
                return data.subCategory_id === selctedSubCategoryId;
              }
            );
            let scoreDataRelatedToUser = spacificSubCategoryData?.map((item) => {
              return item?.score;
            });
            setSelectedScore(scoreDataRelatedToUser);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    } else {
      const user = localStorage.getItem("userDetail");
      uid = user;
      category_id = categoriesIdSlice?.categoryid;
      if (category_id) {
        categoryPage({ uid, category_id })
          .then((data) => {
            setSubcategories(data?.subCategoryData);
            let spacificSubCategoryData = data?.subCategoryData?.filter(
              (data) => {
                return data.subCategory_id === selctedSubCategoryId;
              }
            );
            let scoreDataRelatedToUser = spacificSubCategoryData?.map((item) => {
              return item?.score;
            });
            setSelectedScore(scoreDataRelatedToUser);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    }
  }, [
    selectedInviteDataSlice?.isSelectedSocialUser,
    selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
  ]);
  const handleCloseSocial = () => {
    setIsAskOtherModalOpenSocial(false);
    setTimeout(() => {
      setIsUsernotLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (!selectedInviteDataSlice?.isSelectedSocialUser) {
      setTypeofResult(TYPEOFRESULTS.PERSONAL);
    }
  }, [
    selectedInviteDataSlice?.isSelectedSocialUser,
    isAskOtherModalOpenSocial,
  ]);

  const socialHandler = useCallback(() => {
    setTypeofResult(TYPEOFRESULTS.SOCIAL);
    setIsAskOtherModalOpenSocial(true);
    // setSocialHeaderText(true);
  }, [typeofResult]);
  // for modal
  const mainStateUpdateHandler = (rattingIrrelevnt, irrelevantData) => {
    setFinalirrelevantdata(irrelevantData);
    setIrreleventRating(rattingIrrelevnt);
  };
  const inaccurateHandler = (ratingInaccurate, inaccurateData) => {
    setinaccurateRatting(ratingInaccurate);
    setFinalinaccuratedata(inaccurateData);
  };
  const sendModalhandler = (irrr) => {
    setSendmodalStep(irrr);
  };
  const feedBackDataHandler = (ratingInaccurate, inaccurateData) => {
    setinaccurateRatting(ratingInaccurate);
    setFinalinaccuratedata(inaccurateData);
    const user = localStorage.getItem("userDetail");
    let FinalFeedBackData = {
      uid: user,
      ratingIrrelevant: irreleventRating,
      inaccurate: inaccurateData,
      ratingInaccurate: ratingInaccurate,
      questionCategoryId: QuetionCatagoriesId,
      irrelevant: finalirrelevantdata,
    };

    feedbackDiamentionCreate(FinalFeedBackData)
      .then((response) => {
        console.log("response>>>>>>", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          {...props}
          sx={{ width: "48px !important", height: "48px !important" }}
        // className={classes.circularProgress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            color="#9747FF"
            component="div"
            sx={{ padding: "12px" }}
          >
            {`${Math.round(props.value)}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const ContainerWrapper = styled("div")(({ theme }) => ({
    overflowY: "auto",
    height: "90vh",
    [theme.breakpoints.down("md")]: {
      height: "fit-content",
      marginTop: "4px",
    },
  }));

  // CustomDialog
  const CustomDialogSocial = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
      }
    }
  `;
  const PersonalButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "10px",
    fontSize: "10px !important",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const SocialButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",
    // color: "#190D1A",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const PersonPublic = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  }));


  console.log(" this re rendered ");
  return <>
    {/* isLoading ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "calc(100vh - 64px)",
        }}
      >
        <CircularProgress />
      </div>
    ) : */ (
        <Grid container spacing={1.5} className={classes.testListWrapper}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            xl={8}
          //   sx={{ background: 'yellow'  }}
          >
            <ResultInfo state={state} setCustomNotification={setCustomNotification} setToastMessage={setToastMessage} allDimentionByTest={allDimentionByTest} allDimentionByTestInvite={allDimentionByTestInvite}/>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
          >
            <ContainerWrapper>
              <div className={classes.scoreInfo}>
                <div className={classes.scoreTitle}>
                  <Typography variant="body4" color="#190D1A">
                    Switch between different scores to view previous results
                  </Typography>
                </div>
                <div className={classes.scoreDescription}>
                  <Typography
                    variant="subtitle1"
                    color="#434243"
                    sx={{ lineHeight: "145%" }}
                  >
                    By default we show you up-to-date results for your discovery
                  </Typography>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.testScores}>
                  {typeofResult === TYPEOFRESULTS.SOCIAL ? (
                    <div className={classes.testResultScore}>
                      {selectedScore[0]?.length === 0 ? (
                        <Typography variant="body2">
                          No score found for this test
                        </Typography>
                      ) : (
                        selectedScore[0]?.map((data) => {
                          const { archive, percent } = data;
                          return <CircularProgressWithLabel value={percent} />;
                        })
                      )}
                    </div>
                  ) : (
                    <div className={classes.testResultScore}>
                      {selectedScore[0]?.length === 0 ? (
                        <Typography variant="body2">
                          No score found for this test
                        </Typography>
                      ) : (
                        selectedScore[0]?.map((data) => {
                          const { archive, percent } = data;
                          return <CircularProgressWithLabel value={percent} />;
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
              {(selectedUserInviteId ||
                selectedInviteDataSlice?.isSelectedSocialUser) && (
                  <div className={classes.reflectiveQuestions}>
                    {reflectiveQuestion?.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                        <Typography variant="body4">
                          Reflective Question not found
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.reflectiveQuestionsTitle}>
                        <Typography variant="body4" color="#190D1A">
                          {inviteName} reflective questions on {subCategory}
                        </Typography>
                      </div>
                    )}

                    <div className={classes.breakLine}></div>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {
                        reflectiveQuestion?.map((data, i) => {
                          const { question, answer, inviteID } = data;
                          return (
                            <>
                              <ResultReflectiveCardWhole
                                question={question}
                                answer={answer}
                                inviteID={inviteID}
                                inviteName={inviteName}
                                index={i}
                              />
                              <Divider className={classes.divider} />
                            </>
                          );
                        })
                      }

                    </Grid>
                  </div>
                )}
            </ContainerWrapper>
          </Grid>
        </Grid>
      )}
    {console.log("modalStep")}
    {customNotification && <NotificationContentJSX {...toastMessage} />}
    {/* Ask other modal */}
    <CustomDialogSocial
      open={isAskOtherModalOpenSocial}
      onClose={handleCloseSocial}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <SocialResultUser
        subCategory_id={subCategory_id}
        category_id={category_id}
        setSelectedUserInviteId={setSelectedUserInviteId}
        setselectedInviteId={setselectedInviteId}
        setInviteName={setInviteName}
        handleClose={handleCloseSocial}
        setIsAskOtherModalOpenSocial={setIsAskOtherModalOpenSocial}
        setTypeofResult={setTypeofResult}
      // isUsernotLoading={isUsernotLoading}
      />
    </CustomDialogSocial>
    <StepperModal
      UnlockGAAction={UnlockGAAction}
      sendModalhandler={sendModalhandler}
      mainStateUpdateHandler={mainStateUpdateHandler}
      inaccurateHandler={inaccurateHandler}
      feedBackDataHandler={feedBackDataHandler}
      Notification={Notification}
      updateNotificationState={updateNotificationState}
    /></>
}

export default ResultLayoutData