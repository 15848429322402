import React from "react";
import classes from "./GrowthArea.module.css";
import SmallEngagement from "../../../assets/Svg/SmallEngagement.svg";
import moment from "moment";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { imageURLRender } from "../../../utils";

const GrowthArea = ({
  x,
  monthObject,
  updateData,
  drop,
  dragOver,
  colorPickData,
  daysInMonth,
  index,
  getCalenderDataSlice
}) => {
  // const { getCalenderDataSlice } = useSelector((state) => state);
  const UK_TIME = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  return (
    <div
      className={clsx(
        classes.calendar_Box,
        x == 1 || x == 15 ? classes.first_Of_End : classes.center_Of_End,
        x == 14 || x == daysInMonth()
          ? classes.last_Of_End
          : classes.center_Of_End
      )}
      id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")} ${index}`}
      onDragOver={(e) => dragOver(e)}
      onDrop={(e) => drop(e, false, "false")}
    >
      {getCalenderDataSlice?.getCalenderData?.map((ele, eindex) => {
        return (
          <div
            key={eindex}
            onClick={(e) => {
              updateData(ele, e);
            }}
            id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")} ${index}`}
            className={clsx(
              // d[0] <
              //   moment(new Date(ele.startDate)).format(
              //     "DD"
              //   ) &&
              //   d[d.length - 1] >
              //     moment(new Date(ele.startDate)).format(
              //       "DD"
              //     ) &&
              //   classes.occupy,
              ele?.dates?.map((itemRange, index) => {
                if (
                  moment(new Date(itemRange.date)).format("DD-MMMM, yyyy") ===
                  `${x}-${monthObject}`
                ) {
                  return classes.box_ga_Center;
                }
              }),
              moment(new Date(ele.startDate)).format("DD-MMMM, yyyy") ===
                `${x}-${monthObject}`
                ? classes.box_start_radius
                : classes.box_start_radius2,
              moment(new Date(ele.endDate)).format("DD-MMMM, yyyy") ===
                `${x}-${monthObject}`
                ? classes.box_redius
                : classes.box_redius2
            )}
            onDragOver={(e) => dragOver(e)}
            onDrop={(e) => drop(e, false, "false")}
            style={{
              backgroundColor: ele.colour ? ele.colour : "white",
              color: ele.textColour ? ele.textColour : "black",
              width:
                moment(new Date(ele.endDate)).format("DD-MMMM, yyyy") ===
                  `${x}-${monthObject}` && "94%",
            }}
          >
            {moment(new Date(ele.startDate)).format("DD-MMMM, yyyy") ===
              `${x}-${monthObject}` && (
              <div
                className={clsx(classes.question_box)}
                id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")}`}
                key={index}
              >
                <div
                  className={classes.dimention_box}
                  id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")}`}
                  onDragOver={(e) => dragOver(e)}
                  onDrop={(e) => drop(e, false, "false")}
                >
                  <img 
                    //src={SmallEngagement} 
                    src={imageURLRender(ele?.data?.url)}
                    height={'15px'}
                    alt=""
                  />
                  <Typography
                    variant="p"
                    className={classes.dimention_name}
                    id={`${x}-${monthObject} ${moment(new Date()).format(
                      "h a"
                    )}`}
                    style={{
                      color: ele.textColour ? ele.textColour : "black",
                    }}
                    onDragOver={(e) => dragOver(e)}
                    onDrop={(e) => drop(e, false, "New Question")}
                  >
                    {ele.questionCategory}
                  </Typography>
                </div>
                <p
                  id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")}`}
                  onDragOver={(e) => dragOver(e)}
                  onDrop={(e) => drop(e, false, "false")}
                  style={{ fontSize: "12px" }}
                >
                  {ele.name ? ele.name : colorPickData.text}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(GrowthArea);
