import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { archiveInviteTestAnswer, inviteTestReflectiveAnswer, inviteTestReflectiveQuestionList } from "../../../services/auth";
import typography from "../../../theme/typography";
import { MultiLineInput, SubHeadingTwo } from "../StyledComponent";
import classes from "./ReflectiveQuestion.module.css";

const ReflectiveQuestion = () => {
  const [reflactiveQuestion, setReflactiveQuestion] = useState([]);
  const [answer, setAnswer] = useState("")
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const nextButtonRef = useRef();
  const navigate = useNavigate()
  const {
    inviteDataSlice: { inviteData },
    } = useSelector((state) => state);
  useEffect(() => {
    const uid = localStorage.getItem("userDetail");
    const inviteID = localStorage.getItem("inviteId");
    // if (uid) {
    inviteTestReflectiveQuestionList({ uid, inviteID })
      .then((questions, i) => {
        console.log("reflactive questions", questions);
        setReflactiveQuestion(questions?.data);
      })
      .catch((error) => {
        console.log("57 error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // } else {
    //   console.log("user not logedin");
    //   navigate("/")
    // setIsLoading(false);
    // }
  }, []);

  const onNext = () => {
    const uid = localStorage.getItem("userDetail");
    const inviteID = localStorage.getItem("inviteId");
    const inviteQuestionID = reflactiveQuestion[questionIndex].inviteQuestionID;
    if (questionIndex <= reflactiveQuestion.length - 1) {
      setIsLoading(true);
      inviteTestReflectiveAnswer({uid,inviteID,inviteQuestionID,answer})
        .then((res) => {
          console.log(res);
          setAnswer("")
          if(questionIndex < reflactiveQuestion.length - 1){
            setQuestionIndex((prev) => prev + 1);
          }else{
        
              setIsLoading(true)
              archiveInviteTestAnswer({
                uid,
                inviteID,
                subCategory_id: inviteData?.subCategory_id,
              })
                .then((res) => {
                  navigate(`/invite/${inviteID}/congrats`);
                  console.log(res);

                })
                .catch((err) => {
                  console.log(err);
                }).finally(()=>{
                  setIsLoading(false)
                })

          }
        })
        .catch((err) => console.log("error in reflactive question"))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   console.log("nextButtonRef 123",nextButtonRef)
  //   nextButtonRef.current?.focus()
  // }, [questionIndex,nextButtonRef.current])

  const onPrevious = () => {
    if (questionIndex !== 0) {
      setQuestionIndex((prev) => prev - 1);
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : isLoading ? (
        <div>No Question Found</div>
      ) : (
        <div className={classes.wrapper}>
          <div></div>
          <div className={classes.middelContent}>
            <div className={classes.headerWrapper}>
              <Typography variant="h3">
                {inviteData?.name ? inviteData?.name : "Guest" } prepared some reflective questions for you
              </Typography>
            </div>
            <SubHeadingTwo>
              <Typography variant="subtitle1" color="#190D1A">
                {reflactiveQuestion[questionIndex]?.question}
              </Typography>
            </SubHeadingTwo>
            <div className={classes.textInput}>
              <MultiLineInput
                multiline
                size="small"
                fullWidth
                className={classes.multilineTextField}
                  autoFocus={true}
                key="description"
                type="text"
                placeholder="text"
                id="outlined-start-adornment"
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value)
                  }}
              />
              <div className={classes.wordCount}>
                <Typography variant="body2" color="#434243">
                  {questionIndex + 1}/{reflactiveQuestion.length}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <ButtonGroup
              onNext={onNext}
              onPrevious={onPrevious}
              updateMainState={() => {}}
              isNextDisable={!answer}
              isPreviousBtnDisable={questionIndex === 0}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReflectiveQuestion;
