import React, { useEffect, useState } from "react";
import classes from "./GrowthCalendar.module.css";
import { RightSideContentWrapper } from "../../components/GoalComponent/GoalCustomDiv";
import GcHeader from "../../components/Header/GcHeader";
import CalendarHeader from "../../components/GrowthCalendar/CalendarHeader/CalendarHeader";
import { CALENDARTYPE } from "../../Constant/GoalConstant";
import MonthlyCaledar from "../../components/GrowthCalendar/MonthlyCalendar/MonthlyCaledar";
import WeeklyCalendar from "../../components/GrowthCalendar/WeeklyCalendar/WeeklyCalendar";
import { getWeek } from "date-fns";
// import SideBar from "../../components/GrowthCalendar/SideBar/SideBar";
import SideBar from "./GrowthSidebar";
import { Grid, useMediaQuery } from "@mui/material";
import moment from "moment";
import {
  getGrowthData,
  growthCalenderGetCalenderData,
} from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../Store/Reducers/goalGrowthCalendar";
import {
  storeGetActionData,
  storeGetCalendarData,
  storeGetHabitData,
  updateIsGetCalenderData,
} from "../../Store/Reducers/getCalendarData";
import { MobileSiderBar } from "../../components/GoalComponent/GoalDialog";
import { useTheme } from "@emotion/react";
import ModelSideBar from "../../components/GrowthCalendar/SideBar/ModelSidebar";

const GrowthCalendar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [showSidebar, setShowSidebar] = useState(true)
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const [growthData, setGrowthData] = useState(getAllGrowthData.GetGrowthData);
  const dispatch = useDispatch();
  const [typeCalendar, setTypeCalendar] = React.useState(
    CALENDARTYPE.MONTHLY_CALENDAR
  );
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const [currentMonthOfWeek, setCurrentMonthOfWeek] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonthOfWeek));
  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };
  const [monthObject, setMonthObject] = useState(moment().format("MMMM, yyyy"));
  const [dateObject, setDateObject] = useState(moment());
  const [fullscreen, setfullscreen] = useState(false);
  const [selectedGrowthArr, setSelectedGrowthArr] = useState([]);
  const [bgcolor, setBgColor] = useState("");
  const [getGrowthCalendarDate, setGetGrowthCalendarDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [calendarData, setCalendarData] = useState({});
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [isWeekTrue, setIeWeekTrue] = useState(false);
  const getGrowthd = () => {
    let growthData = {
      uid: localStorage.getItem("userDetail"),
      archive: false,
      status: "IN_PROGRESS",
    };
    // if (!getAllGrowthData?.GetGrowthData.length) {
      getGrowthData(growthData)
        .then((res) => {
          dispatch(storeGetGrowthData(res?.data));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateIsGetGrowthData(true));
        });
    // }
  }
  const getCalendars = () => {
    const getgrowthCalenderData = {
      uid: localStorage.getItem("userDetail"),
      from: getGrowthCalendarDate.fromDate,
      to: getGrowthCalendarDate.toDate,
    };
    if (getGrowthCalendarDate.fromDate) {
      setIeWeekTrue(true);
      growthCalenderGetCalenderData(getgrowthCalenderData)
        .then((res) => {
          dispatch(storeGetCalendarData(res?.data));
          dispatch(storeGetActionData(res?.actionData));
          dispatch(storeGetHabitData(res?.habitCalenderData));
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          dispatch(updateIsGetCalenderData(true));
          setIeWeekTrue(false);
        });
    }
  }
  useEffect(() => {
    getGrowthd()
  }, []);
  useEffect(() => {
    getCalendars()
  }, [getGrowthCalendarDate]);
  
  return (
    <div className={classes.main_wrapper}>
      <BackgroundRadient />
      
      <RightSideContentWrapper>
        <GcHeader showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
        <Grid container spacing={2} className={`${showSidebar === true ? classes.showOpenGrid : null}`}>
          {!matches && (
            <Grid
              xs={12}
              sm={12}
              md={fullscreen ? 12 : 3}
              lg={fullscreen ? 12 : 3}
              xl={fullscreen ? 12 : 3}
              item
            >
              <SideBar
                setfullscreen={setfullscreen}
                fullscreen={fullscreen}
                growthData={growthData}
                setGrowthData={setGrowthData}
                setSelectedGrowthArr={setSelectedGrowthArr}
                selectedGrowthArr={selectedGrowthArr}
                setBgColor={setBgColor}
                bgcolor={bgcolor}
              />
            </Grid>
          )}
          <Grid
            xs={12}
            sm={12}
            md={fullscreen ? 12 : 9}
            lg={fullscreen ? 12 : 9}
            xl={fullscreen ? 12 : 9}
            item
            sx={{ width: "100%", position: "relative", overflow: "hidden" }}
          >
            <div
              className={classes.calendar}
              style={{ overflowX: matches && "hidden" }}
            >
              <CalendarHeader
                typeCalendar={typeCalendar}
                setTypeCalendar={setTypeCalendar}
                setCurrentMonthOfWeek={setCurrentMonthOfWeek}
                currentMonthOfWeek={currentMonthOfWeek}
                currentWeek={currentWeek}
                setCurrentWeek={setCurrentWeek}
                setMonthObject={setMonthObject}
                monthObject={monthObject}
                setDateObject={setDateObject}
                dateObject={dateObject}
                getGrowthCalendarDate={getGrowthCalendarDate}
                matches={matches}
                setIeWeekTrue={setIeWeekTrue}
              />
              {typeCalendar === "WEEKLY_CALENDAR" || matches ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  item
                  sx={{ width: "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    <WeeklyCalendar
                      showDetailsHandle={showDetailsHandle}
                      setCurrentMonthOfWeek={setCurrentMonthOfWeek}
                      currentMonthOfWeek={currentMonthOfWeek}
                      currentWeek={currentWeek}
                      setCurrentWeek={setCurrentWeek}
                      setSelectedGrowthArr={setSelectedGrowthArr}
                      selectedGrowthArr={selectedGrowthArr}
                      setGetGrowthCalendarDate={setGetGrowthCalendarDate}
                      getGrowthCalendarDate={getGrowthCalendarDate}
                      setBgColor={setBgColor}
                      bgcolor={bgcolor}
                      setCalendarData={setCalendarData}
                      calendarData={calendarData}
                      matches={matches}
                      setIsAskOtherModalOpen={setIsAskOtherModalOpen}
                      isAskOtherModalOpen={isAskOtherModalOpen}
                      typeCalendar={typeCalendar}
                      setIeWeekTrue={setIeWeekTrue}
                      isWeekTrue={isWeekTrue}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  item
                  sx={{ width: "100%" }}
                >
                  <div style={{ width: "100%", display: "contents" }}>
                    <MonthlyCaledar
                      setMonthObject={setMonthObject}
                      monthObject={monthObject}
                      setDateObject={setDateObject}
                      dateObject={dateObject}
                      setSelectedGrowthArr={setSelectedGrowthArr}
                      selectedGrowthArr={selectedGrowthArr}
                      setBgColor={setBgColor}
                      bgcolor={bgcolor}
                      setGetGrowthCalendarDate={setGetGrowthCalendarDate}
                      getGrowthCalendarDate={getGrowthCalendarDate}
                      setCalendarData={setCalendarData}
                      calendarData={calendarData}
                      getGrowthd={getGrowthd}
                      getCalendars={getCalendars}
                    />
                  </div>
                </Grid>
              )}
            </div>
          </Grid>
          {
            <MobileSiderBar
              open={isAskOtherModalOpen}
              action={
                <ModelSideBar
                  setfullscreen={setfullscreen}
                  fullscreen={fullscreen}
                  growthData={growthData}
                  setGrowthData={setGrowthData}
                  setSelectedGrowthArr={setSelectedGrowthArr}
                  selectedGrowthArr={selectedGrowthArr}
                  setBgColor={setBgColor}
                  bgcolor={bgcolor}
                  setIsAskOtherModalOpen={setIsAskOtherModalOpen}
                />
              }
            ></MobileSiderBar>
          }
        </Grid>
      </RightSideContentWrapper>
    </div>
  );
};

export default React.memo(GrowthCalendar);

const BackgroundRadient = () => {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1
    }}>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '826.65px',
        top: '629.11px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '720px',
        height: '653px',
        left: '0',
        top: '-104px',

        background: '#E5D5FC',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '571px',
        height: '517px',
        left: '854px',
        top: '0px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1171.43px',
        top: '446.5px',

        background: '#F3E9F7',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1326.54px',
        top: '646.06px',

        background: '#FCD686',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '444px',
        top: '-40px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '16px',
        top: '434px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
    </div>
  )
}