import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import React, { useState } from "react";
import classes from "./GoalArchiveAccordian.module.css";
function GrowthAreaArchiveAccrordian({ preSelectedGrowthComplete }) {
  const [growthAccordian, setGrowthAccordian] = useState(false);
  const GrowthAccordianHandler = (panel) => (event, isExpanded) => {
    setGrowthAccordian(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={growthAccordian === "panel"}
        onChange={GrowthAccordianHandler("panel")}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
          className={classes.subAccordianSummery}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            "& .Mui-expanded": {
              margin: "0px 0px !important",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
            },
          }}
          //
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Growth Area
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.GrowthAccordianDetailContainer}>
          <div style={{ position: "relative" }}>
            <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
              Completed
            </Typography>
            {preSelectedGrowthComplete?.length === 0 ? (
              <div className={classes.dataNotFoundSubWrapper}>
                <Typography variant="body2">
                  Completed growth area not found
                </Typography>
              </div>
            ) : (
              preSelectedGrowthComplete?.map((data, index) => {
                const { text, _id } = data;
                return (
                  <div className={classes.customRadioAction} key={_id}>
                    <div className={classes.actionNameWrapper}>
                      {data?.count > 1 ? (
                        <div className={classes.duplicateTextWrapper}>
                          <img
                            src={CheckedCheckbox}
                            className={classes.checkBoxIcon}
                            alt=""
                          />
                          <Typography variant="body3">
                            {data?.count}x
                          </Typography>
                        </div>
                      ) : (
                        <img
                          src={CheckedCheckbox}
                          className={classes.checkBoxIcon}
                          alt=""
                        />
                      )}

                      <Typography
                        variant=" body2"
                        className={classes.checkBoxChekedText}
                      >
                        {" "}
                        {text}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <img
                        style={{
                          margin: "4px 0px 0px -7px",
                          height: "18px",
                        }}
                        src={tripleDot}
                        alt="ExpandMore"
                        onClick={() => {}}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default GrowthAreaArchiveAccrordian;
