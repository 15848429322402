// SETUP COLORS
const PRIMARY = {
  main: "#9747FF",
  contrast: "#9747FF",
  contrastText: "#fff",
  light: "#9747FF",
};
const SECONDARY = {
  main: "#3366FF",
  contrastText: "#fff",
  contrast: "#9BA19D",
  light: "#F7F9FA",
};

const palette = {
  common: { black: "#000", white: "#fff", bgColor: "#F7F9FA" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  borderRadius: { md: "10px" },
};

export default palette;
