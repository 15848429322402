import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Coin from "../../../assets/Svg/Coin.svg";
import typography from "../../../theme/typography";
import classes from "./CongratsPage.module.css";
import Confetti from "react-confetti";
import { SubHeading } from "../StyledComponent";
import { SubmitButtonWithBackGround } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { archiveInviteTestAnswer } from "../../../services/auth";

const CongratsPage = () => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const confettiRef = useRef(null);
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
  }, []); //we need dependencies here for check in responsive mode in inspact but we don't need because it's automatically detect width here when in production mode
  console.log("confettiEffect", height, width);
  const navigate = useNavigate();

  const {
    inviteDataSlice: { inviteData },
  } = useSelector((state) => state);

  const onNext = () => {
      navigate("/testcategory");
  };
  return (
    <>
      <div className={classes.invitePageWrapper} ref={confettiRef}>
        <Confetti width={width} height={height} />
        <div></div>
        <div className={classes.headingWrapper}>
          {/* <Confetti width={width} height={height} /> */}
          <div className={classes.headingText}>
            <Typography variant="h3">
              Congrats on completing{" "}
              {inviteData?.subCategory
                ? inviteData?.subCategory
                : "VIA-IS Revised through Rasch modeling test "}{" "}
              for {inviteData?.name ? inviteData?.name : "guest"}
            </Typography>
          </div>
          <SubHeading>
            <div className={classes.subHeadingText}>
              <Typography variant="subtitle1" color="#434243">
                You have earned
              </Typography>
            </div>
          </SubHeading>
          <div className={classes.coinIconWrapper}>
            <img src={Coin} alt="" />
            <Typography variant="body3" color="#9747FF">
              20
            </Typography>
          </div>
        </div>
        <div className={classes.nextButton}>
          <SubmitButtonWithBackGround
            buttonText="Go back to home"
            onClick={onNext}
            isDisabled={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default CongratsPage;
