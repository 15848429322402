import { useTheme } from "@emotion/react";
import { styled, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import classes from "./index.module.css"
import BtmNavigation from "../../../components/BottomNavigation/BtmNavigation";
import ResponsiveSideBar from "../../../components/ResponsiveSideBar/ResponsiveSideBar";
import { RightSide, RootStyle } from "../../../components/Settings/StyledElement/StyledDiv";

export default function SettingDashbord() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <RootStyle className={classes.main_wrapper}>
      {matches ? null : <ResponsiveSideBar />}
      <RightSide>
        <BackgroundRadient />
        <Outlet />
      </RightSide>
      {matches ? <BtmNavigation /> : null}
    </RootStyle>
  )
}


const BackgroundRadient = () => {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1
    }}>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '826.65px',
        top: '629.11px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '720px',
        height: '653px',
        left: '0',
        top: '-104px',

        background: '#E5D5FC',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '571px',
        height: '517px',
        left: '854px',
        top: '0px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1171.43px',
        top: '446.5px',

        background: '#F3E9F7',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1326.54px',
        top: '646.06px',

        background: '#FCD686',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '444px',
        top: '-40px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '16px',
        top: '434px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
    </div>
  )
}