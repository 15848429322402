import styled from "@emotion/styled";
import { Button } from "@mui/material";
 export const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "13.25rem auto",
  height: "100vh",
  height: "100vh",
  minHeight: "100vh",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
  position: "relative",
}));

 export const RightSide = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  height: "102vh",
  overflow: "hidden",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    overflowY: "auto",
  },
}));
export const ProfileInfoButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "16px",
}));
 export const RightSideContentWrapper = styled("div")(({ theme }) => ({
  padding: "0px 20px",
  overflowY: "auto",
  height: "100vh",
}));
 export const ValidationMessage = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  marginTop: "8px",
}))