import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./BottomNavigation.module.css";
import FavoriteIcon from "@mui/icons-material/Star";
import { Link, useLocation } from "react-router-dom";

export default function BtmNavigation() {
  const path = useLocation();
  const [value, setValue] = useState("recent");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (path.pathname.includes("test")) {
      setValue("Tests");
    }
    if (path.pathname.includes("results")) {
      setValue("Results");
    }
    if (path.pathname.includes("goals")) {
      setValue("Goals");
    }
    if (path.pathname.includes("growthCalendar")) {
      setValue("Growthcalendar");
    }
  });
  return (
    <div className={classes.bottomNavigationWrapper}>
      <BottomNavigation
        showLabels
        sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction
          component={Link}
          to="/testcategory"
          label="Tests"
          icon={<FavoriteIcon />}
          value="Tests"
        />
        <BottomNavigationAction
          component={Link}
          to="/results"
          label="Results"
          icon={<FavoriteIcon />}
          value="Results"
        />
        <BottomNavigationAction
          component={Link}
          to="/growthCalendar"
          label="Growth Calendar"
          icon={<FavoriteIcon />}
          value="Growthcalendar"
        />
        <BottomNavigationAction
          component={Link}
          to="/goals"
          label="Goals"
          icon={<FavoriteIcon />}
          value="Goals"
        />
        <BottomNavigationAction
          component={Link}
          to="/Settings"
          label="Settings"
          icon={<FavoriteIcon />}
          value="Settings"
        />
      </BottomNavigation>
    </div>
  );
}
