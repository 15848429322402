import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import typography from "../../../../theme/typography";
import classes from "./StepFour.module.css";
import PlusIcon from "../../../../assets/Svg/PlusIcon.svg";
import styled from "@emotion/styled";
import CloseSmalIcon from "../../../../assets/Svg/CloseSmalIcon.svg";
import { v4 as uuidv4 } from "uuid";
const selectionArr = [
  { title: 'What examples did you had in mind when you were responding?', },
  { title: 'What recommendations do you have for me?', },
  { title: 'What actions should I take?', },

]
const StepFour = (props) => {
  const [addQuestionIndex, setAddQuestion] = useState(0);
  const [questionList, setQuestionList] = useState(
    props?.inviteData?.questions
  );
  const [questionText, setQuestionText] = useState("");
  const [inputFocus, setInputFocus] = useState(true);
  const [open, setOpen] = useState(true);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
    // useEffect(() => {
    //   if(!questionText.length) {
    //     openPopper()
    //   } else if(open) {
    //     closePopper()
    //   }
    // }, [questionText])
  console.log("step4");
  const addQuestionHandler = () => {
    // if (addQuestionIndex < 3) {
    if (questionText !== "") {
      setQuestionList([
        ...questionList,
        { id: uuidv4(), question: questionText },
      ]);
      setAddQuestion((prev) => prev + 1);
      if (questionText !== "") {
        setQuestionText("");
      }
      openPopper()
    }

    // }
  };
  const removeQuestionHandler = (id) => {
    if (addQuestionIndex > 0) {
      const updatedQuestionArray = questionList.filter((question) => {
        return question.id !== id;
      });
      setQuestionList(updatedQuestionArray);
      setAddQuestion((prev) => prev - 1);
    }
  };

  // last remove Handler
  const lastremoveHandler = () => {
    //  console.log("called")
    if (questionText !== "") {
      setQuestionText("");
    }
  };

  const updateMainState = () => {
    let updatedQuestionList = questionList?.map((question) => {
      delete question.id;
      return question;
    });
    if (questionText !== "") {
      updatedQuestionList.push({
        question: questionText,
      });
    }
    console.log("updatedQuestionList", updatedQuestionList);
    props.updateInviteData("questions", updatedQuestionList);
  };
  console.log("questionText", questionText, "-");
  const InputField = styled(TextField)`
    height: 56px;
    background: #f9f9f9;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
  `;
  const PopperMy = function (props) {
    return <Popper {...props} sx={{
      borderRadius: '16px',
      background: '#FFF',
      padding: '20px',
      boxShadow: '0px 4px 40px 0px rgba(17, 17, 17, 0.08)',
      marginTop: '10px !important',
      '& .MuiAutocomplete-listbox': {
        padding: 0,
        '& li': {
          marginLeft: 0
        },
        '& .MuiListSubheader-root': {
          color: '#B4B0B4',
          padding: 0
        }
      }
    }} placement="bottom-start" />;
  };
  const options = selectionArr.map((option) => {
    return {
      firstLetter: 'We suggest these',
      ...option,
    };
  });
  console.log("open", open);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className={classes.stepOneWrapper}>
      <ProgressBar progress={props.progress} />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", mt: "32px" }}
        >
          Would you like to add reflective questions?
        </Typography>
        <div className={classes.questionListWrapper}>
          {questionList?.slice(0, addQuestionIndex).map((input, index) => {
            return (
              <Box className={classes.wrapper} key={input.id}>
                {console.log("input>>", input)}
                <InputField
                  size="small"
                  fullWidth
                  value={input.question}
                  type="text"
                  placeholder="Question 1"
                  id="outlined-start-adornment"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => removeQuestionHandler(input?.id)}
                          edge="end"
                        >
                          <img src={CloseSmalIcon} alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            );
          })}
          {/* {

            addQuestionIndex < 3 && <Box className={classes.wrapper}>
              <InputField
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                size="small"
                fullWidth
                autoFocus
                type="text"
                placeholder="Type in your question"
                id="outlined-start-adornment"
              />
            </Box>

          } */}
          <Box className={classes.wrapper}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              onChange={(e, val) => {
                console.log("val", val)
                setQuestionText(val.title)
                closePopper()
              }}
              PopperComponent={PopperMy}
              open={open}
              // onOpen={openPopper}
              onClose={closePopper}

              renderOption={(props, option) => {
                console.log("option", option);
                return (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, borderBottom: option.title !== selectionArr[selectionArr.length - 1].title &&  '1px solid #F7F2FE', marginLeft: 0, padding: '12px 0 12px 0!important', }} {...props}>
                    <Typography sx={{ color: '#190D1A', fontSize: '14px', fontWeight: 400 }}>
                      {option.title}
                    </Typography>
                  </Box>
                )
              }}
              inputValue={questionText}
              options={options}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}

              renderInput={(params) => (
                <InputField
                  value={questionText}
                  onChange={(e) => {
                    if(!e.target.value.length) {
                      openPopper()
                    } else if(open) {
                      closePopper()
                    }
                    setQuestionText(e.target.value)
                  }}
                  size="small"
                  fullWidth
                  autoFocus={inputFocus}
                  type="text"
                  placeholder="Type in your question"
                  id="outlined-start-adornment"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {addQuestionIndex > 0 && questionText !== "" && (
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => lastremoveHandler()}
                          >
                            <img src={CloseSmalIcon} alt="" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  {...params}
                />
              )}
            />

          </Box>
          {/* {
            addQuestionIndex < 3 && <div className={classes.addMoreQuestionBtn} onClick={addQuestionHandler}>
              <Typography
                variant="body3"
                color="#9747FF"
                className={classes.addmoreTextWrapper}

              >
                Add more
                <img src={PlusIcon} alt="" style={{ marginLeft: "4px" }} />
              </Typography>
            </div>
          } */}
          <div
            className={classes.addMoreQuestionBtn}
            onClick={addQuestionHandler}
          >
            <Typography
              variant="body3"
              color="#9747FF"
              className={classes.addmoreTextWrapper}
            >
              Add more
              <img src={PlusIcon} alt="" style={{ marginLeft: "4px" }} />
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
        <ButtonGroup
          {...props}
          updateMainState={updateMainState}
          isNextDisable={questionText === ""}
        />
      </div>
    </div>
  );
};

export default StepFour;
