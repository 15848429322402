import { Box } from "@mui/system";
import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import classes from "./Header.module.css";
import Premium from "../../assets/Svg/Premium.svg";
import Notification from "../../assets/Svg/Notification.svg";
import Profile from "../../assets/Svg/Profile.svg";
import RightArrow from "../../assets/Svg/RightArrow.svg";
import { Avatar, Badge, Button, Popover, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import XP from "../../assets/Svg/XP.svg";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import { useDispatch, useSelector } from "react-redux";
import { imageURLRender, userNamerender } from "../../utils";
import React, { useEffect, useRef, useState } from "react";
import NotificationPopover from "./NotificationPopover";
import NotificationService from "../../services/Notifications";
import { setAnchorEl, setXposition, setYposition, updateNotificationData, updateUnreadNotificationData } from "../../Store/Reducers/headerData";
import HeaderPopover from "./HeaderPopover";
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 2px',
    fontSize: '0.50rem'
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userProfile);
  const { notifications, alreadyCalled, unreadnotifications } = useSelector(state => state.headerDataSlice)
  console.log("headerData", notifications);
  const NavigationLink = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));

  const HeaderWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "64px",
    /* background: yellow; */
    padding: "12px 0px",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  }));
  const popRef = useRef()
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [xposition, setXposition] = useState(0);
  // const [yposition, setYposition] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [selectedTab, handleChangeSelectedTab] = useState(
    'All'
  )
  const handleClickPopover = (event) => {
    dispatch(setXposition(popRef.current.getBoundingClientRect().left))
    dispatch(setYposition(popRef.current.getBoundingClientRect().top + 40))
    dispatch(setAnchorEl(event.currentTarget))
  };
  const handleClose = () => {
    dispatch(setAnchorEl(null));
  }
  useEffect(() => {
    const user = localStorage.getItem("userDetail");

    if ((!notifications || !notifications.length) && user && !alreadyCalled) {
      NotificationService.LIST_NOTIFICATION({
        uid: user,
      })
        .then((res) => {
          console.log("res", res);
          dispatch(updateNotificationData(res?.notificationData));
          dispatch(updateUnreadNotificationData(res?.unread));
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // this.setState({
          //   buttonLoading: false
          // })
          // this.openCloseEditGoalModal(false)
        });
    }
    console.log("notificationData", "notification rendered")
  }, [alreadyCalled])
  console.log("userData?.imageUrl", userData, userData?.imageUrl && userData?.imageUrl !== "Not updated" && Object.keys(userData?.imageUrl).length);
  return (
    <HeaderWrapper>
      <NavigationLink>
        <HeaderNavigation />
      </NavigationLink>
      <Box className={classes.profileInfoWrapper}>
        <div className={classes.coinIconWrapper}>
          <div className={classes.goldCoinIconWrapper}>
            <img src={XP} alt="" />
            <Typography variant="body3" color="#190D1A">
              {userData?.xp || 0}
            </Typography>
          </div>
          <div className={classes.goldCoinIconWrapper}>
            <img src={Coin} alt="" />
            <Typography variant="body3" color="#190D1A">
              {userData?.wallet?.coins || 0}
            </Typography>
          </div>
          <div className={`${classes.goldCoinIconWrapper} ${classes.ml4}`}>
            <img src={DiamondCoin} alt="" />
            <Typography variant="body3" color="#190D1A">
              {userData?.wallet?.diamonds || 0}
            </Typography>
          </div>
        </div>

        <StyledBadge color="primary" badgeContent={unreadnotifications.length} max={99} >
          <div className={classes.notificationButton} onClick={handleClickPopover} ref={popRef}>
            <img src={Notification} alt="notificatin" />
          </div>
        </StyledBadge>


        <HeaderPopover  handleClose={handleClose} />
        {/* <div className={classes.premiumButton}>
          <img src={Premium} alt="premium" />
          <Typography variant="body3" color="#FFFFFF">
            Premium
          </Typography>
        </div> */}
        <div onClick={() => navigate('/settings')}>
          {userData?.imageUrl && userData?.imageUrl !== "Not updated" && Object.keys(userData?.imageUrl).length ? <img
            src={
              userData?.imageUrl === "Not updated"
                ? Profile
                : imageURLRender(userData?.imageUrl)
            }
            alt="profile"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          /> : <Avatar sx={{ width: "40px", height: "40px", background: '#9847ff' }}>{userNamerender(userData?.name)}</Avatar>}
        </div>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
