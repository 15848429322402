import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
    questionsList: [],
}

export const questionsListSlice = createSlice({
    name: 'questionsListSlice',
    initialState,
    reducers: {
        storeQuestionList: (state, action) => {
            state.questionsList = action.payload
        },
        storeAnswerId: (state, action) => {
            state.questionsList = current(state.questionsList).map((question) => {
                if (question.questionData.questionId === action.payload.questionId) {
                    if (!question.answerId.includes(action.payload.answerId)) {
                        return { ...question, answerId: [action.payload.answerId] }
                    } else {
                        return question
                    }
                }
                return question
            })
        },
        storeAnswerValue: (state, action) => {
            state.questionsList = current(state.questionsList).map((question) => {
                let index=0;
                const ansId = question.answerId[0]
                question.questionData.options.map((option, i) => {
                        if(option.answerId === ansId){
                          index = i + 1
                        }
                })
                if(ansId){
                    return { ...question , ansValue : (index * question?.questionData?.options?.length) - question?.questionData?.options?.length}
                }else{
                    return { ...question , ansValue : index }
                }
            })
        },
        
    },
})

// Action creators are generated for each case reducer function
export const { storeQuestionList, storeAnswerId,storeAnswerValue } = questionsListSlice.actions

export default questionsListSlice.reducer