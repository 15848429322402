import React from "react";
import classes from "./StepTwoRight.module.css";
import { FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CircularProgressWithLabel from "../../../components/CircularProgressBar/CircularProgressBar";
import RadioIconfnc from "../../../components/RadioIcon/RadioIcon";

function SteptwoRight({ modalData }) {
  return (
    <Box className={classes.mainWrapper}>
      <Box sx={{ display: "flex" }}>
        {modalData.data.map((item) => {
          return item.score?.map((ele) => {
            return (
              <Box className={classes.circularprogressBar}>
                <CircularProgressWithLabel value={ele.percent.toFixed()} />
              </Box>
            );
          });
        })}
      </Box>
      <Box className={classes.ContentWrapper}>
        <Typography variant="h4" className={classes.ZestHeading}>
          {modalData?.questionCategory}
        </Typography>
        <Typography variant="body2" className={classes.ContentText}>
          {modalData?.data[modalData.data.length - 1].detailedDescription[0]
          }
        </Typography>
        {modalData.data.map((item) => {
          item?.detailedDescriptionDetails?.map((data) => {
          return (
            <>
              <Typography className={classes.QauetionText}>
                {data?.heading}
              </Typography>
              <RadioGroup
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                // value={value}
                // onChange={handleChange}
              >
                {data?.points.map((data) => {
                  return (
                    <Typography className={classes.RadioOptionText} key={data}>
                      {data}
                    </Typography>
                  );
                })}
                {/* <FormControlLabel
                      value={data}
                      control={<RadioIconfnc />}
                      className={classes.RadioOptionText}
                      label={data}
                    /> */}
              </RadioGroup>
            </>
          );
        })
        })}
      </Box>
    </Box>
  );
}

export default SteptwoRight;
