import { Badge, Button, Typography } from "@mui/material";
import { useState } from "react";
import { UnlockButtonOutline } from "../Button/Button";
import classes from "./Header.module.css";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Notification from "../../assets/images/notification.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NotificationService from "../../services/Notifications";
import { unreadSpecificaNotifications, updateUnreadNotificationData } from "../../Store/Reducers/headerData";

const NotificationPopover = () => {
    const dispatch = useDispatch();
    const [selectedTab, handleChangeSelectedTab] = useState("All")
    const { notifications, unreadnotifications } = useSelector(state => state.headerDataSlice)
    const handleReadNotification = (type, ids = []) => {
        let allIds = [...ids]
        if (type === "ALL") {
            allIds = unreadnotifications.map(it => it._id)
        }
        NotificationService.READ_NOTIFICATION({
            "uid": localStorage.getItem("userDetail"),
            "Ids": allIds,
            "type": type
        }).then(res => {
            if(type === "ALL") {
                dispatch(unreadSpecificaNotifications(allIds))
                dispatch(updateUnreadNotificationData([]));
            } else {
                dispatch(unreadSpecificaNotifications(allIds))
            }
        }).catch(err => {

        }).finally(() => {

        })
    }
    return (
        <div className={classes.popoverContainer}>
            <div className={classes.popovertitle}>
                <Typography className={classes.popovertitle1} variant="body3" color="#190D1A">
                    Notifications
                </Typography>
                <Button className={classes.popoverbutton} disabled={!unreadnotifications.length} onClick={() => {
                    handleReadNotification("ALL")
                }}>
                    Mark all as read
                </Button>
            </div>
            <div className={classes.popoverBody}>
                <div className={classes.popoverTabs}>
                    {["All", "Unread"].map(it => (
                        <div className={selectedTab === it ? classes.popoverselectedtab : classes.popovertab} onClick={() => handleChangeSelectedTab(it)}>
                            <Typography className={selectedTab === it ? classes.popoverselectedtabText : classes.popovertabText} variant="body3" color="#190D1A">
                                {it}
                            </Typography>
                        </div>

                    ))}
                </div>
                <div className={classes.notificationLists}>
                    {selectedTab === "All" ? (
                        notifications.map(it => (
                            <NotificationData key={it?.uid} item={it} handleReadNotification={handleReadNotification}/>
                        ))
                    ) : null}
                    {selectedTab === "Unread" ? (
                        unreadnotifications.map(it => (
                            <NotificationData key={it?.uid} item={it} handleReadNotification={handleReadNotification}/>
                        ))
                    ) : null}

                </div>
            </div>
        </div>
    )

}

export default NotificationPopover

const NotificationData = ({ item, handleReadNotification }) => {
    return (
        <div className={classes.notificationItem} onClick={() => !item.read && handleReadNotification("SPECIFIC", [item._id])}>
            <div className={classes.notificationIcon}>
                <Badge color="primary" variant="dot" className={classes.badgeIcon} invisible={item.read}>

                </Badge>
                {/* <Typography className={classes.notificationIconText} variant="body3" color="#190D1A">
                    {"SSD"}
                </Typography> */}
                <img src={Notification} alt="notification" className={classes.notificationIconText}/>
            </div>
            <div className={classes.notificationContent}>
                <div className={classes.notificationContentData}>
                    <Typography className={classes.notificationContentDataTitle} variant="body3" color="#190D1A">
                        {item.title}
                    </Typography>
                    <Typography className={classes.notificationContentDataDate} variant="body3" color="#190D1A">
                        {moment(item.createdAt).fromNow()}
                    </Typography>
                </div>
                <Button
                    onClick={() => window.open(item.onClick, "_self")}
                    className={classes.notificationContentButton}
                //   buttonText={[
                //     "Unlock with 1",
                //      <img
                //       style={{ marginLeft: "4px", width: "12px", height: " 12px" }}
                //       src={DiamondCoin}
                //     />,
                //   ]}
                >
                    <Typography className={classes.notificationContentButtonLabel} variant="body3" color="#190D1A">
                        {item.buttonText}
                    </Typography>
                    {/* <img
                        style={{ width: "12px", height: " 12px" }}
                        src={DiamondCoin}
                        alt="icon"
                    /> */}

                </Button>
            </div>
        </div>
    )
}