import { Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import {
  DATE_PICKER,
  optionGroups,
  RANGEPICKERTYPE,
  TIME_BASE,
  TIME_PICKER,
} from "../../../Constant/GoalConstant";
import {
  CalanderActionDialog,
  CalanderDialog,
} from "../../GoalComponent/GoalDialog";
import classes from "./ActionDateTimePicker.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import DatePicker from "../../DatePicker/DatePicker";
import Picker from "react-mobile-picker-scroll";
import { useDispatch, useSelector } from "react-redux";
import { TimePicker } from "@material-ui/pickers";
import {
  goalUpdateHabitAction,
  growthCalenderCreate,
  updateHabitActionCalender,
} from "../../../services/auth";
import { storeGetGrowthData } from "../../../Store/Reducers/goalGrowthCalendar";
import { storeGetActionData } from "../../../Store/Reducers/getCalendarData";
import ChipSaveButton from "../../ChipSaveButton";
function ActionDateTimePicker({
  editShedualdataHandler,
  isSchedule,
  setIsSchedule,
  setIsSetDurationAction,
  actionData,
  setActionSliceData,
  actionSliceData,
  updateAction,
  setUpdateAction,
  isSetDurationAction,
}) {
  console.log(actionSliceData,' =====');
  const { ActiondataSlice, getCalenderDataSlice, getAllGrowthData } =
    useSelector((state) => state);
  const [timePicker, setTimePicker] = useState(TIME_PICKER.TIME_PICKER_START);
  const [datePicker, setDatePicker] = useState(DATE_PICKER.DATE_PICKER_START);
  const [isStartTime, setIsStartTime] = useState(true);
  const [isEndTime, setIsEndTime] = useState(false);
  const tempUpdateStartDate = updateAction.executionDate?.split("T");
  const temptempStartDate = actionData.executionDate?.split("T");
  const [startDate, setStartDate] = useState(
    new Date(
      Object.keys(updateAction).length
        ? tempUpdateStartDate[0]
        : temptempStartDate[0]
    )
  );
  const [endDate, setEndDate] = useState(new Date(actionData.executionDateEnd));
  const [specifiedTime, setSpecifiedTime] = useState(
    Object.keys(updateAction).length
      ? updateAction.scheduled_type
      : TIME_BASE.TIME_BASED
  );
  const dispatch = useDispatch();
  console.log("ActiondataSlice?.scheduleState", ActiondataSlice?.scheduleState);
  const [startTimevalueGroups, setStartTimevalueGroups] = useState({
    hour: Object.keys(updateAction).length
      ? moment(new Date(updateAction.executionDate)).format("HH")
      : moment
          (new Date(ActiondataSlice?.scheduleState.executionDate))
          .format("hh"),
    minutus: Object.keys(updateAction).length
      ? moment(new Date(updateAction.executionDate)).format("mm")
      : moment
          (new Date(ActiondataSlice?.scheduleState.executionDate))
          .format("mm"),
    AMPM: Object.keys(updateAction).length
      ? moment.utc(new Date(updateAction.executionDate)).format("A")
      : moment
          .utc(new Date(ActiondataSlice?.scheduleState.executionDate))
          .format("A"),
  });
  console.log("updateAction", updateAction);
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: Object.keys(updateAction).length
      ? moment(new Date(updateAction.executionDateEnd)).format("HH")
      : moment
          (new Date(ActiondataSlice?.scheduleState.executionDateEnd))
          .format("hh"),
    minutus: Object.keys(updateAction).length
      ? moment(new Date(updateAction.executionDateEnd)).format("mm")
      : moment
          (new Date(ActiondataSlice?.scheduleState.executionDateEnd))
          .format("mm"),
    AMPM: Object.keys(updateAction).length
      ? moment.utc(new Date(updateAction.executionDateEnd)).format("A")
      : moment
          .utc(new Date(ActiondataSlice?.scheduleState.executionDateEnd))
          .format("A"),
  });
  const [rangePickerType, setRangePickerType] = useState(
    RANGEPICKERTYPE?.DATE_RANGE_PICKER
  );
  const [isValid, setIsValid] = useState("");

  const handleChange = (name, value) => {
    if (isStartTime) {
      setStartTimevalueGroups({
        ...startTimevalueGroups,
        [name]: value,
      });
    } else {
      setIsValid(false);
      setEndTimeValuegroup({
        ...endTimeValuegroup,
        [name]: value,
      });
    }
  };
  const isColorasign = getCalenderDataSlice?.getCalenderData.find(
    (item) => item.questionCategory === actionData.name
  );

  const modalClosehandler = useCallback(() => {
    const convetedStartDate = moment(startDate).format("yyyy-MM-DDT");
    const convetedEndDate = moment(startDate).format("yyyy-MM-DDT");
    
    let executionDate = moment(convetedStartDate, "yyyy-MM-DDT").set({
      hour: startTimevalueGroups.hour,
      minutes: startTimevalueGroups.minutus
    }).format();
    let executionEnddate = moment(convetedEndDate, "yyyy-MM-DDT").set({
      hour: endTimeValuegroup.hour,
      minutes: endTimeValuegroup.minutus
    }).format();
   
    if(specifiedTime === TIME_BASE.UNSPECIFIED_TIME) {
      executionDate = moment(executionDate).startOf('day').format()
      executionEnddate = moment(executionEnddate).endOf('day').format()
    }

    if (
      moment(new Date(executionDate)) <
      moment(new Date(executionEnddate))
    ) {
      let tempAction;
      actionData?.actions?.map((item) => {
        if (actionData?.actionId === item?.actionId) {
          tempAction = {
            uid: localStorage.getItem("userDetail"),
            actionDetail: item?.actionDetail,
            actionId: item?.actionId,
            actionName: item?.actionName,
            active: item?.active,
            archive: item?.archive,
            calenderStatus: item?.calenderStatus,
            category: item?.category,
            category_id: item?.category_id,
            executionDate: executionDate,
            executionDateEnd: executionEnddate,
            scheduled_type: specifiedTime,
            status: "IN_PROGRESS",
            traits: [],
            subCategory: item?.subCategory,
            subCategory_id: item.subCategory_id,
            questionCategoryId: item.questionCategoryId,
            questionCategory: item.questionCategory,
            colour: item.colour,
            textColour: item.textColour,
            _id: item._id,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };
          return tempAction;
        }
      });
      if (isColorasign) {
        if (tempAction) {
          setActionSliceData(tempAction);
        }
        let createActionData = {
          uid: localStorage.getItem("userDetail"),
          id: actionData.actionId,
          type: actionData.type,
          action: {
            scheduled_type: specifiedTime,
            active: "false",
            calenderStatus: "IN_PROGRESS",
            status: "IN_PROGRESS",
            colour: isColorasign.colour,
            textColour: isColorasign.textColour,
            executionDate: executionDate,
            executionDateEnd: executionEnddate,
          },
        };
        updateHabitActionCalender(createActionData)
          .then((res) => {
            setUpdateAction({});
            const updateData = actionData.actions.map((ele) => {
              if (ele.actionName === actionData.text) {
                return { ...ele, active: "false" };
              }
              return ele;
            });
            const tempGetAllGrowthData = [];
            getAllGrowthData?.GetGrowthData?.map((data) => {
              tempGetAllGrowthData.push({
                actions:
                  data.questionCategoryId === actionData.questionCategoryId
                    ? updateData
                    : data.actions,
                actionsArchived: data.actionsArchived,
                actionsComplete: data.actionsComplete,
                archive: data.archive,
                habits: data.habits,
                habitsArchived: data.habitsArchived,
                habitsComplete: data.habitsComplete,
                increase: data.increase,
                mySelf: data.mySelf,
                others: data.others,
                preSelectedGrowth: data.preSelectedGrowth,
                preSelectedGrowthComplete: data.preSelectedGrowthComplete,
                questionCategory: data.questionCategory,
                questionCategoryId: data.questionCategoryId,
                status: data.status,
                tbd: data.tbd,
                uid: data.uid,
                url: data.url,
                _id: data._id,
              });
              return data;
            });
            dispatch(storeGetGrowthData(tempGetAllGrowthData));
          })
          .catch((err) => {
            console.log("res Action = ", err);
          });
      } else {
        let updateActionData = {
          uid: updateAction.uid,
          id: updateAction.actionId,
          type: "ACTION",
          action: {
            scheduled_type: specifiedTime,
            active: "false",
            calenderStatus: updateAction.calenderStatus,
            status: updateAction.status,
            colour: updateAction.colour,
            textColour: updateAction.textColour,
            executionDate:
              specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                ? updateAction.executionDate
                : executionDate,
            executionDateEnd:
              specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                ? updateAction.executionDateEnd
                : executionEnddate,
          },
        };
        updateHabitActionCalender(updateActionData)
          .then((res) => {
            setUpdateAction("");
            let tempUpdateActionData = getCalenderDataSlice.getActionData.map(
              (ele) => {
                if (ele.actionId === updateAction.actionId) {
                  return {
                    ...ele,
                    scheduled_type: specifiedTime,
                    executionDate: executionDate,
                    executionDateEnd: executionEnddate,
                  };
                }
                return ele;
              }
            );
            dispatch(storeGetActionData(tempUpdateActionData));
            console.log("res = ", res);
          })
          .catch((err) => {
            console.log("res Action = ", err);
          });
      }
      editShedualdataHandler(executionDate, executionEnddate);
      setIsSchedule(false);
      setIsSetDurationAction(false);
    } else {
      setIsValid(true);
      setIsSchedule(true);
      setIsSetDurationAction(true);
    }
  }, [
    isSchedule,
    isSetDurationAction,
    getCalenderDataSlice,
    startDate,
    startTimevalueGroups,
    endTimeValuegroup,
    specifiedTime
  ]);
  console.log("endTimeValuegroup", endTimeValuegroup);
  return (
    <CalanderActionDialog
      open={isSchedule}
      onClose={modalClosehandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.calanderWrapper}>
        <div className={classes.DateAndTimewrapper}>
          <img src={Clock} />
          <Typography variant="body2" sx={{ color: "#190D1A" }}>
            Set duration
          </Typography>
        </div>
        <div
          className={classes.boxDuration}
          style={{
            height:
              specifiedTime === TIME_BASE.TIME_BASED
                ? isValid
                  ? "auto"
                  : "383px"
                : "auto",
          }}
        >
          <div className={classes.setTimeBox}>
            <div
              className={classes.time_based_event}
              onClick={() => {
                setSpecifiedTime(TIME_BASE.TIME_BASED);
              }}
              style={{
                background:
                  specifiedTime === TIME_BASE.TIME_BASED
                    ? "#ffffff"
                    : "transparent",
              }}
            >
              <Typography
                variant="body2"
                className={classes.text_size}
                style={{
                  color:
                    specifiedTime === TIME_BASE.TIME_BASED
                      ? "#9747FF"
                      : "black",
                }}
              >
                Time-based
              </Typography>
            </div>
            <div
              className={classes.time_based_event}
              onClick={useCallback(() => {
                setSpecifiedTime(TIME_BASE.UNSPECIFIED_TIME);
              }, [specifiedTime])}
              style={{
                background:
                  specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                    ? "#ffffff"
                    : "transparent",
              }}
            >
              <Typography
                variant="body2"
                className={classes.text_size}
                style={{
                  color:
                    specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                      ? "#9747FF"
                      : "black",
                }}
              >
                Unspecified time
              </Typography>
            </div>
          </div>
          {specifiedTime === TIME_BASE.TIME_BASED ? (
            <>
              <div style={{ width: "100%" }}>
                <Typography variant="small">Starts</Typography>
                <div className={classes.dateTimeRangePickerWrapper}>
                  <div className={classes.dateTimeRangePickerSubWrapper}>
                    <Typography variant="body2" sx={{ color: 'black'}}>
                      {Object.keys(updateAction).length
                        ? moment
                            .utc(new Date(updateAction.executionDate))
                            .format("DD MMM YYYY")
                        : moment(startDate).format("DD MMM YYYY")}
                    </Typography>
                  </div>
                  <div
                    className={classes.dateTimeRangePickerSubWrapper}
                    onClick={() => {
                      setIsStartTime(true);
                      setIsEndTime(false);
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: isStartTime ? "#9747FF" : 'black',
                      }}
                      onClick={() => {
                        setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                        setTimePicker(TIME_PICKER.TIME_PICKER_START);
                        setDatePicker(DATE_PICKER.DATE_PICKER_START);
                      }}
                    >
                      {" "}
                      {`${startTimevalueGroups.hour}:${startTimevalueGroups.minutus}`}
                    </Typography>
                  </div>
                </div>
              </div>
              {isStartTime && (
                <Picker
                  optionGroups={optionGroups}
                  valueGroups={startTimevalueGroups
                  }
                  // itemHeight={25}
                  height={130}
                  // padding={10}
                  onChange={handleChange}
                />
              )}

              <div style={{ width: "100%" }}>
                <Typography variant="small">Ends</Typography>
                <div className={classes.dateTimeRangePickerWrapper}>
                  <div className={classes.dateTimeRangePickerSubWrapper}>
                    <Typography variant="body2" sx={{ color: 'black'}}>
                      {Object.keys(updateAction).length
                        ? moment
                            .utc(new Date(updateAction.executionDate))
                            .format("DD MMM YYYY")
                        : moment(startDate).format("DD MMM YYYY")}
                    </Typography>
                  </div>
                  <div
                    className={classes.dateTimeRangePickerSubWrapper}
                    onClick={() => {
                      setIsEndTime(true);
                      setIsStartTime(false);
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: isEndTime ? "#9747FF"  : 'black',
                      }}
                      onClick={() => {
                        setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                        setTimePicker(TIME_PICKER.TIME_PICKER_END);
                        setDatePicker(DATE_PICKER.DATE_PICKER_END);
                      }}
                    >
                      {`${endTimeValuegroup.hour}:${endTimeValuegroup.minutus}`}
                    </Typography>
                  </div>
                </div>
              </div>
              {isEndTime && (
                <Picker
                  optionGroups={optionGroups}
                  valueGroups={endTimeValuegroup}
                  height={130}
                  onChange={handleChange}
                />
              )}
            </>
          ) : (
            <div className={classes.unspecified}>
              <div style={{ width: "100%" }}>
                <Typography variant="small">Starts</Typography>
                <div
                  className={classes.dateTimeRangePickerWrapper}
                  style={{ paddingTop: "8px" }}
                >
                  <div className={classes.dateTimeRangePickerSubWrapper}>
                    <Typography variant="body2">
                      {" "}
                      {Object.keys(updateAction).length
                        ? moment
                            .utc(new Date(updateAction.executionDate))
                            .format("DD MMM YYYY")
                        : moment(startDate).format("DD MMM YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Typography variant="small">Ends</Typography>
                <div
                  className={classes.dateTimeRangePickerWrapper}
                  style={{ paddingTop: "8px" }}
                >
                  <div className={classes.dateTimeRangePickerSubWrapper}>
                    <Typography variant="body2">
                      {Object.keys(updateAction).length
                        ? moment
                            .utc(new Date(updateAction.executionDate))
                            .format("DD MMM YYYY")
                        : moment(startDate).format("DD MMM YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isValid ? (
            <div
            style={{
              paddingTop: "15px",
              fontSize: "14px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="p">
              {isValid && "Start Time should be less than End Time"}
            </Typography>
          </div>
          ): null}
          
          <div
            style={{
              paddingTop: "15px",
              fontSize: "14px",
              width: "100%",
            }}
          >
          
          </div>
         
        </div>
        <ChipSaveButton
            title="Save"
            style={{
              marginTop: '10px'
            }}
            onSave={modalClosehandler}
          />
      </div>
    </CalanderActionDialog>
  );
}
export default React.memo(ActionDateTimePicker);
