import classes from "./GetName.module.css";
import Box from "@mui/material/Box";
import Text from "../../../components/Text/Text";
import Input from "../../../components/Input/Input";
import {
  PrevAndNextButtonGroup,
  SubmitButton,
  SubmitButtonOutline,
  SubmitButtonWithBackGround,
} from "../../../components/Button/Button";
import {
  InputField,
  Step,
  StepsWrapper,
  SubHeadingTwo,
  WrapperDiv,
} from "../StyledComponent";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateName } from "../../../Store/Reducers/onbordingSlice";
import { nameValidation } from "../../../validations/OnbordingValidation/StepOne";
import { styled, Typography } from "@mui/material";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { generateAnoName } from "../../../services/auth";

export default function GetName({ onNext, showUserdata, setshowUserdata }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [fullName, setName] = useState(showUserdata?.nickName);
  const dispatch = useDispatch();
  const onNextStep = () => {
    onNext();
    setshowUserdata({ ...showUserdata, nickName: fullName });
  };
  if (fullName == "") {
    console.log("not filed");
  }
  const generateName = () => {
    generateAnoName({
      uid: localStorage.getItem("userDetail"),
    })
    .then(({User_data = ""}) => {
      setName(User_data)
    })
    .catch((err) => {
      console.log("err = ", err);
    });
  }
  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step></Step>
        <Step></Step>
      </StepsWrapper>
      <WrapperDiv>
        <div></div>
        <div style={{ width: "100%" }}>
          <Box className={classes.wrapper}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Let’s start with some questions
            </Typography>
          </Box>
          <SubHeadingTwo className={classes.wrapper}>
            <Typography variant="subtitle1">Create a nickname</Typography>
          </SubHeadingTwo>
          <Box className={classes.wrapper} sx={{ mt: "16px" }}>
            <InputField
              size="small"
              fullWidth
              autoFocus={true}
              // key="inviteEmail"
              key="getName"
              type="text"
              placeholder="Name"
              id="outlined-start-adornment"
              value={fullName}
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    className="generate"
                    variant="subtitle1"
                    color="#9747FF"
                    onClick={generateName}
                  >
                    Generate
                  </Typography>
                ),
              }}
            />
          </Box>
        </div>
        <Box sx={{ width: "100%", mt: "16px" }}>
          {console.log("fullName", fullName)}
          <SubmitButtonWithBackGround
            buttonText="Next"
            onClick={onNextStep}
            isDisabled={fullName === ""}
            isLoading={false}
          />
          {/* <ButtonGroup
            // {...props}
            // updateMainState={updateMainState}
            isPreviousBtnDisable={true}
            isNextDisable={fullName == ""}
          /> */}
        </Box>
      </WrapperDiv>
    </>
  );
}
