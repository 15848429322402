import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  habitData: [],
  compeletedHabit: [],
  isHabitSchedule:false,
  isAddmoreHabit:false,
  habitCalendersComplete: [],
  habitCalenders: [],
//   isActionShedualOpen:false,
//   scheduleState:{}
};
export const HabitDataSlice = createSlice({
  name: "HabitDataSlice",
  initialState,
  reducers: {
    updateHabitData: (state, action) => {
      state.habitData = action.payload;
    },
    updateCompeletedHabit: (state, action) => {
      state.compeletedHabit = action.payload;
    },
    updateHabitAddMore: (state, action) => {
      state.isAddmoreHabit = action.payload;
    },
    updateIsSchedule: (state, action) => {
      state.isHabitSchedule = action.payload;
    },
    updatehabitCalenders: (state, action) => {
      state.habitCalenders = action.payload;
    },
    updatehabitCalendersComplete: (state, action) => {
      state.habitCalendersComplete = action.payload;
    }
  },
});

export const { updateHabitData, updateCompeletedHabit,updateIsSchedule,updateHabitAddMore, updatehabitCalenders, updatehabitCalendersComplete} =
HabitDataSlice.actions;
export default HabitDataSlice.reducer;