import { Button, CircularProgress, Dialog, Grid } from "@mui/material";

import classes from "./TestItem.module.css";
const ConfirmationModal = props => {
    const {
        visibleConfirmation,
        setConfirmationVisible,
        selectedItem,
        getSymboleAndAmountForBuy,
        createInvoice,
        generatingPaymentLoader
    } = props
    
    const renderItemName = () => {
        if(selectedItem.category_id || selectedItem.questionCategory) {
            return "You are getting immediate access to \"" + (selectedItem.category_id && selectedItem.category ? selectedItem.category : selectedItem.questionCategory ? selectedItem.questionCategory : '') + "\""
        }
        if((selectedItem.buyFor && selectedItem.buyWith) || (selectedItem.price && selectedItem.price.id)) {
            const { amount, coin, diamond, symbol, } = getSymboleAndAmountForBuy(selectedItem)
            return <>
            You are purchasing to {selectedItem.name} for {(!coin && !diamond ? symbol : '')  + amount}
            {coin && ' Coins'}
            {diamond && ' Diamonds'}
            </>
        }
    }
    console.log(selectedItem);
    return <Dialog
        open={visibleConfirmation}
        onClose={() => {
            setConfirmationVisible(false);
        }}
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                position: "unset",
                padding: "20px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div className={classes.confirmationModalcardTitle}>
            {renderItemName()}
            
        </div>
        {generatingPaymentLoader && <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>}
        {!generatingPaymentLoader && <Grid container rowSpacing={1} sx={{
            margin: '10px 0',
        }}>
            <Grid item xs={6}>
                <Button sx={{
                    width: '99%', float: 'left',
                    border: '1px solid #9747FF',
                    borderRadius: '6px'
                }} variant="outlined" onClick={() => setConfirmationVisible(false)}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
                <Button sx={{
                    width: '99%', float: 'right',
                    border: '1px solid #9747FF',
                    borderRadius: '6px'
                }} variant="contained" onClick={() => {
                    createInvoice()
                    
                }}>Confirm</Button>
            </Grid>
        </Grid>}
        
    </Dialog>
}

export default ConfirmationModal