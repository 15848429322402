import { useTheme } from "@emotion/react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import typography from "../../theme/typography";
import classes from "./GoalList.module.css";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import GoalGroup from "../../assets/Svg/GoalGroup.svg";
import GoalCalibrate from "../../assets/Svg/GoalCalibrate.svg";
import GoalDecrease from "../../assets/Svg/GoalDecrease.svg";
import AcademicIcon from "../../assets/Svg/AcademicIcon.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import Archive from "../../assets/Svg/Archive.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { PersonalChart } from "../../components/PersonalChartGoal/PersonalChart";
import CalanderGoal from "../../components/CalanderGoal/CalanderGoal";
import { FriendChart } from "../../components/FriendChartGoal/FriendChart";
import {
    editPreSelectedGrowth,
    getGoals,
    goalArchive,
    updateHabitActionCalender,
} from "../../services/auth";
import CircularProgressGoal from "../../components/CircularProgressBar/CircularProgressBarGoal";
import moment from "moment";
import {
    CustomTypography,
    RightSideContentWrapper,
    SchedualdateWrapper,
} from "../../components/GoalComponent/GoalCustomDiv";
import ActionAccordian from "../../components/GoalAccordian/ActionAccordian";
import GoalNotFound from "../GoalNotFound/GoalNotFound";
import HabitAccordian from "../../components/GoalAccordian/HabitAccordian";
import GrowthAreaAccordian from "../../components/GoalAccordian/GrowthAreaAccordian";
import AddNewGoal from "../../components/AddNewGoal/AddNewGoal";
import {
    updateGoalID,
    updateSuggestedGrowthAreaState,
} from "../../Store/Reducers/goalGrowthArea";
import { useDispatch, useSelector } from "react-redux";
import {
    updateActionData,
    updateIsAddMore,
    updateisCompeletedAction,
} from "../../Store/Reducers/goalActionData";
import {
    updateCompeletedHabit,
    updateHabitAddMore,
    updatehabitCalenders,
    updatehabitCalendersComplete,
    updateHabitData,
} from "../../Store/Reducers/goalHabitData";
import ButtonGroup from "../../components/GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import CompletedGoalDialog from "../../components/CompletedArchiveDialog/CompletedDialog";
import ArchiveGoalDialog from "../../components/CompletedArchiveDialog/ArchieveDialog";
import { imageURLRender } from "../../utils";
import { setGoalNotificationMessage, updateAddGoalCategory, updateAddGoalStep, updategetGoal, updateGoalData } from "../../Store/Reducers/goalDataSlice";
import AddNewgoalModal, { ConfirmationClose } from "./AddGoalModal";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import useCustomNotification from "../../Hooks/useCustomNotification";
function GoalList() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const growthAreaDataSlice = useSelector(
        (state) => state.growthAreaDataSlice
    );
    const { state, pathname } = useLocation();
    console.log("state", state);
    const ActiondataSlice = useSelector(
        (state) => state.ActiondataSlice
    );
    const HabitDataSlice = useSelector(
        (state) => state.HabitDataSlice
    );
    const {
        getgoal,
        isAddNewGoal,
        isGoalArchive,
        isCompletedGoal,
        preSelectedState,
        isCalenderOpen,
        isGoalGet,
        tbdDate,
        goalName,
        schedualDate,
        othresArray,
        PersonalDataArray,
        goalScore,
        selectedQuestionCategoriesId,
        completedGrowthAreaState,
        isAddMoreGrowthAreaOpen,
        isCompletedArchiveOpen,
        isArchieveOpen,
        expanded,
        notificationmesage
    } = useSelector(
        (state) => state.goalData
    );
    const setExpanded = (val) => {
        dispatch(updateGoalData({
            expanded: val
        }))
    }
    const setisAddNewGoal = (val) => {
        dispatch(updateGoalData({
            isAddNewGoal: val
        }))
    }
    const setIsGoalArchive = (val) => {
        dispatch(updateGoalData({
            isGoalArchive: val
        }))
    }
    const setIsCompletedGoal = val => {
        dispatch(updateGoalData({
            isCompletedGoal: val
        }))
    }
    const setPreSelectedState = (val) => {
        dispatch(updateGoalData({
            preSelectedState: val
        }))
    }
    useEffect(() => {
        const user = localStorage.getItem("userDetail");
        const archive = false;
        const status = "IN_PROGRESS";
        getGoals(user, archive, status)
            .then((res) => {
                dispatch(updategetGoal(res?.data))
                const cat = localStorage.getItem("cat")
                if (cat) {
                    const newCat = JSON.parse(cat)
                    console.log("????", res?.data)
                    const foundGoal = res?.data.findIndex(it => it.questionCategoryId === newCat.questionCategoryId)
                    console.log("foundGoal =====", foundGoal);
                    if (foundGoal < 0) {
                        dispatch(updateAddGoalCategory(newCat))
                        dispatch(updateAddGoalStep(2))
                        dispatch(updateGoalData({
                            isAskOtherModalOpen: true
                        }))
                        localStorage.removeItem("cat")
                    }
                    if (foundGoal > -1) {
                        dispatch(setGoalNotificationMessage("Goal is already created"))
                        setCustomNotification(true)
                    }
                }
            })
    }, [])
    useEffect(() => {
        if (
            !getgoal.length ||
            isAddNewGoal ||
            isCompletedGoal ||
            isGoalArchive ||
            ActiondataSlice?.isAddmoreAction ||
            HabitDataSlice?.isAddmoreHabit
        ) {
            const user = localStorage.getItem("userDetail");
            const archive = false;
            const status = "IN_PROGRESS";
            getGoals(user, archive, status)
                .then((res) => {
                    dispatch(updategetGoal(res?.data))
                    setIsGoalGet(true);
                    setIsGoalArchive(false);
                    setisAddNewGoal(false);
                    setIsCompletedGoal(false);
                    const tempPreselectedData = res?.data?.filter(
                        (item) => item._id === growthAreaDataSlice?.goalID
                    );
                    setCompletedGrowthAreaState(
                        tempPreselectedData[0]?.preSelectedGrowthComplete
                    );
                    setPreSelectedState(tempPreselectedData[0]?.preSelectedGrowth);
                    const newActions = [...(tempPreselectedData[0]?.actions || [])].map(it => {
                        const newObject = { ...(it || {}) }
                        if (newObject && newObject.scheduled_type === "DEFAULT") {
                            newObject.executionDate = moment().format()
                            newObject.executionDateEnd = moment().add(1, 'hour').format()
                        }
                        return newObject
                    })
                    dispatch(updateActionData(newActions));
                    dispatch(
                        updateisCompeletedAction(tempPreselectedData[0]?.actionsComplete)
                    );
                    dispatch(updateHabitData(tempPreselectedData[0]?.habits));
                    dispatch(updatehabitCalenders(tempPreselectedData[0]?.habitCalenders));
                    dispatch(updatehabitCalendersComplete(tempPreselectedData[0]?.habitCalendersComplete));
                    dispatch(
                        updateCompeletedHabit(tempPreselectedData[0]?.habitsComplete)
                    );
                    dispatch(updateIsAddMore(false));
                    dispatch(updateHabitAddMore(false));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => { });
        }
    }, [
        isAddNewGoal,
        isCompletedGoal,
        isGoalArchive,
        ActiondataSlice?.isAddmoreAction,
        HabitDataSlice?.isAddmoreHabit,
    ]);
    const setIsCalenderOpen = (val) => {
        dispatch(updateGoalData({
            isCalenderOpen: val
        }))
    }
    const setIsGoalGet = (val) => {
        dispatch(updateGoalData({
            isGoalGet: val
        }))
    }
    const setTbdDate = (val) => {
        dispatch(updateGoalData({
            tbdDate: val
        }))
    }
    const setGoalName = (val) => {
        dispatch(updateGoalData({
            goalName: val
        }))
    }
    const setschedualDate = (val) => {
        dispatch(updateGoalData({
            schedualDate: val
        }))
    }
    const setOthresArray = (val) => {
        dispatch(updateGoalData({
            othresArray: val
        }))
    }
    const setPersonalDataArray = (val) => {
        dispatch(updateGoalData({
            PersonalDataArray: val
        }))
    }
    const setgoalScore = (val) => {
        dispatch(updateGoalData({
            goalScore: val
        }))
    }
    const setSelectedQuestionCategoriesId = (val) => {
        dispatch(updateGoalData({
            selectedQuestionCategoriesId: val
        }))
    }
    const setIsAskOtherModalOpen = (val) => {
        dispatch(updateGoalData({
            isAskOtherModalOpen: val
        }))
    }
    const { NotificationContentJSX, customNotification, setCustomNotification } =
        useCustomNotification();
    const setCompletedGrowthAreaState = val => dispatch(updateGoalData({
        completedGrowthAreaState: val
    }))
    const setisAddMoreGrowthAreaOpen = val => dispatch(updateGoalData({
        isAddMoreGrowthAreaOpen: val
    }))
    const setIsCompletedArchiveOpen = val => dispatch(updateGoalData({
        isCompletedArchiveOpen: val
    }))
    const setisArchieveOpen = val => dispatch(updateGoalData({
        isArchieveOpen: val
    }))
    const smallDeviceMatches = useMediaQuery("(max-width:500px)");

    const selectGrowthAreaHandler = (data) => {
        const completed = preSelectedState.filter((item) => item._id === data._id);
        const tempCompeleted = [
            ...completedGrowthAreaState,
            {
                type: completed[0].type,
                status: "COMPLETE",
                _id: completed[0]._id,
                text: completed[0].text,
            },
        ];
        const uniquePreselected = duplicateGrowthAreaRemoveHandler(tempCompeleted);
        setCompletedGrowthAreaState(uniquePreselected);
        const tempSelectedGrowthArea = preSelectedState?.filter(
            (item) => item._id !== data._id
        );
        setPreSelectedState(tempSelectedGrowthArea);
        const user = localStorage.getItem("userDetail");
        let selectedToCompletedGrowth = {
            uid: user,
            goalId: growthAreaDataSlice?.goalID,
            preSelectedGrowth: tempSelectedGrowthArea,
            preSelectedGrowthComplete: tempCompeleted,
        };
        editPreSelectedGrowth(selectedToCompletedGrowth)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const completeDataHandler = () => {
        const user = localStorage.getItem("userDetail");
        setIsCompletedGoal(true);
        const archiveData = {
            id: selectedQuestionCategoriesId,
            archive: false,
            uid: user,
            status: "COMPLETE",
        };
        goalArchive(archiveData)
            .then((res) => {
                // console.log(res);
                setIsCompletedArchiveOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setExpanded(false);
    };
    const archiveDataHandler = () => {
        const user = localStorage.getItem("userDetail");
        setIsGoalArchive(true);
        let archiveData = {
            id: selectedQuestionCategoriesId,
            archive: true,
            uid: user,
            status: "IN_PROGRESS",
        };
        goalArchive(archiveData)
            .then((res) => {
                // console.log(res);
                setisArchieveOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setExpanded(false);
    };
    const completedGrowthAreaHandler = (data) => {
        const selectedArea = completedGrowthAreaState.filter(
            (item) => item._id === data._id
        );

        const tempSelected = [
            ...preSelectedState,
            {
                type: selectedArea[0].type,
                status: "IN_PROGRESS",
                _id: selectedArea[0]._id,
                text: selectedArea[0].text,
            },
        ];
        setPreSelectedState(tempSelected);

        const user = localStorage.getItem("userDetail");
        const tempCompleted = completedGrowthAreaState?.filter(
            (item) => item._id !== data._id
        );
        setCompletedGrowthAreaState(tempCompleted);

        let selectedToCompletedGrowth = {
            uid: user,
            goalId: growthAreaDataSlice?.goalID,
            preSelectedGrowthComplete: tempCompleted,
            preSelectedGrowth: tempSelected,
        };
        editPreSelectedGrowth(selectedToCompletedGrowth)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const duplicateGrowthAreaRemoveHandler = (arr) => {
        const res = {};
        arr?.forEach((obj) => {
            const key = `${obj.text}`;
            if (!res[key]) {
                res[key] = { ...obj, count: 0 };
            }
            res[key].count += 1;
        });
        return Object.values(res);
    };
    const handleChange =
        (
            panel,
            questionCategory,
            mySelf,
            others,
            questionCategoryId,
            id,
            preSelectedGrowth,
            preSelectedGrowthComplete,
            actions,
            actionsComplete,
            habits,
            habitsComplete,
            tbd,
            habitCalenders = [],
            habitCalendersComplete = []
        ) =>
            (event, isExpanded) => {
                setExpanded(isExpanded ? panel : false);
                setGoalName(questionCategory);
                setSelectedQuestionCategoriesId(questionCategoryId);
                dispatch(updateGoalID(id));
                setPreSelectedState(preSelectedGrowth);
                setCompletedGrowthAreaState(preSelectedGrowthComplete);
                console.log("actions", actions);
                const newActions = [...(actions || [])].map(it => {
                    const newObject = { ...(it || {}) }
                    if (newObject && newObject.scheduled_type === "DEFAULT") {
                        newObject.executionDate = moment().format()
                        newObject.executionDateEnd = moment().add(1, 'hour').format()
                    }
                    return newObject
                })
                dispatch(updateActionData(newActions));
                dispatch(updateHabitData(habits));
                dispatch(updateCompeletedHabit(habitsComplete));
                dispatch(updateisCompeletedAction(actionsComplete));
                dispatch(updatehabitCalenders(habitCalenders));
                console.log("habitCalendersComplete", habitCalendersComplete);
                dispatch(updatehabitCalendersComplete(habitCalendersComplete));
                setTbdDate(tbd);

                const tempScore = mySelf.map((item) => {
                    return item.percent;
                });
                setgoalScore(tempScore);
                const personalArray = mySelf.map((item) => {
                    return {
                        y: item.percent,
                        name: moment(item?.date).format("DD MMM"),
                        userName: item?.name && item?.name[0] && item?.name[0]
                            .split(" ")
                            .map((i) => i.toUpperCase().charAt(0))
                            .join(""),
                    };
                });
                const friendArray = others.map((item) => {
                    return {
                        y: item.percent,
                        name: moment(item.date).format("DD MMM"),
                        userName: item?.name && item?.name[0] && item?.name[0]
                            .split(" ")
                            .map((i) => i.toUpperCase().charAt(0))
                            .join(""),
                    };
                });
                setOthresArray(friendArray);
                setPersonalDataArray(personalArray);
                // const suggestedGrowthArea = preSelectedGrowth?.filter(
                //   (item) => item.type === "SUGGESTED"
                // );
                // const suggestedCompletedGrowthArea = preSelectedGrowthComplete?.filter(
                //   (item) => item.type === "SUGGESTED"
                // );
                const tempSuggestedArea = [
                    ...preSelectedGrowth,
                    ...preSelectedGrowthComplete,
                ];
                dispatch(updateSuggestedGrowthAreaState(tempSuggestedArea));
            };

    console.log("customNotification", customNotification)
    return (
        <>

            <div className={classes.dashbordTitleContainer}>
                <div className={classes.dashbordTitle}>
                    <Typography variant="h4">Goals</Typography>
                    <img
                        src={Archive}
                        onClick={() => navigate("/goals/archive")}
                        alt="Archive"
                    />
                </div>
                <div className={classes.dashbordTitlebody}>
                    {matches ? (
                        <Typography variant="p" sx={{ color: "#434243" }}>
                            Goals allow to set measurable targets in various dimensions of
                            your personality. You can track your progress
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="p" sx={{ color: "#434243" }}>
                                Goals allow to set measurable targets in various dimensions of
                                your personality. You can track your progress
                            </Typography>
                            <Typography variant="p" sx={{ color: "#434243" }}>
                                and adjust the goals to stay on track towards self-improvement
                                and discovery.
                            </Typography>
                        </>
                    )}
                </div>
            </div>
            {!isGoalGet ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : getgoal.length === 0 ? (
                <GoalNotFound setisAddNewGoal={setisAddNewGoal} />
            ) : (
                <div
                    className={
                        matches
                            ? expanded === false
                                ? classes.smallMainWrapper
                                : classes.accodianOpensmallWrapper
                            : expanded === false
                                ? classes.mainWrapper
                                : classes.accodianOpenMainWrapper
                    }
                >
                    <Grid
                        container
                        spacing={1}
                    // sx={{
                    //   overflowY: "auto",
                    //   height: "490px"
                    // }}
                    >
                        {getgoal.map((data, index) => {
                            const {
                                questionCategory,
                                questionCategoryId,
                                actions,
                                actionRecommended = [],
                                growthRecommended = [],
                                actionsComplete,
                                habits,
                                mySelf,
                                others,
                                url,
                                preSelectedGrowth,
                                preSelectedGrowthComplete,
                                actionsArchived,
                                habitsArchived,
                                _id,
                                increase,
                                habitsComplete,
                                tbd,
                                habitCalenders = [],
                                habitCalendersComplete = [],
                                subCategory_id, category_id
                            } = data;
                            console.log("data", data);
                            const imageCompo = <div>
                                <img
                                    src={
                                        url === "/public/images/"
                                            ? AcademicIcon
                                            : imageURLRender(url)
                                    }
                                    style={{ margin: "16px auto", height: '58px', }} 
                                    alt=""
                                />
                            </div>
                            return (
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                    <Accordion
                                        sx={{
                                            border: "1px solid #F3F3F3",
                                            borderRadius: "16px !important",
                                            margin: "3.9px 0px",
                                            padding: "3px 0px",
                                        }}
                                        expanded={expanded === index}
                                        onChange={handleChange(
                                            index,
                                            questionCategory,
                                            mySelf,
                                            others,
                                            questionCategoryId,
                                            _id,
                                            preSelectedGrowth,
                                            preSelectedGrowthComplete,
                                            actions,
                                            actionsComplete,
                                            habits,
                                            habitsComplete,
                                            tbd,
                                            habitCalenders,
                                            habitCalendersComplete
                                        )}
                                    >
                                        <AccordionSummary
                                            expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
                                            className={classes.AccordianSummery}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            sx={{
                                                "& .Mui-expanded": {
                                                    margin: "0px 0px !important",
                                                },
                                            }}
                                        //
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <img
                                                        src={
                                                            url === "/public/images/"
                                                                ? AcademicIcon
                                                                : imageURLRender(url)
                                                        }
                                                        alt=""
                                                    />
                                                    <Typography
                                                        variant="body4"
                                                        sx={{
                                                            marginLeft: "12px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {questionCategory}
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        marginRight: increase === "Calibrate" ? "10px" : "15px",
                                                        alignItems: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    {increase === "Decrease" ? (
                                                        <img src={GoalDecrease} alt="GoalGroup" />
                                                    ) : increase === "Calibrate" ? (
                                                        <img src={GoalCalibrate} alt="GoalGroup" height={"30px"} />
                                                    ) : (
                                                        <img src={GoalGroup} alt="GoalGroup" />
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                <div className={classes.schedualScoreWrapper}>
                                                    <div className={classes.goalScoreWrapper}>
                                                        {goalScore.length === 0 ? (
                                                            <div className={classes.dataNotFoundSubWrapper}>
                                                                <Typography variant="body2">
                                                                    Archived Action not found
                                                                </Typography>
                                                            </div>
                                                        ) : (
                                                            goalScore?.map((percent, i) => {
                                                                return (
                                                                    <CircularProgressGoal
                                                                        key={i}
                                                                        value={Math.round(percent)}
                                                                        width={matches ? "32px" : "48px"}
                                                                        height={matches ? "32px" : "48px"}
                                                                    />
                                                                );
                                                            })
                                                        )}

                                                        {tbdDate?.map((data, i) => {
                                                            const { date } = data;
                                                            return (
                                                                <SchedualdateWrapper key={i}>
                                                                    <Typography
                                                                        variant="small"
                                                                        className={classes.shedualdateText}
                                                                    >
                                                                        {moment(date).format("MMM Do")}
                                                                    </Typography>
                                                                </SchedualdateWrapper>
                                                            );
                                                        })}
                                                        <SchedualdateWrapper>
                                                            <img
                                                                className={classes.plusImage}
                                                                style={{
                                                                    padding: matches && "5px 0px 0px 5px",
                                                                    width: matches && "22px",
                                                                }}
                                                                onClick={() => {
                                                                    setIsCalenderOpen(true);
                                                                }}
                                                                alt=""
                                                                src={PlusLargeIcon}
                                                            />
                                                        </SchedualdateWrapper>
                                                    </div>
                                                </div>
                                                <div style={{ margin: "16px 0px" }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontWeight: "500",
                                                            margin: "16px 0px",
                                                        }}
                                                    >
                                                        Personal
                                                    </Typography>

                                                    <div
                                                        style={{
                                                            maxWidth: "800px",
                                                            minWidth: "320px",
                                                            height: "400px",
                                                            // width: " 400px",
                                                        }}
                                                        className="goalListFriendChart"
                                                    >
                                                        <PersonalChart
                                                            PersonalDataArray={PersonalDataArray}
                                                            expanded={expanded}
                                                            url={mySelf[0]?.imageUrl[0] ? mySelf[0]?.imageUrl[0] : '/public/images/'}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ margin: "16px 0px" }}
                                                    className={classes.friendChartWrapper}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontWeight: "500",
                                                            margin: "16px 0px",
                                                        }}
                                                    >
                                                        Friends
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            maxWidth: "800px",
                                                            minWidth: "320px",
                                                            height: "400px",
                                                            // width: " 400px",
                                                        }}
                                                        className="goalListFriendChart"
                                                    >
                                                        <FriendChart data={data} othresArray={othresArray} others={others} />
                                                    </div>
                                                </div>
                                                <div style={{ margin: "30px 0px" }}>
                                                    <GrowthAreaAccordian
                                                        preSelectedGrowth={preSelectedState}
                                                        growthRecommended={growthRecommended}
                                                        preSelectedGrowthComplete={completedGrowthAreaState}
                                                        selectGrowthAreaHandler={selectGrowthAreaHandler}
                                                        completedGrowthAreaHandler={
                                                            completedGrowthAreaHandler
                                                        }
                                                        selectedQuestionCategoriesId={
                                                            selectedQuestionCategoriesId
                                                        }
                                                        setPreSelectedState={setPreSelectedState}
                                                        setCompletedGrowthAreaState={
                                                            setCompletedGrowthAreaState
                                                        }
                                                        isAddMoreGrowthAreaOpen={isAddMoreGrowthAreaOpen}
                                                        setisAddMoreGrowthAreaOpen={
                                                            setisAddMoreGrowthAreaOpen
                                                        }
                                                        goalName={goalName}
                                                        expanded={expanded}
                                                        imageCompo={imageCompo}
                                                    />
                                                </div>
                                                <div style={{ margin: "30px 0px" }}>
                                                    <ActionAccordian
                                                        actions={actions}
                                                        actionRecommended={actionRecommended}
                                                        actionsArchived={actionsArchived}
                                                        selectedQuestionCategoriesId={
                                                            selectedQuestionCategoriesId
                                                        }
                                                        goalName={goalName}
                                                        expanded={expanded}
                                                        imageCompo={imageCompo}

                                                    />
                                                </div>
                                                <div style={{ margin: "30px 0px" }}>
                                                    <HabitAccordian
                                                        habits={habits}
                                                        habitsArchived={habitsArchived}
                                                        goalName={goalName}
                                                        selectedQuestionCategoriesId={
                                                            selectedQuestionCategoriesId
                                                        }
                                                        imageCompo={imageCompo}

                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "10px",
                                                        zIndex: "1",
                                                    }}
                                                >
                                                    <ButtonGroup
                                                        nextButtonText="Complete"
                                                        previousButtonText="Archive"
                                                        isPrevDisable={false}
                                                        onClickNext={() => {
                                                            preSelectedState?.length === 0 &&
                                                                ActiondataSlice?.actionData?.length === 0 &&
                                                                HabitDataSlice?.habitData?.length === 0
                                                                ? completeDataHandler()
                                                                : setIsCompletedArchiveOpen(true);
                                                        }}
                                                        onClickPrevious={() => {
                                                            preSelectedState?.length === 0 &&
                                                                ActiondataSlice?.actionData?.length === 0 &&
                                                                HabitDataSlice?.habitData?.length === 0
                                                                ? archiveDataHandler()
                                                                : setisArchieveOpen(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <div
                        className={classes.createMoreGoalWrapper}
                        onClick={() => {
                            setIsAskOtherModalOpen(true);
                        }}
                    >
                        <img style={{ margin: "9px" }} src={PlusLargeIcon} alt="" />
                    </div>
                </div>
            )}
            <Dialog
                open={isCalenderOpen}
                onClose={() => {
                    setIsCalenderOpen(false);
                }}
                sx={{
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "388px",
                        height: "361px",
                        position: "unset",
                        padding: "20px",
                        borderRadius: "20px",
                        overflow: "hidden",
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CalanderGoal
                    goalName={goalName}
                    setschedualDate={setschedualDate}
                    setIsCalenderOpen={setIsCalenderOpen}
                    schedualDate={schedualDate}
                    selectedQuestionCategoriesId={selectedQuestionCategoriesId}
                    tbdDate={tbdDate}
                    setTbdDate={setTbdDate}
                />
            </Dialog>

            <AddNewgoalModal
                setCustomNotification={setCustomNotification} />
            {isCompletedArchiveOpen && (
                <CompletedGoalDialog
                    isCompletedArchiveOpen={isCompletedArchiveOpen}
                    setIsCompletedArchiveOpen={setIsCompletedArchiveOpen}
                    preSelectedState={preSelectedState}
                    goalName={goalName}
                    setIsCompletedGoal={setIsCompletedGoal}
                    completeDataHandler={completeDataHandler}
                />
            )}
            {isArchieveOpen && (
                <ArchiveGoalDialog
                    setisArchieveOpen={setisArchieveOpen}
                    isArchieveOpen={isArchieveOpen}
                    preSelectedState={preSelectedState}
                    goalName={goalName}
                    setIsGoalArchive={setIsGoalArchive}
                    archiveDataHandler={archiveDataHandler}
                />
            )}
            {customNotification && (
                <NotificationContentJSX
                    message={notificationmesage}
                    isThumbIcon={false}
                    position="top"
                />
            )}
        </>
    );
}
export default GoalList;
