import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    overflowY: "auto",
    height: "100vh",
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "42px",
    [theme.breakpoints.down("md")]: {
        order: "2",
        marginTop: "12px",
        fontSize: "22px",
    },
}));

export const PersonPublic = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
        marginTop: "20px",
        order: "1",
        margin: "0px",
    },
}));

export const PersonalButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "10px",
    fontSize: "10px !important",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));

export const  PublicButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));