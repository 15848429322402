import Coins from "../../assets/Svg/Coins-SVG.svg";
import Conins500 from "../../assets/Svg/500Conins.svg"
import Conins1000 from "../../assets/Svg/1000Coins.svg"
import Diamond1 from "../../assets/Svg/1Diamond.svg"
import Diamond5 from "../../assets/Svg/5Diamonds.svg"
import Diamond10 from "../../assets/Svg/10Diamonds.svg"
import PowerUpSaver from "../../assets/Svg/PowerUpSaver.svg"
import StoreTest from "../../assets/Svg/StoreTest.svg"
import StoreDimension from "../../assets/Svg/StoreDimension.svg"
export const DataForStore = [
    {
        name: "Currencies",
        children: [
            {
                name: "100 coins",
                description: "Item description",
                icon: Coins,
                buyFor: 5,
                buyWith: 'amt'
            },
            {
                name: "500 coins",
                description: "Item description",
                icon: Conins500,
                buyFor: 20,
                buyWith: 'amt'
            },
            {
                name: "1000 coins",
                description: "Item description",
                icon: Conins1000,
                buyFor: 35,
                buyWith: 'amt'
            },
            {
                name: "1 diamond",
                description: "Item description",
                icon: Diamond1,
                buyFor: 10,
                buyWith: 'amt'
            },
            {
                name: "5 diamond",
                description: "Item description",
                icon: Diamond5,
                buyFor: 45,
                buyWith: 'amt'
            },
            {
                name: "10 diamond",
                description: "Item description",
                icon: Diamond10,
                buyFor: 80,
                buyWith: 'amt'
            },
        ]
    },
    {
        name: "Power ups",
        children: [
            // {
            //     name: "Saver",
            //     description: "Having saver allows you to keep your streak going up, in case you miss an action a day",
            //     icon: PowerUpSaver,
            //     buyFor: 200,
            //     buyWith: 'coins'
            // },
            {
                name: "Access test immediately",
                description: "Immediate access to test allows you to unlock test when you want, regardless limits",
                icon: StoreTest,
                buyFor: 20,
                buyWith: 'amt',
                test: true
            },
            {
                name: "Immediate access to dimension",
                description: "Immediate access to dimension allows you to unlock dimension when you want, regardless limits",
                icon: StoreDimension,
                buyFor: 10,
                buyWith: 'amt',
                dimention: true
            },
        ]
    }
]

export const foundNameRetunImage = (name) => {
    console.log('retuning name', name);
    switch (name) {
        case "100 Coins":
            return {name: Coins, imageProps: { /* width: '84px' */ }}
        case "500 Coins":
            return {name: Conins500, imageProps: { /* width: '94px' */ }}
        case "1000 Coins":
            return {name: Conins1000, imageProps: { /* width: '110px' */ }}
        case "1 Diamond":
            return {name: Diamond1, imageProps: { /* width: '84px' */ }}
        case "5 Diamonds":
            return {name: Diamond5, imageProps: { /* width: '84px' */ }}
        case "10 Diamonds":
            return {name: Diamond10, imageProps: { /* width: '84px' */ }}
        default:
            return {name: Coins, imageProps: { /* width: '84px' */ }}
    }
}