import { Button, Grid } from "@mui/material";
import React from "react";
import typography from "../../theme/typography";
import classes from "./ButtonGroup.module.css";
const ButtonGroup = ({
  onNext,
  onPrevious,
  updateMainState = () => {},
  isNextDisable = false,
  isPreviousBtnDisable = false,
  nextButtonText = "Next",
  previousButtonText = "Previous",
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.buttonWrapper}
      rowSpacing="12px"
      columnSpacing="12px"
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Button
          className={classes.previousButton}
          sx={{ ...typography.subtitle1 }}
          variant="outlined"
          // onClick={onPrevious}
          disabled={isPreviousBtnDisable}
          // onClick={onPrevious}
          onClick={() => {
            if (updateMainState) {
              updateMainState();
            }
            onPrevious();
          }}
        >
          {previousButtonText}
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
      >
        <Button
          className={classes.nextButton}
          sx={{ ...typography.subtitle1 }}
          variant="outlined"
          onClick={() => {
            if (updateMainState) {
              updateMainState();
            }
            onNext();
          }}
          disabled={isNextDisable}
        >
          {nextButtonText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ButtonGroup;
