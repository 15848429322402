import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    inviteData: [],
}

export const inviteDataSlice = createSlice({
    name: 'inviteDataSlice',
    initialState,
    reducers: {
        updateInviteData: (state, action) => {
            state.inviteData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateInviteData } = inviteDataSlice.actions

export default inviteDataSlice.reducer