import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import classes from "./DropModal.module.css";
import SmallEngagement from "../../../assets/Svg/SmallEngagement.svg";
import Clock from "../../../assets/Svg/Clock.svg";
import ColorPick from "../../../assets/Svg/ColorPick.svg";
import Smallexpanded from "../../../assets/Svg/Smallexpanded.svg";
import SmallUpExpanded from "../../../assets/Svg/SmallUpExpanded.svg";
import moment from "moment";
import colorPalette from "./colorPick";
import {
  editPreSelectedGrowth,
  getGrowthData,
  growthCalenderCreate,
  growthCalenderGetCalenderData,
  growthCalenderUpdate,
} from "../../../services/auth";
import Lock from "../../../assets/Svg/Lock.svg";
import { useDispatch, useSelector } from "react-redux";
import useCustomNotification from "../../../Hooks/useCustomNotification";
import { border } from "@mui/system";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../../Store/Reducers/goalGrowthCalendar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  storeGetActionData,
  storeGetCalendarData,
  storeGetHabitData,
  updateIsGetCalenderData,
} from "../../../Store/Reducers/getCalendarData";
import { imageURLRender } from "../../../utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 244,
  height: 156,
  padding: "12px",
  bgcolor: "white",
  boxShadow: "0px 0px 44px rgba(25, 13, 26, 0.1)",
  borderRadius: "12px",
};
function DropModal({
  colorPickData,
  setColorPickData,
  setisDropGrowthAreaOpen,
  bgcolor,
  getGrowthCalendarDate,
  setBgColor,
  unique,
  setCustomNotificationColourPick,
  updateColourPickData,
  setUpdateColourPickData,
  setCalendarData,
  calendarData,
  tableCellId,
}) {
  const [expanded, setExpanded] = useState(false);
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const date = tableCellId.split(" ");
  const startDate = date[0] + date[1];
  const findData = getCalenderDataSlice?.getCalenderData.find(
    (item) =>
      moment(new Date(item.startDate)).format("DD-MMMM,yyyy") === startDate
  );
  let temp = [];
  if (findData) {
    getCalenderDataSlice?.getCalenderData?.map((item) => {
      if (
        moment(new Date(item.startDate)).format("DD-MMMM,yyyy") === startDate
      ) {
        temp.push(item);
      } else {
        item.dates.map((ele) => {
          if (moment(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate) {
            temp.push(item);
          }
        });
      }
    });
  }
  const uniquedata = [
    ...new Map(temp.map((ele) => [ele["colour"], ele])).values(),
  ];
  const disapatch = useDispatch();

  const handleChange = (event) => {
    const textBgColour = event.target.id.split(" ");
    const BGCOLOUR = textBgColour[0];
    const TEXTCOLOUR = textBgColour[1];
    const isColorAlreadySelected = temp.find(
      (item) => item.colour === BGCOLOUR
    );
    if (isColorAlreadySelected) {
      setCustomNotificationColourPick(true);
    } else {
      setBgColor(event.target.id);
      colorPalette.map((item, index) => {
        if (item.bgColour === BGCOLOUR) {
          item.isPick = true;
        }
      });
      const createData = {
        uid: localStorage.getItem("userDetail"),
        questionCategoryId: colorPickData.questionCategoryId,
        name: colorPickData.text,
        type: colorPickData.type,
        colour: BGCOLOUR,
        textColour: TEXTCOLOUR,
        startDate: moment(colorPickData.startDate).format("MM-DD-YYYY"),
        endDate: moment(colorPickData.endDate).format("MM-DD-YYYY"),
      };
      if (event.target.id) {
        if (updateColourPickData._id) {
          setBgColor(event.target.id);
          let updateData = {
            uid: localStorage.getItem("userDetail"),
            id: updateColourPickData._id,
            questionCategoryId: updateColourPickData.questionCategoryId,
            name: updateColourPickData.name,
            type: updateColourPickData.type,
            colour: BGCOLOUR,
            textColour: TEXTCOLOUR,
            startDate: moment(updateColourPickData.startDate).format(
              "MM-DD-YYYY"
            ),
            endDate: moment(updateColourPickData.endDate).format("MM-DD-YYYY"),
          };
          growthCalenderUpdate(updateData)
            .then((res) => {
              console.log(res);
              const getgrowthCalenderData = {
                uid: localStorage.getItem("userDetail"),
                from: getGrowthCalendarDate.fromDate,
                to: getGrowthCalendarDate.toDate,
              };
              if (getGrowthCalendarDate.fromDate) {
                growthCalenderGetCalenderData(getgrowthCalenderData)
                  .then((res) => {
                    disapatch(storeGetCalendarData(res?.data));
                    disapatch(storeGetActionData(res?.actionData));
                    disapatch(storeGetHabitData(res?.habitCalenderData));
                  })
                  .catch((err) => {
                    console.log("err", err);
                  })
                  .finally(() => {
                    disapatch(updateIsGetCalenderData(true));
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setBgColor({});
          growthCalenderCreate(createData)
            .then((res) => {
              const getgrowthCalenderData = {
                uid: localStorage.getItem("userDetail"),
                from: getGrowthCalendarDate.fromDate,
                to: getGrowthCalendarDate.toDate,
              };
              if (getGrowthCalendarDate.fromDate) {
                growthCalenderGetCalenderData(getgrowthCalenderData)
                  .then((res) => {
                    disapatch(storeGetCalendarData(res?.data));
                    disapatch(storeGetActionData(res?.actionData));
                    disapatch(storeGetHabitData(res?.habitCalenderData));
                  })
                  .catch((err) => {
                    console.log("err", err);
                  })
                  .finally(() => {
                    disapatch(updateIsGetCalenderData(true));
                  });
              }
              setCalendarData(res?.growthdata);
              const temp = getCalenderDataSlice.getCalenderData.map((item) => {
                if (!item._id || item._id === undefined) {
                  return {
                    ...item,
                    uid: res?.growthdata?.uid,
                    category: res?.growthdata?.category,
                    subCategory: res?.growthdata?.subCategory,
                    subCategory_id: res?.growthdata?.subCategory_id,
                    questionCategoryId: res?.growthdata?.questionCategoryId,
                    questionCategory: res?.growthdata?.questionCategory,
                    startDate: res?.growthdata?.startDate,
                    endDate: res?.growthdata?.endDate,
                    colour: res?.growthdata?.colour,
                    textColour: res?.growthdata?.textColour,
                    progress: res?.growthdata?.progress,
                    name: res?.growthdata?.text,
                    type: res?.growthData?.type,
                    dates: res?.growthdata?.dates,
                    _id: res?.growthdata?._id,
                    createdAt: res?.growthdata?.createdAt,
                    updatedAt: res?.growthdata?.updatedAt,
                  };
                }
                return item;
              });
              disapatch(storeGetCalendarData(temp));
              // disapatch(
              //   storeGetActionData(getCalenderDataSlice?.getActionData)
              // );

              let growthData = {
                uid: localStorage.getItem("userDetail"),
                archive: false,
                status: "IN_PROGRESS",
              };
              getGrowthData(growthData)
                .then((res) => {
                  disapatch(storeGetGrowthData(res?.data));
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  disapatch(updateIsGetGrowthData(true));
                });

              const editPreSelectedGrowthData = {
                uid: localStorage.getItem("userDetail"),
                goalId: colorPickData.id,
                preSelectedGrowth: colorPickData.preSelectedGrowth.map(
                  (item) => {
                    if (item.text === colorPickData.text) {
                      return { ...item, active: "false" };
                    }
                    return item;
                  }
                ),
                preSelectedGrowthComplete:
                  colorPickData.preSelectedGrowthComplete,
              };
              editPreSelectedGrowth(editPreSelectedGrowthData)
                .then((res) => {
                  console.log("res = ", res);
                })
                .catch((error) => {
                  console.log("error = ", error);
                });
              // const updateData = colorPickData.preSelectedGrowth.map((ele) => {
              //   if (ele.text === colorPickData.text) {
              //     return { ...ele, active: "false" };
              //   }
              //   return ele;
              // });
              // const tempGetAllGrowthData = [];
              // getAllGrowthData?.GetGrowthData?.map((data) => {
              //   tempGetAllGrowthData.push({
              //     actions: data.actions,
              //     actionsArchived: data.actionsArchived,
              //     actionsComplete: data.actionsComplete,
              //     archive: data.archive,
              //     habits: data.habits,
              //     habitsArchived: data.habitsArchived,
              //     habitsComplete: data.habitsComplete,
              //     increase: data.increase,
              //     mySelf: data.mySelf,
              //     others: data.others,
              //     preSelectedGrowth:
              //       data.questionCategoryId === colorPickData.questionCategoryId
              //         ? updateData
              //         : data.preSelectedGrowth,
              //     preSelectedGrowthComplete: data.preSelectedGrowthComplete,
              //     questionCategory: data.questionCategory,
              //     questionCategoryId: data.questionCategoryId,
              //     status: data.status,
              //     tbd: data.tbd,
              //     uid: data.uid,
              //     url: data.url,
              //     _id: data._id,
              //   });
              //   return data;
              // });
              // disapatch(storeGetGrowthData(tempGetAllGrowthData));
              setisDropGrowthAreaOpen(false);
            })
            .catch((err) => {
              console.log("error = ", err);
            });
          setisDropGrowthAreaOpen(false);
        }
      }
    }
  };
  console.log("colorPickData", colorPickData, colorPickData?.cat?.url);
  console.log("updateColourPickData", updateColourPickData);
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 244,
        height: expanded ? 210 : 156,
        padding: "12px",
        background: "white",
        boxShadow: "0px 0px 44px rgba(25, 13, 26, 0.1)",
        borderRadius: "12px",
        overflow: "auto",
      }}
    >
      <Box className={classes.box_name}>
        <div className={classes.dimention_box}>
          <img src={colorPickData?.cat  && colorPickData?.cat?.url ? imageURLRender(colorPickData?.cat?.url) : updateColourPickData?.data  && updateColourPickData?.data?.url ? imageURLRender(updateColourPickData?.data?.url) : SmallEngagement} style={{
            height: '16px',
            width: '16px',

          }} alt=""/>
          <Typography variant="p" className={classes.dimention_name}>
            {Object.keys(updateColourPickData).length === 0
              ? colorPickData.name
              : updateColourPickData.questionCategory}
          </Typography>
        </div>
        <Typography variant="p" className={classes.new_question}>
          {Object.keys(updateColourPickData).length === 0
            ? colorPickData.text
            : updateColourPickData.name}
        </Typography>
      </Box>
      <Box className={classes.divider_box}>
        <div className={classes.hr_line}></div>
      </Box>
      <Box className={classes.time}>
        <img src={Clock} />
        <Typography variant="p" className={classes.duration_time}>
          {moment(
            Object.keys(updateColourPickData).length === 0
              ? colorPickData.startDate
              : updateColourPickData.startDate
          ).format("DD MMM YYYY")}{" "}
          to{" "}
          {moment(
            Object.keys(updateColourPickData).length === 0
              ? colorPickData.endDate
              : updateColourPickData.endDate
          ).format("DD MMM YYYY")}
        </Typography>
      </Box>
      <Box className={classes.divider_box}>
        <div className={classes.hr_line}></div>
      </Box>
      <Box
        className={classes.color_Picker}
        onClick={() => {
          if (expanded) {
            setExpanded(false);
          } else {
            setExpanded(true);
          }
        }}
      >
        <img src={ColorPick} />
        <Typography variant="p" className={classes.duration_time}>
          Pick a colour
        </Typography>
        <img src={expanded ? SmallUpExpanded : Smallexpanded} />
      </Box>
      {expanded ? (
        <Box className={classes.color_data} style={{}}>
          <Grid className={classes.color_Column} container rowSpacing={0}>
            {colorPalette.map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  sm={2.5}
                  className={classes.color_collection}
                  id={`${item.bgColour} ${item.colour}`}
                  sx={{
                    background: item.bgColour,
                    border: temp.map((ele) =>
                      ele.colour === item.bgColour
                        ? `1px solid ${ele.textColour}`
                        : ""
                    ),
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handleChange(e);
                  }}
                >
                  {uniquedata.map((ele) => {
                    if (ele.colour === item.bgColour) {
                      return (
                        <LockOutlinedIcon
                          style={{ fill: item.colour, width: 10, height: 10 }}
                        />
                      );
                    }
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
export default React.memo(DropModal);
