import { Avatar, Button, Typography } from "@mui/material"
import classes from "./index.module.css"
import User2162 from "./../../assets/Svg/FriendsResult/User2162.svg"
import User2164 from "./../../assets/Svg/FriendsResult/User2164.svg"
import User2167 from "./../../assets/Svg/FriendsResult/User2167.svg"
import User2170 from "./../../assets/Svg/FriendsResult/User2170.svg"
import User2169 from "./../../assets/Svg/FriendsResult/User2169.svg"
import User2168 from "./../../assets/Svg/FriendsResult/User2168.svg"
import User2166 from "./../../assets/Svg/FriendsResult/User2166.svg"
import User2165 from "./../../assets/Svg/FriendsResult/User2165.svg"
import User2163 from "./../../assets/Svg/FriendsResult/User2163.svg"
import { useNavigate } from "react-router-dom"
const FriendsResultBlank = ({ data }) => {
    const navigate = useNavigate()
    let category, category_id, description, imageUrl, subCategory, subCategory_id;
    if(data && data.subcategoryData && data.subcategoryData[0]) {
        [{ category, category_id, description, imageUrl, subCategory, subCategory_id }] = data.subcategoryData
    }
     
    return (
        <div className={classes.friendsResultBlankContainer}>
            <div className={classes.imagesBlock}>
                <Avatar 
                    alt="User2162" 
                    src={User2162} 
                    sx={{ 
                        height: '3.375rem', 
                        width: '3.375rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '1.44rem', 
                        left: '45.4%' 
                    }} 
                />
                <Avatar 
                    alt="User2164" 
                    src={User2164} 
                    sx={{ 
                        height: '3.375rem', 
                        width: '3.375rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '4.94rem', 
                        left: '56%' 
                    }} 
                />
                <Avatar 
                    alt="User2167" 
                    src={User2167} 
                    sx={{ 
                        height: '2rem', 
                        width: '2rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '4.87rem', 
                        left: '72%' 
                    }} 
                />
                <Avatar 
                    alt="User2170" 
                    src={User2170} 
                    sx={{ 
                        height: '3rem', 
                        width: '3rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '2.44rem', 
                        left: '83%' 
                    }} 
                />
                <Avatar 
                    alt="User2169" 
                    src={User2169} 
                    sx={{ 
                        height: '3rem', 
                        width: '3rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '1.31rem', 
                        left: '16%',
                    }} 
                />
                <Avatar 
                    alt="User2168" 
                    src={User2168} 
                    sx={{ 
                        height: '3rem', 
                        width: '3rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '4.81rem', 
                        left: '26%' 
                    }} 
                />
                <Avatar 
                    alt="User2166" 
                    src={User2166} 
                    sx={{ 
                        height: '3rem', 
                        width: '3rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '1rem', 
                        left: '62%' 
                    }} 
                />
                <Avatar 
                    alt="User2165" 
                    src={User2165} 
                    sx={{ 
                        height: '2.375rem', 
                        width: '2.375rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '1.62rem', 
                        left: '32%' 
                    }} 
                />
                <Avatar 
                    alt="User2163" 
                    src={User2163} 
                    sx={{ 
                        height: '3.375rem', 
                        width: '3.375rem', 
                        display: 'flex', 
                        position: 'absolute', 
                        top: '5.31rem', 
                        left: '40%' 
                    }} 
                />

            </div>
            <div className={classes.friendsResultContent}>
                <div className={classes.friendsResultDataContent}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "#190D1A",
                            fontSize: '1.125rem !important',
                            fontWeight: 500,
                            textAlign: 'center'
                        }}
                    >
                        You haven’t invited anyone yet
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "#B4B0B4",
                            fontSize: '0.875rem !important',
                            fontWeight: 400,
                            textAlign: 'center'
                        }}
                    >
                        Start inviting your friends, family colleagues and other to get their perspective on your discovery
                    </Typography>
                </div>
                <Button variant="contained" disabled={!subCategory_id} sx={{
                    borderRadius: '6px',
                    background: '#9747FF',
                    display: 'flex',
                    padding: '12px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',

                }} onClick={() => {
                    if(subCategory_id){
                        localStorage.setItem("selctedCategoryId", category_id)
                        localStorage.setItem("askother", "true")
                        navigate(`/test/${subCategory_id}`, {
                            state: {
                                subCategory: subCategory,
                                subCategory_desc: description,
                                imageUrl: imageUrl,
                                category: category,
                                categoryId: category_id,
                                subCategoryId: subCategory_id,
                              },
                        })
                    }
                }}>  <Typography
                    variant="body2"
                    sx={{
                        color: "#FFF",
                        fontSize: '0.875rem !important',
                        fontWeight: 400
                    }}
                >
                        Invite now
                    </Typography></Button>
            </div>
        </div>
    )
}

export default FriendsResultBlank