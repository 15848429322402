import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState, useEffect, useCallback } from "react";
import classes from "./SetDurationActionModal.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import ActionDateTimePicker from "../ActionDateTimePicker/ActionDateTimePicker";
import {
  updateActionData,
  updateScheduleState,
} from "../../../Store/Reducers/goalActionData";
import { useDispatch, useSelector } from "react-redux";
import { goalUpdateHabitAction } from "../../../services/auth";
import { storeGetActionData } from "../../../Store/Reducers/getCalendarData";
import { TIME_BASE } from "../../../Constant/GoalConstant";
import moment from "moment";

function SetDurationActionModal({
  setIsSetDurationAction,
  actionData,
  setActionData,
  setActionSliceData,
  actionSliceData,
  updateAction,
  setUpdateAction,
  isSetDurationAction,
}) {
  console.log("actionData", actionData);
  const [isSchedule, setIsSchedule] = useState(false);
  const dispatch = useDispatch();
  const editShedualdataHandler = (executionStart, executionEnd) => {};
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 244,
        padding: "12px",
        background: "white",
        boxShadow: "0px 0px 44px rgba(25, 13, 26, 0.1)",
        borderRadius: "12px",
        overflow: "auto",
      }}
      onClose={() => {
        console.log("helo");
        setIsSetDurationAction(false);
      }}
    >
      <Box className={clsx(classes.main_box)}>
        <Typography variant="p">
          {Object.keys(updateAction).length
            ? updateAction.actionName
            : actionData.text}
        </Typography>
        <Box className={classes.divider_box}>
          <div className={classes.hr_line}></div>
        </Box>
        <div
          className={classes.DateAndTimewrapper}
          onClick={useCallback(() => {
            console.log("actionData====", actionData);
            const obj = {
              executionDate: actionData?.executionDate
                  ? new Date(actionData?.executionDate).toLocaleString()
                  : new Date().toLocaleString(),
                executionDateEnd: actionData.executionDateEnd
                  ? actionData.executionDateEnd
                  : actionData?.executionDate
                    ? actionData?.executionDate
                    : new Date(moment().add(1, 'hour')),
            }
            if(actionData.scheduled_type === "DEFAULT") {
              const time = moment()
              obj.executionDate = actionData?.executionDate
              ? moment(actionData?.executionDate, "DD-MMMM,yyyy").set({
                hour:   time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            }).format("MM/DD/YYYY, HH:mm:ss")
              : new Date().toString();
              obj.executionDateEnd = actionData.executionDateEnd
              ? moment(actionData.executionDateEnd)
              : actionData?.executionDate
                ? moment(actionData?.executionDate)
                : moment()
            }
            console.log("obj", obj);
            setIsSchedule(true);
            dispatch(
              updateScheduleState({
                actionName: actionData.actionName,
                actionDetail: actionData.actionDetail,
                ...obj,
                actionId: actionData.actionId,
              })
            );
          }, [isSchedule])}
        >
          <img src={Clock} />
          <Typography variant="body2" sx={{ color: "#190D1A" }}>
            Set duration
          </Typography>
        </div>
      </Box>
      {isSchedule && (
        <ActionDateTimePicker
          setIsSetDurationAction={setIsSetDurationAction}
          isSetDurationAction={isSetDurationAction}
          editShedualdataHandler={editShedualdataHandler}
          isSchedule={isSchedule}
          setIsSchedule={setIsSchedule}
          actionData={actionData}
          setActionSliceData={setActionSliceData}
          actionSliceData={actionSliceData}
          updateAction={updateAction}
          setUpdateAction={setUpdateAction}
        />
      )}
    </Box>
  );
}

export default React.memo(SetDurationActionModal);
