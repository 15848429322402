import classes from "../Onbording.module.css";
import { Box } from "@mui/system";
import Input from "../../../../components/Input/Input";
import { PrevAndNextButtonGroup } from "../../../../components/Button/Button";
import { Step, StepsWrapper, SubHeadingTwo } from "../../StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateAge } from "../../../../Store/Reducers/onbordingSlice";
import { useState } from "react";
import {
  ageValidation,
  nameValidation,
} from "../../../../validations/OnbordingValidation/StepOne";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Typography } from "@mui/material";

export default function StepTwo({ onNext, onPrevious }) {
  const { age, dob, name } = useSelector((state) => state.onbording);
  console.log(age, 19999);
  const [datePickerValue, setDatePickerValue] = useState(dob);
  const [errorMessage, setErrorMessage] = useState("");
  const [ageValue, setAgeValue] = useState(age);
  const dispatch = useDispatch();
  const onNextStep = () => {
    if (!ageValue) {
      setErrorMessage("Age is required");
      return;
    }
    // dispatch(updateAge({ ageValue, dob: datePickerValue }));
    dispatch(updateAge({ ageValue }));
    onNext();
  };

  console.log(datePickerValue);

  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step></Step>
        <Step></Step>
      </StepsWrapper>
      <div className={classes.wrapperDiv}>
        <Box className={classes.wrapper}>
          <Typography variant="h2"  sx={{ textAlign: "center" }}>{name}, it’s nice to meet you!</Typography>
        </Box>
        <SubHeadingTwo className={classes.wrapper} sx={{ mb: "12px" }}>
          <Typography variant="subtitle1"  sx={{ textAlign: "center" }}>What’s your age?</Typography>
        </SubHeadingTwo>
        <Box className={classes.wrapper} sx={{ mb: "16px" }}>
          <Input
            placeholder="Age"
            value={ageValue}
            onChange={setAgeValue}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validationFunction={ageValidation}
            type="number"
          />
        </Box>
        {/* <Box className={classes.wrapper}>
        <Input
          placeholder="Birthdate"
          type="date"
          value={datePickerValue}
          onChange={setDatePickerValue}
          errorMessage={""}
          setErrorMessage={()=>{}}
          validationFunction={ageValidation}
        />
      </Box> */}

        <Box sx={{ width: "100%" }}>
          <PrevAndNextButtonGroup
            isPreviousButton={true}
            onNext={onNextStep}
            onPrevious={onPrevious}
            // isDisabled={!ageValue || !datePickerValue || errorMessage}
            isDisabled={!ageValue || errorMessage}
          />
        </Box>
      </div>
    </>
  );
}
