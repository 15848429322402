import { styled } from "@mui/material/styles";
import {
  IconButton,
  InputAdornment,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import classes from "./Input.module.css";
import { useState } from "react";
import HidePassword from "../../assets/Svg/Hidepassword.svg";
import ShowPassword from "../../assets/Svg/Showpassword.svg";
import Error from "../../assets/Svg/Error.svg";
import CheckedIcon from "../../assets/Svg/Right.svg";
import PinkRadio from "../../assets/Svg/PinkRadio.svg";

const InputField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: 0;
    // background: #f9f9f9;
    // border: 1px solid #f9f9f9;
    // border-radius: 16px;

    //  :hover {
    //   border: 1px solid #e5d5fc;
    // }
    // :focus {
    //   border: 1px solid #9747ff;
    // }

    position: relative;

    & .MuiInputAdornment-root {
      margin-left: 0px;
    }
  }
  button {
    position: absolute;
    right: 20px;
  }
  & .MuiOutlinedInput-input {
    height: 28px;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 16px;
    // padding: 15.5px 20px;
    padding: 13px 20px;
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiFormHelperText-root {
    color: #ef8b6e !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    margin-left: 0;

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`;
const InputCheckBoxField = styled(TextField)`
  height: 58px;
  border-radius: 16px;

  & input {
    color: #9747ff !important;
  }

  & .MuiOutlinedInput-root {
    padding: 0;
    position: relative;

    & .MuiInputAdornment-root {
      margin-left: 0px;
    }
  }

  button {
    position: absolute;
    right: 20px;
  }

  & .MuiOutlinedInput-input {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 16px;
    padding: 15.5px 20px;
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiFormHelperText-root {
    color: #ef8b6e !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    margin-left: 0;

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`;
const InputFieldChecked = styled(TextField)`
  height: 56px;
  border: 1px solid #9747ff;
  border-radius: 16px;
  background: #f9f9f9;
  & .MuiOutlinedInput-input {
    border-radius: 16px;
    padding: 13px 20px;
    ::placeholder {
      color: #9747ff;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      opacity: 1;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
const InputFieldGoal = styled(TextField)`
  background: #ffffff;
  & .MuiOutlinedInput-root {
    padding: 8px 40px 0px 0px;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    min-height: 56px;

    :hover {
      border: 1px solid #e5d5fc;
    }
    // :focus {
    //   border: 1px solid #9747ff;
    // }

    position: relative;

    & .MuiInputAdornment-root {
      margin-left: 8px;
      // height: 0em;
    }
  }
  button {
    position: absolute;
    right: 20px;
  }
  & .MuiOutlinedInput-input {
    height: 35px;
    font-size: 16px;
    color: #434243;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      text-align: left;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiFormHelperText-root {
    color: #434243;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    margin-left: 0;

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`;

const InputFieldGoalChecked = styled(TextField)`
  border: 1px solid #9747ff;
  border-radius: 16px;
  background: #ffffff;
  min-height: 56px;
  justify-content: center;
  & .MuiOutlinedInput-input {
    height: 37px;
    ::placeholder {
      color: #9747ff;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      opacity: 1;
      text-align: left;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
  & .MuiInputBase-root {
    padding: 8.5px 7px 0 7px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & div {
    display: flex;
    align-items: center;
  }
`;

const PasswordButton = styled(IconButton)`
  :hover {
    background: none;
  }
`;
export const CheckBoxInput = ({ placeholder }: any) => {
  return (
    <InputField
      // error
      className={classes.inputHover}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      size="small"
    />
  );
};
export const CheckBoxInputChecked = ({ placeholder }: any) => {
  return (
    <InputFieldChecked
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {/* <CheckCircleOutlineIcon
                fontSize="small"
                sx={{ color: "#9747ff" }}
              /> */}

              <img src={CheckedIcon} alt="" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export const CheckBoxInputGoal = ({ placeholder }: any) => {
  return (
    <InputFieldGoal
      // error
      className={classes.inputHover}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      multiline
      size="small"
      sx={{
        "& .MuiOutlinedInput-root" : {
          alignItems: "center",
          justifyContent:"center",
          padding: "10px 16px 3px 16px"
        },
      }}
    />
  );
};
export const FavouriteMultilineCheckbox = ({ placeholder }: any) => {  
  return (
    <InputFieldGoal
      // error
      sx={{
        "& .MuiOutlinedInput-root" : {
          alignItems: placeholder.length > 60 || placeholder.length > 50 ? "flex-start" : "center" ,   
        },
        "& .MuiInputAdornment-root" : {
          height:"auto"
        }
      }}
      className={classes.inputHover}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      multiline
      fullWidth
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={PinkRadio}
              className={classes.pinkRadioImg}
              alt="CheckboxStartIcon"
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
export const CheckBoxInputCheckedGoal = ({ placeholder }: any) => {
  return (
    <InputFieldGoalChecked
      id="outlined-basic1"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      size="small"
      multiline
      sx={{
        "& .MuiOutlinedInput-root" : {
          alignItems: placeholder.length > 60 || placeholder.length > 50 ? "flex-start" : "center" ,   
        },
        "& .MuiInputAdornment-root" : {
          height:"auto"
        },
        ".MuiInputBase-root":{
          padding:"8.5px 16px 5px 16px"
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <img src={CheckedIcon} alt="" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export const CheckBoxFavouriteInputCheckedGoal = ({ placeholder }: any) => {
  return (
    <InputFieldGoalChecked
      id="outlined-basic1"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      size="small"
      multiline
      sx={{
        "& .MuiOutlinedInput-root" : {
          alignItems: placeholder.length > 60 || placeholder.length > 50 ? "flex-start" : "center" ,   
        },
        "& .MuiInputAdornment-root" : {
          height:"auto"
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={PinkRadio}
              className={classes.pinkRadioImg}
              alt="CheckboxStartIcon"
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <img src={CheckedIcon} alt="" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
// radio for days

const InputFieldDay = styled(TextField)`
  height: 42px;
  background: #ffffff;
  & .MuiOutlinedInput-root {
    padding: 0;
    border: 1px solid #f3f3f3;
    position: relative;
    & .MuiInputAdornment-root {
      margin-left: 0px;
    }
  }
  button {
    position: absolute;
    right: 20px;
  }
  & .MuiOutlinedInput-input {
    height: 42px;
    border-right: 1px solid #f9f9f9;
    font-size: 16px;
    color: #190d1a;
    padding: 0px;
    text-transform: capitalize;
    text-align: center;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      text-transform: capitalize;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const InputFieldDayChecked = styled(TextField)`
  height: 42px;
  background: #9747ff;

  & .MuiOutlinedInput-input {
    height: 42px;
    padding: 0px;
    ::placeholder {
      color: #ffffff;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      opacity: 1;
      text-align: center;
      text-transform: capitalize;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
export const CheckBoxInputDay = ({ placeholder, sx = {} }: any) => {
  return (
    <InputFieldDay
      // error
      className={classes.inputHover}
      id="outlined-basic"
      value={placeholder}
      variant="outlined"
      fullWidth
      size="small"
      sx={sx}
    />
  );
};
export const CheckBoxInputCheckedDay = ({ placeholder }: any) => {
  return (
    <InputFieldDayChecked
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      size="small"
    />
  );
};

const ValidationMessage = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",

  marginTop: "8px",
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Input = ({
  placeholder,
  value,
  onChange,
  errorMessage,
  setErrorMessage,
  validationFunction,
  type,
  height,
  disabled = false
}: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  return (
    <InputField
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      error={errorMessage !== ""}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      className={errorMessage ? classes.validationError : ""}
      fullWidth
      sx={{ height: { xl: height, lg: height, md: height } }}
      size="small"
      disabled={disabled}
      helperText={
        errorMessage ? (
          <ValidationMessage>
            {/* <CancelOutlinedIcon fontSize="inherit" /> */}
            <img src={Error} alt="" />
            <span>{errorMessage}</span>
          </ValidationMessage>
        ) : null
      }
      value={value}
      onBlur={(e) => {
        console.log(251, e.target.value);
        if (e.target.value !== "") {
          let error = validationFunction(e.target.value);
          console.log(error);
          setErrorMessage(error);
        }
      }}
      onChange={(e) => {
        onChange(e.target.value);
        // let error = validationFunction(e.target.value);
        // console.log(error);
        setErrorMessage("");
      }}
      InputProps={{
        endAdornment: (
          <>
            {type === "password" && (
              <InputAdornment position="end">
                <PasswordButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  disableRipple
                >
                  {showPassword ? (
                    <img src={ShowPassword} alt="" />
                  ) : (
                    <img src={HidePassword} alt="" />
                  )}
                </PasswordButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export const InputCheckBox = ({ placeholder, value, onChange, type }: any) => {
  return (
    <InputCheckBoxField
      type={type}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      sx={{ border: value ? "1px solid #9747ff" : "none" }}
      fullWidth
      size="small"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      InputProps={{
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {/* <CheckCircleOutlineIcon
                fontSize="small"
                sx={{ color: "#9747ff" }}
              /> */}
              <img src={CheckedIcon} alt="" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Input;
