import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonGroup from "../GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import { CompletedAcrchiveGoalDialog } from "../GoalComponent/GoalDialog";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import { useSelector } from "react-redux";
function ArchiveGoalDialog({
  setisArchieveOpen,
  isArchieveOpen,
  preSelectedState,
  goalName,
  setIsGoalArchive,
  archiveDataHandler,
}) {
  const { ActiondataSlice, HabitDataSlice } = useSelector((state) => state);
  return (
    <CompletedAcrchiveGoalDialog
      open={isArchieveOpen}
      onClose={() => {
        setisArchieveOpen(false);
      }}
    >
      <Box sx={{ padding: "40px 22px", position: "relative" }}>
        <Typography variant="h6" sx={{ textAlign: "center" }} color=" #190D1A">
          You still have uncompleted{" "}
          {preSelectedState?.length !== 0 &&
          ActiondataSlice?.actionData?.length !== 0 &&
          HabitDataSlice?.habitData?.length !== 0
            ? "growth areas, actions and habits"
            : preSelectedState?.length !== 0 &&
              ActiondataSlice?.actionData?.length !== 0
            ? "growth areas and actions"
            : preSelectedState?.length !== 0 &&
              HabitDataSlice?.habitData?.length !== 0
            ? "growth areas and habits"
            : ActiondataSlice?.actionData?.length !== 0 &&
            HabitDataSlice?.habitData?.length !== 0 ?
            "actions and habits" : 
            ActiondataSlice?.actionData?.length !== 0 ? "actions" :
            HabitDataSlice?.habitData?.length !== 0 ? "habits" :
            "growth areas"
            }
            {" "}
          for {goalName}. Are you sure you want to move it to archive?
        </Typography>
        <Box>
          <ButtonGroup
            nextButtonText="Keep working"
            previousButtonText="Archive"
            isPrevDisable={false}
            onClickNext={() => {
              setisArchieveOpen(false);
            }}
            onClickPrevious={() => {
              archiveDataHandler();
              setIsGoalArchive(true);
            }}
          />
        </Box>
        <img
          src={CloseSmalIcon}
          style={{ position: "absolute", top: "16px", right: "20px" }}
          onClick={() => {
            setisArchieveOpen(false);
          }}
          alt=""
        />
      </Box>
    </CompletedAcrchiveGoalDialog>
  );
}
export default ArchiveGoalDialog;
