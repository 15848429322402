import { Button, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classes from "./SocialEmptyResult.module.css"
import { ReactComponent as SocialEmpty } from "./../../assets/Svg/EmptyResultSocial.svg"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: `0px 0px 44px rgba(25, 13, 26, 0.05)`,
        fontSize: 11,
        borderRadius: '12px',
        padding: '16px',
        gap: '12px'
    },
    [`& .${tooltipClasses.tooltipArrow}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #F3F3F3'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        '&::before': {
            border: '1px solid #F3F3F3'

        }
    },
}));
const SocialEmptyResult = () => {
    const navigate = useNavigate();

    return <div className={classes.emptybox}>
        <div className={classes.emptyContentBox}>
            <div className={classes.graphicalBox}>
                <SocialEmpty />
            </div>
            <div className={classes.emptyTextBox}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#190D1A',
                    textAlign: 'center'
                }}>
                    Seem like you haven’t invited anyone
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#434243',
                    textAlign: 'center'
                }}>
                    Start inviting friends to get their perspective on your self-discovery, that will help you grow even faster
                </Typography>
            </div>
        </div>
        <div className={classes.emptyActionButton}>
            <Button variant="contained" sx={{
                background: '#9747FF',
                borderRadius: '100px',
                fontSize: '14px !important',
                padding: '12px 16px',
                height: '38px'
            }} onClick={() => {
                navigate("/testcategory");
            }}>Invite others</Button>
            <LightTooltip title={
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#190D1A',
                }}>
                    Before adding an action, you would need to set a goal in Goals. As you start adding actions to goals and schedule them, they will appear on your home page
                </Typography>
            } arrow placement="top"
            >
                <InfoOutlinedIcon sx={{
                    color: '#434243',
                    height: '16px'
                }} />
            </LightTooltip>
        </div>
    </div>
}

export default SocialEmptyResult