import {
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  Snackbar,
  styled,
} from "@mui/material";
import classes from "./Signup.module.css";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import {
  LinkButton,
  LinkButtonForTermsAndCondition,
  LinkButtonWithText,
  SubmitButton,
} from "../../components/Button/Button";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/fb.png";
import apple from "../../assets/images/apple.png";
import {
  appleProvider,
  facebookProvider,
  googleProvider,
} from "../../config/authMethods";
import {
  createUser,
  socialMediaAuth,
  termsAndConditionContent,
} from "../../services/auth";
import { isValidEmail, isValidPassword } from "../../validations/Email/Email";
import { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import useNotifications from "../../Hooks/useNotifications";
import checkedIcon from "../../../src/assets/images/Vector.png";
import Google from "../../assets/Svg/Google.svg";
import Facebook from "../../assets/Svg/Facebook.svg";
import Apple from "../../assets/Svg/Apple.svg";
import SixDigitCode from "../Passcode/SixDigitCode";
import { useDispatch } from "react-redux";
import { updateImageUrl, updateName, updateSurName } from "../../Store/Reducers/onbordingSlice";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Signup() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surName, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorSurName, setErrorSurName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsAndConditionUrl, settermsAndConditionUrl] = useState("");
  const [isCode, setIsCode] = useState(window.x_version ? true : localStorage.getItem("bypassCode") === "true");
  const [isCheckboxError, setIsCheckboxError] = useState(false)
  const [signedUpWithGoogleApple, setSignedUpWithGoogleApple] = useState(false)
  const { Notification, updateNotificationState, action } =
    useNotifications(false);
  let navigate = useNavigate();
  const changeTermsAndCondition = (val) => {
    setIsCheckboxError(!val)
    setTermsAndConditions(val)
  }
  useEffect(() => {
    termsAndConditionContent()
      .then((data) => {
        console.log(data);
        settermsAndConditionUrl(data?.policyAndConditionsData?.conditionsUrl);
      })
      .catch((err) => {
        console.log(err);
      });
    if (window.location.origin.includes('-x')) {
      setIsCode(true)
    }
  }, []);

  const createNewUser = async (result, isGoogleApple) => {
    const userDataRes = {
      uid: result?.user?.uid ? result?.user?.uid : result?.uid,
      user_email: result?.user?.email ? result?.user?.email : result?.email,
      name: name,
      surName: surName,
      platform: 'https://appx.scientific-selfdiscovery.com'
    };
    if(result.photoURL) {
      userDataRes.imageUrl = result.photoURL
    }
    if (window.location.origin.includes('app.scientific-selfdiscovery.com')) {
      userDataRes.platform = 'https://app.scientific-selfdiscovery.com'
    }
    setIsLoading(true);
    createUser(userDataRes)
      .then((data) => {
        console.log(data);
        localStorage.setItem("userDetail", data.data.uid);
        localStorage.setItem("userEmail", data.data.user_email);
        localStorage.setItem("name", name);
        localStorage.setItem("surName", surName);
        // updateNotificationState(data.message);
        dispatch(updateName(name))
        dispatch(updateSurName(surName))
        if(result.photoURL) {
          dispatch(updateImageUrl(result.photoURL))
        } else {
          dispatch(updateImageUrl(""))
        }
        if (isGoogleApple) {
          setEmail(data.data.user_email)
          setSignedUpWithGoogleApple(true)
          localStorage.setItem("bypassCode", true)
        } else {
          navigate("/onbording");
        }
      })
      .catch((error) => {
        console.log(12, error.response);
        if (error.response.data.description === "Uid is already registered") {
          updateNotificationState("User is alredy registered");
          // setErrorEmail("User is alredy registered")
          // navigate("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClick = async (provider) => {
    try {
      const result = await socialMediaAuth(provider);
      console.log("result ===", result);
      if (!result.uid) {
        throw new Error(result.message);
      } else {
        if(result.displayName) {
          const splitted = result.displayName.split(" ")
          setName(splitted[0])
          setSurname(splitted[1])
        }
        if(result.photoURL) {

        }
        createNewUser(result, true);
      }
    } catch (error) {
      if (!error.message.includes("auth/popup-closed-by-user")) {
        updateNotificationState(error?.message);
      }
      setIsLoading(false);
    }
  };

  const emailAuth = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log(result);
        localStorage.setItem("allUserDetails", JSON.stringify(result));
        return result;
      })
      .catch((err) => {
        setIsLoading(false);
        // updateNotificationState("User is alredy registered");
        setErrorEmail("User is alredy registered");
        setPassword("");
        console.log("----------", err);
        return err;
      });
  };

  const firebaseLogin = async () => {
    // setShowLoader(true);
    try {
      const result = await emailAuth(email, password);
      console.log("res 82", result);
      if (result.user) {
        createNewUser(result);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(">>>", error);
    }
  };

  const handleSubmit = () => {
    if (!termsAndConditions) {
      setIsCheckboxError(true)
      return
    }
    setIsLoading(true);
    firebaseLogin();
    setIsCode(true)

  };

  const handleNext = () => {
    localStorage.setItem("name", name);
    localStorage.setItem("surName", surName);
    dispatch(updateName(name))
    dispatch(updateSurName(surName))
    navigate("/onbording");
  }

  console.log(termsAndConditions);

  const CheckboxIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "11px",
        }}
      ></div>
    );
  };

  const CheckboxCheckedIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "2px",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <img src={checkedIcon} alt="" />
      </div>
    );
  };

  const signinWithApple = async () => {
    // signInWithRedirect(auth, appleProvider)
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        console.log(result);
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        if (!user.uid) {
          throw new Error(result.message);
        }
        createNewUser(result, true);
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(error, credential);
        setErrorPassword("Invalid credentials");
        setErrorEmail("Invalid credentials");
        setIsLoading(false);
      });
  };

  const TermsAndCondition = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "-2px",
    marginLeft: "3px",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",

    [theme.breakpoints.up("xl")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4px",
    },
  }));
  return (
    <>
      {!isCode ? (
        <SixDigitCode setIsCode={setIsCode} />
      ) : (
        <>
          <Box className={classes.brandBox} sx={{ mb: "16px", py: "1px" }}>
            <Text textValue="Scientific Self-Discovery" variant="h6" color="#9747FF" otherSx={{
              fontSize: "18px",
              fontWeight: 500
            }} />
          </Box>
          <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px", mt: '32px' }}>
            <Text textValue={signedUpWithGoogleApple ? "Let’s set up your account" :"Create an account"} variant="h2" />
          </Box>
          <Box className={classes.wrapper}>
            <Input
              placeholder="Email"
              value={email}
              onChange={setEmail}
              validationFunction={isValidEmail}
              type="text"
              autoComplete='off'
              disabled={signedUpWithGoogleApple}
              errorMessage={errorEmail}
              setErrorMessage={setErrorEmail}
            />
          </Box>
          <Box className={classes.wrapper} style={{
            gap: '16px'
          }}>
            <Input
              placeholder="Name"
              value={name}
              onChange={setName}
              type="text"
              autoComplete='off'
              errorMessage={errorName}
              setErrorMessage={setErrorName}
            />
            <Input
              placeholder="Surname"
              value={surName}
              onChange={setSurname}
              type="text"
              autoComplete='off'
              errorMessage={errorSurName}
              setErrorMessage={setErrorSurName}
            />
          </Box>
          {signedUpWithGoogleApple ? <Box sx={{ width: "100%" }}>
                <SubmitButton
                  buttonText="Next"
                  isDisabled={isLoading ? false : (!email ||
                    !name ||
                    !surName ||
                    
                    errorEmail ||
                    errorName ||
                    errorSurName )
                  }
                  onClick={handleNext}
                  isLoading={isLoading}
                />
              </Box>
 : (
            <>
              <Box className={classes.wrapper}>
                <Input
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                  validationFunction={isValidPassword}
                  type="password"
                  autoComplete='off'

                  errorMessage={errorPassword}
                  setErrorMessage={setErrorPassword}
                />
              </Box>
              <TermsAndCondition className={classes.terms_and_condition}>
                <Checkbox
                  {...label}
                  disableRipple
                  sx={{ pl: "0", width: "20px", height: "20px", borderColor: isCheckboxError && "red" /* background: "red" */ }}
                  checked={termsAndConditions}
                  onChange={(e) => changeTermsAndCondition(e.target.checked)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />

                <span style={{ color: "#434243", paddingLeft: "12px" }}>
                  By creating an account you are agree to our{" "}
                  <LinkButtonForTermsAndCondition
                    termsAndConditionUrl={termsAndConditionUrl}
                    buttonText="Terms & Conditions"
                    color="#000000"
                    textDecoration="underline"
                  />
                </span>
              </TermsAndCondition>
              {isCheckboxError && <p style={{
                color: "red",
                fontSize: '12px'
              }}>Please select the checkbox to agree to our Terms & Conditions</p>}
              <Box sx={{ width: "100%" }}>
                <SubmitButton
                  buttonText="Sign up"
                  isDisabled={isLoading ? false :(
                    !email ||
                    !name ||
                    !surName ||
                    !password ||
                    errorEmail ||
                    errorName ||
                    errorSurName ||
                    errorPassword )/* ||
                !termsAndConditions */
                  }
                  onClick={handleSubmit}
                  isLoading={isLoading}
                />
              </Box>

              <Box className={`${classes.wrapper} ${classes.orText}`}>or</Box>
              <Box
                className={classes.wrapper}
                sx={{ gap: "12px", padding: "16px" }}
              >
                <Box
                  className={classes.imgIcon}
                  onClick={() => handleOnClick(googleProvider)}
                >
                  <img src={Google} alt="" />
                </Box>

                <Box className={classes.imgIcon} onClick={() => signinWithApple()}>
                  <img src={Apple} alt="" />
                </Box>

                <Box
                  className={classes.imgIcon}
                  onClick={() => handleOnClick(facebookProvider)}
                >
                  <img src={Facebook} alt="" />
                </Box>
              </Box>
              <Box className={classes.wrapper}>
                <LinkButtonWithText
                  text="Already a user?"
                  buttonText="Log in"
                  navigateUrl="/"
                />
              </Box>
            </>
          )}




          {Notification}
        </>
      )
      }
    </>
  );
}
