import { TextField } from "@mui/material";
import React from "react";
function EditSelf({ handleSelfInput, mySelfState, editSelfHandler }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <TextField
          size="small"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #f3f3f3",
              borderRadius: "12px",
            },
            " & .MuiOutlinedInput-input": {
              color: " #190d1a",
              padding: "10px",
            },
            " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
              { border:    mySelfState === "" ? "1px solid red" : "1px solid #9747FF" },
          }}
          InputProps={{
            sx: {
              "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                  border:   mySelfState === "" ? "1px solid red" : "1px solid #e5d5fc",
                },
              },
            },
          }}
          fullWidth
          name="text"
          value={mySelfState}
          onChange={(e) => handleSelfInput(e)}
          type="text"
          placeholder="Text"
          id="outlined-start-adornment"
        />
        {/* <div
          style={{ width: "16px", height: "16px", background: "#D9D9D9" }}
          onClick={() => {
            editSelfHandler();
          }}
        ></div> */}
      </div>
    </>
  );
}
export default EditSelf;
