import classes from "./TestItem.module.css"
import Confetti from "react-confetti";
import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import typography from "../../../theme/typography";
import { useNavigate } from "react-router-dom";

const UnlockSuccessFullModal = props => {
    const confettiRef = useRef(null);
    const navigate = useNavigate();

    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const {
        unlockSuccessfullModalVisible,
        setUnlockSuccessfullModalVisible,
        selectedItem,
        paymentSuccess,
        loadingPaymentStatus
    } = props
    useEffect(() => {
        setHeight(confettiRef?.current?.clientHeight);
        setWidth(confettiRef?.current?.clientWidth);
    }, []);
    console.log("UnlockSuccessFullModal selectedItem", selectedItem);
    const renderItemName = () => {
        if (selectedItem.category_id || selectedItem.questionCategoryId) {
            return "Great! You’ve just unlocked \"" + (selectedItem.category_id && selectedItem.category ? selectedItem.category : selectedItem.questionCategory ? selectedItem.questionCategory : '') + "\""
        }
        if (selectedItem.buyFor && selectedItem.buyWith) {
            return <>
                Great! You’ve just purchased  {selectedItem.name}
            </>
        }
        if (selectedItem.price && selectedItem.price.id) {
            return <>
                Great! You’ve just purchased  {selectedItem.name}
            </>
        }
    }
    return <Dialog
        open={unlockSuccessfullModalVisible}
        onClose={() => {
            setUnlockSuccessfullModalVisible(false);
            localStorage.removeItem("selectedItem")
            localStorage.removeItem("selected_item")
        }}
        maxWidth={'md'}
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                position: "unset",
                padding: "20px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {loadingPaymentStatus && (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>
        )}
        {!loadingPaymentStatus && (
            <div className={classes.mainWrapper} ref={confettiRef}>
                {paymentSuccess && <Confetti width={width} height={height} />}
                <div className={classes.testEndPageWrapper}>
                    <div className={classes.heading}>
                        <Typography variant="h3" sx={{ textAlign: "center" }}>
                            {paymentSuccess ? renderItemName() : 'Payment was not successfull'}
                        </Typography>
                    </div>
                    {((selectedItem.buyFor && selectedItem.buyWith) || (selectedItem.price && selectedItem.price.id) || !paymentSuccess) ? null : <div className={classes.bottomContainerWrapper}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.buttonWrapper}
                            rowSpacing="12px"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
                            >
                                <Button
                                    className={classes.nextButton}
                                    sx={{ ...typography.subtitle1 }}
                                    variant="outlined"
                                    onClick={() => {
                                        if (selectedItem.questionCategoryId) navigate("/results")
                                        if (selectedItem.category_id && selectedItem.subCategory_id) {
                                            navigate(`/test/${selectedItem.subCategory_id}`, {
                                                state: {
                                                    subCategory: selectedItem.subCategory,
                                                    description: selectedItem.description,
                                                    subCategory_desc: selectedItem.subCategory_desc,
                                                    category_desc: selectedItem.category_desc,
                                                    category: selectedItem.category,
                                                    categoryId: selectedItem.category_id,
                                                    subCategoryId: selectedItem.subCategory_id,
                                                    imageUrl: selectedItem.imageUrl,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    Start Test
                                </Button>
                            </Grid>
                        </Grid>
                    </div>}

                </div>
            </div>
        )}

    </Dialog>
}

export default UnlockSuccessFullModal