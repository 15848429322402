import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header";
import classes from "./TestCategory.module.css";
import Star from "../../assets/images/Star.png";
import Search from "../../assets/Svg/Search.svg";
import TestCategoryCardDisable, {
  TestCategoryCard,
} from "../../components/TestCategoryCard/TestCategoryCard";
import { categoryList, categoryPage } from "../../services/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTestCategoryId,
  setSelectedCategoryData,
  storeTestCategories,
} from "../../Store/Reducers/testCategories";
import { useQuery } from "react-query";
import AskOther from "../../core-ui/AskOther/AskOther";
import PaymentService from "../../services/Payments";
import UnlockSuccessFullModal from "./Modals/UnlockSuccessfullModal";
const TestCategory = () => {
  const { categoryId } = useParams();
  const { userData } = useSelector((state) => state.userProfile);
  const { testCategories } = useSelector((state) => state);
  const [categoryData, setCategoryData] = useState(
    testCategories.testCategories
  );
  const [paymentId, setSelectedId] = useState(null)
  const { state: routerState, pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loadingPaymentStatus, setLoadingPaymentStatus] = useState(false);
  const [state, setState] = useState(routerState || testCategories.selectedCategoryData || {})
  const navigate = useNavigate();
  const dispatch = useDispatch();
    const [ loadingTime, setLoadingTime] = useState(true)
  // askOther modal
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };
  const handleClickOpen = () => {
    setIsAskOtherModalOpen(true);
  };
  // ask other modal

  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    // background: "yellow",
    overflowY: "auto",
    height: "100vh",
  }));

  useLayoutEffect(() => {
    if (testCategories?.activeTestCategoryId !== categoryId) {
      setIsLoading(true);
    }
  }, [testCategories]);
  const getPaymentStatus = (id) => {
    setLoadingPaymentStatus(true)
    const objForServer = {
        "user_email": userData?.user_email,
        "uid": userData?.uid,
        "id": id
    }
    PaymentService.PAYMENT_STATUS(objForServer)
        .then(async (res) => {
          if (["paid",].includes(res.data.payment_status)) {
            setPaymentSuccess(true)
          } else {
            setPaymentSuccess(false)
          }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
          setLoadingPaymentStatus(false)
        });
  }
  let controller;
  const [unlockSuccessfullModalVisible, setUnlockSuccessfullModalVisible] = useState(false)
  useEffect(() => {
    if (pathname.includes('paymentresult')) {
      const selectedItemLocal = localStorage.getItem("selectedItem")
      if (selectedItemLocal) {
          setSelectedId(selectedItemLocal)
          const internalLoop = () => {
            if(userData?.uid && selectedItemLocal) {
              getPaymentStatus(selectedItemLocal)
              if(localStorage.getItem("selected_item") && Object.keys(JSON.parse(localStorage.getItem("selected_item"))).length) {
                setSelectedItem(JSON.parse(localStorage.getItem("selected_item")))
              }
              !unlockSuccessfullModalVisible && setUnlockSuccessfullModalVisible(true)
            } else {
              setTimeout(() => {
                internalLoop()
              }, 1000);
            }
          }
          internalLoop()
      } else {
          // navigate('/store')
      }
    }
    return () => {
      controller && controller.abort();
      dispatch(storeTestCategories([]));
      dispatch(setActiveTestCategoryId(categoryId));
    }
  }, [paymentId])
   const fetchTestCategories = () => {

    const user = localStorage.getItem("userDetail");
    return /* categorys.length ? { categoryData: categorys } :  */categoryList({ uid: user, type: "NORMAL" });
  };
  const fetchTestSubCategories = () => {
   controller = new AbortController();
    const user = localStorage.getItem("userDetail");
    return categoryPage({ uid: user, category_id: categoryId }, controller.signal);
  };

  const { isLoading: loading } = useQuery(
    "test-subCategorys",
    fetchTestSubCategories,
    {
      onSuccess: async (data) => {
        console.log("test-subCategorys",data);
        if(!state || !state.category) {
          const allCategory = await fetchTestCategories()
          const foundCate = allCategory.categoryData.find(it => it.category_id === categoryId)
          if(foundCate) {
            setState({
              ...foundCate,
              category_desc: foundCate?.description,
              categoryId: foundCate.category_id
            })
            dispatch(setSelectedCategoryData({
              ...foundCate,
              category_desc: foundCate?.description,
              categoryId: foundCate.category_id
            }))
          }
        }
        setCategoryData(data.subCategoryData);
        setLoadingTime(false)
        dispatch(storeTestCategories(data.subCategoryData));
        dispatch(setActiveTestCategoryId(categoryId));
        setIsLoading(false);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchOnWindowFocus: false,
    }
  );
    console.log("isLoading =====", isLoading, loading);
  const InputField = styled(TextField)`
    height: 56px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }startTest/testend
    }
  `;

  const SearchCategoryList = (e) => {
    setSearchValue(e.target.value);
    const filteredCategories = categoryData.filter((category) => {
      return category.subCategory
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchResultArray(filteredCategories);
  };

  const onClickHandler = ({
    subCategory_id,
    subCategory,
    subCategory_desc,
    category_desc,
    category,
    categoryId,
    subCategoryId,
    imageUrl,
  }) => {
    localStorage.setItem("selctedSubCategoryId", subCategory_id);
    // {subCategory_id,subCategory,subCategory_desc: description,category_desc :state?.category_desc,category,categoryId:category_id,subCategoryId:subCategory_id}
    navigate(`/test/${subCategory_id}`, {
      state: {
        subCategory,
        subCategory_desc,
        category_desc,
        category,
        categoryId,
        subCategoryId,
        imageUrl,
      },
    });
  };

  const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
        position: unset;
      }
    }
  `;
  console.log("re rendered due to state changed")

  //  SOON , LIVE , DEACTIVATE
  return (
    <RightSideContentWrapper>
      <Header />
      <div className={classes.categoryHeading}>
        <div>
          <Typography variant="h3">{state?.category}</Typography>
        </div>
        <div className={classes.categoryDescription}>
          {/* // if we want to display test according to our theme then put it text into typography or comment typography */}
          <Typography
            variant="subtitle1"
            color="#434243"
            sx={{ lineHeight: "145%" }}
          >
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: state?.category_desc }}
            />
          </Typography>
        </div>
      </div>
      <div className={classes.SearchBar}>
        <InputField
          size="small"
          fullWidth
          type="text"
          autoFocus={searchValue !== ""}
          value={searchValue}
          onChange={(e) => SearchCategoryList(e)}
          placeholder="Well-being, self-esteem..."
          id="outlined-start-adornment"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Search} alt="" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div>
        <Grid container spacing={1.5} className={classes.testListWrapper}>
          {isLoading || loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                height: "calc(100vh - 420px)",
              }}
            >
              <CircularProgress />
            </div>
          ) : searchValue ? (
            searchResultArray.map((data) => {
              const {
                _id,
                subCategory,
                description,
                category,
                subCategoryPercentage,
                subCategory_id,
                activate,
                testStatus,
                points,
                time,
                category_id,
                preDescription,
                score,
                imageUrl,
                recommendation,
                coins,
              } = data;
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                  key={_id}
                >
                  {testStatus === "LIVE" ? (
                    <TestCategoryCard
                      subCategory={subCategory + "?????"}
                      description={preDescription}
                      percentage={subCategoryPercentage}
                      onClickHandler={() =>
                        onClickHandler({
                          subCategory_id,
                          subCategory,
                          subCategory_desc: description,
                          category_desc: state?.category_desc,
                          category,
                          categoryId: category_id,
                          subCategoryId: subCategory_id,
                          imageUrl,
                        })
                      }
                      loadingTime={loadingTime}
                      testStatus={testStatus}
                      time={time}
                      points={points}
                      subCategory_id={subCategory_id}
                      score={score}
                      category_id={category_id}
                      category={category}
                      subCategory_desc={description}
                      category_desc={state?.category_desc}
                      imageUrl={imageUrl}
                      handleClickOpen={handleClickOpen}
                      coins={coins}
                      {...data}
                    />
                  ) : (
                    <TestCategoryCardDisable
                      subCategory={subCategory}
                      description={preDescription}
                      percentage={subCategoryPercentage}
                      testStatus={testStatus}
                      time={time}
                      points={points}
                      subCategory_id={subCategory_id}
                      score={score}
                      imageUrl={imageUrl}
                      recommendation={recommendation}
                    />
                  )}
                </Grid>
              );
            })
          ) : (
            categoryData.map((data) => {
              const {
                _id,
                subCategory,
                description,
                subCategoryPercentage,
                subCategory_id,
                activate,
                category,
                testStatus,
                time,
                points,
                category_id,
                preDescription,
                score,
                imageUrl,
                coins,
                recommendation
              } = data;
              return (
                <Grid item xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}  key={_id}>
                  {testStatus === "LIVE" ? (
                    <TestCategoryCard
                      subCategory={subCategory}
                      description={preDescription}
                      percentage={subCategoryPercentage}
                      onClickHandler={() =>
                        onClickHandler({
                          subCategory_id,
                          subCategory,
                          subCategory_desc: description,
                          category_desc: state?.category_desc,
                          category,
                          categoryId: category_id,
                          subCategoryId: subCategory_id,
                          imageUrl,
                        })
                      }
                      testStatus={testStatus}
                      loadingTime={loadingTime}
                      time={time}
                      points={points}
                      subCategory_id={subCategory_id}
                      score={score}
                      category_id={category_id}
                      category={category}
                      subCategory_desc={description}
                      category_desc={state?.category_desc}
                      imageUrl={imageUrl}
                      handleClickOpen={handleClickOpen}
                      coins={coins}
                      {...data}
                    />
                  ) : (
                    <TestCategoryCardDisable
                      subCategory={subCategory}
                      description={preDescription}
                      percentage={subCategoryPercentage}
                      testStatus={testStatus}
                      time={time}
                      points={points}
                      subCategory_id={subCategory_id}
                      score={score}
                      imageUrl={imageUrl}
                      recommendation={recommendation}
                    />
                  )}
                </Grid>
              );
            })
          )}
        </Grid>
      </div>
      <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AskOther
          onClose={handleClose}
          setIsAskOtherModalOpen={setIsAskOtherModalOpen}
          isAskOtherModalOpen={isAskOtherModalOpen}
        />
      </CustomDialog>
      {unlockSuccessfullModalVisible && <UnlockSuccessFullModal
          selectedItem={selectedItem}
          paymentSuccess={paymentSuccess}
          unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
          setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
          loadingPaymentStatus={loadingPaymentStatus}
      />}
    </RightSideContentWrapper>
  );
};

export default TestCategory;
