import React, { useEffect, useState } from "react";
import classes from "./SideBar.module.css";
import Favourite from "../../../assets/Svg/Favourite.svg";
import Featured from "../../../assets/Svg/Featured.svg";
import CloseIconBlack from "../../../assets/Svg/CloseIconBlack.svg";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import RightDoubleArrow from "../../../assets/Svg/RightDoubleArrow.svg";
import LeftDoubleArrow from "../../../assets/Svg/LeftDoubleArrow.svg";
import FavouriteGoals from "../FavouriteGoals/FavouriteGoals";
import FeaturedGoals from "../FeaturedGoals/FeaturedGoals";
import { useDispatch, useSelector } from "react-redux";
import { getGrowthData } from "../../../services/auth";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../../Store/Reducers/goalGrowthCalendar";
import { NextButtonWithBackGround, SubmitButtonWithBackGround } from "../../Button/Button";

const ModelSideBar = ({
  setfullscreen,
  fullscreen,
  setSelectedGrowthArr,
  selectedGrowthArr,
  bgcolor,
  setBgColor,
  matches,
  setIsAskOtherModalOpen,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const [preselectedState, setPreselectedState] = useState([]);
  const disapatch = useDispatch();
  const [isclose, setIsClose] = useState(false);
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };
  return (
    <>
      <div
        className={classes.Darg_drop}
        style={{
          height: fullscreen ? "90.5vh" : expanded === false ? "643px" : "90vh",
        }}
      >
        {!getAllGrowthData.isGetGrowthData ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              className={classes.growth_Area}
              style={{
                justifyContent: fullscreen ? "space-between" : "normal",
                width: fullscreen ? "100%" : "auto",
              }}
            >
              <Typography
                variant="p"
                className={classes.text_header}
                style={{
                  fontSize: fullscreen ? "28px" : "15px",
                  fontWeight: fullscreen ? 680 : 500,
                  maxWidth: fullscreen ? "517px" : "auto",
                }}
              >
                Drag and drop two growth areas from any dimension into empty
                boxes
              </Typography>
              <img src={CloseIconBlack} alt="" onClick={handleClose} />
            </div>
            <div
              className={classes.goals}
              style={{
                flexDirection: fullscreen ? "row" : "column",
                height: "450px",
              }}
            >
              <div>
                {" "}
                <div className={classes.goals_featured}>
                  <img src={Featured} alt="Featured" />
                  <Typography variant="p" className={classes.text_header}>
                    Featured goals
                  </Typography>
                </div>
                {getAllGrowthData.GetGrowthData.length === 0 ? (
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }} variant="body2">
                      Data not found
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.Featured_goals}>
                    <FeaturedGoals
                      setExpanded={setExpanded}
                      expanded={expanded}
                      setfullscreen={setfullscreen}
                      fullscreen={fullscreen}
                      setSelectedGrowthArr={setSelectedGrowthArr}
                      selectedGrowthArr={selectedGrowthArr}
                      setBgColor={setBgColor}
                      bgcolor={bgcolor}
                      preselectedState={preselectedState}
                      setPreselectedState={setPreselectedState}
                    />
                  </div>
                )}
              </div>

              <div>
                <div className={classes.goals_favourite}>
                  <img src={Favourite} alt="Favourite" />
                  <Typography variant="p" className={classes.text_header}>
                    Favourite goals
                  </Typography>
                </div>
                <div className={classes.Featured_goals}>
                  <FavouriteGoals
                    setExpanded={setExpanded}
                    expanded={expanded}
                    setfullscreen={setfullscreen}
                    fullscreen={fullscreen}
                  />
                </div>
              </div>
            </div>
            <NextButtonWithBackGround buttonText={"Next"} />
          </>
        )}
      </div>
    </>
  );
};

export default ModelSideBar;
