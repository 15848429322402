import React from 'react'
import Progress from './Progress'
import PropTypes from 'prop-types'

export default class ProgressArray extends React.Component {
    render() {
        return (
            <div style={styles.progressArr}>
                {this.props.length.map((i, index) =>
                    <Progress
                        key={index}
                        width={1 / this.props.length.length}
                        next={this.props.next}
                        defaultInterval={this.props.defaultInterval}
                        videoDuration={this.props.videoDuration}
                        currentStory={this.props.currentStory}
                        active={i === this.props.progress.id ? 1 : (i < this.props.progress.id ? 2 : 0)}
                        pause={this.props.pause}
                        bufferAction={this.props.bufferAction}
                    />)}
                <button style={{
                    width: '20px',
                    height: '20px',
                    zIndex: 1000,
                    background: '#FFFFFF',
                    borderRadius: '100px',
                    padding: '2px',
                    fontSize: '10px',
                    border: '1px solid rgb(255, 255, 255)',
                    color: '#434243',
                }} onClick={this.props.onHandleExit ? this.props.onHandleExit : null}>X</button>
            </div>
        )
    }
}

const styles = {
    progressArr: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        flexWrap: 'row',
        position: 'absolute',
        width: '100%',
        padding: 10,
        background: 'rgba(25, 13, 26, 0.2)',
        borderRadius: '20px 20px 0 0',
        paddingTop: 7,
        paddingBottom: 5,
        alignSelf: 'center',
        alignItems: 'center',
        zIndex: 99,
        filter: 'drop-shadow(0 0 50px #000)'
    }
}

ProgressArray.propTypes = {
    length: PropTypes.array,
    progress: PropTypes.object,
    pause: PropTypes.bool,
    next: PropTypes.func,
    currentStory: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    defaultInterval: PropTypes.number,
    videoDuration: PropTypes.number,
    bufferAction: PropTypes.bool
}