import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { useEffect, useMemo } from "react";
import Routers from "./Routers";
import palette from "./theme/palette";
import typography from "./theme/typography";
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      console.log('beforeunload event triggered');

      return (event.returnValue =
        'Are you sure you want to exit?');
    };

    // window.addEventListener('beforeunload', handleTabClose);

    // return () => {
    //   window.removeEventListener('beforeunload', handleTabClose);
    // };
  }, []);
  useEffect(() => {
    handleGoogleAnalytics()
  })
  const handleGoogleAnalytics = () => {
      // ReactGA.initialize("GTM-54K2JD7");
  };
  const themeOptions = useMemo(
    () => ({
      shadows: [
        "none",
      
        ...Array(24).fill('none')
      ],
      palette,
      typography,
    }),
    []
  );
  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Routers />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
