import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonGroup from "../GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import { CompletedAcrchiveGoalDialog } from "../GoalComponent/GoalDialog";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import { useSelector } from "react-redux";
import useCustomNotification from "../../Hooks/useCustomNotification";
function CompletedGoalDialog({
  setIsCompletedArchiveOpen,
  isCompletedArchiveOpen,
  preSelectedState,
  goalName,
  completeDataHandler,
}) {
  const { ActiondataSlice, HabitDataSlice } = useSelector((state) => state);
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  
  return (
    <CompletedAcrchiveGoalDialog
      open={isCompletedArchiveOpen}
      onClose={() => {
        setIsCompletedArchiveOpen(false);
      }}
    >
      <Box sx={{ padding: "40px 22px", position: "relative" }}>
        
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
            color= "#190D1A"
          >
            You still have uncompleted{" "}
            {preSelectedState?.length !== 0 &&
          ActiondataSlice?.actionData?.length !== 0 &&
          HabitDataSlice?.habitData?.length !== 0
            ? "growth areas, actions and habits"
            : preSelectedState?.length !== 0 &&
              ActiondataSlice?.actionData?.length !== 0
            ? "growth areas and actions"
            : preSelectedState?.length !== 0 &&
              HabitDataSlice?.habitData?.length !== 0
            ? "growth areas and habits"
            : ActiondataSlice?.actionData?.length !== 0 &&
            HabitDataSlice?.habitData?.length !== 0 ?
            "actions and habits" : 
            ActiondataSlice?.actionData?.length !== 0 ? "actions" :
            HabitDataSlice?.habitData?.length !== 0 ? "habits" :
            "growth areas"
            }
            {" "}
            for {goalName}. Are you sure you want to mark it completed?
          </Typography>
        <Box>
          <ButtonGroup
            nextButtonText="Keep working"
            previousButtonText="Complete anyways"
            isPrevDisable={false}
            onClickNext={() => {
              setIsCompletedArchiveOpen(false);
            }}
            onClickPrevious={() => {
              completeDataHandler();
              setCustomNotification(true);
            }}
          />
        </Box>
        <img
          src={CloseSmalIcon}
          style={{ position: "absolute", top: "16px", right: "20px" }}
          onClick={() => {
            setIsCompletedArchiveOpen(false);
          }}
          alt=""
        />
      </Box>
      {customNotification && (
        <NotificationContentJSX
          message={"Goal completed sucessfully"}
          isThumbIcon={false}
          position="top"
        />
      )}
    </CompletedAcrchiveGoalDialog>
  );
}
export default CompletedGoalDialog;
