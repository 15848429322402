import { styled } from "@mui/system";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import AddNewGoal from "../../components/AddNewGoal/AddNewGoal";
import { useDispatch, useSelector } from "react-redux";
import { updateAddGoalCategory, updateAddGoalStep, updateGoalData } from "../../Store/Reducers/goalDataSlice";
import { createRef, useRef, useState } from "react";
import ButtonGroup from "../../components/GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import { GOALTYPE } from "../../Constant/GoalConstant";
const AddNewgoalModal = ({setCustomNotification }) => {
    const dispatch = useDispatch();
    const isAskOtherModalOpen = useSelector(
        (state) => state.goalData.isAskOtherModalOpen
    );
    const addGoalStep = useSelector(
        (state) => state.goalData.addGoalStep
    );
    const addGoalCtageory = useSelector(
        (state) => state.goalData.addGoalCtageory
    );
    let FieldEditor1 = useRef();

    const [step, setStep] = useState(1);
    const [isLastStep, setisLastStep] = useState(false);
    const [typeGoal, setTypeGoal] = useState(GOALTYPE.ALL_DIAMENSTIONGOAL);
    const [selectedDiamenstion, setselectedDiamenstion] = useState("")
    const [goalDetail, setGoalDetail] = useState({});
    const handleClose = () => {
        if(FieldEditor1.current && FieldEditor1.current.closeConforma) {
            FieldEditor1.current.closeConforma(true)
        }
    };
    const setisAddNewGoal = (val) => {
        dispatch(updateGoalData({
            isAddNewGoal: val
        }))
    }
    // const closeConformation = (val) => dispatch(updateGoalData({
    //     openConfirmationModal: val
    // }))
    console.log(FieldEditor1);
    const smallDeviceMatches = useMediaQuery("(max-width:500px)");
    const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};

        position: unset;
      }
    }
  `;
    return (
        <>
            <CustomAddNewGoalDialog
                open={isAskOtherModalOpen}
                onClose={handleClose}
                keepMounted={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AddNewGoal
                    handleClose={handleClose}
                    ref={FieldEditor1}
                    setisAddNewGoal={setisAddNewGoal}
                    step={step}
                    defaultStep ={addGoalStep}
                    setStep={setStep}
                    isLastStep={isLastStep}
                    setisLastStep={setisLastStep}
                    typeGoal={typeGoal}
                    setTypeGoal={setTypeGoal}
                    selectedDiamenstion={selectedDiamenstion}
                    setselectedDiamenstion={setselectedDiamenstion}
                    goalDetail={goalDetail}
                    setGoalDetail={setGoalDetail}
                    defaultSelectCtageory={addGoalCtageory}
                    setCustomNotification={setCustomNotification}
                />
            </CustomAddNewGoalDialog>
            
            
        </>
    )
}

export default AddNewgoalModal


export const ConfirmationClose = ({ closeConformation }) => {
    const smallDeviceMatches = useMediaQuery("(max-width:500px)");
    const CustomAddNewGoalDialog = styled(Dialog)`
      .MuiDialog-root: {
        z-index: 1301;
      }
      & .MuiDialog-container {
        & .MuiPaper-root {
          width: 100%;
          max-width: 640px;
          border-radius: ${smallDeviceMatches ? "20px" : "40px"};
          margin: ${smallDeviceMatches && "10px"};
  
          position: unset;
        }
      }
    `;
    const dispatch = useDispatch();

    const setIsAskOtherModalOpen = (val) => {
        dispatch(updateAddGoalStep(1))
        dispatch(updateAddGoalCategory({}))
        dispatch(updateGoalData({
            isAskOtherModalOpen: val
        }))
    }
    const conformCloseModal = () => {
        closeConformation(false)
        setIsAskOtherModalOpen(false);
    }
    return <CustomAddNewGoalDialog
        keepMounted={true}
        open={true}
        onClose={() => closeConformation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box sx={{ padding: "40px 22px", position: "relative" }}>
            <Typography variant="h6" sx={{ textAlign: "center" }} color=" #190D1A">
                Are you sure want to close ?
            </Typography>
            <Box>
                <ButtonGroup
                    nextButtonText="Yes"
                    previousButtonText="No"
                    isPrevDisable={false}
                    onClickNext={() => {
                        conformCloseModal();
                    }}
                    onClickPrevious={() => {
                        closeConformation(false)
                    }}
                />
            </Box>
            <img
                src={CloseSmalIcon}
                style={{ position: "absolute", top: "16px", right: "20px" }}
                onClick={() => {
                    closeConformation(false)
                }}
                alt=""
            />
        </Box>
    </CustomAddNewGoalDialog>
}