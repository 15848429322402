import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";
import clsx from "clsx";

import classes from "./GrowthAction.module.css";
import { getGrowthData } from "../../../services/auth";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
const GrowthAction = ({ x, monthObject, handleIsTrue, updateActionData }) => {
  const UK_TIME = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  const { getCalenderDataSlice } = useSelector((state) => state);
  return (
    <>
      {getCalenderDataSlice?.getActionData.map((action, index) => {
        const temp = new Set();
        const dateMatches = getCalenderDataSlice?.getCalenderData.filter(
          (item) => item.questionCategory === action.questionCategory
        );
        return dateMatches.map((item, dindex) => {
          return item.dates.map((dateRange, i) => {
            if (
              (moment.utc(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                moment
                  .utc(new Date(action.executionDate))
                  .format("DD-MMMM, yyyy") &&
                moment
                  .utc(new Date(action.executionDate))
                  .format("DD-MMMM, yyyy") === `${x}-${monthObject}` &&
                action.scheduled_type === "TIME_BASED") ||
              (action.executionDate === `${x}-${monthObject}` &&
                action.scheduled_type === "TIME_BASED")
            ) {
              if (temp.has(action._id)) {
                return null;
              }
              temp.add(action._id);
              return (
                item.questionCategory === action.questionCategory &&
                action._id && (
                  <Box
                    style={{
                      background: item.colour,
                      color: item.textColour,
                      borderRadius: "4px",
                    }}
                    key={index}
                    className={classes.action_data}
                    id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")}`}
                    onClick={() => {
                      updateActionData(action);
                    }}
                  >
                    <Box
                      style={{
                        width: "13px",
                        height: "13px",
                        border: `1px solid ${item.textColour}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleIsTrue(action);
                      }}
                    >
                      {action.calenderStatus === "COMPLETE" ? (
                        <DoneIcon
                          style={{
                            color: item.textColour,
                            width: "12px",
                            height: "12px",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                    <div className={clsx(classes.textAction, 'text-3lines')}>
                      <Typography
                        variant="p"
                        style={{
                          textDecoration:
                            action.calenderStatus === "COMPLETE" &&
                            "line-through",
                        }}
                        className="text-3lines"
                      >
                        {action?.actionName}
                      </Typography>
                    </div>
                    <AutorenewRoundedIcon
                      style={{
                        color: "transparent",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Box>
                )
              );
            } else {
              return null;
            }
          });
        });
      })}
    </>
  );
};

export default React.memo(GrowthAction);
