import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dimensions: [],
    subCategoryid: ""
}

export const dimensionsSlice = createSlice({
    name: 'dimensionsSlice',
    initialState,
    reducers: {
        updateDimensions: (state, action) => {
            state.dimensions = action.payload
        },
        updateSubCategoryId: (state, action) => {
            state.subCategoryid = action.payload
        },
    },
})
// Action creators are generated for each case reducer function
export const { updateDimensions, updateSubCategoryId } = dimensionsSlice.actions

export default dimensionsSlice.reducer