// ----------------------------------------------------------------------
import palette from "./palette";
export const pxToRem = (value: any) => {
  return `${value / 16}rem`;
};

export const responsiveFontSizes = (sm: any, md: any, lg: any) => {
  return {
    "@media (max-width:600px)": {
      fontSize: pxToRem(sm),
      // lineHeight: pxToRem(sm),
    },
    "@media (max-width:900px)": {
      fontSize: pxToRem(md),
      // lineHeight: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
      // lineHeight: pxToRem(lg),
    },
  };
};

const FONT_PRIMARY = "Inter";
const typography = {
  fontFamily: FONT_PRIMARY,
  button: {
    fontWeight: 400,
    lineHeight: 30 / 14,
    fontSize: pxToRem(20),
    textTransform: "inherit",
    ...responsiveFontSizes(14, 16, 20),
  },
  h1: {
    fontSize: pxToRem(36),
    lineHeight: pxToRem(43),
    fontWeight: 700,
    ...responsiveFontSizes(20, 26, 36),
  },
  h2: {
    // in used
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(48),
    lineHeight: "105%",
    ...responsiveFontSizes(28, 28, 48),
  },
  h3: {
    // in used
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(40),
    lineHeight: "115%",
    color: "#190D1A",
    ...responsiveFontSizes(28, 28, 40),
  },
  h4: {
    // in used
    fontSize: pxToRem(32),
    lineHeight: "105%",
    fontWeight: 600,
    color: "#190D1A",
    ...responsiveFontSizes(32, 32, 32),
  },
  
  h5: {
    // not in use
    fontSize: pxToRem(28),
    lineHeight: "120%",
    fontWeight: 600,
    color: "#190D1A",
    ...responsiveFontSizes(14, 18, 22),
  },
  h6: { // not in use
    fontSize: pxToRem(24),
    lineHeight: pxToRem(28.8),
    fontWeight: 600,
    color: palette.secondary.contrast,
    marginBottom: pxToRem(21),
    ...responsiveFontSizes(16, 20, 24),
  },
  subtitle1: {
    // in use
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: pxToRem(16),
    lineHeight: "100%",
    color: "#190D1A",
    // color: '#434243',
    ...responsiveFontSizes(16, 16, 16),
  },
  // body1: { // not in use
  //   fontSize: pxToRem(20),
  //   lineHeight: pxToRem(30),
  //   fontWeight: 400,
  //   color: palette.common.white,
  //   ...responsiveFontSizes(16, 18, 20),
  // },
  body2: {
    // in use
    fontSize: pxToRem(14),
    lineHeight: "145%",
    fontWeight: 400,
    fontStyle: "normal",
    color: "#434243",
    ...responsiveFontSizes(14, 14, 14),
  },
  caption: { // not in use
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 500,
    color: "#190D1A",
    ...responsiveFontSizes(12, 14, 16),
  },
  body3: {
    // in use
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: pxToRem(12),
    lineHeight: "100%",
    color: "#434243",
    ...responsiveFontSizes(12, 12, 12),
  },
  small: {
    // in use
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: pxToRem(10),
    lineHeight: "140%",
    ...responsiveFontSizes(10, 10, 10),
  },
  body4: {
    // in use
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: pxToRem(18),
    lineHeight: "140%",
    ...responsiveFontSizes(18, 18, 18),
  },
  subtitle2: {
    // in use
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: pxToRem(12),
    lineHeight: "100%",
    color: "#190D1A",
    // color: '#434243',
    ...responsiveFontSizes(12, 12, 12),
  },
};

export default typography;
