import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Divider,
    Grid,
    LinearProgress,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import parse from "html-react-parser";
import classes from "./ResultLayout.module.css";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Coin from "../../assets/Svg/Coin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import Sharedisable from "../../assets/Svg/Sharedisable.svg";
import XP from "../../assets/Svg/XP.svg";
import Share from "../../assets/Svg/Share.svg";
import { imageURLRender, randomXP } from "../../utils";
import { updateModalData, updateResultState, updateTestResultDimention } from "../../Store/Reducers/ResultAllDiamention";
import { useDispatch, useSelector } from "react-redux";
import { TestQuestionSlider } from "../../components/TestQuestionSlider/TestQuestionSlider";
import ResultCategoryCard, {
    LongMenu,
    ResultReflectiveCard,
    ResultReflectiveCardWhole,
} from "../../components/ResultCategoryCard/ResultCategoryCard";
import StepperModal from "./stepperModal";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
import { updateUserWalletCoins, userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import { AllDiamentionByTest, AllDiamentionByTestInvite, feedbackDiamentionCreate, getUserData, unlockGAAction } from "../../services/auth";
import useNotifications from "../../Hooks/useNotifications";
import { styled } from "@mui/system";
import { useEffect } from "react";
import CategorySubcategoryService from "../../services/CategorySubcategory";
import ConfirmationModal from "../TestResultCategoryList/Modal/ConfirmatioinModal";
import useCustomNotification from "../../Hooks/useCustomNotification";
import PersonalEmptyResult from "../TestResultCategoryList/PersonaEmptyResult";
const label = { inputProps: { "aria-label": "Checkbox" } };


const GridButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const ComparisonButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const GraphButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
        width: "50%",
    },
}));
const GridGraphComprison = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: "20px",
    },
}));
const ResultDimention = (props) => {
    const {
        state: {
            subCategory_id,
            category_id,
        },
        allDimentionByTest,
        allDimentionByTestInvite
    } = props
    const { Notification, updateNotificationState } = useNotifications(false);

    const { selectedInviteDataSlice, categoriesIdSlice, resultAllDiamention } = useSelector(
        (state) => state
    );

    const {
        modalStep = false,
        sendmodalStep = false,
        congatesStep = false,
        isAskOtherModalOpen,
        modalData = {},
        selectedGrowthArr = [],
        selectedActionArr = [],
        finalSelectedGrowthArea = [],
        tempSelectedGrowthArea,
        tempSelectedAction,
        tempGrowhtArea,
        tempActionArea,
        resultTestDimention,
        isAskOtherModalOpenSocial,
        isTestBookMark,
        isLoading,
        testtypeofResult: typeofResult,
        dimensionsBtnData,
        ResultAllDiamentionByInvite,
        reflectiveQuestion,
        subcategories,
        selectedSubCategories,
        testselectLayoutBtn: selectLayoutBtn,
        selectedUserInviteId,
        selectedinviteId,
        inviteName,
        testQuetionCatagoriesId: QuetionCatagoriesId,
        testfinalirrelevantdata: finalirrelevantdata,
        testirreleventRating: irreleventRating,
        selectedPersonal,
        generatingPaymentLoader = false,
        selectedItem = {},
        visibleConfirmation = false,
        spacificPersonInviteId = '',
        isCatagories = true,
        notificationMessage = "",
    } = resultAllDiamention


    const setIsLoading = val => dispatch(updateResultState({
        isLoading: val
    }))
    const setResultAllDiamention = val => dispatch(updateResultState({
        resultTestDimention: val
    }))
    const setResultAllDiamentionByInvite = val => dispatch(updateResultState({
        ResultAllDiamentionByInvite: val
    }))
    // useEffect(() => {
    //     setIsLoading(true);
    //     let uid;

    //     if (selectedUserInviteId) {
    //         uid = selectedUserInviteId;
    //     } else {
    //         const user = localStorage.getItem("userDetail");
    //         uid = user;
    //     }
    //     AllDiamentionByTest(uid, category_id, subCategory_id)
    //         .then((data) => {
    //             setResultAllDiamention(data?.allPercentage);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // }, [
    //     selectedUserInviteId,
    //     selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
    // ]);
    // useEffect(() => {
    //     setIsLoading(true);
    //     let uid;
    //     if (selectedUserInviteId) {
    //         uid = selectedUserInviteId;
    //     } else if (selectedInviteDataSlice?.isSelectedSocialUser) {
    //         uid = selectedInviteDataSlice?.selectedinviteData?.inviteUserUid;
    //     } else {
    //         const user = localStorage.getItem("userDetail");
    //         uid = user;
    //     }
    //     AllDiamentionByTestInvite(uid, category_id, subCategory_id)
    //         .then((data) => {
    //             setResultAllDiamentionByInvite(data?.allPercentage);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // }, [
    //     selectedUserInviteId,
    //     selectedPersonal,
    //     selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
    // ]);
    const dispatch = useDispatch();
    function renderGrowthFavorites(growthArea, growthFav, enable) {
        growthArea.filter(function (o1) {
            growthFav.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
                return item
            });
        });
    }

    function renderActionFavorites(actionArea, actionFavorites, enable) {
        actionArea.filter(function (o1) {
            actionFavorites.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
            });
        });
    }

    const setModalData = val => dispatch(updateResultState({
        modalData: val
    }))
    const setIsAskOtherModalOpen = val => dispatch(updateResultState({
        isAskOtherModalOpen: val
    }))
    const setQuetionCatagoriesId = val => dispatch(updateResultState({
        testQuetionCatagoriesId: val
    }))
    const modalOpenhandler = (data, questionCategory, questionCategoryId) => {
        setModalData({
            data,
            questionCategory,
            questionCategoryId,
        });
        const enable = data.length - 1
        const selectedScore = data?.length && data[data?.length - 1]?.uniqueId
        const growthAreaActionData = data?.map((item, index) => parse(item.descriptionDetails))
        const growthFavorites = data?.map((item) => item.growthFavorites)
        console.log("growthFavorites setting in store from click", growthFavorites);
        const actionFavorites = data?.map((item) => item.actionFavorites)
        dispatch(updateResultState({
            enable: enable,
            selectedScore: selectedScore,
            growthAreaActionData: growthAreaActionData,
            growthFavorites: growthFavorites,
            actionFavorites: actionFavorites,
        }))
        setIsAskOtherModalOpen(true);
        setQuetionCatagoriesId(questionCategoryId);
        const handleTestData = () => {
            let tempGrowthArea = [];
            let tempProgress = [];
            if (growthAreaActionData?.length) {
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "1"
                                ? item?.props?.children?.map((ele) => {
                                    tempGrowthArea.push({
                                        uniqueId: selectedScore,
                                        text: ele.props.children,
                                        isChecked: false,
                                    });
                                })
                                : null
                        );
                    }
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "3"
                                ? item?.props?.children?.map((ele) => {
                                    tempProgress.push({
                                        uniqueId: selectedScore,
                                        text: ele.props.children,
                                        isChecked: false,
                                        randomXPObject: randomXP(),
                                    });
                                })
                                : null
                        );
                    }
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "0" ? dispatch(updateResultState({
                                growthAreaQsn: item?.props?.children,
                            })) : ""
                        );
                    }
                    return data
                });
                growthAreaActionData?.map((data, index) => {
                    if (index === enable) {
                        data.map((item) =>
                            item.key === "2" ? dispatch(updateResultState({
                                progressQsn: item?.props?.children,
                            })) : ""
                        );
                    }
                    return data
                });
                renderGrowthFavorites(tempGrowthArea, [...growthFavorites], enable);
                renderActionFavorites(tempProgress, [...actionFavorites], enable);
                dispatch(updateResultState({
                    growthAreaData: tempGrowthArea,
                    progressData: tempProgress
                }))

                if (
                    data[enable]?.unlockgrowthandactions &&
                    data[enable]?.unlockgrowthandactions[0]
                ) {
                    dispatch(
                        updateResultState({
                            growthCount:
                                data[enable]?.unlockgrowthandactions[0].growth,
                            actionCount:
                                data[enable]?.unlockgrowthandactions[0].action,
                        })
                    );
                }
            }
        };
        handleTestData()
    };
    const setIsTestBookMark = val => dispatch(updateResultState({
        isTestBookMark: val
    }))
    const setselectedGrowthArr = val => dispatch(updateResultState({
        selectedGrowthArr: val
    }))
    const setselectedActionArr = val => dispatch(updateResultState({
        selectedActionArr: val
    }))
    const setSendmodalStep = val => dispatch(updateResultState({
        sendmodalStep: val
    }))
    const sendModalhandler = (irrr) => {
        setSendmodalStep(irrr);
    };
    const setFinalirrelevantdata = val => dispatch(updateResultState({
        testfinalirrelevantdata: val
    }))
    const setIrreleventRating = val => dispatch(updateResultState({
        testirreleventRating: val
    }))
    const mainStateUpdateHandler = (rattingIrrelevnt, irrelevantData) => {
        setFinalirrelevantdata(irrelevantData);
        setIrreleventRating(rattingIrrelevnt);
    };
    const inaccurateHandler = (ratingInaccurate, inaccurateData) => {
    };

    const feedBackDataHandler = (ratingInaccurate, inaccurateData) => {

        const user = localStorage.getItem("userDetail");
        let FinalFeedBackData = {
            uid: user,
            ratingIrrelevant: irreleventRating,
            inaccurate: inaccurateData,
            ratingInaccurate: ratingInaccurate,
            questionCategoryId: QuetionCatagoriesId,
            irrelevant: finalirrelevantdata,
        };

        feedbackDiamentionCreate(FinalFeedBackData)
            .then((response) => {
                console.log("response>>>>>>", response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const UnlockGAAction = (type, object) => {
        unlockGAAction(object).then(data => {
            if (type === "GROWTH") {
                dispatch(updateModalData({
                    growth: true,
                    idx: 0
                }))
                dispatch(updateUserWalletCoins())

                // const modelInfo = {...modalData};
                // console.log("modelInfo", modelInfo);
                // modelInfo.data[0].unlockgrowthandactions[0] = {
                //   ...modelInfo.data[0].unlockgrowthandactions[0],
                //   growth: modelInfo.data[0].unlockgrowthandactions[0].growth + 3
                // }
                // setModalData(modelInfo)
                // dispatch(updateResultState({
                //   actionCount: growthCount + 3
                // }))
            }
            if (type === "ACTION") {

                dispatch(updateModalData({
                    action: true,
                    idx: 0
                }))
                dispatch(updateUserWalletCoins())
                // dispatch(updateResultState({
                //   actionCount: actionCount + 3
                // }))
                // const modelInfo = {...modalData};
                // modelInfo.data[0].unlockgrowthandactions[0] = {
                //   ...modelInfo.data[0].unlockgrowthandactions[0],
                //   action: modelInfo.data[0].unlockgrowthandactions[0].action + 3
                // }
                // setModalData(modelInfo)
            }
        }).catch(err => {
            if (err.response.data.description) {
                updateNotificationState(err.response.data.description);
                setTimeout(() => {
                    updateNotificationState("", false);
                }, 2000);
                // setCustomNotification(err.response.data.description);
                // setCustomNotification(true);

            }
        }).finally(() => {

        })
    }
    const setGeneratingPaymentLoader = val => dispatch(updateResultState({
        generatingPaymentLoader: val
    }))
    const setSelectedItem = val => dispatch(updateResultState({
        selectedItem: val
    }))
    const setConfirmationVisible = val => dispatch(updateResultState({
        visibleConfirmation: val
    }))
    const updateUserData = () => {
        const user = localStorage.getItem("userDetail");
        if (user) {
            getUserData(user)
                .then((response) => {
                    console.log(response.data);
                    // navigate("/onbording");
                    dispatch(userProfileUpdate(response.data));
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }
    const setNotificationMessage = val => dispatch(updateResultState({
        notificationMessage: val
    }))
    const { NotificationContentJSX, customNotification, setCustomNotification } =
        useCustomNotification();
    const unlockCard = (e, questionCategoryId, makeAPI = false) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("unlock card clicked", makeAPI);
        if (makeAPI) {
            setGeneratingPaymentLoader(true)
            const uid = localStorage.getItem("userDetail");
            CategorySubcategoryService.UNLOCK_DIMANETION({
                uid,
                "questionCategoryId": questionCategoryId
            }).then((data) => {
                allDimentionByTest()
                allDimentionByTestInvite()
                // getDimentions(false)
                updateUserData()
                if (data) {
                    // navigate(`/test/${subCategory_id}`, {
                    //   state: {
                    //     subCategory,
                    //     description,
                    //     subCategory_desc,
                    //     category_desc,
                    //     category,
                    //     categoryId: category_id,
                    //     subCategoryId: subCategory_id,
                    //     imageUrl,
                    //   },
                    // });
                }
            })
                .catch((error) => {
                    if (error?.response?.data?.description) {
                        setNotificationMessage(error?.response?.data?.description)
                        // setCustomNotification(error?.response?.data?.description);
                        setCustomNotification(true);
                    }
                }).finally(() => {
                    setGeneratingPaymentLoader(false)
                    setSelectedItem({})
                    setConfirmationVisible(false)
                });
        } else {
            if(typeofResult === TYPEOFRESULTS.PERSONAL) {
                const foundAllSelectedcard = resultAllDiamention?.resultTestDimention.find(it => it.questionCategoryId === questionCategoryId)
                if (foundAllSelectedcard) {
                    setSelectedItem(foundAllSelectedcard)
                    setConfirmationVisible(true)
                }
            } else {
                // ResultAllDiamentionByInvite
                const foundAllSelectedcard = resultAllDiamention?.ResultAllDiamentionByInvite.find(it => it.questionCategoryId === questionCategoryId)
                if (foundAllSelectedcard) {
                    setSelectedItem(foundAllSelectedcard)
                    setConfirmationVisible(true)
                }
            }

        }


    }
    const setSelected = val => dispatch(updateResultState({
        testselectLayoutBtn: val
    }))
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    return <>
        <div className={classes.headerWrapper}>
            {inviteName !== "" &&
                typeofResult !== TYPEOFRESULTS.PERSONAL ? (
                <Typography
                    variant="h3"
                    sx={{
                        fontSize: "28px !important",
                    }}
                >
                    <span style={{ color: "#9747FF" }}>{inviteName}'</span>s
                    latest view
                </Typography>
            ) : (
                <Typography
                    variant="h3"
                    sx={{
                        fontSize: "28px !important",
                    }}
                >
                    Here’s how you did last time
                </Typography>
            )}
            {matches ? (
                <LongMenu
                    selectLayoutBtn={selectLayoutBtn}
                    setSelected={setSelected}
                />
            ) : (
                <GridGraphComprison>
                    <GridButton
                        variant={
                            selectLayoutBtn === SELECTLAYOUTBTN.GRID
                                ? "contained"
                                : null
                        }
                        onClick={() => {
                            setSelected(SELECTLAYOUTBTN.GRID);
                        }}
                    >
                        Grid
                    </GridButton>
                    <ComparisonButton
                        variant={
                            selectLayoutBtn === SELECTLAYOUTBTN.COMPRISON
                                ? "contained"
                                : null
                        }
                        onClick={() => {
                            setSelected(SELECTLAYOUTBTN.COMPRISON);
                        }}
                    >
                        Comparison
                    </ComparisonButton>
                    <GraphButton
                        variant={
                            selectLayoutBtn === SELECTLAYOUTBTN.GRAPH
                                ? "contained"
                                : null
                        }
                        onClick={() => {
                            setSelected(SELECTLAYOUTBTN.GRAPH);
                        }}
                    >
                        Graph
                    </GraphButton>
                </GridGraphComprison>
            )}
        </div>
        <Grid
            container
            spacing={2}
            className={classes.testListWrapper}
            sx={{ height: "100%" }}
        >
            {typeofResult === TYPEOFRESULTS.PERSONAL ? (
                resultAllDiamention?.resultTestDimention?.length === 0 ? (
                    <PersonalEmptyResult />
                ) : selectLayoutBtn === SELECTLAYOUTBTN.GRAPH ? (
                    <Grid
                        container
                        spacing={1.5}
                        className={classes.testListWrapper}
                    >
                        {resultAllDiamention?.resultTestDimention.map((item, i) => {
                            const { data, questionCategory, questionCategoryId } =
                                item;

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                    key={i}
                                    className={classes.personalityReslutItem}
                                    onClick={() =>
                                        item.unlock === true ? modalOpenhandler(
                                            data,
                                            questionCategory,
                                            questionCategoryId
                                        ) : null
                                    }
                                >
                                    <Typography
                                        className={classes.personalityResultText}
                                    >
                                        {questionCategory}
                                    </Typography>
                                    <TestQuestionSlider
                                        sx={{
                                            height: "14px",
                                            width: "14px",
                                            color: "",
                                        }}
                                        className={classes.TestQuestionSlider}
                                        defaultValue={30}
                                        // getAriaValueText={valuetext}
                                        valueLabelDisplay="off"
                                        step={50}
                                        marks
                                        min={0}
                                        max={100}
                                        value={data[data.length - 1].percent}
                                        // components={{ Input: SilderMarkComponent }}
                                        onChange={(e) => { }}
                                    />
                                    <Typography
                                        className={classes.personalityResultpercentage}
                                    >
                                        {data[data.length - 1].percent?.toFixed()}%
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    resultAllDiamention?.resultTestDimention?.map((item, index) => {
                        const { data, questionCategory, questionCategoryId } =
                            item;
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                key={index}
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    item.unlock === true ? modalOpenhandler(
                                        data,
                                        questionCategory,
                                        questionCategoryId
                                    ) : null
                                }
                            >
                                <ResultCategoryCard
                                    category={questionCategory}
                                    preDescription={
                                        data ? data[data.length - 1].description : ""
                                    }
                                    percentage={
                                        data ? data[data.length - 1].percent : ""
                                    }
                                    selectLayoutBtn={selectLayoutBtn}
                                    score={
                                        data ? data?.map((item) => item.score) : ""
                                    }
                                    unlockCard={unlockCard}
                                    imageUrl={data[data.length - 1].url}
                                    {...item}
                                ></ResultCategoryCard>
                            </Grid>
                        );
                    })
                )
            ) : ResultAllDiamentionByInvite?.length === 0 ? (
                <div
                    style={{
                        margin: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: "28px !important",
                        }}
                    >
                        No data found
                    </Typography>
                </div>
            ) : selectLayoutBtn === SELECTLAYOUTBTN.GRAPH ? (
                <Grid
                    container
                    spacing={1.5}
                    className={classes.testListWrapper}
                >
                    {ResultAllDiamentionByInvite?.map((item, i) => {
                        const { data, questionCategory, questionCategoryId } =
                            item;

                        return (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                key={i}
                                className={classes.personalityReslutItem}
                            >
                                <Typography
                                    className={classes.personalityResultText}
                                >
                                    {questionCategory}
                                </Typography>
                                <TestQuestionSlider
                                    sx={{ height: "14px", width: "14px", color: "" }}
                                    className={classes.TestQuestionSlider}
                                    defaultValue={30}
                                    // getAriaValueText={valuetext}
                                    valueLabelDisplay="off"
                                    step={50}
                                    marks
                                    min={0}
                                    max={100}
                                    value={data[data.length - 1].percent}
                                    onChange={(e) => { }}
                                />
                                <Typography
                                    className={classes.personalityResultpercentage}
                                >
                                    {data[data.length - 1].percent?.toFixed()}%
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                ResultAllDiamentionByInvite?.map((item) => {
                    const { data, questionCategory, questionCategoryId } =
                        item;
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                                item.unlock === true ? modalOpenhandler(
                                    data,
                                    questionCategory,
                                    questionCategoryId
                                ) : null
                            }
                        >
                            <ResultCategoryCard
                                category={questionCategory}
                                preDescription={data[data.length - 1].description}
                                percentage={data[data.length - 1].percent}
                                selectLayoutBtn={selectLayoutBtn}
                                score={item?.data?.map((item) => item.score)}
                                imageUrl={data[data.length - 1].url}
                                unlockCard={unlockCard}
                                {...item}
                            ></ResultCategoryCard>
                        </Grid>
                    );
                })
            )}
        </Grid>
        {customNotification && (
            <NotificationContentJSX
                message={notificationMessage}
                isThumbIcon={false}
                position="top"
            />
        )}
        {visibleConfirmation && <ConfirmationModal
            visibleConfirmation={visibleConfirmation}
            setConfirmationVisible={setConfirmationVisible}
            selectedItem={selectedItem}
            // getSymboleAndAmountForBuy={getSymboleAndAmountForBuy}
            // unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
            // setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
            // setTestModalVisible={setTestModalVisible}
            // setDiamondModalVisible={setDiamondModalVisible}
            createInvoice={unlockCard}
            generatingPaymentLoader={generatingPaymentLoader}
        />}
    </>
}

export default ResultDimention