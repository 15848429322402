import styled from "@emotion/styled";
import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIconBlack from "../../assets/Svg/CloseIconBlack.svg";
import StepOneAll from "../../pages/GoalSelectAllDiamension/StepOneAll";
import StepTwo from "../../pages/GoalSelectAllDiamension/StepTwo.jsx/StepTwo";
import StepThree from "../../pages/GoalSelectAllDiamension/StepThree/StepThree";
import StepFive from "../../pages/GoalSelectAllDiamension/StepFive/StepFive";
import StepFour from "../../pages/GoalSelectAllDiamension/StepFour/StepFour";
import { GOALTYPE } from "../../Constant/GoalConstant";
import FavouriteStepFour from "../../pages/Faverourite/StepFour/FavouriteStepFour";
import FaverouiteStepOne from "../../pages/Faverourite/FaverouiteStepOne/FaverouiteStepOne";
import { goalCreate } from "../../services/auth";
import GrowthDialogCom from "../../components/GrowthDialog/GrowthDialog";
import {
  CardContent,
  MainCard,
} from "../../components/GoalComponent/GoalDialog";
import HabitStepSix from "../../pages/GoalSelectAllDiamension/HabitStepSix/HabitStepSix";
import classes from "./CreateGoal.module.css";
function CreateGoal({ handleClose, setisAddNewGoal }) {
  const [step, setStep] = useState(1);
  const [isLastStep, setisLastStep] = useState(false);
  const [typeGoal, setTypeGoal] = useState(GOALTYPE.ALL_DIAMENSTIONGOAL);
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const [selectedDiamenstion, setselectedDiamenstion] = useState("");

  const allDiamensionGoal = {
    1: StepOneAll,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
    6: HabitStepSix,
  };
  const faverouiteDiamensionGoal = {
    1: FaverouiteStepOne,
    2: StepTwo,
    3: StepThree,
    4: FavouriteStepFour,
    5: StepFive,
    6: HabitStepSix,
  };
  useEffect(() => {
    let user = localStorage.getItem("userDetail");
    let filterPreSelectedGrowth = goalDetail?.preSelectedGrowth?.filter(
      (item) => item.text !== ""
    );
    let finalGoalDetail = {
      uid: user,
      questionCategoryId: goalDetail?.questionCategoryId,
      increase: goalDetail?.increase,
      progress: goalDetail?.progress,
      preSelectedGrowth: filterPreSelectedGrowth,
      habit: goalDetail?.habit || [],
      action: goalDetail?.action || [],
    };
    if (isLastStep) {
      goalCreate(finalGoalDetail)
        .then((response) => {
          console.log(response);
          setisAddNewGoal(true);
        })
        .catch((err) => {
          console.log(err);
        });

      onNext();
      handleClose();
    }
  }, [isLastStep]);
  const stepSixNextHandler = () => {
    setisLastStep(true);
  };
  const [goalDetail, setGoalDetail] = useState({});
  const getGoalDetail = async (key, value) => {
    setGoalDetail({
      ...goalDetail,
      [key]: value,
    });
  };
  const Step =
    typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL
      ? allDiamensionGoal[step]
      : faverouiteDiamensionGoal[step];

  const onNext = () => {
    if (typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL) {
      if (step < Object.keys(allDiamensionGoal).length) {
        setStep((prev) => prev + 1);
      }
    } else {
      if (step < Object.keys(faverouiteDiamensionGoal).length) {
        setStep((prev) => prev + 1);
      }
    }
  };
  const onPrevious = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };
  return (
    <MainCard
      sx={{
        minWidth: 250,
        borderRadius: "40px",
        marginInline: "auto",
        position: 'relative',
        padding: '40px',
        width: "640px",
      }}
    >
      <CardContent style={{
        // position: 'relative',
        padding: '0px'
      }}>
        <Step
          onNext={onNext}
          onPrevious={onPrevious}
          setTypeGoal={setTypeGoal}
          typeGoal={typeGoal}
          handleClose={handleClose}
          getGoalDetail={getGoalDetail}
          goalDetail={goalDetail}
          stepSixNextHandler={stepSixNextHandler}
          setselectedDiamenstion={setselectedDiamenstion}
          selectedDiamenstion={selectedDiamenstion}
        />
        <div className={classes.closebutton} onClick={handleClose}>
          <img src={CloseIconBlack} alt="" />
          {(step === 5 || step === 6) && (
            <GrowthDialogCom goalDetail={goalDetail} />
          )}
        </div>
      </CardContent>
    </MainCard>
  );
}
export default CreateGoal;
