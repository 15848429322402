import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { createRef, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import classes from "./invitemodal.module.css"
import WinningCel from "./../../../assets/Svg/WinningCel.svg"
const CelebrationModal = (props) => {
  const [value, setInputValue] = useState("");
  const textInput = createRef(null);
  const {
    visibleCelebrationmodal,
    openCloseCelebrationModal,
    openJournalItem,
    addUpdateJournalData,
    savingJournal,
  } = props;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const confettiRef = useRef(null);
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
  }, []);

  return (
    <Dialog
      open={visibleCelebrationmodal}
      onClose={() => {
        openCloseCelebrationModal(false);
      }}
      PaperProps={{
        style: {
          borderRadius: "20px",
          position: "unset",
          padding: "0",
          width: 'calc(100vw - 45%)',
        },
    }}
      sx={{
        "& .MuiPaper-root": {
          // width: "100%",
          

        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ position: 'relative' }} ref={confettiRef}>
        <div style={{
          display: "flex", position: 'absolute', justifyContent: "space-between", right: '20px',
          top: '5px',     cursor: 'pointer',
          zIndex: 1
          
        }} onClick={() => {
          openCloseCelebrationModal(false);
        }}>
          <div
            className={classes.closeIcon}
            
          >
            x
          </div>
        </div>
        <div className={classes.cel_title}>
          <Confetti width={width} height={height} opacity={0.4} />
          <div className={classes.cel_title_icon}>
            <img src={

              WinningCel} alt="" />
          </div>
        </div>
        <div className={classes.cel_info}>
          <Typography
            variant="caption"
            component="div"
            className={classes.cel_info_text}
          >
            Nice! You’ve reached your daily XP goal
          </Typography>
          <LoadingButton
            variant="contained"
            className={classes.cel_info_button}
            onClick={(e) => {
              openCloseCelebrationModal(false);
            }}
          >
            Keep Working
          </LoadingButton>
        </div>
      </div>

    </Dialog>
  );
};

export default CelebrationModal;
