import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fatuDiamension } from "../Growth";
import Engagement from "../../../assets/Svg/Engagement.svg";
import CheckboxEndIcon from "../../../assets/Svg/CheckboxEndIcon.svg";
import ExpandMore from "../../../assets/Svg/ExpandMore.svg";
import classes from "./FavouriteGoals.module.css";
import GrowthAreas from "../GrowthAreas";
import { CustomShedualButtonAddMore } from "../../GoalComponent/GoalButton";
const FavouriteGoals = ({
  setExpanded,
  expanded,
  setfullscreen,
  fullscreen,
}) => {
  const [questionCategoryid, setquestionCategoryId] = useState("");
  const handleChange =
    (panel, questionCategoryId, questionCategory) => (event, isExpanded) => {
      setquestionCategoryId(questionCategoryId);
      setExpanded(isExpanded ? panel : false);
    };
  return (
      <Grid
        container
        spacing={2}
        sx={{
          overflowY: "auto",
          // height: favDiamension.length > 2 ? "490px" : "auto",
        }}
      >
        {fatuDiamension.map((data, index) => {
          const {
            questionCategory,
            questionCategoryId,
            description,
            growthFavorites,
            actionFavorites,
          } = data;
          return (
            <Grid
              item
              xl={fullscreen ? 4 : 12}
              lg={fullscreen ? 4 : 12}
              md={fullscreen ? 4 : 12}
              sm={12}
              xs={12}
              key={index}
            >
              <Accordion
                sx={{
                  border: "1px solid #F3F3F3",
                  borderRadius: "16px !important",
                  padding: "2.5px 0px",
                }}
                className={questionCategoryid ? index : expanded}
                expanded={expanded === questionCategoryid}
                onChange={handleChange(
                  questionCategoryId,
                  questionCategoryId,
                  questionCategory
                )}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMore} />}
                  className={classes.AccordianSummery}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    "& .Mui-expanded": {
                      margin: "12px 0px !important",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Engagement} />
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: "12px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {questionCategory}
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginRight: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img src={CheckboxEndIcon} />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography variant="body2">{description}</Typography>
                    <div style={{ marginTop: "24px" }}>
                      <Typography
                        variant="body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        Liked growth areas
                      </Typography>
                      {growthFavorites.map((item, index) => {
                        // return <GrowthAreas item={item} index={index} />;
                      })}
                      <CustomShedualButtonAddMore>
                        Add more +
                      </CustomShedualButtonAddMore>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                      <Typography
                        variant="body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        Liked actions
                      </Typography>
                      {actionFavorites.map((item, index) => {
                        // return <GrowthAreas item={item} index={index} />;
                      })}
                      <CustomShedualButtonAddMore>
                        Add more +
                      </CustomShedualButtonAddMore>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
  );
};

export default FavouriteGoals;
