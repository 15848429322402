import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
import { updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import { CustomTypography, PersonalButton, PersonPublic, PublicButton } from "./createdComponents";
import classes from "./TestResultCategoryList.module.css";
import {
    updateisLearnMore,
    updateisSocialResultNavigate,
    updateSelectedQuestionCategories,
  } from "../../Store/Reducers/averageByInviteUser";
const ResultTitle = (props) => {
    const { resultAllDiamention, testCategoriesList, selectedInviteDataSlice, averageByInviteUserSlice } = useSelector(
        (state) => state
    );
    const {
        
        typeofResult = TYPEOFRESULTS.PERSONAL,
    } = resultAllDiamention
    const dispatch = useDispatch();
    const setTypeofResult = val => dispatch(updateResultState({
        typeofResult: val
      }))
    return (
        <div className={classes.dashbordTitleContainer}>
            <div className={classes.dashbordTitle}>
                <CustomTypography variant="h4">
                    {typeofResult === TYPEOFRESULTS.PERSONAL
                        ? !selectedInviteDataSlice?.isSelectedSocialUser
                            ? "All Personal Results"
                            : `Feedback from ${selectedInviteDataSlice?.selectedinviteData?.name}`
                        : "Social results"}
                </CustomTypography>
                {!selectedInviteDataSlice?.isSelectedSocialUser && (
                    <PersonPublic>
                        {/* {typeofResult === TYPEOFRESULTS.PERSONAL ? ( */}
                        <PersonalButton
                            variant={
                                typeofResult === TYPEOFRESULTS.PERSONAL ? "contained" : null
                            }
                            onClick={() => {
                                setTypeofResult(TYPEOFRESULTS.PERSONAL);
                                dispatch(updateisSocialResultNavigate(false));
                                dispatch(updateSelectedQuestionCategories(""));
                                dispatch(updateisLearnMore(false));
                            }}
                        >
                            Personal
                        </PersonalButton>
                        <PublicButton
                            variant={
                                typeofResult === TYPEOFRESULTS.SOCIAL ? "contained" : null
                            }
                            onClick={() => {
                                setTypeofResult(TYPEOFRESULTS.SOCIAL);
                            }}
                        >
                            Social
                        </PublicButton>
                    </PersonPublic>
                )}
            </div>
            {typeofResult === TYPEOFRESULTS.PERSONAL &&
                !selectedInviteDataSlice?.isSelectedSocialUser ? (
                <div className={classes.dashbordTitlebody}>
                    <Typography variant="p" sx={{ color: "#434243" }}>
                        The dimenstion overview shows all the results relevent for your
                        discovery.
                    </Typography>
                    <Typography variant="p" sx={{ color: "#434243" }}>
                        You can move between all dimenstions or test categories.
                    </Typography>
                </div>
            ) : (
                <div className={classes.dashbordTitlebody}>
                    <Typography variant="p" sx={{ color: "#434243" }}>
                        Explore what other people think about you and potential area for
                        growth.
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default ResultTitle