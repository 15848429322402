import { Button, Typography } from "@mui/material";
import classes from "./EmptyStateBox.module.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: `0px 0px 44px rgba(25, 13, 26, 0.05)`,
        fontSize: 11,
        borderRadius: '12px',
        padding: '16px',
        gap: '12px'
    },
    [`& .${tooltipClasses.tooltipArrow}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #F3F3F3'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        '&::before': {
            border: '1px solid #F3F3F3'

        }
    },
}));
const EmptyStateBox = () => {
    let navigate = useNavigate();
    return <div className={classes.mainEmptyBox}>
        <div className={classes.emptyContentBox}>
            <div className={classes.illuBox}>
                <div className={classes.rectangle}></div>

                <div className={classes.illuCheckboxContent}>
                    {new Array(6).fill(1).map((it, index) => {
                        return <div key={index} className={classes.frameWithCheckboxChecked}>
                            <div className={classes.emptycheckbox}>
                                <div className={classes.emptyinnercheckbox}>
                                    {index < 3 && (
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#9747FF',
                                            textAlign: 'center'
                                        }}>
                                            &#10003;
                                        </span>
                                    )}
                                </div>
                            </div>
                            {index < 3 ? (
                                <div className={classes.zeroindexbarlines}>

                                    {!index && (
                                        <>
                                            <div className={classes.zeroBar1}></div>
                                            <div className={classes.zeroBar2}></div>
                                        </>

                                    )}
                                    {index === 1 && (
                                        <>
                                            <div className={classes.oneBar1}></div>
                                            <div className={classes.oneBar2}></div>
                                            <div className={classes.oneBar3}></div>
                                        </>
                                    )}
                                    {index === 2 && (
                                        <>
                                            <div className={classes.twoBar1}></div>
                                            <div className={classes.twoBar2}></div>
                                            <div className={classes.twoBar3}></div>
                                            <div className={classes.twoBar4}></div>
                                        </>
                                    )}
                                </div>
                            ) : null}

                        </div>
                    })}
                </div>
            </div>
            <div className={classes.emptyTextBox}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#190D1A',
                    textAlign: 'center'
                }}>
                    You haven’t added any actions
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#434243',
                    textAlign: 'center'
                }}>
                    Start adding actions to keep the good work going
                </Typography>
            </div>
        </div>
        <div className={classes.emptyActionButton}>
            <Button variant="contained" sx={{
                background: '#9747FF',
                borderRadius: '100px',
                fontSize: '14px !important',
                padding: '12px 16px',
                height: '38px'
            }} onClick={() => {
                navigate("/goals");
            }}>Add Actions</Button>
            <LightTooltip title={
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#190D1A',
                }}>
                    Before adding an action, you would need to set a goal in Goals. As you start adding actions to goals and schedule them, they will appear on your home page
                </Typography>
            } arrow  placement="top"
            >
                <InfoOutlinedIcon sx={{
                    color: '#434243',
                    height: '16px'
                }} />
            </LightTooltip>

        </div>
    </div>
}

export default EmptyStateBox
