import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { StepTwo } from "../../pages/TestResultsPages/StepTwo/StepTwo";
import { StepThree } from "../../pages/TestResultsPages/StepThree/Stepthree";
import classes from "./PersonalTestResult2.module.css";
import SendStepThree from "../../pages/TestResultsPages/SendStepThree/SendStepThree";
import { v4 as uuidv4 } from "uuid";

export const PersonalTestResult2 = ({
  sendmodalStep,
  setSendmodalStep,
  setIsAskOtherModalOpen,
  setmodalStep,
  mainStateUpdateHandler,
  sendModalhandler,
  inaccurateHandler,
  feedBackDataHandler,
  setCongatesStep,
  modalData,
  handleClose
}) => {
  console.log("sendmodalStep", sendmodalStep);
  const [inputField, setInputField] = useState([uuidv4()]);
  const MainCard = styled(Box)(({ theme }) => ({
    background: "#fff",
    // background: "blue",
    width: "100%",
    maxWidth: "1400px",
    height: "inherit",
    // minHeight: "calc(100vh - 4rem)",
    position: "relative",
    // [theme.breakpoints.up("xl")]: {
    //   width: "1400px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "1190",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "890",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   width: "100%",
    // },
    // [theme.breakpoints.up("xs")]: {
    //   width: "100%",
    // },
  }));
  const CardContent = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "24px",
    position: "relative",
    // background: 'red',
    [theme.breakpoints.down("lg")]: {
      height: "auto",
      padding: "10px",
      overflowY: "auto",
    },
  }));

  return (
    <MainCard
      sx={{
        minWidth: 260,
        borderRadius: "32px",
        marginInline: "auto",
      }}
    >
      <CardContent>
        {sendmodalStep ? (
          <SendStepThree
            setSendmodalStep={setSendmodalStep}
            setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            setmodalStep={setmodalStep}
            inaccurateHandler={inaccurateHandler}
            feedBackDataHandler={feedBackDataHandler}
            setCongatesStep={setCongatesStep}
            modalData={modalData}

            handleClose={handleClose} 

          />
        ) : (
          <StepTwo
            // setSendmodalStep={setSendmodalStep}
            mainStateUpdateHandler={mainStateUpdateHandler}
            sendModalhandler={sendModalhandler}
            setInputField={setInputField}
            inputField={inputField}
            modalData={modalData}
            handleClose={handleClose}     
          />
        )}
      </CardContent>
    </MainCard>
  );
};
