import HighchartsReact from "highcharts-react-official";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";

import Highcharts from "highcharts";
import { useEffect, useState } from "react";
import { imageURLRender } from "../../utils";
export const PersonalChart = ({ PersonalDataArray, url }) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      chart: {
        type: "column",
        className: "personalChart",
        scrollablePlotArea:
          PersonalDataArray.length === 0
            ? {
                minWidth: 0,
                scrollPositionX: 0,
              }
            : {
                minWidth: 502,
                scrollPositionX: 1,
              },
      },
      title: {
        text: "",
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            useHTML: true,
            enabled: true,

            format:
              url === "/public/images/"
                ? '<div style="background: #F7F2FE;border-radius: 50%; width: 28px; height: 28px;display: flex;justify-content: center;align-items: center;"><div style="color:#9747FF;font-size:8px">' +
                  "{point.userName}" +
                  "</div></div>"
                : '<div style="background: #F7F2FE;border-radius: 50%; width: 28px; height: 28px;display: flex;justify-content: center;align-items: center;"><div style="color:#9747FF;font-size:8px">' +
                  '<img style= "border-radius: 50%; width: 28px; height: 28px;" src=' +
                  `${imageURLRender(url)}` +
                  "> " +
                  "</div></div>",
          },
        },
      },

      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
      },
      yAxis: {
        min: 0, // Lowest value to show on the yAxis
        max: 100,
        tickPixelInterval: 200,
        title: {
          text: "", // Title for the yAxis
        },
        barPercentage: 0.5,
        barThickness: 0,
      },

      xAxis:
        PersonalDataArray.length === 0
          ? {
              type: "category",
              labels: {
                overflow: "justify",
              },
              max: 0,
            }
          : PersonalDataArray.length <= 6
          ? {
              type: "category",
              labels: {
                overflow: "justify",
              },
            }
          : {
              type: "category",
              labels: {
                overflow: "justify",
              },
              min: 0,
              max: 6,
            },
      series: [
        {
          name: "Personal",
          colorByPoint: true,
          data: PersonalDataArray,
        },
      ],
      accessibility: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    });
  }, [PersonalDataArray]);
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
