import styled from "@emotion/styled";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { SubmitButtonWithBackGround } from "../../../../components/Button/Button";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { FEEDBACKTYPE } from "../../../../Constant/Invite";
import useCustomCheckBox from "../../../../Hooks/useCustomCheckBox";
import typography from "../../../../theme/typography";
import classes from "./StepOne.module.css";
const StepOne = (props) => {
  const { firstCheckBox, secondCheckBox, handleChange } = useCustomCheckBox(
    FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION,
    FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS,
    props?.inviteData?.feedbackType
  );

  // const [first, setfirst] = useState(second)
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;
  const updateMainState = () => {
    if (firstCheckBox) {
      props.updateInviteData(
        "feedbackType",
        FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION
      );
    } else {
      props.updateInviteData(
        "feedbackType",
        FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS
      );
    }
    props.onNext();
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className={classes.stepOneWrapper}>
      <ProgressBar progress={props.progress} />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", mt: "32px" }}
        >
          Select feedback type
        </Typography>
        <div className="option">
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={firstCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Based on average population" />}
              onChange={() => {
                handleChange(FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION);
              }}
              checkedIcon={
                <CheckBoxInputChecked placeholder="Based on average population" />
              }
            />
          </Box>
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={secondCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Based on personal standards" />}
              onChange={() => {
                handleChange(FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS);
              }}
              checkedIcon={
                <CheckBoxInputChecked placeholder="Based on personal standards" />
              }
            />
          </Box>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
        {/* <ButtonGroup {...props} updateMainState={updateMainState} isNextDisable={!firstCheckBox && !secondCheckBox} isPreviousBtnDisable={true} /> */}
        <SubmitButtonWithBackGround
          buttonText="Next"
          onClick={updateMainState}
          isDisabled={!firstCheckBox && !secondCheckBox}
        />
      </div>
    </div>
  );
};

export default StepOne;
