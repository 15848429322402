import { Tune } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import classes from "./settingNavigation.module.css";
import RightArrowVector from "../../../assets/Svg/RightArrowVector.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetStore } from "../../../Store/Reducers/onbordingSlice";
import { resetGrowthStore } from "../../../Store/Reducers/goalGrowthCalendar";
import { resetUsrStore } from "../../../Store/Reducers/userProfileSlice";
import { resetGrowthCalendarData } from "../../../Store/Reducers/getCalendarData";
import { storeTestCategoriesList } from "../../../Store/Reducers/testCategoriesList";
import { resetHeaderState } from "../../../Store/Reducers/headerData";
import { resetGoalState } from "../../../Store/Reducers/goalDataSlice";
import { resetDashboardState } from "../../../Store/Reducers/dashboard";
import { deleteUserAccount } from "../../../services/auth";
import { styled } from "@mui/system";
import CloseButton from "../../CloseButton";
const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 520px;
        border-radius: 24px;
        position: unset;
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        position: relative !important;
      }
    }
  `;
export const SettingNavigationBar = () => {
  const settingMenuItem = [
    "Profile",
    "Password",
    // "Membership",
    "Billing",
    "Feedback",
    "Notifications",
    "Legal",
    "Delete Account"
  ];
  const [details, setDetails] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState("Profile");
  const { userProfile: { userData = {} } } = useSelector(
    (state) => state
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (location.pathname.includes("password")) {
      setValue("Password");
    } else if (location.pathname.includes("membership")) {
      setValue("Membership");
    } else if (location.pathname.includes("feedback")) {
      setValue("Feedback");
    } else if (location.pathname.includes("billing")) {
      setValue("Billing");
    } else if (location.pathname.includes("legal")) {
      setValue("Legal");
    } else if (location.pathname.includes("notifications")) {
      setValue("Notifications");
    }
  });
  const settingNavigationHandler = (e) => {
    console.log();
    if (e.currentTarget.textContent === "Profile") {
      navigate("/settings");
    } else if (e.currentTarget.textContent === "Password") {
      navigate("/settings/password");
      // console.log("hello");
    } else if (e.currentTarget.textContent === "Membership") {
      navigate("/settings/membership");
    } else if (e.currentTarget.textContent === "Billing") {
      navigate("/settings/billing");
    } else if (e.currentTarget.textContent === "Feedback") {
      navigate("/settings/feedback");
    } else if (e.currentTarget.textContent === "Notifications") {
      navigate("/settings/notifications");
    } else if (e.currentTarget.textContent === "Legal") {
      navigate("/settings/legal");
    } else if (e.currentTarget.textContent === "Delete Account") {
      console.log("Delete account clicked")
      setOpenModal(!openModal)
    }
  };

  const deleteAccountAndLogout = () => {
    const user = localStorage.getItem("userDetail");
    deleteUserAccount({
      uid: user,
      user_email: userData.user_email
    }).then(() => {
      localStorage.clear();
      dispatch(resetStore())
      dispatch(resetGrowthStore())
      dispatch(resetUsrStore())
      dispatch(resetGrowthCalendarData())
      dispatch(storeTestCategoriesList([]));
      dispatch(resetHeaderState())
      dispatch(resetGoalState())
      dispatch(resetDashboardState())
      navigate("/")
    })

  }

  const setNavbarDetails = (e) => {
    console.log(e);
    // alert("hello")
    setDetails(details === settingMenuItem);
  };

  return (
    <>
      <Grid container gap={1} className={classes.main_div}>
        {matches && (
          <Typography className={classes.header} variant="h4">
            Setting
          </Typography>
        )}
        {settingMenuItem?.map((item) => {
          return (
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              key={item}
              sx={{
                background: !matches && value == item ? "#F7F2FE" : "",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => setNavbarDetails(item)}
            >
              <Typography
                onClick={(e) => settingNavigationHandler(e)}
                sx={{
                  marginLeft: "24px",
                  color: !matches && value == item ? "#9747FF" : item === "Delete Account" ? "red" : "",
                  cursor: "pointer",
                }}
                variant="subtitle1"
              >
                {item}
              </Typography>
              {matches && (
                <div style={{ paddingRight: "15px" }}>
                  <img src={RightArrowVector} />
                </div>
              )}
            </Grid>
          );
        })}
      </Grid>
      <CustomDialog
        open={openModal}
        keepMounted
        onClose={() => setOpenModal(!openModal)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '4px',
          alignSelf: 'stretch',
        }}>
          <Typography sx={{ color: '#190D1A', fontSize: '1.5rem', fontWeight: 600, textAlign: 'center'}}>Are you sure you want to delete your account</Typography>
          <Typography sx={{ color: '#B4B0B4', fontSize: '1rem', fontWeight: 400, textAlign: 'center'}}>This action cannot be undone, you will lose all your data</Typography>
        </div>
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '12px',
          alignSelf: 'stretch',
        }}>
          <Button sx={{
            display: 'flex',
            padding: '12px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            flex: '1 0 0',
            borderRadius: '6px',
            background: '#EF8B6E',
            color: '#FFF',
            "&:hover": { backgroundColor: "#EF8B6E" } 
          }} onClick={deleteAccountAndLogout}> <Typography sx={{ color: '#FFF', fontSize: '0.875rem', fontWeight: 400, textAlign: 'center'}}>Delete</Typography></Button>
          <Button sx={{
            display: 'flex',
            padding: '12px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            flex: '1 0 0',
            borderRadius: '6px',
            background: '#9747FF',
            color: '#FFF',
            "&:hover": { backgroundColor: "#9747FF" } 
          }} onClick={() => setOpenModal(!openModal)} ><Typography sx={{ color: '#FFF', fontSize: '0.875rem', fontWeight: 400, textAlign: 'center'}}>Keep account</Typography></Button>
        </Box>
        <div
          className={classes.closeIcon}
          onClick={() => {
            setOpenModal(!openModal)
          }}
        >
          <CloseButton />
        </div>
      </CustomDialog>
    </>
  );
};
