import { RightSideContentWrapper } from "./createdComponents";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
import ResultTitle from "./resultTitle";

const TestCategiryListnew = (props) => {
    const { resultAllDiamention, testCategoriesList, selectedInviteDataSlice, averageByInviteUserSlice } = useSelector(
        (state) => state
    );
    const { userData } = useSelector((state) => state.userProfile);
    const {
        generatingPaymentLoader = false,
        selectedItem = {},
        visibleConfirmation = false,
        selectLayoutBtn = SELECTLAYOUTBTN.GRID,
        typeofResult = TYPEOFRESULTS.PERSONAL,
        modalStep = false,
        sendmodalStep = false,
        congatesStep = false,
        isAskOtherModalOpen,
        modalData = {},
        spacificPersonInviteId = '',
        QuetionCatagoriesId = "",
        finalirrelevantdata = [],
        irreleventRating = "",
        selectedGrowthArr = [],
        selectedActionArr = [],
        finalSelectedGrowthArea = [],
        isHandleCloseCall = false,
        isCatagories = true,
        notificationMessage = "",
        tempSelectedGrowthArea,
        tempSelectedAction,
        tempGrowhtArea,
        tempActionArea,
    } = resultAllDiamention
    console.log("isCatagories", isCatagories);
    return (
        <RightSideContentWrapper>
            <Header />
            {!isCatagories && (<ResultTitle />)}
        </RightSideContentWrapper>
    )
}

export default TestCategiryListnew