import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Typography,
    Grid,
    Snackbar,
    styled,
    LinearProgress,
    linearProgressClasses,
} from "@mui/material";
import React, { Component, memo, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { RightSideContentWrapper } from "../Store/store.styles";
import classes from "./Dashboard.module.css";
import clsx from "clsx";
import Ludus from "../../assets/Svg/Ludus.svg";
import { Stack } from "@mui/system";
import XP from "../../assets/Svg/XP.svg";
import StreakIcon from "./../../assets/Svg/StreakIcon.svg"
import ShareIcon from "./../../assets/Svg/ShareIcon.svg"
import Stories from '../../components/Stories';
import Success16px from "./../../assets/Svg/success-circle-16px.svg"
import Story1 from "./../../assets/images/Story1.jpeg"
import Story2 from "./../../assets/images/Story2.jpeg"
import Story3 from "./../../assets/images/Story3.jpeg"
import EarlyAccess from "./../../assets/Svg/EarlyAccess1.svg"
// import {ReactComponent as StarHome} from "./../../assets/Svg/StarHome.svg"
import StarHome from "./../../assets/Svg/StarHome.svg"
// import StarHome from "./../../assets/Svg/StarHome.png"
import Ellipse11 from "./../../assets/Svg/Ellipse11.svg"
import Ellipse12 from "./../../assets/Svg/Ellipse12.svg"
import IconLevel from "./../../assets/Svg/IconLevel.svg"
import RectangleHome from "./../../assets/Svg/RectangleHome.svg"
import Vector16 from "./../../assets/Svg/Vector16.svg"
import Vector21 from "./../../assets/Svg/Vector21.svg"
import WinningCel from "./../../assets/Svg/WinningCel.svg"
import EditIcon from "./../../assets/Svg/Edit-16px.svg"
import QuoteIcon from "./../../assets/Svg/quoteIcon.svg"
import moment from "moment";
import DashboardService from "../../services/Dashboard";
import JournalEntryModal from "./Modal/JournalEntryModal";
import { updateHabitActionCalender } from "../../services/auth";
import { FindAndGetXPLevel, imageURLRender } from "../../utils";
import Confetti from "react-confetti";
import InviteReferModal from "./Modal/InviteModal";
import CelebrationModal from "./Modal/CelebrationModal";
import EditGoalModal from "./Modal/EditGoalModal";
import { openCloseCelebrationModal, openCloseEditGoalModal, openCloseInviteModal, openCloseJournalModal, setDahData, updateDashButtonLoading, updateDashGoalXP, updateDashStartEnd, updateScheduleAtIndex, updateSetShowStories, updateSetShowYoutube } from "../../Store/Reducers/dashboard";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import EmptyStateBox from "./DashboardEmptyBox";
import ScheduleDataContent from "./scheduleData";

const label = { inputProps: { "aria-label": "Checkbox" } };
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 50,
    width: '100%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#F7F2FE',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 50,
        backgroundColor: '#9747FF',
    },
}));
const DashboardDataContent = () => {
    const dispatch = useDispatch()
    const { currentMonth, selectedDate, startDay, endDay } = useSelector(state => state.dashboardDataSlice)

    useEffect(() => {
        if (currentMonth.length === 0) {
            dispatch(setDahData({
                startDay: moment().startOf("month").format(),
                endDay: moment().endOf("month").format(),
            }))
            trackingEvent();
        }
        if (["Goals", "Growth calender"].includes(localStorage.getItem("lastSelectedKey"))) {
            callToGetData(selectedDate, selectedDate);
            localStorage.setItem("lastSelectedKey", "Home")
        }
    }, [])

    const callToGetData = (startDate, endDate) => {
        const user = localStorage.getItem("userDetail");
        DashboardService.LIST_DATA({
            uid: user,
            from: moment(startDate).format("MM-DD-YYYY"),
            to: moment(endDate).format("MM-DD-YYYY"),
        })
            .then((res) => {
                console.log("res.data", res);
                dispatch(setDahData({
                    scheduleData: res.data,
                    quote: res?.quote[0] || {},
                    completedXP: res?.completedXP,
                    goalXp: res?.goalXp,
                    userLevelDetails: res?.userLevelDetails,
                    dailyGoalLevel: res?.dailyGoalLevel
                }))
                // setTimeout(() => {
                //   this.executeScroll();
                // }, 500);

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => { });
    };


    const getDatesArr = (startDay, endDay) => {
        const day = moment(startDay)?.clone().subtract(1, "day");

        const tempCalendar = [];

        let startDayTemp = moment(startDay)?.clone();
        let endDayTemp = moment(startDay)?.clone();

        while (day?.isBefore(moment(endDay), "day")) {
            let daysInMonth = startDayTemp
                .clone()
                // .add(3, 'months')
                .endOf("month")
                .diff(endDayTemp.clone(), "days");

            tempCalendar.push(
                Array(daysInMonth + 1)
                    .fill(0)
                    .map(() => {
                        return day.add(1, "day").clone().format();
                    })
            );

            startDayTemp = day.clone().add(1, "day");
            endDayTemp = moment(endDay).clone();
        }
        return tempCalendar
    }
    const trackingEvent = (callToGet = true) => {
        console.log("startDay, endDay", startDay, endDay);
        const tempCalendar = getDatesArr(startDay, endDay)
        console.log("tempCalendar", tempCalendar);
        dispatch(setDahData({
            currentMonth: tempCalendar[0],
        }));
        console.log("callToGet", callToGet);
        if (callToGet) {
            callToGetData(selectedDate, selectedDate);
            setTimeout(() => {
                executeScroll();
            }, 1000);
        }

        // setCalendar(tempCalendar);
    };
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice)
    const { userData } = useSelector(state => state.userProfile)
    const {
        openNotification,
        notificationMessage, } = dashboardDataSlice;
    useEffect(() => {
        if (dashboardDataSlice.currentMonth.length === 0) {
            dispatch(setDahData({
                startDay: moment().startOf("month").format(),
                endDay: moment().endOf("month").format(),
            }))
            trackingEvent();
        }
        if (["Goals", "Growth calender"].includes(localStorage.getItem("lastSelectedKey"))) {
            callToGetData(dashboardDataSlice.selectedDate, dashboardDataSlice.selectedDate);
            localStorage.setItem("lastSelectedKey", "Home")
        }
    })


    const openCloseJournalModal = (visible, itm = {}) => {
        dispatch(setDahData({
            openJournalModal: visible,
            openJournalItem: itm,
        }))
    };
    const openCloseCelebrationModal = (visible, itm = {}) => {
        dispatch(setDahData({
            visibleCelebrationmodal: visible,
        }))
    };
    const executeScroll = () => {
        // this.myRef.current && this.myRef.current.scrollIntoView({ behavior: 'smooth' })
        const violation = document.getElementById("row-id-date");
        // console.log("violation", violation);
        // if (violation) {
        //     violation.scrollIntoView({ behavior: 'smooth' });
        // }
        // window.scrollTo({
        //   top: violation.offsetTop,
        //   behavior: "smooth"
        // });

    };


    const addUpdateJournalData = (value, type, id) => {
        dispatch(setDahData({
            savingJournal: true,
        }));
        const user = localStorage.getItem("userDetail");
        DashboardService.UPDATE_JOURNAL({
            uid: user,
            id: id,
            type: type,
            journal: value,
        })
            .then((res) => {
                callToGetData(dashboardDataSlice.selectedDate, dashboardDataSlice.selectedDate);
                openCloseJournalModal(false);
                console.log("res", res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setDahData({
                    savingJournal: false,
                }));
            });
    };


    const sendInvite = (values) => {
        dispatch(setDahData({
            loadingButton: true,
        }))
        DashboardService.inviteRefereal(values).then((res) => {
            console.log("res", res);
            dispatch(openCloseInviteModal(false))
            dispatch(setDahData({
                openNotification: true,
                notificationMessage: res?.description
            }))

            setTimeout(() => {

                dispatch(setDahData({
                    openNotification: false,
                    notificationMessage: ''

                }))
            }, 2000);
        }).catch(err => {
            console.log("err")
        }).finally(() => {
            dispatch(setDahData({
                loadingButton: false
            }))

        })
    }
    const openCloseModal = (val) => dispatch(openCloseEditGoalModal(val))
    const openCloseInvModal = (val) => dispatch(openCloseInviteModal(val))
    const saveUpdateDailyGoal = (selectedGoalLevel) => {
        console.log("selectedGoalLevel", selectedGoalLevel)
        const foundGoalLevel = dashboardDataSlice.dailyGoalLevel.find(it => it.points === selectedGoalLevel)
        if (foundGoalLevel) {
            dispatch(updateDashButtonLoading(true))

            const user = localStorage.getItem("userDetail");
            DashboardService.UPDATE_DAILY_GOAL({
                uid: user,
                dailyGoal: foundGoalLevel.level
            })
                .then((res) => {
                    dispatch(updateDashGoalXP(selectedGoalLevel))

                }).catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    dispatch(updateDashButtonLoading(false))
                    dispatch(openCloseEditGoalModal(false))
                });
        } else {
            dispatch(openCloseEditGoalModal(false))
        }
    }
    return (
        <>

            <Grid
                container
                sx={{ overflowY: 'auto' }}
                // spacing={{ xs: 2, md: 2 }}
                // columns={{ xs: 4, sm: 8, md: 12 }}
                className={classes.mainLayout}
                rowSpacing="12px"
                columnSpacing="12px"
            >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <div>
                        <Typography variant="h4" component="div">
                            Hello, {userData?.name}
                        </Typography>
                        {(
                            <div className={classes.headerImageDiv}>
                                {(window.x_version ? [1] : [1,2]).map((it, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            className={clsx(
                                                classes.headerImageBox,
                                                index !== 0 && classes.headerImageMarginRight
                                            )}
                                            style={{
                                                justifyContent: 'center'
                                            }}
                                            onClick={() => {
                                                if (index === 1) {
                                                    dispatch(updateSetShowStories(true))
                                                } else {
                                                    dispatch(updateSetShowYoutube(true))
                                                }
                                            }}
                                        >
                                            <Typography sx={{
                                                fontSize: '8px',
                                                fontWeight: 500,
                                                color: '#FFFFFF',
                                                textAlign: 'center'
                                            }}>
                                                {
                                                    index === 1 ? <>
                                                        Growth Calendar: <br />5 benefits to get
                                                    </> : <>
                                                    Watch our introduction video
                                                    </>
                                                }
                                            </Typography>
                                        </Box>
                                    );
                                })}
                                <div className={classes.blurImage}></div>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} display="flex" alignItems={"flex-start"} flexDirection="column" gap={3} zIndex={1}>
                    <LevelOfUser />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <ScheduleDataComponent callToGetData={callToGetData} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <InviteUser />
                </Grid>
            </Grid>
            <JournalEntryModal
                openCloseJournalModal={openCloseJournalModal}
                addUpdateJournalData={addUpdateJournalData}
                {...dashboardDataSlice}
            />
            <CelebrationModal
                openCloseCelebrationModal={openCloseCelebrationModal}
                {...dashboardDataSlice}
            />
            <EditGoalModal
                openCloseEditGoalModal={openCloseModal}
                saveUpdateDailyGoal={saveUpdateDailyGoal}
                {...dashboardDataSlice}
            />
            {dashboardDataSlice.openInviteModal ? (
                <InviteReferModal
                    {...dashboardDataSlice}
                    openCloseInviteModal={openCloseInvModal}
                    sendInvite={sendInvite}
                />
            ) : null}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        background: '#fff',
                        borderRadius: '12px',
                        borderLeft: '4px solid #86E784',
                        '& .MuiSnackbarContent-message': {
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '140%',
                            /* or 22px */

                            letterSpacing: '-0.01em',

                            /* Main/Black */

                            color: '#190D1A',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '10px',
                        }
                    }
                }}
                open={openNotification}
                className={classes.linktocopy}
                message={<>
                    <img src={Success16px} alt="" />
                    <span>
                        {notificationMessage}
                    </span>
                </>}
                // action={action}
                variant="success"
                autoHideDuration={2000}
            />



        </>
    );


}

export default memo(DashboardDataContent);

// render() {
//   return (
//     <>
//       <RightSideContentWrapper>
//         <Header />
//         <DashboardDataContent />
//       </RightSideContentWrapper>

//     </>
//   );
// }

const InviteUser = () => {
    const dispatch = useDispatch()
    return (
        <div className={classes.premiumCTA} style={{
            marginTop: '50px'
        }}>
            <div className={classes.premiumCTARectangle}>
                <div className={classes.premiumCTAOuter} style={{
                    // backgroundImage: `url(${EarlyAccess})`
                }}>
                    {/* <Confetti width={200} height={400} /> */}
                    {/* <StarHome /> */}
                    <img src={StarHome} alt="" style={{
                        position: 'absolute',
                        width: '23.59px',
                        height: '23.59px',
                        left: '22.78px',
                        top: '21.95px',
                    }} />
                    <img src={StarHome} alt="" style={{
                        position: 'absolute',
                        width: '22.51px',
                        height: '22.51px',
                        left: '34.24px',
                        top: '113.7px',
                    }} />
                    <img src={StarHome} alt="" style={{
                        position: 'absolute',
                        width: '22.51px',
                        height: '22.51px',
                        left: '196.24px',
                        top: '19.02px',
                    }} />
                    <img src={StarHome} alt="" style={{
                        position: 'absolute',
                        width: '23.59px',
                        height: '23.59px',
                        left: '266.7px',
                        top: '119.52px',

                        transform: 'matrix(1, 0, 0, 1, 0, 0)',
                    }} />
                    <img src={Ellipse11} alt="" style={{
                        position: 'absolute',
                        width: '8px',
                        height: '8px',
                        left: '229.88px',
                        top: '58.18px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'matrix(-0.39, -0.92, 0.92, -0.39, 0, 0)',

                    }} />
                    <img src={Ellipse12
                    } alt="" style={{
                        position: 'absolute',
                        width: '6px',
                        height: '6px',
                        left: '256.98px',
                        top: '118.2px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'matrix(-0.39, -0.92, 0.92, -0.39, 0, 0)',

                    }} />
                    <img src={
                        RectangleHome
                    } alt="" style={{
                        position: 'absolute',
                        width: '5px',
                        height: '20.49px',
                        left: '103.31px',
                        top: '17.39px',
                        opacity: '0.92',
                        /* Main / Orange */

                        border: '1.12539px solid #EF8B6E',
                        background: '#EF8B6E',
                        transform: 'matrix(0.37, -0.93, 0.93, 0.37, 0, 0)',
                    }} />
                    <img src={
                        Vector16
                    } alt="" style={{
                        position: 'absolute',
                        width: '11.21px',
                        height: '16.97px',
                        left: '272.24px',
                        top: '7.91px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'matrix(2.73, -0.32, 1.32, 1.73, 0, 0)',

                    }} />
                    <img src={
                        Vector21
                    } alt="" style={{
                        position: 'absolute',
                        width: '7.54px',
                        height: '11.41px',
                        left: '12.74px',
                        top: '65.61px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'matrix(0.47, -0.88, 0.88, 0.47, 0, 0)',

                    }} />
                    <img src={

                        WinningCel} alt="" style={{
                            position: 'absolute',
                            width: '24px',
                            height: '24px',
                            left: '62.99px',
                            top: '47.72px',

                            opacity: '1',
                            /* Main/Purple */

                            // border: '3.37617px solid #9747FF',
                            transform: 'matrix(0.38, -0.93, 0.93, 0.38, 0, 0)',

                        }} />
                    <img src={

                        WinningCel} alt="" style={{
                            position: 'absolute',
                            width: '38px',
                            height: '38px',
                            left: '257.07px',
                            top: '23.09px',

                            opacity: '1',
                            /* Main/Purple */

                            // border: '3.37617px solid #9747FF',
                            transform: 'rotate(-1.42deg)',

                        }} />
                    <Typography
                        variant="caption"
                        component="div"
                        className={classes.premiumText}
                    >
                        Early Access
                    </Typography>
                    <Typography
                        variant="caption"
                        component="div"
                        className={classes.premiumScientific}
                    >
                        Scientific Self-Discovery
                    </Typography>
                    {/* <Typography
        variant="caption"
        component="div"
        className={classes.premium30DayFree}
      >
        30-day free pass
      </Typography> */}
                </div>
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.premiumGive30DayFree}
                >
                    Get your friends onboard
                </Typography>
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.premiumRectDesc}
                >
                    Drive your friend towards self-discovery with our personality tests, plans and daily actions by giving them Early Access
                </Typography>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '40px',
                }}>
                    <Button
                        className={classes.premiumButton}
                        fullWidth
                        onClick={() => dispatch(openCloseInviteModal(true))}
                    //sx={{ mt: "24px", mb: "57px" }}
                    >
                        Invite a Friend
                    </Button>
                </div>

            </div>
        </div>
    )
}


const LevelOfUser = () => {
    const dispatch = useDispatch()
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice)
    const { userData } = useSelector(state => state.userProfile)
    const {
        completedXP,
        goalXp,
        userLevelDetails = {} } = dashboardDataSlice;
    let percentValue = 0;
    if (userLevelDetails?.start > -1 && userLevelDetails?.end > -1) {
        const difference = Number(userLevelDetails?.end) - Number(userLevelDetails?.start)
        let endValue = userData?.xp;
        if (endValue > difference) {
            endValue = userData?.xp - difference
        }
        if (difference) {
            percentValue = (endValue / difference) * 100;
        }
    }
    return (
        <>
            <div className={classes.acticityCounterContainer}>
                <div className={classes.acticityCounterContainerTiles}>
                    <div className={classes.noviceCounter}>
                        <div className={classes.noviceDataContain}>
                            <div className={classes.noviceDataContainTitle}>
                                <Typography className={classes.noviceTitle}>
                                    {userLevelDetails?.level}
                                </Typography>
                                <Typography className={classes.noviceTitle1}>
                                    Complete actions to reach new level
                                </Typography>

                            </div>

                            <img src={userLevelDetails?.url && imageURLRender(userLevelDetails?.url)} alt="" className={classes.noviceIcon}></img>
                        </div>
                        <div className={classes.noviceDataContain}>
                            <BorderLinearProgress variant="determinate" value={percentValue} />
                            <div style={{
                                display: 'flex'
                                , alignItems: 'center'
                            }}>
                                <Typography className={classes.noiceTargetCurrent}>
                                    {userData?.xp}/
                                </Typography> <Typography className={classes.noiceTargetGoal}>
                                    {userLevelDetails?.end}
                                </Typography>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className={classes.acticityCounterContainer}>
                <div className={classes.acticityCounterContainerTiles}>
                    <div className={classes.acticityCounter}>
                        <div className={classes.acticityCounterContain}>
                            <span className={classes.acticityCounterContainTitle}>
                                <div style={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                }}>
                                    <img alt="Natacha" src={XP} /> Daily goal

                                </div>
                                <div style={{
                                    cursor: "pointer"
                                }} onClick={() => {
                                    dispatch(openCloseEditGoalModal(true))
                                }}>
                                    <img src={

                                        EditIcon} alt="" />
                                </div>

                            </span>

                        </div>
                        <span className={classes.acticityCounterContainResult}>
                            {completedXP}/{goalXp}
                        </span>
                    </div>
                    <div className={classes.acticityCounter}>
                        <div className={classes.acticityCounterContain}>
                            <span className={classes.acticityCounterContainTitle}>
                                <div style={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                }}>
                                    <img alt="Natacha" src={StreakIcon} />Streak

                                </div>
                            </span>
                        </div>
                        <span className={classes.acticityCounterContainResult}>
                            {userData?.streakDays} days
                        </span>
                    </div>
                </div>
            </div></>
    )
}

const ScheduleDataComponent = memo(({ callToGetData }) => {
    const dispatch = useDispatch()
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice)
    const { currentMonth,

        selectedDate,
        quote, } = dashboardDataSlice;

    const changeMonth = (minus = false) => {
        if (minus) {
            const startDate = moment(dashboardDataSlice.selectedDate).subtract(1, 'month').startOf("month").format()
            const endDate = moment(dashboardDataSlice.selectedDate).subtract(1, 'month').endOf("month").format()
            let selectedDate = moment(startDate).format()
            if (moment(startDate) >= moment().startOf('month') && moment(startDate) <= moment().endOf('month')) {
                selectedDate = moment().format()
            }
            dispatch(setDahData({
                startDay: moment(startDate).format(),
                endDay: moment(endDate).format(),
                selectedDate: selectedDate
            }))
            const tempCalendar = getDatesArr(startDate, endDate)
            console.log("tempCalendar", tempCalendar);
            dispatch(setDahData({
                currentMonth: tempCalendar[0],
            }));
            callToGetData(selectedDate, selectedDate);
        } else {
            const startDate = moment(dashboardDataSlice.selectedDate).add(1, 'month').startOf("month").format()
            const endDate = moment(dashboardDataSlice.selectedDate).add(1, 'month').endOf("month").format()
            let selectedDate = moment(startDate).format()
            if (moment(startDate) >= moment().startOf('month') && moment(startDate) <= moment().endOf('month')) {
                selectedDate = moment().format()
            }
            dispatch(setDahData({
                startDay: moment(startDate).format(),
                endDay: moment(endDate).format(),
                selectedDate: selectedDate
            }))
            const tempCalendar = getDatesArr(startDate, endDate)
            console.log("tempCalendar", tempCalendar);
            dispatch(setDahData({
                currentMonth: tempCalendar[0],
            }));
            callToGetData(selectedDate, selectedDate);

        }
    }
    const getDatesArr = (startDay, endDay) => {
        const day = moment(startDay)?.clone().subtract(1, "day");

        const tempCalendar = [];

        let startDayTemp = moment(startDay)?.clone();
        let endDayTemp = moment(startDay)?.clone();

        while (day?.isBefore(moment(endDay), "day")) {
            let daysInMonth = startDayTemp
                .clone()
                // .add(3, 'months')
                .endOf("month")
                .diff(endDayTemp.clone(), "days");

            tempCalendar.push(
                Array(daysInMonth + 1)
                    .fill(0)
                    .map(() => {
                        return day.add(1, "day").clone();
                    })
            );

            startDayTemp = day.clone().add(1, "day");
            endDayTemp = moment(endDay).clone();
        }
        return tempCalendar
    }
    const shareQuote = () => {
        const user = localStorage.getItem("userDetail");
        const callback_url = `${window.location.origin}/quote-share/${dashboardDataSlice.quote?._id}`
        DashboardService.SHARE_QUOTE({
            uid: user,
            id: dashboardDataSlice.quote?._id,
            url: callback_url,
        })
            .then((res) => {
                navigator.clipboard.writeText(/* res?.response */callback_url)
                console.log("res", res);
                dispatch(setDahData({
                    openNotification: true,
                    notificationMessage: 'Link copied to clipboard'
                }))
                setTimeout(() => {
                    dispatch(setDahData({
                        openNotification: false,
                        notificationMessage: ''
                    }))
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {

            });
    }
    const openCloseJournalMod = (visible, itm) => {
        dispatch(setDahData({
            openJournalModal: visible,
            openJournalItem: itm,
        }))
    }
    useEffect(() => {
        executeScroll()
    }, [currentMonth])
    const executeScroll = () => {
        // this.myRef.current && this.myRef.current.scrollIntoView({ behavior: 'smooth' })
        const violation = document.getElementById("row-id-block");
        const dateEle = document.getElementById("row-id-date")
        console.log("violation", violation, dateEle);
        if (violation && dateEle) {
            violation.scrollTo({ left: dateEle.offsetLeft - 100, behavior: 'smooth' });
            // violation.scrollBy(130, 0);
        }
        // window.scrollTo({
        //   top: violation.offsetTop,
        //   behavior: "smooth"
        // });

    };
    return <>
        <div style={{
            marginTop: '-50px'
        }}>
            <Box className={classes.dailySchedule}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography className={classes.dailyScheduleTitle}>
                        Your schedule
                    </Typography>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 20
                    }}>
                        <Button style={{
                            padding: 0,
                            lineHeight: 0,
                            minWidth: 30
                        }} onClick={() => changeMonth(true)}>&lt; </Button>
                        <Typography className={classes.dailyScheduleTitle} style={{
                            marginBottom: 0
                        }}>
                            {moment(selectedDate).format("MMMM")}
                        </Typography>
                        <Button style={{
                            padding: 0,
                            lineHeight: 0,
                            minWidth: 30

                        }} onClick={() => changeMonth()}>&gt; </Button>
                    </div>
                </div>


                <Box className={classes.dailyscheduleLayout}>
                    <div className={classes.streakWeek} id="row-id-block">
                        {currentMonth.length &&
                            currentMonth.map((it) => {
                                const headerClass = `${moment(it).isSame(
                                    moment(),
                                    "day"
                                    )
                                    ? clsx(classes.streakDate, classes.todayDate)
                                    : classes.streakDate
                                    }`;
                                const selectedDateClass = `${moment(it).isSame(
                                    moment(selectedDate),
                                    "day"
                                    )
                                    ? clsx(classes.streakDate, classes.selectedDate)
                                    : classes.streakDate
                                    }`;
                                const extraProps = {
                                    id: ''
                                }
                                if (moment(it).isSame(
                                    moment(selectedDate),
                                    "day"
                                )) {
                                    extraProps.id = 'row-id-date'
                                }
                                return (
                                    <div
                                        key={moment(it).format()}
                                        className={classes.streakDay}
                                        onClick={(e) => {
                                            dispatch(setDahData({
                                                selectedDate: moment(it).format(),
                                            }));
                                            callToGetData(moment(it), moment(it));
                                        }}
                                        style={moment(it).isSame(
                                            moment(),
                                            "day"
                                          ) ? {
                                            color: "#FFF"
                                          } : {}}
                                        ref={(ref) => {
                                            if (moment(it).isSame(
                                                moment(selectedDate),
                                                "day"
                                            )) {
                                                // this.myRef = ref
                                            }
                                        }

                                        }
                                        {...extraProps}
                                    >
                                        <span className={classes.streakDateDay}>
                                            {moment(it).format("dd")}
                                        </span>
                                        <div className={`${clsx(headerClass, selectedDateClass)}`} style={moment(it).isSame(
                                            moment(),
                                            "day"
                                          ) ? {
                                            color: "#FFF"
                                          } : {}}>
                                            <span className={classes.streakDateText}>
                                                {moment(it).format("DD")}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <Box className={classes.actionGroup}>
                        <ScheduleDataContent openCloseJournalModal={openCloseJournalMod} />
                    </Box>
                </Box>
            </Box>
            {/* EARLY ACCESS */}
            <Box className={classes.quote} sx={{ backgroundImage: `url(${QuoteIcon}) !important`, backgroundRepeat: 'no-repeat !important', backgroundPositionX: '20px !important' }}>
                {/* <img src={QuoteIcon} alt="" /> */}
                <div className={classes.quoteDataContent}>
                    <span className={classes.quoteDataContentText}>
                        {quote?.title}
                    </span>
                    <span className={classes.quoteDataContentTextName}>
                        {quote?.name}
                    </span>
                </div>
                <div className={classes.quoteDataContentButtons}>
                    <Button
                        variant="outlined"
                        className={classes.quoteDataContentButton}
                        onClick={shareQuote}
                    >
                        <span className={classes.quoteDataContentButtonText}>
                            <img alt="Natacha" src={ShareIcon} />  Share
                        </span>
                    </Button>
                    {/* <Button
                    variant="outlined"
                    className={classes.quoteDataContentButton}
                  >
                    <span className={classes.quoteDataContentButtonText}>
                      <img alt="Natacha" src={InstagramIcon} />  Stories
                    </span>
                  </Button> */}
                </div>
            </Box>
        </div>
    </>
})
