import { Box, Dialog, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { styled } from "@mui/system";
import { GOALTYPE } from "../../Constant/GoalConstant";
import FaverouiteStepOne from "../../pages/Faverourite/FaverouiteStepOne/FaverouiteStepOne";
import FavouriteStepFour from "../../pages/Faverourite/StepFour/FavouriteStepFour";
import StepFive from "../../pages/GoalSelectAllDiamension/StepFive/StepFive";
import StepFour from "../../pages/GoalSelectAllDiamension/StepFour/StepFour";
import StepOneAll from "../../pages/GoalSelectAllDiamension/StepOneAll";
import StepThree from "../../pages/GoalSelectAllDiamension/StepThree/StepThree";
import StepTwo from "../../pages/GoalSelectAllDiamension/StepTwo.jsx/StepTwo";
import { goalCreate } from "../../services/auth";
import { CardContent, MainCard } from "../GoalComponent/GoalDialog";
import GrowthDialogCom from "../GrowthDialog/GrowthDialog";
import CloseIconBlack from "../../assets/Svg/CloseIconBlack.svg";
import HabitStepSix from "../../pages/GoalSelectAllDiamension/HabitStepSix/HabitStepSix";
import classes from './AddNewGoal.module.css'
import { useDispatch, useSelector } from "react-redux";
import { setGoalNotificationMessage, updateAddGoalCategory, updateAddGoalStep, updateGoalData } from "../../Store/Reducers/goalDataSlice";
import ButtonGroup from "../../components/GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import { ConfirmationClose } from "../../pages/GoalListPage/AddGoalModal";
const AddNewGoal = React.forwardRef(({ handleClose, setisAddNewGoal, defaultStep = 1, defaultSelectCtageory = {}, setCustomNotification }, ref) => {
  const [step, setStep] = useState(defaultStep);
  const [isLastStep, setisLastStep] = useState(false);
  const [openConfirmationModal, closeConformation] = useState(false)
  const [typeGoal, setTypeGoal] = useState(GOALTYPE.ALL_DIAMENSTIONGOAL);
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const dispatch = useDispatch()
  const [selectedDiamenstion, setselectedDiamenstion] = useState(defaultSelectCtageory?.questionCategory || "")
  const [selectedDiamenstionImage, setselectedDiamenstionImage] = useState(defaultSelectCtageory?.questionCategoryImage || "")
  const allDiamensionGoal = {
    1: StepOneAll,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
    6: HabitStepSix,
  };
  const faverouiteDiamensionGoal = {
    1: FaverouiteStepOne,
    2: StepTwo,
    3: StepThree,
    4: FavouriteStepFour,
    5: StepFive,
    6: HabitStepSix,
  };
  const setSelectDim = (val) => {
    setselectedDiamenstion(val)
  }

  useEffect(() => {

    let user = localStorage.getItem("userDetail");
    let filterPreSelectedGrowth = goalDetail?.preSelectedGrowth?.filter((item) => item.text !== '')
    let finalGoalDetail = {
      uid: user,
      questionCategoryId: goalDetail?.questionCategoryId,
      increase: goalDetail?.increase,
      progress: goalDetail?.progress,
      preSelectedGrowth: filterPreSelectedGrowth,
      habit: goalDetail?.habit || [],
      action: goalDetail?.action || [],
    };
    if (isLastStep) {
      goalCreate(finalGoalDetail)
        .then((response) => {
          console.log(response);
          setisAddNewGoal(true);

          dispatch(updateAddGoalStep(1))
          dispatch(updateAddGoalCategory({}))
          dispatch(updateGoalData({
            isAskOtherModalOpen: false
          }))
        })
        .catch((err) => {
          if (err?.response?.data?.description) {
            dispatch(setGoalNotificationMessage(err?.response?.data?.description))
            setCustomNotification(true)
            setisAddNewGoal(true);
            dispatch(updateAddGoalStep(1))
            dispatch(updateAddGoalCategory({}))
            dispatch(updateGoalData({
              isAskOtherModalOpen: false
            }))
          }

          console.log(err);
        });
      onNext();
      // handleClose();
    }
  }, [isLastStep]);
  useImperativeHandle(ref, () => ({
    closeConforma: val => {
      closeConformation(val)
      console.log("called fror confirmation")
    }
  }), [step])

  const stepSixNextHandler = () => {
    setisLastStep(true);
  };
  const [goalDetail, setGoalDetail] = useState(defaultSelectCtageory);
  const getGoalDetail = async (key, value) => {
    setGoalDetail({
      ...goalDetail,
      [key]: value,
    });

  };


  const Step =
    typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL
      ? allDiamensionGoal[step]
      : faverouiteDiamensionGoal[step];

  const onNext = () => {
    if (typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL) {
      if (step < Object.keys(allDiamensionGoal).length) {
        setStep((prev) => prev + 1);
      }
    } else {
      if (step < Object.keys(faverouiteDiamensionGoal).length) {
        setStep((prev) => prev + 1);
      }
    }
  };
  const onPrevious = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };
  return (
    <>
      {/* <AddNewGoalDialogTitle handleClose={handleClose} step={step} goalDetail={goalDetail} /> */}

      <MainCard
        sx={{
          minWidth: 250,
          borderRadius: "40px",
          marginInline: "auto",
          position: 'relative',
          padding: '0 0 40px 0',
          width: "640px",
          // minHeight: '740px',
          height: 'calc(100vh - 120px) !important'
        }}
        style={{
          width: "640px",
        }}
      >
        <CardContent style={{
          // position: 'relative',
          padding: '0px'
        }}>
          <Step
            onNext={onNext}
            onPrevious={onPrevious}
            setTypeGoal={setTypeGoal}
            typeGoal={typeGoal}
            handleClose={handleClose}
            getGoalDetail={getGoalDetail}
            goalDetail={goalDetail}
            stepSixNextHandler={stepSixNextHandler}
            setselectedDiamenstion={setSelectDim}
            selectedDiamenstion={selectedDiamenstion}
            selectedDiamenstionImage={selectedDiamenstionImage}
            setselectedDiamenstionImage={setselectedDiamenstionImage}
          />
          {(step === 5 || step === 6) && (
            <GrowthDialogCom goalDetail={goalDetail} />
          )}
        </CardContent>
        {openConfirmationModal && <ConfirmationClose closeConformation={closeConformation} />}
      </MainCard>
    </>

  );
})

export default AddNewGoal;

export const AddNewGoalDialogTitle = ({ handleClose, skipButton = null }) => {
  return <div style={{
    // position: 'relative',
    top: '0',
    height: "54px",
    width: '100%',
    marginBottom: '1.25rem',
  }}>
    <DialogTitle sx={{
      display: 'flex',
      padding: '1.25rem 2rem',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderBottom: '1px solid  #F3F3F3',
      background: '#FFF',
      height: '54px',
      marginBottom: '1.25rem',
      position: 'relative',
      '&.MuiTypography-root': {
        color: '#190D1A',
        fontSize: '0.875rem',
        fontWeight: '500',
      },
      textAlign: 'center',
      width: '100%'
    }}>
      {skipButton}
      <div
        className={classes.closebutton}
        onClick={handleClose}
      >
        <img src={CloseIconBlack} alt="" />
      </div>
      Creating a goal</DialogTitle>
  </div>

} 