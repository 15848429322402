import { styled } from "@mui/system";
import Header from "../../components/Header/Header";
import ResultLayoutData from "./ResultLayoutData";
const ResultLayout = () => {
  
  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    // background: "yellow",
  }));

  return (
    <RightSideContentWrapper>
      <Header />
      <ResultLayoutData />
    </RightSideContentWrapper>
  );
};

export default ResultLayout;
