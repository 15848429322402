import classes from "../ForgotPassword.module.css";
import { Box } from "@mui/system";
import Text from "../../../components/Text/Text";
import Input from "../../../components/Input/Input";
import {
  LinkButtonWithText,
  SubmitButton,
} from "../../../components/Button/Button";
import { forgotPassword } from "../../../services/auth";
import { useState } from "react";
import { isValidEmail } from "../../../validations/Email/Email";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { auth } from "../../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import useNotifications from "../../../Hooks/useNotifications";

export default function ForgotPassword() {
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  // const onClick = () => {
  //   setIsLoading(true)

  // forgotPassword(userEmail)
  //   .then((data) => {
  //     console.log(data);
  //     if (data.message) {
  //       navigate("/verify");
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  //   .finally(() => {
  //     setIsLoading(false);
  //   });
  // };

  const { Notification, updateNotificationState } = useNotifications();

  const forgotPasswordHandler = () => {
    setIsLoading(true);
    return sendPasswordResetEmail(auth, userEmail)
      .then((a) => {
        // updateNotificationState("Check your inbox for further instructions")
        forgotPassword(userEmail)
          .then((data) => {
            console.log(data);
            if (data.message) {
              navigate("/verify");
            }
          })
          .catch((err) => {
            console.log(err.response.data.description);
            updateNotificationState(err.response.data.description);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setError("User with such email isn’t found");
        updateNotificationState("somthing went worng try again");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
        <Text textValue="Forgot password?" variant="h2" />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Email"
          value={userEmail}
          onChange={setUserEmail}
          validationFunction={isValidEmail}
          type="text"
          errorMessage={error}
          setErrorMessage={setError}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <SubmitButton
          buttonText="Reset Password"
          onClick={forgotPasswordHandler}
          isDisabled={!userEmail || error}
          isLoading={isLoading}
        />
      </Box>

      <Box className={classes.wrapper}>
        <LinkButtonWithText
          text="Back to"
          buttonText="Log in"
          navigateUrl="/"
        />
      </Box>
    </>
  );
}
