import React, { useState } from 'react'
const useCustomCheckBox = (checkValueOne, checkValueTwo, defaultValue="") => {

    let firstCheckBoxValue = checkValueOne;
    let secondCheckBoxValue = checkValueTwo;
    const [firstCheckBox, setFirstCheckBox] = useState(defaultValue === checkValueOne)
    const [secondCheckBox, setSecondCheckBox] = useState(defaultValue === checkValueTwo)

    // const [firstCheckBox, setFirstCheckBox] = useState(true)
    // const [secondCheckBox, setSecondCheckBox] = useState(false)

    const handleChange = (changeValue) => {
        if (changeValue === firstCheckBoxValue) {
            setFirstCheckBox(true);
            setSecondCheckBox(false);
        }
        if (changeValue === secondCheckBoxValue) {
            setSecondCheckBox(true);
            setFirstCheckBox(false);
        }
    };

    return { firstCheckBox, secondCheckBox, handleChange }
}

export default useCustomCheckBox