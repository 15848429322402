import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  LinearProgress,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ChipSaveButton from "../../components/ChipSaveButton";
import Header from "../../components/Header/Header";
import classes from "./TestLayout.module.css";
import Coin from "../../assets/Svg/Coin.svg";
import XP from "../../assets/Svg/XP.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Bookmark from "../../assets/Svg/Bookmark.svg";
import BookmarkActive from "../../assets/Svg/BookmarkActive.svg";
import Share from "../../assets/Svg/Share.svg";

import typography from "../../theme/typography";
import ReadMore from "../../components/ReadMore/ReadMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Calander from "../../components/Calander/Calander";
import useCustomNotification from "../../Hooks/useCustomNotification";
import {
  archivedAnswer,
  categoryList,
  categoryPage,
  getAllTestScores,
  getQuestionsList,
  getTestDimensions,
  getUserData,
} from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { storeQuestionList } from "../../Store/Reducers/questionsListSlice";
import { LoadingButton } from "@mui/lab";
import {
  setActiveTestCategoryId,
  setCurrentSelectedSubcategory,
  setIsAskOtherModalOpenTest,
  setOpenConfirmatinModal,
  setSelectedCategoryData,
  setSelectedSubcategoryData,
  setShowConformationModal,
  storeTestCategories,
} from "../../Store/Reducers/testCategories";
import { useQuery } from "react-query";

import { baseUrl, IMAGE_BASE_URL } from "../../services/config";
import defaultImg from "../../assets/images/resizeCategory1662712270910_1662712270910.jpeg";
import {
  updateDimensions,
  updateSubCategoryId,
} from "../../Store/Reducers/dimensions";
import { imageURLRender, secondsToDhms } from "../../utils";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";
import PaymentService from "../../services/Payments";
import ConfirmationModal from "../../components/TestCategoryCard/Modal/ConfirmatioinModal";
import CategorySubcategoryService from "../../services/CategorySubcategory";
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import AskModal from "./AskModal";
import ConfirmationModalClose from "../../core-ui/AskOther/ConfirmationClose";
const TestButtonWrapper = styled("div")(({ theme }) => ({
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  // background: "yellow",
  [theme.breakpoints.down("xl")]: {
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down("lg")]: {
    justifyContent: "flex-start",
  },
  [theme.breakpoints.up("md")]: {
    // marginTop: "1rem",
    justifyContent: "flex-start",
    padding: "0px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "1rem",
    justifyContent: "flex-start",
    padding: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
    justifyContent: "flex-start",
    padding: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
    justifyContent: "flex-start",
    padding: "0px",
  },
}));

const usePayment = true
const TestLayout = () => {
  const { selectedSubCat } = useSelector((state) => state.testCategories);
  const label = { inputProps: { "aria-label": "Checkbox" } };
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [isTestBookMark, setIsTestBookMark] = useState(false);
  const { testCategories } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState([]);
  const [testScores, setTestScores] = useState([]);


  const [testUnlockIn, settestUnlockIn] = useState(selectedSubCat?.testUnlockIn)
  const [toastMessage, setToastMessage] = useState({
    message: "Link copied to clipboard",
    isThumbIcon: true,
    position: "bottom",
  });
  console.log("testUnlockIn", testUnlockIn)
  const [currentTime, setCurrentTime] = useState();
  const [dimensionsBtnData, setDimensionsBtnData] = useState({
    index: 8,
    text: "View all",
  });
  const { userData } = useSelector((state) => state.userProfile);

  const [selectedSubCategories, setSelectedSubCategories] = useState({});
  const navigate = useNavigate();
  // const [currntDate, setCurrntDate] = useState(new Date());
  const { state: newState } = useLocation();
  const { testId } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState(newState || testCategories.selectedSubcategoryData || {});
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    // background: "yellow",
  }));
  // useEffect(() => {
  //   const user = localStorage.getItem("userDetail");
  //   const category_id = localStorage.getItem("selctedCategoryId");
  //   if (
  //     testCategories?.activeTestCategoryId !== testId &&
  //     testCategories?.testCategories.length === 0
  //   ) {
  //     setIsLoading(true);
  //     categoryPage({ uid: user, category_id: category_id })
  //       .then((data) => {
  //         // setCategoryData(data.subCategoryData);
  //         const selectedSubCategoryData = data.subCategoryData.filter(
  //           (testCategories) => {
  //             return testCategories?.subCategory_id === testId;
  //           }
  //         );
  //         setSelectedSubCategories(selectedSubCategoryData[0]);
  //         dispatch(storeTestCategories(data.subCategoryData));
  //         dispatch(setActiveTestCategoryId(category_id));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // }, []);
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };

  const handleClickOpen = () => {
    setIsAskOtherModalOpen(true);
  };

  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const fetchTestCategory = () => {
    const user = localStorage.getItem("userDetail");
    const category_id = localStorage.getItem("selctedCategoryId") || state?.category_id;
    return categoryPage({ uid: user, category_id: category_id });
  };
  const fetchTestSubCategories = () => {
    const user = localStorage.getItem("userDetail");
    const category_id = localStorage.getItem("selctedCategoryId") || state?.category_id;
    return categoryPage({ uid: user, category_id: category_id });
  };
  const fetchTestCategories = () => {
    const user = localStorage.getItem("userDetail");
    return /* categorys.length ? { categoryData: categorys } :  */categoryList({ uid: user, type: "NORMAL" });
  };
  const { isLoading: loadingCategory } = useQuery(
    "test-subCategorys-for-testPage",
    fetchTestCategory,
    {
      onSuccess: async (data) => {
        console.log("state ===", state);
        if (!state || !state.category) {
          const allCategory = await fetchTestCategories()
          const foundCate = allCategory.categoryData.find(it => it.category_id === localStorage.getItem("selctedCategoryId"))
          if (foundCate) {
            dispatch(setSelectedCategoryData({
              ...foundCate,
              category_desc: foundCate?.description,
              categoryId: foundCate.category_id
            }))
          }
        }
        if (!state || !state.subCategory) {
          const allCategory = await fetchTestSubCategories()
          console.log("allCategory", allCategory);
          const foundCate = allCategory.subCategoryData.find(it => it.subCategory_id === testId)
          console.log("allCategory foundCate", foundCate);

          if (foundCate) {
            setState({
              ...foundCate,
              subCategory_desc: foundCate.description,
              category_desc: state?.category_desc,
              categoryId: localStorage.getItem("selctedCategoryId"),
              subCategoryId: foundCate.subCategory_id,
            })
            dispatch(setSelectedSubcategoryData({
              ...foundCate,
              subCategory_desc: foundCate.description,
              category_desc: state?.category_desc,
              categoryId: localStorage.getItem("selctedCategoryId"),
              subCategoryId: foundCate.subCategory_id,
            }))
          }
        }
        const category_id = localStorage.getItem("selctedCategoryId");
        const selectedSubCategoryData = data?.subCategoryData.filter(
          (testCategories) => {
            return testCategories?.subCategory_id === testId;
          }
        );
        setSelectedSubCategories(selectedSubCategoryData[0]);
        dispatch(setCurrentSelectedSubcategory(selectedSubCategoryData[0]))
        dispatch(storeTestCategories(data?.subCategoryData));
        dispatch(setActiveTestCategoryId(category_id));
        if(localStorage.getItem("askother") === "true") {
          dispatch(setIsAskOtherModalOpenTest(true))
          localStorage.removeItem("askother")
        }
      },
      onError: (error) => {
        console.log(error);
      },
      refetchOnWindowFocus: false,
    }
  );
  const fetchDimensions = () => {
    return getTestDimensions(selectedSubCategories?.subCategory_id);
  };
  const { isLoading: loadingDimensions } = useQuery(
    "test-subCategorys-dimensions",
    fetchDimensions,
    {
      onSuccess: (dimensions) => {
        setDimensions(dimensions.questionCategoryData);
        dispatch(updateDimensions(dimensions?.questionCategoryData));
        dispatch(updateSubCategoryId(selectedSubCategories?.subCategory_id));
        setIsLoading(false);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedSubCategories?.subCategory_id,
    }
  );

  const fetchScores = () => {
    const uid = localStorage.getItem("userDetail");
    return getAllTestScores({
      uid,
      subCategory_id: selectedSubCategories?.subCategory_id,
    });
  };

  const { isLoading: loadingSocres } = useQuery("test-scores", fetchScores, {
    onSuccess: (scores) => {
      setTestScores(scores.allPercentage);
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedSubCategories?.subCategory_id,
  });


  useEffect(() => {
    let inertval = null
    if (testCategories.testCategories.length !== 0) {
      const selectedSubCategory = testCategories.testCategories.filter(
        (testCategories) => {
          return testCategories?.subCategory_id === testId;
        }
      );
      console.log("selectedSubCategory[0]", selectedSubCategory[0]);
      if (selectedSubCategory[0]) {
        setSelectedSubCategories(selectedSubCategory[0]);
        settestUnlockIn(selectedSubCategory[0].testUnlockIn)
        dispatch(setCurrentSelectedSubcategory(selectedSubCategory[0]))
      }


    }
    // setIsLoading(false);
  }, [testCategories.testCategories]);

  // useEffect(() => {
  //   if (selectedSubCategories.subCategory_id) {
  //     // setIsLoading(true);
  //     getTestDimensions(selectedSubCategories.subCategory_id)
  //       .then((dimensions) => {
  //         console.log(
  //           "dimensions dimensionsdimensions",
  //           dimensions.questionCategoryData
  //         );
  //         setDimensions(dimensions.questionCategoryData);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       // .finally(() => {
  //       //   setIsLoading(false);
  //       // });
  //   }
  // }, []);



  function LinearProgressWithLabel(props) {
    return (
      <Box className={classes.progressBarWrapper}>
        <Box sx={{ width: "100%", mr: "15px" }}>
          <LinearProgress
            className={classes.progress}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box>
          <Typography variant="body3" color="#190D1A">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          {...props}
          sx={{ width: "48px !important", height: "48px !important" }}
        // className={classes.circularProgress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            color="#9747FF"
            component="div"
            sx={{ padding: "12px" }}
          >
            {`${Math.round(props.value)}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const ContainerWrapper = styled("div")(({ theme }) => ({
    overflowY: "auto",
    height: "90vh",
    [theme.breakpoints.down("md")]: {
      height: "fit-content",
      marginTop: "4px",
    },
  }));

  // CustomDialog

  const closeCustomDialog = () => {
    console.log("this clicked")
  }
  console.log("selectedSubCategories", selectedSubCategories);

  console.log("selectedSubCategories", selectedSubCategories);
  const getQuestions = () => {
    // console.log("get queestion")
    // setIsLoading(true)
    // const uid = localStorage.getItem("userDetail");
    // getQuestionsList({uid,subCategory_id:testId}).then((questions)=>{
    //   console.log("question",questions)
    //   dispatch(storeQuestionList(questions.response))
    // }).catch((error)=>{
    //   console.log("57 error",error)
    // }).finally(()=>{
    //   setIsLoading(false)
    // })

    if (selectedSubCategories?.subCategoryPercentage === 100) {
      console.log("you can not give test on same day");
      const uid = localStorage.getItem("userDetail");

      archivedAnswer({
        uid,
        subCategory_id: selectedSubCategories?.subCategory_id,
      })
        .then((data) => {
          if (data) {
            navigate("/startTest", { state: { testId, coins: selectedSubCategories?.coins, points: selectedSubCategories?.points } });
          }
        })
        .catch((error) => {
          if (error?.response?.data?.description) {
            // setCustomNotification(error?.response?.data?.message);
            setCustomNotification(true);
            setToastMessage({
              message: error?.response?.data?.description,
              isThumbIcon: false,
              position: "top",
            });
          }
        });
    } else {
      navigate("/startTest", {
        state: { testId, subCategory: selectedSubCategories?.subCategory, coins: selectedSubCategories?.coins, points: selectedSubCategories?.points },
      });
    }
  };

  const viewAllBtnHendler = () => {
    if (dimensionsBtnData.text === "View all") {
      setDimensionsBtnData({ index: dimensions.length, text: "Hide" });
    } else {
      setDimensionsBtnData({ index: 8, text: "View all" });
    }
  };
  console.log("selectedSubCategories", selectedSubCategories);


  return (
    <RightSideContentWrapper>
      <Header />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "calc(100vh - 64px)",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={1.5} className={classes.testListWrapper}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            xl={8}
          //   sx={{ background: 'yellow'  }}
          >
            <ContainerWrapper>
              <div className={classes.testInfo}>
                <div className={classes.imageWrapper}>
                  <div className={classes.Imageoverlay}>

                    <img src={imageURLRender(state?.imageUrl)} alt="" />

                  </div>
                  <div className={classes.iconsWrapper}>
                    <div className={classes.iconsLeftWrapper}>
                      <div className={classes.goldCoinIconWrapper}>
                        <img src={Coin} alt="" />
                        <Typography variant="body3" color="#9747FF">
                          {selectedSubCategories?.coins}
                        </Typography>
                      </div>
                      <div
                        className={`${classes.goldCoinIconWrapper} ${classes.ml4}`}
                      >
                        <img src={DiamondCoin} alt="" />
                        <Typography variant="body3" color="#9747FF">
                          {selectedSubCategories?.points}
                        </Typography>
                      </div>
                      <div className={`${classes.timeIcon} ${classes.ml4}`} style={{ display: 'flex', gap: '4px' }}>
                        <img src={XP} alt="" style={{
                          height: '12px',
                          width: '12px',
                        }} />
                        <Typography variant="body3" color="#9747FF">
                          {selectedSubCategories?.total_questions}
                        </Typography>
                      </div>
                      <div className={`${classes.timeIcon} ${classes.ml4}`}>
                        <Typography variant="body3" color="#9747FF">
                          {selectedSubCategories?.time} min
                        </Typography>
                      </div>
                      <div className={`${classes.questionIcon} ${classes.ml4}`}>
                        <Typography variant="body3" color="#9747FF">
                          {selectedSubCategories?.total_questions} questions
                        </Typography>
                      </div>
                    </div>
                    {/* <div className={classes.iconsRightWrapper}>
                      <div className={classes.bookMarkIcon}>
                        <Checkbox
                          {...label}
                          disableRipple
                          sx={{ pl: "0", width: "20px", height: "20px" }}
                          // checked={isTestBookMark}
                          // onChange={(e) => setIsTestBookMark(e.target.checked)}
                          icon={
                            <img
                              src={Bookmark}
                              style={{
                                width: "20px",
                                height: "20px",
                                padding: "0px",
                              }}
                              alt="Bookmark"
                            ></img>
                          }
                          checkedIcon={
                            <img
                              src={BookmarkActive}
                              style={{
                                width: "20px",
                                height: "20px",
                                padding: "0px",
                              }}
                              alt="BookmarkActive"
                            ></img>
                          }
                        />
                      </div>
                      <div
                        className={classes.shareIcon}
                        onClick={() => {
                          // setCustomNotification(true);
                          // setToastMessage({
                          //   message: "Link copied to clipboard",
                          //   isThumbIcon: true,
                          //   position: "bottom",
                          // });
                        }}
                      >
                        <img
                          src={Share}
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className={classes.testName}>
                  <Typography variant="h4" sx={{ fontSize: '1.75rem!important', fontWeight: 600, letterSpacing: '-0.01em' }}>{state?.subCategory}</Typography>
                </div>

                <div className={classes.testDescription}>
                  {/* <Typography
                variant="subtitle1"
                color="#434243"
                sx={{ lineHeight: "145%" }}
              >
                Character strengths are the positive parts of your personality
                that make you feel authentic and engaged. You possess all 24
                character strengths in different degrees, giving you a unique
                character strengths profile.
              </Typography>
              <Typography
                variant="subtitle1"
                color="#434243"
                sx={{ lineHeight: "145%", marginTop: "12px" }}
              >
                The VIA Survey of Character Strengths is a self-assessment that
                takes less than 15 minutes and provides a wealth of information
                to help you understand your best qualities. SSD reports provide
                personalized,including well-bein provide personalized, in-depth analysis of your
                results, strengths to find greater in-depth analysis of your
                resr strengths to find greater well-bein...{" "}
                <span className={classes.readMore}>Read more</span>
              </Typography> */}

                  {state?.subCategory_desc && <ReadMore
                    variant="subtitle1"
                    color="#190D1A"
                    styles={{ lineHeight: "145%" }}
                  >
                    {/* Character strengths are the positive parts of your personality
                  that make you feel authentic and engaged. You possess all 24
                  character strengths in different degrees, giving you a unique
                  character strengths profile. The VIA Survey of Character
                  Strengths is a self-assessment that takes less than 15 minutes
                  and provides a wealth of information to help you understand
                  your best qualities. SSD reports provide
                  personalized,including well-bein provide personalized,
                  in-depth analysis of your results, strengths to find greater
                  in-depth analysis of your resr strengths to find greater
                  well-bein The VIA Survey of Character Strengths is a
                  self-assessment that takes less than 15 minutes and provides a
                  wealth of information to help you understand your best
                  qualities. SSD reports provide personalized,including
                  well-bein provide personalized, in-depth analysis of your
                  results, strengths to find greater in-depth analysis of your
                  resr strengths to find greater well-bein
                  personalized,including well-bein provide personalized,
                  in-depth analysis of your results, strengths to find greater
                  in-depth analysis of your resr strengths to find greater
                  well-bein */}
                    {state?.subCategory_desc}
                  </ReadMore>}
                </div>

                <div className={classes.sourceText}>
                  <Typography variant="body3">
                    Source: {selectedSubCategories.source}
                  </Typography>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ marginTop: "32px" }}
                >
                  {/* <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <div className={classes.profileInfoLeft}>
                      <Avatar
                        alt=""
                        src={Avatarone}
                        sx={{ width: 40, height: 40 }}
                      />
                      <div className={classes.userName}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "600" }}
                        >
                          {selectedSubCategories?.createdBy}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: "4px" }}>
                          {selectedSubCategories?.createdByDetails}
                        </Typography>
                      </div>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TestButtons selectedSubCategories={selectedSubCategories} testUnlockIn={testUnlockIn} dimensions={dimensions} isLoading={isLoading} handleClickOpen={handleClickOpen} getQuestions={getQuestions} setCustomNotification={setCustomNotification} />
                  </Grid>
                </Grid>

                <div className={classes.cardProgressBar}>
                  <LinearProgressWithLabel
                    value={selectedSubCategories?.subCategoryPercentage}
                  />
                </div>
              </div>
            </ContainerWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
          // sx={{ overflowY: "scroll" }}
          >
            <ContainerWrapper>
              <div className={classes.testDimensionsInfo}>
                <div className={classes.dimensionsHeading}>
                  <Typography variant="body4" color="#190D1A">
                    {" "}
                    The test covers {dimensions.length} dimensions
                  </Typography>
                  <HTMLTooltip
                    title="A “dimension” refers to a specific aspect or area of an individual’s personality traits or character qualities"
                    placement="left"
                    showArrow={true}
                  />

                </div>
                {dimensions.length === 0 ? (
                  <div className={classes.dimensionsList}>
                    No dimensions Founds
                  </div>
                ) : (
                  <div className={classes.dimensionsList}>
                    <Grid container spacing={2}>
                      {/* /public/questionCategory/questionCategory1663000471208_1663000471208.png */}
                      {/* src="http://92.205.28.214:3000/api/v1/app/read-file/public/questionCategory/questionCategory1663000471208_1663000471208.png" */}
                      {dimensions
                        .slice(0, dimensionsBtnData.index)
                        .map((dimension, index) => {
                          const { questionCategory, url, _id } = dimension;
                          return (
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              md={6}
                              lg={12}
                              xl={12}
                              key={index}
                            >
                              <div className={classes.dimensionsContentWrapper}>
                                <img
                                  className={classes.dimensionImg}
                                  src={imageURLRender(url)}
                                  alt=""
                                />
                                <Typography variant="body2" sx={{ ml: "12px" }}>
                                  {questionCategory}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                    {dimensions?.length > 8 && (
                      <div
                        className={classes.viewAndHideButton}
                        onClick={viewAllBtnHendler}
                      >
                        <Typography variant="body3" color="#9747FF">
                          {dimensionsBtnData.text}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {selectedSubCategories?.scoreView && (
                <div className={classes.scoreInfo}>
                  <div className={classes.scoreTitle}>
                    <Typography variant="body4" color="#190D1A">
                      Score
                    </Typography>
                  </div>
                  <div className={classes.scoreDescription}>
                    <Typography
                      variant="subtitle1"
                      color="#434243"
                      sx={{ lineHeight: "145%" }}
                    >
                      No results yet, but you will see scores as you take the
                      test
                    </Typography>
                  </div>
                  {testScores.length ? (
                    <>
                      <Divider className={classes.divider} />
                      <div className={classes.testScores}>
                        {(
                          testScores?.map((score, index) => {
                            const { percent } = score;
                            return (
                              <div key={index} className={classes.testResultScore}>
                                <CircularProgressWithLabel value={percent} />
                              </div>
                            );
                          })
                        )}
                      </div>
                    </>
                  ) : <ChipSaveButton
                    style={{
                      marginTop: 16
                    }}
                    onSave={getQuestions}
                    title='Start test'
                  />}

                </div>
              )}
              {/* <Calander testName={state.subCategory} /> */}
            </ContainerWrapper>
          </Grid>
        </Grid>
      )}
      {customNotification && <NotificationContentJSX {...toastMessage} />}
      {/* Ask other modal */}

    </RightSideContentWrapper>
  );
};

export default TestLayout;

const TestButtons = ({ selectedSubCategories, testUnlockIn, isLoading, dimensions, getQuestions, setCustomNotification }) => {
  console.log("testUnlockIn", testUnlockIn);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(testUnlockIn);
  const [showTime, setShowTime] = useState(false);
  let FieldEditor1 = useRef();
  console.log("currentTime", testUnlockIn, currentTime);
  const [sec, seSeconds] = useState(0);
  const [generatingPaymentLoader, setGeneratingPaymentLoader] = useState(false)
  const [visibleConfirmation, setConfirmationVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const setTime = () => {
    const splittedValue = testUnlockIn?.split(":")
    if (splittedValue && splittedValue[0] !== 'NaN') {
      const hms = testUnlockIn;   // your input string
      const a = hms.split(':');
      const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      seSeconds(sec + 1)
      setCurrentTime(secondsToDhms(seconds - sec))
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setShowTime(true)
    }, 500);
  })
  const { userData } = useSelector((state) => state.userProfile);
  const unlockCard = (e, subCategory_id, makeAPI = false) => {
    e.stopPropagation();
    e.preventDefault();
    if (makeAPI) {
      if (usePayment) {
        const objForServer = {
          "user_email": userData?.user_email,
          "uid": userData?.uid,
          redirect_url: window.location.origin + '/testcategory/' + selectedSubCategories.category_id + '/paymentresult'
        }
        if (selectedSubCategories.category_id && selectedSubCategories.subCategory_id) {
          objForServer["type"] = "SHOP_TEST"
          objForServer["category_id"] = selectedSubCategories.category_id
          objForServer["subCategory_id"] = selectedSubCategories.subCategory_id
          objForServer["subCategory"] = selectedSubCategories.subCategory
        }
        PaymentService.CREATE_INVOICE(objForServer)
          .then((res) => {
            localStorage.setItem("selectedSubCategories", res.paymentLink.id)
            localStorage.setItem("selected_item", JSON.stringify(selectedSubCategories))
            setGeneratingPaymentLoader(false)
            setConfirmationVisible(false)
            if (res.paymentLink.url) {
              window.open(res.paymentLink.url, "_self")
            }
            // setSubcategories(res.subCategoryData)
            // setAllSubcategories(res.subCategoryData)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
          });
      } else {
        const uid = localStorage.getItem("userDetail");
        CategorySubcategoryService.UNLOCK_TEST({
          uid,
          "category_id": selectedSubCategories.category_id,
          "subCategory_id": subCategory_id
        }).then((data) => {
          if (data) {
            const user = localStorage.getItem("userDetail");
            if (user) {
              getUserData(user)
                .then((response) => {
                  // navigate("/onbording");
                  dispatch(userProfileUpdate(response.data));
                })
                .catch((error) => {
                  console.log(error);
                })
            }
            navigate(`/test/${subCategory_id}`, {
              state: {
                subCategory: selectedSubCategories.subCategory,
                description: selectedSubCategories.description,
                subCategory_desc: selectedSubCategories.description,
                category_desc: undefined,
                category: selectedSubCategories.category,
                categoryId: selectedSubCategories.category_id,
                subCategoryId: selectedSubCategories.subCategory_id,
                imageUrl: selectedSubCategories.imageUrl,
              },
            });
          }
        })
          .catch((error) => {
            if (error?.response?.data?.description) {
              // setNotificationMessage(error?.response?.data?.description)
              // setCustomNotification(error?.response?.data?.description);
              setCustomNotification(true);
            }
          });
      }

    } else {
      setSelectedItem({
        subCategory: selectedSubCategories.subCategory,
        description: selectedSubCategories.description,
        percentage: selectedSubCategories.percentage,
        // onClickHandler,
        testStatus: selectedSubCategories.testStatus,
        time: selectedSubCategories.time,
        points: selectedSubCategories.points,
        subCategory_id,
        score: selectedSubCategories.score,
        category_id: selectedSubCategories.category_id,
        category: selectedSubCategories.category,
        subCategory_desc: selectedSubCategories.description,
        category_desc: undefined,
        imageUrl: selectedSubCategories.imageUrl,
        handleClickOpen,
        coins: selectedSubCategories.coins,
        unlock: selectedSubCategories.unlock,
        testUnlockIn: selectedSubCategories.testUnlockIn,
        preDescription: selectedSubCategories.preDescription
      })
      setConfirmationVisible(true)
    }

  }
  const handleClickOpen = (visible = true) => {
    dispatch(setIsAskOtherModalOpenTest(true))
};
  return <><TestButtonWrapper className={classes.profileInfoRight}>
    {!selectedSubCategories.unlock ? (
      <>
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '12px',

        }}>
          <div style={{
            display: 'flex',
            height: '48px',
            padding: '2px 2px 2px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            flexShrink: '0',
            borderRadius: '8px',
            border: '1px solid  #F3F3F3',
            background: '#FFF',
            width: '225px',
            position: 'relative'
          }}>
            {showTime && <Timer testUnlockIn={testUnlockIn} />}
            <LoadingButton
              sx={{
                ...typography.subtitle1, height: '44px', borderRadius: '6px',
                display: 'flex',
                padding: '14px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                background: '#9747FF',
                color: "#FFF",
                fontSize: '1rem',
                fontWeight: 400,
                '&:hover': {
                  background: '#9747FF',
                  color: "#FFF",
                },
                position: 'absolute',
                right: 2
              }}
              variant="outlined"
              // onClick={()=>{navigate("/startTest")}}
              onClick={(e) => unlockCard(e, selectedSubCategories.subCategory_id)}
              loading={isLoading}
              loadingIndicator={
                <CircularProgress size={30} sx={{ color: "#fff" }} />
              }
            >
              {"Unlock"}
            </LoadingButton>
          </div>
          <Button
            className={classes.previous_btn}
            sx={{
              ...typography.subtitle1, marginRight: "12px", height: '48px', borderRadius: '8px',
              display: 'flex',
              padding: '16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
            }}
            variant="outlined"
            onClick={handleClickOpen}
          >
            Ask others
          </Button>
        </div>
        {/* <Grid
        container
        spacing={1.5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
          <Timer testUnlockIn={testUnlockIn}/>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <div
            className={classes.retakeButtonWrapper}
          // onClick={(e) => unlockCard(e, subCategory_id)}
          >
            <LoadingButton
              className={classes.startTestButton}
              sx={{ ...typography.subtitle1, marginRight: "12px" }}
              variant="outlined"
              // onClick={()=>{navigate("/startTest")}}
              onClick={getQuestions}
              loading={isLoading}
              loadingIndicator={
                <CircularProgress size={30} sx={{ color: "#fff" }} />
              }
            >
              {"Unlock"}
            </LoadingButton>
          </div>
        </Grid>
      </Grid> */}

      </>

    ) : (
      <>
        <LoadingButton
          className={classes.startTestButton}
          sx={{ ...typography.subtitle1, marginRight: "12px" }}
          variant="outlined"
          // onClick={()=>{navigate("/startTest")}}
          onClick={getQuestions}
          loading={isLoading}
          loadingIndicator={
            <CircularProgress size={30} sx={{ color: "#fff" }} />
          }
        >
          {selectedSubCategories?.subCategoryPercentage === 100
            ? "Retake"
            : "Start test"}
        </LoadingButton>
        <Button
          className={classes.previous_btn}
          sx={{ ...typography.subtitle1, marginRight: "12px" }}
          variant="outlined"
          onClick={handleClickOpen}
        >
          Ask others
        </Button>
      </>
    )}

  </TestButtonWrapper>
   <AskModal dimensions={dimensions}/>

    {visibleConfirmation && <ConfirmationModal
      visibleConfirmation={visibleConfirmation}
      setConfirmationVisible={setConfirmationVisible}
      selectedItem={selectedItem}
      // getSymboleAndAmountForBuy={getSymboleAndAmountForBuy}
      // unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
      // setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
      // setTestModalVisible={setTestModalVisible}
      // setDiamondModalVisible={setDiamondModalVisible}
      createInvoice={unlockCard}
      generatingPaymentLoader={generatingPaymentLoader}
    />}
  </>
}

const Timer = ({ testUnlockIn }) => {
  const [currentTime, setCurrentTime] = useState(testUnlockIn);
  console.log("currentTime", currentTime);
  const [sec, seSeconds] = useState(0);
  const setTime = () => {
    const splittedValue = testUnlockIn?.split(":")
    if (splittedValue && splittedValue[0] !== 'NaN') {
      const hms = testUnlockIn;   // your input string
      const a = hms.split(':');
      const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      seSeconds(sec + 1)
      setCurrentTime(secondsToDhms(seconds - sec))
    }
  }
  useEffect(() => {
    const inertval = setInterval(() => {
      setTime()
    }, 1000);
    return () => inertval && clearInterval(inertval)

  })
  return currentTime && testUnlockIn !== currentTime && <div className={classes.cardTimerBar} style={{
    position: 'absolute',
    left: 10
  }}>
    {currentTime || ''}
  </div>
}