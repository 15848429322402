const colorPalette = [
  {
    bgColour: "#e5d5fc",
    border: "1px solid #9747ff",
    colour: "#9747ff",
    isPick: false,
  },
  {
    bgColour: "#DCF6E5",
    border: "1px solid #45bf70",
    colour: "#45bf70",
    isPick: false,
  },
  {
    bgColour: "#DBEAFA",
    border: "1px solid #5aa0ea",
    colour: "#5aa0ea",
    isPick: false,
  },
  {
    bgColour: "#F5EBE8",
    border: "1px solid #c6aaa2",
    colour: "#c6aaa2",
    isPick: false,
  },
  {
    bgColour: "#ABBEFF",
    border: "1px solid #456bec ",
    colour: "#456bec",
    isPick: false,
  },
  {
    bgColour: "#FDF8D9",
    border: "1px solid #f3da41",
    colour: "#f3da41",
    isPick: false,
  },
  {
    bgColour: "#FFBBE4",
    border: "1px solid #FF5CBF",
    colour: "#FF5CBF",
    isPick: false,
  },
  {
    bgColour: "#FFB9A3",
    border: "1px solid #f36639",
    colour: "#f36639",
    isPick: false,
  },
  {
    bgColour: "#82EEDD",
    border: "1px solid #38ae9b",
    colour: "#38ae9b",
    isPick: false,
  },
  {
    bgColour: "#D9EAD3",
    border: "1px solid #83a976",
    colour: "#83a976",
    isPick: false,
  },
  {
    bgColour: "#D0E0E3",
    border: "1px solid #7ba0a7",
    colour: "#7ba0a7",
    isPick: false,
  },
  {
    bgColour: "#D9D2E9",
    border: "1px solid #716384",
    colour: "#716384",
    isPick: false,
  },
  {
    bgColour: "#FFE599",
    border: "1px solid #cda93f",
    colour: "#cda93f",
    isPick: false,
  },
  {
    bgColour: "#A2C4C9",
    border: "1px solid #30717b",
    colour: "#30717b",
    isPick: false,
  },
  {
    bgColour: "#B4BDC4",
    border: "1px solid #444a4e",
    colour: "#444a4e",
    isPick: false,
  },
  {
    bgColour: "#F7B3AE",
    border: "1px solid #d8635a",
    colour: "#d8635a",
    isPick: false,
  },
  {
    bgColour: "#EEFFC5",
    border: "1px solid #99bb46",
    colour: "#99bb46",
    isPick: false,
  },
  {
    bgColour: "#A8814A",
    border: "1px solid #4a3921",
    colour: "#4a3921",
    isPick: false,
  },
];

export default colorPalette;
