import axios from "axios";
import { baseEndpoints } from "../config";

class DashboardService {
    static LIST_DATA = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.LIST_DATA}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static UPDATE_DAILY_GOAL = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.UPDATE_DAILY_GOAL}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static UPDATE_JOURNAL = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.UPDATE_JOURNAL}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static SHARE_QUOTE = async (values) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.SHARE_QUOTE}`, values)
            .then((response) => {
                return response;
            });
        return data;
    }

    static GET_QUOTE_DATA = async (values) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.GET_BY_ID}`, values)
            .then((response) => {
                return response;
            });
        return data;
    }

    static inviteRefereal = async (values) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.REFERE_INVITE}`, values)
            .then((response) => {
                return response;
            });
        return data;
    }
}

export default DashboardService