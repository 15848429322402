import { Typography } from "@mui/material";
import React from "react";
import { SubmitButton, SubmitButtonOutline } from "../../../components/Button/Button";
import classes from "./AuthOption.module.css";
import Coin from "../../../assets/Svg/Coin.svg";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { SubHeading } from "../StyledComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthOption = () => {
  const navigate = useNavigate()
  const {inviteDataSlice : {inviteData}} = useSelector((state)=>state)
  const {id:inviteId} = useParams()
  const onNext = () => {
    navigate(`/invite/${inviteId}/signup`)
  }
  const onPrevious = () => {
    navigate(`/invite/${inviteId}/login`)
  }
  const joinAsAGuestBtn = () => {
    // navigate(`/invite/${inviteId}/userinfo`)
  }
  return (
    <div className={classes.invitePageWrapper}>
      <div></div>
      <div className={classes.headingWrapper}>
        <div className={classes.headingText}>
          <Typography variant="h3">
            {inviteData?.name} invited you to take {inviteData?.subCategory ? inviteData?.subCategory : "Virtues in action"} test
          </Typography>
        </div>
        <SubHeading>
          <div className={classes.subHeadingText}>
            <Typography variant="subtitle1" color="#434243">
              Help your friend to get feedback on their self-discovery and earn
            </Typography>
          </div>
        </SubHeading>
        <div className={classes.coinIconWrapper}>
          <img src={Coin} alt="" />
          <Typography variant="body3" color="#9747FF">
            20
          </Typography>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <div className={classes.buttonGroup}>
          <ButtonGroup onNext={onNext} onPrevious={onPrevious} isNextDisable={false} isPreviousBtnDisable={false} nextButtonText="Sign up" previousButtonText="Log in" />
        </div>
        <div className={classes.singleButton}>
          <SubmitButtonOutline
            buttonText="Join as guest"
            onClick={joinAsAGuestBtn}
            isDisabled={false}
            isLoading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthOption;
