import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./StepFour.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import styled from "@emotion/styled";
import {
  CheckBoxInputCheckedGoal,
  CheckBoxInputGoal,
  FavouriteMultilineCheckbox,
} from "../../../components/Input/Input";
import typography from "../../../theme/typography";
import { goalGetPreSelectedGrowth } from "../../../services/auth";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import { imageURLRender } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
function StepFour({ onNext, onPrevious, getGoalDetail, goalDetail, handleClose, selectedDiamenstion, selectedDiamenstionImage }) {

  useEffect(() => {
    let user = localStorage.getItem("userDetail");
    let questionCategoryId = goalDetail?.questionCategoryId;
    if (!goalGrowthArea?.length) {
      goalGetPreSelectedGrowth(user, questionCategoryId)
        .then((res) => {
          setgoalGrowthArea(res?.data);
          fill();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setisGoalGrowth(true);
        });
    }
  }, []);
  const [goalGrowthArea, setgoalGrowthArea] = useState([]);
  const [isGoalGrowth, setisGoalGrowth] = useState(false);
  const [preselectedState, setPreselectedState] = useState([]);
  const [newInputList, setNewInputList] = useState([
    { text: "", type: "SELF" },
  ]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...newInputList];
    // console.log("tempList==>", tempList);
    tempList[index][name] = value;
    setNewInputList(tempList);
  };
  const handleAddMore = () => {
    const isText = newInputList[newInputList.length - 1]?.text;
    console.log(isText);
    if (isText) {
      setNewInputList([...newInputList, { text: "", type: "SELF" }]);
    }
  };

  // function
  const growthArea = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SUGGESTED"
  );
  const selfgrowthAreas = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SELF"
  );
  const fill = () => {
    if (growthArea) {
      setPreselectedState(growthArea);
      setNewInputList(selfgrowthAreas);
    }
  };
  const growthAreaHandler = (index, item) => {
    const findData = preselectedState.find((o) => o.index === index);
    if (findData) {
      setPreselectedState((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setPreselectedState([...preselectedState, { index: index, text: item }]);
    }
  };
  const updateMainState = () => {
    let tempArr = [];
    preselectedState?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SUGGESTED", index: data.index });
    });
    newInputList?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SELF" });
    });
    getGoalDetail("preSelectedGrowth", tempArr);
  };
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    border-radius: 16px;
    background: #ffffff;

    & .MuiTextField-root {
      width: 100%;
      border-radius: 16px;
      :hover {
        backgroundcolor: transparent !important;
      }
    }
    & .MuiCheckbox-root {
      :hover {
        border: 1px solid red;
      }
    }
  `;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={() => {
        updateMainState();
        onNext();
      }}>
        <span style={{ color: "#9747FF" }}>{"Skip Growth Area"}</span>{" "}
      </Typography>} />
      <div className={classes.StepThreeWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.StepThreeSubWrapper}>

          {isGoalGrowth && <div>
            <img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" />
          </div>}

          <Typography variant="h5" sx={{ textAlign: "center" }}>
            You have selected{" "}
            <span style={{ color: "#9747FF" }}>
              {" "}
              {selectedDiamenstion}
            </span>{" "}
          </Typography>
          <Typography
            variant="body4"
            sx={{ margin: "16px 0px 10px 0px", textAlign: "center" }}
          >
            Choose preselected growth area or type in your own
          </Typography>
          {!isGoalGrowth ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box className={classes.radioWrapper}>
              {goalGrowthArea?.map((item, index) => {
                return (
                  <CustomCheckBox
                    key={index}
                    sx={{ margin: "6px 0px" }}
                    {...label}
                    icon={<CheckBoxInputGoal placeholder={item?.point} />}
                    checkedIcon={
                      <CheckBoxInputCheckedGoal placeholder={item?.point} />
                    }
                    onChange={() => {
                      growthAreaHandler(index, item?.point);
                    }}
                    checked={preselectedState.find((o) => o.index === index) ? true : false}
                  />
                );
              })}
            </Box>
          )}
          <Typography
            variant="body4"
            sx={{ margin: "20px 0px 16px 0px", textAlign: "center" }}
          >
            or add your own
          </Typography>
          {/* new input */}
          {newInputList?.map((data, i) => {
            return (
              <TextField
                key={i}
                size="small"
                className={classes.goalActionInput}
                sx={{
                  marginBottom: "12px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #f3f3f3",
                    borderRadius: "16px",
                  },
                  " & .MuiOutlinedInput-input": {
                    color: " #190d1a",
                    padding: "16px 10px",
                  },
                  " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                    { border: "1px solid #9747FF" },
                }}
                InputProps={{
                  sx: {
                    "&:hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #e5d5fc",
                      },
                    },
                  },
                }}
                fullWidth
                name="text"
                value={data.text}
                onChange={(e) => handleInputChange(e, i)}
                type="text"
                placeholder="Text"
                id="outlined-start-adornment"
              />
            );
          })}
          <div style={{ width: "100%", marginBottom: "12px" }}>
            <CustomShedualButton
              style={{ maxWidth: "91px" }}
              onClick={() => {
                handleAddMore();
              }}
            >
              Add more +
            </CustomShedualButton>
          </div>

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0", padding: "0 40px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onPrevious();
                }}
              // disabled={isPreviousBtnDisable}
              >
                Previous
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onNext();
                }}
                disabled={preselectedState.length === 0 && !selfgrowthAreas && !newInputList[newInputList.length - 1]?.text}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>

  );
}
export default StepFour;
