import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  paymentId: null,
  products: [],
  productsLoading: false,
  dimetionModalVisible: false,
  testModalVisible: false,
  subcategories: [],
  allsubcategories: null,
  dimensions: [],
  allDimensions: null,
  searchValue: '',
  loadingData: false,
  visibleConfirmation: false,
  selectedItem: {},
  unlockSuccessfullModalVisible: false,
  generatingPaymentLoader: false,
  paymentSuccess: false,
  loadingPaymentStatus: false
  //   isActionShedualOpen:false,
  //   scheduleState:{}
};
export const StoreData = createSlice({
  name: "StoreData",
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      if(Object.keys(action.payload).includes("paymentId")) {
        state.paymentId = action.payload.paymentId
      }
      if(Object.keys(action.payload).includes("products")) {
        state.products = action.payload.products
      }
      if(Object.keys(action.payload).includes("productsLoading")) {
        state.productsLoading = action.payload.productsLoading
      }
      if(Object.keys(action.payload).includes("dimetionModalVisible")) {
        state.dimetionModalVisible = action.payload.dimetionModalVisible
      }
      if(Object.keys(action.payload).includes("testModalVisible")) {
        state.testModalVisible = action.payload.testModalVisible
      }
      if(Object.keys(action.payload).includes("subcategories")) {
        state.subcategories = action.payload.subcategories
      }
      if(Object.keys(action.payload).includes("allsubcategories")) {
        state.allsubcategories = action.payload.allsubcategories
      }
      if(Object.keys(action.payload).includes("dimensions")) {
        state.dimensions = action.payload.dimensions
      }
      if(Object.keys(action.payload).includes("allDimensions")) {
        state.allDimensions = action.payload.allDimensions
      }
      if(Object.keys(action.payload).includes("searchValue")) {
        state.searchValue = action.payload.searchValue
      }
      if(Object.keys(action.payload).includes("loadingData")) {
        state.loadingData = action.payload.loadingData
      }
      if(Object.keys(action.payload).includes("visibleConfirmation")) {
        state.visibleConfirmation = action.payload.visibleConfirmation
      }
      if(Object.keys(action.payload).includes("selectedItem")) {
        state.selectedItem = action.payload.selectedItem
      }
      if(Object.keys(action.payload).includes("unlockSuccessfullModalVisible")) {
        state.unlockSuccessfullModalVisible = action.payload.unlockSuccessfullModalVisible
      }
      if(Object.keys(action.payload).includes("generatingPaymentLoader")) {
        state.generatingPaymentLoader = action.payload.generatingPaymentLoader
      }
      if(Object.keys(action.payload).includes("paymentSuccess")) {
        state.paymentSuccess = action.payload.paymentSuccess
      }
      if(Object.keys(action.payload).includes("loadingPaymentStatus")) {
        state.loadingPaymentStatus = action.payload.loadingPaymentStatus
      }
    },
   
    resetStoreState: () => {
      return initialState;
    },
  },
});

export const { setStoreData, resetStoreState } = StoreData.actions;
export default StoreData.reducer;
