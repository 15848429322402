export const FEEDBACKTYPE = Object.freeze({
  BASED_ON_AVERAGE_POPULATION: "BASED_ON_AVERAGE_POPULATION",
  BASED_ON_PERSONAL_STANDARDS: "BASED_ON_PERSONAL_STANDARDS",
});

export const TESTTYPE = Object.freeze({
  WHOLE_TEST: "WHOLE_TEST",
  SPECIFIC_DIMENSION: "SPECIFIC_DIMENSION",
});

export const REFLECTIVEQUESTION = Object.freeze({
  YES: "YES",
  NO: "NO",
});

export const LINKTYPE = Object.freeze({
  MULTIPLE: "MULTIPLE",
  SINGLE: "SINGLE",
});

// showUsers DetailsFlows

export const USERTYPE = Object.freeze({
  SHOW_MY_NAME: "SHOW_MY_NAME",
  ANONYMEOUSLY: "ANONYMEOUSLY",
});

export const USERAGE = Object.freeze({
  TEN_TO_TWENTY: "10-20",
  TWENTY_TO_THIRTY: "20-30",
  THIRTY_TO_FOURTYT: "30-40",
  FOURTY_TO_FIFTY: "40-50",
  FIFTY_TO_SIXTY: "50-60",
  SIXTY_PLUS: "60+",
});

export const USERSGENDER = Object.freeze({
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
});
export const SELECTLAYOUTBTN = Object.freeze({
  GRID: "GRID",
  COMPRISON: "COMPRISON",
  GRAPH: "GRAPH",
});
export const TYPEOFRESULTS = Object.freeze({
  PERSONAL: "PERSONAL",
  SOCIAL: "SOCIAL"
  
});
export const RESULTSSUBTYPE = Object.freeze({
  INDIVIDUAL: "INDIVIDUAL",
  AVERAGE: "AVERAGE"
  
});
export const SOCIALGRAPHTYPE = Object.freeze({
  INDIVIDUAL: "AVERAGEPOPULATION",
  AVERAGE: "PERSONALSTANDARD"
  
});

