import styled from "@emotion/styled";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { TESTTYPE } from "../../../../Constant/Invite";
import useCustomCheckBox from "../../../../Hooks/useCustomCheckBox";
import typography from "../../../../theme/typography";
import classes from "./StepTwo.module.css";

const StepTwo = (props) => {
  const {firstCheckBox, secondCheckBox, handleChange} = useCustomCheckBox(TESTTYPE?.WHOLE_TEST,TESTTYPE?.SPECIFIC_DIMENSION,props?.inviteData?.testType)
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const updateMainState = () => {
    if (firstCheckBox) {
      props.updateInviteData("testType", TESTTYPE?.WHOLE_TEST)
    } else {
      props.updateInviteData("testType", TESTTYPE?.SPECIFIC_DIMENSION)
    }
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className={classes.stepOneWrapper}>
        <ProgressBar  progress={props.progress}  />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center",mt: '32px' }}>
        How would you like to receive feedback?
        </Typography>
        <div className="option">
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={firstCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="For whole test" />}
                onChange={() => handleChange(TESTTYPE?.WHOLE_TEST)}
              checkedIcon={
                <CheckBoxInputChecked placeholder="For whole test" />
              }
            />
          </Box>
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={secondCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="For a specific dimension" />}
                onChange={() => handleChange(TESTTYPE?.SPECIFIC_DIMENSION)}
              checkedIcon={
                <CheckBoxInputChecked placeholder="For a specific dimension" />
              }
            />
          </Box>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
       <ButtonGroup {...props} updateMainState={updateMainState} isNextDisable={!firstCheckBox && !secondCheckBox} />
      </div>
    </div>
  );
};

export default StepTwo;
