import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import classes from "./GoalArchiveAccordian.module.css";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import useCustomNotification from "../../Hooks/useCustomNotification";
function HabitArchiveAccordian() {
    const { HabitDataSlice } = useSelector((state) => state);
    const [habitAccordian, sethabitAccordian] = useState(false);
    const [habitIds, setHabitIds] = useState("");
  const { NotificationContentJSX, customNotification, setCustomNotification } =
  useCustomNotification();
  const habitAccordianHandler = (panel) => (event, isExpanded) => {
    sethabitAccordian(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={habitAccordian === "panel"}
      onChange={habitAccordianHandler("panel")}
      sx={{ position: "relative" }}
    >
      <AccordionSummary
        expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
        className={classes.subAccordianSummery}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          "& .Mui-expanded": {
            margin: "0px 0px !important",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px 0px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
             Habits
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.GrowthAccordianDetailContainer}>
       <div style={{position:'relative'}}>
        <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
          Completed
        </Typography>
        {HabitDataSlice?.compeletedHabit?.length === 0 ? (
          <div className={classes.dataNotFoundSubWrapper}>
            <Typography variant="body2">Completed Action not found</Typography>
          </div>
        ) : (
            HabitDataSlice?.compeletedHabit?.map((data, index) => {
            const { habitId, _id, habitName, count } = data;
            return (
              <div className={classes.customRadioAction} key={index}>
                <div className={classes.actionNameWrapper}>
                  {count > 1 ? (
                    <div className={classes.duplicateTextWrapper}>
                      <img
                        src={CheckedCheckbox}
                        className={classes.checkBoxIcon}
                        alt=""
                      />
                      <Typography variant="body3">{count}x</Typography>
                    </div>
                  ) : (
                    <img
                      src={CheckedCheckbox}
                      className={classes.checkBoxIcon}
                      alt=""
                    />
                  )}
                  <Typography
                    variant=" body2"
                    className={classes.checkBoxChekedText}
                  >
                    {" "}
                    {habitName}
                  </Typography>
                </div>
                <div className={classes.schedualWrapper}>
                  <img
                    style={{ width: "14px" }}
                    src={CheckboxEndIcon}
                    alt="CheckboxEndIcon"
                    onClick={() => {
                      setCustomNotification(true);
                      setHabitIds(index)
                    }}
                  />
                  <img
                    style={{
                      margin: "4px 0px 0px -7px",
                      height: "18px",
                    }}
                    src={tripleDot}
                    alt="ExpandMore"
                  />
                </div>

                {customNotification && habitIds === index && (
                  <NotificationContentJSX
                    message={habitId?.[0].habitDetail}
                    isThumbIcon={false}
                    position="top"
                  />
                )}
              </div>
            );
          })
        )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default HabitArchiveAccordian;
