import { Grid } from '@mui/material';
import React from 'react'
import Header from '../../../components/Header/Header'
import { SettingNavigationBar } from '../../../components/Settings/SettingNavigationBar/SettingNavigationBar';
import { RightSideContentWrapper } from '../../../components/Settings/StyledElement/StyledDiv'
import ChooseMembership from './ChooseMembership'
import classes from "./membership.module.css";


function SetMembership() {
  return (
    <>
      <RightSideContentWrapper>
        <Header />
        <Grid container spacing={1.5}>
          <Grid item xs={12} lg={9} md={9}>
            <div className={classes.dashbordTitleContainer}>
              {/* <ChooseMembership /> */}
            </div>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div
              style={{ padding: "12px 0px" }}
              className={classes.dashbordTitleContainer}
            >
              <SettingNavigationBar />
            </div>
          </Grid>
        </Grid>
      </RightSideContentWrapper>
    </>
  );
}

export default SetMembership