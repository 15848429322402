import { ClassNames } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import classes from "./EditGoal.module.css";
function EditGoal({ removeGrowthAreaHandler, editGrowthAreaTextHandler, completeGrowthAreaHandler }) {
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.editInnerWrapper}>
        <Typography
          variant="small"
          //   sx={{ color: "#434243",po }}
          className={classes.editText}
          onClick={() => {
            editGrowthAreaTextHandler();
          }}
        >
          Edit
        </Typography>
        <Typography
          variant="small"
          className={classes.removeText}
          onClick={() => {
            removeGrowthAreaHandler();
          }}
        >
          Remove
        </Typography>
        {completeGrowthAreaHandler ?  <Typography
          variant="small"
          className={classes.completeText}
          onClick={() => {
            removeGrowthAreaHandler();
          }}
        >
          Complete
        </Typography> : null}
      </div>
    
    </div>
  );
}

export default EditGoal;
