import { createSlice } from '@reduxjs/toolkit'
import { LINKTYPE } from '../../Constant/Invite'

const initialState = {
    testCategories: [],
    activeTestCategoryId: "", // selected category id,
    selectedSubCat: {},
    selectedSubcategoryData: {},
    selectedCategoryData: {},
    openConfirmationModal: false,
    showConfirmationModal: true,
    isAskOtherModalOpen: false,
    askStep: 1,
    inviteData: {
        feedbackType: "",
        testType: "",
        reflectiveQuestion: "",
        questionCategoryIds: [], // dimension
        questions: [],
        inviteDetails: "", // desc
        invites: [], // email aahi mokalvu
        // linkType: "",
        linkType: LINKTYPE.MULTIPLE,
      }
}

export const testCategoriesSlice = createSlice({
    name: 'testCategoriesSlice',
    initialState,
    reducers: {
        storeTestCategories: (state, action) => {
            state.testCategories = action.payload
        },
        setActiveTestCategoryId: (state,action)=>{
            state.activeTestCategoryId = action.payload
        },
        setCurrentSelectedSubcategory: (state, action) => {
            state.selectedSubCat = action.payload
        },
        setSelectedSubcategoryData: (state, action) => {
            state.selectedSubcategoryData = action.payload
        },
        setSelectedCategoryData: (state, action) => {
            state.selectedCategoryData = action.payload
        },
        setShowConformationModal: (state, action) => {
            state.showConfirmationModal = action.payload
        },
        setOpenConfirmatinModal: (state, action) => {
            state.openConfirmationModal = action.payload
        },
        setIsAskOtherModalOpenTest: (state, action) => {
            state.isAskOtherModalOpen = action.payload

        },
        setaskStep: (state, action) => {
            state.askStep = action.payload
        },
        setInviteDataAsk: (state, action) => {
            state.inviteData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { storeTestCategories,setActiveTestCategoryId, setCurrentSelectedSubcategory, setSelectedSubcategoryData, setSelectedCategoryData, setShowConformationModal, setOpenConfirmatinModal, setIsAskOtherModalOpenTest, setaskStep, setInviteDataAsk } = testCategoriesSlice.actions

export default testCategoriesSlice.reducer