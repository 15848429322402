import styled from "@emotion/styled";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { REFLECTIVEQUESTION } from "../../../../Constant/Invite";
import useCustomCheckBox from "../../../../Hooks/useCustomCheckBox";
import typography from "../../../../theme/typography";
import classes from "./StepThree.module.css";

const StepThree = (props) => {
  const {firstCheckBox, secondCheckBox, handleChange} = useCustomCheckBox(REFLECTIVEQUESTION?.YES,REFLECTIVEQUESTION?.NO, props?.inviteData?.reflectiveQuestion)
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const updateMainState = () => {
    if (firstCheckBox) {
      props.updateInviteData("reflectiveQuestion", REFLECTIVEQUESTION?.YES)
    } else {
      props.updateInviteData("reflectiveQuestion", REFLECTIVEQUESTION?.NO)
    }
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className={classes.stepOneWrapper}>
        <ProgressBar progress={props.progress} />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center",mt: '32px' }}>
        Would you like to add reflective questions?
        </Typography>
        <div className="option">
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={firstCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Yes" />}
                onChange={() => handleChange(REFLECTIVEQUESTION?.YES)}
              checkedIcon={
                <CheckBoxInputChecked placeholder="Yes" />
              }
            />
          </Box>
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={secondCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="No" />}
                onChange={() => handleChange(REFLECTIVEQUESTION?.NO)}
              checkedIcon={
                <CheckBoxInputChecked placeholder="No" />
              }
            />
          </Box>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
       <ButtonGroup {...props} updateMainState={updateMainState} isNextDisable={!firstCheckBox && !secondCheckBox} />
      </div>
    </div>
  );
};

export default StepThree;
