import { Typography } from '@mui/material'
import React from 'react'
import classes from './DuplicategrowthArea.module.css'
function DuplicateGrowthArea({duplicateGrowthAreaHandler}) {
  return (
    <div className={classes.mainWrapper}>
    <div className={classes.editInnerWrapper}>
      <Typography
        variant="small"
        className={classes.editText}
        onClick={() => {
          duplicateGrowthAreaHandler();
        }}
      >
      Duplicate
      </Typography>
    </div>
  </div>
  )
}

export default DuplicateGrowthArea