import {
  Checkbox,
  styled,
  Typography,
} from "@mui/material";
import classes from "./JoinWaitlist.module.css";

import Box from "@mui/material/Box";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import {
  LinkButtonForTermsAndCondition,
  LinkButtonWithText,
  SubmitButton,
} from "../../components/Button/Button";
import {
  appleProvider,
} from "../../config/authMethods";
import {
  termsAndConditionContent,
} from "../../services/auth";
import { isValidEmail } from "../../validations/Email/Email";
import { useEffect, useState } from "react";
import {
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import useNotifications from "../../Hooks/useNotifications";
import checkedIcon from "../../../src/assets/images/Vector.png";
import WaitlistService from "../../services/WaitList";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function JoinWaitlist() {
 
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [nameError, setNameError] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsAndConditionUrl, settermsAndConditionUrl] = useState("");

  const { Notification, updateNotificationState, action } =
    useNotifications(false);
  let navigate = useNavigate();

  useEffect(() => {
    termsAndConditionContent()
      .then((data) => {
        console.log(data);
        settermsAndConditionUrl(data?.policyAndConditionsData?.conditionsUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createNewUser = async () => {
    const userDataRes = {
      "name": name,
      user_email: email,
    };
    setIsLoading(true);
    WaitlistService.WAIT_LIST_CREATE(userDataRes)
      .then((data) => {
        console.log(data);
        setName("")
        setEmail("")
        setEmailError("")
        setNameError("")
        // localStorage.setItem("userDetail", data.data.uid);
        // localStorage.setItem("userEmail", data.data.user_email);
        updateNotificationState(data.description);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error) => {
        console.log(12, error.response);
        if (error.response.data.description === "Uid is already registered") {
          updateNotificationState("User is alredy registered");
          // setErrorEmail("User is alredy registered")
          // navigate("/");
        } else {
          updateNotificationState(error.response.data.description);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const handleSubmit = () => {
    setIsLoading(true);
    createNewUser()
  };

  console.log(termsAndConditions);

  const CheckboxIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "11px",
        }}
      ></div>
    );
  };

  const CheckboxCheckedIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "2px",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <img src={checkedIcon} alt="" />
      </div>
    );
  };

  const TermsAndCondition = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "-2px",
    marginLeft: "3px",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",

    [theme.breakpoints.up("xl")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4px",
    },
  }));
  return (
    <>
      { (
        <>
          <Box className={classes.wrapperTitle} style={{ marginBottom: '80px'}}>Scientific Self-Discovery</Box>
          <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
            <Text textValue="Join our waitlist and be among the first to try" variant="h2" />
          </Box>
          <Box className={classes.wrapper}>
            <Input
              placeholder="Name"
              value={name}
              onChange={(val) => setName(val)}
              // validationFunction={}
              type="text"
              errorMessage={nameError}
              setErrorMessage={(err) => setNameError(err)}
            />
          </Box>
          <Box className={classes.wrapper}>
            <Input
              placeholder="Email"
              value={email}
              onChange={(val) => setEmail(val)}
              validationFunction={isValidEmail}
              type="text"
              errorMessage={emailError}
              setErrorMessage={(err) => setEmailError(err)}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <SubmitButton
              buttonText="Join Waitlist"
              isDisabled={
                !name ||
                !email ||
                nameError ||
                emailError ||
                !termsAndConditions
              }
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </Box>
          <TermsAndCondition className={classes.terms_and_condition}>
            <Checkbox
              {...label}
              disableRipple
              sx={{ pl: "0", width: "20px", height: "20px" }}
              checked={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.checked)}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
            />
            <span style={{ color: "#434243", paddingLeft: "12px" }}>
              I agree with {" "}
              <LinkButtonForTermsAndCondition
                termsAndConditionUrl={termsAndConditionUrl}
                buttonText="Terms & Conditions"
                color="#000000"
                textDecoration="underline"
              />
               &nbsp;and &nbsp; 
               <LinkButtonForTermsAndCondition
                termsAndConditionUrl={termsAndConditionUrl}
                buttonText="Privacy Policy"
                color="#000000"
                textDecoration="underline"
              />
            </span>
          </TermsAndCondition>
          
          <Box className={classes.bottomBox}>
            <Typography variant="p" className={classes.set_text}>
              Already Have a code? <span onClick={() => navigate('/signup')}> Join Here</span>
            </Typography>
          </Box>
          {Notification}
        </>
      )}
    </>
  );
}
