import classes from "../Onbording.module.css";
import Box from "@mui/material/Box";
import Input, {
  CheckBoxInput,
  CheckBoxInputChecked,
  InputCheckBox,
} from "../../../../components/Input/Input";
import { PrevAndNextButtonGroup } from "../../../../components/Button/Button";
import { Step, StepsWrapper, SubHeadingTwo } from "../../StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateMotivates } from "../../../../Store/Reducers/onbordingSlice";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { updateUserData } from "../../../../services/auth";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useNotifications from "../../../../Hooks/useNotifications";
import moment from "moment";

export default function StepFour({ onNext, onPrevious }) {
  const { motivates, otherMotivationesValue, name, age, gender } = useSelector(
    (state) => state.onbording
  );

  const [motivatesValue, setMotivatesValue] = useState(motivates);
  const [motivatesName, setMotivatesName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { Notification, updateNotificationState } = useNotifications(false);

  const [others, setOthers] = useState(otherMotivationesValue);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const onNextStep = () => {
    setIsLoading(true);
    if (
      !motivatesValue.curiosity &&
      !motivatesValue.personalDevlopment &&
      !motivatesValue.work &&
      !motivatesValue.mentalHealth
    ) {
      console.log("curiosity is required");
      return;
    }
    dispatch(updateMotivates({ motivatesName, others }));
    // onNext();

    const uid = localStorage.getItem("userDetail");

    let userData = {
      name,
      age: parseInt(age),
      gender,
      uid,
      dob: "12-20-2022",
      motivates:
        others !== "" ? [...motivatesName, others] : [...motivatesName],
    };
    // const userData = {name,age: parseInt(age),gender,motivates: [...motivatesName,others],uid};
    // if (others !== "") {
    //   userData.motivates = [...motivatesName, others];
    // } else {
    //   userData.motivates = [...motivatesName];
    // }
   
    const inviteID = localStorage.getItem("inviteId")
    updateUserData(userData)
      .then((data) => {
        console.log("inside onbording last step");
        // Navigate("/onbording");
        navigate(`/invite/${inviteID}/done`);
      })
      .catch((error) => {
        console.log(error.response.data.description);
        if (error.response.data.description === "Uid is not registered") {
          updateNotificationState("User is not Registered yet");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const handelChange = (event) => {
    setMotivatesValue({
      ...motivatesValue,
      [event.target.name]: event.target.checked,
    });
    let currvalue = [...motivatesName];
    if (event.target.checked) {
      if (!currvalue.includes(event.target.name)) {
        currvalue.push(event.target.name);
        setMotivatesName(currvalue);
      }
    } else {
      if (currvalue.includes(event.target.name)) {
        let newMotivationList = currvalue.filter(
          (value) => value !== event.target.name
        );
        setMotivatesName(newMotivationList);
      }
    }
  };

  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
      </StepsWrapper>
      <div className={classes.wrapperDiv}>
        <Box className={classes.wrapper}>
          <Typography variant="h2"  sx={{ textAlign: "center" }}>
            Great! Now let’s talk about motivations
          </Typography>
        </Box>
        <SubHeadingTwo className={classes.wrapper} sx={{ mb: "16px" }}>
          <Typography variant="subtitle1"  sx={{ textAlign: "center" }}>
            What motivaties you to use this app?
          </Typography>
        </SubHeadingTwo>
        <Box className={classes.wrapper} sx={{ mb: "16px" }}>
          <CustomCheckBox
            {...label}
            checked={motivatesValue.curiosity}
            name="curiosity"
            onChange={(e) => handelChange(e)}
            icon={<CheckBoxInput placeholder="Curiosity" />}
            checkedIcon={<CheckBoxInputChecked placeholder="Curiosity" />}
          />
        </Box>
        <Box className={classes.wrapper} sx={{ mb: "16px" }}>
          <CustomCheckBox
            name="personalDevlopment"
            checked={motivatesValue.personalDevlopment}
            onChange={(e) => handelChange(e)}
            {...label}
            icon={<CheckBoxInput placeholder="Personal development" />}
            checkedIcon={
              <CheckBoxInputChecked placeholder="Personal development" />
            }
          />
        </Box>
        <Box className={classes.wrapper} sx={{ mb: "16px" }}>
          <CustomCheckBox
            name="work"
            onChange={(e) => handelChange(e)}
            checked={motivatesValue.work}
            {...label}
            icon={<CheckBoxInput placeholder="Work" />}
            checkedIcon={<CheckBoxInputChecked placeholder="Work" />}
          />
        </Box>
        <Box className={classes.wrapper} sx={{ mb: "16px" }}>
          <InputCheckBox
            placeholder="Other"
            value={others}
            onChange={setOthers}
            type="text"
          />
        </Box>
        <Box className={classes.wrapper}>
          <PrevAndNextButtonGroup
            isPreviousButton={true}
            onNext={onNextStep}
            onPrevious={onPrevious}
            nextButtonText="Save"
            isDisabled={motivatesName.length === 0 && others === ""}
            isLoading={isLoading}
          />
        </Box>
      </div>
      {Notification}
    </>
  );
}
