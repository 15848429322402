import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const AllButton = styled(Button)(({ theme }) => ({
  height: "24px",
  borderRadius: "4px",
  fontSize: "10px !important",
  minWidth: "36px",
  padding: "0px 6px",
  border: "1px solid #F3F3F3",
  // [theme.breakpoints.down("md")]: {
  //   // width: "50%",
  // },
}));
export const FavouriteButton = styled(Button)(({ theme }) => ({
  height: "24px",
  borderRadius: "4px",
  fontSize: "10px !important",
  minWidth: "68px",
  padding: "0px 6px",
  border: "1px solid #F3F3F3",
  // [theme.breakpoints.down("md")]: {
  //   // width: "50%",
  // },
}));
export const AllFavourite = styled("div")(({ theme }) => ({
  backgroundColor: "#fffff",
  borderRadius: "6px",
  margin: "10px 0px",
  zIndex: "1",
  // [theme.breakpoints.down("md")]: {
  //   // marginTop: "20px",
  //   order: "1",
  //   margin: "0px",
  // },
}));
export const CustomShedualButton = styled("div")(({ theme }) => ({
  background: "#F7F2FE",
  borderRadius: "16px",
  maxWidth: "76px",
  padding: "6px 12px",
  color: "#9747FF",
  fontSize: "12px",
  cursor: "pointer",
}));

export const CustomShedualButtonAddMore = styled("div")(({ theme }) => ({
  background: "#F7F2FE",
  borderRadius: "16px",
  maxWidth: "76px",
  padding: "6px 12px",
  color: "#9747FF",
  fontSize: "12px",
  cursor: "pointer",
  maxWidth: "91px",
  background: "#F7F2FE",
  color: "#9747FF",
  marginLeft: "8px",
}));
