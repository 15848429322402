import { Grid, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
import { SubmitButton } from "../../../components/Button/Button";
import { Input } from "../../../components/Settings/StyledElement/StyledformInput";
import { giveFeedbackData } from "../../../services/auth";
import { MultiLineInput } from "../../TestResultsPages/StyledStepper/styledStepper";
import { useTheme } from "@emotion/react";
import classes from "./giveFeedback.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import useCustomNotification from "../../../Hooks/useCustomNotification";

function GiveFeedback() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [giveFeadback, setGiveFeadback] = useState({
    theme: "",
    user_email: "",
    description: "",
  });
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();

  console.log("giveFeadback:-", giveFeadback);

  const handleSubmitData = () => {
    console.log("Give feedback", giveFeadback);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = regex.test(giveFeadback.user_email);
    console.log("isValidEmail==>", isValidEmail);
    setEmailError(isValidEmail);

    if (isValidEmail) {
      let details = {
        ...giveFeadback,
        uid: localStorage.getItem("userDetail"),
      };
      console.log("details = ", details);
      setIsLoading(true);
      setCustomNotification(true)
      giveFeedbackData(details)
        .then((res) => {
          console.log("res update = ", res);
          setGiveFeadback({
            theme: "",
            user_email: "",
            description: "",
          });
        })
        .catch((err) => {
          console.log("err = ", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleInpute = (e) => {
    if (e.target.name === "user_email") {
      setEmailError("");
    }
    setGiveFeadback({ ...giveFeadback, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Grid item sm={12}>
        <Grid item sm={12} className={clsx(!matches && classes.mob_set_grid)}>
          <Input
            placeholder="Describe thems"
            name="theme"
            type="text"
            value={giveFeadback.theme}
            onChange={handleInpute}
          />
        </Grid>
        <Grid container sx={{ marginBottom: "12px" }}>
          <Grid
            className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
          >
            <MultiLineInput
              sx={{ maxWidth: "100% !important" }}
              value={giveFeadback.description}
              onChange={handleInpute}
              name={"description"}
              multiline
              size="small"
              fullWidth
              key="pogress"
              type="text"
              placeholder="Discribe feature functionality"
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <Input
            name="user_email"
            placeholder="Email address"
            value={giveFeadback.user_email}
            onChange={handleInpute}
            type="email"
            emailError={emailError}
          />
          <span className={classes.email_error}>
            {emailError === false && "Please add valid email"}
          </span>
        </Grid>
        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <SubmitButton
            isDisabled={
              giveFeadback.theme === "" ||
              giveFeadback.description === "" ||
              giveFeadback.user_email === ""
            }
            buttonText={"Send"}
            onClick={handleSubmitData}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      {customNotification && (
        <NotificationContentJSX
          message="Feedback added successfully"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
}

export default GiveFeedback;
