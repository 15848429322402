import Stories from '../../components/Stories';
import Success16px from "./../../assets/Svg/success-circle-16px.svg"
import Story1 from "./../../assets/Svg/Story1.svg"
import Story2 from "./../../assets/Svg/Story2.svg"
import Story3 from "./../../assets/Svg/Story3.svg"
import { updateSetShowStories, updateSetShowYoutube } from "../../Store/Reducers/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, Typography } from '@mui/material';
import YouTube from 'react-youtube';
import classes from "./youtube.module.css"
export const YoutubeComponent = () => {
    const dispatch = useDispatch();
    const { showYoutube } = useSelector(
        (state) => state.dashboardDataSlice
    );
    const { topicDataYoutubeLink } = useSelector(
        (state) => state.onbording
    );

    console.log("dashboardDataSlice", topicDataYoutubeLink, showYoutube);
    return showYoutube ? (
        <Dialog open={showYoutube}
            keepMounted
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    width: '90%', 
                    maxWidth: '80rem', 
                    height: 'calc(100vh - 140px)', 
                    maxHeight: '40rem', 
                    position: "relative"
                }
            }}
            className={classes.mainModal}
            onClose={() => dispatch(updateSetShowYoutube(false))}
            aria-describedby="alert-dialog-slide-description">
            <DialogContent className={classes.modalContent}>
                {topicDataYoutubeLink && (
                    <div className={classes.video}>
                        <iframe
                            width="100%"
                            height="100%"
                            style={{
                                borderRadius: '24px'
                            }}
                            src={`https://www.youtube-nocookie.com/embed/${topicDataYoutubeLink.split("/").pop()}?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent`}
                            title="YouTube video player"
                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen rel="0">

                        </iframe>
                        {/* <YouTube
     videoId={"qg4H1kMcrlA"}
     style={{
         height: '100%',
         width: '100%',
     }}
     iframeClassName={classes.testVideo}
     //   onReady={onReady}
     opts={{
         height: '100%',
         width: '100%',

         playerVars: {
             controls: 0,
         },
     }}
 /> */}
                    </div>
                )}

                <div className={classes.videoContent}>
                    <Typography
                        sx={{
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            color: '190D1A',
                            lineHeight: '135%',
                            letterSpacing: '-0.24px',
                            textAlign: 'center'
                        }}>Watch our introduction video</Typography>
                    <Typography
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                            color: '190D1A',
                            lineHeight: '135%',
                            letterSpacing: '-0.16px',
                            textAlign: 'center'

                        }}>We have filmed a video where we explain how to use the app and make the most out of it. </Typography>
                </div>

            </DialogContent>
        </Dialog>
    ) : null
}