import classes from "../ForgotPassword.module.css";
import { Box } from "@mui/system";
import { SubmitButton } from "../../../components/Button/Button";
import Text from "../../../components/Text/Text";
import { useNavigate } from "react-router-dom";

export default function EmailVerification() {
  let navigate = useNavigate();
  const isLoading = false;
  return (
    <>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
        <Text textValue="Check your email" variant="h2" />
      </Box>

      <Box className={classes.wrapper}>
        <Text
          textValue="We have sent a link to reset your password. Please click in the
          received link to continue to reset your password"
          variant="subtitle1"
        />
      </Box>
      <Box className={classes.wrapper}>
        <Text
          textValue="Didn't receive an email? Check your junk folder or request another
          link."
          variant="subtitle1"
        />
      </Box>
      <Box sx={{ width: "100%", pt: "10px", pb: "10px" }}>
        <SubmitButton
          buttonText="Back To Login"
          onClick={() => {
            navigate("/");
          }}
          isDisabled={false}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
}
