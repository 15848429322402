import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMore from "../../../assets/Svg/ExpandMore.svg";
import classes from "./FeaturedGoals.module.css";
import Engagement from "../../../assets/Svg/Engagement.svg";
import CheckboxEndIcon from "../../../assets/Svg/CheckboxEndIcon.svg";
import { CustomShedualButtonAddMore } from "../../GoalComponent/GoalButton";
import { CardContent, MainCard } from "../../GoalComponent/GoalDialog";
import GrowthAreaDialog from "../../GoalAddMoreDialog/GrowthAreaDialog/GrowthAreaDialog";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  editPreSelectedGrowth,
  getGrowthData,
  goalCreateHabitAction,
} from "../../../services/auth";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../../Store/Reducers/goalGrowthCalendar";
import ActionDialog from "../../GoalAddMoreDialog/ActionDialog/ActionDialog";
import { updateIsAddMore } from "../../../Store/Reducers/goalActionData";
import HabitDialog from "../../GoalAddMoreDialog/HabitDialog/HabitDialog";
import { updateHabitAddMore } from "../../../Store/Reducers/goalHabitData";
import { v4 as uuidv4 } from "uuid";

const FeaturedGoals = ({
  setExpanded,
  expanded,
  setSelectedGrowthArr,
  selectedGrowthArr,
  setBgColor,
  preselectedState,
  setPreselectedState,
  fullscreen,
}) => {
  const { getAllGrowthData } = useSelector((state) => state);
  const disapatch = useDispatch();
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;

  const [isAddMoreGrowthAreaOpen, setisAddMoreGrowthAreaOpen] = useState(false);
  const [idDialog, setidDialog] = useState("");
  const [isActionOPen, setIsActionOPen] = useState(false);
  const [growthAccordian, setGrowthAccordian] = useState(false);
  const [questionCategoryid, setquestionCategoryId] = useState("");
  const [isHabitOpen, setIsHabitOpen] = useState(false);
  useEffect(() => {
    let growthData = {
      uid: localStorage.getItem("userDetail"),
      archive: false,
      status: "IN_PROGRESS",
    };
    if (getAllGrowthData?.GetGrowthData) {
      getGrowthData(growthData)
        .then((res) => {
          disapatch(storeGetGrowthData(res?.data));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          disapatch(updateIsGetGrowthData(true));
        });
    }
  }, []);
  const dragStart = (e, item) => {
    let element = e.currentTarget;
    element.classList.add("dragged");
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
    e.dataTransfer.effectAllowed = "move";
  };
  const dragEnter = (e, position) => {
    e.preventDefault();
    let element = e.currentTarget;
    element.classList.add("dragged-over");
    e.dataTransfer.dropEffect = "move";
  };
  const dragEnd = (e) => {
    e.currentTarget.classList.remove("dragged");
  };
  const dragLeave = (e) => {
    let currentTarget = e.currentTarget;
    let newTarget = e.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    e.preventDefault();
    let element = e.currentTarget;
    element.classList.remove("dragged-over");
  };
  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const drop = (e, value, status) => {
    let dateId = e.target.id;
    e.preventDefault();
    e.target.classList.remove("dragged-over");
    let objData = JSON.parse(e.dataTransfer.getData("text/plain"));
    const newSelectedGrowthArr = selectedGrowthArr.map((obje) => {
      if (obje.text === objData.text && obje.status === "New Question") {
        setBgColor("");
        return { ...obje, status: "IN_PROGRESS" };
      }
      return obje;
    });
    setSelectedGrowthArr(newSelectedGrowthArr);
    let tempGetGrowthCalendarData = [];
    getAllGrowthData?.GetGrowthData?.map((data) => {
      const newPreselectedGrowth = data?.preSelectedGrowth?.map((item) => {
        if (objData.text === item.text) {
          return { ...item, status: status };
        }
        return item;
      });
      const newActions = data?.actions?.map((item) => {
        if (objData.text === item.actionName) {
          return { ...item, status: status };
        }
        return item;
      });
      const newHabits = data?.habits?.map((item) => {
        if (objData.text === item.habitName) {
          return { ...item, status: status };
        }
        return item;
      });
      console.log("data", data);
      tempGetGrowthCalendarData.push({
        actions: newActions,
        actionsArchived: data.actionsArchived,
        actionsComplete: data.actionsComplete,
        archive: data.archive,
        habits: newHabits,
        habitsArchived: data.habitsArchived,
        habitsComplete: data.habitsComplete,
        increase: data.increase,
        mySelf: data.mySelf,
        others: data.others,
        preSelectedGrowth: newPreselectedGrowth,
        preSelectedGrowthComplete: data.preSelectedGrowthComplete,
        questionCategory: data.questionCategory,
        questionCategoryId: data.questionCategoryId,
        status: data.status,
        tbd: data.tbd,
        uid: data.uid,
        url: data.url,
        _id: data._id,
      });
    });
    disapatch(storeGetGrowthData(tempGetGrowthCalendarData));
  };

  const handleChange = (questionCategoryId, _id) => (event, isExpanded) => {
    setquestionCategoryId(questionCategoryId);
    setExpanded(isExpanded ? questionCategoryId : false);
  };
  const GrowthAccordianHandler = (panel) => (event, isExpanded) => {
    setGrowthAccordian(isExpanded ? panel : false);
  };
  const addNewGrowthAreaHandler = (selfInput, preSelectedGrowthPerams) => {
    let tempNewGrowthAreaState = [];
    let tempmeargeSelectedGrowth = [];
    if (selfInput?.text) {
      tempNewGrowthAreaState = [...preSelectedGrowthPerams, selfInput];
    } else {
      tempNewGrowthAreaState = preSelectedGrowthPerams;
    }
    let tempGetGrowthCalendarData = [];
    const newGetAllGrowthData = getAllGrowthData?.GetGrowthData?.map((data) => {
      if (idDialog === data._id) {
        tempGetGrowthCalendarData.push(
          ...data.preSelectedGrowth,
          ...tempNewGrowthAreaState
        );
        return {
          ...data,
          preSelectedGrowth: tempGetGrowthCalendarData,
        };
      }
      return data;
    });
    disapatch(storeGetGrowthData(newGetAllGrowthData));
    const user = localStorage.getItem("userDetail");
    let newGrowthAreaData = {
      uid: user,
      goalId: idDialog,
      preSelectedGrowth: tempGetGrowthCalendarData,
      preSelectedGrowthComplete: [],
    };
    editPreSelectedGrowth(newGrowthAreaData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addMoreActionHandler = (actionState) => {
    let tempActionState = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionId: uuidv4(),
        actionDetail: item?.actionDetail,
        actionName: item.actionName,
        active: "true",
        scheduled_type: "DEFAULT",
      });
    });
    let tempGetGrowthCalendarData = [];
    getAllGrowthData?.GetGrowthData?.map((data) => {
      tempGetGrowthCalendarData.push({
        actions:
          idDialog === data._id
            ? [...data.actions, ...tempActionState]
            : data.actions,
        actionsArchived: data.actionsArchived,
        actionsComplete: data.actionsComplete,
        archive: data.archive,
        habits: data.habits,
        habitsArchived: data.habitsArchived,
        habitsComplete: data.habitsComplete,
        increase: data.increase,
        mySelf: data.mySelf,
        others: data.others,
        preSelectedGrowth: data.preSelectedGrowth,
        preSelectedGrowthComplete: data.preSelectedGrowthComplete,
        questionCategory: data.questionCategory,
        questionCategoryId: data.questionCategoryId,
        status: data.status,
        tbd: data.tbd,
        uid: data.uid,
        url: data.url,
        _id: data._id,
      });
    });
    disapatch(storeGetGrowthData(tempGetGrowthCalendarData));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: questionCategoryid,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        console.log(res);
        let growthData = {
          uid: localStorage.getItem("userDetail"),
          archive: false,
          status: "IN_PROGRESS",
        };
        if (createActiondata) {
          getGrowthData(growthData)
            .then((res) => {
              disapatch(storeGetGrowthData(res?.data));
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              disapatch(updateIsGetGrowthData(true));
            });
        }
        disapatch(updateIsAddMore(true));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const addMoreHabitHandler = (habits) => {
    const addMoreHabitState = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: questionCategoryid,
      type: "HABIT",
      habit: [habits],
    };
    let tempGetGrowthCalendarData = [];
    getAllGrowthData?.GetGrowthData?.map((data) => {
      tempGetGrowthCalendarData.push({
        actions: data.actions,
        actionsArchived: data.actionsArchived,
        actionsComplete: data.actionsComplete,
        archive: data.archive,
        habits: [...data.habits, habits],
        habitsArchived: data.habitsArchived,
        habitsComplete: data.habitsComplete,
        increase: data.increase,
        mySelf: data.mySelf,
        others: data.others,
        preSelectedGrowth: data.preSelectedGrowth,
        preSelectedGrowthComplete: data.preSelectedGrowthComplete,
        questionCategory: data.questionCategory,
        questionCategoryId: data.questionCategoryId,
        status: data.status,
        tbd: data.tbd,
        uid: data.uid,
        url: data.url,
        _id: data._id,
      });
    });
    disapatch(storeGetGrowthData(tempGetGrowthCalendarData));
    goalCreateHabitAction(addMoreHabitState)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    disapatch(updateHabitAddMore(true));
    setIsHabitOpen(false);
  };
  return (
    <Grid
      container
      spacing={2}
      style={{
        overflowY: "auto",
        // height: favDiamension.length > 2 ? "490px" : "auto",
        width: fullscreen ? "350px" : "100%",
      }}
      onDragLeave={(e) => dragLeave(e)}
      onDragEnter={(e) => dragEnter(e)}
      onDragEnd={(e) => dragEnd(e)}
      onDragOver={(e) => dragOver(e)}
      onDrop={(e) => drop(e, false, "IN_PROGRESS")}
    >
      {getAllGrowthData?.GetGrowthData.length
        ? getAllGrowthData?.GetGrowthData?.map((data, index) => {
            const {
              questionCategory,
              questionCategoryId,
              preSelectedGrowth,
              actions,
              habits,
              preSelectedGrowthComplete,
              _id,
            } = data;
            return (
              <Grid
                item
                xl={fullscreen ? 12 : 12}
                lg={fullscreen ? 12 : 12}
                md={fullscreen ? 12 : 12}
                sm={12}
                xs={12}
                key={index}
              >
                <Accordion
                  sx={{
                    border: "1px solid #F3F3F3",
                    borderRadius: "16px !important",
                    padding: "2.5px 0px",
                  }}
                  className={questionCategoryid ? index : expanded}
                  expanded={expanded === questionCategoryId}
                  onChange={handleChange(questionCategoryId, _id)}
                >
                  <AccordionSummary
                    expandIcon={<img src={ExpandMore} />}
                    className={classes.AccordianSummery}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                      "& .Mui-expanded": {
                        margin: "12px 0px !important",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img src={Engagement} />
                        <Typography
                          variant="body2"
                          sx={{
                            marginLeft: "12px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {questionCategory}
                        </Typography>
                      </div>
                      <div
                        style={{
                          marginRight: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <img src={CheckboxEndIcon} />
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginLeft: "10px",
                      }}
                    >
                      Growth areas
                    </Typography>
                    <div style={{ marginTop: "17px" }}>
                      <Typography
                        variant="body2"
                        className={classes.checkBoxText}
                        style={{ color: "#434243" }}
                      >
                        {" "}
                        Selected
                      </Typography>
                      {preSelectedGrowth.map((item, index) => {
                        if (item.active === "true") {
                          return (
                            <div className={classes.customRadio} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "all-scroll",
                                }}
                                draggable
                                onDragStart={(e) =>
                                  dragStart(e, {
                                    questionCategoryId: questionCategoryId,
                                    text: item.text,
                                    type: "GA",
                                    name: questionCategory,
                                    id: _id,
                                    preSelectedGrowth: preSelectedGrowth,
                                    preSelectedGrowthComplete:
                                      preSelectedGrowthComplete,
                                  })
                                }
                                onDragEnter={(e) => {
                                  dragEnter(e, index);
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.checkBoxText}
                                >
                                  {" "}
                                  {item.text}
                                </Typography>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <CustomShedualButtonAddMore
                        onClick={() => {
                          setisAddMoreGrowthAreaOpen(true);
                          setidDialog(_id);
                        }}
                      >
                        Add more +
                      </CustomShedualButtonAddMore>
                    </div>
                    {isAddMoreGrowthAreaOpen && idDialog === _id && (
                      <CustomAddNewGoalDialog
                        open={isAddMoreGrowthAreaOpen}
                        onClose={() => {
                          setisAddMoreGrowthAreaOpen(false);
                        }}
                      >
                        <MainCard
                          sx={{
                            minWidth: 250,
                            borderRadius: "40px ",
                            marginInline: "auto",
                            height: "652px !important",
                          }}
                        >
                          <CardContent>
                            <GrowthAreaDialog
                              selectedQuestionCategoriesId={questionCategoryId}
                              goalName={questionCategory}
                              setisAddMoreGrowthAreaOpen={
                                setisAddMoreGrowthAreaOpen
                              }
                              preSelectedGrowth={preSelectedGrowth}
                              preSelectedGrowthComplete={
                                preSelectedGrowthComplete
                              }
                              addNewGrowthAreaHandler={addNewGrowthAreaHandler}
                            ></GrowthAreaDialog>
                          </CardContent>
                        </MainCard>
                      </CustomAddNewGoalDialog>
                    )}
                    <div style={{ marginTop: "24px" }}>
                      <Typography
                        variant="body2"
                        className={classes.checkBoxText}
                        style={{ color: "#434243" }}
                      >
                        {" "}
                        Liked actions
                      </Typography>
                      {actions.map((item, index) => {
                        if (item.active === "true") {
                          return (
                            <div className={classes.customRadio} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                draggable
                                onDragStart={(e) =>
                                  dragStart(e, {
                                    questionCategoryId: questionCategoryId,
                                    text: item.actionName,
                                    actionDetail: item.actionDetail,
                                    executionDate: item.executionDate,
                                    executionDateEnd: item.executionDateEnd,
                                    actionId: item.actionId,
                                    type: "ACTION",
                                    name: questionCategory,
                                    id: _id,
                                    actions: actions,
                                  })
                                }
                                onDragEnter={(e) => {
                                  dragEnter(e, index);
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.checkBoxText}
                                >
                                  {" "}
                                  {item.actionName}
                                </Typography>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <CustomShedualButtonAddMore
                        onClick={() => {
                          setIsActionOPen(true);
                          setidDialog(_id);
                        }}
                      >
                        Add more +
                      </CustomShedualButtonAddMore>
                    </div>
                    {isActionOPen && idDialog === _id && (
                      <CustomAddNewGoalDialog
                        open={isActionOPen}
                        onClose={() => {
                          setIsActionOPen(false);
                        }}
                      >
                        <ActionDialog
                          selectedQuestionCategoriesId={questionCategoryId}
                          goalName={questionCategory}
                          setIsActionOPen={setIsActionOPen}
                          addMoreActionHandler={addMoreActionHandler}
                        ></ActionDialog>
                      </CustomAddNewGoalDialog>
                    )}
                    <div style={{ marginTop: "24px" }}>
                      <Typography
                        variant="body2"
                        className={classes.checkBoxText}
                        style={{ color: "#434243" }}
                      >
                        {" "}
                        Liked habits
                      </Typography>
                      {habits.map((item, index) => {
                        if (item.active === "true") {
                          return (
                            <div className={classes.customRadio} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                draggable
                                onDragStart={(e) =>
                                  dragStart(e, {
                                    questionCategoryId: questionCategoryId,
                                    text: item.habitName,
                                    actionDetail: item.habitDetail,
                                    executionDate: item.executionDate,
                                    executionDateEnd: item.executionDateEnd,
                                    habitId: item.habitId,
                                    type: "habit",
                                    name: questionCategory,
                                    id: _id,
                                    habits: habits,
                                  })
                                }
                                onDragEnter={(e) => {
                                  dragEnter(e, index);
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.checkBoxText}
                                >
                                  {" "}
                                  {item.habitName}
                                </Typography>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <CustomShedualButtonAddMore
                        onClick={() => {
                          setIsHabitOpen(true);
                          setidDialog(_id);
                        }}
                      >
                        Add more +
                      </CustomShedualButtonAddMore>
                    </div>
                    {isHabitOpen && idDialog === _id && (
                      <CustomAddNewGoalDialog
                        open={isHabitOpen}
                        onClose={() => {
                          setIsHabitOpen(false);
                        }}
                      >
                        <HabitDialog
                          selectedQuestionCategoriesId={questionCategoryId}
                          goalName={questionCategory}
                          setIsHabitOpen={setIsHabitOpen}
                          addMoreHabitHandler={addMoreHabitHandler}
                        ></HabitDialog>
                      </CustomAddNewGoalDialog>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export default FeaturedGoals;
