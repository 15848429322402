// import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//   categoryid: "",
// };
// export const categoriesIdSlice = createSlice({
//   name: "categoriesIdSlice",
//   initialState,
//   reducers: {
//     updateCategoryId: (state, action) => {
//       state.categoryid = action.payload;
//     },
//   },
// });
// // Action creators are generated for each case reducer function
// export const { updateCategoryId } = categoriesIdSlice.actions;
// export default categoriesIdSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  categoryid: "",
};

export const categoriesIdSlice = createSlice({
  name: "categoriesIdSlice",
  initialState,
  reducers: {
    updateCategoryId: (state, action) => {
      state.categoryid = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCategoryId } = categoriesIdSlice.actions;

export default categoriesIdSlice.reducer;
