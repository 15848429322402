import { GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";

export const facebookProvider = new FacebookAuthProvider().setCustomParameters({
  prompt: "select_account",
  display: "popup",
});
export const googleProvider = new GoogleAuthProvider().setCustomParameters({
  prompt: "select_account",
});

export const appleProvider = new OAuthProvider("apple.com")