import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Typography,
  IconButton,
  Chip,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import XP from "../../assets/Svg/XP.svg";
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from "react";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import UncheckCheckbox from "../../assets/Svg/UncheckCheckbox.svg";
import { ReactComponent as Sparkles } from "../../assets/Svg/Sparkles.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import classes from "./Accordian.module.css";
import { CustomShedualButton } from "../GoalComponent/GoalButton";
import styled from "@emotion/styled";
import {
  DuplicateSnackbar,
  EditActionSnakebar,
  EditRemoveSnackbar,
} from "../GoalComponent/GoalDialog";
import useCustomNotification from "../../Hooks/useCustomNotification";
import EditGoal from "../EditRemoveGoal/EditGoal/EditGoal";
import { useDispatch, useSelector } from "react-redux";
import ActionDialog from "../GoalAddMoreDialog/ActionDialog/ActionDialog";
import {
  goalCreateHabitAction,
  goalDeleteHabitAction,
  goalDuplicateHabitAction,
  goalUpdateHabitAction,
  updateHabitActionCalender,
} from "../../services/auth";
import {
  updateActionData,
  updateisCompeletedAction,
  updateScheduleState,
  updateIsAddMore,
} from "../../Store/Reducers/goalActionData";
import moment from "moment";
import EditAction from "../EditRemoveGoal/EditAction/EditAction";
import GoalDateTimePicker from "../EditRemoveGoal/GoalDateTimePicker/GoalDateTimePicker";
import DuplicateGrowthArea from "../EditRemoveGoal/DuplicateGrowthArea/DuplicateGrowthArea";
import SuggestedGrowthArea from "../GrowthDialog/SuggestedGrowthArea";
import { v4 as uuidv4 } from "uuid";
import useCustomNotificationActionHabit from "../../Hooks/useCustomNotiFicationActionHabit";
import useCustomNotificationColourPick from "../../Hooks/useCustomNotificationColourPick";
import { FindAndGetXPLevel, randomXP } from "../../utils";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
function ActionAccordian({ selectedQuestionCategoriesId, goalName, actionRecommended = [], imageCompo }) {
  const { growthAreaDataSlice, ActiondataSlice, userProfile: { userData = {} } } = useSelector(
    (state) => state
  );

  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const [actionAccordian, setActionAccordian] = useState(false);
  const [isActionOPen, setIsActionOPen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [idDialog, setidDialog] = useState("");
  const [actionIds, setActionId] = useState("");
  const [selectedAction, setSelectedAction] = useState({});
  const [isEditActionOpen, setisEditActionOpen] = useState(false);
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [actionState, setActionState] = useState({
    actionName: "",
    actionDetail: "",
    executionDate: "",
    executionDateEnd: "",
    actionId: "",
    scheduled_type: "DEFAULT",
  });
  const [isCompoletedMenuOpen, setIsCompoletedMenuOpen] = useState(false);
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const {
    NotificationContentActionHabitJSX,
    customNotificationActionHabit,
    setCustomNotificationActionHabit,
  } = useCustomNotificationActionHabit();
  const {
    NotificationContentColourPickJSX,
    customNotificationColourPick,
    setCustomNotificationColourPick,
  } = useCustomNotificationColourPick();
  const dispatch = useDispatch();
  const ActionAccordianHandler = (panel) => (event, isExpanded) => {
    setActionAccordian(isExpanded ? panel : false);
  };
  const addMoreActionHandler = (actionState) => {
    let tempActionState = [];
    // let tempActionStateForRedux = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
      });
    });
    // actionState.forEach((item) => {
    //   tempActionStateForRedux.push({
    //     actionDetail: item?.actionDetail,
    //     // actionId: uuidv4(),
    //     actionName: item.actionName,
    //     status: "IN-PROGRESS",
    //     executionDate: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //     executionDateEnd: moment(new Date())
    //       .add({ hours: 1 })
    //       .format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //   });
    // });
    // let tempActionAddedAction = [
    //   ...ActiondataSlice.actionData,
    //   ...tempActionStateForRedux,
    // ];
    // dispatch(updateActionData(tempActionAddedAction));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        dispatch(updateIsAddMore(true));
      })
      .catch((err) => console.log(err))
      .finally(() => { });
  };
  const addActionHandler = (actionState) => {
    let tempActionState = [];
    // let tempActionStateForRedux = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
      });
    });
    // actionState.forEach((item) => {
    //   tempActionStateForRedux.push({
    //     actionDetail: item?.actionDetail,
    //     // actionId: uuidv4(),
    //     actionName: item.actionName,
    //     status: "IN-PROGRESS",
    //     executionDate: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //     executionDateEnd: moment(new Date())
    //       .add({ hours: 1 })
    //       .format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //   });
    // });
    // let tempActionAddedAction = [
    //   ...ActiondataSlice.actionData,
    //   ...tempActionStateForRedux,
    // ];
    // dispatch(updateActionData(tempActionAddedAction));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        dispatch(updateIsAddMore(true));
      })
      .catch((err) => console.log(err))
      .finally(() => { });
  };
  const updateProfileXp = (xp, add) => {
    const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
    dispatch(updateUserCions(updatedXp))
  }
  const makeAPIcall = (action) => {
    let createActionData;
    if (action.calenderStatus !== "IN_PROGRESS") {
      createActionData = {
        uid: localStorage.getItem("userDetail"),
        id: action.actionId,
        type: "ACTION",
        xpChange: "INCREASE",
        action: {
          scheduled_type: action.scheduled_type,
          // active: "false",
          colour: action.colour,
          textColour: action.textColour,
          executionDate: action.executionDate,
          executionDateEnd: action.executionDateEnd,
          calenderStatus: "COMPLETE",
          status: "COMPLETE",
          xpChange: "INCREASE"
        },
      };
    } else {
      createActionData = {
        uid: localStorage.getItem("userDetail"),
        id: action.actionId,
        type: "ACTION",
        xpChange: "DECREASE",
        action: {
          scheduled_type: action.scheduled_type,
          // active: "false",
          colour: action.colour,
          textColour: action.textColour,
          executionDate: action.executionDate,
          executionDateEnd: action.executionDateEnd,
          calenderStatus: "IN_PROGRESS",
          status: "IN_PROGRESS",
          xpChange: "DECREASE"
        },
      };
    }
    console.log("createActionData ========", createActionData);
    if (Object.keys(createActionData).length > 0) {
      updateHabitActionCalender(createActionData)
        .then((res) => {
          //setIsSetDurationAction(false);
          // this.callToGetData(
          //   this.state.selectedDate,
          //   this.state.selectedDate
          // );
          const xp = FindAndGetXPLevel(action.level, false)
          updateProfileXp(xp, createActionData.action.status !== "IN_PROGRESS")
          console.log("res = ", res);
        })
        .catch((err) => {
          console.log("err = ", err);
        });
    }
  }
  const unCheckToCheckActionHandler = (data) => {
    let tempAction = {};
    const user = localStorage.getItem("userDetail");
    const goalId = data?.actionId;
    tempAction = {
      actionName: data.actionName,
      actionDetail: data.actionDetail,
      level: data.level,
      actionId: [
        {
          actionDetail: data.actionDetail,
          actionId: data?.actionId,
          status: "COMPLETE",
          level: data.level,
        },
      ],
      executionDate: data?.executionDate,
      executionDateEnd: data?.executionDateEnd,

      status: "COMPLETE",
    };
    console.log("data", data);
    const findData = ActiondataSlice.compeletedAction?.find(
      (o) => o.actionName === data.actionName
    );
    if (findData) {
      let tempActionCompelete = [];
      ActiondataSlice.compeletedAction?.forEach((element) => {
        tempActionCompelete.push({
          actionName: element.actionName,
          count:
            data.actionName === element.actionName
              ? element.count + 1
              : element.count,
          _id: { actionName: element.actionName },
          actionId: [
            ...element.actionId,
            {
              actionDetail: tempAction.actionDetail,
              actionId: tempAction.actionId,
              status: "COMPLETE",
              calenderStatus: "COMPLETE",
              level: tempAction.level,
              scheduled_type: data.scheduled_type,
              executionDate
                : data.executionDateEnd,
              executionDateEnd
                : data.executionDateEnd,
            },
          ],
        });
      });
      dispatch(updateisCompeletedAction(tempActionCompelete));
    } else {
      const tempActionCompelete = [
        ...ActiondataSlice.compeletedAction,
        {
          actionName: data.actionName,
          actionDetail: data.actionDetail,
          count: 1,
          actionId: [
            {
              actionDetail: data.actionDetail,
              actionId: data?.actionId,
              status: "COMPLETE",
              level: tempAction.level,
              scheduled_type: data.scheduled_type,
              executionDate
                : data.executionDateEnd,
              executionDateEnd
                : data.executionDateEnd,
                calenderStatus: "COMPLETE",

            },
          ],
          executionDate: data?.executionDate,
          executionDateEnd: data?.executionDateEnd,
          status: "COMPLETE",
          calenderStatus: "COMPLETE",
          level: tempAction.level
        },
      ];
      dispatch(updateisCompeletedAction(tempActionCompelete));
    }
    const tempActionData = ActiondataSlice?.actionData.filter(
      (item) => item.actionId !== data.actionId
    );

    dispatch(updateActionData(tempActionData));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempAction,
    };
    makeAPIcall({
      ...data,
      calenderStatus: "COMPLETE",
    })
    // goalUpdateHabitAction(updatedActionState)
    //   .then((res) => {
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
  };
  const checkToUncheckActionHandler = (data) => {
    console.log("data", data);
    const user = localStorage.getItem("userDetail");
    const goalId = data?.actionId[0]?.actionId;
    const tempAction = {
      actionName: data.actionName,
      actionDetail: data?.actionId[0]?.actionDetail,
      actionId: data?.actionId[0]?.actionId,
      level: data.level,
      executionDate: data.executionDate ? data.executionDate : data.actionId[0] && data.actionId[0].executionDate ? data.actionId[0] && data.actionId[0].executionDate : moment(),
      scheduled_type: data.scheduled_type ? data.scheduled_type : data.actionId[0] && data.actionId[0].scheduled_type ? data.actionId[0] && data.actionId[0].scheduled_type : "DEFAULT",
      executionDateEnd: data.executionDateEnd ? data.executionDateEnd : data.actionId[0] && data.actionId[0].executionDateEnd ? data.actionId[0] && data.actionId[0].executionDateEnd : moment(),
      status: "IN_PROGRESS",
      calenderStatus: "IN_PROGRESS",
    };
    const tempActionCompeleteToProgress = [
      ...ActiondataSlice.actionData,
      {
        ...tempAction,
      
      },
    ];
    dispatch(updateActionData(tempActionCompeleteToProgress));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempAction,
    };
    if (data.count > 1) {
      let tempActionCompelete = [];
      ActiondataSlice.compeletedAction.forEach((element) => {
        tempActionCompelete.push({
          actionName: element.actionName,
          count:
            data?.actionId[0]?.actionId === element.actionId[0].actionId
              ? element.count - 1
              : element.count,
          actionDetail: element?.actionId[0]?.actionDetail,
          actionId: [
            ...element.actionId,
            {
              actionDetail: tempAction.actionDetail,
              actionId: tempAction.actionId,
              status: "COMPLETE",
              calenderStatus: "COMPLETE",
            },
          ],
          executionDate: data?.executionDate,
          executionDateEnd: data?.executionDateEnd,
          status: "IN_PROGRESS",
          calenderStatus: "IN_PROGRESS",
        });
      });
      dispatch(updateisCompeletedAction(tempActionCompelete));
    } else {
      const tempCompletedAction = ActiondataSlice?.compeletedAction.filter(
        (item) => item.actionId !== data.actionId
      );
      dispatch(updateisCompeletedAction(tempCompletedAction));
    }
    makeAPIcall({
      ...tempAction,
      actionId: data?.actionId[0]?.actionId
    })

    // goalUpdateHabitAction(updatedActionState)
    //   .then((res) => {
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
  };
  const removeActionHandler = () => {
    const tempActionData = ActiondataSlice?.actionData.filter(
      (item) => item.actionId !== selectedAction.actionId
    );
    dispatch(updateActionData(tempActionData));
    setIsEditMenuOpen(false);
    const removeUserdata = {
      ids: [selectedAction?.actionId],
      uid: localStorage.getItem("userDetail"),
      type: "ACTION",
    };
    goalDeleteHabitAction(removeUserdata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editActionHandler = () => {
    setActionState({
      actionName: selectedAction?.actionName,
      actionDetail: selectedAction?.actionDetail,
      executionDate: selectedAction?.executionDate?.replace("Z", ""),
      executionDateEnd: selectedAction?.executionDateEnd?.replace("Z", ""),
      actionId: selectedAction?.actionId,
    });
    setisEditActionOpen(true);
    setIsEditMenuOpen(false);
  };
  const editActionTextHandler = () => {
    const user = localStorage.getItem("userDetail");
    const goalId = actionState?.actionId;
    const tempEditedAction = {
      actionName: actionState.actionName,
      actionDetail: actionState.actionDetail,
      actionId: actionState?.actionId,
      executionDate: actionState.executionDate
        ? actionState.executionDate
        : `${new Date().format("yyyy-MM-DDThh:mm:ss")}.000`,
      executionDateEnd: actionState.executionDateEnd
        ? actionState.executionDateEnd
        : `${new Date().format("yyyy-MM-DDThh:mm:ss")}.000`,
    };
    let tempAction = [];
    ActiondataSlice?.actionData?.map((item) => {
      return tempAction.push({
        actionName:
          item.actionId === actionState.actionId
            ? actionState.actionName
            : item.actionName,
        actionDetail:
          item.actionId === actionState.actionId
            ? actionState.actionDetail
            : item.actionDetail,
        status: "IN_PROGRESS",
        actionId: item.actionId,
        executionDate:
          item.actionId === actionState.actionId
            ? actionState.executionDate
            : item.executionDate,
        executionDateEnd:
          item.actionId === actionState.actionId
            ? actionState.executionDateEnd
            : item.executionDateEnd,
        calenderStatus: "IN_PROGRESS"
      });
    });
    dispatch(updateActionData(tempAction));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempEditedAction,
    };
    goalUpdateHabitAction(updatedActionState)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
    setisEditActionOpen(false);
  };
  const duplicateActionHandler = () => {
    let duplicateActiondata = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: {
        actionName: selectedAction.actionName,
      },
    };
    let tempActionDataInProgress = {
      actionDetail: selectedAction?.actionId[0]?.actionDetail,
      actionName: selectedAction.actionName,
      executionDate: selectedAction?.actionId[0]?.executionDate,
      executionDateEnd: selectedAction.actionId[0]?.executionDateEnd,
      actionId: selectedAction?.actionId[0]?.actionId,
      status: "IN_PROGRESS",
      calenderStatus: selectedAction.calenderStatus
    };
    dispatch(
      updateActionData([
        ...ActiondataSlice?.actionData,
        tempActionDataInProgress,
      ])
    );
    goalDuplicateHabitAction(duplicateActiondata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsCompoletedMenuOpen(false);
  };
  const editShedualdataHandler = (executionStart, executionEnd) => {
    const user = localStorage.getItem("userDetail");
    const goalId = ActiondataSlice?.scheduleState?.actionId;
    const tempEditedAction = {
      actionName: ActiondataSlice?.scheduleState.actionName,
      actionDetail: ActiondataSlice?.scheduleState.actionDetail,
      executionDate: executionStart,
      executionDateEnd: executionEnd,
      scheduled_type: "TIME_BASED",
      status: "IN_PROGRESS",
      calenderStatus: "IN_PROGRESS",
    };
    let tempAction = [];
    ActiondataSlice?.actionData?.map((item) => {
      return tempAction.push({
        actionName:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? ActiondataSlice?.scheduleState?.actionName
            : item.actionName,
        actionDetail:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? ActiondataSlice?.scheduleState?.actionDetail
            : item.actionDetail,
        status: "IN_PROGRESS",
        level: item.level,
        actionId: item.actionId,
        executionDate:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? executionStart
            : item.executionDate,
        executionDateEnd:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? executionEnd
            : item.executionDateEnd,
        scheduled_type:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? "TIME_BASED"
            : item.scheduled_type,
            calenderStatus: "IN_PROGRESS",

          
      });
    });
    dispatch(updateActionData(tempAction));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempEditedAction,
    };
    goalUpdateHabitAction(updatedActionState)
      .then((res) => {
        // console.log(res);
        dispatch(updateScheduleState({}));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
    setisEditActionOpen(false);
  };
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;
  const tempGoalaction = [...(ActiondataSlice?.actionData || []), ...(ActiondataSlice?.compeletedAction || [])];
  const getUniquePreselectedGrowthArea = (array1) => {
    const recommended = [...actionRecommended]
    return recommended.filter(obj2 => array1.every((obj1) => obj1.actionName !== obj2.point))
  };
  return (
    <>
      <Accordion
        expanded={actionAccordian === "panel"}
        onChange={ActionAccordianHandler("panel")}
        sx={{ position: "relative" }}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
          className={classes.subAccordianSummery}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            "& .Mui-expanded": {
              margin: "0px 0px !important",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
            },
          }}
        //
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Actions
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.GrowthAccordianDetailContainer}>
          <div className={classes.selectedWrapper}>
            <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
              Selected
            </Typography>
            <CustomShedualButton
              style={{
                maxWidth: "91px",
                background: "#F9F9F9",
                color: "#190D1A",
              }}
              onClick={() => {
                setIsActionOPen(true);
                setidDialog(growthAreaDataSlice?.goalID);
              }}
            >
              Add more +
            </CustomShedualButton>
          </div>
          <div>
            {ActiondataSlice?.actionData?.map((data, index) => {
              const newActionsData = { ...data };
              if (newActionsData.scheduled_type === "DEFAULT") {
                newActionsData.executionDate = moment().format()
                newActionsData.executionDateEnd = moment().add(1, 'hour').format()
              }
              const { actionId, actionName, actionDetail, level, status } = newActionsData;
              const foundXp = FindAndGetXPLevel(level, false)
              return (
                <div className={classes.customRadioActionGroup}>
                  {foundXp && (
                    <div className={classes.schedualWrapper}>
                      <Chip
                        label={foundXp}
                        avatar={<Avatar alt="Natacha" sx={{ width: '20px', height: '20px' }} src={XP} />}
                        className={classes.backgroundChip}
                      />
                    </div>
                  )}

                  <div className={classes.customRadioActionAction} key={index}>

                    <div className={classes.actionNameWrapper}>
                      <img
                        src={UncheckCheckbox}
                        alt="CheckboxStartIcon"
                        onClick={() => {
                          unCheckToCheckActionHandler(newActionsData);
                        }}
                      />

                      <Typography
                        variant=" body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        {actionName}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <div style={{ width: "100%" }}>
                        {data.scheduled_type === "DEFAULT" && (
                          <CustomShedualButton
                            onClick={() => {
                              setidDialog(actionId);
                              setIsSchedule(true);
                              dispatch(
                                updateScheduleState({
                                  actionName: data.actionName,
                                  actionDetail: data.actionDetail,
                                  executionDate: data?.executionDate
                                    ? moment(data?.executionDate).format()
                                    : new Date(),
                                  executionDateEnd: data.executionDateEnd
                                    ? data.executionDateEnd
                                    : data?.executionDate
                                      ? data?.executionDate
                                      : new Date(),
                                  actionId: data.actionId,
                                })
                              );
                            }}
                          >
                            Schedule
                          </CustomShedualButton>
                        )}

                      </div>
                      <img
                        style={{ width: "14px" }}
                        src={CheckboxEndIcon}
                        alt="CheckboxEndIcon"
                        onClick={() => {
                          setCustomNotificationActionHabit(true);
                          setActionId(index);
                        }}
                      />
                      <img
                        onClick={() => {
                          setActionId(index);
                          setIsEditMenuOpen(true);
                          setSelectedAction({
                            actionName: data.actionName,
                            actionDetail: data.actionDetail,
                            actionId: data.actionId,
                            executionDate: data.executionDate,
                            executionDateEnd: data.executionDateEnd
                              ? data.executionDateEnd
                              : data.executionDate,
                          });
                        }}
                        style={{
                          margin: "4px 0px 0px -7px",
                          height: "18px",
                        }}
                        src={tripleDot}
                        alt="ExpandMore"
                      />
                    </div>
                    {actionIds === index && (
                      <>
                        <EditRemoveSnackbar
                          open={isEditMenuOpen}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          onClose={() => {
                            setIsEditMenuOpen(false);
                          }}
                          action={
                            <EditGoal
                              editGrowthAreaTextHandler={editActionHandler}
                              removeGrowthAreaHandler={removeActionHandler}
                            />
                          }
                        ></EditRemoveSnackbar>
                        <EditActionSnakebar
                          open={isEditActionOpen}
                          onClose={() => {
                            editActionTextHandler();
                          }}
                          action={
                            <EditAction
                              actionState={actionState}
                              setActionState={setActionState}
                              selectedAction={selectedAction}
                              editActionTextHandler={editActionTextHandler}
                            />
                          }
                        ></EditActionSnakebar>
                        {customNotificationActionHabit && index === actionIds && (
                          <NotificationContentActionHabitJSX
                            message={actionDetail}
                            isThumbIcon={false}
                            position="top"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
            SSD recommends
          </Typography>
          <div className={classes.customRadioAction}>
            <div className={classes.actionNameWrapper}>
              <div className={classes.PlusIconWrapper}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={PlusLargeIcon}
                  alt="CheckboxStartIcon"
                />
              </div>
              <Typography variant=" body2" className={classes.checkBoxText}>
                {" "}
                Send flowers to mom
              </Typography>
            </div>
            <div className={classes.schedualWrapper}>
              <div style={{ width: "100%" }}>
                <CustomShedualButton>Schedule</CustomShedualButton>
              </div>
             
            </div>
          </div> */}
          <div style={{ position: "relative" }}>
            <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }} className={classes.recommendedClass}>
              <span style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}>
                <Sparkles /> Recommended

              </span>
              <span style={{
                marginRight: '20px',
                background: '#F7F2FE',
                padding: '0 5px',
                borderRadius: '4px',
              }}>
                {getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length ? getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length : ''}
              </span>
            </Typography>
            {getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length === 0 ? (
              <div className={classes.dataNotFoundSubWrapper}>
                <Typography variant="body2">
                  Recommended actions area not found
                </Typography>
              </div>
            ) : (
              getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended)?.map((data, index) => {
                const { point: text, _id } = data;
                const randomXPObject = randomXP()
                return (
                  <div className={classes.customRadioAction} style={{
                    alignItems: 'center',
                    cursor: 'pointer'
                  }} onClick={e => {
                    addActionHandler([{
                      actionId: _id,
                      actionName: text,
                      actionDetail: "",
                      active: "true",
                      level: randomXPObject.level,
                      scheduled_type: "DEFAULT",
                    }])
                  }} key={index}>
                    <div className={classes.actionNameWrapper}>
                      <IconButton aria-label="add-icon" color="primary">
                        <AddIcon />
                      </IconButton>
                      <Typography
                        variant=" body2"
                        className={classes.checkBoxText}
                      >
                        {" "}
                        {text}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <Chip
                        label={randomXPObject.xp}
                        avatar={<Avatar alt="Natacha" sx={{ width: '20px', height: '20px' }} src={XP} />}
                        className={classes.backgroundChip}
                      />
                    </div>

                  </div>
                );
              })
            )}
          </div>
          <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
            Completed
          </Typography>
          {ActiondataSlice?.compeletedAction?.length === 0 ? (
            <div className={classes.dataNotFoundSubWrapper}>
              <Typography variant="body2">
                Completed Action not found
              </Typography>
            </div>
          ) : (
            ActiondataSlice?.compeletedAction?.map((data, index) => {
              const { actionId, _id, actionName, count, level } = data;
              return (
                <div className={classes.customRadioActionGroup}>
                  <div className={classes.customRadioActionAction} key={index}>
                    <div className={classes.actionNameWrapper}>
                      {count > 1 ? (
                        <div className={classes.duplicateTextWrapper}>
                          <img
                            src={CheckedCheckbox}
                            className={classes.checkBoxIcon}
                            alt=""
                            onClick={() => {
                              checkToUncheckActionHandler(data);
                            }}
                          />
                          <Typography variant="body3">{count}x</Typography>
                        </div>
                      ) : (
                        <img
                          src={CheckedCheckbox}
                          className={classes.checkBoxIcon}
                          alt=""
                          onClick={() => {
                            checkToUncheckActionHandler(data);
                          }}
                        />
                      )}
                      <Typography
                        variant=" body2"
                        className={classes.checkBoxChekedText}
                      >
                        {" "}
                        {actionName}
                      </Typography>
                    </div>
                    <div className={classes.schedualWrapper}>
                      <img
                        style={{ width: "14px" }}
                        src={CheckboxEndIcon}
                        alt="CheckboxEndIcon"
                        onClick={() => {
                          setCustomNotification(true);
                          setActionId(index);
                        }}
                      />
                      <img
                        style={{
                          margin: "4px 0px 0px -7px",
                          height: "18px",
                        }}
                        src={tripleDot}
                        onClick={() => {
                          setActionId(index);
                          setIsCompoletedMenuOpen(true);
                          setSelectedAction(data);
                        }}
                        alt="ExpandMore"
                      />
                    </div>
                    {actionIds === index && isCompoletedMenuOpen && (
                      <DuplicateSnackbar
                        open={isCompoletedMenuOpen}
                        onClose={() => {
                          setIsCompoletedMenuOpen(false);
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        action={
                          <DuplicateGrowthArea
                            duplicateGrowthAreaHandler={duplicateActionHandler}
                          />
                        }
                      ></DuplicateSnackbar>
                    )}
                    {customNotification && actionIds === index && (
                      <NotificationContentJSX
                        message={actionId[0].actionDetail}
                        isThumbIcon={false}
                        position="top"
                      />
                    )}
                  </div>
                </div>
              );
            })
          )}
        </AccordionDetails>
        {isSchedule && idDialog === ActiondataSlice?.scheduleState?.actionId && (
          <GoalDateTimePicker
            isAskOtherModalOpen={isAskOtherModalOpen}
            setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            setActionState={setActionState}
            actionState={actionState}
            editActionTextHandler={editShedualdataHandler}
            isSchedule={isSchedule}
            setIsSchedule={setIsSchedule}
            setCustomNotificationColourPick={setCustomNotificationColourPick}
          />
        )}
      </Accordion>
      {isActionOPen && idDialog === growthAreaDataSlice.goalID && (
        <CustomAddNewGoalDialog
          open={isActionOPen}
          onClose={() => {
            setIsActionOPen(false);
          }}
        >
          <ActionDialog
            selectedQuestionCategoriesId={selectedQuestionCategoriesId}
            goalName={goalName}
            setIsActionOPen={setIsActionOPen}
            addMoreActionHandler={addMoreActionHandler}
            imageCompo={imageCompo}
          ></ActionDialog>
        </CustomAddNewGoalDialog>
      )}
      {/* {isSchedule && idDialog === ActiondataSlice?.scheduleState?.actionId && (
        <GoalDateTimePicker
          isAskOtherModalOpen={isAskOtherModalOpen}
          setIsAskOtherModalOpen={setIsAskOtherModalOpen}
          setActionState={setActionState}
          actionState={actionState}
          editActionTextHandler={editShedualdataHandler}
          isSchedule={isSchedule}
          setIsSchedule={setIsSchedule}
          setCustomNotificationColourPick={setCustomNotificationColourPick}
        />
      )} */}
      {isActionOPen && idDialog === growthAreaDataSlice.goalID && (
        <SuggestedGrowthArea
          suggestedGrowthArea={growthAreaDataSlice?.suggestedGrowthAreaState}
        />
      )}
      {customNotificationColourPick && (
        <NotificationContentColourPickJSX
          message="Start Time should be less than End Time "
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
}

export default ActionAccordian;
