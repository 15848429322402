import React, { useState } from "react";
const useCustomSixCheckBox = (
  checkValueOne,
  checkValueTwo,
  checkValueThree,
  checkValueFour,
  checkValueFive,
  checkValueSix,
  defaultValue = ""
) => {
  let firstCheckBoxValue = checkValueOne;
  let secondCheckBoxValue = checkValueTwo;
  let thirdCheckBoxValue = checkValueThree;
  let fourthCheckBoxValue = checkValueFour;
  let fifthCheckBoxValue = checkValueFive;
  let sixthCheckBoxValue = checkValueSix;
  const [firstCheckBox, setFirstCheckBox] = useState(
    defaultValue === checkValueOne
  );
  const [secondCheckBox, setSecondCheckBox] = useState(
    defaultValue === checkValueTwo
  );
  const [thirdCheckBox, setThirdCheckBox] = useState(
    defaultValue === checkValueThree
  );
  const [fourthCheckBox, setFourthCheckBox] = useState(
    defaultValue === checkValueFour
  );
  const [fifthCheckBox, setFifthCheckBox] = useState(
    defaultValue === checkValueFive
  );
  const [sixthCheckBox, setSixthCheckBox] = useState(
    defaultValue === checkValueSix
  );
  const handleChange = (changeValue) => {
    if (changeValue === firstCheckBoxValue) {
      setFirstCheckBox(true);
      setSecondCheckBox(false);
      setThirdCheckBox(false);
      setFourthCheckBox(false)
      setFifthCheckBox(false);
      setSixthCheckBox(false)
    }
    if (changeValue === secondCheckBoxValue) {
      setSecondCheckBox(true);
      setFirstCheckBox(false);
      setThirdCheckBox(false);
      setFourthCheckBox(false)
      setFifthCheckBox(false);
      setSixthCheckBox(false)
    }
    if (changeValue === thirdCheckBoxValue) {
        setFirstCheckBox(false);
        setSecondCheckBox(false);
        setThirdCheckBox(true);
        setFourthCheckBox(false)
        setFifthCheckBox(false);
        setSixthCheckBox(false)
      }
      if (changeValue === fourthCheckBoxValue) {
        setFirstCheckBox(false);
        setSecondCheckBox(false);
        setThirdCheckBox(false);
        setFourthCheckBox(true)
        setFifthCheckBox(false);
        setSixthCheckBox(false)
      }
      if (changeValue === fifthCheckBoxValue) {
        setFirstCheckBox(false);
        setSecondCheckBox(false);
        setThirdCheckBox(false);
        setFourthCheckBox(false)
        setFifthCheckBox(true);
        setSixthCheckBox(false)
      }
      if (changeValue === sixthCheckBoxValue) {
        setFirstCheckBox(false);
        setSecondCheckBox(false);
        setThirdCheckBox(false);
        setFourthCheckBox(false)
        setFifthCheckBox(false);
        setSixthCheckBox(true)
      }
  };

  return {
    firstCheckBox,
    secondCheckBox,
    thirdCheckBox,
    fourthCheckBox,
    fifthCheckBox,
    sixthCheckBox,
    handleChange,
  };
};

export default useCustomSixCheckBox;
