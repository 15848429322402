import { Box, Typography } from "@mui/material";
import React from "react";
import classes from "./UnspecifiedActionHabit.module.css";
import DoneIcon from "@mui/icons-material/Done";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";

const UnspecifiedActionHabit = ({
  action,
  updateActionData,
  handleIsTrue,
  typeCalendar,
  updateHabitData,
}) => {
  return (
      <Box
        className={classes.action_data}
        onClick={() => {
          if (action.actionName) {
            updateActionData(action);
          } else {
            updateHabitData(action);
          }
        }}
        key={action._id || action.actionName}
        style={{ marginBottom: typeCalendar === "WEEKLY_CALENDAR" && "9px" }}
      >
        <Box
          className={classes.checkBox}
          onClick={() => {
            handleIsTrue(action);
          }}
        >
          {action.calenderStatus === "COMPLETE" ? (
            <DoneIcon className={classes.dropIcon} />
          ) : (
            <></>
          )}
        </Box>
        <div className={classes.textAction}>
          <Typography
            variant="p"
            style={{
              textDecoration:
                action.calenderStatus === "COMPLETE" && "line-through",
            }}
          >
            {action?.actionName
              ? action?.actionName?.substring(0, 17)
              : action?.habitName?.substring(0, 17)}
            <Typography
              variant="p"
              style={{
                textDecoration:
                  action.calenderStatus === "COMPLETE" && "line-through",
                paddingTop: "15px",
              }}
            >
              {action?.actionName
                ? action?.actionName?.length > 17 && "..."
                : action?.habitName?.length > 17 && "..."}
            </Typography>
          </Typography>
        </div>
        {action?.habitName && (
          <AutorenewRoundedIcon
            style={{
              color:"#434243",
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>
  );
};

export default React.memo(UnspecifiedActionHabit);
