import { Divider, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { futureTestCreate } from "../../services/auth";
import DatePicker from "../DatePicker/DatePicker";
import classes from "./CalenderGoal.module.css";

function CalanderGoal({
  goalName, setIsCalenderOpen,
  selectedQuestionCategoriesId,
  setTbdDate,
  tbdDate,
}) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const shedualhandler = () => {
    const futureTestData = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: selectedQuestionCategoriesId,
      date: currentDate,
    };
    setTbdDate([...tbdDate, { date: currentDate }]);
    futureTestCreate(futureTestData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsCalenderOpen(false);
  };
  return (
    <div className={classes.calendarInfo}>
      <DatePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
      <Divider className={classes.divider} />
      {moment(currentDate).diff(new Date(), "days") >= 0 && (
        <div className={classes.scheduleInfoWrapper}>
          <div className={classes.scheduleInfo}>
            <Typography variant="body2" color="#190D1A">
              {goalName}
            </Typography>
            <Typography variant="body3">
              {moment(currentDate).format("LL")}
            </Typography>
          </div>
          <div
            className={classes.scheduleButton}
            // onClick={() => {
            //   setCustomNotification(true)
            // }}
          >
            <Typography
              variant="body3"
              color="#9747FF"
              onClick={shedualhandler}
            >
              Schedule
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default CalanderGoal;
