import React, { useState } from 'react'
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import classes from './ResponsiveSideBar.module.css';
import SideBar from '../SideBar/SideBar';
import { styled } from '@mui/system';

const 
ResponsiveSideBar = () => {
    const [toggelSideMenu, setToggelSideMenu] = useState(false);
    const SideBarToggelButton = styled("div")(({ theme }) => ({
        position: "absolute",
        zIndex: "100",
        top: "20px",
        [theme.breakpoints.up("xl")]: {
          display: "none",
        },
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
        [theme.breakpoints.up("lg")]: {
          display: "none",
        },
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
      }));

      const SideBarContent = styled("div")(({ theme }) => ({
        padding: "20px",
        background: "#FFFFFF",
        height: "100%",
        position: "relative",
    
        [theme.breakpoints.up("xl")]: {},
        [theme.breakpoints.up("lg")]: {},
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {
          display: !toggelSideMenu ? "none" : "block",
        },
      }));

      const toggelMenu = () => {
        setToggelSideMenu(!toggelSideMenu);
      };
  return (
    <>
     <div>
        <SideBarToggelButton
          style={{ left: toggelSideMenu ? "11rem" : "20px" }}
        >
          {toggelSideMenu ? (
            <HighlightOffIcon onClick={toggelMenu} color="primary" />
          ) : (
            <MenuIcon onClick={toggelMenu} color="primary" />
          )}
        </SideBarToggelButton>

        <SideBarContent className={toggelSideMenu ? classes.sideBarToogle : ""}>
          <SideBar />
        </SideBarContent>
      </div>
    </>
  )
}

export default ResponsiveSideBar