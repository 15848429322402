import { Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./StepThree.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import { MultiLineInput } from "../../TestResultsPages/StyledStepper/styledStepper";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { imageURLRender } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
function StepThree({ onNext, onPrevious, getGoalDetail, goalDetail, handleClose, selectedDiamenstion, selectedDiamenstionImage }) {
  const [progress, setprogress] = useState("");
  const onchangeHandler = (e) => {
    // if (progress.length < 100) {
    setprogress(e.target.value);
    getGoalDetail("progress", "");
    // }
  };
  const updateMainState = () => {
    if (progress === "") {
      getGoalDetail("progress", goalDetail?.progress);
    } else {
      getGoalDetail("progress", progress);
    }
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose}/>

      <div className={classes.StepThreeWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.StepThreeSubWrapper}>
          <img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" />
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            You have selected{" "}
            <span style={{ color: "#9747FF" }}>
              {" "}
              {selectedDiamenstion}
            </span>{" "}
          </Typography>
          <Typography
            variant="body4"
            sx={{ margin: "16px 0px", textAlign: "center" }}
          >
            Why is it important for you to make progress on
            {selectedDiamenstion}?
          </Typography>
          <MultiLineInput
            sx={{ maxWidth: "528px !important" }}
            value={progress || goalDetail.progress}
            onChange={onchangeHandler}
            multiline
            size="small"
            fullWidth
            key="pogress"
            type="text"
            placeholder="Tell us briefly"
          />
          {/* <div style={{ width: "100%", padding: "12px 5px" }}>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {goalDetail?.progress ? goalDetail?.progress.length:progress.length}/100
          </Typography>
        </div> */}

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: '0 40px' }}>
          <ButtonGroup
            onNext={onNext}
            onPrevious={onPrevious}
            updateMainState={updateMainState}
            isNextDisable={
              progress === ""
                ? goalDetail?.progress === undefined
                  ? true
                  : false
                : goalDetail?.progress === undefined
                  ? true
                  : false
            }
          />
        </div>
      </div>
    </>

  );
}

export default StepThree;
