import React, { useState } from "react";
import { Grid, MenuItem, OutlinedInput, TextareaAutosize } from "@mui/material";
import classes from "./featureRequest.module.css";
import {
  Input,
  ItemList,
  Selecter,
} from "../../../components/Settings/StyledElement/StyledformInput";
import { SubmitButton } from "../../../components/Button/Button";
import clsx from "clsx";
import { MultiLineInput } from "../../TestResultsPages/StyledStepper/styledStepper";
import { featureRequestData } from "../../../services/auth";
import { facebookSection } from "../../../Constant/SettingConstant";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import useCustomNotification from "../../../Hooks/useCustomNotification";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FeatureRequest() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestFeature, setRequestFeature] = useState({
    featureType: "",
    description: "",
    user_email: "",
  });
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  console.log("requestFeature==>", requestFeature);

  const handleSubmitData = () => {
    console.log("Feedback feature request send:", requestFeature);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = regex.test(requestFeature.user_email);
    console.log("isValidEmail==>", isValidEmail);
    setEmailError(isValidEmail);

    if (isValidEmail) {
      let featureDetails = {
        ...requestFeature,
        uid: localStorage.getItem("userDetail"),
      };
      setIsLoading(true);
      setCustomNotification(true);
      featureRequestData(featureDetails)
        .then((res) => {
          console.log("feature request", res);
          setRequestFeature({
            featureType: "",
            description: "",
            user_email: "",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleInpute = (e) => {
    if (e.target.name === "user_email") {
      setEmailError("");
    }
    setRequestFeature({ ...requestFeature, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Grid className={clsx(!matches && classes.mob_set_grid)}>
        <ItemList
          onChange={handleInpute}
          value={requestFeature.featureType}
          input={<OutlinedInput />}
          name="featureType"
          placeholder="Select feature section"
          select
          disableUnderline={false}
          variant="outlined"
        >
          {facebookSection.map((item, index) => {
            return (
              <MenuItem className={classes.item_list} key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </ItemList>
        <Grid container sx={{ marginBottom: "12px" }}>
          <Grid
            className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
          >
            <MultiLineInput
              sx={{ maxWidth: "100% !important" }}
              value={requestFeature.description}
              onChange={handleInpute}
              name={"description"}
              multiline
              size="small"
              fullWidth
              key="pogress"
              type="text"
              placeholder="Discribe feature functionality"
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <Input
            name="user_email"
            placeholder="Email address"
            value={requestFeature.user_email}
            onChange={handleInpute}
            type="email"
            emailError={emailError}
          />
          <span className={classes.email_error}>
            {emailError === false && "Please add valid email"}
          </span>
        </Grid>
        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <SubmitButton
            isDisabled={
              requestFeature.featureType === "" ||
              requestFeature.user_email === "" ||
              requestFeature.description === ""
            }
            buttonText={"Send"}
            onClick={() => {
              handleSubmitData();
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      {customNotification && (
        <NotificationContentJSX
          message="Feature added successfully"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
}

export default FeatureRequest;
