import { useTheme } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CreateGoal from "../../core-ui/CreateGoal/CreateGoal";
import classes from "./GoalArchive.module.css";
import { getGoals, goalArchive } from "../../services/auth";
import ButtonGroup from "../../components/GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import GoalGroup from "../../assets/Svg/GoalGroup.svg";
import GoalCalibrate from "../../assets/Svg/GoalCalibrate.svg";
import GoalDecrease from "../../assets/Svg/GoalDecrease.svg";
import AcademicIcon from "../../assets/Svg/AcademicIcon.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";

import CircularProgressGoal from "../../components/CircularProgressBar/CircularProgressBarGoal";
import {
  CustomTypography,
  RightSideContentWrapper,
  SchedualdateWrapper,
} from "../../components/GoalComponent/GoalCustomDiv";
import { PersonalChart } from "../../components/PersonalChartGoal/PersonalChart";
import { FriendChart } from "../../components/FriendChartGoal/FriendChart";
import moment from "moment";
import GrowthAreaArchiveAccrordian from "../../components/GoalArchiveAccordian/GrowthAreaArchiveAccrordian";
import { SubmitButtonWithBackGround } from "../../components/Button/Button";
import ActionArchieveAccordian from "../../components/GoalArchiveAccordian/ActionArchieveAccordian";
import HabitArchiveAccordian from "../../components/GoalArchiveAccordian/HabitArchiveAccordian";
import { updateCompeletedHabit } from "../../Store/Reducers/goalHabitData";
import { updateisCompeletedAction } from "../../Store/Reducers/goalActionData";
import { useDispatch } from "react-redux";
function GoalArchive() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const [goalArchiveState, setGoalArchive] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [goalScore, setgoalScore] = useState([]);
  const [isArchiveGoal, setIsArchiveGoal] = useState(false);
  const [othresArray, setOthresArray] = useState([]);
  const [PersonalDataArray, setPersonalDataArray] = useState([]);
  const [isArchiveUpdate, setIsArchiveUpdate] = useState(false);
  const [selectedQuestionCategoriesId, setSelectedQuestionCategoriesId] =
    useState("");
  useEffect(() => {
    const user = localStorage.getItem("userDetail");
    const archive = true;
    const status = "IN_PROGRESS";
    if (!goalArchiveState.length || isArchiveUpdate) {
      getGoals(user, archive, status)
        .then((res) => {
          console.log(res);
          setGoalArchive(res?.data);
          setIsArchiveGoal(true);
          setIsArchiveUpdate(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isArchiveUpdate]);
  const updateArchivehandler = () => {
    const ArchiveGoalData = {
      uid: localStorage.getItem("userDetail"),
      archive: false,
      status: "IN_PROGRESS",
      id: selectedQuestionCategoriesId,
    };
    goalArchive(ArchiveGoalData)
      .then((res) => {
        console.log(res);
        setIsArchiveUpdate(true);
      })
      .catch((err) => {
        console.log(err);
      });
    setExpanded(false);
  };
  const handleChange =
    (
      panel,
      mySelf,
      others,
      questionCategoryId,
      actionsComplete,
      habitsComplete
    ) =>
    (event, isExpanded) => {
      console.log(actionsComplete);
      setExpanded(isExpanded ? panel : false);
      // setGoalName(questionCategory);
      setSelectedQuestionCategoriesId(questionCategoryId);
      dispatch(updateCompeletedHabit(habitsComplete));
      dispatch(updateisCompeletedAction(actionsComplete));

      const tempScore = mySelf.map((item) => {
        return item.percent;
      });
      setgoalScore(tempScore);
      const personalArray = mySelf.map((item) => {
        return {
          y: item.percent,
          name: moment(item?.date).format("DD MMM"),
          userName: item?.name[0]
            .split(" ")
            .map((i) => i.toUpperCase().charAt(0))
            .join(""),
        };
      });
      const friendArray = others.map((item) => {
        return {
          y: item.percent,
          name: moment(item.date).format("DD MMM"),
          userName: item?.name[0]
            .split(" ")
            .map((i) => i.toUpperCase().charAt(0))
            .join(""),
        };
      });
      setOthresArray(friendArray);
      setPersonalDataArray(personalArray);
    };

  return (
    <RightSideContentWrapper>
      <Header />
      <div className={classes.dashbordTitleContainer}>
        <div className={classes.dashbordTitle}>
          <CustomTypography variant="h4">Goals Archive</CustomTypography>
        </div>
        <div className={classes.dashbordTitlebody}>
          {matches ? (
            <Typography variant="p" sx={{ color: "#434243" }}>
              The Goals Archive allows you to track and review your progress
              towards character goals. Once a goal has been completed, it is
              stored in the Goals Archive where you can check your previous
              results and reflect on the actions and habits you completed to
              reach your goal
            </Typography>
          ) : (
            <>
              <Typography variant="p" sx={{ color: "#434243" }}>
                The Goals Archive allows you to track and review your progress
                towards character goals. Once a goal
              </Typography>
              <Typography variant="p" sx={{ color: "#434243" }}>
                has been completed, it is stored in the Goals Archive where you
                can check your previous results
              </Typography>
              <Typography variant="p" sx={{ color: "#434243" }}>
                and reflect on the actions and habits you completed to reach
                your goal
              </Typography>
            </>
          )}
        </div>
      </div>
      {!isArchiveGoal ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className={matches ? classes.smallMainWrapper : classes.mainWrapper}
        >
          {goalArchiveState?.length === 0 ? (
            <div className={classes.dataNotFoundSubWrapper}>
              <Typography variant="body2">Archive Goal not found</Typography>
            </div>
          ) : (
            <Grid container spacing={1}>
              {goalArchiveState?.map((data, index) => {
                const {
                  questionCategory,
                  questionCategoryId,
                  actionsComplete,
                  mySelf,
                  others,
                  url,
                  preSelectedGrowthComplete,
                  _id,
                  increase,
                  habitsComplete,
                } = data;
                return (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                    <Accordion
                      sx={{
                        border: "1px solid #F3F3F3",
                        borderRadius: "16px !important",
                        margin: "3.9px 0px",
                        padding: "3px 0px",
                      }}
                      expanded={expanded === index}
                      onChange={handleChange(
                        index,
                        mySelf,
                        others,
                        questionCategoryId,
                        actionsComplete,
                        habitsComplete
                      )}
                    >
                      <AccordionSummary
                        expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
                        className={classes.AccordianSummery}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          "& .Mui-expanded": {
                            margin: "0px 0px !important",
                          },
                        }}
                        //
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img src={AcademicIcon} alt="" />
                            <Typography
                              variant="body4"
                              sx={{
                                marginLeft: "12px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {questionCategory}
                            </Typography>
                          </div>
                          <div
                            style={{
                              marginRight: "15px",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {increase === "Decrease" ? (
                              <img src={GoalDecrease} alt="GoalGroup" />
                            ) : increase === "Calibrate" ? (
                              <img src={GoalCalibrate} alt="GoalGroup" />
                            ) : (
                              <img src={GoalGroup} alt="GoalGroup" />
                            )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div className={classes.schedualScoreWrapper}>
                            <div className={classes.goalScoreWrapper}>
                              {goalScore.length === 0 ? (
                                <div className={classes.dataNotFoundSubWrapper}>
                                  <Typography variant="body2">
                                    Archived Action not found
                                  </Typography>
                                </div>
                              ) : (
                                goalScore?.map((percent, i) => {
                                  return (
                                    <CircularProgressGoal
                                      key={i}
                                      value={Math.round(percent)}
                                      width={matches ? "32px" : "48px"}
                                      height={matches ? "32px" : "48px"}
                                    />
                                  );
                                })
                              )}
                              <SchedualdateWrapper>
                                <img
                                  className={classes.plusImage}
                                  style={{
                                    padding: matches && "5px 0px 0px 5px",
                                    width: matches && "22px",
                                  }}
                                  onClick={() => {
                                    // setIsCalenderOpen(true);
                                  }}
                                  alt=""
                                  src={PlusLargeIcon}
                                />
                              </SchedualdateWrapper>
                            </div>
                          </div>
                          <div style={{ margin: "16px 0px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "500",
                                margin: "16px 0px",
                              }}
                            >
                              Personal
                            </Typography>

                            <PersonalChart
                              PersonalDataArray={PersonalDataArray}
                            />
                          </div>
                          <div
                            style={{ margin: "16px 0px" }}
                            className={classes.friendChartWrapper}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "500",
                                margin: "16px 0px",
                              }}
                            >
                              Friends
                            </Typography>
                            <FriendChart othresArray={othresArray} />
                          </div>
                          <div style={{ margin: "30px 0px" }}>
                            <GrowthAreaArchiveAccrordian
                              preSelectedGrowthComplete={
                                preSelectedGrowthComplete
                              }
                            />
                          </div>
                          <div style={{ margin: "30px 0px" }}>
                            <ActionArchieveAccordian />
                          </div>
                          <div style={{ margin: "30px 0px" }}>
                            <HabitArchiveAccordian />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              zIndex: "1",
                            }}
                          >
                            <SubmitButtonWithBackGround
                              buttonText="Restore"
                              onClick={() => {
                                updateArchivehandler();
                              }}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
      )}
    </RightSideContentWrapper>
  );
}

export default GoalArchive;
