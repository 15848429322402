import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import classes from "./GrowthHabit.module.css";

const GrowthHabit = ({
  x,
  monthObject,
  handleIsTrue,
  updateHabitData,
  getCalenderDataSlice,
}) => {
  const UK_TIME = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  return (
    <>
      {getCalenderDataSlice?.getHabitData.map((habit, index) => {
        const temp = new Set();
        const dateMatches = getCalenderDataSlice?.getCalenderData.filter(
          (item) => item.questionCategory === habit.questionCategory
        );
        return dateMatches.map((item, dindex) => {
          return item.dates.map((dateRange, i) => {
            if (
              moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                moment(new Date(habit.calenderDate)).format("DD-MMMM, yyyy") &&
              moment(new Date(habit.calenderDate)).format("DD-MMMM, yyyy") ===
                `${x}-${monthObject}` &&
              habit.scheduled_type === "TIME_BASED"
            ) {
              if (temp.has(habit._id)) {
                return null;
              }
              temp.add(habit._id);
              return (
                item.questionCategory === habit.questionCategory &&
                habit._id && (
                  <Box
                    style={{
                      background: item.colour,
                      color: item.textColour,
                      borderRadius: "4px",
                    }}
                    key={index}
                    className={classes.action_data}
                    id={`${x}-${monthObject} ${moment(UK_TIME).format("h a")}`}
                    onClick={() => {
                      updateHabitData(habit);
                    }}
                  >
                    <Box
                      style={{
                        width: "13px",
                        height: "13px",
                        border: `1px solid ${item.textColour}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleIsTrue(habit);
                      }}
                    >
                      {habit.calenderStatus === "COMPLETE" ? (
                        <DoneIcon
                          style={{
                            color: item.textColour,
                            width: "12px",
                            height: "12px",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                    <div className={classes.textAction}>
                      <Typography
                        variant="p"
                        style={{
                          textDecoration:
                            habit.calenderStatus === "COMPLETE" &&
                            "line-through",
                        }}
                        className="text-3lines"
                      >
                        {habit?.habitName}

                      </Typography>
                    </div>
                    <AutorenewRoundedIcon
                      style={{
                        color: item.textColour,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Box>
                )
              );
            } else {
              return null;
            }
          });
        });
      })}
    </>
  );
};

export default React.memo(GrowthHabit);
