import classes from "../Onbording.module.css";
import { Box } from "@mui/system";
import Text from "../../../components/Text/Text";
import Input, {
  CheckBoxInput,
  CheckBoxInputChecked,
  InputCheckBox,
  InputFocus,
} from "../../../components/Input/Input";
import { PrevAndNextButtonGroup } from "../../../components/Button/Button";
import { Step, StepsWrapper } from "../StyledComponent";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { updateGender } from "../../../Store/Reducers/onbordingSlice";

export default function StepThree({ onNext, onPrevious }) {
  const Male = "Male";
  const Female = "Female";
  const NonBinary = "NON-BINARY";
  const { gender,name } = useSelector((state) => state.onbording);
  const [male, setMale] = useState(gender === Male);
  const [female, setFemale] = useState(gender === Female);
  const [nonbinary, setnonBinary] = useState(gender === NonBinary);
  const [others, setOthers] = useState(gender === Male || gender === Female || gender === NonBinary ? "" : gender);
  const dispatch = useDispatch();
  const onNextStep = () => {
    console.log(male, female);
    
    if (male) {
      dispatch(updateGender(Male));
    } else if(female) {
      dispatch(updateGender(Female));
    } else if(nonbinary) {
      dispatch(updateGender(NonBinary));
    }else{
      dispatch(updateGender(others))
    }
    onNext();
  };

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const handleChange = (gender) => {
    if (gender === Male ) {
      setMale(!male);
      setFemale(false);
      setnonBinary(false)
      if (others !== "") {
        setOthers("");
      }
    }
    if (gender === Female ) {
      setFemale(!female);
      setMale(false);
      setnonBinary(false)
      if (others !== "") {
        setOthers("");
      }
    }
    if (gender === NonBinary ) {
      setFemale(false);
      setMale(false);
      setnonBinary(!nonbinary)
      if (others !== "") {
        setOthers("");
      }
    }
  };

  useEffect(() => {
    if (others !== "") {
      setFemale(false);
      setMale(false);
    }
  }, [others]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const isBtnDisabled = () =>{
    console.log(74,others,male,female)

  }
  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step ></Step>
        {/* <Step></Step> */}
      </StepsWrapper>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
        <Text textValue={`${name}, it’s nice to meet you!`} variant="h2" />
      </Box>
      <Box className={classes.wrapper}>
        <Text textValue="How do you identify yourself?" variant="subtitle1" />
      </Box>
      <Box className={classes.wrapper}>
        <CustomCheckBox
          checked={male}
          {...label}
          icon={<CheckBoxInput placeholder="Male" />}
          onChange={() => handleChange(Male)}
          checkedIcon={<CheckBoxInputChecked placeholder="Male" />}
        />
      </Box>
      <Box className={classes.wrapper}>
        <CustomCheckBox
          checked={female}
          onChange={() => handleChange(Female)}
          {...label}
          icon={<CheckBoxInput placeholder="Female" />}
          checkedIcon={<CheckBoxInputChecked placeholder="Female" />}
        />
      </Box>
      <Box className={classes.wrapper}>
        <CustomCheckBox
          checked={nonbinary}
          onChange={() => handleChange(NonBinary)}
          {...label}
          icon={<CheckBoxInput placeholder="Non-binary" />}
          checkedIcon={<CheckBoxInputChecked placeholder="Non-binary" />}
        />
      </Box>
      <Box className={classes.wrapper}>
        <InputCheckBox
          placeholder="Other"
          value={others}
          onChange={setOthers}
          type="text"
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <PrevAndNextButtonGroup
          isPreviousButton={true}
          onNext={onNextStep}
          onPrevious={onPrevious}
          isDisabled={ male || female || nonbinary ? others !== '' ? false : false : others !== '' ? false : true  }
        />
      </Box>
    </>
  );
}
