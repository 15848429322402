import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import OnbordingLayout from "./core-ui/OnbordingLayout/OnbordingLayout";
import Login from "./pages/Login/Login";
import StepOne from "./pages/Onbording/StepOne/StepOne";
import StepTwo from "./pages/Onbording/StepTwo/StepTwo";
import StepThree from "./pages/Onbording/StepThree/StepThree";
import StepFour from "./pages/Onbording/StepFour/StepFour";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword/ForgotPassword";
import Signup from "./pages/Signup/Signup";
import EmailVerification from "./pages/ForgotPassword/EmailVerification/EmailVerification";
import CreateNewPassword from "./pages/ForgotPassword/CreateNewPassword/CreateNewPassword";
import SuccessMessage from "./pages/ForgotPassword/SuccessMessage/SuccessMessage";
import Onbording from "./pages/Onbording/Onbording";
// import Dashboard from "./pages/Dashboard/Dashboard";
import Dashboard from "./pages/Dashboard/NewDashboard";
import { useEffect, useState } from "react";
import { getUserData } from "./services/auth";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { userProfileUpdate } from "./Store/Reducers/userProfileSlice";
import moment from "moment";
import TestDashbord from "./core-ui/TestDashbord/TestDashbord";
import TestCategoriesList from "./pages/TestCategoriesList/TestCategoriesList";
import TestCategory from "./pages/TestCategory/TestCategory";
import TestLayout from "./pages/TestLayout/TestLayout";
import TestPage from "./pages/TestPage/TestPage";
import TestPageLayout from "./core-ui/TestPageLayout/TestPageLayout";
import TestEndPage from "./pages/TestEndPage/TestEndPage";
import InvitePage from "./pages/InviteFlow/InvitePage/InvitePage";
import InviateTestLayout from "./core-ui/InviateTestLayout/InviateTestLayout";
import AuthOption from "./pages/InviteFlow/AuthOption/AuthOption";
import ShowUserDetailsFlow from "./pages/InviteFlow/ShowUserDetailsFlow/ShowUserDetailsFlow";
import InviteTestPage from "./pages/InviteFlow/InviteTestPage/InviteTestPage";
import ReflectiveQuestion from "./pages/InviteFlow/ReflectiveQuestion/ReflectiveQuestion";
import InviteFlowOnbording from "./pages/InviteFlow/InviteFlowOnbording/Onbording";
import InviteLogin from "./pages/InviteFlow/InviteLogin/InviteLogin";
import InviteSignup from "./pages/InviteFlow/InviteSignup/InviteSignup";
import CongratsPage from "./pages/InviteFlow/CongratsPage/CongratsPage";
import StartTest from "./pages/InviteFlow/StartTest/StartTest";
import OnbordingDone from "./pages/InviteFlow/OnbordingDone/OnbordingDone";
import ResultDashbord from "./core-ui/ResultDashbord/ResultDashbord";
import HomeDashbord from "./core-ui/ResultDashbord/HomeDashbord"
import TestResultCategoriesList from "./pages/TestResultCategoryList/TestResultCategoryList";
import ResultCategoryCard from "./components/ResultCategoryCard/ResultCategoryCard";
import ResultLayout from "./pages/ResultLayout/ResultLayout";
import SingleResultLayout from "./pages/SingleResultLayout/SingleResultLayout";
import GoalDashbord from "./core-ui/GoalDashbord/GoalDashbord";
import GoalList from "./pages/GoalListPage/GoalList";
import GoalArchive from "./pages/GoalArchive/GoalArchive";
import GrowthCalendar from "./pages/GrowthCalendar/GrowthCalendar";
import GrowthCalendarDashbord from "./core-ui/GrowthCalendarDashbord/GrowthCalendarDashbord";
import SettingDashbord from "./pages/Settings/Index/Index";
import SettingProfile from "./pages/Settings/Profile/Profile";
import SetPassword from "./pages/Settings/Password/Password";
import Billing from "./pages/Settings/Billing/Billing"
import SetMembership from "./pages/Settings/Membership/Membership";
import SetFeedback from "./pages/Settings/Feedback/Feedback";
import Notifications from "./pages/Settings/Notifications/Notifications";
import Legal from "./pages/Settings/Legal/Legal";
import { SettingNavigationBar } from "./components/Settings/SettingNavigationBar/SettingNavigationBar";
import JoinWaitlist from "./pages/JoinWaitList/JoinWaitlist";
import StorePage from "./pages/Store";
import QuoteShareView from "./pages/quoteShare";
import TestCategiryListnew from "./pages/TestResultCategoryListNew";
import PageNotFound from "./pages/404NotFound";
import { updateTopicDataProfile, updateTopicDataYoutubeLink } from "./Store/Reducers/onbordingSlice";

const Routers = () => {
  // const navigate = useNavigate()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const ProptectedRoutesRedirectToDashboard = ({ children }) => {
    const user = localStorage.getItem("userDetail");
    if (user) {
      return <Navigate to="/testcategory" />;
    } else {
      return children;
    }
  };

  const ProptectedRoutesRedirectToLogin = ({ children }) => {
    const user = localStorage.getItem("userDetail");
    if (user) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("userDetail");
    if (user) {
      setIsLoading(true);
      getUserData(user)
        .then((response) => {
          console.log("??????",response);
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
          if(response.topicData) {
            dispatch(updateTopicDataProfile(response.topicData))
            dispatch(updateTopicDataYoutubeLink(response.topicData.link))
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<OnbordingLayout />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToDashboard>
              <Login />
            </ProptectedRoutesRedirectToDashboard>
          }
        />
        <Route
          path="signup"
          element={
            <ProptectedRoutesRedirectToDashboard>
              <Signup />
            </ProptectedRoutesRedirectToDashboard>
          }
        />
        <Route
          path="onbording"
          element={
            <ProptectedRoutesRedirectToLogin>
              <Onbording />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="join-waitlist"
          element={
            <ProptectedRoutesRedirectToDashboard>
              <JoinWaitlist />
            </ProptectedRoutesRedirectToDashboard>
          }
        />

        {/* // comment this before build  */}
        {/* <Route path="stepone" element={<StepOne onNext={undefined} />} />
        <Route
          path="steptwo"
          element={<StepTwo onNext={undefined} onPrevious={undefined} />}
        />
        <Route
          path="stepthree"
          element={<StepThree onNext={undefined} onPrevious={undefined} />}
        />
        <Route
          path="stepfour"
          element={<StepFour onNext={undefined} onPrevious={undefined} />}
        /> */}

        <Route
          path="forgotpassword"
          element={
            <ProptectedRoutesRedirectToLogin>
              <ForgotPassword />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="verify"
          element={
            <ProptectedRoutesRedirectToLogin>
              <EmailVerification />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        {/* <Route
          path="newpassword"
          element={
             <ProptectedRoutesRedirectToLogin>
              <CreateNewPassword />
             </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="success"
          element={
             <ProptectedRoutesRedirectToLogin>
              <SuccessMessage />
             </ProptectedRoutesRedirectToLogin>
          }
        /> */}
      </Route>

      <Route path='/dashboard' element={<HomeDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <Dashboard />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/testcategory" element={<TestDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestCategoriesList />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path=":categoryId"
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestCategory />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path=":categoryId/paymentresult"
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestCategory />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        {/* <Route path=":testId" element={<TestLayout />} /> */}
      </Route>
      <Route path="/test" element={<TestDashbord />}>
        {/* <Route index element={<TestCategoriesList />} /> */}
        <Route
          path=":testId"
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestLayout />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/startTest" element={<TestPageLayout />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestPage />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="testend"
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestEndPage />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/quote-share" element={<TestPageLayout showCloseIcon={false} showFlag={false} showBrand={true} />}>
        <Route
          path=":testId"
          element={
            <QuoteShareView />
          }
        />
      </Route>
      <Route path="/invite/:id" element={<InviateTestLayout />}>
        <Route
          index
          element={
            // <ProptectedRoutesRedirectToLogin>
            <InvitePage />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="auth"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <AuthOption />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="login"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <InviteLogin />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="signup"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <InviteSignup />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="userinfo"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <ShowUserDetailsFlow />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="test"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <InviteTestPage />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="reflective"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <ReflectiveQuestion />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="onbording"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <InviteFlowOnbording />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="done"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <OnbordingDone />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="congrats"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <CongratsPage />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/results" element={<ResultDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestResultCategoriesList />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path=":categoryId"
          element={
            <ProptectedRoutesRedirectToLogin>
              <SingleResultLayout />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/newresults" element={<ResultDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestCategiryListnew />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path=":categoryId"
          element={
            <ProptectedRoutesRedirectToLogin>
              <SingleResultLayout />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path='/store' element={<ResultDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <StorePage />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="paymentresult"
          element={
            <ProptectedRoutesRedirectToLogin>
              <StorePage />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/resultsubcatagories" element={<ResultDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <TestResultCategoriesList />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path=":subcatagories"
          element={
            <ProptectedRoutesRedirectToLogin>
              <ResultLayout />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/goals" element={<GoalDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <GoalList />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="/goals/archive"
          element={
            <ProptectedRoutesRedirectToLogin>
              <GoalArchive />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route
        path="/growthCalendar"
      //element={<GrowthCalendarDashbord />}
      >
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <GrowthCalendar />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/settings" element={<SettingDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <SettingProfile />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="/settings/password"
          element={
            <ProptectedRoutesRedirectToLogin>
              <SetPassword />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="/settings/billing"
          element={
            <ProptectedRoutesRedirectToLogin>
              <Billing />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="/settings/membership"
          element={
            <ProptectedRoutesRedirectToLogin>
              <SetMembership />
            </ProptectedRoutesRedirectToLogin>
          }
        />

        <Route
          path="/settings/feedback"
          element={
            <ProptectedRoutesRedirectToLogin>
              <SetFeedback />
            </ProptectedRoutesRedirectToLogin>
          }
        />

        <Route
          path="/settings/notifications"
          element={
            <ProptectedRoutesRedirectToLogin>
              <Notifications />
            </ProptectedRoutesRedirectToLogin>
          }
        />

        <Route
          path="/settings/legal"
          element={
            <ProptectedRoutesRedirectToLogin>
              <Legal />
            </ProptectedRoutesRedirectToLogin>
          }
        />
        <Route
          path="/settings/settingnavigationbar"
          element={
            // <ProptectedRoutesRedirectToLogin>
            <SettingNavigationBar />
            // </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route path="/page-not-found" element={<ResultDashbord />}>
        <Route
          index
          element={
            <ProptectedRoutesRedirectToLogin>
              <PageNotFound />
            </ProptectedRoutesRedirectToLogin>
          }
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/page-not-found" />}
      />
    </Routes>
  );
};

export default Routers;
