import { IconButton, Snackbar } from '@mui/material';
import React, { useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";


const useNotifications = (status, messageText = "", verticalPosition = "top", horizontalPosition = "center") => {

    const [notification, setNotification] = useState({
        open: status, message: messageText, vertical: verticalPosition,
        horizontal: horizontalPosition,
    })
    const { open, message, vertical, horizontal } = notification;
    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setNotification({ ...notification, open: false })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    const Notification = (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                className="notification"
                message={message}
                action={action}
            />
        </>
    )
    const updateNotificationState = (message, open = true) => {
        setNotification({ ...notification, open: open, message: message })
    }

    return { Notification, updateNotificationState }
}

export default useNotifications