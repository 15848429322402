import { Typography } from "@mui/material";

const Text = ({ textValue, variant , width="100%",color="#190D1A", otherSx = {} }) => {
  return (
    <Typography variant={variant} component="div" sx={{ textAlign: "center" ,width: width, color: color, ...otherSx }}>
      {textValue}
    </Typography>
  );
};


export default Text;
