import { useTheme } from '@emotion/react'
import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import Header from '../../../components/Header/Header'
import { SettingNavigationBar } from '../../../components/Settings/SettingNavigationBar/SettingNavigationBar'
import { RightSideContentWrapper } from '../../../components/Settings/StyledElement/StyledDiv'
import classes from './notification.module.css'
import NotificationsDetailes from './NotificationsDetailes'

function Notifications() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <RightSideContentWrapper>
        <Header />
        <Grid container spacing={1.5}>
          <Grid item xs={12} lg={9} md={9}>
            <div className={classes.dashbordTitleContainer}>
                <NotificationsDetailes />
            </div>
          </Grid>
          {
           !matches && <Grid item xs={12} lg={3} md={3}>
          <div style={{padding:"12px 0px"}} className={classes.dashbordTitleContainer}>
                <SettingNavigationBar />
          </div>
          </Grid>
          }
        </Grid>
      </RightSideContentWrapper>
    </>
  );
}

export default Notifications