import { Checkbox, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../components/Input/Input";
import { USERTYPE } from "../../../Constant/Invite";
import useCustomCheckBox from "../../../Hooks/useCustomCheckBox";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import classes from "./NameOption.module.css";
import { Step, StepsWrapper } from "../StyledComponent";
import { useState } from "react";
import { inviteRegister } from "../../../services/auth";

const NameOption = (props) => {
  const { firstCheckBox, secondCheckBox, handleChange } = useCustomCheckBox(
    USERTYPE?.SHOW_MY_NAME,
    USERTYPE?.ANONYMEOUSLY,
    props?.showUserdata?.TesterMode
  );
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;
  const updateMainState = () => {
    const uid = localStorage.getItem("userDetail");
    const inviteId = localStorage.getItem("inviteId");
    const body = {
      uid,
      inviteId,
      anonymousStatus: firstCheckBox ? "NO" : "YES",
    };
    console.log("inviteId",body)
    inviteRegister(body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    if (firstCheckBox) {
      props.getUserDatafnc("TesterMode", USERTYPE?.SHOW_MY_NAME);
    } else {
      props.getUserDatafnc("TesterMode", USERTYPE?.ANONYMEOUSLY);
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const SubHeading = styled(Box)(({ theme }) => ({
    textAlign: "center",
    marginTop: "32px",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  }));

  return (
    <div className={classes.stepOneWrapper}>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
      </StepsWrapper>
      <div></div>
      <div className={classes.middleContent}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          How would you like to pass the test?
        </Typography>

        <SubHeading>
          <Typography variant="subtitle1">Select tester’s mode</Typography>
        </SubHeading>
        <div className="option">
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={firstCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Show my name" />}
              onChange={() => {
                handleChange(USERTYPE?.SHOW_MY_NAME);
              }}
              checkedIcon={<CheckBoxInputChecked placeholder="Show my name" />}
            />
          </Box>
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={secondCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Take anonymously" />}
              onChange={() => {
                handleChange(USERTYPE?.ANONYMEOUSLY);
              }}
              checkedIcon={
                <CheckBoxInputChecked placeholder="Take anonymously" />
              }
            />
          </Box>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
        <ButtonGroup
          {...props}
          updateMainState={updateMainState}
          isPreviousBtnDisable={true}
          isNextDisable={!firstCheckBox && !secondCheckBox}
        />
      </div>
    </div>
  );
};

export default NameOption;
