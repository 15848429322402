import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Coin from "../../../assets/Svg/Coin.svg";
import typography from "../../../theme/typography";
import classes from "./ResultCongrats.module.css";
import Confetti from "react-confetti";
// import { SubHeading } from "../StyledComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SubHeading } from "../../InviteFlow/StyledComponent";
import close from "../../../assets/Svg/Close.svg";



const ResultCongrats = ({handleClose,modalData}) => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const confettiRef = useRef(null);
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
  }, []); //we need dependencies here for check in responsive mode in inspact but we don't need because it's automatically detect width here when in production mode
  const navigate = useNavigate();
  const {
    inviteDataSlice: { inviteData },
  } = useSelector((state) => state);

  const onNext = () => {
    navigate("/testcategory");
  };
  return (
    <>
      <div className={classes.invitePageWrapper} ref={confettiRef}>
      <div  className={classes.closeIconeWrapper} onClick={()=>{handleClose()}}>
        <img src={close}/>
      </div>
        <Confetti width={width} height={height} />
        <div></div>
        <div className={classes.headingWrapper}>
          <div className={classes.headingText}>
            <Typography variant="h3">
              Thank you for your feedback on '{modalData?.questionCategory}'
            </Typography>
          </div>
          <SubHeading>
            <div className={classes.subHeadingText}>
              <Typography variant="subtitle1" color="#434243">
                You have earned
              </Typography>
            </div>
          </SubHeading>
          <div className={classes.coinIconWrapper}>
            <img src={Coin} alt="" />
            <Typography variant="body3" color="#9747FF">
              20
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResultCongrats;
