import {
  Box,
  Button,
  Dialog,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useState } from "react";
import { CustomShedualButton } from "../../GoalComponent/GoalButton";
import Clock from "../../../assets/Svg/Clock.svg";
import {
  CardContent,
  CustomSelection,
  MainCard,
} from "../../GoalComponent/GoalDialog";
import classes from "./HabitDialog.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import ButtonGroup from "../ButtonGroupAddMore/ButtonGroup";
import { dates, days } from "../../../Constant/GoalConstant";
import { CheckBoxInputCheckedDay, CheckBoxInputDay } from "../../Input/Input";
import { CustomCheckBox } from "../../GoalComponent/GoalCustomDiv";
import moment from "moment";
import RightIcon from "../../../assets/Svg/RightIcon.svg";
import { goalCreateHabitAction } from "../../../services/auth";
import { XPLevels } from "../../../utils";
import HabitFrequencySelection from "../../HabitSelection/HabitSelection";

function HabitDialog({ goalName, setIsHabitOpen, addMoreHabitHandler, imageCompo }) {
  const [habitScheduleType, sethabitScheduleType] = useState("");
  const [isDatePickerOpen, setisDatePickerOpen] = useState(false);
  // const [selectedDay, setselectedDay] = useState(new Map());
  const [selectedDaystate, setSelectedDaystate] = useState([]);
  const [selectedDate, setselectedDate] = useState("02");
  const [habitName, setHabitName] = useState("");
  const [habitDetail, setHabitDetail] = useState("");
  const [level, setLevel] = useState("");

  const onClickNext = () => {
    let days = [];
    selectedDaystate.forEach((data) => {
      days.push(data.days);
    });
    if(habitName && habitScheduleType && level) {
      addMoreHabitHandler({
        habitName: habitName,
        habitDetail: habitDetail,
        habitScheduleType: habitScheduleType,
        days: days,
        habitSchedule:
          habitScheduleType === "MONTHLY" &&
          `${moment().format("MM")}-${selectedDate}-${moment().format("YYYY")}`,
        active: "true",
        level
      });
    }
    // setIsHabitOpen(false);
  };
  const dateselector = (date) => {
    setselectedDate(date);
  };
  const setselectedDayHandler = (day, index) => {
    const findData = selectedDaystate.find((o) => o.index === index);
    if (findData) {
      setSelectedDaystate((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setSelectedDaystate([...selectedDaystate, { index: index, days: day }]);
    }
  };
 
  return (
    <MainCard
      sx={{
        minWidth: 250,
        borderRadius: "40px",
        marginInline: "auto",
        height: "610px !important",
      }}
    >
      <CardContent>
        <div className={classes.StepFiveWrapper}>
          <div className={classes.StepFiveSubWrapper}>
            {imageCompo ? imageCompo : <img src={Hedonism} alt="" />}
            <Typography
              variant="h5"
              sx={{ textAlign: "center", margin: "16px 0px" }}
            >
              Add new habit to
              <span style={{ color: "#9747FF" }}> {goalName}</span>
            </Typography>
            <div style={{ width: "100%", marginBottom: "12px" }}>
              <div style={{ width: "100%" }}>
                <Typography variant="subtitle1" sx={{ marginTop: "10px" }}>
                  Give it a name
                </Typography>
                <TextField
                  size="small"
                  // className="goalName"
                  className={classes.goalActionInput}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #f3f3f3",
                      borderRadius: "16px",
                    },
                    " & .MuiOutlinedInput-input": {
                      color: " #190d1a",
                      padding: "16px 10px",
                    },
                    " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #9747FF" },
                  }}
                  InputProps={{
                    sx: {
                      "&:hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #e5d5fc",
                        },
                      },
                    },
                  }}
                  fullWidth
                  value={habitName}
                  onChange={(e) => {
                    setHabitName(e.target.value);
                  }}
                  type="text"
                  placeholder="Text"
                  id="outlined-start-adornment"
                />
                <Typography variant="subtitle1" sx={{ marginTop: "10px" }}>
                  Tell us why this habit
                </Typography>
                <TextField
                  size="small"
                  // className="goalName"
                  className={classes.goalActionInput}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #f3f3f3",
                      borderRadius: "16px",
                    },
                    " & .MuiOutlinedInput-input": {
                      color: " #190d1a",
                      padding: "16px 10px",
                    },
                    " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #9747FF" },
                  }}
                  InputProps={{
                    sx: {
                      "&:hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #e5d5fc",
                        },
                      },
                    },
                  }}
                  fullWidth
                  value={habitDetail}
                  onChange={(e) => {
                    setHabitDetail(e.target.value);
                  }}
                  type="text"
                  placeholder="Text"
                  id="outlined-start-adornment"
                />
              </div>
              <div style={{ width: "100%", marginBottom: '10px' }}>
                <Typography variant="subtitle1" sx={{ marginTop: "10px", marginBottom: '10px' }}>
                  {" "}
                  How often
                </Typography>
                <HabitFrequencySelection
                  scheduleType={habitScheduleType}
                  onChangeSelect={sethabitScheduleType}
                  onChangeDay={setselectedDayHandler}
                  selectedDays={selectedDaystate}
                />
              </div>
            </div>
            <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start', marginTop: "10px", }}>
              Select Difficulty
            </Typography>
            <div className={classes.customActionLevelGroup}>
              <ToggleButtonGroup
                color="primary"
                value={level}
                className={classes.customActionLevelGroup}
                exclusive
                onChange={(e, val) => {
                  setLevel(val)
                }}
                size="small"
                aria-label="Platform"
              >
                {XPLevels.map(it => (
                  <ToggleButton value={it.level} className={level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {it.icon} <span className="toggle-text">{it.level}</span></ToggleButton>

                ))}
              </ToggleButtonGroup>
            </div>

            <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
              <ButtonGroup
                onClickPrevious={() => setIsHabitOpen(false)}
                onClickNext={onClickNext}
                nextButtonText="Save"
                previousButtonText="Discard"
                isNextDisable={!(habitName && habitScheduleType && level)}
              />
            </div>
          </div>
        </div>
      </CardContent>

      {isDatePickerOpen && (
        <Dialog
          open={isDatePickerOpen}
          onClose={() => {
            setisDatePickerOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "458px",
              height: "372px",
              borderRadius: "20px",
              position: "unset",
              padding: "20px",
            },
          }}
        >
          <div style={{ width: "100%" }}>
            <Typography className={classes.dailyText} variant="subtitle1">
              Daily
            </Typography>
            <Typography className={classes.DateweeklyText} variant="subtitle1">
              Weekly
            </Typography>
            <div className={classes.WeeklyText}>
              <Typography variant="subtitle1" sx={{ color: "#434243" }}>
                Monthly
              </Typography>
              <img
                style={{ width: "20px", height: "20px" }}
                src={RightIcon}
                alt=""
              />
            </div>
            <div className={classes.DayWrapper}>
              {dates.map((date) => {
                return (
                  <div
                    onClick={() => dateselector(date)}
                    className={
                      selectedDate === date
                        ? classes.selectedDateText
                        : classes.dateText
                    }
                  >
                    {date}
                  </div>
                );
              })}
            </div>
          </div>
        </Dialog>
      )}
    </MainCard>
  );
}

export default HabitDialog;
