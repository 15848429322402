import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { RightSideContentWrapper } from "../Store/store.styles";

import moment from "moment";
import DashboardService from "../../services/Dashboard";

import { setDahData, } from "../../Store/Reducers/dashboard";

import DashboardDataContent from "./DashboardContent";

const Dashboard = () => {


  return (
    <RightSideContentWrapper>
      <Header />
      <DashboardDataContent />
    </RightSideContentWrapper>
  );


}

export default Dashboard;

// render() {
//   return (
//     <>
//       <RightSideContentWrapper>
//         <Header />
//         <DashboardDataContent />
//       </RightSideContentWrapper>

//     </>
//   );
// }

