import { Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./EditAction.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import moment from "moment";
import { CalanderDialog } from "../../GoalComponent/GoalDialog";
import {
  DATE_PICKER,
  optionGroups,
  RANGEPICKERTYPE,
  TIME_PICKER,
} from "../../../Constant/GoalConstant";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Picker from "react-mobile-picker-scroll";
import ChipSaveButton from "../../ChipSaveButton";
function EditAction({ actionState, setActionState, editActionTextHandler = () => { } }) {
  console.log("actionState = ", actionState);
  const tempStartDate = actionState?.executionDate ? actionState?.executionDate?.split("T") : moment().format().split("T");
  const [startTimevalueGroups, setStartTimevalueGroups] = useState({
    hour: moment(new Date(actionState?.executionDate ? actionState?.executionDate : moment().format())).format("HH"),
    minutus: moment(new Date(actionState?.executionDate ? actionState?.executionDate : moment().format())).format("mm"),
    // AMPM: moment(new Date(actionState?.executionDate)).format("A"),
  });
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: moment(new Date(actionState?.executionDateEnd ? actionState?.executionDateEnd : moment())).format("HH"),
    minutus: moment(new Date(actionState?.executionDateEnd ? actionState?.executionDateEnd : moment())).format("mm"),
    // AMPM: moment(new Date(actionState?.executionDateEnd)).format("A"),
  });
  const [isValid, setIsValid] = useState("");
  const [startDate, setStartDate] = useState(new Date(tempStartDate[0]));
  const [endDate, setEndDate] = useState(new Date(tempStartDate[0]));

  console.log("startDate = ", startDate);
  console.log("endDate = ", endDate);
  const modalClosehandler = () => {
    const convetedStartDate = moment(startDate).format("yyyy-MM-DDT");
    const convetedEndDate = moment(startDate).format("yyyy-MM-DDT");

    const executionDate = moment(convetedStartDate, "yyyy-MM-DDT").set({
      hour: startTimevalueGroups.hour,
      minutes: startTimevalueGroups.minutus
    }).format();
    const executionEnddate = moment(convetedEndDate, "yyyy-MM-DDT").set({
      hour: endTimeValuegroup.hour,
      minutes: endTimeValuegroup.minutus
    }).format();
    console.log("moment(executionDate) < moment(executionEnddate)", moment(executionDate), moment(executionEnddate));
    if (moment(executionDate) > moment(executionEnddate)) {
      setIsValid(true)
    } else {
      setIsValid(false)
      setIsAskOtherModalOpen(false);
      console.log("actionState", actionState);
      setActionState({
        actionName: actionState.actionName,
        actionDetail: actionState.actionDetail,
        actionId: actionState.actionId,
        executionDate: executionDate,
        executionDateEnd: executionEnddate,
        scheduled_type: actionState.scheduled_type,
        calenderStatus: actionState.calenderStatus,

      });
    }

  };
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [rangePickerType, setRangePickerType] = useState(
    RANGEPICKERTYPE?.DATE_RANGE_PICKER
  );
  const [timePicker, setTimePicker] = useState(TIME_PICKER.TIME_PICKER_START);
  const [datePicker, setDatePicker] = useState(DATE_PICKER.DATE_PICKER_START);

  const handleChange = (name, value) => {
    if (timePicker === TIME_PICKER.TIME_PICKER_START) {
      setStartTimevalueGroups({
        ...startTimevalueGroups,
        [name]: value,
      });
    } else {
      setEndTimeValuegroup({
        ...endTimeValuegroup,
        [name]: value,
      });
    }
  };
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.editInnerWrapper}>
        <TextField
          size="small"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            " & .MuiOutlinedInput-input": {
              color: "#190D1A",
              padding: "10px",
              fontWeight: 500,
              fontSize: "12px",
            },
          }}
          InputProps={{
            sx: {
              "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            },
          }}
          fullWidth
          name="text"
          value={actionState.actionName}
          onChange={(e) =>
            setActionState({
              actionName: e.target.value,
              actionDetail: actionState.actionDetail,
              actionId: actionState.actionId,
              executionDate: actionState.executionDate,
              executionDateEnd: actionState.executionDateEnd,
              calenderStatus: actionState.calenderStatus,
            })
          }
          type="text"
          placeholder="Text"
          id="outlined-start-adornment"
        />
        <Divider />
        <TextField
          size="small"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            " & .MuiOutlinedInput-input": {
              color: "#190D1A",
              padding: "10px",
              fontWeight: 500,
              fontSize: "12px",
            },
          }}
          InputProps={{
            sx: {
              "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            },
          }}
          fullWidth
          name="text"
          value={actionState.actionDetail}
          onChange={(e) =>
            setActionState({
              actionName: actionState.actionName,
              actionDetail: e.target.value,
              actionId: actionState.actionId,
              executionDate: actionState.executionDate,
              executionDateEnd: actionState.executionDateEnd,
              calenderStatus: actionState.calenderStatus,
            })
          }
          type="text"
          placeholder="Text"
          id="outlined-start-adornment"
        />
        <Divider />
        <div className={classes.DateAndTimewrapper}>
          <img src={Clock} />
          <Typography
            variant="body3"
            className={classes.dateAndTimeText}
            onClick={() => {
              setIsAskOtherModalOpen(true);
            }}
          >
            {`${moment(startDate).format(" DD MMM  YYYY")}`}{" "}
            {`${startTimevalueGroups?.hour}:${startTimevalueGroups?.minutus} to ${endTimeValuegroup?.hour}:${endTimeValuegroup.minutus}`}
          </Typography>
        </div>
        <ChipSaveButton
          title="Save"
          style={{
            marginTop: '10px'
          }}
          onSave={editActionTextHandler}
        />
      </div>
      <div>
        <CalanderDialog
          open={isAskOtherModalOpen}
          onClose={() => modalClosehandler()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.calanderWrapper}>
            <div className={classes.DateAndTimewrapper}>
              <img src={Clock} />
              <Typography variant="body2" sx={{ color: "#190D1A" }}>
                Set duration
              </Typography>
            </div>
            <div style={{ width: "100%" }}>
              <Typography variant="small">Starts</Typography>
              <div className={classes.dateTimeRangePickerWrapper}>
                <div className={classes.dateTimeRangePickerSubWrapper}>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER &&
                        "#9747FF",
                    }}
                    onClick={() => {
                      setRangePickerType(RANGEPICKERTYPE.DATE_RANGE_PICKER);
                      setDatePicker(DATE_PICKER.DATE_PICKER_START);
                    }}
                  >
                    {" "}
                    {moment(startDate).format("DD MMM YYYY")}
                  </Typography>
                </div>
                <div className={classes.dateTimeRangePickerSubWrapper}>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        rangePickerType === RANGEPICKERTYPE.TIME_RANGE_PICKER &&
                        "#9747FF",
                    }}
                    onClick={() => {
                      setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                      setTimePicker(TIME_PICKER.TIME_PICKER_START);
                      setDatePicker(DATE_PICKER.DATE_PICKER_START);
                    }}
                  >
                    {" "}
                    {`${startTimevalueGroups.hour}:${startTimevalueGroups.minutus}`}
                  </Typography>
                </div>
              </div>
            </div>
            {rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER ? (
              <DatePicker
                currentDate={startDate}
                setCurrentDate={setStartDate}
              />
            ) : (
              <Picker
                optionGroups={optionGroups}
                valueGroups={
                  timePicker === TIME_PICKER.TIME_PICKER_START
                    ? startTimevalueGroups
                    : endTimeValuegroup
                }
                onChange={handleChange}
              />
            )}

            <div style={{ width: "100%" }}>
              <Typography variant="small">Ends</Typography>
              <div className={classes.dateTimeRangePickerWrapper}>
                <div className={classes.dateTimeRangePickerSubWrapper}>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER &&
                        "#9747FF",
                    }}
                    onClick={() => {
                      setRangePickerType(RANGEPICKERTYPE.DATE_RANGE_PICKER);
                      setDatePicker(DATE_PICKER.DATE_PICKER_END);
                    }}
                  >
                    {" "}
                    {moment(startDate).format("DD MMM YYYY")}
                  </Typography>
                </div>
                <div className={classes.dateTimeRangePickerSubWrapper}>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        rangePickerType === RANGEPICKERTYPE.TIME_RANGE_PICKER &&
                        "#9747FF",
                    }}
                    onClick={() => {
                      setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                      setTimePicker(TIME_PICKER.TIME_PICKER_END);
                      setDatePicker(DATE_PICKER.DATE_PICKER_END);
                    }}
                  >
                    {`${endTimeValuegroup.hour}:${endTimeValuegroup.minutus}`}
                  </Typography>
                </div>
              </div>
            </div>
            {isValid ? (
              <div
                style={{
                  paddingTop: "15px",
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="p">
                  {isValid && "Start Time should be less than End Time"}
                </Typography>
              </div>
            ) : null}
            <ChipSaveButton
              title="Save"
              style={{
                marginTop: '10px'
              }}
              onSave={modalClosehandler}
            />
          </div>

        </CalanderDialog>
      </div>
    </div>
  );
}
export default EditAction;
