import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import React, { useState } from "react";
import classes from "./Frequency.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import {
  DATE_PICKER,
  days,
  FREQUENCY_TYPE,
  optionGroups,
  RANGEPICKERTYPE,
  TIME_BASE,
  TIME_PICKER,
} from "../../../Constant/GoalConstant";
import RightIcon from "../../../assets/Svg/RightIcon.svg";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Picker from "react-mobile-picker-scroll";
import { CustomCheckBox } from "../../GoalComponent/GoalCustomDiv";
import { CheckBoxInputCheckedDay, CheckBoxInputDay } from "../../Input/Input";
import {
  CalanderActionDialog,
  CalanderHabitDialog,
} from "../../GoalComponent/GoalDialog";
import { updateHabitActionCalender } from "../../../services/auth";
import { storeGetHabitData } from "../../../Store/Reducers/getCalendarData";
import ChipSaveButton from "../../ChipSaveButton";
import { firstLetterUpper } from "../../../utils";
import { storeGetGrowthData } from "../../../Store/Reducers/goalGrowthCalendar";

const Frequency = ({
  habitData,
  habitSliceData,
  updateHabit,
  setUpdateHabit,
  setIsSetDurationHabit,
  setHabitSliceData,
  setIsFrequency,
  isFrequency,
  getGrowthd,
  getCalendars
}) => {
  const { ActiondataSlice, getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const [isSchedule, setIsSchedule] = useState(false);
  const [frequency, setFrequency] = useState(updateHabit.habitScheduleType);
  const [timePicker, setTimePicker] = useState(TIME_PICKER.TIME_PICKER_START);
  const [datePicker, setDatePicker] = useState(DATE_PICKER.DATE_PICKER_START);
  const [isStartTime, setIsStartTime] = useState(true);
  const [isEndTime, setIsEndTime] = useState(false);
  const tempUpdateStartDate = updateHabit.executionDate?.split("T");
  const temptempStartDate = habitData.executionDate?.split("T");
  const [startDate, setStartDate] = useState(
    new Date(
      Object.keys(updateHabit).length
        ? tempUpdateStartDate[0]
        : temptempStartDate[0]
    )
  );
  console.log("updateHabit = ", updateHabit);
  const [endDate, setEndDate] = useState(new Date(habitData.executionDateEnd));
  const [specifiedTime, setSpecifiedTime] = useState(
    Object.keys(updateHabit).length
      ? updateHabit.scheduled_type
      : TIME_BASE.TIME_BASED
  );
  const [selectedDaystate, setSelectedDaystate] = useState(updateHabit?.days);
  const [startTimevalueGroups, setStartTimevalueGroups] = useState({
    hour: moment(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date() : new Date(updateHabit.executionDate)).format("HH"),
    minutus: moment(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date() : new Date(updateHabit.executionDate)).format("mm"),
    AMPM: moment(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date() : new Date(updateHabit.executionDate)).format("A"),
  });
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: moment(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date(moment().add(1, 'hour').format()) : new Date(updateHabit.executionDateEnd)).format("HH"),
    minutus: moment(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date(moment().add(1, 'hour').format()) : new Date(updateHabit.executionDateEnd)).format("mm"),
    AMPM: moment.utc(updateHabit?.scheduled_type === "UNSPECIFIED" ? new Date(moment().add(1, 'hour').format()) : new Date(updateHabit.executionDateEnd)).format("A"),
  });
  console.log("endTimeValuegroup", endTimeValuegroup);
  const [rangePickerType, setRangePickerType] = useState(
    RANGEPICKERTYPE?.DATE_RANGE_PICKER
  );
  const [isValid, setIsValid] = useState("");
  const handleChange = (name, value) => {
    console.log("name, value", name, value, isStartTime);
    if (isStartTime) {
      setStartTimevalueGroups({
        ...startTimevalueGroups,
        [name]: value,
      });
    } else {
      // setIsValid(false);
      setEndTimeValuegroup({
        ...endTimeValuegroup,
        [name]: value,
      });
    }
  };
  const modalClosehandler = () => {
    const convetedStartDate = moment(startDate).startOf('day').format("yyyy-MM-DDT");
    const convetedEndDate = moment(startDate).endOf('day').format("yyyy-MM-DDT");

    let executionDate = moment(convetedStartDate, "yyyy-MM-DDT").set({
      hour: startTimevalueGroups.hour,
      minutes: startTimevalueGroups.minutus
    }).format();
    let executionEnddate = moment(convetedEndDate, "yyyy-MM-DDT").set({
      hour: endTimeValuegroup.hour,
      minutes: endTimeValuegroup.minutus
    }).format();
    if (specifiedTime === TIME_BASE.UNSPECIFIED_TIME) {
      executionDate = moment(convetedStartDate, "yyyy-MM-DDT").startOf('day').format()
      executionEnddate = moment(convetedEndDate, "yyyy-MM-DDT").endOf('day').format()
    }

    if (
      moment(new Date(executionDate)) <
      moment(new Date(executionEnddate))
    ) {
      if (updateHabit?.type === "HABIT_DRAGGED") {
        let updateHabitData = {
          uid: localStorage.getItem("userDetail"),
          id: updateHabit.habitId,
          "type": "HABIT_DRAGGED",
          "gaStart": updateHabit?.gaStart,
          "gaEnd": updateHabit?.gaEnd,
          "habit": {
            executionDate: executionDate,
            executionDateEnd: executionEnddate,
            "active": "false",
            scheduled_type: specifiedTime,
            "goalStartDate": updateHabit?.goalStartDate,
            "goalEndDate": updateHabit?.goalEndDate
          }
        };
        updateHabitActionCalender(updateHabitData).then((res) => {
          setUpdateHabit({});
          console.log("getCalenderDataSlice.getHabitData", getCalenderDataSlice.getHabitData);
          let tempUpdateHabitData = getCalenderDataSlice.getHabitData.map(
            (ele) => {
              if (ele._id === updateHabit._id) {
                return {
                  ...ele,
                  scheduled_type: specifiedTime,
                  executionDate: executionDate,
                  executionDateEnd: executionEnddate,
                };
              }
              return ele;
            }
          );
          dispatch(storeGetHabitData(tempUpdateHabitData));
          getGrowthd && getGrowthd()
          getCalendars && getCalendars()
        });
      } else {
        let updateHabitData = {
          uid: localStorage.getItem("userDetail"),
          id: updateHabit._id,
          type: "HABIT",
          habit: {
            calenderStatus: updateHabit?.calenderStatus,
            executionDate: executionDate,
            executionDateEnd: executionEnddate,
            scheduled_type: specifiedTime,
          },
        };
        updateHabitActionCalender(updateHabitData).then((res) => {
          setUpdateHabit({});
          let tempUpdateHabitData = getCalenderDataSlice.getHabitData.map(
            (ele) => {
              if (ele._id === updateHabit._id) {
                return {
                  ...ele,
                  scheduled_type: specifiedTime,
                  executionDate: executionDate,
                  executionDateEnd: executionEnddate,
                };
              }
              return ele;
            }
          );
          dispatch(storeGetHabitData(tempUpdateHabitData));
        });
      }

      setIsSetDurationHabit(false);
      setIsFrequency(false);
    } else {
      setIsValid(true);
      setIsSetDurationHabit(true);
      setIsFrequency(true);
    }
  };
  return (
    <CalanderHabitDialog
      open={isFrequency}
      onClose={() => {
        if (
          FREQUENCY_TYPE.DAILY === frequency ||
          FREQUENCY_TYPE.WEEKLY === frequency
        ) {
          modalClosehandler();
        } else {
          console.log("helo");
          setIsSetDurationHabit(false);
          setIsFrequency(false);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        // "& .MuiPaper-root": {
        //   minHeight:
        //     frequency === ""
        //       ? "135px"
        //       : FREQUENCY_TYPE.DAILY === frequency
        //       ? FREQUENCY_TYPE.DAILY === frequency &&
        //         specifiedTime === TIME_BASE.UNSPECIFIED_TIME
        //         ? "307px"
        //         : isValid
        //         ? "520px"
        //         : "450px"
        //       : FREQUENCY_TYPE.WEEKLY === frequency &&
        //         specifiedTime === TIME_BASE.UNSPECIFIED_TIME
        //       ? "320px"
        //       : isValid
        //       ? "600px"
        //       : "570px",
        //   minWidth: frequency === "" ? "135px" : "384px",
        //   height: 'auto'
        // },
      }}
    >
      <Box
        style={{
          padding: "20px 20px 5px 20px",
          background: "white",
          borderRadius: "12px",
        }}
      >
        <Box className={clsx(classes.main_box)}>
          <div className={classes.DateAndTimewrapper}>
            <img src={Clock} />
            <Typography
              variant="body2"
              sx={{ color: "#190D1A", fontSize: "17px" }}
            >
              Frequency
            </Typography>
          </div>
          <div
            className={classes.divider_box}
          // onClick={() => {
          //   setFrequency(FREQUENCY_TYPE.DAILY);
          // }}
          >
            <Typography variant="p">Daily</Typography>
            {FREQUENCY_TYPE.DAILY === frequency && (
              <img
                style={{ width: "22px", height: "22px" }}
                src={RightIcon}
                alt=""
              />
            )}
          </div>
          {FREQUENCY_TYPE.DAILY === frequency && (
            <div
              className={classes.boxDuration}
              style={{
                height: specifiedTime === TIME_BASE.UNSPECIFIED_TIME && "178px",
              }}
            >
              <div className={classes.setTimeBox}>
                <div
                  className={classes.time_based_event}
                  onClick={() => {
                    setSpecifiedTime(TIME_BASE.TIME_BASED);
                  }}
                  style={{
                    background:
                      specifiedTime === TIME_BASE.TIME_BASED
                        ? "#ffffff"
                        : "transparent",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text_size}
                    style={{
                      color:
                        specifiedTime === TIME_BASE.TIME_BASED
                          ? "#9747FF"
                          : "black",
                    }}
                  >
                    Time-based
                  </Typography>
                </div>
                <div
                  className={classes.time_based_event}
                  onClick={() => {
                    setSpecifiedTime(TIME_BASE.UNSPECIFIED_TIME);
                  }}
                  style={{
                    background:
                      specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                        ? "#ffffff"
                        : "transparent",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text_size}
                    style={{
                      color:
                        specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                          ? "#9747FF"
                          : "black",
                    }}
                  >
                    Unspecified time
                  </Typography>
                </div>
              </div>
              {specifiedTime === TIME_BASE.TIME_BASED ? (
                <div style={{ fontSize: "16px" }}>
                  <div className={classes.unspecified}>
                    <div style={{ width: "100%" }}>
                      <Typography variant="small">Starts</Typography>
                      <div
                        className={classes.dateTimeRangePickerWrapper}
                        style={{ paddingTop: "8px" }}
                        onClick={() => {
                          setIsStartTime(true);
                          setIsEndTime(false);
                        }}
                      >
                        <div
                          className={classes.dateTimeRangePickerSubWrapper}
                          style={{
                            borderColor: isStartTime && "#9747FF",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#190D1A",
                            }}
                            onClick={() => {
                              setRangePickerType(
                                RANGEPICKERTYPE.TIME_RANGE_PICKER
                              );
                              setTimePicker(TIME_PICKER.TIME_PICKER_START);
                              setDatePicker(DATE_PICKER.DATE_PICKER_START);
                            }}
                          >
                            {`${startTimevalueGroups.hour}:${startTimevalueGroups.minutus}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Typography variant="small">Ends</Typography>
                      <div
                        className={classes.dateTimeRangePickerWrapper}
                        style={{ paddingTop: "8px" }}
                        onClick={() => {
                          setIsEndTime(true);
                          setIsStartTime(false);
                        }}
                      >
                        <div
                          className={classes.dateTimeRangePickerSubWrapper}
                          style={{
                            borderColor: isEndTime && "#9747FF",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#190D1A",
                            }}
                            onClick={() => {
                              setRangePickerType(
                                RANGEPICKERTYPE.TIME_RANGE_PICKER
                              );
                              setTimePicker(TIME_PICKER.TIME_PICKER_END);
                              setDatePicker(DATE_PICKER.DATE_PICKER_END);
                            }}
                          >
                            {`${endTimeValuegroup.hour}:${endTimeValuegroup.minutus}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isStartTime && (
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={startTimevalueGroups
                      }
                      // itemHeight={25}
                      height={130}
                      // padding={10}
                      onChange={handleChange}
                    />
                  )}
                  {isEndTime && (
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={endTimeValuegroup}
                      height={130}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ) : (
                <div className={classes.unspecified}>
                  <div style={{ width: "100%" }}>
                    <Typography variant="small">Starts</Typography>
                    <div
                      className={classes.dateTimeRangePickerWrapper}
                      style={{ paddingTop: "8px" }}
                    >
                      <div className={classes.dateTimeRangePickerSubWrapper}>
                        <Typography variant="body2">
                          {" "}
                          {Object.keys(updateHabit).length
                            ? moment
                              .utc(new Date(updateHabit.executionDate))
                              .format("DD MMM YYYY")
                            : moment(startDate).format("DD MMM YYYY")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Typography variant="small">Ends</Typography>
                    <div
                      className={classes.dateTimeRangePickerWrapper}
                      style={{ paddingTop: "8px" }}
                    >
                      <div className={classes.dateTimeRangePickerSubWrapper}>
                        <Typography variant="body2">
                          {Object.keys(updateHabit).length
                            ? moment
                              .utc(new Date(updateHabit.executionDate))
                              .format("DD MMM YYYY")
                            : moment(startDate).format("DD MMM YYYY")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  paddingTop: "15px",
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="p">
                  {isValid && "Start Time should be less than End Time"}
                </Typography>
              </div>
            </div>
          )}
          <div
            className={classes.divider_box}
          // onClick={() => {
          //   setFrequency(FREQUENCY_TYPE.WEEKLY);
          // }}
          >
            <Typography variant="p">Weekly</Typography>
            {FREQUENCY_TYPE.WEEKLY === frequency && (
              <img
                style={{ width: "22px", height: "22px" }}
                src={RightIcon}
                alt=""
              />
            )}
          </div>
          {FREQUENCY_TYPE.WEEKLY === frequency && (
            <div
              className={classes.boxDuration}
              style={{
                height: specifiedTime === TIME_BASE.UNSPECIFIED_TIME && "178px",
              }}
            >
              <div className={classes.setTimeBox}>
                <div
                  className={classes.time_based_event}
                  onClick={() => {
                    setSpecifiedTime(TIME_BASE.TIME_BASED);
                  }}
                  style={{
                    background:
                      specifiedTime === TIME_BASE.TIME_BASED
                        ? "#ffffff"
                        : "transparent",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text_size}
                    style={{
                      color:
                        specifiedTime === TIME_BASE.TIME_BASED
                          ? "#9747FF"
                          : "black",
                    }}
                  >
                    Time-based
                  </Typography>
                </div>
                <div
                  className={classes.time_based_event}
                  onClick={() => {
                    setSpecifiedTime(TIME_BASE.UNSPECIFIED_TIME);
                  }}
                  style={{
                    background:
                      specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                        ? "#ffffff"
                        : "transparent",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text_size}
                    style={{
                      color:
                        specifiedTime === TIME_BASE.UNSPECIFIED_TIME
                          ? "#9747FF"
                          : "black",
                    }}
                  >
                    Unspecified time
                  </Typography>
                </div>
              </div>
              {specifiedTime === TIME_BASE.TIME_BASED ? (
                <div style={{ fontSize: "16px" }}>
                  <Typography variant="body2">
                    {`Event will occur every week on ${updateHabit.days.map(firstLetterUpper).join(", ")}`}
                  </Typography>
                  <Box className={classes.radioWrapper}>
                    {days?.map((day, index) => {
                      return (
                        <CustomCheckBox
                          key={index}
                          sx={{ margin: "6px 0px" }}
                          icon={
                            <CheckBoxInputDay
                              placeholder={day.slice(0, 3).toLowerCase()}
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  color: 'grey'
                                }
                              }}
                            />
                          }
                          // onChange={() => {
                          //   setselectedDayHandler(day);
                          // }}
                          checked={
                            selectedDaystate?.find((o) => o === day)
                              ? true
                              : false
                          }
                          checkedIcon={
                            <CheckBoxInputCheckedDay
                              placeholder={day.slice(0, 3).toLowerCase()}
                            />
                          }
                        />
                      );
                    })}
                  </Box>
                  <div className={classes.unspecified}>
                    <div style={{ width: "100%" }}>
                      <Typography variant="small">Starts</Typography>
                      <div
                        className={classes.dateTimeRangePickerWrapper}
                        style={{ paddingTop: "8px" }}
                        onClick={() => {
                          setIsStartTime(true);
                          setIsEndTime(false);
                        }}
                      >
                        <div
                          className={classes.dateTimeRangePickerSubWrapper}
                          style={{
                            borderColor: isStartTime && "#9747FF",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#190D1A",
                            }}
                            onClick={() => {
                              setRangePickerType(
                                RANGEPICKERTYPE.TIME_RANGE_PICKER
                              );
                              setTimePicker(TIME_PICKER.TIME_PICKER_START);
                              setDatePicker(DATE_PICKER.DATE_PICKER_START);
                            }}
                          >
                            {`${startTimevalueGroups.hour}:${startTimevalueGroups.minutus}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Typography variant="small">Ends</Typography>
                      <div
                        className={classes.dateTimeRangePickerWrapper}
                        style={{ paddingTop: "8px" }}
                        onClick={() => {
                          setIsEndTime(true);
                          setIsStartTime(false);
                        }}
                      >
                        <div
                          className={classes.dateTimeRangePickerSubWrapper}
                          style={{
                            borderColor: isEndTime && "#9747FF",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#190D1A",
                            }}
                            onClick={() => {
                              setRangePickerType(
                                RANGEPICKERTYPE.TIME_RANGE_PICKER
                              );
                              setTimePicker(TIME_PICKER.TIME_PICKER_END);
                              setDatePicker(DATE_PICKER.DATE_PICKER_END);
                            }}
                          >
                            {`${endTimeValuegroup.hour}:${endTimeValuegroup.minutus}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isStartTime && (
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={
                        timePicker === TIME_PICKER.TIME_PICKER_START
                          ? startTimevalueGroups
                          : endTimeValuegroup
                      }
                      // itemHeight={25}
                      height={130}
                      // padding={10}
                      onChange={handleChange}
                    />
                  )}
                  {isEndTime && (
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={
                        timePicker === TIME_PICKER.TIME_PICKER_START
                          ? endTimeValuegroup
                          : startTimevalueGroups
                      }
                      height={130}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ) : (
                <>
                  <Typography variant="body2">
                    {`Event will occur every week on ${updateHabit.days.map(firstLetterUpper).join(", ")}`}
                  </Typography>
                  <Box className={classes.radioWrapper_un}>
                    {days?.map((day, index) => {
                      return (
                        <CustomCheckBox
                          key={index}
                          sx={{ margin: "6px 0px" }}
                          icon={
                            <CheckBoxInputDay
                              placeholder={day.slice(0, 3).toLowerCase()}
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  color: 'grey'
                                }
                              }}
                            />
                          }
                          // onChange={() => {
                          //   setselectedDayHandler(day);
                          // }}
                          checked={
                            selectedDaystate?.find((o) => o === day)
                              ? true
                              : false
                          }
                          checkedIcon={
                            <CheckBoxInputCheckedDay
                              placeholder={day.slice(0, 3).toLowerCase()}
                            />
                          }
                        />
                      );
                    })}
                  </Box>
                </>
              )}
              <div
                style={{
                  paddingTop: "15px",
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="p">
                  {isValid && "Start Time should be less than End Time"}
                </Typography>
              </div>
            </div>
          )}
          <ChipSaveButton
            style={{ margin: '10px 0' }}
            onSave={() => {
              if (
                FREQUENCY_TYPE.DAILY === frequency ||
                FREQUENCY_TYPE.WEEKLY === frequency
              ) {
                modalClosehandler();
              } else {
                console.log("helo");
                setIsSetDurationHabit(false);
                setIsFrequency(false);
              }
            }}
          />
        </Box>
      </Box>
    </CalanderHabitDialog>
  );
};

export default Frequency;
