import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AllButton,
  AllFavourite,
  FavouriteButton,
} from "../../components/GoalComponent/GoalButton";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import CheckboxStartIcon from "../../assets/Svg/CheckboxStartIcon.svg";
import CheckedCheckboxStart from "../../assets/Svg/CheckedCheckboxStart.svg";
import classes from "./StepOneAll.module.css";
import { GOALTYPE } from "../../Constant/GoalConstant";
import { goalsAllDiamension } from "../../services/auth";
import { useDispatch } from "react-redux";
import {
  Step,
  StepsWrapper,
} from "../../components/GoalComponent/GoalCustomDiv";
import { imageURLRender } from "../../utils";
import { ConfirmationClose } from "../GoalListPage/AddGoalModal";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";
import { AddNewGoalDialogTitle } from "../../components/AddNewGoal/AddNewGoal";

function StepOneAll({
  onNext,
  setTypeGoal,
  typeGoal,
  getGoalDetail,
  goalDetail,
  handleClose,
  setselectedDiamenstion,
  setselectedDiamenstionImage
}) {
  useEffect(() => {
    const user = localStorage.getItem("userDetail");
    goalsAllDiamension(user)
      .then((data) => {
        setGoalAllDiamension(data?.allPercentage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisGoalDialmension(true);
      });
  }, []);
  // const matches = useMediaQuery("(max-height:750px)");
  const dispatch = useDispatch();
  const [goalAllDiamension, setGoalAllDiamension] = useState([]);
  const [isGoalDialmension, setisGoalDialmension] = useState(false);
  const [questionCategoryId, setquestionCategoryId] = useState("");
  const goalDiamenstionHandler = (_id, image) => {
    setquestionCategoryId(_id?.questionCategoryId);
    getGoalDetail("questionCategoryId", "");
    image && setselectedDiamenstionImage(image);
    setselectedDiamenstion(_id?.questionCategory);
    // dispatch(updateSelectedGoalDiamension(_id?.questionCategory));
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose}/>
      <div className={classes.stepOneWrapper}>
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            sx={{ color: "#9747FF !important" }}
            variant="body3"
            className={classes.progressTittle}
          >
            Choose dimension
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div style={{ margin: "32px 0px 10px 0px" }}>
          <Typography variant="body4">Select a dimension</Typography>
        </div>
        <AllFavourite>
          {" "}
          <AllButton
            onClick={() => {
              setTypeGoal(GOALTYPE.ALL_DIAMENSTIONGOAL);
            }}
            variant={
              typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL ? "contained" : null
            }
          >
            All
          </AllButton>
          <FavouriteButton
            onClick={() => {
              setTypeGoal(GOALTYPE.FAVEROUITE_DIAMENSTIONGOAL);
            }}
            variant={
              typeGoal === GOALTYPE.FAVEROUITE_DIAMENSTIONGOAL
                ? "contained"
                : null
            }
          >
            {" "}
            Favourite
          </FavouriteButton>
        </AllFavourite>
        {!isGoalDialmension ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : goalAllDiamension.length === 0 ? (
          <div className={classes.dataNotFoundSubWrapper}>
            <Typography variant="h5">
              No dimension found, Start giving the test
            </Typography>
          </div>
        ) : (
          <>
            <div style={{
              maxHeight: '48vh',
              overflowY: 'scroll',
              width: '100%'
            }}>
              <Grid
                container
                columnSpacing={1.5}
                rowSpacing={1.5}
                sx={{ overflowY: "auto" }}
                className={classes.all_goals}
              >
                {goalAllDiamension?.map((diamension, index) => {
                  const { _id, data } = diamension;
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                      <div
                        onClick={() => {
                          goalDiamenstionHandler(_id, diamension?.data[0]?.url);
                        }}
                        className={
                          _id?.questionCategoryId === questionCategoryId ||
                            goalDetail?.questionCategoryId === _id?.questionCategoryId
                            ? classes.checkedCustomRadio
                            : classes.customRadio
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={
                              diamension?.data[0].url === "/public/images/"
                                ? CheckboxStartIcon
                                : imageURLRender(diamension?.data[0]?.url)
                            }
                            alt=""
                          />
                          <Typography
                            variant="body2"
                            className={classes.checkBoxText}
                            sx={{
                              color:
                                (_id?.questionCategoryId === questionCategoryId ||
                                  goalDetail?.questionCategoryId ===
                                  _id?.questionCategoryId) &&
                                "#9747FF",
                            }}
                          >
                            {_id?.questionCategory}
                          </Typography>
                        </div>
                        {data[0]?.description && (
                          <HTMLTooltip
                            title={data[0]?.description}
                          />
                        )}

                        {/* <img src={CheckboxEndIcon} alt="" /> */}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "0px",
                width: "100%",
                marginTop: "10px",
                zIndex: "1",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "column",
                
              }}
            >
              <Button
                className={classes.nextButton}
                variant="outlined"
                onClick={() => {
                  onNext();
                  questionCategoryId === ""
                    ? getGoalDetail(
                      "questionCategoryId",
                      goalDetail?.questionCategoryId
                    )
                    : getGoalDetail("questionCategoryId", questionCategoryId);
                }}
                disabled={
                  questionCategoryId === ""
                    ? goalDetail?.questionCategoryId === undefined
                      ? true
                      : false
                    : goalDetail?.questionCategoryId === undefined
                      ? true
                      : false
                }
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
    </>

  );
}
export default StepOneAll;
