import React, { useEffect, useState } from "react";
import classes from "./GrowthSidebar.module.css";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SmallEngagement from "../../assets/Svg/SmallEngagement.svg";
import {ReactComponent as Sparkles} from "../../assets/Svg/Sparkles.svg";
import XP from "../../assets/Svg/XP.svg";
import PlusIcon from "../../assets/Svg/PlusIcon.svg";
import styled from "@emotion/styled";
import { CardContent, MainCard } from "../../components/GoalComponent/GoalDialog";
import GrowthAreaDialog from "../../components/GoalAddMoreDialog/GrowthAreaDialog/GrowthAreaDialog";
import { editPreSelectedGrowth, getGrowthData, goalCreateHabitAction, } from "../../services/auth";
import { storeGetGrowthData, updateIsGetGrowthData, } from "../../Store/Reducers/goalGrowthCalendar";
import ActionDialog from "../../components/GoalAddMoreDialog/ActionDialog/ActionDialog";
import HabitDialog from "../../components/GoalAddMoreDialog/HabitDialog/HabitDialog";
import { updateHabitAddMore } from "../../Store/Reducers/goalHabitData";
import { updateIsAddMore } from "../../Store/Reducers/goalActionData";
import { FindAndGetXPLevel, imageURLRender, randomXP } from "../../utils";
import moment from "moment";

const AccordionItem = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const { getAllGrowthData } = useSelector((state) => state);
  const disapatch = useDispatch();
  const [segmentBoxValue, setSegmentBoxValue] = useState("growth_area");
  const [isAddMoreGrowthAreaOpen, setisAddMoreGrowthAreaOpen] = useState(false);
  const [idDialog, setidDialog] = useState("");
  const [isHabitOpen, setIsHabitOpen] = useState(false);
  const [isActionOPen, setIsActionOPen] = useState(false);

  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;
  const dragStart = (e, item) => {
    console.log("dragStart ====item", item);
    let element = e.currentTarget;
    element.classList.add("dragged");
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
    e.dataTransfer.effectAllowed = "move";
  };
  const dragEnter = (e, position) => {
    e.preventDefault();
    let element = e.currentTarget;
    element.classList.add("dragged-over");
    e.dataTransfer.dropEffect = "move";
  };
  const addNewGrowthAreaHandler = (selfInput, preSelectedGrowthPerams) => {
    let tempNewGrowthAreaState = [];
    let tempmeargeSelectedGrowth = [];
    if (selfInput?.text) {
      tempNewGrowthAreaState = [...preSelectedGrowthPerams, selfInput];
    } else {
      tempNewGrowthAreaState = preSelectedGrowthPerams;
    }
    let tempGetGrowthCalendarData = [];
    const newGetAllGrowthData = getAllGrowthData?.GetGrowthData?.map((data) => {
      if (idDialog === data._id) {
        tempGetGrowthCalendarData.push(
          ...data.preSelectedGrowth,
          ...tempNewGrowthAreaState
        );
        return {
          ...data,
          preSelectedGrowth: tempGetGrowthCalendarData,
        };
      }
      return data;
    });
    disapatch(storeGetGrowthData(newGetAllGrowthData));
    const user = localStorage.getItem("userDetail");
    let newGrowthAreaData = {
      uid: user,
      goalId: idDialog,
      preSelectedGrowth: tempGetGrowthCalendarData,
      preSelectedGrowthComplete: [],
    };
    editPreSelectedGrowth(newGrowthAreaData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    let growthData = {
      uid: localStorage.getItem("userDetail"),
      archive: false,
      status: "IN_PROGRESS",
    };
    getGrowthData(growthData)
      .then((res) => {
        console.log("res?.data", res?.data);
        dispatch(storeGetGrowthData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsGetGrowthData(true));
      });
  };
  const addMoreActionHandler = (actionState) => {
    let tempActionState = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionId: uuidv4(),
        actionDetail: item?.actionDetail,
        actionName: item.actionName,
        active: "true",
        scheduled_type: "DEFAULT",
      });
    });
    let tempGetGrowthCalendarData = [];
    getAllGrowthData?.GetGrowthData?.map((data) => {
      tempGetGrowthCalendarData.push({
        actions:
          idDialog === data._id
            ? [...data.actions, ...tempActionState]
            : data.actions,
        actionsArchived: data.actionsArchived,
        actionsComplete: data.actionsComplete,
        archive: data.archive,
        habits: data.habits,
        habitsArchived: data.habitsArchived,
        habitsComplete: data.habitsComplete,
        increase: data.increase,
        mySelf: data.mySelf,
        others: data.others,
        preSelectedGrowth: data.preSelectedGrowth,
        preSelectedGrowthComplete: data.preSelectedGrowthComplete,
        questionCategory: data.questionCategory,
        questionCategoryId: data.questionCategoryId,
        status: data.status,
        tbd: data.tbd,
        uid: data.uid,
        url: data.url,
        _id: data._id,
      });
    });
    disapatch(storeGetGrowthData(tempGetGrowthCalendarData));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: props.item.questionCategoryId,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        console.log(res);
        let growthData = {
          uid: localStorage.getItem("userDetail"),
          archive: false,
          status: "IN_PROGRESS",
        };
        if (createActiondata) {
          getGrowthData(growthData)
            .then((res) => {
              disapatch(storeGetGrowthData(res?.data));
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              disapatch(updateIsGetGrowthData(true));
            });
        }
        disapatch(updateIsAddMore(true));
      })
      .catch((err) => console.log(err))
      .finally(() => { });
  };
  const getUniquePreselectedGrowthArea = (array1 = [], array2 = []) => {
    let arr3 = [].concat(
      array1.filter((obj1) => array2.every((obj2) => obj1.text !== obj2.point)),
      array2.filter((obj2) => array1.every((obj1) => obj2.point !== obj1.text))
    );
    const abc = Object.values(
      arr3.reduce((acc, cur) => Object.assign(acc, { [cur.point]: cur }), {})
    );
    return abc
  };
  const tempGoalaction = [...(item?.actions || []), ...(item?.actionsComplete || [])];
  const getUniquePreselectedActions = (array1) => {
    const recommended = [...(item?.actionRecommended || [])]
    return recommended.filter(obj2 => array1.every((obj1) => obj1.actionName !== obj2.point))
  };
  const addMoreHabitHandler = (habits) => {
    const addMoreHabitState = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: props.item.questionCategoryId,
      type: "HABIT",
      habit: [habits],
    };
    let tempGetGrowthCalendarData = [];
    getAllGrowthData?.GetGrowthData?.map((data) => {
      tempGetGrowthCalendarData.push({
        actions: data.actions,
        actionsArchived: data.actionsArchived,
        actionsComplete: data.actionsComplete,
        archive: data.archive,
        habits: [...data.habits, habits],
        habitsArchived: data.habitsArchived,
        habitsComplete: data.habitsComplete,
        increase: data.increase,
        mySelf: data.mySelf,
        others: data.others,
        preSelectedGrowth: data.preSelectedGrowth,
        preSelectedGrowthComplete: data.preSelectedGrowthComplete,
        questionCategory: data.questionCategory,
        questionCategoryId: data.questionCategoryId,
        status: data.status,
        tbd: data.tbd,
        uid: data.uid,
        url: data.url,
        _id: data._id,
      });
    });
    disapatch(storeGetGrowthData(tempGetGrowthCalendarData));
    goalCreateHabitAction(addMoreHabitState)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    disapatch(updateHabitAddMore(true));
    setIsHabitOpen(false);
    let growthData = {
      uid: localStorage.getItem("userDetail"),
      archive: false,
      status: "IN_PROGRESS",
    };
    getGrowthData(growthData)
      .then((res) => {
        dispatch(storeGetGrowthData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsGetGrowthData(true));
      });
  };
  const tempGoalgrowth = [...(item?.preSelectedGrowth || []), ...(item?.preSelectedGrowthComplete || [])];
  const tempGrowthAreaSuggested = tempGoalgrowth.filter(
    (item) => item.type === "SUGGESTED"
  );
  const addNewGrowthAreaHandlerFormRecommened = (value) => {
    let tempNewGrowthArea = [];
    let tempNewGrowthAreaState = [];
    if (value) {
      tempNewGrowthArea = [value];
      tempNewGrowthAreaState = [...item?.preSelectedGrowth, ...tempNewGrowthArea];
    } else {
      tempNewGrowthAreaState = [...item?.preSelectedGrowth];
    }
    // setPreSelectedState(tempNewGrowthAreaState);
    const user = localStorage.getItem("userDetail");
    let newGrowthAreaData = {
      uid: user,
      goalId: props.item?._id,
      preSelectedGrowth: tempNewGrowthAreaState,
      preSelectedGrowthComplete: item?.preSelectedGrowthComplete,
    };
    editPreSelectedGrowth(newGrowthAreaData)
      .then((res) => {
        let growthData = {
          uid: localStorage.getItem("userDetail"),
          archive: false,
          status: "IN_PROGRESS",
        };
        getGrowthData(growthData)
          .then((res) => {
            dispatch(storeGetGrowthData(res?.data));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(updateIsGetGrowthData(true));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addActionHandler = (actionState, questionCategoryId) => {
    let tempActionState = [];
    // let tempActionStateForRedux = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
      });
    });
    // actionState.forEach((item) => {
    //   tempActionStateForRedux.push({
    //     actionDetail: item?.actionDetail,
    //     // actionId: uuidv4(),
    //     actionName: item.actionName,
    //     status: "IN-PROGRESS",
    //     executionDate: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //     executionDateEnd: moment(new Date())
    //       .add({ hours: 1 })
    //       .format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //   });
    // });
    // let tempActionAddedAction = [
    //   ...ActiondataSlice.actionData,
    //   ...tempActionStateForRedux,
    // ];
    // dispatch(updateActionData(tempActionAddedAction));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: questionCategoryId,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        dispatch(updateIsAddMore(true));
        let growthData = {
          uid: localStorage.getItem("userDetail"),
          archive: false,
          status: "IN_PROGRESS",
        };
        getGrowthData(growthData)
          .then((res) => {
            dispatch(storeGetGrowthData(res?.data));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(updateIsGetGrowthData(true));
          });
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  return (
    <AccordionDetails>
      <Grid container className={classes.growthAccordianSegmentControl}>
        <Box
          className={`${classes.boxTypography} ${segmentBoxValue === "growth_area" ? classes.boxActive : ""
            }`}
          onClick={() => setSegmentBoxValue("growth_area")}
        >
          <Typography
            className={`${classes.boxTypographyP} ${segmentBoxValue === "growth_area"
              ? classes.boxTypographyActiveP
              : ""
              }`}
          >
            Growth areas
          </Typography>
        </Box>
        <Box
          className={`${classes.boxTypography} ${segmentBoxValue === "actions" ? classes.boxActive : ""
            }`}
          onClick={() => setSegmentBoxValue("actions")}
        >
          <Typography
            className={`${classes.boxTypographyP} ${segmentBoxValue === "actions" ? classes.boxTypographyActiveP : ""
              }`}
          >
            Actions
          </Typography>
        </Box>
        <Box
          className={`${classes.boxTypography} ${segmentBoxValue === "habits" ? classes.boxActive : ""
            }`}
          onClick={() => setSegmentBoxValue("habits")}
        >
          <Typography
            className={`${classes.boxTypographyP} ${segmentBoxValue === "habits" ? classes.boxTypographyActiveP : ""
              }`}
          >
            Habits
          </Typography>
        </Box>
      </Grid>

      <Grid className={classes.growthAccordionListContainer}>
        <Grid className={classes.growthAccordionDataContent}>
          <Typography className={classes.dataContentTypography}>
            Selected
          </Typography>
          {segmentBoxValue === "growth_area" &&
            item?.preSelectedGrowth.map((t, index) => {
              if (t.active === "true") {
                return (
                  <div className={classes.customRadio} style={{ width: '100%' }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "all-scroll",
                        width: '100%'
                      }}
                      draggable
                      onDragStart={(e) => {
                        dragStart(e, {
                          questionCategoryId: props.item.questionCategoryId,
                          text: t.text,
                          type: "GA",
                          data: t,
                          cat: item,
                          name: props.item.questionCategory,
                          id: item._id,
                          preSelectedGrowth: props.item.preSelectedGrowth,
                          preSelectedGrowthComplete:
                            props.item.preSelectedGrowthComplete,
                        })
                      }}
                      onDragEnter={(e) => {
                        dragEnter(e, index);
                      }}
                    >
                      <Box
                        className={`${classes.dataContentBoxItem} ${segmentBoxValue === "actions"
                          ? classes.dataContentActionsBoxItem
                          : ""
                          }`}
                      >
                        {segmentBoxValue === "actions" && (
                          <Typography
                            variant="string"
                            component="div"
                            className={classes.dataContentTag}
                          >
                            <img src={XP} alt="" width="12" height="12" />
                            <span className={classes.dataContentSpan}>43</span>
                          </Typography>
                        )}
                        <Typography
                          variant="p"
                          className={classes.dataContentBoxTypography}
                        >
                          {t.text}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                );
              }
            })}
          {segmentBoxValue === "actions" &&
            item?.actions.map((newObj, index) => {
              if (newObj.active === "true") {
                const t = {...newObj}
                if(t.scheduled_type === "DEFAULT") {
                  t.executionDate = moment().format()
                  t.executionDateEnd = moment().add(1, 'hour').format()
                }
                const foundXp = FindAndGetXPLevel(t.level,false)
                return (
                  <div className={classes.customRadio} style={{ width: '100%' }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "all-scroll",
                        width: '100%'
                      }}
                      draggable
                      onDragStart={(e) => {
                        if(t.scheduled_type === "DEFAULT") {
                          t.executionDate = moment().format()
                          t.executionDateEnd = moment().add(1, 'hour').format()
                        }
                        dragStart(e, {
                          questionCategoryId: item.questionCategoryId,
                          text: t.actionName,
                          scheduled_type: t.scheduled_type,
                          actionDetail: t.actionDetail,
                          executionDate: t.executionDate,
                          executionDateEnd: t.executionDateEnd,
                          actionId: t.actionId,
                          type: "ACTION",
                          name: item.questionCategory,
                          id: t._id,
                          actions: item.actions,
                        })
                      }}
                      onDragEnter={(e) => {
                        dragEnter(e, index);
                      }}
                    >
                      <Box
                        className={`${classes.dataContentBoxItem} ${segmentBoxValue === "actions"
                          ? classes.dataContentActionsBoxItem
                          : ""
                          }`}
                      >
                        {segmentBoxValue === "actions" && foundXp && (
                          <Typography
                            variant="string"
                            component="div"
                            className={classes.dataContentTag}
                          >
                            <img src={XP} alt="" width="12" height="12" />
                            <span className={classes.dataContentSpan}>{foundXp}</span>
                          </Typography>
                        )}
                        <Typography
                          variant="p"
                          className={classes.dataContentBoxTypography}
                        >
                          {t.actionName}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                );
              }
            })}
          {segmentBoxValue === "habits" &&
            item?.habits.map((t, index) => {
              if (t.active === "true") {
                const foundXp = FindAndGetXPLevel(t.level,false)
                return (
                  <div className={classes.customRadio} style={{ width: '100%' }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "all-scroll",
                        width: '100%'
                      }}
                      draggable
                      onDragStart={(e) => {
                        dragStart(e, {
                          questionCategoryId: item.questionCategoryId,
                          text: t.habitName,
                          actionDetail: t.habitDetail,
                          executionDate: t.executionDate,
                          executionDateEnd: t.executionDateEnd,
                          habitId: t.habitId,
                          type: "habit",
                          name: item.questionCategory,
                          id: t._id,
                          habits: item.habits,
                        })
                        dragStart(e, {
                          calenderStatus: t.calenderStatus,
                          category: t.category,
                          category_id: t.category_id,
                          days: t.days,
                          executionDate: t.executionDate,
                          executionDateEnd: t.executionDateEnd,
                          goalEndDate: t.goalEndDate,
                          goalStartDate: t.goalStartDate,
                          actionDetail: t.habitDetail,
                          habitId: t.habitId,
                          text: t.habitName,
                          habitName: t.habitName,
                          type: "HABIT_DRAGGED",
                          habitScheduleType: t.habitScheduleType,
                          journal: t.journal,
                          level: t.level,
                          name: item.questionCategory,
                          questionCategoryId: t.questionCategoryId,
                          scheduled_type: t.scheduled_type,
                          id: t._id,
                          habits: item.habits,
                        })
                      }}
                      onDragEnter={(e) => {
                        dragEnter(e, index);
                      }}
                    >
                      <Box
                        className={`${classes.dataContentBoxItem} ${segmentBoxValue === "actions"
                          ? classes.dataContentActionsBoxItem
                          : ""
                          }`}
                      >
                        {segmentBoxValue === "habits" && foundXp && (
                          <Typography
                            variant="string"
                            component="div"
                            className={classes.dataContentTag}
                          >
                            <img src={XP} alt="" width="12" height="12" />
                            <span className={classes.dataContentSpan}>{foundXp}</span>
                          </Typography>
                        )}
                        <Typography
                          variant="p"
                          className={classes.dataContentBoxTypography}
                        >
                          {t.habitName}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                );
              }
            })}
        </Grid>
        <Grid className={classes.segmentButtonGrid}>
          <Typography
            variant="string"
            component="div"
            className={classes.segmentButtonText}
            onClick={() => {
              if (segmentBoxValue === "growth_area") {
                setisAddMoreGrowthAreaOpen(true);
                setidDialog(item._id);
              } else if (segmentBoxValue === "actions") {
                setIsActionOPen(true);
                setidDialog(item._id);
              } else if (segmentBoxValue === "habits") {
                setIsHabitOpen(true);
                setidDialog(item._id);
              }

            }}
          >
            <img src={PlusIcon} alt="" width="12" height="12" />
            <span className={classes.dataContentSpan}>Add More</span>
          </Typography>
        </Grid>
        {console.log("item", item)}
        {isAddMoreGrowthAreaOpen && idDialog === item._id && (
          <CustomAddNewGoalDialog
            open={isAddMoreGrowthAreaOpen}
            onClose={() => {
              setisAddMoreGrowthAreaOpen(false);
            }}
          >
            <MainCard
              sx={{
                minWidth: 250,
                borderRadius: "40px ",
                marginInline: "auto",
                height: "652px !important",
              }}
            >
              <CardContent>
                <GrowthAreaDialog
                  selectedQuestionCategoriesId={item.questionCategoryId}
                  goalName={item.questionCategory}
                  setisAddMoreGrowthAreaOpen={setisAddMoreGrowthAreaOpen}
                  preSelectedGrowth={item.preSelectedGrowth}
                  preSelectedGrowthComplete={item.preSelectedGrowthComplete}
                  addNewGrowthAreaHandler={addNewGrowthAreaHandler}
                ></GrowthAreaDialog>
              </CardContent>
            </MainCard>
          </CustomAddNewGoalDialog>
        )}
        {isActionOPen && idDialog === item._id && (
          <CustomAddNewGoalDialog
            open={isActionOPen}
            onClose={() => {
              setIsActionOPen(false);
            }}
          >
            <ActionDialog
              selectedQuestionCategoriesId={props.item.questionCategoryId}
              goalName={props.item.questionCategory}
              setIsActionOPen={setIsActionOPen}
              addMoreActionHandler={addMoreActionHandler}
            ></ActionDialog>
          </CustomAddNewGoalDialog>
        )}
        {isHabitOpen && idDialog === item._id && (
          <CustomAddNewGoalDialog
            open={isHabitOpen}
            onClose={() => {
              setIsHabitOpen(false);
            }}
          >
            <HabitDialog
              selectedQuestionCategoriesId={props.item.questionCategoryId}
              goalName={props.item.questionCategory}
              setIsHabitOpen={setIsHabitOpen}
              addMoreHabitHandler={addMoreHabitHandler}
            ></HabitDialog>
          </CustomAddNewGoalDialog>
        )}
      </Grid>

      {segmentBoxValue === "growth_area" && getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, item?.growthRecommended).length === 0
        ? null : segmentBoxValue === "growth_area" &&<Grid className={classes.growthAccordionListContainer}>
          <Grid className={classes.growthAccordionDataContent}>
            <Typography className={classes.dataContentTypographyRecommend}>
            <Sparkles /> Recommended
            </Typography>
            {
              getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, item?.growthRecommended).map((t, index) => {
                return (
                  <div className={classes.customRadio} style={{ width: '100%' }}
                    onClick={e => {
                      addNewGrowthAreaHandlerFormRecommened({
                        active: "true",
                        index: 0,
                        status: "IN_PROGRESS",
                        text: t.point,
                        type: "SUGGESTED"
                      })
                    }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "all-scroll",
                        width: '100%'
                      }}
                    >
                      <Box
                        className={`${classes.dataContentBoxItem} ${segmentBoxValue === "actions"
                          ? classes.dataContentActionsBoxItem
                          : ""
                          }`}
                      >
                        {segmentBoxValue === "actions" && (
                          <Typography
                            variant="string"
                            component="div"
                            className={classes.dataContentTag}
                          >
                            <img src={XP} alt="" width="12" height="12" />
                            <span className={classes.dataContentSpan}>43</span>
                          </Typography>
                        )}
                        <Typography
                          variant="p"
                          className={classes.dataContentBoxTypography}
                        >
                          {t.point}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                );
              })}
          </Grid>
        </Grid>}
      {segmentBoxValue === "actions" && getUniquePreselectedActions(tempGoalaction, item?.actionRecommended).length === 0
        ? null :segmentBoxValue === "actions" &&<Grid className={classes.growthAccordionListContainer}>
          <Grid className={classes.growthAccordionDataContent}>
            <Typography className={classes.dataContentTypographyRecommend}>
            <Sparkles /> Recommended
            </Typography>
            {
              getUniquePreselectedActions(tempGoalaction, item?.actionRecommended).map((t, index) => {
                const randomXPObject = randomXP()
                return (
                  <div className={classes.customRadio} style={{ width: '100%' }}
                    onClick={e => {
                      addActionHandler([{
                        actionId: t._id,
                        actionName: t.point,
                        actionDetail: "",
                        active: "true",
                        level: randomXPObject.level,
                        scheduled_type: "DEFAULT",
                      }], t.questionCategoryId)
                    }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "all-scroll",
                        width: '100%'
                      }}
                    >
                      <Box
                        className={`${classes.dataContentBoxItem} ${segmentBoxValue === "actions"
                          ? classes.dataContentActionsBoxItem
                          : ""
                          }`}
                      >
                        {segmentBoxValue === "actions" && (
                          <Typography
                            variant="string"
                            component="div"
                            className={classes.dataContentTag}
                          >
                            <img src={XP} alt="" width="12" height="12" />
                            <span className={classes.dataContentSpan}>{randomXPObject.xp}</span>
                          </Typography>
                        )}
                        <Typography
                          variant="p"
                          className={classes.dataContentBoxTypography}
                        >
                          {t.point}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                );
              })}
          </Grid>
        </Grid>}
    </AccordionDetails>
  );
};

const GrowthInnerAccordion = (props) => {
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const [expanded, setExpanded] = useState(
    getAllGrowthData?.GetGrowthData[0]?._id
  );
  const [expandedId, setExpandedId] = useState(
    getAllGrowthData?.GetGrowthData[0]?._id
  );

  return (
    <>
      {getAllGrowthData.GetGrowthData.length
        ? getAllGrowthData.GetGrowthData?.map((it, index) => (
          <Accordion
            expanded={expanded === it._id}
            className={classes.growthAccordion}
            onChange={(e) => {
              setExpanded(expandedId === it._id ? null : it._id)
              setExpandedId(expandedId === it._id ? null : it._id)
            }}
            key={it._id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid container className={classes.growthAccordianHeader}>
                <Typography
                  component="div"
                  className={classes.growthEngagement}
                // align={"justify"}
                >
                  <img
                    //src={SmallEngagement}
                    src={imageURLRender(it.url)}
                    width="24"
                    height="24"
                    alt="engagement"
                  />
                  <span className={clsx(classes.growthEngagementSpan, 'text-1lines')}>
                    {it.questionCategory}
                  </span>
                </Typography>
              </Grid>
            </AccordionSummary>
            {<AccordionItem {...props} item={it} />}
          </Accordion>
        ))
        : null}
    </>
  );
};

export default GrowthInnerAccordion;
