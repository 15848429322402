import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import {
    Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PersonalTestResult2 } from "../../core-ui/PersonalTestResults/PersonalTestResult2";
import { updateResultDimention, updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import ResultCongrats from "../../pages/TestResultsPages/ResultCongrates/ResultCongrats";
import { StepOne } from "../../pages/TestResultsPages/StepOne/StepOne";
const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
      }
    }
  `;
const CustomDialogResult = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 1400px;
        border-radius: 32px;
        height: 100%;
      }
    }
  `;


const StepperModal = ({
    UnlockGAAction,
    sendModalhandler,
    mainStateUpdateHandler,
    inaccurateHandler,
    feedBackDataHandler,
    Notification,
    updateNotificationState
}) => {
    const { resultAllDiamention } = useSelector(
        (state) => state
    );
    const {
        modalStep = false,
        sendmodalStep = false,
        congatesStep = false,
        isAskOtherModalOpen,
        modalData = {},
        selectedGrowthArr = [],
        selectedActionArr = [],
        finalSelectedGrowthArea = [],
        tempSelectedGrowthArea,
        tempSelectedAction,
        tempGrowhtArea,
        tempActionArea,
    } = resultAllDiamention
    const dispatch = useDispatch();

    const setmodalStep = val => dispatch(updateResultState({
        modalStep: val
    }))
    const setSendmodalStep = val => dispatch(updateResultState({
        sendmodalStep: val
    }))
    const setCongatesStep = val => dispatch(updateResultState({
        congatesStep: val
    }))

    const setIsAskOtherModalOpen = val => dispatch(updateResultState({
        isAskOtherModalOpen: val
    }))
    const setModalData = val => dispatch(updateResultState({
        modalData: val
    }))

    const setselectedGrowthArr = val => dispatch(updateResultState({
        selectedGrowthArr: val
    }))
    const setselectedActionArr = val => dispatch(updateResultState({
        selectedActionArr: val
    }))

    const setFinalSelectedGrowthArea = val => dispatch(updateResultState({
        finalSelectedGrowthArea: val
    }))

    const setResultAllDiamention = val => dispatch(updateResultState({
        ResultDiamention: val
    }))
  
    const getdeletedGrowrthArea = (array, growthArea, actionArea) => {
        console.log(
            "array1 = ",
            array,
            "array2 = ",
            growthArea,
            "array3 = ",
            actionArea
        );
        let deletedGrowth = array.map((item) => {
            growthArea.map((data) => {
                if (data.uniqueId === item.uniqueId) {
                    let likeGrowhtArea = item.growthFavorites.filter(
                        (ele) => ele.point !== data.point
                    );
                    item.growthFavorites = likeGrowhtArea;
                }
            });
            actionArea.map((data) => {
                if (data.uniqueId === item.uniqueId) {
                    let likeActionArea = item.actionFavorites.filter(
                        (ele) => ele.point !== data.point
                    );
                    item.actionFavorites = likeActionArea;
                }
            });
            return item;
        });
        return deletedGrowth;
    };
    const onHandleclose = () => {
        setselectedGrowthArr([...selectedGrowthArr, ...tempSelectedGrowthArea]);
        setselectedActionArr([...selectedActionArr, ...tempSelectedAction]);

        const tempFinalSelectedGrowthArea = [];
        modalData.data.forEach((item, index) => {
            const tempGrowth = tempGrowhtArea.filter(
                (data) => data.uniqueId === item.uniqueId && data.isChecked
            );
            const tempAction = tempActionArea.filter(
                (data) => data.uniqueId === item.uniqueId && data.isChecked
            );
            const growthdFav = tempGrowth
            ? [...item.growthFavorites, ...tempGrowth]
            : item.growthFavorites
            console.log("tempAction", tempAction);
            const objToPush = {
                description: item.description,
                actionFavorites: tempAction
                    ? [...item.actionFavorites, ...tempAction]
                    : item.actionFavorites,
                descriptionDetails: item.descriptionDetails,
                detailedDescription: item.detailedDescription,
                detailedDescriptionDetails: item.detailedDescriptionDetails,
                growthFavorites: [...growthdFav],
                score: item.score,
                percent: item.percent,
                url: item.url,
                uniqueId: item.uniqueId,
                unlockgrowthandactions: item.unlockgrowthandactions
            }
            tempFinalSelectedGrowthArea.push({
              ...objToPush
            });
            return item;
        });
        const tempGrowthUnSelected = tempGrowhtArea.filter(
            (data) => !data.isChecked
        );
        console.log("tempActionArea =====", tempActionArea);
        const tempActionUnSelected = tempActionArea.filter(
            (data) => !data.isChecked
        );
        console.log("tempActionUnSelected", tempActionUnSelected);
        console.log("tempActionUnSelected", tempActionUnSelected);
        const finalSelectedGrowthArea = getdeletedGrowrthArea(
            tempFinalSelectedGrowthArea,
            tempGrowthUnSelected,
            tempActionUnSelected
        );
        dispatch(updateResultDimention({
            questionCategoryId: modalData.questionCategoryId,
            data: finalSelectedGrowthArea
        }))
    }
    const handleClose = () => {
        onHandleclose()
        setIsAskOtherModalOpen(false);
        setmodalStep(false);
        setSendmodalStep(false);
        setCongatesStep(false);
    };
    if (modalStep) {
        return <CustomDialogResult
            open={isAskOtherModalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <PersonalTestResult2
                setmodalStep={setmodalStep}
                setSendmodalStep={setSendmodalStep}
                sendModalhandler={sendModalhandler}
                sendmodalStep={sendmodalStep}
                setIsAskOtherModalOpen={setIsAskOtherModalOpen}
                mainStateUpdateHandler={mainStateUpdateHandler}
                inaccurateHandler={inaccurateHandler}
                feedBackDataHandler={feedBackDataHandler}
                setCongatesStep={setCongatesStep}
                modalData={modalData}
                handleClose={handleClose}
                setModalData={setModalData}
            />
        </CustomDialogResult>
    }
    return <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted
        PaperProps={congatesStep ? {
            sx: {
                height: '80%'
            }
        } : {}}
    >
        {/* <PersonaltestResult
            ResultAllDiamention={resultAllDiamention?.ResultDiamention}
            setResultAllDiamention={setResultAllDiamention}
            setmodalStep={setmodalStep}
            modalData={modalData}
            setModalData={setModalData}
            setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            congatesStep={congatesStep}
            handleClose={handleClose}
            setselectedGrowthArr={setselectedGrowthArr}
            selectedGrowthArr={selectedGrowthArr}
            setselectedActionArr={setselectedActionArr}
            selectedActionArr={selectedActionArr}
            setFinalSelectedGrowthArea={setFinalSelectedGrowthArea}
            finalSelectedGrowthArea={finalSelectedGrowthArea}
            UnlockGAAction={UnlockGAAction}
            tempSelectedGrowthArea={tempSelectedGrowthArea}
            tempSelectedAction={tempSelectedAction}
            tempDeletedGrowthArea={tempDeletedGrowthArea}
            tempGrowhtArea={tempGrowhtArea}
            tempActionArea={tempActionArea}
            setTempSelectedGrowthArea={setTempSelectedGrowthArea}
            setTempSelectedAction={setTempSelectedAction}
            setTempDeletedGrowthArea={setTempDeletedGrowthArea}
            settempGrowhtArea={settempGrowhtArea}
            settempActionArea={settempActionArea}
            onHandleclose={onHandleclose}
            Notification={Notification}
            updateNotificationState={updateNotificationState}
        /> */}
        {congatesStep ? (
            <ResultCongrats
                modalData={modalData}
                setIsAskOtherModalOpen={setIsAskOtherModalOpen}
                handleClose={handleClose}
            />
        ) : (
            <StepOne
                setResultAllDiamention={setResultAllDiamention}
                setmodalStep={setmodalStep}
                setModalData={setModalData}
                handleClose={handleClose}
                setselectedGrowthArr={setselectedGrowthArr}
                selectedGrowthArr={selectedGrowthArr}
                setselectedActionArr={setselectedActionArr}
                selectedActionArr={selectedActionArr}
                setFinalSelectedGrowth={setFinalSelectedGrowthArea}
                finalSelectedGrowth={finalSelectedGrowthArea}
                UnlockGAAction={UnlockGAAction}
                // tempSelectedGrowthArea={tempSelectedGrowthArea}
                //       tempSelectedAction={tempSelectedAction}
                //       tempDeletedGrowthArea={tempDeletedGrowthArea}
                //       tempDeletedAction={tempDeletedAction}
                //       tempGrowhtArea={tempGrowhtArea}
                //       tempActionArea={tempActionArea}
                //       setTempSelectedGrowthArea={setTempSelectedGrowthArea}
                //       setTempSelectedAction={setTempSelectedAction}
                //       setTempDeletedGrowthArea={setTempDeletedGrowthArea}
                //       setTempDeletedAction={setTempDeletedAction}
                //       settempGrowhtArea={settempGrowhtArea}
                //       settempActionArea={settempActionArea}
                onHandleclose={onHandleclose}
                Notification={Notification}
                updateNotificationState={updateNotificationState}
            />
        )}
    </CustomDialog>
}

export default memo(StepperModal)