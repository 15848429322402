// singleResultLayout

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/SearchBar/SearchBar";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Input,
  InputAdornment,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classes from "./SingleResultLayout.module.css";
import Search from "../../assets/Svg/Search.svg";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { border, borderBottom, Box } from "@mui/system";
import {
  categoryPage,
  ResultAllTestDiamention,
  AllDiamentionByInvite,
  subcategoriesByInvite,
  ResultAllTestDiamentionNew,
  categoryPageNew,
} from "../../services/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeTestCategoriesList } from "../../Store/Reducers/testCategoriesList";
import { TYPEOFRESULTS } from "../../Constant/Invite";
import { useQuery } from "react-query";
import ResultCategoryCard, {
  LongMenu,
  TestcatagoriesComponent,
} from "../../components/ResultCategoryCard/ResultCategoryCard";
// import PesonalityResultSlider from "../../components/TestQuestionSlider/TestQuestionSlider";
import { TestQuestionSlider } from "../../components/TestQuestionSlider/TestQuestionSlider";

import ResultChart from "../../components/ResultChart/ResultChart";
import { useTheme } from "@emotion/react";
import { ResultCategoryCardWithImage } from "../../components/ResultCategoryCard/ResultCategoryCard";
// import PesonalityResultSlider from "../../components/TestQuestionSlider/TestQuestionSlider";
import Avatarone from "../../assets/Svg/Avatarone.svg";
import { PersonaltestResult } from "../../core-ui/PersonalTestResults/PersonaltestResult";
import { PersonalTestResult2 } from "../../core-ui/PersonalTestResults/PersonalTestResult2";
import { storeResultAllDiamention } from "../../Store/Reducers/ResultAllDiamention";
import { storeTestCategories } from "../../Store/Reducers/testCategories";
import TestCategoryCardDisable from "../../components/TestCategoryCard/TestCategoryCard";
import PersonalEmptyResult from "../TestResultCategoryList/PersonaEmptyResult";
export default function SingleResultLayout() {
  const { testCategories } = useSelector((state) => state);
  // const [searchResultArray, setSearchResultArray] = useState([]);
  // const [searchValue, setSearchValue] = useState("");
  // const [selectLayoutBtn, setSelected] = useState("Grid");
  const [typeofResult, setTypeofResult] = useState(TYPEOFRESULTS.PERSONAL);
  const [spacificPersonInviteId, setSpacificPersonInviteId] = useState("");
  const [isResultLoading, setIsResultLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(true);
  const [subcategoryData, setsubCategoryData] = useState(
    testCategories.testCategories
  );
  const { resultAllDiamention } = useSelector((state) => state);
  const { selectedInviteDataSlice } = useSelector((state) => state);

  const [ResultAllDiamention, setResultAllDiamention] = useState(
    resultAllDiamention?.ResultDiamention
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const { state } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // fetch data for result-subcatagories
  const fetchTestSubCategories = () => {
    const user = localStorage.getItem("userDetail");
    if (
      selectedInviteDataSlice?.isSelectedSocialUser &&
      selectedInviteDataSlice?.selectedinviteData?.inviteUserUid
    ) {
      return subcategoriesByInvite({
        uid: selectedInviteDataSlice?.selectedinviteData?.inviteUserUid,
        category_id: categoryId,
      });
    } else {
      // selectedInviteDataSlice.isSelectedSocialUser
      return categoryPageNew({ uid: user, category_id: categoryId });
    }
  };
  const { isLoading, isFetching } = useQuery(
    "result-subCategorys",
    fetchTestSubCategories,
    {
      onSuccess: (data) => {
        setsubCategoryData(data.subCategoryData);
        dispatch(storeTestCategories(data.subCategoryData));
        // dispatch(setActiveTestCategoryId(categoryId));
      },
      onError: (error) => {
        console.log(error);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setIsResultLoading(true);
    if (selectedInviteDataSlice?.isSelectedSocialUser) {
      AllDiamentionByInvite(
        selectedInviteDataSlice?.selectedinviteData?.inviteUserUid
      )
        .then((data) => {
          setResultAllDiamention(data?.allPercentage);
          dispatch(storeResultAllDiamention(data?.allPercentage));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsResultLoading(false);
        });
    } else {
      const user = localStorage.getItem("userDetail");
      ResultAllTestDiamention(user)
        .then((data) => {
          setResultAllDiamention(data?.allPercentage);
          dispatch(storeResultAllDiamention(data?.allPercentage));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsResultLoading(false);
        });
    }
  }, [spacificPersonInviteId, selectedInviteDataSlice]);

  // to naviagte subcatagories id
  const onClickHandler = ({
    score,
    imageUrl,
    subCategory,
    description,
    category_id,
    subCategory_id,
    coins,
    points,
    time,
    total_questions,
    category,
  }) => {
    localStorage.setItem("selctedSubCategoryId", subCategory_id);
    navigate(`/resultsubcatagories/${subCategory_id}`, {
      state: {
        score,
        imageUrl,
        subCategory,
        description,
        category_id,
        subCategory_id,
        coins,
        points,
        time,
        total_questions,
        category,
      },
    });
  };
  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    overflowY: "auto",
    height: "100vh",
  }));

  const PersonalButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "10px",
    fontSize: "10px !important",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const PublicButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const PersonPublic = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      order: "1",
      margin: "0px",
    },
  }));
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "42px",
    [theme.breakpoints.down("md")]: {
      order: "2",
      marginTop: "12px",
      fontSize: "22px",
    },
    [theme.breakpoints.down("lg")]: {
      order: "2",
      marginTop: "12px",
      fontSize: "28px",
    },
  }));
  const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: "28px",
    marginTop: "28px",
    marginBottom: "16px",
    [theme.breakpoints.down("md")]: {
      marginTop: "16px",
      fontSize: "18px !important",
    },
  }));
  const MainWrapper = styled("div")(({ theme }) => ({
    height: "fit-content",
    overflow: "auto",
    marginTop: "16px",
    backgroundColor: "#ffffff",
    padding: "24px",
    borderRadius: "20px",

    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 286px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 370px)",
    },
  }));
  console.log("typeofResult", typeofResult);
  return (
    <RightSideContentWrapper>
      <Header />
      <div className={classes.dashbordTitleContainer}>
        <div className={classes.dashbordTitle}>
          {selectedInviteDataSlice?.isSelectedSocialUser ? (
            <CustomTypography variant="h4">
              Feedback from {selectedInviteDataSlice?.selectedinviteData?.name}
            </CustomTypography>
          ) : (
            <CustomTypography variant="h4">
              {/* Well-beings results */}
              {state?.category} Results
            </CustomTypography>
          )}
          {!selectedInviteDataSlice?.isSelectedSocialUser && (
            <PersonPublic>
              <PersonalButton
                variant={
                  typeofResult === TYPEOFRESULTS.PERSONAL ? "contained" : null
                }
                onClick={() => {
                  setTypeofResult(TYPEOFRESULTS.PERSONAL);
                }}
              >
                Personal
              </PersonalButton>
              <PublicButton
                variant={
                  typeofResult === TYPEOFRESULTS.SOCIAL ? "contained" : null
                }
                onClick={() => {
                  setTypeofResult(TYPEOFRESULTS.SOCIAL);
                }}
              >
                Social
              </PublicButton>
            </PersonPublic>
          )}
        </div>
        {selectedInviteDataSlice?.isSelectedSocialUser ? (
          <div className={classes.dashbordTitlebody}>
            <Typography variant="p" sx={{ color: "#434243" }}>
              Explore what other people think about you and potential area for
              growth.
            </Typography>
          </div>
        ) : (
          <div className={classes.dashbordTitlebody}>
            <Typography variant="p" sx={{ color: "#434243" }}>
              The well-being overview shows all the results relevent for your
              self-discovery.
            </Typography>
            <Typography variant="p" sx={{ color: "#434243" }}>
              You can move between all dimenstions or test category
            </Typography>
          </div>
        )}
      </div>
      {typeofResult === TYPEOFRESULTS.PERSONAL ? (
        <div className={classes.mainWrapper}>
          <div>
            <TestcatagoriesComponent isFetching={isFetching} />
          </div>
          {isFetching || isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "calc(100vh - 228px)",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={1.5} className={classes.testListWrapper}>
              {subcategoryData?.map((data) => {
                const {
                  score,
                  imageUrl,
                  subCategory,
                  description,
                  category_id,
                  subCategory_id,
                  preDescription,
                  coins,
                  points,
                  time,
                  total_questions,
                  category,
                } = data;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3} 
                    className={classes.personalityReslutItem}
                  >
                    {" "}
                    <ResultCategoryCardWithImage
                      onClickHandler={() =>
                        onClickHandler({
                          score,
                          imageUrl,
                          subCategory,
                          description,
                          category_id,
                          subCategory_id,
                          preDescription,
                          coins,
                          points,
                          time,
                          total_questions,
                          category,
                        })
                      }
                      imageUrl={imageUrl}
                      subCategory={subCategory}
                      description={description}
                      score={score}
                      preDescription={preDescription}
                      {...data}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
          {subcategoryData.length === 0 && (
             <PersonalEmptyResult />
          )}
        </div>
      ) : (
        <ResultChart
          spacificPersonInviteId={spacificPersonInviteId}
          setSpacificPersonInviteId={setSpacificPersonInviteId}
          setTypeofResult={setTypeofResult}
        />
      )}
    </RightSideContentWrapper>
  );
}
