export const nameValidation = (name) => {
    let regex = /^[a-zA-Z]+$/
    let errorMessage = ""
    if(!name.match(regex)){
        errorMessage = "Name must consist of letters only"
    }
    if(name.length <= 1) {
        errorMessage = "Name must be at least 2 characters"
    }
   
    return errorMessage
}


export const ageValidation = (age) => {
  
    if (age < 12 || age > 99){
        return "Age should between 12 and 99"
    }
    console.log("age",age)
}