import styled from "@emotion/styled";
import { Button, Snackbar, Typography } from "@mui/material";
import React from "react";
import { CustomSnackbar } from "../GoalComponent/GoalDialog";
import classes from "./GrowthDialog.module.css";
function SuggestedGrowthArea({ suggestedGrowthArea }) {
  return (
    <CustomSnackbar
      open={true}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      action={
        <div className={classes.SnackbarWrapper}>
          {suggestedGrowthArea.length === 0 ? (
            <div className={classes.dataNotFoundSubWrapper}>
              <Typography variant="body2">You have not select suggested growth area</Typography>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <Typography sx={{ color: "#434243" }} variant="small">
                {" "}
                Selected growth areas
              </Typography>
              {suggestedGrowthArea?.map((data, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{ color: "#190D1A", marginBottom: "12px" }}
                    variant="body2"
                  >
                    {data.text}
                  </Typography>
                );
              })}
            </div>
          )}
        </div>
      }
    ></CustomSnackbar>
  );
}

export default SuggestedGrowthArea;
