import {
    Box,
    Button,
    Dialog,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import {
    CardContent,
    CustomSelection,
    MainCard,
} from "../GoalComponent/GoalDialog";
import Clock from "../../assets/Svg/Clock.svg";
import RightSign from "../../assets/Svg/RightSign.svg";
import classes from "./HabitFrequencySelection.module.css"
import { days } from "../../Constant/GoalConstant";
import { CustomCheckBox } from "../GoalComponent/GoalCustomDiv";
import { CheckBoxInputCheckedDay, CheckBoxInputDay } from "../Input/Input";
import { firstLetterUpper } from "../../utils";
import ChipSaveButton from "../ChipSaveButton";
import GoalTimePicker from "../EditRemoveGoal/GoalTimePicker/GoalTimePicker";
const HabitFrequencySelection = (props) => {
    const [habitScheduleType, sethabitScheduleType] = useState(props.scheduleType);
    const [isDayPickerOpen, setisDayPickerOpen] = useState(false);
    const [selectedDaystate, setSelectedDaystate] = useState(props?.selectedDays || []);
    const {
        onChangeSelect,
        onChangeDay
    } = props
    console.log("selectedDaystate", selectedDaystate);
    const setselectedDayHandler = (day) => {
        const findData = selectedDaystate.find((o) => o === day);
        if (findData) {
            setSelectedDaystate((current) => current.filter((data) => data !== day));
        } else {
            setSelectedDaystate([...selectedDaystate, day]);
        }
    };
    return (
        <>
            <CustomSelection
                value={habitScheduleType}
                displayEmpty
                onChange={(e) => {
                    sethabitScheduleType(e.target.value);
                    onChangeSelect && onChangeSelect(e.target.value)
                }}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                    anchorOrigin: {
                        // vertical: "bottom",
                        horizontal: "center",
                    },
                }}
                sx={{
                    color: "#000000",
                    "& .MuiSelect-root": {
                        color: "#434243",
                    },
                }}
            >
                <MenuItem
                    value=""
                    sx={{
                        color: "#434243",
                    }}
                >
                    Select
                </MenuItem>
                <MenuItem sx={{ color: "#434243" }} value={"DAILY"}>
                    Daily{" "}
                </MenuItem>
                <MenuItem
                    sx={{ color: "#434243" }}
                    value={"WEEKLY"}
                    onClick={() => {
                        setisDayPickerOpen(true);
                    }}
                >
                    Weekly{" "}
                </MenuItem>
                {/* <MenuItem
                    sx={{ color: "#434243" }}
                    value={"MONTHLY"}
                    onClick={() => {
                      setisDatePickerOpen(true);
                    }}
                  >
                    Monthly
                  </MenuItem> */}
            </CustomSelection>
            {isDayPickerOpen && (
                <GoalTimePicker
                    isDayPickerOpen={isDayPickerOpen}
                    dayModalCloseHandler={() => setisDayPickerOpen(false)}
                    setselectedDayHandler={setselectedDayHandler}
                    selectedDaystate={selectedDaystate}
                    selectedHabitData={{}}
                    onChangeDay={onChangeDay}
                />
            )}

            {/* <Dialog
                open={isDayPickerOpen}
                onClose={() => {
                    setisDayPickerOpen(false);
                }}
                sx={{
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "456px",
                        height: "196px",
                        borderRadius: "20px",
                        position: "unset",
                        padding: "20px",
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ width: "100%" }}>
                    <div className={classes.DateAndTimewrapper}>
                        <img src={Clock} />
                        <Typography variant="body2" sx={{ color: "#190D1A" }}>
                            Frequency
                        </Typography>
                    </div>
                    <Typography className={classes.dailyText} variant="subtitle1">
                        Daily
                    </Typography>
                    <div className={classes.WeeklyText}>
                        <Typography variant="subtitle1" sx={{ color: "#434243" }}>
                            Weekly
                        </Typography>
                        <img
                            style={{ width: "14px", height: "8px" }}
                            src={RightSign}
                            alt=""
                        />
                    </div>
                    {selectedDaystate.length ? <Typography variant="subtitle1" sx={{ color: "#434243", lineHeight: '20px' }}>
                        Event will occur every week on  {[...selectedDaystate].map((st) => firstLetterUpper(st.days)).join(", ")}
                    </Typography> : ""}
                    <Box className={classes.radioWrapper}>
                        {days?.map((day, index) => {
                            return (
                                <CustomCheckBox
                                    key={index}
                                    sx={{ margin: "6px 0px" }}
                                    // {...label}
                                    icon={
                                        <CheckBoxInputDay
                                            placeholder={day.slice(0, 3).toLowerCase()}
                                        />
                                    }
                                    onChange={() => {
                                        setselectedDayHandler(day, index);
                                        onChangeDay && onChangeDay(day, index)
                                    }}
                                    checked={selectedDaystate.find((o) => o.index === index)}
                                    checkedIcon={
                                        <CheckBoxInputCheckedDay
                                            placeholder={day.slice(0, 3).toLowerCase()}
                                        />
                                    }
                                />
                            );
                        })}
                    </Box>
                    <ChipSaveButton
                        title="Save"
                        onSave={() => setisDayPickerOpen(false)}
                    />
                </div>
            </Dialog> */}
        </>
    )
}
export default HabitFrequencySelection