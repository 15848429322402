import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import ProfileHeader from "../../../components/Settings/Legal/ProfileHeader";
import { SettingNavigationBar } from "../../../components/Settings/SettingNavigationBar/SettingNavigationBar";
import { RightSideContentWrapper } from "../../../components/Settings/StyledElement/StyledDiv";
import { PROFILEINFO_TYPE } from "../../../Constant/SettingConstant";
import classes from "./profile.module.css";
import ProfileAccountInfo from "./PrivacyPolcy";
import ProfilePersonalInfo from "./CookiePolicy";

function SettingLegal() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [togglePersonalAccountInfo, setPersonalAccountInfo] = useState(
    PROFILEINFO_TYPE.ACCOUNT_INFO
  );

  return (
    <RightSideContentWrapper>
      <Header />
      <Grid container spacing={1.5}>
        <Grid item xs={12} lg={9} md={9}>
          <div className={classes.dashbordTitleContainer}>
            <ProfileHeader
              setPersonalAccountInfo={setPersonalAccountInfo}
            />
            {/* {togglePersonalAccountInfo === PROFILEINFO_TYPE.ACCOUNT_INFO ? (
              <>

                <ProfileAccountInfo

                />
              </>
            ) : (
              <>

                <ProfilePersonalInfo

                />
              </>
            )} */}
          </div>
        </Grid>
        {!matches && (
          <Grid item xs={12} lg={3} md={3}>
            <div
              style={{ padding: "12px 0px" }}
              className={classes.dashbordTitleContainer}
            >
              <SettingNavigationBar />
            </div>
          </Grid>
        )}
      </Grid>
    </RightSideContentWrapper>
  );
}

export default SettingLegal;
