import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  getCalenderData: [],
  getActionData: [],
  getHabitData: [],
  isGetCalenderData: false,
};
export const getCalenderDataSlice = createSlice({
  name: "getCalenderDataSlice",
  initialState,
  reducers: {
    storeGetCalendarData: (state, action) => {
      state.getCalenderData = action.payload;
    },
    storeGetActionData: (state, action) => {
      state.getActionData = action.payload;
    },
    storeGetHabitData: (state, action) => {
      state.getHabitData = action.payload;
    },
    updateIsGetCalenderData: (state, action) => {
      state.isGetCalenderData = action.payload;
    },
    resetGrowthCalendarData: () => {
      return initialState
    }
  },
});
// Action creators are generated for each case reducer function
export const {
  storeGetCalendarData,
  storeGetActionData,
  storeGetHabitData,
  updateIsGetCalenderData,
  resetGrowthCalendarData
} = getCalenderDataSlice.actions;
export default getCalenderDataSlice.reducer;
