import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { goalGetPreSelectedGrowth } from "../../../services/auth";
import typography from "../../../theme/typography";
import { CustomShedualButton } from "../../GoalComponent/GoalButton";
import { CheckBoxInputCheckedGoal, CheckBoxInputGoal } from "../../Input/Input";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import classes from "./GrowthAreaDialog.module.css";
function GrowthAreaDialog({
  selectedQuestionCategoriesId,
  goalName,
  setisAddMoreGrowthAreaOpen,
  addNewGrowthAreaHandler,
  preSelectedGrowthComplete,
  preSelectedGrowth,
  imageCompo,
}) {
  const [filterGrowthArea, setFilterGrowthArea] = useState([]);
  useEffect(() => {
    const tempGoalgrowth = [...preSelectedGrowth, ...preSelectedGrowthComplete];
    const tempGrowthAreaSuggested = tempGoalgrowth.filter(
      (item) => item.type === "SUGGESTED"
    );
    let user = localStorage.getItem("userDetail");
    if (!goalGrowthArea?.length) {
      goalGetPreSelectedGrowth(user, selectedQuestionCategoriesId)
        .then((res) => {
          setgoalGrowthArea(res?.data);
          getUniquePreselectedGrowthArea(tempGrowthAreaSuggested, res?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setisGoalGrowth(true);
        });
    }
  }, []);
  const getUniquePreselectedGrowthArea = (array1, array2) => {
    let arr3 = [].concat(
      array1.filter((obj1) => array2.every((obj2) => obj1.text !== obj2.point)),
      array2.filter((obj2) => array1.every((obj1) => obj2.point !== obj1.text))
    );
    const abc = Object.values(
      arr3.reduce((acc, cur) => Object.assign(acc, { [cur.point]: cur }), {})
    );
    console.log("abc = ", abc);
    setFilterGrowthArea(abc);
  };
  const [goalGrowthArea, setgoalGrowthArea] = useState([]);
  const [isGoalGrowth, setisGoalGrowth] = useState(false);
  const [preselectedState, setPreselectedState] = useState([]);
  const [selfInput, setselfInput] = useState({ text: "" });

  const growthAreaHandler = (index, item) => {
    const findData = preselectedState.find((o) => o.index === index);
    if (findData) {
      setPreselectedState((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setPreselectedState([
        ...preselectedState,
        {
          index: index,
          text: item,
          type: "SUGGESTED",
          status: "IN_PROGRESS",
          active: "true",
        },
      ]);
    }
  };

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    border-radius: 16px;
    background: #ffffff;

    & .MuiTextField-root {
      width: 100%;
      border-radius: 16px;
      :hover {
        backgroundcolor: transparent !important;
      }
    }
    & .MuiCheckbox-root {
      :hover {
        border: 1px solid red;
      }
    }
  `;
  return (
    <div className={classes.StepThreeWrapper}>
      <div className={classes.StepThreeSubWrapper}>
        {imageCompo ? imageCompo : <img style={{ margin: "16px auto" }} src={Hedonism} alt="" />}
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Add new growth area to
          <span style={{ color: "#9747FF" }}> {goalName}</span>{" "}
        </Typography>
        <Typography
          variant="body4"
          sx={{ margin: "16px 0px 10px 0px", textAlign: "center" }}
        >
          Choose preselected growth area or type in your own
        </Typography>
        {!isGoalGrowth ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box className={classes.radioWrapper}>
            {filterGrowthArea?.map((item, index) => {
              return (
                <CustomCheckBox
                  key={index}
                  sx={{ margin: "6px 0px" }}
                  // {...label}
                  icon={<CheckBoxInputGoal placeholder={item?.point} />}
                  checkedIcon={
                    <CheckBoxInputCheckedGoal placeholder={item?.point} />
                  }
                  onChange={() => {
                    growthAreaHandler(index, item?.point);
                  }}
                  checked={preselectedState.find((o) => o.index === index)}
                />
              );
            })}
          </Box>
        )}
        <Typography
          variant="body4"
          sx={{ margin: "20px 0px 16px 0px", textAlign: "center" }}
        >
          or add your own
        </Typography>
        <TextField
          size="small"
          className={classes.goalActionInput}
          sx={{
            marginBottom: "12px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #f3f3f3",
              borderRadius: "16px",
            },
            " & .MuiOutlinedInput-input": {
              color: " #190d1a",
              padding: "16px 10px",
            },
            " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
              { border: "1px solid #9747FF" },
          }}
          InputProps={{
            sx: {
              "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #e5d5fc",
                },
              },
            },
          }}
          fullWidth
          name="text"
          value={selfInput.text}
          onChange={(e) =>
            setselfInput({
              text: e.target.value,
              type: "SELF",
              status: "IN_PROGRESS",
              active: "true",
            })
          }
          type="text"
          placeholder="Text"
          id="outlined-start-adornment"
        />
        <div style={{ width: "100%", marginBottom: "12px" }}>
          {/* <CustomShedualButton
          style={{ maxWidth: "91px" }}
          onClick={() => {
            handleAddMore();
          }}
        >
          Add more +
        </CustomShedualButton> */}
        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  setisAddMoreGrowthAreaOpen(false);
                }}
                // disabled={isPreviousBtnDisable}
              >
                Discard
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  console.log("helo");
                  addNewGrowthAreaHandler(selfInput, preselectedState);
                  setisAddMoreGrowthAreaOpen(false);
                }}
                disabled={preselectedState.length === 0 && !selfInput.text}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default GrowthAreaDialog;
