import classes from "./SendStepThree.module.css";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PlusIcon from "../../../assets/Svg/PlusIcon.svg";
import { v4 as uuidv4 } from "uuid";
import {
  Step,
  StepsWrapper,
  ResultSlider,
  MultiLineInput,
  FeedbackInput,
  NextButton,
} from "../StyledStepper/styledStepper";
import styled from "@emotion/styled";
import SteptwoRight from "../StepTwoRight/SteptwoRight";
import close from "../../../assets/Svg/Close.svg";
import { useDispatch } from "react-redux";
import { updateUserWalletCoinsAdd } from "../../../Store/Reducers/userProfileSlice";

const marks = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  }
];
export const RightMainCard = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  margin: "auto",
  justifyContent: "center",
  [theme.breakpoints.up("xl")]: {
    padding: "0px 60px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "0px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0px 10px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
export const SliderAndstepperWrapper = styled("div")(({ theme }) => ({
  margin: " 0px 28px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: { margin: " 0px" },
}));

function valuetext(value) {
  return value;
}
function SendStepThree({
  setSendmodalStep,
  setIsAskOtherModalOpen,
  setmodalStep,
  inaccurateHandler,
  feedBackDataHandler,
  setCongatesStep,
  modalData,
  handleClose
}) {
  const dispatch = useDispatch()
  const [ratingInaccurate, setRatinginaccurate] = useState(1);
  const [IrreleventResult, setIrreleventResult] = useState("");
  const [IrreleventMessage, setIrreleventMessage] = useState("");
  const [addQuestionIndex, setAddQuestion] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [isnext, setisnext] = useState(false);
  const addQuestionHandler = () => {
    if (IrreleventMessage !== "" && IrreleventResult !== "") {
      setQuestionList((prev) => [
        ...prev,
        {
          id: uuidv4(),
          result: IrreleventResult,
          suggestion: IrreleventMessage,
        },
      ]);
      setAddQuestion((prev) => prev + 1);
      setIrreleventMessage("");
      setIrreleventResult("");
    }
  };

  const NextButtonHandler = async () => {
    questionList.push(...questionList, {
      id: uuidv4(),
      result: IrreleventResult,
      suggestion: IrreleventMessage,
    });

    let updatedQuestionList = [];
    questionList?.forEach((irrelevnt) => {
      updatedQuestionList.push({
        result: irrelevnt.result,
        suggestion: irrelevnt.suggestion,
      });
    });
    // inaccurateHandler();
    feedBackDataHandler(ratingInaccurate, updatedQuestionList);
    // setIsAskOtherModalOpen(false);
    dispatch(updateUserWalletCoinsAdd())
    setmodalStep(false);
    setCongatesStep(true);
    setSendmodalStep(false);
  };
  return (
    <Grid
      container
      sx={{ height: "100%", display: "flex", justifyContent: "space-between" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        sx={{ display: "flex", height: "100%" }}
      >
        <SteptwoRight modalData={modalData} />
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={5.9}
        xl={5.9}
        className={classes.RightSideWrapper}
        sx={{ height: "100%" }}
      >
        <RightMainCard>
        <div  className={classes.closeIconeWrapper} onClick={()=>{handleClose()}}>
        <img src={close}/>
      </div>
          <SliderAndstepperWrapper>
            <StepsWrapper>
              <Step className={classes.active} />
              <Step className={classes.active} />
            </StepsWrapper>
            <Box
              sx={{
                padding: "24px 0px",
                margin: " auto",
                maxWidth: "500px",
              }}
            >
              <Typography className={classes.headingtext} variant={"h4"}>
                Share your feedback on ‘ {modalData?.questionCategory}’
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={"body1"}
                sx={{
                  color: "#190D1A",
                  margin: "8px 0px 16px 0px",
                  textAlign: "center",
                }}
              >
                Overall, do you find the results accurate?
              </Typography>
            </Box>
            <ResultSlider 
              classes={{markLabelActive: classes.test}}
              value={ratingInaccurate}
              onChange={(e) => {
                setRatinginaccurate(e.target.value);
              }}
              min={0}
              max={10}
              marks={marks}
              getAriaValueText={valuetext}
            />
          </SliderAndstepperWrapper>
          <Box className={classes.inputWrapper}>
            {/* <Typography
              sx={{
                maxWidth: "456px",
                color: "#190D1A",
                margin: "6px 0px",
                lineHeight: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "start",
              }}
            >
              What in the results you find irrelevant?{" "}
            </Typography>
            <FeedbackInput
              onChange={feedbackDatahandler}
              value={inaccuratedata.result}
              sx={{ margin: "16px 0px" }}
              multiline
              size="small"
              fullWidth
              name="result"
              type="text"
              placeholder="Copy and paste text for feedback"
            />
            <Typography
              sx={{
                maxWidth: "456px",
                color: "#190D1A",
                margin: "8px 0px",
                lineHeight: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "start",
              }}
            >
              Message{" "}
            </Typography>
            <MultiLineInput
              onChange={feedbackDatahandler}
              sx={{ margin: "8px 0px" }}
              value={inaccuratedata.suggestion}
              multiline
              size="small"
              fullWidth
              name="suggestion"
              type="text"
              placeholder="Type in what we can do to improve our results"
            /> */}
            {questionList?.slice(0, addQuestionIndex).map((input, index) => {
              return (
                <div key={index}>
                  <Typography
                    sx={{
                      maxWidth: "456px",
                      color: "#190D1A",
                      margin: "6px 0px",
                      lineHeight: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "start",
                    }}
                  >
                    What in the results you find irrelevant?{" "}
                  </Typography>
                  <FeedbackInput
                    value={input.result}
                    name="result"
                    sx={{ margin: "16px 0px" }}
                    multiline
                    size="small"
                    fullWidth
                    // key="description"
                    type="text"
                    placeholder="Copy and paste text for feedback"
                  />
                  <Typography
                    sx={{
                      maxWidth: "456px",
                      color: "#190D1A",
                      margin: "8px 0px",
                      lineHeight: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "start",
                    }}
                  >
                    Message{" "}
                  </Typography>
                  <MultiLineInput
                    value={input.suggestion}
                    name="suggestion"
                    sx={{ margin: "8px 0px" }}
                    multiline
                    size="small"
                    fullWidth
                    // key="description"
                    type="text"
                    placeholder="Type in what we can do to improve our results"
                  />
                </div>
              );
            })}
            <div>
              <Typography
                sx={{
                  maxWidth: "456px",
                  color: "#190D1A",
                  margin: "6px 0px",
                  lineHeight: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "start",
                }}
              >
                What in the results you find irrelevant?{" "}
              </Typography>
              <FeedbackInput
                value={IrreleventResult}
                onChange={(e) => setIrreleventResult(e.target.value)}
                name="result"
                sx={{ margin: "16px 0px" }}
                multiline
                size="small"
                fullWidth
                // key="description"
                type="text"
                placeholder="Copy and paste text for feedback"
              />
              <Typography
                sx={{
                  maxWidth: "456px",
                  color: "#190D1A",
                  margin: "8px 0px",
                  lineHeight: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "start",
                }}
              >
                Message{" "}
              </Typography>
              <MultiLineInput
                value={IrreleventMessage}
                onChange={(e) => setIrreleventMessage(e.target.value)}
                name="suggestion"
                sx={{ margin: "8px 0px" }}
                multiline
                size="small"
                fullWidth
                // key="description"
                type="text"
                placeholder="Type in what we can do to improve our results"
              />
            </div>
            <Box sx={{ maxWidth: "456px", margin: "auto" }}>
              <div
                className={classes.addMoreQuestionBtn}
                onClick={addQuestionHandler}
              >
                <Typography
                  variant="body3"
                  color="#9747FF"
                  className={classes.addmoreTextWrapper}
                >
                  Add more
                  <img src={PlusIcon} alt="" style={{ marginLeft: "4px" }} />
                </Typography>
              </div>
            </Box>

            <div sx={{ marginTop: "20px" }} onClick={NextButtonHandler}>
              <NextButton
                isNextDisable={
                  IrreleventResult === "" || IrreleventMessage === ""
                }
                nextButtonText="Send"
              />
            </div>
          </Box>
        </RightMainCard>
      </Grid>
    </Grid>
  );
}
export default SendStepThree;
