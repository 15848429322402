import { CircularProgress, Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import { InputField } from "../../InviteFlow/StyledComponent";
import Search from "../../../assets/Svg/Search.svg";
import { useEffect } from "react";
import classes from "./TestItem.module.css";
import Coin from "../../../assets/Svg/Coin.svg";
import DiamondCoin from "../../../assets/Svg/DiamondCoin.svg";
import { imageURLRender } from "../../../utils";

const TestItemUnlock = props => {
    const {
        testModalVisible,
        setTestModalVisible,
        getAllSubcategoryData,
        subcategories,
        searchValue,
        allsubcategories,
        setSearchValue, searchSubCategory,
        loadingData,
        setLoadingData, setSelectedItem,
        setConfirmationVisible,
        setSubcategories
    } = props
    useEffect(() => {
        if (!allsubcategories) {
            setLoadingData(true)
            getAllSubcategoryData()
        }
    })
    return <Dialog
        open={testModalVisible}
        onClose={() => {
            setSearchValue('')
            setSubcategories(allsubcategories)
            setTestModalVisible(false);
        }}
        maxWidth={'lg'}
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                position: "unset",
                padding: "20px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.cardTitle}>
                Select test you want to have immediate access to
            </div>
            <div className={classes.closeIcon} onClick={() => {
                setSearchValue('')
                setSubcategories(allsubcategories)
                setTestModalVisible(false);
            }}>
                x
            </div>
        </div>

        {loadingData && (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>
        )}
        {!loadingData && (
            <>
                <div >
                    <InputField
                        size="small"
                        fullWidth
                        type="text"
                        autoFocus={searchValue !== ""}
                        value={searchValue}
                        onChange={(e) => searchSubCategory(e)}
                        placeholder="Search Test"
                        id="outlined-start-adornment"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={Search} alt="" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Grid container spacing={0} className={classes.testListWrapper}>

                    {subcategories ?
                        subcategories.length ? subcategories.map(data => {
                            const {
                                _id,
                                subCategory,
                                time,
                                points,
                                preDescription,
                                imageUrl,
                                coins,
                            } = data;
                            // const imageUrl = "/public/SubCategory/resizeSubCategory1662727003807_1662727003807.jpeg"
                            return (
                                <Grid
                                    item
                                    xs={11.8}
                                    sm={5.8}
                                    md={2.8}
                                    lg={2.8}
                                    xl={1.8}
                                    key={_id}
                                    sx={{
                                        border: '1px solid #E5D5FC',
                                        borderRadius: '16px',
                                        justifyContent: 'space-around',
                                        margin: '5px',
                                        cursor: 'pointer'
                                    }}

                                >
                                    <div
                                        className={classes.card}
                                        onClick={() => {
                                            setSelectedItem(data)
                                            setConfirmationVisible(true)
                                        }}
                                    >
                                        <div>
                                            <div className={classes.imageWrapper}>
                                                <div className={classes.Imageoverlay}>
                                                    
                                                        <img
                                                            src={imageURLRender(imageUrl)}
                                                            alt=""
                                                            className={classes.overlayEffect}
                                                        />
                                                   
                                                </div>
                                                <div className={classes.iconsWrapper}>
                                                    <div className={classes.iconsLeftWrapper}>
                                                        <div className={classes.coinIconWrapper}>
                                                            <img src={Coin} alt="" />
                                                            <Typography variant="body3" color="#9747FF">
                                                                {coins}
                                                            </Typography>
                                                        </div>
                                                        <div className={`${classes.coinIconWrapper} ${classes.ml4}`}>
                                                            <img src={DiamondCoin} alt="" />
                                                            <Typography variant="body3" color="#9747FF">
                                                                {points}
                                                            </Typography>
                                                        </div>
                                                        <div className={`${classes.timeIcon} ${classes.ml4}`}>
                                                            <Typography variant="body3" color="#9747FF">
                                                                {time} min
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.testTitle}>
                                                <Typography variant="body4">{subCategory}</Typography>
                                            </div>
                                            <div className={classes.testDiscription}>
                                                <Typography variant="body2" color="#434243" className="text-3lines">
                                                    {preDescription}
                                                </Typography>
                                            </div>
                                        </div>

                                    </div>
                                </Grid>
                            )
                        }) : (
                            <div
                                style={{
                                    margin: "auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: "28px !important",
                                    }}
                                >
                                    No data found
                                </Typography>
                            </div>
                        ) : null}
                </Grid>
            </>
        )}


    </Dialog>
}

export default TestItemUnlock