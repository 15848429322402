import classes from "./ResultDashbor.module.css";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ResponsiveSideBar from "../../components/ResponsiveSideBar/ResponsiveSideBar";
import {
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Star";
import { useTheme } from "@emotion/react";
import BtmNavigation from "../../components/BottomNavigation/BtmNavigation";
import { useDispatch, useSelector } from "react-redux";
import Stories from '../../components/Stories';
import Success16px from "./../../assets/Svg/success-circle-16px.svg"
import Story1 from "./../../assets/images/Story1.jpeg"
import Story2 from "./../../assets/images/Story2.jpeg"
import Story3 from "./../../assets/images/Story3.jpeg"
import { updateSetShowStories } from "../../Store/Reducers/dashboard";
// import Star from "../../assets/Svg/Star.fill.svg"
export default function ResultDashbord() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const RootStyle = styled("div")(({ theme }) => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "13.25rem auto",
    height: "100vh",
    height: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
    position: "relative",
  }));
  // const dispatch = useDispatch();
  const RightSide = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    height: "102vh",
    overflow: "hidden",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
    },
  }));
  // const { dashboardDataSlice } = useSelector(
  //   (state) => state
  // );
  // console.log("dashboardDataSlice", dashboardDataSlice);
  return (
    <RootStyle className={classes.main_wrapper}>
      {matches ? null : <ResponsiveSideBar />}
      <RightSide>
          <BackgroundRadient />
        <Outlet />
      </RightSide>
      {matches ? <BtmNavigation /> : null}
      {/* {dashboardDataSlice.showStories ? (
          <Stories
            stories={[
              Story1,
              Story2,
              Story3
            ]}
            defaultInterval={1500}
            width={432}
            height={768}
            storyContainerStyles={{
              position: 'absolute',
              top: 0,
              width: '100%',
              left: '40%',
              zIndex: 10
            }}
            storyInnerContainerStyles={{
              padding: 0,
              width: '100%',
              background: "#000000",
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center'
            }}
            storyStyles={{
              margin: 0,
              objectFit: 'fill',
              width: '100%'
            }}
            onHandleExit={() => dispatch(updateSetShowStories(false))}
          />
        ) : null} */}
    </RootStyle>
  );
}

const BackgroundRadient = () => {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1
    }}>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '826.65px',
        top: '629.11px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '720px',
        height: '653px',
        left: '0',
        top: '-104px',

        background: '#E5D5FC',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '571px',
        height: '517px',
        left: '854px',
        top: '0px',

        background: '#DBC1FF',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1171.43px',
        top: '446.5px',

        background: '#F3E9F7',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '466.44px',
        height: '422.19px',
        left: '1326.54px',
        top: '646.06px',

        background: '#FCD686',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '444px',
        top: '-40px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
      <div style={{
        position: 'absolute',
        width: '616px',
        height: '557px',
        left: '16px',
        top: '434px',

        background: '#FFD172',
        opacity: '0.7',
        filter: 'blur(252.5px)',
      }}></div>
    </div>
  )
}