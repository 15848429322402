import { Box, Button, CircularProgress, Grid } from "@mui/material";
import classes from "./Button.module.css";
import typography from "../../theme/typography";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingSpinner from "../../assets/Svg/Loading.svg";

export const LinkButton = ({
  buttonText,
  color,
  navigateUrl,
  textDecoration = "none",
}) => {
  const navigate = useNavigate();
  return (
    <Button
      className={classes.forgotPassword_btn}
      sx={{
        p: 0,
        color: color,
        textDecoration: textDecoration,
        height: "20px",
      }}
      onClick={() => navigate(navigateUrl)}
    >
      {buttonText}
    </Button>
  );
};

export const LinkButtonForTermsAndCondition = ({
  buttonText,
  color,
  navigateUrl,
  textDecoration = "none",
  termsAndConditionUrl,
}) => {
  const navigate = useNavigate();
  return (
    <Button
      className={classes.forgotPassword_btn}
      sx={{
        p: 0,
        color: color,
        textDecoration: textDecoration,
        height: "20px",
      }}
      href={termsAndConditionUrl}
      target="_blank"
    >
      {buttonText}
    </Button>
  );
};

// const SpinnerSvg = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="40"
//     height="40"
//     viewBox="0 0 20 20"
//     fill="none"
//   >
//     <path
//     stroke="#d32f2f"
//       d="M10 16C9.58579 16 9.25 15.6642 9.25 15.25C9.25 14.8358 9.58579 14.5 10 14.5C12.4853 14.5 14.5 12.4853 14.5 10C14.5 7.51472 12.4853 5.5 10 5.5C7.51472 5.5 5.5 7.51472 5.5 10C5.5 10.4854 5.5766 10.9595 5.72515 11.4101C5.85483 11.8035 5.64105 12.2275 5.24766 12.3572C4.85427 12.4869 4.43024 12.2731 4.30056 11.8797C4.10221 11.2781 4 10.6455 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16Z"
//       fill="url(#paint0_angular_1_47)"
//     />
//     <defs>
//       <radialGradient
//         id="paint0_angular_1_47"
//         cx="0"
//         cy="0"
//         r="1"
//         gradientUnits="userSpaceOnUse"
//         gradientTransform="translate(10 9.625) rotate(126.87) scale(3.75 4.02538)"
//       >
//         <stop stop-color="blue" />
//         <stop offset="1" stop-color="blue" stop-opacity="0" />
//       </radialGradient>
//     </defs>
//   </svg>
// );

export const SubmitButton = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading,
}) => {
  return (
    <LoadingButton
      className={classes.submit_btn}
      variant="contained"
      loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{
        ...typography.subtitle1,
        color: "#FFFFFF",
        background: isDisabled ? "#F9F9F9 !important" : "#9747FF !important",
      }}
      onClick={onClick}
      disabled={isDisabled}
      loading={isLoading && <CircularProgress sx={{ color: "#fff" }} />}
    >
      {buttonText}
    </LoadingButton>
  );
};

export const SubmitButtonOutline = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.submitButtonOutline}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle1 }}
      variant="outlined"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export const SubmitButtonWithBackGround = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.submitButtonOutlineWithBackGround}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle1 }}
      variant="outlined"
      onClick={onClick}
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export const LinkButtonWithText = ({ text, buttonText, navigateUrl }) => {
  const navigate = useNavigate();
  return (
    <Box className={classes.signup_btn_wrapper}>
      <span>{text}</span>
      <Button
        className={classes.signup_btn}
        sx={{
          p: 0,
          ...typography.subtitle1,
          borderBottom: "1px solid #000000",
          textDecoration: "none",
          borderRadius: 0,
          minWidth: 0,
        }}
        onClick={() => {
          navigate(navigateUrl);
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export const PrevAndNextButtonGroup = ({
  isPreviousButton,
  onNext,
  onPrevious,
  nextButtonText = "Next",
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Grid
      // rowSpacing={1}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={2}
    >
      {isPreviousButton ? (
        <>
          <Grid item xs={6}>
            <Button
              className={classes.previous_btn}
              sx={{ ...typography.subtitle1 }}
              variant="outlined"
              onClick={onPrevious}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            {/* <Button
              className={classes.next_btn}
              // sx={{ ...typography.subtitle1, borderColor: '#9747ff !important',
              //   color: '#ffffff !important',background: '#9747ff !important'  }}
              sx={{ ...typography.subtitle1 }}
              variant="outlined"
              onClick={onNext}
              disabled
            >
              {nextButtonText}
            </Button> */}
            {/* <SubmitButton buttonText={nextButtonText} onClick={onNext}  isDisabled={isDisabled} /> */}
            <LoadingButton
              className={classes.submit_btn}
              variant="contained"
              sx={{
                ...typography.subtitle1,
                color: "#FFFFFF",
                background: isDisabled
                  ? "#F9F9F9 !important"
                  : "#9747FF !important",
              }}
              onClick={onNext}
              disabled={isDisabled}
              loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
              loading={isLoading}
            >
              {nextButtonText}
            </LoadingButton>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Button
            className={classes.submit_btn}
            variant="contained"
            sx={{
              ...typography.subtitle1,
              color: "#FFFFFF",
              background: isDisabled
                ? "#F9F9F9 !important"
                : "#9747FF !important",
            }}
            onClick={onNext}
            disabled={isDisabled}
          >
            {nextButtonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const UnlockButtonOutline = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.unlockButtonOutline}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle2 }}
      variant="outlined"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export const NextButtonWithBackGround = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.NextButtonWithOutlineBackGround}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle1 }}
      variant="outlined"
      onClick={onClick}
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export const SettingUploadButton = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.settingUploadButton}
      disabled={isDisabled}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle2 }}
      variant="contained"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export const SettingDeleteButton = ({
  buttonText,
  onClick,
  isDisabled,
  isLoading = false,
}) => {
  return (
    <Button
      className={classes.settingDeleteButton}
      disabled={isDisabled}
      // loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
      sx={{ ...typography.subtitle2 }}
      variant="contained"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};