import axios from "axios";
import { baseEndpoints } from "../config";

class CategorySubcategoryService {
    static UNLOCK_TEST = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.UNLOCK_TEST.UNLOCK}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static UNLOCK_DIMANETION = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.UNLOCK_DIMENTION.UNLOCK}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static getAllInactiveSubcategory = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.CATEGORY_SUBCATEGORY.ALL_SUBCAT}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }
}

export default CategorySubcategoryService