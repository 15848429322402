import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

// export interface CounterState {
//   value: any
// }

const initialState = {
  name: '',
  surname: '',
  age: '',
  gender: '',
  motivates:[],
  otherMotivationesValue : "",
  topicData: [],
  selectedTopic: {},
  topicDataYoutubeLink: "",
  topicDataProfile: {},
  imageUrl: "",
  dob: ""
  // dob: ""
}

export const onbordingSlice = createSlice({
  name: 'onbording',
  initialState,
  reducers: {
    updateName: (state,action)=>{
      state.name = action.payload
    },
    updateSurName: (state,action)=>{
      state.surname = action.payload
    },
    updateImageUrl: (state,action)=>{
      state.imageUrl = action.payload
    },
    updateTopicData: (state, action) => {
      state.topicData = action.payload
    },
    updateTopicDataYoutubeLink: (state, action) => {
      state.topicDataYoutubeLink = action.payload
    },
    updateTopicDataProfile: (state, action) => {
      state.topicDataProfile = action.payload
    },
    updateSelectedTopic: (state, action) => {
      state.selectedTopic = action.payload
    },
    updateAge: (state,action)=>{
      state.age = action.payload.ageValue
      // state.dob = action.payload.dob
    },
    updateDOB: (state, action) => {
      state.dob = action.payload
    },
    updateGender: (state,action)=>{
      state.gender = action.payload
    },
    updateMotivates: (state,action)=>{
      console.log(action.payload)
      state.motivates = action.payload.motivatesName
      state.otherMotivationesValue = action.payload.others
    },
    resetStore: (state,action) =>{
      return initialState
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateName, updateAge,updateGender,updateMotivates,resetStore, updateDOB, updateSurName, updateTopicData, updateSelectedTopic, updateTopicDataYoutubeLink, updateTopicDataProfile, updateImageUrl} = onbordingSlice.actions

export default onbordingSlice.reducer