import { Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./EditHabit.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import GoalDatePicker from "../GoalDatePicker/GoalDatePicker";
import moment from "moment";
import GoalTimePicker from "../GoalTimePicker/GoalTimePicker";
import DatePicker from "../../DatePicker/DatePicker";
import { CalanderDialog } from "../../GoalComponent/GoalDialog";
import useNotifications from "../../../Hooks/useNotifications";
import ChipSaveButton from "../../ChipSaveButton";
function EditHabit({
  selectedHabitData,
  setSelectedHabitData,
  setIsHabitScheduleOpen,
  editHabitTextHandler = () => { }
}) {
  console.log("selectedHabitData", selectedHabitData);
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [isAskOpenDateModal, setIsAskOpenDateModal] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);
  const [goalStartDate, setStartDate] = useState(selectedHabitData?.goalStartDate ? moment(selectedHabitData?.goalStartDate) : null);
  const [goalEndDate, setEndDate] = useState(selectedHabitData?.goalEndDate ? moment(selectedHabitData?.goalEndDate) : null);
  const [isDatePickerOpen, setisDatePickerOpen] = useState(false);
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);
  const [selectedDate, setselectedDate] = useState(
    selectedHabitData?.habitSchedule &&
    selectedHabitData?.habitSchedule?.split("-")[1]
  );
  // const [selectedDaystate, setSelectedDaystate] = useState(
  //   selectedHabitData?.days?.length &&
  //   selectedHabitData?.days?.map(
  //     (item) => item.charAt(0) + item.substring(1).toLowerCase().slice(0, 2)
  //   )
  // );
  const { Notification, updateNotificationState, action } =
    useNotifications(false);
  const [selectedDaystate, setSelectedDaystate] = useState(
    selectedHabitData?.days
  );
  const setselectedDayHandler = (day) => {
    const findData = selectedDaystate.find((o) => o === day);
    if (findData) {
      setSelectedDaystate((current) => current.filter((data) => data !== day));
    } else {
      setSelectedDaystate([...selectedDaystate, day]);
    }
  };
  const dateselectorHandler = (date) => {
    setselectedDate(date);
  };
  console.log("goalStartDate", goalStartDate);
  const dateModalClosehandler = () => {
    setSelectedHabitData({
      habitName: selectedHabitData.habitName,
      habitDetail: selectedHabitData.habitDetail,
      habitId: selectedHabitData.habitId,
      habitSchedule: `${moment().format(
        "MM"
      )}-${selectedDate}-${moment().format("YYYY")}`,
      days: selectedHabitData?.days,
      habitScheduleType: selectedHabitData?.habitScheduleType,
      goalStartDate,
      goalEndDate,
      calenderStatus: selectedHabitData.calenderStatus
    });
    setisDatePickerOpen(false);
  };
  const dayModalCloseHandler = () => {
    setSelectedHabitData({
      habitName: selectedHabitData.habitName,
      habitDetail: selectedHabitData.habitDetail,
      habitId: selectedHabitData.habitId,
      habitSchedule: `${moment().format(
        "MM"
      )}-${selectedDate}-${moment().format("YYYY")}`,
      days: selectedDaystate,
      habitScheduleType: selectedHabitData?.habitScheduleType,
      goalStartDate,
      goalEndDate,
      calenderStatus: selectedHabitData.calenderStatus

    });
    setIsDayPickerOpen(false);
  };
  const startDateSelect = (val) => {
    setSelectedHabitData({
      habitName: selectedHabitData.habitName,
      habitDetail: selectedHabitData.habitDetail,
      habitId: selectedHabitData.habitId,
      habitSchedule: `${moment().format(
        "MM"
      )}-${selectedDate}-${moment().format("YYYY")}`,
      days: selectedDaystate,
      habitScheduleType: selectedHabitData?.schedule_type,
      goalStartDate: val,
      goalEndDate: selectedHabitData?.goalEndDate,
      calenderStatus: selectedHabitData.calenderStatus
    });
    setStartDate(val)
  }
  const endDateSelect = (val) => {
    console.log("val", val, selectedHabitData?.originalEndFDate);
    if (moment(val).isBefore(moment(selectedHabitData?.originalEndFDate))) {
      updateNotificationState("You can not select previous date from old end date")
      setTimeout(() => {
        updateNotificationState("", false)
      }, 1000);
    } else {
      setSelectedHabitData({
        habitName: selectedHabitData.habitName,
        habitDetail: selectedHabitData.habitDetail,
        habitId: selectedHabitData.habitId,
        habitSchedule: `${moment().format(
          "MM"
        )}-${selectedDate}-${moment().format("YYYY")}`,
        days: selectedDaystate,
        habitScheduleType: selectedHabitData?.schedule_type,
        goalEndDate: val,
        goalStartDate: selectedHabitData?.goalStartDate,
        originalEndFDate: selectedHabitData?.originalEndFDate,
        calenderStatus: selectedHabitData.calenderStatus
      });
      setEndDate(val)
    }

  }
  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.editInnerWrapper}>
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              " & .MuiOutlinedInput-input": {
                color: "#190D1A",
                padding: "10px",
                fontWeight: 500,
                fontSize: "12px",
              },
            }}
            InputProps={{
              sx: {
                "&:hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              },
            }}
            fullWidth
            name="text"
            value={selectedHabitData.habitName}
            onChange={(e) =>
              setSelectedHabitData({
                habitName: e.target.value,
                habitDetail: selectedHabitData.habitDetail,
                habitId: selectedHabitData.habitId,
                habitSchedule: selectedHabitData.habitSchedule,
                days: selectedHabitData.days,
                habitScheduleType: selectedHabitData?.habitScheduleType,
                goalStartDate,
                goalEndDate,
                calenderStatus: selectedHabitData.calenderStatus
              })
            }
            type="text"
            placeholder="Text"
            id="outlined-start-adornment"
          />
          <Divider />
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              " & .MuiOutlinedInput-input": {
                color: "#190D1A",
                padding: "10px",
                fontWeight: 500,
                fontSize: "12px",
              },
            }}
            InputProps={{
              sx: {
                "&:hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              },
            }}
            fullWidth
            name="text"
            value={selectedHabitData.habitDetail}
            onChange={(e) =>
              setSelectedHabitData({
                habitDetail: e.target.value,
                habitName: selectedHabitData.habitName,
                habitId: selectedHabitData.habitId,
                habitSchedule: selectedHabitData.habitSchedule,
                days: selectedHabitData.days,
                habitScheduleType: selectedHabitData?.habitScheduleType,
                goalStartDate,
                goalEndDate,
                calenderStatus: selectedHabitData.calenderStatus
              })
            }
            type="text"
            placeholder="Text"
            id="outlined-start-adornment"
          />
          <Divider />
          <div className={classes.DateAndTimewrapper}>
            <img src={Clock} />
            <Typography
              variant="body3"
              className={classes.dateAndTimeText}
              onClick={() => {
                setIsAskOtherModalOpen(true);
                selectedHabitData.habitScheduleType === "MONTHLY" &&
                  setisDatePickerOpen(true);
                selectedHabitData.habitScheduleType === "WEEKLY" &&
                  setIsDayPickerOpen(true);
                selectedHabitData.habitScheduleType === "DAILY" &&
                  setIsHabitScheduleOpen(true);
              }}
            >
              {selectedHabitData.habitScheduleType === "DAILY"
                ? selectedHabitData.habitScheduleType
                : selectedHabitData.habitScheduleType === "MONTHLY"
                  ? `${selectedDate}th of each month`
                  : `Every ${selectedDaystate?.map(
                    (item) =>
                      item.charAt(0) + item.substring(1).toLowerCase().slice(0, 2)
                  )} `}
            </Typography>
          </div>
          <Divider />
          <div className={classes.DateAndTimewrapper}>
            <img src={Clock} />
            <Typography
              variant="body3"
              className={classes.dateAndTimeText}
            // onClick={() => {
            //   setIsEndDate(false)
            //   setIsAskOpenDateModal(true)
            // }}
            >
              {`${moment(goalStartDate).format(" DD MMM  YYYY")}`}{" "}
            </Typography>
          </div>
          <Divider />
          <div className={classes.DateAndTimewrapper}>
            <img src={Clock} />
            <Typography
              variant="body3"
              className={classes.dateAndTimeText}
              onClick={() => {
                setIsEndDate(true)
                setIsAskOpenDateModal(true)
              }}
            >
              {`${moment(goalEndDate).format(" DD MMM  YYYY")}`}{" "}
            </Typography>
          </div>
          <ChipSaveButton
            title="Save"
            style={{
              marginTop: '10px'
            }}
            onSave={editHabitTextHandler}
          />
          <div style={{ background: 'red'}}>
            <CalanderDialog
              open={isAskOpenDateModal}
              onClose={() => setIsAskOpenDateModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className={classes.calanderWrapper}>
                <div className={classes.DateAndTimewrapper}>
                  <img src={Clock} />
                  <Typography variant="body2" sx={{ color: "#190D1A" }}>
                    Set duration
                  </Typography>
                </div>
                <div style={{ width: "100%" }}>
                  <Typography variant="small">{!isEndDate ? 'Starts' : 'Ends'}</Typography>
                  <div className={classes.dateTimeRangePickerWrapper}>
                    <div className={classes.dateTimeRangePickerSubWrapper}>
                      <Typography
                        variant="body2"
                        sx={{
                          // color:
                          //   rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER &&
                          //   "#9747FF",
                        }}
                        onClick={() => {

                        }}
                      >
                        {" "}
                        {moment(isEndDate ? goalEndDate : goalStartDate).format("DD MMM YYYY")}
                      </Typography>
                    </div>

                  </div>
                </div>
                {isEndDate ? (
                  <DatePicker
                    currentDate={new Date(moment(goalEndDate))}
                    setCurrentDate={endDateSelect}
                  />
                ) : (
                  <DatePicker
                    currentDate={new Date(moment(goalStartDate))}
                    setCurrentDate={startDateSelect}
                  />
                )}
                <ChipSaveButton
                  title="Save"
                  style={{
                    marginTop: '10px'
                  }}
                  onSave={() => setIsAskOpenDateModal(false)}
                />
              </div>
            </CalanderDialog>
          </div>
        </div>

        {isDatePickerOpen && (
          <GoalDatePicker
            isDatePickerOpen={isDatePickerOpen}
            dateModalClosehandler={dateModalClosehandler}
            dateselectorHandler={dateselectorHandler}
            selectedDate={selectedDate}
          />
        )}
        {isDayPickerOpen && (
          <GoalTimePicker
            isDayPickerOpen={isDayPickerOpen}
            dayModalCloseHandler={dayModalCloseHandler}
            setselectedDayHandler={setselectedDayHandler}
            selectedDaystate={selectedDaystate}
            selectedHabitData={selectedHabitData}
          />
        )}
      </div>
      {Notification}
    </>
  );
}
export default EditHabit;
