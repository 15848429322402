import { CircularProgress, Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import { InputField } from "../../InviteFlow/StyledComponent";
import Search from "../../../assets/Svg/Search.svg";
import { useEffect } from "react";
import classes from "./TestItem.module.css";
import Gift from "../../../assets/Svg/Gift.svg";
import { imageURLRender } from "../../../utils";

const DimentionUnlock = props => {
    const { dimetionModalVisible, setDiamondModalVisible,
        dimensions,
        allDimensions,
        searchValue,
        setSearchValue,
        searchDimension,
        loadingData,
        setLoadingData,
        setSelectedItem,
        setConfirmationVisible,
        setDimensions,
        getDimentions } = props
    useEffect(() => {
        if (!allDimensions) {
            setLoadingData(true)
            getDimentions()
        }
    })
    return <Dialog
        open={dimetionModalVisible}
        onClose={() => {
            setSearchValue('')
            setDimensions(allDimensions)
            setDiamondModalVisible(false);
        }}
        maxWidth={'lg'}

        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                position: "unset",
                padding: "20px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.cardTitle}>
                Select dimension you want to have immediate access to
            </div>
            <div className={classes.closeIcon} onClick={() => {
                setSearchValue('')
                setDimensions(allDimensions)
                setDiamondModalVisible(false);
            }}>
                x
            </div>
        </div>

        {loadingData && (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>
        )}
        {!loadingData && (
            <>
                <div >
                    <InputField
                        size="small"
                        fullWidth
                        type="text"
                        autoFocus={searchValue !== ""}
                        value={searchValue}
                        onChange={(e) => searchDimension(e)}
                        placeholder="Search Dimesion"
                        id="outlined-start-adornment"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={Search} alt="" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Grid container spacing={0} className={classes.testListWrapper}>

                    {dimensions ?
                        dimensions.length ? dimensions.map(data => {
                            const {
                                _id,
                            } = data;
                            const { url } = data.data[data.data.length - 1]
                            return (
                                <Grid
                                    item
                                    xs={11.8}
                                    sm={5.8}
                                    md={2.8}
                                    lg={2.8}
                                    xl={1.8}
                                    key={_id}
                                    sx={{
                                        border: '1px solid #E5D5FC',
                                        borderRadius: '16px',
                                        justifyContent: 'space-around',
                                        margin: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <div className={classes.maincontainerWrapper}
                                        onClick={() => {
                                            setSelectedItem(data)
                                            setConfirmationVisible(true)
                                        }}>
                                        <div className={classes.mainWrapper}>
                                            <div className={classes.headerWrapper}>
                                                <div className={classes.headerTitleWrapper}>
                                                    <div>
                                                        <img
                                                            src={
                                                                url === "/public/images/"
                                                                    ? Gift
                                                                    : imageURLRender(url)
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <Typography variant="caption">{data.questionCategory}</Typography>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Grid>
                            )
                        }) : (
                            <div
                                style={{
                                    margin: "auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: "28px !important",
                                    }}
                                >
                                    No data found
                                </Typography>
                            </div>
                        ) : null}
                </Grid>
            </>
        )}


    </Dialog>
}

export default DimentionUnlock