import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  GetGrowthData: [],
  isGetGrowthData:false
};
export const GetAllGrowthData = createSlice({
  name: "GetGrowthData",
  initialState,
  reducers: {
    storeGetGrowthData: (state, action) => {
     state.GetGrowthData = action.payload;
    },
    updateIsGetGrowthData: (state, action) => {
      state.isGetGrowthData = action.payload;
     },
     resetGrowthStore: (state,action) =>{
      return initialState
    }
  },
});
// Action creators are generated for each case reducer function
export const { storeGetGrowthData,updateIsGetGrowthData, resetGrowthStore } = GetAllGrowthData.actions;
export default GetAllGrowthData.reducer;
