import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import classes from "./FaverouiteStepOne.module.css";
import ExpandMore from "../../../assets/Svg/ExpandMore.svg";
import CheckboxStartIcon from "../../../assets/Svg/CheckboxStartIcon.svg";
import CheckboxEndIcon from "../../../assets/Svg/CheckboxEndIcon.svg";
import PinkRadio from "../../../assets/Svg/PinkRadio.svg";
import { TypeFormatFlags } from "typescript";
import RadioCheckedIcon from "../../../assets/Svg/RadioCheckedIcon.svg";
import {
  AllButton,
  AllFavourite,
  FavouriteButton,
} from "../../../components/GoalComponent/GoalButton";
import { GOALTYPE } from "../../../Constant/GoalConstant";
import { GoalFaverouteDiamention } from "../../../services/auth";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { imageURLRender } from "../../../utils";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";

function FaverouiteStepOne({
  setTypeGoal,
  typeGoal,
  onNext,
  getGoalDetail,
  goalDetail,
  setselectedDiamenstion,
  handleClose
}) {
  useEffect(() => {
    const user = localStorage.getItem("userDetail");
    const type = "FAV";
    GoalFaverouteDiamention(user, type)
      .then((data) => {
        setFavDiamension(data?.allPercentage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsFavDiamension(true);
      });
    if (goalDetail.questionCategoryId) {
      setExpanded(goalDetail.questionCategoryId);
    }
  }, []);
  const [expanded, setExpanded] = React.useState(false);
  const [favDiamension, setFavDiamension] = useState([]);
  const [isFavDiamension, setIsFavDiamension] = useState(false);
  const [questionCategoryid, setquestionCategoryId] = useState("");
  console.log(" favDiamension = ", favDiamension);
  const handleChange =
    (panel, questionCategoryId, questionCategory) => (event, isExpanded) => {
      setquestionCategoryId(questionCategoryId);
      setselectedDiamenstion(questionCategory);
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} />
      <div className={classes.stepOneWrapper}>
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div style={{ margin: "32px 0px 10px 0px" }}>
          <Typography variant="body4">Select a dimension</Typography>
        </div>
        <AllFavourite>
          {" "}
          <AllButton
            onClick={() => {
              setTypeGoal(GOALTYPE.ALL_DIAMENSTIONGOAL);
            }}
            variant={
              typeGoal === GOALTYPE.ALL_DIAMENSTIONGOAL ? "contained" : null
            }
          >
            All
          </AllButton>
          <FavouriteButton
            onClick={() => {
              setTypeGoal(GOALTYPE.FAVEROUITE_DIAMENSTIONGOAL);
            }}
            variant={
              typeGoal === GOALTYPE.FAVEROUITE_DIAMENSTIONGOAL
                ? "contained"
                : null
            }
          >
            {" "}
            Favourite
          </FavouriteButton>
        </AllFavourite>
        {!isFavDiamension ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={{
              maxHeight: '48vh',
              overflowY: 'scroll',
              width: '100%'
            }}>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  height: favDiamension.length > 6 ? "490px" : "auto",
                }}
              >
                {favDiamension.map((data, index) => {
                  const {
                    questionCategory,
                    questionCategoryId,
                    description,
                    growthFavorites,
                    actionFavorites,
                    url,
                  } = data;
                  return (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={index}>
                      <Accordion
                        sx={{
                          border: "1px solid #F3F3F3",
                          borderRadius: "16px !important",
                          padding: "2.5px 0px",
                        }}
                        className={questionCategoryid ? index : expanded}
                        expanded={expanded === questionCategoryId}
                        onChange={handleChange(
                          questionCategoryId,
                          questionCategoryId,
                          questionCategory
                        )}
                      >
                        <AccordionSummary
                          expandIcon={<img src={ExpandMore} />}
                          className={classes.AccordianSummery}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{
                            "& .Mui-expanded": {
                              margin: "12px 0px !important",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <img
                                src={
                                  url == "/public/images/"
                                    ? CheckboxStartIcon
                                    : imageURLRender(url)
                                }
                                alt=""
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  marginLeft: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {questionCategory}
                              </Typography>
                            </div>
                            <div
                              style={{
                                marginRight: "15px",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              {description && (
                                <HTMLTooltip
                                  title={description}
                                />
                              )}
                              {/* <img src={CheckboxEndIcon} /> */}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <Typography variant="body2">{description}</Typography>
                            <div style={{ marginTop: "24px" }}>
                              <Typography
                                variant="body2"
                                className={classes.checkBoxText}
                              >
                                {" "}
                                Liked growth areas
                              </Typography>
                              {growthFavorites.map((item, index) => {
                                return (
                                  <div className={classes.customRadio}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={PinkRadio}
                                        className={classes.pinkRadioImg}
                                        alt="CheckboxStartIcon"
                                      />

                                      <Typography
                                        variant="body2"
                                        className={classes.checkBoxText}
                                      >
                                        {" "}
                                        {item.point}
                                      </Typography>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ marginTop: "24px" }}>
                              <Typography
                                variant="body2"
                                className={classes.checkBoxText}
                              >
                                {" "}
                                Liked actions
                              </Typography>
                              {actionFavorites.map((item, index) => {
                                return (
                                  <div className={classes.customRadio}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={PinkRadio}
                                        className={classes.pinkRadioImg}
                                        alt="CheckboxStartIcon"
                                      />

                                      <Typography
                                        variant="body2"
                                        className={classes.checkBoxText}
                                      >
                                        {" "}
                                        {item.point}
                                      </Typography>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            <div style={{ position: "absolute",
                bottom: "0px",
                width: "100%",
                marginTop: "10px",
                zIndex: "1",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "column", }}>
              <Button
                className={classes.nextButton}
                variant="outlined"
                onClick={() => {
                  onNext();
                  questionCategoryid === ""
                    ? getGoalDetail(
                      "questionCategoryId",
                      goalDetail?.questionCategoryId
                    )
                    : getGoalDetail("questionCategoryId", questionCategoryid);
                }}
                disabled={
                  expanded === false
                    ? goalDetail?.questionCategoryId === undefined
                      ? true
                      : false
                    : goalDetail?.questionCategoryId === undefined
                      ? false
                      : false
                }
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
    </>

  );
}

export default FaverouiteStepOne;
