import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Typography,
    Grid,
    Snackbar,
    styled,
    LinearProgress,
    linearProgressClasses,
} from "@mui/material";
import XP from "../../assets/Svg/XP.svg";
import classes from "./Dashboard.module.css";
import EmptyStateBox from "./DashboardEmptyBox";
import { FindAndGetXPLevel, imageURLRender } from "../../utils";
import Ludus from "../../assets/Svg/Ludus.svg";
import clsx from "clsx";
import moment from "moment";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateHabitActionCalender } from "../../services/auth";
import { setDahData, updateScheduleAtIndex } from "../../Store/Reducers/dashboard";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import { memo, useState } from "react";
const label = (id) => ({ inputProps: { "aria-label": "Checkbox" + id } });
const ScheduleDataContent = memo(({ openCloseJournalModal }) => {
    const dispatch = useDispatch()
    const {
        userData = {} } = useSelector(state => state.userProfile);
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice);
    const updateProfileXp = (xp, add) => {
        const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
        dispatch(updateUserCions(updatedXp))

    }
    const updateXp = (xp, add) => {
        const updatedXp = !add ? (dashboardDataSlice.completedXP - xp) : (dashboardDataSlice.completedXP + xp)
        dispatch(setDahData({
            completedXP: updatedXp,
            visibleCelebrationmodal: dashboardDataSlice.goalXp <= updatedXp ? true : false
        }))
        updateProfileXp(xp, add)
    }
    const findAndUpdateStatus = (actionId, habitId, status) => {

        dispatch(updateScheduleAtIndex({
            actionId,
            habitId,
            obj: {
                status,
                calenderStatus: status
            }
        }))
        // const updateObj = [...dashboardData]
        // console.log("updateObj", updateObj, updateObj[foundIndex]);
        // updateObj[foundIndex].status = status
        // updateObj[foundIndex].calenderStatus = status
        // this.props.setDahData({
        //   scheduleData: [...updateObj]
        // })
    }
    const handleIsTrue = (e, action, foundXp) => {
        console.log("e", e);
        console.log("e", e);
        e.preventDefault()
        e.stopPropagation()
        console.log("action ===========", action, foundXp);
        if (action.actionId) {
            let createActionData;
            if (action.calenderStatus === "IN_PROGRESS") {

                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    id: action.actionId,
                    type: "ACTION",
                    action: {
                        scheduled_type: action.scheduled_type,
                        // active: "false",
                        colour: action.colour,
                        textColour: action.textColour,
                        executionDate: action.executionDate,
                        executionDateEnd: action.executionDateEnd,
                        calenderStatus: "COMPLETE",
                        status: "COMPLETE",
                        xpChange: "INCREASE"
                    },
                    xpChange: "INCREASE"
                };
            } else {
                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    id: action.actionId,
                    type: "ACTION",
                    action: {
                        scheduled_type: action.scheduled_type,
                        // active: "false",
                        colour: action.colour,
                        textColour: action.textColour,
                        executionDate: action.executionDate,
                        executionDateEnd: action.executionDateEnd,
                        calenderStatus: "IN_PROGRESS",
                        status: "IN_PROGRESS",
                        xpChange: "DECREASE"
                    },
                    xpChange: "DECREASE"
                };
            }
            console.log("createActionData ========", createActionData);
            if (Object.keys(createActionData).length > 0) {
                findAndUpdateStatus(action.actionId, null, createActionData.action.status)
                updateHabitActionCalender(createActionData)
                    .then((res) => {
                        // this.props.setDahData({
                        //   completedXP: createActionData.action.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                        // })
                        updateXp(foundXp, createActionData.action.status !== "IN_PROGRESS")
                        //setIsSetDurationAction(false);
                        // this.callToGetData(
                        //   this.props.dashboardData.selectedDate,
                        //   this.props.dashboardData.selectedDate
                        // );
                        console.log("res = ", res);
                    })
                    .catch((err) => {
                        console.log("err = ", err);
                    });
            }
        } else {
            let createHabitData;
            if (action._id) {
                if (action.calenderStatus === "IN_PROGRESS") {
                    createHabitData = {
                        uid: localStorage.getItem("userDetail"),
                        id: action._id,
                        type: "HABIT",
                        habit: {
                            scheduled_type: action.scheduled_type,
                            executionDate: action.executionDate,
                            executionDateEnd: action.executionDateEnd,
                            calenderStatus: "COMPLETE",
                            status: "COMPLETE",
                            xpChange: "INCREASE"
                        },
                        xpChange: "INCREASE"
                    };
                } else {
                    createHabitData = {
                        uid: localStorage.getItem("userDetail"),
                        id: action._id,
                        type: "HABIT",
                        habit: {
                            scheduled_type: action.scheduled_type,
                            executionDate: action.executionDate,
                            executionDateEnd: action.executionDateEnd,
                            calenderStatus: "IN_PROGRESS",
                            status: "IN_PROGRESS",
                            xpChange: "DECREASE"
                        },
                        xpChange: "DECREASE"
                    };
                }
                console.log("createHabitData ========", createHabitData);
                if (Object.keys(createHabitData).length > 0) {
                    findAndUpdateStatus(null, action._id, createHabitData.habit.status)
                    updateHabitActionCalender(createHabitData)
                        .then((res) => {
                            // this.props.setDahData({
                            //   completedXP: createHabitData.habit.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                            // })
                            updateXp(foundXp, createHabitData.habit.status !== "IN_PROGRESS")
                            // this.callToGetData(
                            //   this.props.dashboardData.selectedDate,
                            //   this.props.dashboardData.selectedDate
                            // );
                            console.log("res = ", res);
                        })
                        .catch((err) => {
                            console.log("err = ", err);
                        });
                }
            }
        }
    };
    console.log("this re-rendered");
    if ([...dashboardDataSlice.scheduleData].length > 0) {
        return <ScheduleDataBox
            handleIsTrue={handleIsTrue} openCloseJournalModal={openCloseJournalModal} />
    }
    return <EmptyStateBox />
})

export default ScheduleDataContent

const ScheduleDataBox = memo(({ handleIsTrue, openCloseJournalModal }) => {
    console.log("this child re-rendered");
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice);
    return dashboardDataSlice.scheduleData.map((it) => {
        const { data } = it;
        const foundXp = FindAndGetXPLevel(it.level, false)
        return (
            <Box className={classes.actionGroupBox} key={it.actionId || it._id}>
                <Checkbox
                    {...label(it.actionId || it._id)}
                    sx={{ pl: "0", height: "20px" }}
                    checked={
                        it.calenderStatus === "COMPLETE"
                    }
                    onChange={(e) => handleIsTrue(e, it, foundXp)}
                />
                <div className={classes.scheduleAction}>
                    <div className={classes.scheduleActionDataContent}>
                        <div
                            className={
                                classes.scheduleActionDataContentData
                            }
                        >
                            <div
                                className={
                                    classes.scheduleActionDataNameContentData
                                }
                            >
                                {data && data[0] && data[0].url ? (
                                    <img
                                        src={imageURLRender(data[0]?.url)}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        src={Ludus}
                                        alt=""
                                    />
                                )}

                                <span
                                    className={
                                        classes.scheduleActionDataContentTitle
                                    }
                                >
                                    {it?.questionCategory}
                                </span>
                            </div>
                            <div
                                className={
                                    classes.scheduleActionDataTagContentData
                                }
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="flex-end"
                                >
                                    {it?.scheduled_type ===
                                        "UNSPECIFIED" ? null : (
                                        <Chip
                                            label={
                                                moment(it?.executionDate)
                                                    .format("HH:mm") +
                                                " - " +
                                                moment(it?.executionDateEnd)
                                                    .format("HH:mm")
                                            }
                                            className={clsx(classes.backgroundChip)}
                                        />
                                    )}

                                    {foundXp && <Chip
                                        label={foundXp}
                                        avatar={<Avatar alt="Natacha" src={XP} className={classes.avatarClass} />}
                                        className={clsx(classes.backgroundChip)}
                                    />}
                                </Stack>
                            </div>
                        </div>
                        {it && it.actionName && (
                            <span className={classes.scheduleActionText}>
                                {it?.actionName}
                            </span>
                        )}
                        {it?.habitName && (
                            <span className={classes.scheduleActionText}>
                                {it?.habitName}
                            </span>
                        )}

                    </div>
                    <Button
                        variant="contained"
                        className={classes.scheduleActionButton}
                        onClick={() => {
                            openCloseJournalModal(true, it);
                        }}
                    >
                        Open Journal
                    </Button>
                </div>
            </Box>
        );
    })

})

function CheckBoxForm({ checkedElm, it, handleIsTrue, foundXp }) {
    const [checked, setChecked] = useState(checkedElm);
    return
}
