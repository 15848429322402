import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SubmitButtonWithBackGround } from "../../../components/Button/Button";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../components/Input/Input";
import {
  CustomCheckBox,
  Step,
  StepsWrapper,
  SubHeadingTwo,
  WrapperDiv,
} from "../StyledComponent";
import { USERAGE } from "../../../Constant/Invite";
import useCustomSixCheckBox from "../../../Hooks/useCustomSixCheckBox";
import classes from "./GetAge.module.css";

const GetAge = (props) => {
  const {
    firstCheckBox,
    secondCheckBox,
    thirdCheckBox,
    fourthCheckBox,
    fifthCheckBox,
    sixthCheckBox,
    handleChange,
  } = useCustomSixCheckBox(
    USERAGE?.TEN_TO_TWENTY,
    USERAGE?.TWENTY_TO_THIRTY,
    USERAGE?.THIRTY_TO_FOURTYT,
    USERAGE?.FOURTY_TO_FIFTY,
    USERAGE?.FIFTY_TO_SIXTY,
    USERAGE?.SIXTY_PLUS,
    props?.showUserdata?.anonymousAge
  );
  const updateMainState = () => {
    if (firstCheckBox) {
      props.getUserDatafnc("anonymousAge", USERAGE?.TEN_TO_TWENTY);
    } else if (secondCheckBox) {
      props.getUserDatafnc("anonymousAge", USERAGE?.TWENTY_TO_THIRTY);
    } else if (thirdCheckBox) {
      props.getUserDatafnc("anonymousAge", USERAGE?.THIRTY_TO_FOURTYT);
    } else if (fourthCheckBox) {
      props.getUserDatafnc("anonymousAge", USERAGE?.FOURTY_TO_FIFTY);
    } else if (fifthCheckBox) {
      props.getUserDatafnc("anonymousAge", USERAGE?.FIFTY_TO_SIXTY);
    } else {
      props.getUserDatafnc("anonymousAge", USERAGE?.SIXTY_PLUS);
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step></Step>
      </StepsWrapper>
      <WrapperDiv>
        <div></div>
        <div>
          <Box className={classes.wrapper}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              {props?.showUserdata?.nickName}, it’s nice to meet you!
            </Typography>
          </Box>
          <SubHeadingTwo>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              What’s your age?
            </Typography>
          </SubHeadingTwo>
          <Box className={classes.wrapper} sx={{ width: "100%", mt: "12px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.buttonWrapper}
              rowSpacing="16px"
              columnSpacing="12px"
            >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                {" "}
                <CustomCheckBox
                  checked={firstCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="10-20" />}
                  onChange={() => {
                    handleChange(USERAGE?.TEN_TO_TWENTY);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="10-20" />}
                />  
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomCheckBox
                  checked={secondCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="20-30" />}
                  onChange={() => {
                    handleChange(USERAGE?.TWENTY_TO_THIRTY);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="20-30" />}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomCheckBox
                  checked={thirdCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="30-40" />}
                  onChange={() => {
                    handleChange(USERAGE?.THIRTY_TO_FOURTYT);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="30-40" />}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomCheckBox
                  checked={fourthCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="40-50" />}
                  onChange={() => {
                    handleChange(USERAGE?.FOURTY_TO_FIFTY);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="40-50" />}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomCheckBox
                  checked={fifthCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="50-60" />}
                  onChange={() => {
                    handleChange(USERAGE?.FIFTY_TO_SIXTY);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="50-60" />}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomCheckBox
                  checked={sixthCheckBox}
                  {...label}
                  icon={<CheckBoxInput placeholder="60+" />}
                  onChange={() => {
                    handleChange(USERAGE?.SIXTY_PLUS);
                  }}
                  checkedIcon={<CheckBoxInputChecked placeholder="60+" />}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
        <Box sx={{ width: "100%", mt: "16px" }}>
          <ButtonGroup
            {...props}
            buttonText="Next"
            onClick={() => {}}
            isNextDisable={
              !firstCheckBox &&
              !secondCheckBox &&
              !thirdCheckBox &&
              !fourthCheckBox &&
              !fifthCheckBox &&
              !sixthCheckBox
            }
            isLoading={false}
            updateMainState={updateMainState}
          />
        </Box>
      </WrapperDiv>
    </>
  );
};

export default GetAge;
