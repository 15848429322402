import React, { useEffect, useState } from "react";
import {
  Grid, Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Input } from "../../../components/Settings/StyledElement/StyledformInput";
import { SubmitButton } from "../../../components/Button/Button";
import classes from "./changePassword.module.css";
import { useTheme } from "@emotion/react";
import { ReactComponent as Download } from "./../../../assets/Svg/File_Download.svg"
import useMediaQuery from "@mui/material/useMediaQuery";
import LeftArrowIcom from "../../../assets/Svg/LeftArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import { getAuth, updatePassword } from "firebase/auth";
import PaymentService from "../../../services/Payments";
import { useSelector } from "react-redux";
import moment from "moment";

const widths = {
  date: '120px',
  name: '300px',
  receipt: '150px',
  total: '100px',
  pdf: '50px',
}

export const BillingInformation = () => {
  const theme = useTheme();
  const { userData } = useSelector((state) => state.userProfile);
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordData, setPasswordData] = useState({
    Password: "",
    NewPassword: "",
  });
  useEffect(() => {
    getBillingData(currentPage, pageLimit)
  }, [currentPage, pageLimit])
  const getBillingData = (page = 1, limit = 10) => {
    PaymentService.getInvoices({
      "user_email": userData?.user_email,
      "uid": userData?.uid,
      limit
    }).then(({ data }) => {
      setTableData(data?.data || [])
      console.log("data ===", data)
    }).catch(err => {
      console.log("err", err)
    })
  }
  const handlePasswordInput = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleSubmitData = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log("user = ", user);
    const newPassword = "12345678";
    updatePassword(user, newPassword)
      .then((res) => {
        console.log("res = ", res);
        // Update successful.
      })
      .catch((error) => {
        console.log("error = ", error.message);
        // An error ocurred
        // ...
      });
    console.log("Feedback feature request send:");
  };

  const backToSideNavigate = () => {
    navigate("/settings/settingnavigationbar");
  };

  return (
    <>
      <Typography variant="body4" className={classes.headerTitle}>Billing History</Typography>
      <Table size="small" className={classes.tbale} aria-label="a dense table">
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                padding: "6px 14px !important",
              },
            }}
          // className={classes.tableHeader}
          >
            <TableCell className={classes.days_header} sx={{ width: widths.date }}>
              {"Date"}
            </TableCell>
            <TableCell className={classes.days_header} sx={{ width: widths.name }}>
              {"Name"}
            </TableCell>
            <TableCell className={classes.days_header} sx={{ width: widths.receipt }}>
              {"Receipt No"}
            </TableCell>
            <TableCell className={classes.days_header} sx={{ width: widths.total }}>
              {"Total"}
            </TableCell>
            <TableCell className={classes.days_header} sx={{ width: widths.pdf }}>
              {"PDF"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
        // onDragOver={(e) => dragOver(e)}
        // onDrop={(e) => drop(e, false, "New Question")}
        // onClick={handleClose}
        >
          {tableData.map((item) => {
            return (
              <TableRow
              // onClick={() => {
              //   if (
              //     Object.keys(updateAction).length &&
              //     updateAction._id
              //   ) {
              //     setIsSetDurationAction(false);
              //     setUpdateAction({});
              //   } else if (
              //     Object.keys(updateHabit).length &&
              //     updateHabit._id
              //   ) {
              //     setIsSetDurationHabit(false);
              //     setUpdateHabit({});
              //   }
              // }}
              >
                <TableCell
                  className={classes.days_data}
                  sx={{ width: widths.date, padding: '16px 14px!important' }}
                >
                  {moment.unix(item.created).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell
                  className={classes.days_data}
                  sx={{ width: widths.name , padding: '16px 14px!important'}}
                >
                  {item?.lines?.data[0]?.description}
                </TableCell>
                <TableCell
                  className={classes.days_data}
                  sx={{ width: widths.receipt , padding: '16px 14px!important'}}
                >
                  {item?.number}
                </TableCell>
                <TableCell
                  className={classes.days_data}
                  sx={{ width: widths.total, padding: '16px 14px!important' }}
                >
                  ${parseFloat(item?.amount_paid / 100).toFixed(2)}
                </TableCell>
                <TableCell
                  className={classes.days_data}
                  sx={{ width: widths.pdf, padding: '16px 14px!important', cursor: 'pointer' }}
                  onClick={() => {
                    item?.hosted_invoice_url && window.open(item?.hosted_invoice_url, '_blank')
                  }}
                >
                  <span style={{ float: 'right' }}>
                    <Download />

                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
