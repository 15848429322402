import { Box, Button, Checkbox, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useMemo, createRef } from "react";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import classes from "./StepFour.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import PinkRadio from "../../../assets/Svg/PinkRadio.svg";
import styled from "@emotion/styled";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import { goalGetPreSelectedGrowth } from "../../../services/auth";
import { CheckBoxFavouriteInputCheckedGoal, CheckBoxInputCheckedGoal, CheckBoxInputGoal, FavouriteMultilineCheckbox } from "../../../components/Input/Input";
import FavoriteIcon from '@mui/icons-material/Favorite';
import typography from "../../../theme/typography";
import { imageURLRender } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
function FavouriteStepFour({ onNext, onPrevious, getGoalDetail, goalDetail ,selectedDiamenstion, selectedDiamenstionImage }) {
console.log("goalDetail = ",goalDetail);
 
  useEffect(() => {
    console.log("useeffect");
    let user = localStorage.getItem("userDetail");
    let questionCategoryId = goalDetail?.questionCategoryId;
    goalGetPreSelectedGrowth(user, questionCategoryId)
      .then((res) => {
        setgoalGrowthArea(res?.data);
        fill();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisGoalGrowth(true);
      });
      document.getElementById("outlined-start-adornment").focus();
  }, []);
  const textInput = createRef(null);
  const [goalGrowthArea,setgoalGrowthArea] = useState([])
  const [isGoalGrowth, setisGoalGrowth] = useState(false)
  const [preselectedState, setPreselectedState] = useState([]);
  const [newInputList, setNewInputList] = useState([
    { text: "", type: "SELF" },
  ]);
  const handleAddMore = () => {
    const isText = newInputList[newInputList.length - 1]?.text;
    console.log(isText);
    if (isText) {
      setNewInputList([...newInputList, { text: "", type: "SELF" }]);
    }
  };
  const growthArea = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SUGGESTED"
  );
  const selfgrowthAreas = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SELF"
  );
  const fill = () => {
    if (growthArea) {
      setPreselectedState(growthArea);
      setNewInputList(selfgrowthAreas);
    }
  };

  const updateMainState = () => {
    let tempArr = [];
    preselectedState?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SUGGESTED", index: data.index });
    });

    newInputList?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SELF" });
    });
    getGoalDetail("preSelectedGrowth", tempArr);
  };
  const InputField = styled(TextField)`
    height: 56px;
    background: #fffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0px;
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #f3f3f3;
      border-radius: 16px;
    }
    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      padding: 16px 10px;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
    &
      .MuiInputBase-formControl.Mui-focused:hover
      .MuiOutlinedInput-notchedOutline {
      border-color: #9747ff;
    }
  `;
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    border-radius: 16px;
    background: #ffffff;
    & .MuiTextField-root {
      border-radius: 16px;
      :hover {
        backgroundcolor: transparent !important;
      }
    }
    & .MuiCheckbox-root {
      :hover {
        border: 1px solid red;
      }
    }
  `;

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const growthAreaHandler = (index, item) => {
    const findData = preselectedState.find((o) => o.index === index);
    if (findData) {
      setPreselectedState((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setPreselectedState([...preselectedState, { index: index, text: item }]);
    }
  };
// const growthFourite = useMemo(() => {
  const growthFourite = () => {
    return (
      <>
        {goalGrowthArea?.map((item, index) => {
          return (
            <CustomCheckBox
              key={index}
              sx={{ margin: "6px 0px" }}
              {...label}
              icon={<FavouriteMultilineCheckbox placeholder={item?.point} />}
              checkedIcon={
                <CheckBoxFavouriteInputCheckedGoal placeholder={item?.point} />
              }
              onChange={() => {
                growthAreaHandler(index, item?.point);
              }}
              checked={
                preselectedState.find((o) => o.index === index) ? true : false
              }
            />
          );
        })}
      </>
    );
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...newInputList];
    console.log("tempList==>", tempList);
    tempList[index][name] = value;
    setNewInputList(tempList);
  };
  return (
    <>
      <div className={classes.StepThreeWrapper}>
      {" "}
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step className={classes.active}></Step>
        <Step></Step>
        <Step></Step>
      </StepsWrapper>
      <div className={classes.progressBarWrapper}>
        <Typography
          variant="body3"
          className={classes.progressTittle}
          sx={{ color: "#9747FF !important" }}
        >
          Choose dimension
        </Typography>
        <Typography
          variant="body3"
          className={classes.progressTittle}
          sx={{ color: "#9747FF !important" }}
        >
          Select growth areas{" "}
        </Typography>
        <Typography variant="body3" className={classes.progressTittle}>
          Add actions
        </Typography>
        <Typography variant="body3" className={classes.progressTittle}>
          Set habits
        </Typography>
      </div>
      <div className={classes.StepThreeSubWrapper}>
      <img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" />
        <Typography variant="h5">
          You have selected <span style={{ color: "#9747FF" }}> hedomism</span>{" "}
        </Typography>
        <Typography
          variant="body4"
          sx={{ margin: "24px 0px 10px 0px", textAlign: "center" }}
        >
          Choose preselected growth area or type in your own
        </Typography>
        {!isGoalGrowth ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box className={classes.radioWrapper}>
           {growthFourite()}
          </Box>
        )}

        <Typography variant="body4" className={classes.AddYourText} sx={{ margin: "20px 0px 16px 0px", textAlign: "center" }}>
          or add your own
        </Typography>
        {newInputList?.map((data, i) => {
          return (
            <TextField
              key={i}
              size="small"
              className={classes.goalActionInput}
              autoFocus={true}
              sx={{
                marginBottom: "12px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #f3f3f3",
                  borderRadius: "16px",
                },
                " & .MuiOutlinedInput-input": {
                  color: " #190d1a",
                  padding: "16px 10px",
                },
                " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                  { border: "1px solid #9747FF" },
              }}
              InputProps={{
                sx: {
                  "&:hover": {
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #e5d5fc",
                    },
                  },
                },
              }}
              fullWidth
              name="text"
              value={data.text}
              onChange={(e) => handleInputChange(e, i)}
              type="text"
              placeholder="Text"
              ref={textInput}
              id="outlined-start-adornment"
            />
          );
        })}
        <div style={{ width: "100%", margin: "12px 0px" }}>
          <CustomShedualButton style={{ minWidth: "91px" }}  onClick={() => {
              handleAddMore();
            }}>
            Add more +
          </CustomShedualButton>
        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onPrevious();
                }}
                // disabled={isPreviousBtnDisable}
              >
                Previous
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onNext();
                }}
                disabled={preselectedState.length === 0 && !selfgrowthAreas && !newInputList[newInputList.length - 1]?.text}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
    </>
    
  );
}

export default FavouriteStepFour;
