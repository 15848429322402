import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { addDays, startOfWeek, format, lastDayOfWeek } from "date-fns";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from "react";
import classes from "./WeeklyCalendar.module.css";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  storeGetGrowthData,
  updateIsGetGrowthData,
} from "../../../Store/Reducers/goalGrowthCalendar";
import moment from "moment";
import { set } from "lodash";
import {
  editPreSelectedGrowth,
  getGrowthData,
  goalUpdateHabitAction,
  growthCalenderCreate,
  updateHabitActionCalender,
} from "../../../services/auth";
import {
  storeGetActionData,
  storeGetCalendarData,
  storeGetHabitData,
  updateIsGetCalenderData,
} from "../../../Store/Reducers/getCalendarData";
import {
  DropActionSnakebar,
  DropGrowthSnakebar,
} from "../../GoalComponent/GoalDialog";
import DropModal from "../DropModal/DropModal";
// const DropModal = lazy(() => import("../DropModal/DropModal"));
import useCustomNotification from "../../../Hooks/useCustomNotification";
import useCustomNotificationActionHabit from "../../../Hooks/useCustomNotiFicationActionHabit";
import PlusLargeIcon from "../../../assets/Svg/PlusLargeIcon.svg";
import useCustomNotificationColourPick from "../../../Hooks/useCustomNotificationColourPick";
import SetDurationActionModal from "../SetDurationAction/SetDurationActionModal";
// import WeeklyGrowthArea from "../WeeklyGrowthArea/WeeklyGrowthArea";
// import WeeklyActionHabit from "../WeeklyActionHabit/WeeklyActionHabit";

import UnspecifiedActionHabit from "../UnspecifiedActionHabit/UnspecifiedActionHabit";
import SmallEngagement from "../../../assets/Svg/SmallEngagement.svg";
import DoneIcon from "@mui/icons-material/Done";
import SetDurationHabitModal from "../SetDurationHabitModal/SetDurationHabitModal";
const WeeklyActionHabit = lazy(() =>
  import("../WeeklyActionHabit/WeeklyActionHabit")
);
const WeeklyGrowthArea = lazy(() =>
  import("../WeeklyGrowthArea/WeeklyGrowthArea")
);
const TimeData = [
  {
    time: "",
  },
  {
    time: "12 am",
  },
  {
    time: "1 am",
  },
  {
    time: "2 am",
  },
  {
    time: "3 am",
  },
  {
    time: "4 am",
  },
  {
    time: "5 am",
  },
  {
    time: "6 am",
  },
  {
    time: "7 am",
  },
  {
    time: "8 am",
  },
  {
    time: "9 am",
  },
  {
    time: "10 am",
  },
  {
    time: "11 am",
  },
  {
    time: "12 pm",
  },
  {
    time: "1 pm",
  },
  {
    time: "2 pm",
  },
  {
    time: "3 pm",
  },
  {
    time: "4 pm",
  },
  {
    time: "5 pm",
  },
  {
    time: "6 pm",
  },
  {
    time: "7 pm",
  },
  {
    time: "8 pm",
  },
  {
    time: "9 pm",
  },
  {
    time: "10 pm",
  },
  {
    time: "11 pm",
  },
];
const WeeklyCalendar = ({
  currentMonthOfWeek,
  getGrowthCalendarDate,
  setGetGrowthCalendarDate,
  bgcolor,
  setBgColor,
  setCalendarData,
  calendarData,
  matches,
  setIsAskOtherModalOpen,
  typeCalendar,
  isWeekTrue,
}) => {
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const disapatch = useDispatch();
  const [tableCellId, setTableCellId] = useState("");
  const [colorPickData, setColorPickData] = useState({});
  const startDate = startOfWeek(currentMonthOfWeek, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(currentMonthOfWeek, { weekStartsOn: 1 });
  const [updateColourPickData, setUpdateColourPickData] = useState({});
  const [isDropGrowthAreaOpen, setisDropGrowthAreaOpen] = useState(false);
  const [editGrowthArea, setEditGrowthArea] = useState({});
  const [createdData, setCreatedData] = useState({});
  const [actionSliceData, setActionSliceData] = useState({});
  const [isSetDurationAction, setIsSetDurationAction] = useState(false);
  const [actionData, setActionData] = useState({});
  const [editActionHabit, setEditActionHabit] = useState({});
  const [updateAction, setUpdateAction] = useState({});
  const [updateHabit, setUpdateHabit] = useState({});
  const [isSetDurationHabit, setIsSetDurationHabit] = useState(false);
  const [habitData, setHabitData] = useState({});
  const [habitSliceData, setHabitSliceData] = useState({});
  const [countAcH, setCountAcH] = useState([]);

  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const {
    NotificationContentColourPickJSX,
    customNotificationColourPick,
    setCustomNotificationColourPick,
  } = useCustomNotificationColourPick();
  const {
    NotificationContentActionHabitJSX,
    customNotificationActionHabit,
    setCustomNotificationActionHabit,
  } = useCustomNotificationActionHabit();
  var lastday = function (y, m) {
    return new Date(y, m + 1, 0).getDate();
  };
  const dateFormat = "EEE d";
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";
  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      days.push({ formattedDate: day });
      day = addDays(day, 1);
    }
  }
  useEffect(() => {
    if (days.length) {
      const startDate = moment(days[0]?.formattedDate).format("MM-DD-yyyy");
      var theDate = new Date(startDate);
      let fromDate = new Date(theDate);
      fromDate.setDate(fromDate.getDate() - 13);
      const endDate = moment(days[days.length - 1]?.formattedDate).format(
        "MM-DD-yyyy"
      );
      var theDate = new Date(endDate);
      let toDate = new Date(theDate);
      toDate.setDate(toDate.getDate() + 13);
      setGetGrowthCalendarDate({
        fromDate: fromDate,
        toDate: toDate,
      });
    }
  }, [currentMonthOfWeek]);

  const dragStart = (e, ele) => {
    let element = e.currentTarget;
    element.classList.add("dragged");
    e.dataTransfer.setData("text/plain", JSON.stringify(ele));
    e.dataTransfer.effectAllowed = "move";
  };
  const dragEnter = (e, position) => {
    e.preventDefault();
    let element = e.currentTarget;
    element.classList.add("dragged-over");
    e.dataTransfer.dropEffect = "move";
  };
  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const drop = (e, value, status) => {
    if (calendarData) {
      setCalendarData({});
    }
    if (actionSliceData) {
      setActionSliceData({});
    }
    if (updateAction) {
      setUpdateAction({});
    }
    let dateId = e.target.id;
    console.log("dateId = ", dateId);
    setTableCellId(dateId);
    const date = dateId.split(" ");
    const startDate = date[0] + date[1];

    e.preventDefault();
    let text = e.dataTransfer.getData("text/plain");
    e.target.classList.remove("dragged-over");
    const jsonData = JSON.parse(text);
    var theDate = new Date(startDate);
    let endDate = new Date(theDate);
    endDate.setDate(endDate.getDate() + 13);

    if (jsonData.type === "GA") {
      const editPreSelectedGrowthData = {
        uid: localStorage.getItem("userDetail"),
        goalId: jsonData.id,
        preSelectedGrowth: jsonData.preSelectedGrowth.map((item) => {
          if (item.text === jsonData.text) {
            return { ...item, active: "false" };
          }
          return item;
        }),
        preSelectedGrowthComplete: jsonData.preSelectedGrowthComplete,
      };
      setEditGrowthArea(editPreSelectedGrowthData);
    } else if (jsonData.type === "ACTION") {
      const updateActionHabit = {
        uid: localStorage.getItem("userDetail"),
        id: jsonData.id,
        type: jsonData.type,
        action: jsonData.actions.map((item) => {
          if (item.actionName === jsonData.text) {
            return { ...item, active: "false" };
          }
          return item;
        }),
      };
      setEditActionHabit(updateActionHabit);
    }

    function dateRange(startDate, endDate, steps = 1) {
      const dateArray = [];
      let currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        dateArray.push({
          date: moment(new Date(currentDate)).format("DD-MMMM, yyyy"),
        });
        currentDate.setUTCDate(currentDate.getUTCDate() + steps);
      }
      return dateArray;
    }
    const dates = dateRange(date[0] + " " + date[1], endDate);
    const isColorasign = getCalenderDataSlice?.getCalenderData.find(
      (item) => item.questionCategory === jsonData.name
    );
    let temp = [];
    getCalenderDataSlice?.getCalenderData?.map((item) => {
      if (
        moment(new Date(item.startDate)).format("DD-MMMM,yyyy") === startDate
      ) {
        temp.push(item);
      } else {
        item.dates.map((ele) => {
          if (moment(new Date(ele.date)).format("DD-MMMM,yyyy") === startDate) {
            temp.push(item);
          }
        });
      }
    });
    console.log("temp = ", temp);
    if (jsonData.type === "GA") {
      let tempDropedData = {
        uid: calendarData?.uid,
        category: calendarData?.category,
        subCategory: calendarData?.subCategory,
        subCategory_id: calendarData.subCategory_id,
        questionCategoryId: jsonData.questionCategoryId,
        questionCategory: jsonData.name,
        startDate: startDate,
        endDate: endDate,
        colour: isColorasign ? isColorasign.colour : jsonData.colour,
        textColour: isColorasign
          ? isColorasign.textColour
          : jsonData.textColour,
        progress: "IN_PROGRESS",
        name: jsonData.text,
        type: jsonData.type,
        dates: dates,
        _id: calendarData._id,
        createdAt: calendarData.createdAt,
        updatedAt: calendarData.updatedAt,
      };
      if (!temp.length) {
        let tempGrowth = [];
        getCalenderDataSlice.getCalenderData.map((item, index) => {
          item.dates.map((dateRange) => {
            dates.map((dateIndex) => {
              if (
                moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                dateIndex.date
              ) {
                console.log("dates = ");
                return tempGrowth.push(item);
              }
            });
          });
        });
        const unique2 = tempGrowth.filter((obj, index) => {
          return index === tempGrowth.findIndex((o) => obj._id === o._id);
        });
        console.log("unique2 = ", unique2);
        if (unique2.length >= 2) {
          setCustomNotification(true);
          setisDropGrowthAreaOpen(false);
        }
        if (temp.length < 2 && unique2.length < 2) {
          console.log("tempDropedData = ", tempDropedData);
          setCalendarData({});
          disapatch(
            storeGetCalendarData([
              ...getCalenderDataSlice.getCalenderData,
              tempDropedData,
            ])
          );
        }
      }
    }
    if (!temp.length && jsonData.type === "ACTION") {
      setCustomNotificationActionHabit(true);
    } else if (jsonData.type === "ACTION") {
      const executionDate = moment(new Date(jsonData.executionDate)).format(
        "DD-MMMM,yyyy"
      );
      let tempDropedData = {
        uid: localStorage.getItem("userDetail"),
        actionDetail: actionSliceData?.actionDetail,
        actionId: actionSliceData?.actionId,
        actionName: jsonData?.text,
        active: actionSliceData?.active,
        archive: actionSliceData?.archive,
        calenderStatus: actionSliceData?.calenderStatus,
        category: actionSliceData?.category,
        category_id: actionSliceData?.category_id,
        executionDate: dateId,
        executionDateEnd: actionSliceData?.executionDateEnd,
        scheduled_type: "TIME_BASED",
        status: "IN_PROGRESS",
        traits: [],
        subCategory: actionSliceData?.subCategory,
        subCategory_id: actionSliceData.subCategory_id,
        questionCategoryId: jsonData.questionCategoryId,
        questionCategory: jsonData.name,
        colour: isColorasign ? isColorasign.colour : jsonData.colour,
        textColour: isColorasign
          ? isColorasign.textColour
          : jsonData.textColour,
        _id: actionSliceData._id,
        createdAt: actionSliceData.createdAt,
        updatedAt: actionSliceData.updatedAt,
      };
      setCalendarData({});

      const findDimantion1 = temp[0];
      const findDimantion2 = temp[1];
      if (findDimantion1.questionCategoryId === jsonData.questionCategoryId) {
        // findDimantion1.dates.map((ele) => {
        //   if (
        //     moment(new Date(ele.date)).format("DD-MMMM, yyyy h a") ===
        //     dateId
        //   ) {
        setIsSetDurationAction(true);
        setActionData({
          ...jsonData,
          ...{
            executionDate: startDate,
            colour: isColorasign.colour,
            textColour: isColorasign.textColour,
          },
        });
        disapatch(
          storeGetActionData([
            ...getCalenderDataSlice?.getActionData,
            tempDropedData,
          ])
        );
        // }
        // });
      } else {
        if (
          findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
          temp.length === 2
        ) {
          setCustomNotificationActionHabit(false);
        } else {
          setCustomNotificationActionHabit(true);
        }
      }
      if (
        findDimantion2.questionCategoryId === jsonData.questionCategoryId &&
        temp.length === 2
      ) {
        // findDimantion2.dates.map((ele) => {
        //   if (
        //     moment(new Date(ele.date)).format("DD-MMMM, yyyy h a") ===
        //     moment(new Date(dateId)).format("DD-MMMM, yyyy h a")
        //   ) {
        setIsSetDurationAction(true);
        setActionData({
          ...jsonData,
          ...{
            executionDate: startDate,
            colour: isColorasign.colour,
            textColour: isColorasign.textColour,
          },
        });
        disapatch(
          storeGetActionData([
            ...getCalenderDataSlice?.getActionData,
            tempDropedData,
          ])
        );
        //   }
        // });
      } else {
        setCustomNotificationActionHabit(true);
      }
    }
    if (jsonData.type === "GA") {
      if (!temp.length) {
        let tempGrowth = [];
        getCalenderDataSlice.getCalenderData.map((item, index) => {
          item.dates.map((dateRange) => {
            dates.map((dateIndex) => {
              if (
                moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                dateIndex.date
              ) {
                console.log("dates = ");
                return tempGrowth.push(item);
              }
            });
          });
        });
        const unique2 = tempGrowth.filter((obj, index) => {
          return index === tempGrowth.findIndex((o) => obj._id === o._id);
        });
        console.log("unique2 = ", unique2);
        if (unique2.length >= 2) {
          setCustomNotification(true);
          setisDropGrowthAreaOpen(false);
        } else {
          const updateData = jsonData.preSelectedGrowth.map((ele) => {
            if (ele.text === jsonData.text) {
              return { ...ele, active: "false" };
            }
            return ele;
          });
          const tempGetAllGrowthData = [];
          getAllGrowthData?.GetGrowthData?.map((data) => {
            tempGetAllGrowthData.push({
              actions: data.actions,
              actionsArchived: data.actionsArchived,
              actionsComplete: data.actionsComplete,
              archive: data.archive,
              habits: data.habits,
              habitsArchived: data.habitsArchived,
              habitsComplete: data.habitsComplete,
              increase: data.increase,
              mySelf: data.mySelf,
              others: data.others,
              preSelectedGrowth:
                data.questionCategoryId === jsonData.questionCategoryId
                  ? updateData
                  : jsonData.preSelectedGrowth,
              preSelectedGrowthComplete: data.preSelectedGrowthComplete,
              questionCategory: data.questionCategory,
              questionCategoryId: data.questionCategoryId,
              status: data.status,
              tbd: data.tbd,
              uid: data.uid,
              url: data.url,
              _id: data._id,
            });
            return data;
          });
          disapatch(storeGetGrowthData(tempGetAllGrowthData));
          setisDropGrowthAreaOpen(true);
        }
      } else if (temp.length < 2) {
        if (temp.length == 1) {
          let tempGrowth = [];
          getCalenderDataSlice.getCalenderData.map((item, index) => {
            item.dates.map((dateRange) => {
              dates.map((dateIndex) => {
                if (
                  moment(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
                  dateIndex.date
                ) {
                  console.log("dates = ");
                  return tempGrowth.push(item);
                }
              });
            });
          });
          const unique2 = tempGrowth.filter((obj, index) => {
            return index === tempGrowth.findIndex((o) => obj._id === o._id);
          });
          console.log("unique2 = ", unique2);
          if (unique2.length >= 2) {
            setCustomNotification(true);
            setisDropGrowthAreaOpen(false);
          }
          if (unique2.length < 2) {
            temp.map((ele) => {
              if (ele.questionCategoryId === jsonData.questionCategoryId) {
                const createData = {
                  uid: localStorage.getItem("userDetail"),
                  questionCategoryId: jsonData.questionCategoryId,
                  name: jsonData.text,
                  type: jsonData.type,
                  colour: isColorasign && ele.colour,
                  textColour: isColorasign && ele.textColour,
                  startDate: startDate,
                  endDate: endDate,
                };
                // setCalendarData("");
                setCreatedData(createData);
                setisDropGrowthAreaOpen(false);
              } else {
                setisDropGrowthAreaOpen(true);
              }
            });
          }
        }
      } else {
        setCustomNotification(true);
      }
    }

    setColorPickData({
      ...jsonData,
      ...{ startDate: startDate, endDate: endDate },
    });
  };

  useEffect(() => {
    if (Object.keys(createdData).length) {
      growthCalenderCreate(createdData)
        .then((res) => {
          setCreatedData("");
          const temp = getCalenderDataSlice.getCalenderData.map((item) => {
            if (item.name === createdData.name) {
              return {
                ...item,
                uid: res?.growthdata?.uid,
                category: res?.growthdata?.category,
                subCategory: res?.growthdata?.subCategory,
                subCategory_id: res?.growthdata?.subCategory_id,
                questionCategoryId: res?.growthdata?.questionCategoryId,
                questionCategory: res?.growthdata?.questionCategory,
                startDate: res?.growthdata?.startDate,
                endDate: res?.growthdata?.endDate,
                colour: res?.growthdata?.colour,
                textColour: res?.growthdata?.textColour,
                progress: res?.growthdata?.progress,
                name: res?.growthdata?.text,
                type: res?.growthData?.type,
                dates: res?.growthdata?.dates,
                _id: res?.growthdata?._id,
                createdAt: res?.growthdata?.createdAt,
                updatedAt: res?.growthdata?.updatedAt,
              };
            }
            return item;
          });
          disapatch(storeGetCalendarData(temp));

          let growthData = {
            uid: localStorage.getItem("userDetail"),
            archive: false,
            status: "IN_PROGRESS",
          };
          getGrowthData(growthData)
            .then((res) => {
              disapatch(storeGetGrowthData(res?.data));
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              disapatch(updateIsGetGrowthData(true));
            });
          if (getAllGrowthData.GetGrowthData) {
            getAllGrowthData.GetGrowthData.map((item) => {
              if (item.questionCategoryId === createdData.questionCategoryId) {
                disapatch(storeGetActionData(item.actions));
              }
            });
          }
          if (editGrowthArea) {
            editPreSelectedGrowth(editGrowthArea)
              .then((res) => {
                console.log("res = ", res);
              })
              .catch((error) => {
                console.log("error = ", error);
              });
          } else if (editActionHabit) {
            goalUpdateHabitAction(editActionHabit)
              .then((res) => {
                console.log("res = ", res);
              })
              .catch((error) => {
                console.log("error = ", error);
              });
          }
          setisDropGrowthAreaOpen(false);
        })
        .catch((err) => {
          console.log("error = ", err);
        });
    }
  }, [createdData]);
  useEffect(() => {
    if (Object.keys(actionSliceData).length) {
      let tempAction = getCalenderDataSlice?.getActionData?.map((item) => {
        if (item._id === undefined || item._id === null || item._id === "") {
          return {
            ...item,
            actionDetail: actionSliceData.actionDetail,
            actionId: actionSliceData.actionId,
            actionName: actionSliceData.actionName,
            active: "false",
            archive: false,
            calenderStatus: "IN_PROGRESS",
            category: actionSliceData.category,
            category_id: actionSliceData.category_id,
            colour: item.colour,
            createdAt: actionSliceData.createdAt,
            executionDate: actionSliceData.executionDate,
            executionDateEnd: actionSliceData.executionDateEnd,
            questionCategory: actionSliceData.questionCategory,
            questionCategoryId: actionSliceData.questionCategoryId,
            scheduled_type: actionSliceData.scheduled_type,
            status: actionSliceData.status,
            subCategory: actionSliceData.subCategory,
            subCategory_id: actionSliceData.subCategory_id,
            textColour: item.textColour,
            traits: [],
            uid: localStorage.getItem("userDetail"),
            updatedAt: actionSliceData.updatedAt,
            _id: actionSliceData._id,
          };
        }
        return item;
      });
      disapatch(storeGetActionData(tempAction));
      if (tempAction) {
        setActionSliceData({});
      }
    }
  }, [actionSliceData]);
  const handleIsTrue = useCallback(
    (action) => {
      console.log("action = ", action);
      if (action.actionName) {
        let createActionData;
        const tempActionIsTrueFalse = getCalenderDataSlice?.getActionData.map(
          (item) => {
            if (item.actionId === action.actionId) {
              if (item.calenderStatus === "IN_PROGRESS") {
                setIsSetDurationAction(false);
                createActionData = {
                  uid: localStorage.getItem("userDetail"),
                  id: action.actionId,
                  type: "ACTION",
                  action: {
                    scheduled_type: action.scheduled_type,
                    active: "false",
                    colour: action.colour,
                    textColour: action.textColour,
                    executionDate: action.executionDate,
                    executionDateEnd: action.executionDateEnd,
                    calenderStatus: "COMPLETE",
                    status: "COMPLETE",
                  xpChange: "INCREASE"

                  },
                  xpChange: "INCREASE"
                };
                return { ...item, calenderStatus: "COMPLETE" };
              } else {
                setIsSetDurationAction(false);
                createActionData = {
                  uid: localStorage.getItem("userDetail"),
                  id: action.actionId,
                  type: "ACTION",
                  action: {
                    scheduled_type: action.scheduled_type,
                    active: "false",
                    colour: action.colour,
                    textColour: action.textColour,
                    executionDate: action.executionDate,
                    executionDateEnd: action.executionDateEnd,
                    calenderStatus: "IN_PROGRESS",
                    status: "IN_PROGRESS",
                  xpChange: "DECREASE"

                  },
                  xpChange: "DECREASE"
                };
                return { ...item, calenderStatus: "IN_PROGRESS" };
              }
            }
            return item;
          }
        );
        disapatch(storeGetActionData(tempActionIsTrueFalse));
        if (Object.keys(createActionData).length) {
          updateHabitActionCalender(createActionData)
            .then((res) => {
              setIsSetDurationAction(false);
              console.log("res = ", res);
            })
            .catch((err) => {
              console.log("err = ", err);
            });
        }
      } else {
        let createHabitData;
        const tempActionIsTrueFalse = getCalenderDataSlice?.getHabitData.map(
          (item) => {
            if (item._id === action._id) {
              if (item.calenderStatus === "IN_PROGRESS") {
                setIsSetDurationHabit(false);
                createHabitData = {
                  uid: localStorage.getItem("userDetail"),
                  id: action._id,
                  type: "HABIT",
                  habit: {
                    scheduled_type: action.scheduled_type,
                    executionDate: action.executionDate,
                    executionDateEnd: action.executionDateEnd,
                    calenderStatus: "COMPLETE",
                    status: "COMPLETE",
                  xpChange: "INCREASE"

                  },
                };
                return { ...item, calenderStatus: "COMPLETE" };
              } else {
                setIsSetDurationHabit(false);
                createHabitData = {
                  uid: localStorage.getItem("userDetail"),
                  id: action._id,
                  type: "HABIT",
                  habit: {
                    scheduled_type: action.scheduled_type,
                    executionDate: action.executionDate,
                    executionDateEnd: action.executionDateEnd,
                    calenderStatus: "IN_PROGRESS",
                    status: "IN_PROGRESS",
                  xpChange: "DECREASE"

                  },
                };
                return { ...item, calenderStatus: "IN_PROGRESS" };
              }
            }
            return item;
          }
        );
        disapatch(storeGetHabitData(tempActionIsTrueFalse));
        if (Object.keys(createHabitData).length) {
          updateHabitActionCalender(createHabitData)
            .then((res) => {
              setIsSetDurationHabit(false);
              console.log("res = ", res);
            })
            .catch((err) => {
              console.log("err = ", err);
            });
        }
      }
    },
    [getCalenderDataSlice]
  );

  const updateData = useCallback(
    (ele, e) => {
      setUpdateColourPickData(ele);
      setisDropGrowthAreaOpen(true);
      setTableCellId(e.target.id);
    },
    [updateColourPickData]
  );
  const updateActionData = useCallback(
    (action) => {
      setIsSetDurationAction(true);
      setActionData({});
      setUpdateAction(action);
    },
    [updateAction]
  );
  const handleClose = useCallback(() => {
    if (Object.keys(updateColourPickData).length && updateColourPickData._id) {
      setisDropGrowthAreaOpen(false);
      setUpdateColourPickData("");
    }
  }, []);
  const updateHabitData = useCallback(
    (habit) => {
      setIsSetDurationHabit(true);
      setUpdateHabit(habit);
    },
    [updateHabit]
  );

  const newTempActionData = useMemo(
    () =>
      getCalenderDataSlice?.getActionData.map((item, index) => {
        function intervals(start, end) {
          var result = [{ date: item.executionDate }];
          var current = moment.utc(start).format("HH");
          let endTime = moment.utc(end).format("HH");
          while (Number(current) <= Number(endTime)) {
            result.push({
              date: `${moment.utc(start).format("yyyy-MM-DD")}T${
                current > 9 ? current : "0" + current
              }:00:00.000Z`,
            });
            current++;
          }
          result.splice(1, 1);
          return result;
        }
        const dates = intervals(item.executionDate, item.executionDateEnd);
        dates.pop();
        dates.push({ date: item.executionDateEnd });
        console.log("dates = ", dates);
        return { ...item, dates: dates };
      }),
    [getCalenderDataSlice]
  );
  console.log("newTempActionData = ", newTempActionData);
  const tempNewAction = [];
  newTempActionData.map((action, nindex) => {
    const temp = new Set();
    getCalenderDataSlice?.getCalenderData.map((gorwhtArea, index) => {
      gorwhtArea.dates.map((dateRange) => {
        action?.dates?.map((time) => {
          if (
            moment.utc(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
              moment.utc(new Date(time.date)).format("DD-MMMM, yyyy") &&
            action.scheduled_type === "TIME_BASED"
          ) {
            if (temp.has(action._id)) {
              return {};
            }
            temp.add(action._id);
            tempNewAction.push(action);
          }
        });
      });
    });
  });

  console.log("tempNewAction = ", tempNewAction);
  const key = "_id";
  const unique = [
    ...new Map(tempNewAction.map((item) => [item[key], item])).values(),
  ];
  console.log("tempNewAction = ", unique);

  const weekTimeOfHabit = [];
  days.map((day, index) => {
    getCalenderDataSlice?.getHabitData.map((ele) => {
      if (
        `${moment(day.formattedDate).format("DD-MMMM, yyyy")}` ===
        moment.utc(new Date(ele.calenderDate)).format("DD-MMMM, yyyy")
      ) {
        return weekTimeOfHabit.push(ele);
      }
    });
  });
  console.log("weekTimeOfHabit = ", weekTimeOfHabit);
  const newTempHabitData = useMemo(
    () =>
      weekTimeOfHabit.map((item) => {
        function intervals(start, end) {
          var result = [{ date: item.executionDate }];
          var current = moment.utc(start).format("HH");
          let endTime = moment.utc(end).format("HH");
          while (Number(current) <= Number(endTime)) {
            result.push({
              date: `${moment.utc(start).format("yyyy-MM-DD")}T${
                current > 9 ? current : "0" + current
              }:00:00.000Z`,
            });
            current++;
          }
          result.splice(1, 1);
          return result;
        }
        const dates = intervals(item.executionDate, item.executionDateEnd);
        dates.pop();
        dates.push({ date: item.executionDateEnd });
        console.log("dates = ", dates);
        return { ...item, dates: dates };
      }),
    [getCalenderDataSlice]
  );
  console.log("newTempHabitData = ", newTempHabitData);

  const tempNewHabit = [];

  newTempHabitData.map((action, nindex) => {
    const temp = new Set();
    getCalenderDataSlice?.getCalenderData.map((gorwhtArea, index) => {
      gorwhtArea.dates.map((dateRange) => {
        action?.dates?.map((time) => {
          if (
            moment.utc(new Date(dateRange.date)).format("DD-MMMM, yyyy") ===
              moment.utc(new Date(time.date)).format("DD-MMMM, yyyy") &&
            action.scheduled_type === "TIME_BASED"
          ) {
            if (temp.has(action._id)) {
              return {};
            }
            temp.add(action._id);
            tempNewHabit.push(action);
          }
        });
      });
    });
  });

  console.log("tempNewHabit = ", tempNewHabit);
  // const keyHabit = "_id";
  const uniqueHabit = [
    ...new Map(tempNewHabit.map((item) => [item[key], item])).values(),
  ];
  console.log("uniqueHabit = ", uniqueHabit);
  if (getAllGrowthData.GetGrowthData) {
    getAllGrowthData.GetGrowthData.map((item) => {
      if (item.questionCategoryId === createdData.questionCategoryId) {
        disapatch(storeGetActionData(item.actions));
      }
    });
  }
  return (
    <>
      {!getCalenderDataSlice?.isGetCalenderData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              height: "2140px",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: matches && "6px 14px !important",
                    },
                  }}
                >
                  {!matches && (
                    <TableCell className={classes.blank_header}></TableCell>
                  )}
                  {days.map((item) => {
                    return (
                      <TableCell className={classes.days_header}>
                        {format(item.formattedDate, dateFormat)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody
                onDragOver={(e) => dragOver(e)}
                onDrop={(e) => drop(e, false, "New Question")}
                onClick={handleClose}
              >
                {TimeData.map((item) => {
                  return (
                    <TableRow
                      onClick={() => {
                        if (
                          Object.keys(updateAction).length &&
                          updateAction._id
                        ) {
                          setIsSetDurationAction(false);
                          setUpdateAction({});
                        } else if (
                          Object.keys(updateHabit).length &&
                          updateHabit._id
                        ) {
                          setIsSetDurationHabit(false);
                          setUpdateHabit({});
                        }
                      }}
                      style={{ position: item.time && "relative" }}
                    >
                      <TableCell
                        style={{
                          borderTop:
                            matches && !item.time
                              ? "1px solid #f3f3f3"
                              : "none",
                          borderBottom:
                            matches && !item.time
                              ? "1px solid #f3f3f3"
                              : "none",
                          fontSize: matches ? "10px" : "12px",
                          fontWeight: 400,
                          lineHeight: "110%",
                          color: "#434243",
                          padding: "0px 12px 0px 32px",
                          position: item.time && "absolute",
                          top: item.time && "-10%",
                        }}
                      >
                        {item.time}
                      </TableCell>
                      {days.map((day, index) => {
                        return (
                          <TableCell
                            key={index}
                            className={clsx(classes.box_drop)}
                            id={`${moment(day.formattedDate).format(
                              "DD-MMMM, yyyy"
                            )} ${item.time}`}
                            style={{
                              padding: "0",
                              borderRight: matches && "none",
                              height: !item.time ? "120px" : "74px",
                              // overflowY: item.time ? "scroll" : "",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                paddingTop: !item.time && "2px",
                                // width:"100%"
                              }}
                              id={`${moment(day.formattedDate).format(
                                "DD-MMMM, yyyy"
                              )} ${item.time}`}
                            >
                              <Suspense fallback={<div></div>}>
                                <WeeklyGrowthArea
                                  day={day}
                                  item={item}
                                  index={index}
                                  getCalenderDataSlice={getCalenderDataSlice}
                                  updateData={updateData}
                                  dragOver={dragOver}
                                  drop={drop}
                                  matches={matches}
                                  bgcolor={bgcolor}
                                  colorPickData={colorPickData}
                                />
                                <WeeklyActionHabit
                                  day={day}
                                  item={item}
                                  index={index}
                                  getCalenderDataSlice={getCalenderDataSlice}
                                  updateActionData={updateActionData}
                                  handleIsTrue={handleIsTrue}
                                  matches={matches}
                                  updateHabitData={updateHabitData}
                                  newTempActionData={unique}
                                  newTempHabitData={uniqueHabit}
                                  drop={drop}
                                  setCountAcH={setCountAcH}
                                  countAcH={countAcH}
                                />
                              </Suspense>
                            </div>
                          </TableCell>
                        );
                      })}
                      {matches && (
                        <div
                          className={classes.createMoreGoalWrapper}
                          onClick={() => {
                            setIsAskOtherModalOpen(true);
                          }}
                        >
                          <img
                            style={{ margin: "9px" }}
                            src={PlusLargeIcon}
                            alt=""
                          />
                        </div>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className={classes.unspecified}>
            <div className={classes.unspecified_text}>
              <Typography variant="p" style={{ fontSize: "14px" }}>
                Unspecified
              </Typography>
            </div>
            <Table>
              <TableBody>
                {TimeData.map((item) => {
                  return (
                    <TableRow
                      onClick={() => {
                        if (
                          Object.keys(updateAction).length &&
                          updateAction._id
                        ) {
                          setIsSetDurationAction(false);
                          setUpdateAction({});
                        } else if (
                          Object.keys(updateHabit).length &&
                          updateHabit._id
                        ) {
                          setIsSetDurationHabit(false);
                          setUpdateHabit({});
                        }
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TableCell
                        style={{
                          borderBottom:
                            matches && !item.time
                              ? "1px solid #f3f3f3"
                              : "none",

                          height: matches && !item.time ? "120px" : "54px",
                          padding: "0px",
                          width: "69px",
                        }}
                      >
                        {}
                      </TableCell>
                      {days.map((day, index) => {
                        let x = moment(day.formattedDate).format("D");
                        let year = moment(day.formattedDate).format("yyyy");
                        let month = moment(day.formattedDate).format("M");
                        const dateEnd = lastday(year, month);
                        return (
                          !item.time && (
                            <TableCell
                              className={clsx(
                                // classes.second_tablerow,
                                classes.box_drop_Unspecified
                              )}
                              id={`${moment(day.formattedDate).format(
                                "DD-MMMM, yyyy"
                              )} ${item.time}`}
                              style={{
                                padding: "35px 10px 10px 10px",
                                borderRight: matches && "none",
                                height: "148px",
                                borderBottom: "0px",
                              }}
                            >
                              {getCalenderDataSlice?.getActionData.map(
                                (action) => {
                                  if (
                                    action.scheduled_type === "UNSPECIFIED" &&
                                    moment
                                      .utc(new Date(action.executionDate))
                                      .format("DD-MMMM, yyyy") ===
                                      `${moment(day.formattedDate).format(
                                        "DD-MMMM, yyyy"
                                      )}`
                                  ) {
                                    return (
                                      <UnspecifiedActionHabit
                                        action={action}
                                        updateActionData={updateActionData}
                                        handleIsTrue={handleIsTrue}
                                        typeCalendar={typeCalendar}
                                      />
                                    );
                                  }
                                }
                              )}
                              {getCalenderDataSlice?.getHabitData.map(
                                (habit) => {
                                  if (
                                    habit.scheduled_type === "UNSPECIFIED" &&
                                    moment
                                      .utc(new Date(habit.calenderDate))
                                      .format("DD-MMMM, yyyy") ===
                                      `${moment(day.formattedDate).format(
                                        "DD-MMMM, yyyy"
                                      )}`
                                  ) {
                                    return (
                                      <UnspecifiedActionHabit
                                        action={habit}
                                        updateHabitData={updateHabitData}
                                        handleIsTrue={handleIsTrue}
                                        typeCalendar={typeCalendar}
                                      />
                                    );
                                  }
                                }
                              )}
                            </TableCell>
                          )
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      <DropGrowthSnakebar
        style={{ position: "unset" }}
        open={isDropGrowthAreaOpen}
        action={
          <DropModal
            updateColourPickData={updateColourPickData}
            getGrowthCalendarDate={getGrowthCalendarDate}
            setUpdateColourPickData={setUpdateColourPickData}
            colorPickData={colorPickData}
            setColorPickData={setColorPickData}
            setBgColor={setBgColor}
            bgcolor={bgcolor}
            setisDropGrowthAreaOpen={setisDropGrowthAreaOpen}
            setCustomNotificationColourPick={setCustomNotificationColourPick}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            tableCellId={tableCellId}
          />
        }
      ></DropGrowthSnakebar>
      <DropActionSnakebar
        style={{ position: "unset" }}
        open={isSetDurationAction}
        action={
          <SetDurationActionModal
            setActionData={setActionData}
            actionData={actionData}
            setIsSetDurationAction={setIsSetDurationAction}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setActionSliceData={setActionSliceData}
            actionSliceData={actionSliceData}
            updateAction={updateAction}
            setUpdateAction={setUpdateAction}
            isSetDurationAction={isSetDurationAction}
          />
        }
      ></DropActionSnakebar>
      <DropActionSnakebar
        style={{ position: "unset" }}
        open={isSetDurationHabit}
        action={
          <SetDurationHabitModal
            setHabitData={setHabitData}
            habitData={habitData}
            setIsSetDurationHabit={setIsSetDurationHabit}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setHabitSliceData={setHabitSliceData}
            habitSliceData={habitSliceData}
            updateHabit={updateHabit}
            setUpdateHabit={setUpdateHabit}
          />
        }
      ></DropActionSnakebar>
      {customNotification && (
        <NotificationContentJSX
          message="Maximum limit reached! You can't add new growth area for this time frame."
          isThumbIcon={false}
          position="top"
        />
      )}
      {customNotificationColourPick && (
        <NotificationContentColourPickJSX
          message="can't pick same color for different diamension"
          isThumbIcon={false}
          position="top"
        />
      )}
      {customNotificationActionHabit && (
        <NotificationContentActionHabitJSX
          message="can't drop it without growth area"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
};
export default React.memo(WeeklyCalendar);
