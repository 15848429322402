import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Thumbup from "../assets/Svg/Thumbup.svg";

const NotificationWrapper = styled("div")(({ theme }) => ({
    position: 'absolute',
    right: '40px',
    // bottom: '40px',
    width: '340px',
    padding: '16px',
    background: '#9747ff',
    borderRadius: '12px',
    zIndex:"2",
    [theme.breakpoints.down("xl")]: {

    },
    [theme.breakpoints.down("lg")]: {

    },
    [theme.breakpoints.up("md")]: {

    },
    [theme.breakpoints.down("md")]: {

    },
    [theme.breakpoints.down("sm")]: {

    },
    [theme.breakpoints.down("sm")]: {

    },
}));

const NotificationContent = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down("xl")]: {

    },
    [theme.breakpoints.down("lg")]: {

    },
    [theme.breakpoints.up("md")]: {

    },
    [theme.breakpoints.down("md")]: {

    },
    [theme.breakpoints.down("sm")]: {

    },
    [theme.breakpoints.down("sm")]: {

    },
}));

/* // if you want to use this notification so u can mack root elem position relatives */

const useCustomNotification = () => {
    const [customNotification, setCustomNotification] =
        useState(false);
    useEffect(() => {
        if (customNotification === true) {
            setTimeout(() => {
                setCustomNotification(false);
            }, 3000);
        }
    }, [customNotification]);

    const NotificationContentJSX = ({ message, isThumbIcon = true, position = "bottom" }) => {
        return (<NotificationWrapper style={{ top: position === "top" ? "40px" : "unset", bottom: position === "top" ? "unset" : "40px" }} >
            <NotificationContent >
                {isThumbIcon && <img src={Thumbup} alt="" sx={{ mr: "8px" }} />}
                <div style={{ width: "280px" }}>
                    <Typography variant="body2" color="#FFFFFF">
                        {message}
                    </Typography>
                </div>
            </NotificationContent>
        </NotificationWrapper>)
    }

    return { NotificationContentJSX, customNotification, setCustomNotification }
}

export default useCustomNotification