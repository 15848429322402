import { Chip } from "@mui/material"
import classes from "./ChipButton.module.css";
 const ChipSaveButton = (props) => {
    const { onSave, title = 'Save', style = {} } = props
    return (
        <Chip
            label={title}
            onClick={onSave}
            style={style}
            className={classes.backgroundChip}
        />
    )
}

export default ChipSaveButton