import React, { useRef, useState } from "react";
import { Grid, MenuItem, OutlinedInput, Typography } from "@mui/material";
// import classes from "./featureRequest.module.css";
import {
  Input,
  ItemList,
  Selecter,
} from "../../../components/Settings/StyledElement/StyledformInput";
import { SubmitButton } from "../../../components/Button/Button";
import fileIcon from "../../../assets/Svg/fileIcon.svg";
import classes from "./reportBug.module.css";
import DragDropFile from "./DragDrop";
import { MultiLineInput } from "../../TestResultsPages/StyledStepper/styledStepper";
import { reportBugData } from "../../../services/auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { facebookSection } from "../../../Constant/SettingConstant";
import clsx from "clsx";
import useCustomNotification from "../../../Hooks/useCustomNotification";

function ReportBug() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [emailError, setEmailError] = useState("");

  let handleFile = useRef(null);
  const [imageURL, setImageURL] = useState([]);
  const [bugReport, setBugReport] = useState({
    bugType: "",
    user_email: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const handleSubmitData = () => {
    // console.log("Report bug",bugReport);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = regex.test(bugReport.user_email);
    console.log("isValidEmail==>", isValidEmail);
    setEmailError(isValidEmail);

    if (isValidEmail) {
      let data = {
        ...bugReport,
        uid: localStorage.getItem("userDetail"),
        photo: imageURL[0].name,
      };
      setIsLoading(true);
      setCustomNotification(true);
      reportBugData(data)
        .then((res) => {
          console.log("res = ", res);
          setBugReport({
            bugType: "",
            user_email: "",
            description: "",
          });
        })
        .catch((err) => {
          console.log("err = ", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleInput = (e) => {
    if (e.target.name === "user_email") {
      setEmailError("");
    }
    setBugReport({ ...bugReport, [e.target.name]: e.target.value });
  };

  const handleChangeFile = (event) => {
    console.log(event.target.files);
    setImageURL([...imageURL, ...event.target.files]);
  };
  console.log("fileUpload = ", imageURL);

  // const handleDragOver = (e) =>{
  //   e.preventDefault();
  // }

  // const handleDrop = (e)=>{
  //    e.preventDefault();
  //    setFileUpload(e.dataTransfer.files)

  // }
  return (
    <>
      <Grid>
        <Grid className={clsx(!matches && classes.mob_set_grid)} item sm={12}>
          <ItemList
            value={bugReport.bugType}
            input={<OutlinedInput />}
            name="bugType"
            placeholder="Select bug flow"
            select
            disableUnderline={false}
            variant="outlined"
            onChange={handleInput}
          >
            {facebookSection.map((item, index) => {
              return (
                <MenuItem
                  className={classes.item_list}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </ItemList>
        </Grid>

        <Grid container sx={{ marginBottom: "12px" }}>
          <Grid
            className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
          >
            <MultiLineInput
              sx={{ maxWidth: "100% !important" }}
              value={bugReport.description}
              onChange={handleInput}
              name={"description"}
              multiline
              size="small"
              fullWidth
              key="pogress"
              type="text"
              placeholder="Discribe feature functionality"
            />
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <Input
            name="user_email"
            placeholder="Email address"
            value={bugReport.user_email}
            onChange={handleInput}
            type="email"
            emailError={emailError}
          />
          <span className={classes.email_error}>
            {emailError === false && "Please add valid email"}
          </span>
        </Grid>

        <Grid
          item
          sm={12}
          className={clsx(matches ? classes.mob_set_grid : classes.set_grid)}
        >
          <Grid>
            <Typography className={classes.file_heading}>
              Upload file
            </Typography>
            <Typography className={classes.file_type}>
              Use JPG, PNG or MP3, MP4 only
            </Typography>
          </Grid>

          <Grid
            className={classes.file_body}
            // onDragOver={handleDragOver}
            // onDrop={handleDrop}
          >
            <label htmlFor="myfile" className={classes.inbody}>
              Drag & Drop your files hear or <span> choose file</span>
            </label>

            <input
              className={classes.input_file}
              // role="img"
              id="myfile"
              type="file"
              // accept=".jpg"
              onChange={(event) => {
                handleChangeFile(event);
              }}
              ref={handleFile}
              // onChange={handleInput}
              // multiple
            />

            <Typography className={classes.inbody_max}>
              100 MB max file size
            </Typography>
          </Grid>
          {/* <DragDropFile /> */}
        </Grid>

        {imageURL.length > 0 && (
          <Grid>
            <Typography className={classes.file_Uploaded}>
              Uploaded files
            </Typography>
            <Grid xl={12} className={classes.file_div}>
              {imageURL?.map((item, index) => {
                return (
                  <>
                    <Grid xl={12} key={index} className={classes.upload_file}>
                      <img
                        className={classes.files}
                        // className={classes.uploadedImage}
                        alt=""
                        src={imageURL ? fileIcon : URL.createObjectURL(item)}
                      />
                      <Grid xl={12}>
                        <Typography className={classes.file_name}>
                          {item.name} <br />
                        </Typography>
                        <Typography className={classes.file_size}>
                          PDF, {item.size} bytes
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        )}

        <Grid className={classes.set_grid}>
          <SubmitButton
            isDisabled={
              bugReport.bugType === "" ||
              bugReport.description === "" ||
              bugReport.user_email === ""
              // imageURL.length  > 0
            }
            buttonText={"Send"}
            onClick={handleSubmitData}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      {customNotification && (
        <NotificationContentJSX
          message="Bug added successfully"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
}

export default ReportBug;
