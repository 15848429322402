import React from 'react'
import PropTypes from 'prop-types'
import style from './../styles.module.css'

const ProgressWrapper = (props) => (
  <div className={style.autoHide} style={{ ...styles.progress, ...getProgressWrapperStyle(props) }}>
    {props.children}
  </div>
)

const getProgressWrapperStyle = ({ width, pause, bufferAction }) => ({
  width: `${width * 100}%`,
  opacity: pause && !bufferAction ? 0 : 1
})

const styles = {
  progress: {
    height: 8,
    maxWidth: '100%',
    background: 'rgba(255, 255, 255, 0.4)',
    margin: 2,
    borderRadius: '100px'
  }
}

ProgressWrapper.propTypes = {
  children: PropTypes.node
}

export default ProgressWrapper