import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedinviteData: [],
  selectedIndividualInviteID: "",
  isSelectedSocialUser: false,
};
export const selectedInviteDataSlice = createSlice({
  name: "selectedInviteDataSlice",
  initialState,
  reducers: {
    updateSelectedIndividualInvitId: (state, action) => {
      state.selectedIndividualInviteID = action.payload;
    },
    updateSelectedInviteData: (state, action) => {
      state.selectedinviteData = action.payload;
    },
    updateIsSelectedUser: (state, action) => {
      state.isSelectedSocialUser = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {updateSelectedIndividualInvitId, updateSelectedInviteData, updateIsSelectedUser } =
  selectedInviteDataSlice.actions;
export default selectedInviteDataSlice.reducer;
