import { Button, Dialog, Grid, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";

import typography from "../../theme/typography";
import classes from "./TestEndPage.module.css";
import Confetti from "react-confetti";
import AskOther from "../../core-ui/AskOther/AskOther";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserData } from "../../services/auth";
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";

const TestEndPage = () => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const confettiRef = useRef(null);
  const navigate = useNavigate()
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };
  const handleClickOpen = () => {
    setIsAskOtherModalOpen(true);
  };
  const dispatch = useDispatch()
  const { state } = useLocation();
  const { selectedSubCat } = useSelector((state) => state.testCategories);
  const { dimensions, subCategoryid } = useSelector(state => state.dimensions)
  const selectedSubCategories = state?.selectedSubCategories
  console.log("state", dimensions, subCategoryid, state, selectedSubCategories, selectedSubCat)
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
    const user = localStorage.getItem("userDetail");
    if (user) {
      getUserData(user)
        .then((response) => {
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, []); //we need dependencies here for check in responsive mode in inspact but we don't need because it's automatically detect width here when in production mode

  const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
        position: unset;
      }
    }
  `;
  return (
    <>
      <div className={classes.mainWrapper} ref={confettiRef}>
        <Confetti width={width} height={height} />
        <div className={classes.testEndPageWrapper}>
          <div className={classes.heading}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              {`Congrats on completing ${state.subCategory} test`}
            </Typography>
          </div>
          <div className={classes.earnedMsg}>
            <Typography
              variant="subtitle1"
              color="#434243"
              sx={{ textAlign: "center" }}
            >
              You’ve earned
            </Typography>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>
            <div className={classes.coinIconWrapper}>
              <img src={Coin} alt="" />
              <Typography variant="body3" color="#9747FF">
                {selectedSubCat?.coins}
              </Typography>
            </div>
            <div className={classes.coinIconWrapper}>
              <img src={DiamondCoin} alt="" />
              <Typography variant="body3" color="#9747FF">
                {selectedSubCat?.points}
              </Typography>
            </div>
          </div>

          <div className={classes.bottomContainerWrapper}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.buttonWrapper}
              rowSpacing="12px"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  className={classes.previousButton}
                  sx={{ ...typography.subtitle1, marginRight: "12px" }}
                  variant="outlined"
                  onClick={handleClickOpen}
                // disabled
                >
                  Ask others
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
              >
                <Button
                  className={classes.nextButton}
                  sx={{ ...typography.subtitle1 }}
                  variant="outlined"
                  onClick={() => { navigate("/results") }}
                >
                  Go to results
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <CustomDialog
          open={isAskOtherModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AskOther onClose={handleClose} dimensions={dimensions} />
        </CustomDialog>
      </div>
    </>
  );
};

export default TestEndPage;
