import { Box } from "@mui/system";
import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import classes from "./Header.module.css";
import Premium from "../../assets/Svg/Premium.svg";
import MenubarIcon from "../../assets/Svg/MenubarIcon.svg";
import Notification from "../../assets/Svg/Notification.svg";
import Profile from "../../assets/Svg/Profile.svg";
import { Typography, Grid, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Coin from "../../assets/Svg/Coin.svg";
import XP from "../../assets/Svg/XP.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import SideBar from '../SideBar/SideBar';
import { imageURLRender, userNamerender } from "../../utils";


const GcHeader = ({ showSidebar, setShowSidebar }) => {
  const { userData } = useSelector((state) => state.userProfile);
  const navigate = useNavigate();

  const NavigationLink = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));
  const HeaderWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "64px",
    /* background: yellow; */
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  }));
  return (
    <>
      <Grid component={"div"} className={`${classes.sideBar} ${showSidebar === true ? classes.sideBarOpen : ""}`}>
        <SideBar isGrowthSideBar={showSidebar}/>
      </Grid>
      <HeaderWrapper
        className={`${showSidebar === true ? classes.showMenuOpen : ""}`}
      >
        <NavigationLink>
          <div
            className={classes.show_menu}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <img src={MenubarIcon} alt="Menubar" />
            <Typography
              variant="p"
              color="black"
              className={classes.show_menu_text}
            >
              Show menu
            </Typography>
          </div>
        </NavigationLink>
        <Box className={classes.profileInfoWrapper}>
          <div className={classes.coinIconWrapper}>
            <div className={classes.goldCoinIconWrapper}>
              <img src={XP} alt="" />
              <Typography variant="body3" color="#190D1A">
                {userData?.xp || 0}
              </Typography>
            </div>
            <div className={classes.goldCoinIconWrapper}>
              <img src={Coin} alt="" />
              <Typography variant="body3" color="#190D1A">
                {userData?.wallet?.coins || 0}
              </Typography>
            </div>
            <div className={`${classes.goldCoinIconWrapper} ${classes.ml4}`}>
              <img src={DiamondCoin} alt="" />
              <Typography variant="body3" color="#190D1A">
                {userData?.wallet?.diamonds || 0}
              </Typography>
            </div>
          </div>

          <div className={classes.notificationButton}>
            <img src={Notification} alt="notificatin" />
          </div>
          {/* <div className={classes.premiumButton}>
            <img src={Premium} alt="premium" />
            <Typography variant="body3" color="#FFFFFF">
              Premium
            </Typography>
          </div> */}
          <div onClick={() => navigate('/settings')}>
            {userData?.imageUrl && userData?.imageUrl !== "Not updated" ? <img
              src={
                userData?.imageUrl === "Not updated"
                  ? Profile
                  : imageURLRender(userData?.imageUrl)
              }
              alt="profile"
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            /> : <Avatar sx={{width: "40px", height: "40px", background: '#9847ff'}}>{userNamerender(userData?.name)}</Avatar>}
          </div>
        </Box>
      </HeaderWrapper>
    </>
  );
};

export default GcHeader;
