import React, { useEffect, useState } from "react";
import classes from "./GrowthSidebar.module.css";
import { CircularProgress, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import GrowthInnerAccordion from "./GrowthAccordion";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // width: "336px",
  height: "100vh",
  background: "#F7F1EC",
  overflowY: 'auto',
  borderRadius: "20px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const extraRight = () => {
  return
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
}));

const GrowthSideBar = ({
  setfullscreen,
  fullscreen,
  setSelectedGrowthArr,
  selectedGrowthArr,
  bgcolor,
  setBgColor,
  matches,
  growthData
}) => {
  const [expanded, setExpanded] = useState(false);
  const { getAllGrowthData, getCalenderDataSlice } = useSelector(
    (state) => state
  );
  const [preselectedState, setPreselectedState] = useState([]);
  const disapatch = useDispatch();
  return (
    <>
      <Accordion expanded={true} >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Grid container className={classes.growthAccordianHeader}>
            <Typography component="div" className={classes.growthLeft}>Featured Goals</Typography>
            <Typography component="div" className={classes.growthRight}>{getAllGrowthData.GetGrowthData.length}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <GrowthInnerAccordion />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GrowthSideBar;
