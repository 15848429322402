import { Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { padding } from "@mui/system";
import React, { useState } from "react";
import { FEEDBACK_TYPE } from "../../../Constant/SettingConstant";
import { ProfileInfoButtonWrapper } from "../StyledElement/StyledDiv";
import classes from './feedback.module.css'
import LeftArrowIcom from "../../../assets/Svg/LeftArrowIcon.svg";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function FeedbackHeader({ setFeedbackRequest }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const [selectedImage, setSelectedImage] = useState(null);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const handleChange = (event,newValue) => {
    // setValue(newValue);
    if (newValue === 0) {
      setFeedbackRequest(FEEDBACK_TYPE.REQUEST_FEEDBACK);
    } else if (newValue === 1) {
      setFeedbackRequest(FEEDBACK_TYPE.REPORT_BUG);
    } else {
      setFeedbackRequest(FEEDBACK_TYPE.GIVE_FEEDBACK);
    }
    setValue(newValue);
  };

  const backToSideNavigate = () => {
    navigate("/settings/settingnavigationbar");
  };

  return (
    <>
      {matches ? (
        <Typography variant="h5">
          <img
            onClick={backToSideNavigate}
            className={classes.backbutton}
            alt="..."
            src={
              selectedImage ? URL.createObjectURL(selectedImage) : LeftArrowIcom
            }
          />
          Feedback
        </Typography>
      ) : (
        <Typography variant="h4">Feedback</Typography>
      )}

      <ProfileInfoButtonWrapper>
        <Tabs
          sx={{
            "& .MuiTabs-scroller": {
              overflow: "auto !important",
            },
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            sx={{
              fontSize: smallDeviceMatches
                ? "14px !important"
                : "16px !important",
            }}
            label="Request a feature"
          />
          <Tab
            sx={{
              fontSize: smallDeviceMatches
                ? "14px !important"
                : "16px !important",
            }}
            label="Report a bug"
          />
          <Tab
            sx={{
              fontSize: smallDeviceMatches
                ? "14px !important"
                : "16px !important",
            }}
            label="Give feedback"
          />
        </Tabs>
      </ProfileInfoButtonWrapper>
    </>
  );
}

export default FeedbackHeader;
