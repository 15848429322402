import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import classes from "./StepFive.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import { find } from "lodash";
import { imageURLRender, XPLevels } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
function StepFive({
  onNext,
  onPrevious,
  getGoalDetail,
  goalDetail,
  selectedDiamenstion,
  selectedDiamenstionImage,
  handleClose,
}) {
  useEffect(() => {
    nextDataHandler();
  }, []);

  const [actionState, setActionState] = useState([
    {
      actionName: "",
      actionDetail: "",
      level: ""
    },
  ]);
  console.log("actionState", actionState);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...actionState];
    tempList[index][name] = value;
    setActionState(tempList);
  };
  const handleChangeAction = (value, index) => {
    const tempList = [...actionState];
    tempList[index]["level"] = value;
    setActionState(tempList);
  }
  const addMoreHandler = () => {
    if (
      actionState[actionState.length - 1].actionName !== "" &&
      actionState[actionState.length - 1].actionDetail !== ""
    ) {
      setActionState([
        ...actionState,
        {
          actionName: "",
          actionDetail: "",
        },
      ]);
    }
  };

  const nextDataHandler = () => {
    if (goalDetail?.action) {
      setActionState(goalDetail.action);
    }
  };
  const updateMainState = () => {
    getGoalDetail("action", actionState);
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={onNext}>
        <span style={{ color: "#9747FF" }}>{"Skip Action"}</span>{" "}
      </Typography>} />
      <div className={classes.StepFiveWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.StepFiveSubWrapper}>

          <div><img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" /></div>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            You have selected{" "}
            <span style={{ color: "#9747FF" }}>{selectedDiamenstion}</span>{" "}
          </Typography>
          <Typography
            variant="body4"
            sx={{ margin: "16px 0px", textAlign: "center" }}
          >
            Add an action
          </Typography>
          {actionState?.map((item, i) => {
            return (
              <div
                style={{ width: "100%", marginTop: i === 0 ? "10px" : "28px" }}
                key={i}
              >
                <Typography variant="subtitle1">Give it a name</Typography>
                <TextField
                  size="small"
                  className={classes.goalActionInput}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #f3f3f3",
                      borderRadius: "16px",
                    },
                    " & .MuiOutlinedInput-input": {
                      color: " #190d1a",
                      padding: "16px 10px",
                    },
                    " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #9747FF" },
                  }}
                  InputProps={{
                    sx: {
                      "&:hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #e5d5fc",
                        },
                      },
                    },
                  }}
                  fullWidth
                  name="actionName"
                  value={item.actionName}
                  onChange={(e) => handleInputChange(e, i)}
                  type="text"
                  placeholder="Text"
                  id="outlined-start-adornment"
                />
                <Typography variant="subtitle1">
                  Tell us why this action
                </Typography>

                <TextField
                  size="small"
                  className={classes.goalActionInput}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #f3f3f3",
                      borderRadius: "16px",
                    },
                    " & .MuiOutlinedInput-input": {
                      color: " #190d1a",
                      padding: "16px 10px",
                    },
                    " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #9747FF" },
                  }}
                  InputProps={{
                    sx: {
                      "&:hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #e5d5fc",
                        },
                      },
                    },
                  }}
                  fullWidth
                  name="actionDetail"
                  value={item.actionDetail}
                  onChange={(e) => handleInputChange(e, i)}
                  type="text"
                  placeholder="Text"
                  id="outlined-start-adornment"
                />
                <Typography variant="subtitle1">
                  Select Difficulty
                </Typography>
                <div className={classes.customActionLevelGroup}>
                  <ToggleButtonGroup
                    color="primary"
                    value={item.level}
                    className={classes.customActionLevelGroup}
                    exclusive
                    onChange={(e, val) => {
                      handleChangeAction(val, i)
                    }}
                    size="small"
                    aria-label="Platform"
                  >
                    {XPLevels.map(it => (
                      <ToggleButton value={it.level} className={item.level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {it.icon} <span className="toggle-text">{it.level}</span></ToggleButton>

                    ))}
                  </ToggleButtonGroup>
                </div>
              </div>
            );
          })}
          <div style={{ width: "100%", marginBottom: "12px" }}>
            <CustomShedualButton
              style={{ maxWidth: "91px" }}
              onClick={() => {
                addMoreHandler();
              }}
            >
              Add more +
            </CustomShedualButton>
          </div>

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: "0 40px" }}>
          <ButtonGroup
            onNext={onNext}
            onPrevious={onPrevious}
            updateMainState={updateMainState}
            isNextDisable={
              actionState[actionState.length - 1].actionName === "" ||
              actionState[actionState.length - 1].actionDetail === "" ||
              !actionState[actionState.length - 1].level
            }
          />
        </div>
      </div>
    </>

  );
}
export default StepFive;
