
import Header from "../../components/Header/Header";

import {
  RightSideContentWrapper,
} from "../../components/GoalComponent/GoalCustomDiv";
import GoalData from "./GoalData"
import AddNewgoalModal from "./AddGoalModal";
import { ConfirmationClose } from "./AddGoalModal";
function GoalList() {
  return (
    <RightSideContentWrapper>
      <Header />
      <GoalData />
    </RightSideContentWrapper>
  );
}
export default GoalList;
