import styled from "@emotion/styled";
import { Slider } from "@mui/material";
// import React from "react";
export const TestQuestionSlider = styled(Slider)`
  // background: yellow;
  padding: 0px !important;
  background: linear-gradient(
    90deg,
    #d7e0fb 0%,
    #dcfaf0 20.28%,
    #e6f8de 46.45%,
    #fff8bd 69.19%,
    #fce9a5 100%
  );
  & .MuiSlider-rail {
    background: linear-gradient(
      90deg,
      #d7e0fb 0%,
      #dcfaf0 20.28%,
      #e6f8de 46.45%,
      #fff8bd 69.19%,
      #fce9a5 100%
    );
    height: 12px;
  }

  & .MuiSlider-track {
    background: linear-gradient(
      90deg,
      #d7e0fb 0%,
      #dcfaf0 20.28%,
      #e6f8de 46.45%,
      #fff8bd 69.19%,
      #fce9a5 100%
    );
    height: 12px;
    border: none;
  }
  .MuiSlider-thumb {
    box-shadow: none !important;
    width: 14px;
    height: 14px;
    color: #e5d5fc;
  }
  & .MuiSlider-mark {
    display: none !important;
  }

  & .MuiSlider-markActive {
    // background: none;
    & :nth-child(1) {
      background: ;
    }
  }
  // & .MuiSlider-thumbColorPrimary {
  //   box-shadow: none;
  // }
  // & .css-1ggq226-MuiSlider-thumb {
  //   width: 14px;
  //   height: 14px;
  //   color: #e5d5fc;
  // }
`;
// function PesonalityResultSlider({sx}) {
//   const TestQuestionSlider = styled(Slider)`
//     // background: yellow;
//     padding: 0px !important;
//     & .MuiSlider-rail {
//       background: linear-gradient(
//         90deg,
//         #d7e0fb 0%,
//         #dcfaf0 20.28%,
//         #e6f8de 46.45%,
//         #fff8bd 69.19%,
//         #fce9a5 100%
//       );
//       height: 12px;
//     }

//     & .MuiSlider-track {
//       background: linear-gradient(
//         90deg,
//         #d7e0fb 0%,
//         #dcfaf0 20.28%,
//         #e6f8de 46.45%,
//         #fff8bd 69.19%,
//         #fce9a5 100%
//       );
//       height: 12px;
//       border: none;
//     }

//     & .MuiSlider-mark {
//       // left: 0% !important;
//       // width: unset;
//       // height: unset;
//       // background: none;
//       // border-radius: 50%;
//       // background: green;

//       //   background: none;
//       width: 4px;
//       height: 12px;
//       left: 110px;
//       top: 0px;
//       background: #ffffff;
//     }

//     & .MuiSlider-markActive {
//       // left: 0% !important;
//       background: none;
//       // background-image: url("../../assets/Svg/SliderMark.svg");
//       & :nth-child(1) {
//         background: ;
//       }
//     }

//     & .MuiSlider-thumbColorPrimary {
//       box-shadow: none;
//     }
//     & .css-1ggq226-MuiSlider-thumb {
//       width: 14px;
//       height: 14px;
//       color: #e5d5fc;
//     }
//   `;
//   return (
//     <>
//       <TestQuestionSlider
//         defaultValue={30}
//         valueLabelDisplay="off"
//         step={50}
//         marks
//         min={0}
//         max={150}
//         // value={PersonalityResult.percentage}
//         onChange={(e) => {
//         }}
//       />
//     </>
//   );
// }
// export default PesonalityResultSlider;
