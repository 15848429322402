import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import classes from "./TestPage.module.css";
import SliderMarkIcon from "../../assets/Svg/SliderMark.svg";
import Thumbup from "../../assets/Svg/Thumbup.svg";
import Thumbdown from "../../assets/Svg/Thumbdown.svg";
import Success from "../../assets/Svg/Success-20px.svg";
import typography from "../../theme/typography";
import Slider, {
  SliderThumb,
  SliderRail,
  SliderMark,
} from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuestionsList, storeAnswer } from "../../services/auth";
import {
  storeAnswerId,
  storeAnswerValue,
  storeQuestionList,
} from "../../Store/Reducers/questionsListSlice";
const pointLength = 4
const TestPage = () => {
  const { questionsList } = useSelector((state) => state.questionsList);
  const [subCategory, setSubcategory] = useState();

  const nextButtonRef = useRef();
  const [arrayOfArraysRange, setarrayOfArraysRange] = useState([])
  // console.log("questionsList",questionsList)
  const [isLoading, setIsLoading] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [middleValue, setMiddleValue] = useState(0);
  const [noQuestionFound, setNoQuestionFound] = useState(false);

  // console.log("ddhdhdh",answer,questionsList[questionIndex].ansValue )

  const answerIdRef = useRef("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const uid = localStorage.getItem("userDetail");
    // if (uid) {
    getQuestionsList({ uid, subCategory_id: state.testId })
      .then((questions, i) => {
        // identify if user give some ans
        for (let i = 0; i < questions?.response?.length; i++) {
          if (questions?.response[i]?.answerId?.length === 0) {
            setQuestionIndex(i);
            break;
          } else {
          }
        }
        const arr = Array.from({ length: questions.response.length - 1 + 1 }, (_, i) => i + 1);
        var arrayOfArrays = [];
        for (let i = 0; i < arr.length; i += Math.ceil(questions.response.length / pointLength)) {
          arrayOfArrays.push(arr.slice(i, i + Math.ceil(questions.response.length / pointLength)));
        }
        setarrayOfArraysRange(arrayOfArrays)
        console.log("arrayOfArrays", arrayOfArrays);

        // console.log("question", questions);
        // can we need state for store this question array ?
        if (questions?.response?.length === 0) {
          // if question array are empty
          console.log("no question Found");
          // navigate("/testcategory");
          setNoQuestionFound(true);
        } else {
          dispatch(storeQuestionList(questions.response));
          dispatch(storeAnswerValue());
        }
      })
      .catch((error) => {
        console.log("57 error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // } else {
    //   console.log("user not logedin");
    //   navigate("/")
    // setIsLoading(false);
    // }
  }, []);

  useEffect(() => {
    if (questionsList[questionIndex]?.ansValue > -1) {
      setAnswer(questionsList[questionIndex]?.ansValue);
    }
  }, [questionIndex, questionsList]);

  useEffect(() => {
    nextButtonRef.current?.focus();
  });
  // useEffect(() => {
  // ===0 && answer === 0 || questionsList[questionIndex]?.ansValue===0 && answer > 0 ? middleValue : questionsList[questionIndex]?.ansValue
  //   const tempMiddlevalue =
  //     questionsList[questionIndex]?.questionData?.options?.length *
  //       questionsList[questionIndex]?.questionData?.options?.length -
  //     questionsList[questionIndex]?.questionData?.options?.length;
  //   console.log("tempMiddlevalue==>", tempMiddlevalue);
  //   if (tempMiddlevalue) {
  //     setMiddleValue(tempMiddlevalue / 2);
  //   }
  //   console.log("answer==>", middleValue);
  // }, [middleValue, questionsList]);

  const TestQuestionSlider = styled(Slider)`
    // background: yellow;
    padding: 0px !important;
    & .MuiSlider-rail {
      background: #e5d5fc;
      height: 12px;
    }

    & .MuiSlider-track {
      background: #9747ff;
      height: 12px;
    }

    & .MuiSlider-mark {
      // left: 0% !important;

      // width: unset;
      // height: unset;
      // background: none;

      // border-radius: 50%;

      // background: #9747FF;

      background: #e5d5fc;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #fff
    }

    & .MuiSlider-markActive {
      // left: 0% !important;
      background: none;
      // background-image: url("../../assets/Svg/SliderMark.svg");
      & :nth-child(1) {
        background: #e5d5fc;
      }
    }

    & .MuiSlider-thumbColorPrimary {
      box-shadow: none;
    }
  `;

  const TestAnswerSlider = styled(Slider)`
    // background: yellow;
    width: 98.2%;
    padding: 0px;
    & .MuiSlider-rail {
      background: #e5d5fc;
      height: 12px;
    }

    & .MuiSlider-thumbSizeMedium {
      margin-left: 5px;
    }
    & .MuiSlider-rail {
      border-color: #f7f2fe;
      width: 102%;
    }

    & .MuiSlider-track {
      height: 12px;
      background: #f7f2fe;
      border-color: #f7f2fe;
    }

    & .MuiSlider-mark {
      // display: none;
      background: #9747ff ;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    & .MuiSlider-markActive {
      // display: none;
      background: #9747ff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    & .MuiSlider-valueLabelOpen {
      background: #f7f2fe;
      color: #9747ff;
    }

    & .MuiSlider-thumbColorPrimary {
      box-shadow: none;
    }
  `;

  const MiddleContainerWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    flexDirection: "column",
    width: "457px",
    minHeight: "216px",

    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  function SilderMarkComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderMark {...other}>
        {children}
        <img src={SliderMarkIcon} alt="" />
      </SliderMark>
    );
  }
  // console.log(questionIndex, questionsList.length - 1);

  const nextClickHandler = () => {
    // console.log(answerIdRef.current);
    // console.log("questionid",questionsList[questionIndex].questionData.questionId)
    setIsLoading(true);

    if (questionIndex <= questionsList.length - 1) {
      const questionId = questionsList[questionIndex]?.questionData?.questionId;
      const uid = localStorage.getItem("userDetail");
      const answerId = answerIdRef.current;

      // if(uid){
      storeAnswer({ questionId, uid, answerId })
        .then((data) => {
          if (data) {
            // console.log("data", data);
            dispatch(storeAnswerId({ answerId, questionId }));
            dispatch(storeAnswerValue());
            if (questionIndex !== questionsList.length - 1) {
              setQuestionIndex((prev) => prev + 1);
            } else {
              // console.log("test is over");
              navigate("/startTest/testend", {
                state: { subCategory: state.subCategory },
              });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }else{
      //   console.log(160,"user not logedin")
      //   navigate("/")
      //   setIsLoading(false)
      // }
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const tempMiddlevalue =
      questionsList[questionIndex]?.questionData?.options?.length *
      questionsList[questionIndex]?.questionData?.options?.length -
      questionsList[questionIndex]?.questionData?.options?.length;
    // console.log("tempMiddlevalue==>", tempMiddlevalue);
    if (tempMiddlevalue) {
      setMiddleValue(tempMiddlevalue / 2);
    }
  }, [middleValue, questionsList]);
  // console.log("answer = ", answer);
  const prevClickHandler = () => {
    setQuestionIndex((prev) => prev - 1);
  };
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  }

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : noQuestionFound ? (
        <div>No Question Found</div>
      ) : (
        <>
          <div className={classes.topContainerWrapper}>
            <div style={{
              position: 'relative',
              height: '12px',
              display: 'flex',
              background: '#F7F2FE',
              borderRadius: '100px',
              width: '100%'
            }}>
              {arrayOfArraysRange && arrayOfArraysRange.length && arrayOfArraysRange.map((it, index) => {
                const minVal = it[0]
                const maxVal = it[it.length - 1]
                console.log("it", minVal, maxVal, questionsList[questionIndex]?.questionNumber, maxVal < questionsList[questionIndex]?.questionNumber, it.includes(questionsList[questionIndex]?.questionNumber));
                let width = 0;
                if(maxVal < questionsList[questionIndex]?.questionNumber) {
                  width = 100
                }
                if(it.includes(questionsList[questionIndex]?.questionNumber)) {
                  console.log("questionsList[questionIndex]?.questionNumber - minVal", questionsList[questionIndex]?.questionNumber - minVal);
                  width = ((questionsList[questionIndex]?.questionNumber - minVal) * 100 )/ (maxVal - minVal)
                }
                return <div style={{
                  position: 'relative',
                  width: `100%`,
                  display: 'flex',
                  justifyContent: !index ? 'space-between' : 'flex-start',
                  alignItems: 'center'
                  // background: 'red',
                  // zIndex: 10,
                  // left: `${((index / (arrayOfArraysRange.length - 1)) * 100) -1}%`,
                }}>
                  {index ? null : <div className={classes.iconOfProgress} style={{
                   position: 'absolute',
                   left: '-2px',
                   alignSelf: 'flex-end',
                   justifySelf: 'flex-end',
                   top: '-4px',
                    background: (minVal < questionsList[questionIndex]?.questionNumber || it.includes(questionsList[questionIndex]?.questionNumber)) ? '#9747ff' : '#E5D5FC'
                  }}>{(minVal < questionsList[questionIndex]?.questionNumber || it.includes(questionsList[questionIndex]?.questionNumber)) ? <img src={Success} alt='success'></img> : ''}</div>}
                  <div className={classes.iconOfProgress} style={{
                    position: 'absolute',
                    right: '-2px',
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                    top: '-4px',
                    // left: `${((index / (arrayOfArraysRange.length - 1)) * 100) -1}%`,
                    // left: `0%`,
                    background: width === 100 ? '#9747ff' : '#E5D5FC'
                  }}>{width === 100 ? <img src={Success} alt='success'></img> : ''}</div>
                  <div style={{
                    position: 'absolute',
                    height: '13px',
                    // left: `${((index / (arrayOfArraysRange.length - 1)) * 100) -1}%`,
                    background: '#9747FF',
                    width: `${width}%`,
                    borderRadius: `0 100px 100px 0`,
                    zIndex: 3
                  }} />
                </div>
              })}

              {/* {arrayOfArraysRange && arrayOfArraysRange.length && arrayOfArraysRange.map(it => {
                return <TestQuestionSlider
                  defaultValue={0}
                  // getAriaValueText={valuetext}
                  slots={{ thumb: AirbnbThumbComponent }}

                  valueLabelDisplay="off"
                  step={questionsList.length}
                  marks
                  min={1}
                  max={
                    it[it.length - 1]
                  }
                  value={questionIndex * questionsList.length}
                  components={{ Input: SilderMarkComponent }}
                  onChange={(e) => {
                    // console.log("value", e.target.value);
                  }}
                />
              })} */}

            </div>


            <Typography
              variant="subtitle1"
              color="#434243"
              sx={{ marginTop: "1rem" }}
            >
              Question{" "}
              <span className={classes.questionCount}>
                {questionsList[questionIndex]?.questionNumber}
              </span>{" "}
              out of{" "}
              <span className={classes.questionCount}>
                {questionsList[questionIndex]?.totalQuestion}
              </span>
            </Typography>
          </div>
          <MiddleContainerWrapper>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: "75px" }}
            >
              {questionsList[questionIndex]?.questionData?.questionText}
            </Typography>
            <div className={classes.answerWrapper}>
              <TestAnswerSlider
                defaultValue={middleValue}
                valueLabelDisplay="on"
                step={
                  questionsList[questionIndex]?.questionData?.options.length
                }
                marks
                min={0}
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                  nextButtonRef.current.focus();
                }}
                max={
                  questionsList[questionIndex]?.questionData?.options?.length *
                  questionsList[questionIndex]?.questionData?.options
                    ?.length -
                  questionsList[questionIndex]?.questionData?.options?.length
                }
                valueLabelFormat={(value) => (
                  <div>
                    {questionsList[questionIndex]?.questionData?.options.map(
                      (option, index) => {
                        if (
                          value ===
                          index *
                          questionsList[questionIndex]?.questionData?.options
                            ?.length
                        ) {
                          // setAnswerId(option.answerId) // give one warnning (can not update component while other component render)
                          answerIdRef.current = option.answerId;
                        }
                        return (
                          value ===
                          index *
                          questionsList[questionIndex]?.questionData
                            ?.options?.length && (
                            <Typography
                              variant="body3"
                              color="#9747FF"
                              key={option._id}
                            >
                              {option.text}
                            </Typography>
                          )
                        );
                      }
                    )}
                  </div>
                )}
              />
              {/* <div className={classes.thumbIconsWrapper}>
                <img src={Thumbdown} alt="" />
                <img src={Thumbup} alt="" />
              </div> */}
            </div>
          </MiddleContainerWrapper>
          <div className={classes.bottomContainerWrapper}>
            {/* <div className={classes.buttonWrapper}> */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.buttonWrapper}
              rowSpacing="12px"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  className={classes.previousButton}
                  sx={{ ...typography.subtitle1, marginRight: "12px" }}
                  variant="outlined"
                  // onClick={onPrevious}
                  // disabled
                  onClick={prevClickHandler}
                  disabled={questionIndex <= 0}
                  disableRipple
                  disableFocusRipple
                >
                  Previous
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
              >
                <Button
                  className={classes.nextButton}
                  sx={{ ...typography.subtitle1 }}
                  variant="outlined"
                  onClick={nextClickHandler}
                  ref={nextButtonRef}
                  disableRipple
                  disableFocusRipple
                >
                  Next
                </Button>
              </Grid>
            </Grid>
            {/* </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default TestPage;
