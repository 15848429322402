import classes from "../Onbording.module.css";
import Box from "@mui/material/Box";
import Input from "../../../../components/Input/Input";
import {
  PrevAndNextButtonGroup,
  SubmitButton,
} from "../../../../components/Button/Button";
import { Step, StepsWrapper, SubHeadingTwo } from "../../StyledComponent";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateName } from "../../../../Store/Reducers/onbordingSlice";
import { nameValidation } from "../../../../validations/OnbordingValidation/StepOne";
import { Typography } from "@mui/material";

export default function StepOne({ onNext }) {
  const { name } = useSelector((state) => state.onbording);
  const [errorMessage, setErrorMessage] = useState("");
  const [fullName, setName] = useState(name);
  const dispatch = useDispatch();
  const onNextStep = () => {
    if (!fullName) {
      setErrorMessage("Name must be at least 2 characters");
      return;
    }
    dispatch(updateName(fullName));
    onNext();
  };

  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step></Step>
        <Step></Step>
        <Step></Step>
      </StepsWrapper>
      <div className={classes.wrapperDiv}>
        <Box className={classes.wrapper} >
          <Typography variant="h2" sx={{ textAlign: "center" }}>Let’s get your account set up</Typography>
        </Box>
        <SubHeadingTwo className={classes.wrapper} sx={{ mt: '32px' }} >
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>What’s your name?</Typography>
        </SubHeadingTwo>
        <Box className={classes.wrapper} sx={{ mt: '12px' }}>
          <Input
            placeholder="Name"
            value={fullName}
            onChange={setName}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validationFunction={nameValidation}
            type="text"
          />
        </Box>
        <Box className={classes.wrapper} sx={{ mt: "16px" }}>
          <PrevAndNextButtonGroup
            isPreviousButton={false}
            onNext={onNextStep}
            isDisabled={!fullName || errorMessage !== ""}
          />
        </Box>
      </div>
    </>
  );
}
