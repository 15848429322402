import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import classes from "./GoalArchiveAccordian.module.css";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import useCustomNotification from "../../Hooks/useCustomNotification";
function ActionArchieveAccordian() {
  const { ActiondataSlice } = useSelector((state) => state);
  const [actionAccordian, setActionAccordian] = useState(false);
  const [actionIds, setActionId] = useState("");
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const ActionAccordianHandler = (panel) => (event, isExpanded) => {
    setActionAccordian(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={actionAccordian === "panel"}
      onChange={ActionAccordianHandler("panel")}
      sx={{ position: "relative" }}
    >
      <AccordionSummary
        expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
        className={classes.subAccordianSummery}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          "& .Mui-expanded": {
            margin: "0px 0px !important",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px 0px",
          },
        }}
        //
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              Actions
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.GrowthAccordianDetailContainer}>
        <div style={{ position: "relative" }}>
          <Typography variant="body2" sx={{ margin: "10px 0px -2px 0px" }}>
            Completed
          </Typography>
          {ActiondataSlice?.compeletedAction?.length === 0 ? (
            <div className={classes.dataNotFoundSubWrapper}>
              <Typography variant="body2">
                Completed Action not found
              </Typography>
            </div>
          ) : (
            ActiondataSlice?.compeletedAction?.map((data, index) => {
              const { actionId, _id, actionName, count } = data;
              return (
                <div className={classes.customRadioAction} key={index}>
                  <div className={classes.actionNameWrapper}>
                    {count > 1 ? (
                      <div className={classes.duplicateTextWrapper}>
                        <img
                          src={CheckedCheckbox}
                          className={classes.checkBoxIcon}
                          alt=""
                        />
                        <Typography variant="body3">{count}x</Typography>
                      </div>
                    ) : (
                      <img
                        src={CheckedCheckbox}
                        className={classes.checkBoxIcon}
                        alt=""
                      />
                    )}
                    <Typography
                      variant=" body2"
                      className={classes.checkBoxChekedText}
                    >
                      {" "}
                      {actionName}
                    </Typography>
                  </div>
                  <div className={classes.schedualWrapper}>
                    <img
                      style={{ width: "14px" }}
                      src={CheckboxEndIcon}
                      alt="CheckboxEndIcon"
                      onClick={() => {
                        setCustomNotification(true);
                        setActionId(index);
                      }}
                    />
                    <img
                      style={{
                        margin: "4px 0px 0px -7px",
                        height: "18px",
                      }}
                      src={tripleDot}
                      alt="ExpandMore"
                    />
                  </div>

                  {customNotification && actionIds === index && (
                    <NotificationContentJSX
                      message={actionId[0].actionDetail}
                      isThumbIcon={false}
                      position="top"
                    />
                  )}
                </div>
              );
            })
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ActionArchieveAccordian;
