import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Input from "../../../components/Input/Input";
import { SubmitButton } from "../../../components/Button/Button";
import classes from "./changePassword.module.css";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import LeftArrowIcom from "../../../assets/Svg/LeftArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { isValidPassword } from "../../../validations/Email/Email";

export const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordData, setPasswordData] = useState({
    Password: "",
    NewPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    Password: "",
    NewPassword: "",
  });

  const setpasswordError = (e) => {
    setErrorMessage({ ...errorMessage, "Password": e })
  }
  const setNewpasswordError = (e) => {
    console.log("e", e)
    setErrorMessage({ ...errorMessage, "NewPassword": e })
  }

  const handlePasswordInput = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleChangePassword = (e) => {
    setPasswordData({ ...passwordData, "Password": e });
  }

  const handleChangeNewPassword = (e) => {
    setPasswordData({ ...passwordData, "NewPassword": e });
  }

  const handleSubmitData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log("user = ", user);
    const credential = EmailAuthProvider.credential(
      user.email,
      passwordData.Password
   );
   await reauthenticateWithCredential(auth.currentUser, credential);
    updatePassword(user, passwordData.NewPassword)
      .then((res) => {
        console.log("res = ", res);
        // Update successful.
      })
      .catch((error) => {
        console.log("error = ", error.message);
        // An error ocurred
        // ...
      });
    console.log("Feedback feature request send:");
  };

  const backToSideNavigate = () => {
    navigate("/settings/settingnavigationbar");
  };
  console.log(` (!passwordData.Password || !passwordData.NewPassword ) && (
    passwordData.Password.length > 5 && passwordData.NewPassword.length > 5
  )`, passwordData.Password, passwordData.NewPassword, (!passwordData.Password || !passwordData.NewPassword ) && (
    passwordData.Password.length > 5 && passwordData.NewPassword.length > 5
  ));
  return (
    <>
      {matches ? (
        <Typography>
          {" "}
          <img
            className={classes.backbutton}
            onClick={backToSideNavigate}
            alt="..."
            src={
              selectedImage ? URL.createObjectURL(selectedImage) : LeftArrowIcom
            }
          />
          Change Password
        </Typography>
      ) : (
        <Typography variant="body4">Change Password</Typography>
      )}
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <Typography className={classes.fieldName} variant="subtitle1">
            Old Password
          </Typography>
          <Input
            placeholder="Enter old password"
            name="Password"
            value={passwordData.Password}
            onChange={handleChangePassword}
            validationFunction={isValidPassword}
            errorMessage={errorMessage.Password}
            setErrorMessage={setpasswordError}
            type="password"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <Typography className={classes.fieldName} variant="subtitle1">
            New Password
          </Typography>
          <Input
          placeholder="Enter new password"
          value={passwordData.NewPassword}
          onChange={handleChangeNewPassword}
          validationFunction={isValidPassword}
          type="password"
          name="NewPassword"
          errorMessage={errorMessage.NewPassword}
          setErrorMessage={setNewpasswordError}
        />
          {/* <Input
            placeholder="Enter new password"
            name="NewPassword"
            value={passwordData.NewPassword}
            onChange={handlePasswordInput}
            validationFunction={isValidPassword}
            inputType="password"
          /> */}
        </Grid>
      </Grid>
      <SubmitButton
        isDisabled={
          passwordData.Password.length < 6 || passwordData.NewPassword.length < 6
        }
        buttonText={"Save new password"}
        onClick={handleSubmitData}
      />
    </>
  );
};
