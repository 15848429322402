import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";

import { createRef, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import classes from "./invitemodal.module.css"
import WinningCel from "./../../../assets/Svg/WinningCel.svg"
import RightIcon from "../../../assets/Svg/RightIcon.svg";
import CloseButton from "../../../components/CloseButton";

const options = [
  {
    title: "Basic",
    xp: 3,

  },
  {
    title: "Casual",
    xp: 5,

  },
  {
    title: "Serious",
    xp: 10,

  },
  {
    title: "Intense",
    xp: 20,

  }
]
const EditGoalModal = (props) => {
  const [value, setInputValue] = useState("");
  const textInput = createRef(null);
  const [selectedXp, setSelectedXp] = useState(props.goalXp)
  const {
    saveUpdateDailyGoal,
    visibleEditGoalModal,
    openCloseEditGoalModal,
    openJournalItem,
    addUpdateJournalData,
    savingJournal,
    dailyGoalLevel,
    buttonLoading
  } = props;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const confettiRef = useRef(null);
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
  }, []);

  useEffect(() => {
    setSelectedXp(props.goalXp)
  }, [props.goalXp])

  return (
    <Dialog
      open={visibleEditGoalModal}
      onClose={() => {
        openCloseEditGoalModal(false);
      }}
      PaperProps={{
        style: {
          borderRadius: "20px",
          position: "relative",
          padding: '0',
          width: 'calc(100vw - 45%)',
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          // width: "100%",


        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      
      <div style={{ position: 'relative', padding: '24px' }} >

        <div className={classes.gm_title}>
          <Typography
            variant="caption"
            component="div"
            className={classes.gm_title_text}
          >
            Edit your daily XP goal
          </Typography>
          <Typography
            variant="caption"
            component="div"
            className={classes.gm_title_text1}
          >
            Set your daily amount of desired XP
          </Typography>

        </div>
        <div className={classes.gm_info}>
          {dailyGoalLevel && dailyGoalLevel.length && dailyGoalLevel.map(it => {
            const selectedClass = selectedXp === it.points ? clsx(classes.xpbox, classes.selectedXpBox) : clsx(classes.xpbox)
            const selectedTextClass = selectedXp === it.points ? clsx(classes.xpboxTitle, classes.selectedxpboxTitle) : clsx(classes.xpboxTitle)
            const selectedXpclass = selectedXp === it.points ? clsx(classes.xpboxTitleXp, classes.selectedxpboxTitleXp) : clsx(classes.xpboxTitleXp)
            return <div key={it.points} className={selectedClass} onClick={() => {
              setSelectedXp(it.points)
            }}>
              <Typography
                variant="caption"
                component="div"
                className={selectedTextClass}
              >
                {it.level}
              </Typography>
              <div style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}>
                <Typography
                  variant="caption"
                  component="div"
                  className={selectedXpclass}
                >
                  {it.points} Per Day
                </Typography>
                {selectedXp === it.points ? (
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={RightIcon}
                    alt=""
                  />
                ) : null}

              </div>

            </div>
          })}
        </div>
        <div style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <LoadingButton
            variant="contained"
            className={classes.xp_info_button}
            onClick={(e) => {
              saveUpdateDailyGoal(selectedXp);
            }}
            loading={buttonLoading}
          >
            Save daily XP goal
          </LoadingButton>
        </div>

      </div>
      <div
        className={classes.closeIcon}
        onClick={() => {
          openCloseEditGoalModal(false);
        }}
      >
        <CloseButton />
      </div>
    </Dialog>
  );
};

export default EditGoalModal;
