import { Box, Chip, Dialog, Typography } from "@mui/material";
import React from "react";
import { days } from "../../../Constant/GoalConstant";
import { CustomCheckBox } from "../../GoalComponent/GoalCustomDiv";
import { CheckBoxInputCheckedDay, CheckBoxInputDay } from "../../Input/Input";
import classes from "./GoalTimePicker.module.css";
import RightIcon from "../../../assets/Svg/RightIcon.svg";
import { firstLetterUpper } from "../../../utils";
import Clock from "../../../assets/Svg/Clock.svg";
import ChipSaveButton from "../../ChipSaveButton";


function GoalTimePicker({
  isDayPickerOpen,
  dayModalCloseHandler,
  selectedDaystate,
  setselectedDayHandler,
  onChangeDay,
  selectedHabitData,
  disabledSelection = false
}) {
  console.log("selectedHabitData", selectedHabitData);
  return (
    <Dialog
      open={isDayPickerOpen}
      onClose={() => {
        dayModalCloseHandler();
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "456px",
          height: "auto",
          maxHeight: 'calc(100vh - 50%)',
          borderRadius: "20px",
          position: "unset",
          padding: "20px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ width: "100%" }}>
        <div className={classes.DateAndTimewrapper}>
          <img src={Clock} />
          <Typography variant="body2" sx={{ color: "#190D1A" }}>
            Frequency
          </Typography>
        </div>
        <div className={classes.WeeklyText}>
          <Typography className={classes.dailyText} variant="subtitle1">
            Daily
          </Typography>
          {selectedHabitData?.habitScheduleType === "DAILY" ? (
            <img
              style={{ width: "20px", height: "20px" }}
              src={RightIcon}
              alt=""
            />
          ) : null}

        </div>

        <div className={classes.WeeklyText}>
          <Typography variant="subtitle1" sx={{ color: "#434243" }}>
            Weekly
          </Typography>
          {selectedHabitData?.habitScheduleType !== "DAILY" ? (
            <img
              style={{ width: "20px", height: "20px" }}
              src={RightIcon}
              alt=""
            />
          ) : null}
        </div>
        {selectedDaystate.length ? <Typography variant="subtitle1" sx={{ color: "#434243", lineHeight: '20px' }}>
          Event will occur every week on  {[...selectedDaystate].map(firstLetterUpper).join(", ")}
        </Typography> : ""}
        {selectedHabitData?.habitScheduleType === "DAILY" ? null : <Box className={classes.radioWrapper}>
          {days?.map((day, index) => {
            return (
              <CustomCheckBox
                key={index}
                sx={{ margin: "6px 0px" }}
                icon={
                  <CheckBoxInputDay
                    placeholder={day.slice(0, 3).toLowerCase()}
                    sx={{
                      '& .MuiOutlinedInput-input': {
                          color: disabledSelection && 'grey'
                      }
                    }}
                  />
                }
                disabled={disabledSelection}
                onChange={() => {
                  setselectedDayHandler(day);
                  onChangeDay && onChangeDay(day, index)
                }}
                checked={selectedDaystate?.find((o) => o === day) ? true : false}
                checkedIcon={
                  <CheckBoxInputCheckedDay
                    placeholder={day.slice(0, 3).toLowerCase()}
                  />
                }
              />
            );
          })}
        </Box>}

        {/* <Typography className={classes.MonthlyText} variant="subtitle1">
          Monthly
        </Typography> */}
        <ChipSaveButton
          title="Save"
          onSave={dayModalCloseHandler}
        />

      </div>
    </Dialog>
  );
}

export default GoalTimePicker;
