import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import classes from "./SocialResultUserNotFound.module.css";
import Search from "../../../assets/Svg/Search.svg";
import Avatarone from "../../../assets/Svg/Avatarone.svg";
import React, { useState } from "react";

import typography from "../../../theme/typography";
import AskOther from "../../../core-ui/AskOther/AskOther";

function SocialResultUserNotFound({ setIsAskOtherModalOpenSocial }) {
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [dimensions, setDimensions] = useState([]);

  const handleClickOpen = () => {
    setIsAskOtherModalOpen(true);
  };
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };

  const InnerWrapper = styled(Box)(({ theme }) => ({
    margin: "auto 32px",
    overflowX: "auto",
    [theme.breakpoints.down("lg")]: {
      margin: "auto 12px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto 5px",
    },
  }));
  // CustomDialog
  const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
      }
    }
  `;

  return (
    <InnerWrapper>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        You haven’t invited anyone
      </Typography>
      <Typography
        variant="subtitle1"
        color="#434243"
        sx={{ lineHeight: "145%", textAlign: "center", margin: "8px auto" }}
      >
        Start inviting other people to test for you, so you can <br />
        have their perspective on your self-discovery
      </Typography>
      <Button
        className={classes.previous_btn}
        sx={{ ...typography.subtitle1, marginRight: "12px" }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Ask others
      </Button>
      <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AskOther onClose={handleClose} dimensions={dimensions} setIsAskOtherModalOpenSocial={setIsAskOtherModalOpenSocial} />
      </CustomDialog>
    </InnerWrapper>
  );
}

export default SocialResultUserNotFound;
