export function isValidEmail(email) {
    const result = /\S+@\S+\.\S+/.test(email);
    if (!result) {
        return "Enter valid email"
    }
}

export function isValidPassword(password) {
    if (password.length < 6) {
        return 'Password must be at least 6 characters'
    }
}

// export function isBothPasswordSame(password) {
//     let error = ""
//     if (password.length < 8) {
//         error = "Password must be at least 8 characters"
//     } 
//     // else if (password !== resetpassword) {
//     //     error = "Both passwords must match"
//     // }

//     return error
// }