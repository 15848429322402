import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";
// import "./circularProgress.css"
function CircularProgressGoal(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: props?.width,
        height: props?.height,
      }}
    >
      <CircularProgress
        sx={{ color: "#9747FF" }}
        style={{ width: props?.width, height: props?.height }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="#9747FF">
          {props.value}
        </Typography>
      </Box>
    </Box>
  );
}
export default CircularProgressGoal;
