import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLearnMore: false,
  learnMoreQuestioncategories: "",
  selectedQuestioncategoriesId: "",
  isSocialResultNavigate:false,
};

export const averageByInviteUserSlice = createSlice({
  name: "averageByInviteUserSlice",
  initialState,
  reducers: {
    updateisLearnMore: (state, action) => {
      state.isLearnMore = action.payload;
    },
    updateLearnMoreQuestionCategories: (state, action) => {
      state.learnMoreQuestioncategories = action.payload;
    },
    updateSelectedQuestionCategories: (state, action) => {
      state.selectedQuestioncategoriesId = action.payload;
    },
    updateisSocialResultNavigate: (state, action) => {
      state.isSocialResultNavigate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateisLearnMore,
  updateLearnMoreQuestionCategories,
  updateSelectedQuestionCategories,
  updateisSocialResultNavigate
} = averageByInviteUserSlice.actions;

export default averageByInviteUserSlice.reducer;
