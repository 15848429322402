import axios from "axios";
import { baseEndpoints } from "../config";

class NotificationService {
    static LIST_NOTIFICATION = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.NOTIFICATION.LIST}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }

    static READ_NOTIFICATION = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.NOTIFICATION.READ}`, updateActionHabit)
            .then((response) => {
            return response;
            });
        return data;
    }
}

export default NotificationService