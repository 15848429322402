import React, { useState } from "react";
import {
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { SubmitButton } from "../../../components/Button/Button";
import {
  ItemList,
  Selecter,
} from "../../../components/Settings/StyledElement/StyledformInput";
import classes from "./profilePersonalInfo.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../services/auth";
import {
  Gender,
  Language,
  Country,
  Ethnicity,
  Industry,
  MaritalStatus,
  Children,
  Goal,
  PoliticalPreferences,
  Occupation,
} from "../../../Constant/SettingConstant";
import { userProfileUpdate } from "../../../Store/Reducers/userProfileSlice";
import { KeyboardArrowDown } from "@mui/icons-material";
import useCustomNotification from "../../../Hooks/useCustomNotification";

const ProfilePersonalInfo = ({ accountInfoData, setAccountInfoData }) => {
  const distpatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { topicDataProfile } = useSelector(state => state.onbording)
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const handleChangeSelect = (event) => {
    setAccountInfoData({
      ...accountInfoData,
      [event.target.name]: event.target.value,
    });
  };
  console.log("topicDataProfile", topicDataProfile);
  const handleSubmitData = () => {
    console.log("accountpersonalInfoData:", accountInfoData);
    distpatch(userProfileUpdate(accountInfoData));
    let Details = {
      ...accountInfoData,
      uid: localStorage.getItem("userDetail"),
    };
    setIsLoading(true);
    setCustomNotification(true);
    updateUserData(Details)
      .then((res) => {
        console.log("res update = ", res);
      })
      .catch((err) => {
        console.log("err = ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <>
      <Typography variant="body4">Personal data</Typography>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Gender
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.gender}
            input={<OutlinedInput />}
            name="gender"
            placeholder="Set your gender"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {Gender?.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Language
          </InputLabel>
          <Selecter
            placeholder="Not Updated"
            input={<OutlinedInput />}
            onChange={handleChangeSelect}
            value={accountInfoData.languages}
            name="languages"
            select
            disableUnderline={false}
            variant="outlined"
            MenuProps={MenuProps}
          >
            {Language.map((item, index) => {
              return (
                <MenuItem
                  sx={{
                    "& .MuiPaper-root": {
                      height: "500px !important",
                    },
                  }}
                  className={classes.list_item}
                  // className={classes.option_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          xl={6}
          sx={{ maxHeight: "300px !important" }}
        >
          {/*  */}
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Country
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.country}
            input={<OutlinedInput />}
            name="country"
            placeholder="Not Updated"
            select
            disableUnderline={false}
            variant="outlined"
            MenuProps={MenuProps}
          >
            {Country.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Ethnicity
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.ethnicity}
            input={<OutlinedInput />}
            name="ethnicity"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {Ethnicity.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Occupation
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.occupation}
            input={<OutlinedInput />}
            name="occupation"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {Occupation.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Industry
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.industry}
            input={<OutlinedInput />}
            name="industry"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
            MenuProps={MenuProps}
          >
            {Industry.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Marital Status
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.marital_status}
            input={<OutlinedInput />}
            name="marital_status"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {MaritalStatus.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Children
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.children}
            input={<OutlinedInput />}
            name="children"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {Children.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Goal
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={topicDataProfile.topic}
            // value={accountInfoData.goals}
            input={<OutlinedInput />}
            name="goals"
            placeholder="Select"
            select
            disableUnderline={false}
            disabled
            variant="outlined"
          >
            {Goal.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <InputLabel
            className={classes.textFieldName}
            variant="subtitle1"
            id="demo-simple-select-helper-label"
          >
            Political Preferences
          </InputLabel>
          <Selecter
            onChange={handleChangeSelect}
            value={accountInfoData.political_preference}
            input={<OutlinedInput />}
            name="political_preference"
            placeholder="Select"
            select
            disableUnderline={false}
            variant="outlined"
          >
            {PoliticalPreferences.map((item, index) => {
              return (
                <MenuItem
                  className={classes.list_item}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Selecter>
        </Grid>
      </Grid>
      <SubmitButton
        buttonText={"Save"}
        onClick={handleSubmitData}
        isLoading={isLoading}
      />
      {customNotification && (
        <NotificationContentJSX
          message="Profile updated successfully"
          isThumbIcon={false}
          position="top"
        />
      )}
    </>
  );
};

export default ProfilePersonalInfo;
