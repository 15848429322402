
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
export const StepsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "48px",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    top: "24px",
  },
}));

export const Step = styled("div")(({ theme }) => ({
  width: '108px',
  height: '12px',
  border: '1px solid #E5D5FC',
  borderRadius: '10px',
  background: '#E5D5FC',
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    width: '82px',
    height: '8px',
  },
}));