import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  goalID: "",
  isGrowthAreaRemove: false,
  suggestedGrowthAreaState:[],
};

export const growthAreaDataSlice = createSlice({
  name: "growthAreaDataSlice",
  initialState,
  reducers: {
    updateGoalID: (state, action) => {
      state.goalID = action.payload;
    },
    updateSuggestedGrowthAreaState: (state, action) => {
      state.suggestedGrowthAreaState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGoalID,updateSuggestedGrowthAreaState} =
  growthAreaDataSlice.actions;
export default growthAreaDataSlice.reducer;
