import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import FeedbackHeader from "../../../components/Settings/Feedback/FeedbackHeader";
import { SettingNavigationBar } from "../../../components/Settings/SettingNavigationBar/SettingNavigationBar";
import { RightSideContentWrapper } from "../../../components/Settings/StyledElement/StyledDiv";
import { FEEDBACK_TYPE } from "../../../Constant/SettingConstant";

import FeatureRequest from "./FeatureRequest";
import classes from "./feedback.module.css";
import GiveFeedback from "./GiveFeedback";
import ReportBug from "./ReportBug";
import { useTheme } from "@emotion/react";

function SetFeedback() {

const theme = useTheme();
const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [feedbackRequest, setFeedbackRequest] = useState(
    FEEDBACK_TYPE.REQUEST_FEEDBACK
  );
  console.log(feedbackRequest);
  return (
    <>
      <RightSideContentWrapper>
        <Header />
        <Grid container spacing={1.5}>
          <Grid item xs={12} lg={9} md={9}>
            <div className={classes.dashbordTitleContainer}>
              {feedbackRequest === FEEDBACK_TYPE.REQUEST_FEEDBACK ? (
                <>
                  <FeedbackHeader setFeedbackRequest={setFeedbackRequest} />
                  <FeatureRequest />
                </>
              ) : feedbackRequest === FEEDBACK_TYPE.REPORT_BUG ? (
                <>
                  <FeedbackHeader setFeedbackRequest={setFeedbackRequest} />
                  <ReportBug />
                </>
              ) : (
                <>
                  <FeedbackHeader setFeedbackRequest={setFeedbackRequest} />
                  <GiveFeedback />
                </>
              )}
              
            </div>
          </Grid>
          {
           !matches && <Grid item xs={12} lg={3} md={3}>
            <div
              style={{ padding: "12px 0px" }}
              className={classes.dashbordTitleContainer}
            >
              <SettingNavigationBar />
            </div>
          </Grid>
          }
        </Grid>
      </RightSideContentWrapper>
    </>
  );
}

export default SetFeedback;
