import { Button, CircularProgress, IconButton } from "@mui/material";
import classes from "./Login.module.css";
import Box from "@mui/material/Box";
import Input from "../../components/Input/Input";
import Text from "../../components/Text/Text";
import {
  LinkButton,
  LinkButtonWithText,
  SubmitButton,
} from "../../components/Button/Button";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/fb.png";
import apple from "../../assets/images/apple.png";
import {
  appleProvider,
  facebookProvider,
  googleProvider,
} from "../../config/authMethods";
import { getUserData, socialMediaAuth, userLogin } from "../../services/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { isValidEmail, isValidPassword } from "../../validations/Email/Email";
import {
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import useNotifications from "../../Hooks/useNotifications";
import { useDispatch, useSelector } from "react-redux";
import Google from "../../assets/Svg/Google.svg"
import Facebook from "../../assets/Svg/Facebook.svg"
import Apple from "../../assets/Svg/Apple.svg"
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import { updateTopicDataProfile, updateTopicDataYoutubeLink } from "../../Store/Reducers/onbordingSlice";
// import SixDigitCode from "./SixDigitCode";

export default function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isCode, setIsCode] = useState(false)

  const { userData } = useSelector((state) => state.userProfile);
  console.log(33, userData);

  const { Notification, updateNotificationState } = useNotifications(false);

  const loginUser = async (uid) => {
    setIsLoading(true);
    const body = {
      uid: uid,
      platform: 'https://appx.scientific-selfdiscovery.com'
    };
    if (window.location.origin.includes('app.scientific-selfdiscovery.com')) {
      body.platform = 'https://app.scientific-selfdiscovery.com'
    }
    localStorage.setItem("userDetail", uid);

    userLogin(body)
      .then((data) => {
        console.log(48, data);
        updateNotificationState(data.message);
        getUserData(uid)
        .then((response) => {
          console.log(response.data);
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
          if(response.topicData) {
            dispatch(updateTopicDataProfile(response.topicData))
            dispatch(updateTopicDataYoutubeLink(response.topicData.link))
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
        navigate("/testcategory");
      })
      .catch((error) => {
        console.log("error.response.data.description", error.response.data.description);
        updateNotificationState(error.response.data.description);
        if(error.response.data.description === "User is not registered") {
          setTimeout(() => {
            updateNotificationState("");
            navigate('/signup')
          }, 800);
        }
        localStorage.clear()
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClick = async (provider) => {
    try {
      const result = await socialMediaAuth(provider);
      console.log("result", result);
      if (!result.uid) {
        throw new Error(result.message);
      } else {
        localStorage.setItem("userDetail", result?.uid);
        localStorage.setItem("userEmail", result?.email); // store this in the store
        loginUser(result?.uid);
      }
    } catch (error) {
      if (!error.message.includes("auth/popup-closed-by-user")) {
        updateNotificationState(error?.message);
      }
      setIsLoading(false);
    }
  };

  const emailAuth = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => {
        console.log("----------", err.message);
        // user not registered
        if (err.message.includes("auth/wrong-password")) {
          setErrorPassword("Invalid credentials");
        } else if (err.message.includes("auth/user-not-found")) {
          setErrorEmail("User with such email isn’t found");
        }
        setIsLoading(false);
        // return err
      });
  };

  const firebaseLogin = async (email, password) => {
    try {
      const result = await emailAuth(email, password);
      console.log(result);
      if (result?.user) {
        loginUser(result?.user?.uid);
      }
    } catch (error) {
      updateNotificationState("User not found");
      setIsLoading(false);
      console.log(">>>", error);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    firebaseLogin(email, password);
  };

  const signinWithApple = async () => {
    // signInWithRedirect(auth, appleProvider)
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        console.log(result.user.uid);
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        if (result?.user) {
          loginUser(result.user.uid);
        }
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(error, credential);
        setErrorPassword("Invalid credentials");
        setErrorEmail("Invalid credentials");
        setIsLoading(false);
      });
  };

  // const handleYourCode = () =>{
  //   setIsCode(true)
  // }

  return (
    <>
      <Box className={classes.brandBox} sx={{ mb: "16px", py: "1px" }}>
        <Text textValue="Scientific Self-Discovery" variant="h6" color="#9747FF" otherSx={{
          fontSize: "18px",
          fontWeight: 500
        }}/>
      </Box>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px", mt: '32px' }}>
        <Text textValue="Welcome back" variant="h2" otherSx={{
          fontSize: "2.5rem",
          fontWeight: 600
        }} />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Email or username"
          value={email}
          onChange={setEmail}
          validationFunction={isValidEmail}
          type="text"
          errorMessage={errorEmail}
          setErrorMessage={setErrorEmail}
        />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Password"
          value={password}
          onChange={setPassword}
          validationFunction={isValidPassword}
          type="password"
          errorMessage={errorPassword}
          setErrorMessage={setErrorPassword}
          height="56px"
        />
      </Box>
      <Box className={classes.forgotPassword_btn_wrapper}>
        <LinkButton
          buttonText="Forgot password?"
          color="#434243"
          navigateUrl="/forgotpassword"
        />
      </Box>
      <Box sx={{ width: "100%", paddingBottom: "16px" }}>
        <SubmitButton
          buttonText="Log In"
          isDisabled={!email || !password || errorEmail || errorPassword}
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </Box>
      {/* <Box>
        <Button
          size="small"
          className={classes.codeButton}
          onClick={handleYourCode}
        >
          Enter your code
        </Button>
      </Box> */}
      <Box className={`${classes.wrapper} ${classes.orText}`}>or</Box>
      <Box className={classes.wrapper} sx={{ gap: "12px", padding: "16px" }}>
        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(googleProvider)}
        >
          <img src={Google} alt="" />
        </Box>

        <Box className={classes.imgIcon} onClick={() => signinWithApple()}>
          <img src={Apple} alt="" />
        </Box>

        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(facebookProvider)}
        >
          <img src={Facebook} alt="" />
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <LinkButtonWithText
          text="Not a user yet?"
          buttonText="Sign up"
          navigateUrl="/signup"
        />
      </Box>
      {Notification} 
      
    </>
  );
}
