import { useState } from "react";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import StepFour from "./StepFour/StepFour";
import StepGoal from "./StepGoal/StepGoal";

const steps = {
  // 1: StepOne,
  1: StepTwo,
  2: StepThree,
  3: StepGoal,
  // 4: StepFour,
};

const Onbording = () => {
  const [step, setStep] = useState(1);
  const Step = steps[step];
 
  console.log("step",step)
  const onNext =()=> {
    setStep(step + 1);
  }
  const onPrevious =()=> {
    setStep(step - 1);
  }

  return <Step onNext={onNext} onPrevious={onPrevious} />;
};

export default Onbording;
