import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  SubmitButton,
  SubmitButtonWithBackGround,
} from "../../../components/Button/Button";
import { SubHeading, WrapperDiv } from "../StyledComponent";
import classes from "./StartTest.module.css";
import { anonymousUpdate, getUserData } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FEEDBACKTYPE } from "../../../Constant/Invite";
import { userProfileUpdate } from "../../../Store/Reducers/userProfileSlice";

const StartTest = ({ showUserdata }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("showUserdata", showUserdata);
  const {
    inviteDataSlice: { inviteData },
    userProfile: {userData}
  } = useSelector((state) => state);

  useEffect(() => {
    const user = localStorage.getItem("userDetail");

    if (user) {
      getUserData(user)
        .then((response) => {
          console.log(response.data);
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
  }, []);

  const updateMainState = () => {
    const uid = localStorage.getItem("userDetail");
    const inviteId = localStorage.getItem("inviteId");
    // console.log("uid", uid, ">>>>iviteID", inviteId);
    const anonymousData = {
      uid: uid,
      inviteID: inviteId,
      nickName:showUserdata?.nickName,
      anonymousAge:showUserdata?.anonymousAge,
      anonymousGender:showUserdata?.anonymousGender
    };
    console.log("anonymousData",anonymousData);
    // navigate(`/invite/${inviteId}/test`);
    anonymousUpdate(anonymousData)
      .then((response) => {
        console.log(response)
            navigate(`/invite/${inviteId}/test`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <WrapperDiv>
      <div></div>
      <div>
        <Box className={classes.wrapper}>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            {userData?.name ? userData?.name : "Guest"}, now we are good to go
          </Typography>
        </Box>
        <Box sx={{ marginTop: "12px" }}>
          <Typography
            variant="subtitle1"
            color="#434243"
            sx={{ textAlign: "center", lineHeight: "145%" }}
          >
            Please, keep in mind that this test requiers
          </Typography>
          <Typography
            variant="subtitle1"
            color="#A25BFE"
            sx={{ textAlign: "center", lineHeight: "145%" }}
          >
           {inviteData?.feedbackType === FEEDBACKTYPE.BASED_ON_AVERAGE_POPULATION ? "average population based feedback" : "personal standards based feedback"}
          </Typography>
        </Box>
      </div>
      <Box className={classes.wrapper} sx={{ marginTop: "32px" }}>
        <SubmitButtonWithBackGround
          buttonText="Next"
          onClick={updateMainState}
          isDisabled={false}
          isLoading={false}
        />
      </Box>
    </WrapperDiv>
  );
};

export default StartTest;
