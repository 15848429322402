import styled from "@emotion/styled";
import { Button, Grid, Slider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import classes from "./styleStepper.module.css";

export const StepsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "6px",
  alignItems: "center",
  justifyContent: "center",
  top: "48px",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    top: "24px",
  },
}));

export const Step = styled("div")(({ theme }) => ({
  width: "224px",
  height: "12px",
  border: "1px solid #E5D5FC",
  borderRadius: "50px",
  background: "#E5D5FC",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
    height: "8px",
  },
}));

// result slider

export const ResultSlider = styled(Slider)`
  width: 100%;
  max-width: 456px;
  position: relative;
  padding: 0 !important;
  & .MuiSlider-rail {
    background: linear-gradient(
      90deg,
      #d7e0fb 0%,
      #dcfaf0 20.28%,
      #e6f8de 46.45%,
      #fff8bd 69.19%,
      #fce9a5 100%
    );
    height: 16px;
    opacity: 1;
  }

  & .MuiSlider-thumbSizeMedium {
    margin-left: 5px;
  }
  & .MuiSlider-track {
    height: 16px;
    background: linear-gradient(
      90deg,
      #d7e0fb 0%,
      #dcfaf0 20.28%,
      #e6f8de 46.45%,
      #fff8bd 69.19%,
      #fce9a5 100%
    );
    border: none;
  }
  & .MuiSlider-markLabel {
    position: absolute;
    color: #190d1a;
    top: -5px;
    font-size: 10px;
    z-index: 5;
    padding-left: 10px;
  }

  & .MuiSlider-mark {
    display: none;
  }

  & .MuiSlider-thumbColorPrimary {
    box-shadow: none !important;
  }
  & .MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
  }
`;

// multiline input

export const MultiLineInput = styled(TextField)`
  min-height: 180px;
  margin-left: auto;
  margin-right: auto;
  max-width: 456px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-root {
    min-height: 180px;
    display: flex;
    align-items: flex-start;
    padding: 18px 20px;
    :hover {
      border: 1px solid #e5d5fc;
      border-radius: 16px;
    }
    :focus {
      border: 1px solid #9747ff;
      border-radius: 16px;
    }
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;

export const FeedbackInput = styled(TextField)`
  min-height: 88px;
  max-width: 456px;
  margin-left: auto;
  margin-right: auto;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-root {
    min-height: 88px;
    display: flex;
    align-items: flex-start;
    padding: 18px 20px;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;
export const NextButton = ({
  onNext,
  updateMainState = () => {},
  isNextDisable = false,
  nextButtonText = "Next",
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      // className={classes.buttonWrapper}
      rowSpacing="12px"
      columnSpacing="12px"
      marginTop="20px"
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        // sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
        sx={{ textAlign: "center" }}
      >
        <Button
          className={classes.nextButton}
          variant="outlined"
          // onClick={() => {
          //   console.log("onNext button");
          //   if (updateMainState) {
          //     updateMainState();
          //   }
          //   onNext();
          // }}
          disabled={isNextDisable}
        >
          {nextButtonText}
        </Button>
      </Grid>
    </Grid>
  );
};

// review button
export const ReviewButton = ({
  // nextButtonText = "Do full review and earn 20",
  nextButtonText = "Make dimension a goal",
  nextButtonIcon = "",
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      // className={classes.buttonWrapper}
      rowSpacing="12px"
      columnSpacing="12px"
      marginTop="20px"
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ textAlign: "center" }}
      >
        <Button className={classes.reviewButton} variant="outlined">
          {nextButtonText}
          {nextButtonIcon}
        </Button>
      </Grid>
    </Grid>
  );
};

// FeedBack Div

export const FeedBackDiv = styled(Box)`
  min-height: 180px;
  max-width:456px;
  margin:24px auto;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  padding: 0px 20px;
  line-height: 23.2px;
  }
`;
