import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Checkbox, Slider, TextField } from "@mui/material";
export const StepsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  // top: "48px",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    top: "12px",
  },
}));

export const Step = styled("div")(({ theme }) => ({
  width: "108px",
  height: "12px",
  border: "1px solid #E5D5FC",
  borderRadius: "10px",
  background: "#E5D5FC",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    width: "82px",
    height: "8px",
  },
}));

export const InputField = styled(TextField)`
  height: 56px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiTextField-root {
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }

  & .MuiOutlinedInput-root {
    padding-right: unset;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .generate {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }

  & .MuiOutlinedInput-input {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 16px;
    padding: 15.5px 20px;
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
`;

export const CustomCheckBox = styled(Checkbox)`
  padding: 0;
  width: 100%;
   & .MuiTextField-root {
    width: 100%;
  }
`;

export const TestQuestionSlider = styled(Slider)`
  // background: yellow;
  padding: 0px !important;
  & .MuiSlider-rail {
    background: #e5d5fc;
    height: 12px;
  }

  & .MuiSlider-track {
    background: #9747ff;
    height: 12px;
  }

  & .MuiSlider-mark {
    // left: 0% !important;

    // width: unset;
    // height: unset;
    // background: none;

    // border-radius: 50%;

    // background: #9747FF;

    background: #e5d5fc;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  & .MuiSlider-markActive {
    // left: 0% !important;
    background: none;
    // background-image: url("../../assets/Svg/SliderMark.svg");
    & :nth-child(1) {
      background: #e5d5fc;
    }
  }

  & .MuiSlider-thumbColorPrimary {
    box-shadow: none;
  }
`;

export const TestAnswerSlider = styled(Slider)`
  // background: yellow;
  width: 98.2%;
  padding: 0px;
  & .MuiSlider-rail {
    background: #e5d5fc;
    height: 12px;
  }

  & .MuiSlider-thumbSizeMedium {
    margin-left: 5px;
  }
  & .MuiSlider-rail {
    border-color: #f7f2fe;
  }

  & .MuiSlider-track {
    height: 12px;
    background: #f7f2fe;
    border-color: #f7f2fe;
  }

  & .MuiSlider-mark {
    // display: none;
    background: #9747ff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  & .MuiSlider-markActive {
    background: #9747ff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  & .MuiSlider-valueLabelOpen {
    background: #f7f2fe;
    color: #9747ff;
  }

  & .MuiSlider-thumbColorPrimary {
    box-shadow: none;
  }
`;

export const MiddleContainerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  flexDirection: "column",
  width: "457px",
  minHeight: "216px",

  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const MultiLineInput = styled(TextField)`
  min-height: 180px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-root {
    min-height: 180px;
    display: flex;
    align-items: flex-start;
    padding: 18px 20px;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;

export const SubHeading = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "12px",
  },
}));

export const SubHeadingTwo = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: "32px",
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "24px",
  },
}));

export const WrapperDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
  },
}));
