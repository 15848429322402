import styled from "@emotion/styled";
import { List, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ValidationMessage } from "./StyledDiv";
import classes from "./StyledFormInput.module.css";

const InputField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: 0;
    position: relative;

    & .MuiInputAdornment-root {
      margin-left: 0px;
    }
  }
  button {
    position: absolute;
    right: 20px;
  }
  & .MuiOutlinedInput-input {
    height: 28px;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 16px;
    // padding: 15.5px 20px;
    padding: 13px 20px;
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiFormHelperText-root {
    color: #ef8b6e !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    margin-left: 0;

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`;
export const Input = ({
  placeholder,
  value,
  onChange,
  height,
  name,
  emailError,
  inputType = "text"
}) => {
  const [customError, setCustomError] = useState("");

  return (
    <InputField
      type={inputType}
      name={name}
      error={customError !== "" || undefined}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      className={
        name === "user_email" && emailError === false
          ? classes.validationError
          : null
      }
      fullWidth
      sx={{ height: { xl: height, lg: height, md: height } }}
      size="small"
      helperText={customError === "" ? null : <span>{customError}</span>}
      value={value}
      required={true}
      onChange={(e) => {
        onChange(e);
      }}
    />
  );
};

// ***** Select Input ****

export const ItemList = styled(TextField)`
  width: 100%;
  border-radius: 16px;
  & .MuiOutlinedInput-root {
    ${"" /* border: 1px solid #e5d5fc; */}
    background-color: #f9f9f9;
    border: 1px solid transparent;
    border-radius: 16px;
    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
      ${"" /* focus: nono; */}
    }
  }
  ,
  & .MuiInputBase-root {
    font-size: 16px;
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-nativeInput {
    border: none;
    top: 6%;
    opacity: 1;
    ${"" /* font-weight: 400; */}
    ${"" /* font-size: 16px; */}
    padding-left: 16px;
    background-color: #f9f9f9;
    border-radius: 16px;
    color: #434243;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    ::placeholder {
      ${"" /* color: #434243; */}
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
      opacity: 0.5;
    }
  }
`;

export const Selecter = styled(Select)`
  width: 100%;
  border-radius: 16px;
  & .MuiOutlinedInput-root {
    background-color: #f9f9f9;
    border: 1px solid transparent;
    border-radius: 16px;
    color: #434243;

    ::placeholder {
      color: #ebebeb;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }

    :hover {
      border: 1px solid #e5d5fc;
    }
    :focus {
      border: 1px solid #9747ff;
      ${"" /* focus: nono; */}
    }
  }
  ,
  & .MuiInputBase-root {
    font-size: 16px;
    color: #434243; 
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-nativeInput {
    border: none;
    top: 6%;
    opacity: 1;
    padding-left: 16px;
    background-color: #f9f9f9;
    border-radius: 16px;
    font-size: 16px;
<<<<<<< Updated upstream
    color: #434243;
  }
  & .MuiSelect-select {
    color: #434243;
=======
    color: #190d1a;
>>>>>>> Stashed changes
  }
`;
