import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import classes from "./SocialResultUser.module.css";
import Search from "../../../assets/Svg/Search.svg";
import Avatarone from "../../../assets/Svg/Avatarone.svg";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { SocialUserList } from "../../../services/auth";
import SocialResultUserNotFound from "../SocialResultUserNotFound/SocialResultUserNotFound";
import { useDispatch } from "react-redux";
import {
  updateIsSelectedUser,
  updateSelectedIndividualInvitId,
  updateSelectedInviteData,
} from "../../../Store/Reducers/selectedInviteData";
import { TYPEOFRESULTS } from "../../../Constant/Invite";
import { updateCategoryId } from "../../../Store/Reducers/categoriesId";

function SocialResultUser({
  category_id,
  subCategory_id,
  setSelectedUserInviteId,
  setselectedInviteId,
  handleClose,
  setInviteName,
  setIsAskOtherModalOpenSocial,
  setTypeofResult
}) {
  const [socialResultuser, setSocialResultuser] = useState([]);
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  // fetch api for Social User List
  const fetchSocialUserList = () => {
    const user = localStorage.getItem("userDetail");
    return SocialUserList(user, category_id, subCategory_id);
  };
  const { isLoading } = useQuery("social-users-list", fetchSocialUserList, {
    onSuccess: (data) => {
      setSocialResultuser(data?.data);
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });
  const SearchCategoryList = (e) => {
    setSearchValue(e.target.value);
    const filteredSocialUser = socialResultuser.filter((user) => {
      return user._id?.name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchResultArray(filteredSocialUser);
    setTypeofResult(TYPEOFRESULTS.SOCIAL)
  };

  const MainCard = styled(Box)(({ theme }) => ({
    background: "#fff",
    width: "692px",
    height: "740px",
    // minHeight: "calc(100vh - 4rem)",
    position: "relative",
    [theme.breakpoints.up("xl")]: {
      width: "692px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "692px",
    },
    [theme.breakpoints.down("md")]: {
      width: "560px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));
  const CardContent = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "40px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  }));
  const InnerWrapper = styled(Box)(({ theme }) => ({
    margin: "0px 16px",
    overflowX: "auto",
    [theme.breakpoints.down("lg")]: {
      margin: "0px 12px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 5px",
    },
  }));
  const InputField = styled(TextField)`
  height: 40px;
  background: #F9F9F9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }startTest/testend
  }
`;
  return (
    <MainCard
      sx={{
        minWidth: 250,
        borderRadius: "32px",
        marginInline: "auto",
      }}
    >
      <CardContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : socialResultuser.length !== 0 ? (
          <InnerWrapper>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Please select someone
            </Typography>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              from the list to view their perspective
            </Typography>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              on your self-discovery
            </Typography>
            <div className={classes.SearchBar}>
              <InputField
                size="small"
                fullWidth
                type="text"
                autoFocus={searchValue !== ""}
                value={searchValue}
                onChange={(e) => SearchCategoryList(e)}
                placeholder="Type in a name"
                id="outlined-start-adornment"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Search} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Grid
              container
              spacing={2}
              sx={{ width: "100%" }}
              className={classes.socialCardMainWrapper}
            >
              {socialResultuser?.length === 0 && isLoading ? (
                <div
                  style={{
                    margin: "auto",
                    justifyContent: "center",
                    marginTop: "30%",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "28px !important",
                    }}
                  >
                    No data found
                  </Typography>
                </div>
              ) : searchResultArray.length !== 0 ? (
                searchResultArray?.map((data) => {
                  const { _id, inviteID } = data;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      key={inviteID}
                      sx={{ cursor: "pointer", background: "yellow" }}
                      className={classes.socialGrid}
                      onClick={() => {
                        setSelectedUserInviteId(_id?.inviteUserUid);
                        handleClose();
                        setselectedInviteId(inviteID);
                        setInviteName(_id?.name);
                        dispatch(updateSelectedInviteData(_id));
                        dispatch(updateIsSelectedUser(true));
                        dispatch(updateSelectedIndividualInvitId(inviteID));
                      }}
                    >
                      <div className={classes.socialCardWrapper}>
                        <div className={classes.userIconeWrapper}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              color: "#9747FF",
                              height: "10px",
                              widht: "16px",
                              textAlign: "center",
                            }}
                          >
                            {_id.name
                              .split(" ")
                              .map((word) => word[0])
                              .join("")}
                          </Typography>
                        </div>
                        <div className={classes.textWrapper}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {_id.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                socialResultuser?.map((data) => {
                  const { _id, inviteID } = data;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      key={inviteID}
                      sx={{ cursor: "pointer", background: "yellow" }}
                      className={classes.socialGrid}
                      onClick={() => {
                        setSelectedUserInviteId(_id?.inviteUserUid);
                        handleClose();
                        setselectedInviteId(inviteID);
                        setInviteName(_id?.name);
                        dispatch(updateSelectedInviteData(_id));
                        dispatch(updateIsSelectedUser(true));
                        dispatch(updateSelectedIndividualInvitId(inviteID));
                        dispatch(updateCategoryId(data.category_id))
                      }}
                    >
                      <div className={classes.socialCardWrapper}>
                        <div className={classes.userIconeWrapper}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              color: "#9747FF",
                              height: "10px",
                              widht: "16px",
                              textAlign: "center",
                            }}
                          >
                            {_id.name
                              .split(" ")
                              .map((word) => word[0])
                              .join(" ")}
                            {/* {props?.inviteName.slice(0, 2)} */}
                          </Typography>
                        </div>
                        <div className={classes.textWrapper}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {_id.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </InnerWrapper>
        ) : (
          <SocialResultUserNotFound
            setIsAskOtherModalOpenSocial={setIsAskOtherModalOpenSocial}
          />
        )}
      </CardContent>
    </MainCard>
  );
}
export default SocialResultUser;
