import { Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./ReadMore.module.css";

const ReadMore = ({ variant, color, styles, children }) => {

  const text = children.toString();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Typography variant={variant} color={color} sx={{ ...styles }}>
      {isReadMore ? <div  dangerouslySetInnerHTML={{__html: text.slice(0, 350)}} /> : <div  dangerouslySetInnerHTML={{__html: text}} />}
      {text.length > 350 && (
        <span onClick={toggleReadMore} className={classes.readMore}>
          {isReadMore ? "...Read More" : " Show Less"}
        </span>
      )}
    </Typography>
  );
};

export default ReadMore;
