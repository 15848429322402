import { RightSideContentWrapper } from "./store.styles";
import Header from "../../components/Header/Header";
import classes from "./store.module.css";
import { CustomTypography } from "../../components/GoalComponent/GoalCustomDiv";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import { DataForStore, foundNameRetunImage } from "./store.data";
import DimentionUnlock from "./Modals/DimentionItems";
import { useEffect, useState } from "react";
import TestItemUnlock from "./Modals/TestItems";
import { getUserData, ResultAllTestDiamention, userLogin } from "../../services/auth";
import CategorySubcategoryService from "../../services/CategorySubcategory";
import ConfirmationModal from "./Modals/ConfirmatioinModal";
import UnlockSuccessFullModal from "./Modals/UnlockSuccessfullModal";
import PaymentService from "../../services/Payments";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import { setStoreData } from "../../Store/Reducers/storeData";
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "#fff",
                color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                // border: "1px solid",
                // borderColor: (theme) =>
                //     theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                p: 1,
                // borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                borderRadius: '16px',
                ...sx,
            }}
            {...other}
        />
    );
}
const getSymboleAndAmountForBuy = (item) => {
    const obj = {
        symbol: '',
        amount: 0,
        coin: false,
        diamond: false,
    }
    if (item.price && item.price.id) {
        obj.symbol = '$'
        obj.amount = item.price.unit_amount / 100
    }
    if (item.buyWith === "amt") {
        obj.symbol = '$'
        obj.amount = item.buyFor
    }
    if (item.buyWith === "coins") {
        obj.symbol = null
        obj.coin = true
        obj.amount = item.buyFor
    }
    return obj
}

let isCalledAPI = false
const StorePage = () => {
    const { userData } = useSelector((state) => state.userProfile);
    const { storeData } = useSelector(state => state)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state, pathname } = useLocation();
    const {
        paymentId,
        products,
        productsLoading,
        dimetionModalVisible,
        testModalVisible,
        subcategories,
        allsubcategories,
        dimensions,
        allDimensions,
        searchValue,
        loadingData,
        visibleConfirmation,
        selectedItem,
        unlockSuccessfullModalVisible,
        generatingPaymentLoader,
        paymentSuccess,
        loadingPaymentStatus,
    } = storeData
    
    const setSelectedId = val => dispatch(setStoreData({
        paymentId: val
    }))
    const setProducts = val => dispatch(setStoreData({
        products: val
    }))
    const setProductsLoading = val => dispatch(setStoreData({
        productsLoading: val
    }))
    const setDiamondModalVisible = val => dispatch(setStoreData({
        dimetionModalVisible: val
    }))
    const setTestModalVisible = (val) => dispatch(setStoreData({
        testModalVisible: val
    }))
    const setSubcategories = (val) => dispatch(setStoreData({
        subcategories: val
    }))
    const setAllSubcategories = (val) => dispatch(setStoreData({
        allsubcategories: val
    }))
    const setDimensions = (val) => dispatch(setStoreData({
        dimensions: val
    }))
    const setAllDimensions = (val) => dispatch(setStoreData({
        allDimensions: val
    }))
    const setSearchValue = (val) => dispatch(setStoreData({
        searchValue: val
    }))
    const setLoadingData = (val) => dispatch(setStoreData({
        loadingData: val
    }))
    const setConfirmationVisible = (val) => dispatch(setStoreData({
        visibleConfirmation: val
    }))
    const setSelectedItem = (val) => dispatch(setStoreData({
        selectedItem: val
    }))
    const setUnlockSuccessfullModalVisible = (val) => dispatch(setStoreData({
        unlockSuccessfullModalVisible: val
    }))
    const setGeneratingPaymentLoader = (val) => dispatch(setStoreData({
        generatingPaymentLoader: val
    }))
    const setPaymentSuccess = (val) => dispatch(setStoreData({
        paymentSuccess: val
    }))
    const setLoadingPaymentStatus = (val) => dispatch(setStoreData({
        loadingPaymentStatus: val
    }))
    const getProducts = () => {
        setProductsLoading(true)
        PaymentService.GET_PRODUCTS()
            .then((res) => {
                const orderedProducts = [];
                ['100 Coins', '500 Coins', '1000 Coins', '1 Diamond', '5 Diamonds', '10 Diamonds'].map(it => {
                    const foundData = res?.data?.data.find(ite => ite.name.toLowerCase() === it.toLowerCase());
                    if (foundData) {
                        orderedProducts.push(foundData)
                    }
                    return it
                })
                DataForStore[0].children = orderedProducts
                const data = [
                    ...DataForStore
                ]
                setProducts(data)
                // setSubcategories(res.subCategoryData)
                // setAllSubcategories(res.subCategoryData)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setProductsLoading(false)
            });
    }
    const createInvoice = () => {
        setGeneratingPaymentLoader(true)
        const objForServer = {
            "user_email": userData?.user_email,
            "uid": userData?.uid,
            redirect_url: window.location.origin + '/store/paymentresult'
        }
        if (selectedItem.price && selectedItem.price.id) {
            objForServer["type"] = "SHOP_VIRTUAL_CURRENCY"
            objForServer["items"] = [
                {
                    "productId": selectedItem.id,
                    "quantity": 1
                }
            ]
        }
        if (selectedItem.category_id && selectedItem.subCategory_id) {
            objForServer["type"] = "SHOP_TEST"
            objForServer["category_id"] = selectedItem.category_id
            objForServer["subCategory_id"] = selectedItem.subCategory_id
            objForServer["subCategory"] = selectedItem.subCategory
        }
        if (selectedItem.questionCategoryId) {
            objForServer["type"] = "SHOP_DIMENSION"
            objForServer["questionCategoryId"] = selectedItem.questionCategoryId
            objForServer["questionCategory"] = selectedItem.questionCategory
        }
        PaymentService.CREATE_INVOICE(objForServer)
            .then((res) => {
                localStorage.setItem("selectedItem", res.paymentLink.id)
                localStorage.setItem("selected_item", JSON.stringify(selectedItem))
                setGeneratingPaymentLoader(false)
                setConfirmationVisible(false)
                setTestModalVisible(false)
                setDiamondModalVisible(false)
                if (res.paymentLink.url) {
                    window.open(res.paymentLink.url, "_self")
                }
                // setSubcategories(res.subCategoryData)
                // setAllSubcategories(res.subCategoryData)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setProductsLoading(false)
            });
    }
    const getPaymentStatus = (id) => {
        setLoadingPaymentStatus(true)
        const objForServer = {
            "user_email": userData?.user_email,
            "uid": userData?.uid,
            "id": id
        }
        PaymentService.PAYMENT_STATUS(objForServer)
            .then(async (res) => {
                if (["paid",].includes(res.data.payment_status)) {
                    setPaymentSuccess(true)

                    if (res.data.metadata.type === "SHOP_VIRTUAL_CURRENCY") {
                        getUserData(userData?.uid)
                            .then((response) => {
                                dispatch(userProfileUpdate(response.data));
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                            });

                    }
                    if (res.data.metadata.type === "SHOP_TEST") {
                    }
                    if (res.data.metadata.type === "SHOP_DIMENSION") {
                    }
                } else {
                    setPaymentSuccess(false)
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingPaymentStatus(false)
                setProductsLoading(false)
            });
    }
    useEffect(() => {
        if (!products || !products.length) {
            getProducts()
        }

    }, [])
    useEffect(() => {
        if (pathname.includes('paymentresult')) {
            const selectedItemLocal = localStorage.getItem("selectedItem")
            if (selectedItemLocal) {
                setSelectedId(selectedItemLocal)
                const internalLoop = () => {
                    if (userData?.uid && selectedItemLocal) {
                        getPaymentStatus(selectedItemLocal)
                        if (localStorage.getItem("selected_item") && Object.keys(JSON.parse(localStorage.getItem("selected_item"))).length) {
                            setSelectedItem(JSON.parse(localStorage.getItem("selected_item")))
                        }
                        !unlockSuccessfullModalVisible && setUnlockSuccessfullModalVisible(true)
                        // localStorage.removeItem("selectedItem")
                        // localStorage.removeItem("selected_item")
                    } else {
                        setTimeout(() => {
                            internalLoop()
                        }, 1000);
                    }
                }
                internalLoop()
            } else {
                navigate('/store')
            }
        }
    }, [paymentId])
    const getAllSubcategoryData = () => {
        if (!isCalledAPI) {
            isCalledAPI = true
            const user = localStorage.getItem("userDetail");
            CategorySubcategoryService.getAllInactiveSubcategory({
                uid: user
            })
                .then((res) => {
                    setSubcategories(res.subCategoryData)
                    setAllSubcategories(res.subCategoryData)
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    isCalledAPI = false

                    setLoadingData(false)
                });
        }

    }
    const getDimentions = (showLoader = true) => {
        if (!isCalledAPI) {
            isCalledAPI = true
            const user = localStorage.getItem("userDetail");
            ResultAllTestDiamention(user)
                .then((data) => {
                    setDimensions(data?.allPercentage.filter(it => !it.unlock))
                    setAllDimensions(data?.allPercentage.filter(it => !it.unlock))
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    isCalledAPI = false
                    setLoadingData(false)
                });
        }

    }
    const searchSubCategory = ({ target: { value } }) => {
        setSearchValue(value)
        if (value) {
            const filtered = allsubcategories.filter(it => {
                return it.subCategory.toLowerCase().includes(value.toLowerCase())
            })
            setSubcategories(filtered)
        } else {
            setSubcategories(allsubcategories)
        }
    }
    const searchDimension = ({ target: { value } }) => {
        setSearchValue(value)
        if (value) {
            const filtered = allDimensions.filter(it => {
                return it.questionCategory.toLowerCase().includes(value.toLowerCase())
            })
            setDimensions(filtered)
        } else {
            setDimensions(allDimensions)
        }
    }

    return (
        <RightSideContentWrapper>
            <Header />
            <div className={classes.dashbordTitleContainer}>
                <div className={classes.dashbordTitle}>
                    <Typography variant="h4" sx={{ fontSize: '2.5rem' }}>SSD Store</Typography>
                </div>
                <div className={classes.dashbordTitlebody}>
                    <Typography variant="p" sx={{ color: "#434243" }}>
                        Here you can buy
                    </Typography>
                </div>
            </div>
            <div className={classes.storeContainer}>
                {productsLoading && <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <CircularProgress />
                </div>}
                {!productsLoading && [...products].map((it) => (
                    <>
                        <div className={classes.dashbordTitle}>
                            <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>{it.name}</Typography>
                        </div>
                        {it.children.length && (
                            <Grid sx={{ flexGrow: 1, justifyContent: 'space-between' }} container /* rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} */>
                                {it.children.map((ite) => {
                                    const { amount, coin, diamond, symbol, } = getSymboleAndAmountForBuy(ite)
                                    const { name, imageProps } = foundNameRetunImage(ite.name)
                                    return (
                                        <Grid item border="1px solid"
                                            borderColor={"#F3F3F3"}
                                            borderRadius={"16px"}
                                            sx={{
                                                marginBottom: '10px',
                                                padding: '10px 0'
                                            }}
                                            minHeight={"155px"}
                                            xs={11.9} sm={11.9} md={5.9} lg={5.9} xl={5.9}
                                            key={ite.name}>
                                            <Grid sx={{ flexGrow: 1, padding: '10px' }} container /* rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} */
                                            >
                                                <Grid item xs={5} sm={4} md={4} lg={3} xl={3} >
                                                    <div style={{
                                                        width: '126px',
                                                        height: '110px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img
                                                            src={ite.icon ? ite.icon : ite.images && ite.images[0] ? name/* ite.images[0] */ : null}
                                                            {...imageProps}
                                                            alt="icon"
                                                        />
                                                    </div>

                                                </Grid>
                                                <Grid item xs={7} sm={8} md={8} lg={5} xl={5} >
                                                    <Box
                                                        sx={{
                                                            display: "grid",
                                                            gridAutoFlow: "row",
                                                        }}
                                                    >
                                                        <Item sx={{
                                                            fontSize: "1rem",
                                                            fontWeight: "500",
                                                            color: '#190D1A',
                                                            padding: '8px 8px 0px 8px'
                                                        }}>{ite.name}</Item>
                                                        <Item sx={{
                                                            fontSize: "0.875rem",
                                                            fontWeight: "400",
                                                            color: '#434243',
                                                            padding: '4px 8px 8px 8px'
                                                        }}>{ite.description}</Item>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10} sm={12} md={9} lg={4} xl={4}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                    <Button
                                                        className={classes.set_btn}
                                                        variant="contained"
                                                        sx={{
                                                            gap: '6px',

                                                        }}
                                                        onClick={ite.dimention
                                                            ? () => setDiamondModalVisible(true)
                                                            : ite.test
                                                                ? () => setTestModalVisible(true)
                                                                : () => {
                                                                    setSelectedItem(ite);
                                                                    setConfirmationVisible(true)
                                                                }}
                                                    >
                                                        Buy for {!coin && !diamond ? symbol : ''}{amount}
                                                        {coin && <img src={Coin} className={classes.classForAmount} alt="" />}
                                                        {diamond && <img src={DiamondCoin} alt="" className={classes.classForAmount} />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        )}
                    </>
                ))}
            </div>
            {/* <Box
                sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                }}
            >
                <Item sx={{ gridRow: '1', gridColumn: 'span 2' }}>span 2</Item>
                {/* The second non-visible column has width of 1/4
                <Item sx={{ gridRow: '1', gridColumn: '4 / 5' }}>4 / 5</Item>
            </Box> */}
            {dimetionModalVisible && <DimentionUnlock
                dimetionModalVisible={dimetionModalVisible}
                setDiamondModalVisible={setDiamondModalVisible}
                dimensions={dimensions}
                setDimensions={setDimensions}
                allDimensions={allDimensions}
                setAllDimensions={setAllDimensions}
                getDimentions={getDimentions}
                searchDimension={searchDimension}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                setSelectedItem={setSelectedItem}
                setConfirmationVisible={setConfirmationVisible}
            />}
            {testModalVisible && <TestItemUnlock
                testModalVisible={testModalVisible}
                setTestModalVisible={setTestModalVisible}
                getAllSubcategoryData={getAllSubcategoryData}
                allsubcategories={allsubcategories}
                subcategories={subcategories}
                searchValue={searchValue}
                setSubcategories={setSubcategories}
                setSearchValue={setSearchValue}
                searchSubCategory={searchSubCategory}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                setSelectedItem={setSelectedItem}
                setConfirmationVisible={setConfirmationVisible}
            />}
            {visibleConfirmation && <ConfirmationModal
                visibleConfirmation={visibleConfirmation}
                setConfirmationVisible={setConfirmationVisible}
                selectedItem={selectedItem}
                getSymboleAndAmountForBuy={getSymboleAndAmountForBuy}
                unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
                setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
                setTestModalVisible={setTestModalVisible}
                setDiamondModalVisible={setDiamondModalVisible}
                createInvoice={createInvoice}
                generatingPaymentLoader={generatingPaymentLoader}
            />}
            {unlockSuccessfullModalVisible && <UnlockSuccessFullModal
                selectedItem={selectedItem}
                dimensions={dimensions}
                setDimensions={setDimensions}
                allDimensions={allDimensions}
                setAllDimensions={setAllDimensions}
                getAllSubcategoryData={getAllSubcategoryData}
                subcategories={subcategories}
                paymentSuccess={paymentSuccess}
                unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
                setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
                loadingPaymentStatus={loadingPaymentStatus}
            />}
        </RightSideContentWrapper>
    );
};

export default StorePage;
