export const PROFILEINFO_TYPE = Object.freeze({
    ACCOUNT_INFO: "ACCOUNT_INFO",
    PERSONAL_INFO: "PERSONAL_INFO",
  });

export const NAVIGATION_TYPE = Object.freeze({
  PROFILE_INFO: "Profile",
  PASSWORD_INFO: "Password",
  MEMBERSHIP_INFO: "MEMBERSHIP_INFO",
  GET_IN_TOUCH_INFO: "GET_IN_TOUCH_INFO",
  NOTIFICATIONS_INFO: "NOTIFICATIONS_INFO",
  ABOUT_US_INFO: "ABOUT_US_INFO",
  lEGAL_INFO: "lEGAL_INFO",
});

export const FEEDBACK_TYPE = Object.freeze({
  REQUEST_FEEDBACK: "REQUEST_FEEDBACK",
  REPORT_BUG: "REPOORT_BUG",
  GIVE_FEEDBACK: "GIVE_FEEDBACK",
});

export const facebookSection = [
  "Goals", "Growth Calender", "Results", "Dashboard", "Tests", "Setting", "Other"
]

export const Gender = [
  "Male",
  "Female",
  "Non-binary",
  "More Options",
  "Prefer not to answer",
];

export const Language = [
  "Abkhaz",
  "Afar",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragonese",
  "Armenian",
  "Assamese",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbaijani",
  "Bambara",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bihari languages",
  "Bislama",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Burmese",
  "Catalan; Valencian",
  "Chamorro",
  "Chechen",
  "Chichewa; Chewa; Nyanja",
  "Chinese",
  "Chuvash",
  "Cornish",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Divehi; Dhivehi; Maldivian",
  "Dutch; Flemish",
  "Dzongkha",
  "English",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "French",
  "Fulah",
  "Gaelic; Scottish Gaelic",
  "Galician",
  "Georgian",
  "German",
  "Haitian; Haitian Creole",
  "Hausa",
  "Hebrew (modern)",
  "Herero",
  "Hindi",
  "Hiri Motu",
  "Hungarian",
  "Interlingua",
  "Indonesian",
  "Interlingue",
  "Irish",
  "Igbo",
  "Inupiaq",
  "Ido",
  "Icelandic",
  "Italian",
  "Inuktitut",
  "Japanese",
  "Javanese",
  "Kalaallisut, Greenlandic",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Khmer",
  "Kikuyu, Gikuyu",
  "Kinyarwanda",
  "Kirghiz, Kyrgyz",
  "Komi",
  "Kongo",
  "Korean",
  "Kurdish",
  "Kwanyama, Kuanyama",
  "Latin",
  "Luxembourgish, Letzeburgesch",
  "Luganda",
  "Limburgish, Limburgan, Limburger",
  "Lingala",
  "Lao",
  "Lithuanian",
  "Luba-Katanga",
  "Latvian",
  "Manx",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Māori",
  "Marathi (Marāṭhī)",
  "Marshallese",
  "Mongolian",
  "Nauru",
  "Navajo, Navaho",
  "Norwegian Bokmål",
  "North Ndebele",
  "Nepali",
  "Ndonga",
  "Norwegian Nynorsk",
  "Norwegian",
  "Nuosu",
  "South Ndebele",
  "Occitan",
  "Ojibwe, Ojibwa",
  "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  "Oromo",
  "Oriya",
  "Ossetian, Ossetic",
  "Panjabi, Punjabi",
  "Pāli",
  "Persian",
  "Polish",
  "Pashto, Pushto",
  "Portuguese",
  "Quechua",
  "Romansh",
  "Kirundi",
  "Romanian, Moldavian, Moldovan",
  "Russian",
  "Sanskrit (Saṁskṛta)",
  "Sardinian",
  "Sindhi",
  "Northern Sami",
  "Samoan",
  "Sango",
  "Serbian",
  "Scottish Gaelic; Gaelic",
  "Shona",
  "Sinhala, Sinhalese",
  "Slovak",
  "Slovene",
  "Somali",
  "Southern Sotho",
  "Spanish; Castilian",
  "Sundanese",
  "Swahili",
  "Swati",
  "Swedish",
  "Tamil",
  "Telugu",
  "Tajik",
  "Thai",
  "Tigrinya",
  "Tibetan Standard, Tibetan, Central",
  "Turkmen",
  "Tagalog",
  "Tswana",
  "Tonga (Tonga Islands)",
  "Turkish",
  "Tsonga",
  "Tatar",
  "Twi",
  "Tahitian",
  "Uighur, Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venda",
  "Vietnamese",
  "Volapük",
  "Walloon",
  "Welsh",
  "Wolof",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang, Chuang",
];

export const Country = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People'S Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const Ethnicity = [
  "White",
  "Black or African American",
  "Hispanic or Latino",
  "Asian",
  "Arabic",
  "Islander",
  "Indigenous",
  "Indian",
  "Other",
  "Prefer not to answer",
];

export const Industry = [
  "Accounting",
  "Schools",
  "University",
  "Music",
  "Television",
  "News",
  "Advertising or marketing",
  "Design",
  "Consulting or Advising",
  "Start up",
  "Freelance",
  "Technology",
  "Retail",
  "Clothing",
  "Tourism",
  "Transportation",
  "Banking",
  "Events",
  "Hotels",
  "Restaurants",
  "Coaching",
  "Community or Social Services",
  "Public Services",
  "Architecture",
  "Agriculture or Fishing",
  "Legal",
  "Health care",
  "Consumer Products",
];

export const MaritalStatus = [
  "Never married",
  "Married",
  "Separated",
  "Divorced",
  "Widowed",
  "Prefer not to answer",
];

export const Children = [
  "No",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "More than 7",
  "Prefer not to answer",
];

export const Goal = [
  "Better relationships",
  "Better physique",
  "More joy",
  "More meaning",
  "More energy",
  "More strength",
  "More focus",
  "Stronger sense of progress",
  "More calm",
  "Prefer not to answer",
];

export const PoliticalPreferences = [
  "No Preference",
  "Republican",
  "Democrat",
  "Liberal",
  "Green",
  "Conservative",
  "Right",
  "Left",
  "Other",
  "Prefer not to answer",
];

export const Occupation = [
  "Therapist",
  "Counselor",
  "Coach",
  "HR Professional",
  "Manager",
  "Entrepreneur",
  "Homemaker/parent",
  "Designer",
  "Software Developer",
  "Researcher/Scientist",
  "Data Analyst",
  "Psychologist",
  "Teacher/Educator",
  "Social Worker",
  "Consultant",
  "Sales/Marketing Professional",
  "Lawyers",
  "Writers",
  "Journalists/Filmmaker",
  "Military Personnel",
  "Humanitarian Worker",
  "Doctor",
  "Other",
];

