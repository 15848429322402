import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import classes from "./404NotFound.module.css"
import NotFoundImage from "./../../assets/Svg/IllBox.svg"
const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    background: "#FFF",
}));

const PageNotFound = () => {
    const navigate = useNavigate()
    return <RightSideContentWrapper>
        <Header />
        <div className={classes.Container404}>
            <div className={classes.Content}>
                {/* <div className={classes.illubox}>
                    <div className={classes.rectangle}>
                        <Typography
                            sx={{ fontFamily: 'Coffee Mood', fontWeight: 400, fontSize: '6rem', color: '#FFFFFF' }}
                        >404</Typography>
                    </div>
                </div> */}
                <img src={NotFoundImage} alt='not found' />
                <div className={classes.textContent}>
                    <Typography
                        sx={{ fontWeight: 600, fontSize: '2.5rem', color: '#190D1A' }}
                    >Ooops... this page doesn’t exist anymore</Typography>
                    <Typography
                        sx={{ fontWeight: 400, fontSize: '1rem', color: '#434243' }}
                    >It seems like the page you were looking for was removed or the address isn’t right</Typography>
                </div>
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Button
                    className={classes.premiumButton}
                    fullWidth
                    onClick={() => navigate('/dashboard')}
                // onClick={() => dispatch(openCloseInviteModal(true))}
                //sx={{ mt: "24px", mb: "57px" }}
                >
                    Back home
                </Button>
            </div>

        </div>
    </RightSideContentWrapper>
}

export default PageNotFound