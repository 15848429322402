import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

// export interface CounterState {
//   value: any
// }

const initialState = {
  userData: {}
}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    userProfileUpdate: (state,action)=>{
      state.userData = action.payload
    },
    updateUserWalletCoins: (state, action) => {
      if(state.userData?.wallet?.coins && Number(state.userData?.wallet?.coins) > 0) {
        state.userData.wallet = {
          ...state.userData.wallet,
          coins: Number(state.userData.wallet.coins) - 20
        }
      }
    },
    updateUserWalletCoinsAdd: (state, action) => {
      if(state.userData?.wallet?.coins) {
        state.userData.wallet = {
          ...state.userData.wallet,
          coins: Number(state.userData.wallet.coins || 0) + 20
        }
      }
    },
    updateUserCions: (state, action) => {
      console.log("=== updating xp ", action.payload);
      state.userData = {
        ...state.userData,
        xp: action.payload
      }
    },
    resetUsrStore: () => {
      return initialState
    }
  },
})

// Action creators are generated for each case reducer function
export const { userProfileUpdate, resetUsrStore, updateUserWalletCoins, updateUserCions, updateUserWalletCoinsAdd } = userProfileSlice.actions

export default userProfileSlice.reducer