import { Grid, Typography } from "@mui/material";
import { Input } from "../StyledElement/StyledformInput";

export default function ProfileOnlinePresence({
  handleChangeInput,
  accountInfoData,
}) {
  return (
    <>
      <Typography variant="body4">Online presence</Typography>
      <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <Typography
            sx={{ margin: "8px 0px 12px 0px", fontWeight: 500 }}
            variant="subtitle1"
          >
            Facebook
          </Typography>
          <Input
            name={"facebook"}
            placeholder=""
            value={accountInfoData.facebook}
            onChange={handleChangeInput}
            // validationFunction={isValidName}
            type="text"
            // errorMessage={"errorEmail"}
            // setErrorMessage={"setErrorEmail"}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <Typography
            sx={{ margin: "8px 0px 12px 0px", fontWeight: 500 }}
            variant="subtitle1"
          >
            Instagram
          </Typography>
          <Input
            name={"instagram"}
            placeholder=""
            value={accountInfoData.instagram}
            onChange={handleChangeInput}
            // validationFunction={"isValidEmail"}
            type="text"
            // errorMessage={"errorEmail"}
            // setErrorMessage={"setErrorEmail"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "22px" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <Typography
            sx={{ margin: "8px 0px 12px 0px", fontWeight: 500 }}
            variant="subtitle1"
          >
            Twitter
          </Typography>
          <Input
            name={"twitter"}
            placeholder=""
            value={accountInfoData.twitter}
            onChange={handleChangeInput}
            // validationFunction={isValidName}
            type="text"
            // errorMessage={"errorEmail"}
            // setErrorMessage={"setErrorEmail"}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
          <Typography
            sx={{ margin: "8px 0px 12px 0px", fontWeight: 500 }}
            variant="subtitle1"
          >
            Tiktok
          </Typography>
          <Input
            name={"tiktok"}
            placeholder=""
            value={accountInfoData.tiktok}
            onChange={handleChangeInput}
            // validationFunction={"isValidEmail"}
            type="text"
            // errorMessage={"errorEmail"}
            // setErrorMessage={"setErrorEmail"}
          />
        </Grid>
      </Grid>
    </>
  );
}
