import { useEffect, useRef, useState } from "react";
import {
  SettingDeleteButton,
  SettingUploadButton,
  SubmitButton,
} from "../../../components/Button/Button";
import classes from "./ProfileAccountInfo.module.css";
import ProfileAvatar from "../../../assets/Svg/ProfileAvatar.svg";
import { Grid, Typography } from "@mui/material";
import { Input } from "../../../components/Settings/StyledElement/StyledformInput";
import ProfileOnlinePresence from "../../../components/Settings/Profile/ProfileOnLInePresence";
import { MultiLineInput } from "../../TestResultsPages/StyledStepper/styledStepper";
import {
  deleteProfileImage,
  getUserData,
  upadateProfileImage,
  updateUserData,
} from "../../../services/auth";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { userProfileUpdate } from "../../../Store/Reducers/userProfileSlice";
import useCustomNotification from "../../../Hooks/useCustomNotification";
import moment from "moment";
import { imageURLRender } from "../../../utils";

function ProfileAccountInfo({
  accountInfoData,
  setAccountInfoData,
  currentDate,
  setCurrentDate,
}) {
  const hiddenFileInput = useRef(null);
  const [requiredMessage, setRequiredMessage] = useState("");
  const distpatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  // const [currentDate, setCurrentDate] = useState(new Date(accountInfoData.dob));
  console.log("currentDate", currentDate);
  const [isOpen, setIsOpen] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  useEffect(() => {
    console.log("currentDate = ", currentDate);
    setAccountInfoData({
      ...accountInfoData,
      dob: currentDate,
    });
  }, [currentDate]);

  const handleChangeImageUpload = (event) => {
    setSelectedImage(event.target.files[0]);
    const data = {
      uid: localStorage.getItem("userDetail"),
    };
    const formData = new FormData();
    formData.append("photo", event.target.files[0]);
    console.log("formData==>", formData);
    upadateProfileImage(data, formData)
      .then((res) => {
        console.log("updateProfile = ", res);
        const user = localStorage.getItem("userDetail");
        if (user) {
          // setIsLoading(true);
          getUserData(user)
            .then((response) => {
              console.log(response.data);
              // navigate("/onbording");
              distpatch(userProfileUpdate(response.data));
              setAccountInfoData(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              // setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log("error = ", err);
      });
  };
  const handleChangeInput = (e) => {
    if (e.target.name === "dob") {
      setIsOpen(true);
    }
    if (e.target.name === "user_email") {
      setEmailError(true);
    }
    setAccountInfoData({
      ...accountInfoData,
      [e.target.name]: e.target.value,
    });
  };
  const handleDatePicker = () => {
    setIsOpen(false);
  };
  const handleSubmitData = () => {
    console.log("user data==>", accountInfoData);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = regex.test(accountInfoData.user_email);
    console.log("isValidEmail==>", isValidEmail);
    setEmailError(isValidEmail);

    if (isValidEmail) {
      distpatch(userProfileUpdate(accountInfoData));
      let Details = {
        ...accountInfoData,
        uid: localStorage.getItem("userDetail"),
        dob: currentDate,
      };
      setIsLoading(true);
      setCustomNotification(true);
      updateUserData(Details)
        .then((res) => {
          console.log("res update = ", res);
        })
        .catch((err) => {
          console.log("err = ", err);
          setRequiredMessage(err.response.data.description);
        })
        .finally(() => {
          setIsLoading(false);
        });
      setCurrentDate(accountInfoData.dob);
    }
  };
  console.log("requiredMessage==>", requiredMessage);
  
  const userProfileUpload = () => {
    hiddenFileInput.current.click();
  };
  console.log("accountInfoInputData = ", accountInfoData);

  const deleteUserProfileImage = () => {
    setSelectedImage(null);

    let details = {
      uid: localStorage.getItem("userDetail"),
    };
    deleteProfileImage(details)
      .then((resp) => {
        console.log(resp);
        getUserData(details.uid)
          .then((response) => {
            console.log(response.data);
            // navigate("/onbording");
            distpatch(userProfileUpdate(response.data));
            setAccountInfoData(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  return (
    <>
      {" "}
      <div style={{ position: "relative" }}>
        <div onClick={handleDatePicker}>
          <div className={classes.profileImageWrapper}>
            <div className={classes.profileImage}>
              <img
                className={classes.uploadedImage}
                alt="..."
                src={
                  accountInfoData.imageUrl === "Not updated" ||
                  !accountInfoData.imageUrl
                    ? ProfileAvatar
                    : imageURLRender(accountInfoData.imageUrl)
                }
              />
            </div>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(event) => handleChangeImageUpload(event)}
              style={{ display: "none" }}
            />
            <SettingUploadButton
              onClick={userProfileUpload}
              // isDisabled={selectedImage ? true : false}
              buttonText="Upload image"
            />

            <SettingDeleteButton
              isDisabled={
                accountInfoData?.imageUrl === "Not updated" ? true : false
              }
              buttonText="Delete"
              onClick={deleteUserProfileImage}
            />
          </div>
          <Typography variant="body4">Personal data</Typography>
          <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
            <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
              <Typography className={classes.inputName} variant="subtitle1">
                Name
              </Typography>
              <Input
                name={"name"}
                placeholder="Name"
                value={accountInfoData.name}
                onChange={handleChangeInput}
                type="text"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
              <Typography className={classes.inputName} variant="subtitle1">
                Surname
              </Typography>
              <Input
                name={"surName"}
                placeholder="Surname"
                value={accountInfoData.surName}
                onChange={handleChangeInput}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "12px" }}>
            <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
              <Typography className={classes.inputName} variant="subtitle1">
                Username
              </Typography>
              <Input
                name="userName"
                placeholder="Create a username"
                value={accountInfoData.userName}
                onChange={handleChangeInput}
                type="text"
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
              <Typography className={classes.inputName} variant="subtitle1">
                Email
              </Typography>
              <Input
                name="user_email"
                placeholder="example@mail.com"
                value={accountInfoData.user_email}
                onChange={handleChangeInput}
                emailError={emailError}
                type="email"
                required={true}
              />
              <span className={classes.email_error}>
                {emailError === false && "Please add valid email"}
              </span>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "12px" }}>
            <Grid item lg={12} xs={12} md={12}>
              <Typography className={classes.inputName} variant="subtitle1">
                Birthday
              </Typography>
              <Input
                name={"dob"}
                placeholder="dd/mm/yyyy"
                value={moment.utc(new Date(currentDate)).format("DD/MM/yyyy")}
                onChange={handleChangeInput}
                type={"date"}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "12px" }}>
            <Grid item lg={12} xs={12} md={12}>
              <Typography className={classes.inputName} variant="subtitle1">
                City
              </Typography>
              <Input
                name={"city"}
                placeholder="Insert your city name"
                value={accountInfoData.city}
                onChange={handleChangeInput}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "12px" }}>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <Typography className={classes.inputName} variant="subtitle1">
                Bio
              </Typography>
              <MultiLineInput
                sx={{ maxWidth: "100% !important" }}
                value={accountInfoData.bio}
                onChange={handleChangeInput}
                name={"bio"}
                multiline
                size="small"
                fullWidth
                key="pogress"
                type="text"
                placeholder="Tell us briefly"
              />
              <Typography variant="body2" mb={"16px"} mt={"12px"}>
                0/100
              </Typography>
            </Grid>
          </Grid>
          <ProfileOnlinePresence
            handleChangeInput={handleChangeInput}
            accountInfoData={accountInfoData}
          />
          <SubmitButton
            buttonText={"Save"}
            onClick={handleSubmitData}
            isLoading={isLoading}
          />
        </div>
        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: "37%",
              background: "white",
              left: "0%",
            }}
          >
            <DatePicker
              currentDate={new Date(currentDate)}
              setCurrentDate={setCurrentDate}
            />
          </div>
        )}
      </div>
      <>
        {customNotification && (
          <NotificationContentJSX
            message={
              accountInfoData.name === ""
                ? requiredMessage
                : "Profile updated successfully"
            }
            isThumbIcon={false}
            position="top"
          />
        )}
      </>
    </>
  );
}

export default ProfileAccountInfo;
