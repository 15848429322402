export const GOALTYPE = Object.freeze({
  ALL_DIAMENSTIONGOAL: "ALL_DIAMENSTIONGOAL",
  FAVEROUITE_DIAMENSTIONGOAL: "FAVEROUITE_DIAMENSTIONGOAL",
});
export const CALENDARTYPE = Object.freeze({
  MONTHLY_CALENDAR: "MONTHLY_CALENDAR",
  WEEKLY_CALENDAR: "WEEKLY_CALENDAR",
});
export const RANGEPICKERTYPE = Object.freeze({
  DATE_RANGE_PICKER: "DATE_RANGE_PICKER",
  TIME_RANGE_PICKER: "TIME_RANGE_PICKER",
});
export const TIME_PICKER = Object.freeze({
  TIME_PICKER_START: "TIME_PICKER_START",
  TIME_PICKER_END: "TIME_PICKER_END",
});
export const DATE_PICKER = Object.freeze({
  DATE_PICKER_START: "DATE_PICKER_START",
  DATE_PICKER_END: "DATE_PICKER_END",
});
export const TIME_BASE = Object.freeze({
  TIME_BASED: "TIME_BASED",
  UNSPECIFIED_TIME: "UNSPECIFIED",
});
export const FREQUENCY_TYPE = Object.freeze({
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
});
export const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const shortDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const dates = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
export const optionGroups = {
  hour: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  minutus: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
  ],
  // AMPM: ["AM", "PM"],
};
