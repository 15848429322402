import { Avatar, Badge, Button, Popover, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NotificationPopover from "./NotificationPopover";
const HeaderPopover = (
    {
        handleClose,
        
    }
) => {
    const { anchorEl, yposition,
        xposition } = useSelector(state => state.headerDataSlice)
    return (
        <Popover
            sx={{ maxHeight: "calc(100vh - 190px)", }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: yposition, left: xposition }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <NotificationPopover />
        </Popover>
    )
}

export default HeaderPopover